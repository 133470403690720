
import { jsPDF } from "jspdf";

const annotation = {
  position: "front",
  xaxis: [
    {
      x: '2023-01-01',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\u2744\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },

    {
      x: '2023-01-02',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\u2744\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-03',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\u2600\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-04',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\uD83C\uDF27\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-05',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\uD83C\uDF25\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-06',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\u26A1',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    }, {
      x: '2023-01-07',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\uD83C\uDF2A\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-08',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\uD83C\uDF2B\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    {
      x: '2023-01-09',
      borderWidth: 0,
      label: {
        offsetY: 20,
        borderWidth: 0,
        orientation: 'horizontal',
        text: '\uD83C\uDF2B\uFE0F',
        style: {
          cssClass: 'apexcharts-xaxis-annotation-weather',
        },
      }
    },
    // {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-01',
    //   x2: '2023-01-02',
    //   fillColor: "#00E396",
    //   opacity: 0.1
    // },
    // {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-02',
    //   x2: '2023-01-03',
    //   fillColor: "yellow",
    //   opacity: 0.1
    // },
    // {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-03',
    //   x2: '2023-01-04',
    //   fillColor: "yellow",
    //   opacity: 0.1
    // }, {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-04',
    //   x2: '2023-01-05',
    //   fillColor: "#00E396",
    //   opacity: 0.1
    // }, {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-05',
    //   x2: '2023-01-06',
    //   fillColor: "yellow",
    //   opacity: 0.1
    // }, {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-06',
    //   x2: '2023-01-07',
    //   fillColor: "#00E396",
    //   opacity: 0.1
    // }, {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-07',
    //   x2: '2023-01-08',
    //   fillColor: "#00E396",
    //   opacity: 0.1
    // }, {
    //   label: {
    //     text: " "
    //   },
    //   x: '2023-01-08',
    //   x2: '2023-01-09',
    //   fillColor: "yellow",
    //   opacity: 0.1
    // },
  ]
};

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['weather'],
  data () {
    return {
      series: [
        {
          data: [10, 41, 35, 51, 49, 62, 69, 148, 91]
        }
      ],
      chartOptions: {
        grid: {
          yaxis: {
            lines: {
              show: true
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          },

        },
        annotations: this.weather ? annotation : {},
        chart: {
          height: 400,
          type: "line",
          toolbar: {
            show: false
          },
        },
        // dataLabels: {
        //   enabled: true,
        //   offsetX: 50,
        //   offsetY: '0px',
        // },
        // points: [{
        //   image: {
        //     path: 'https://icons8.com/icon/Pju0fK53pVVb/rainy-weather',
        //     width: 20,
        //     height: 20,
        //     offsetX: 0,
        //     offsetY: 0,
        //   }
        // }],
        stroke: {
          curve: "straight"
        },
        // series: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        // labels: ['10k'], //series.monthDataSeries1.dates,
        xaxis: {
          categories: ['2023-01-01', '2023-01-02', '2023-01-03', '2023-01-04', '2023-01-05', '2023-01-06', '2023-01-07', '2023-01-08', '2023-01-09'],
          labels: {
            show: true,
            style: {
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        yaxis: {
          labels: {
            align: 'left',
            offsetX: -20,
          }
        }
      }
    };
  },
  methods: {
    downloadURI () {
      this.$refs.trendChart.chart.dataURI().then(uri => {
        const pdf = new jsPDF();
        pdf.addImage(uri.imgURI, 'PNG', 0, 0);
        pdf.save("pdf-chart.pdf");
      });
    }

  }
};
