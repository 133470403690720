
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    bgColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    cardClass() {
      const { $style, bgColor, color } = this;
      return [
        $style.card,
        bgColor ? `bg-${bgColor}` : null,
        color ? `text-${color}` : null
      ].filter(Boolean);
    }
  }
};
