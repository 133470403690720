
import Vue from 'vue';
import { props } from '@/components/LandingPage/ButtonDefs';
import Button from '@/components/LandingPage/Button.vue';

export default Vue.component('LoginButton', {
  functional: true,
  props: props,
  render: (h, context) => {
    const $style = (context as any).$style;
    const { props, listeners } = context;
    return h(Button, {
      props,
      attrs: {
        class: $style.button
      },
      on: listeners
    });
  }
});
