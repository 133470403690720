
import { required, email } from 'vuelidate/lib/validators';

import CommonMixin from '@/mixins/CommonMixin';
import { createColumn } from '@/util/table';
import { Pagination } from '@/util/pagination';
import countryCodes from 'country-codes-list';
import StoresCheckbox from '../../Common/StoresCheckbox/StoresCheckbox';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  components: {
    StoresCheckbox
  },
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roles: [],
      selectedStores: null,
      storeData: [],
      columns: [
        createColumn('name', this.$t('SettingsManagement.storeName')),
        createColumn('action', this.$t('SettingsManagement.actions'))
      ],
      callingCodeOptions: [
        {
          label: '+81',
          value: '+81'
        }
      ]
    };
  },
  computed: {
    countryCodesList() {
      return (
        Object.keys(
          countryCodes.customList(
            'countryCallingCode',
            '[{countryCallingCode}]'
          )
        ).map(v => ({ label: '+' + v, value: '+' + v })) || []
      );
    }
  },
  async created() {
    const pagination = new Pagination();
    pagination.size = 1000;
    const {
      response: { roles }
    } = await this.$repo.role.getRolesSSO();
    this.roles = roles.map(role => {
      if (role.title.toUpperCase() == 'FlowTenantAdmin'.toUpperCase()) {
        role.title = this.$t('SettingsManagement.Administrativerole');
      }
      return {
        label: role.title,
        value: role._id
      };
    });
    //    var splitString ='';
    //    if( this.value.phoneNumber != null)
    //    splitString= this.value.phoneNumber.split(' ');
    //    this.value.phone.phoneNumber = splitString[1];
  },
  methods: {
    isValidEmail(val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val);
    },
    setStoresList(val) {
      this.value.stores = val;
      this.storeData = val;
    },
    updateField(value) {
      const copy = { ...this.value, ...value };
      this.$emit('input', copy);
    },
    updatePhoneField(value) {
      const copy = {
        ...this.value,
        ...{
          ...this.value.phone,
          ...value
        }
      };
      this.$emit('input', copy);
    },
    validate() {
      return this.$v.value.$invalid;
    },
    sync() {
      this.$v.value.$touch();
    },
    removeStore(index) {
      this.value.stores.splice(index, 1);
    }
  },
  validations: {
    value: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      username: {
        required
      },
      email: {
        required,
        email
      },
      roleId: {
        required
      },
      phone: {
        phoneCountryCode: {
          required
        },
        phoneNumber: {
          required
        }
      },
      stores: {
        required
      }
    }
  }
};
