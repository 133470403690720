import config from '@/config';
import store from '@/store';
import { ClientManager } from './client';
import { addSign } from '@/util/replaceFidaKpi';

export const kpis: string[] =
[
  'sls',
  'aip',
  'upt',
  'tra',
  'ap',
  'cn',
  'trn',
  'trf',

  'pb',
  'spt',
  'tpt',
  'cr',
  'dw',

  'is',
  'sh',

  'rov',
  'sl',
  'cs',
  'se'
];

// export const kpis: string[] =
// [
//   'sales',
//   'avgitemprice',
//   'upt',
//   'targetAchive',
//   'avgpurchase',
//   'conversion',
//   'transactions',
//   'traffic',
//   'passersby',
//   'salespersqm',
//   'trafficpersqm',
//   'capturerate',
//   'storedwelltime',
//   'engagementrate',
//   'bouncerate',
//   // 'facadestops',
//   'stafflevel',
//   'staffhours',
//   'closingstrength',
//   'staffefficiency',
//   'rov',
//   'maxoccupancy',
//   'minoccupancy',
//   'avgoccupancy',
//   'avgoccupancy%',
//   'numberofalerts',
//   'numberofrequests',
//   'requesttakenperalert',
//   'timebeforetakingrequest',
//   'trafficinline',
//   'avgusersinline',
//   'avgwaitingtime'
// ];

const clientManager = new ClientManager(config);
const client = clientManager.ssoClient;
const client2 = clientManager.ssoClient2;
const sso = clientManager.sso;
export function SSOPostRequest(url, data = {}, config = {}) {
  return client.post(url, data, config);
}
export function SSOPostRequest2(url, data = {}, config = {}) {
  return client2.post(url, data, config);
}
export function SSOPutRequest2(url, data = {}, config = {}) {
  return client2.put(url, data, config);
}

export function SSOGetRequest2(url, config = {}) {
  return client2.get(url, config);
}
// sso
export function SSOGetRequest(url, config = {}) {
  return sso.get(url, config);
}
export function SSOPostReq(url, data = {}, config = {}) {
  return sso.post(url, data, config);
}
export function SSOPutRequest(url, data = {}, config = {}) {
  return sso.put(url, data, config);
}
export function SSODeleteRequest(url, data = {}) {
  return sso.delete(url, data);
}
export function SSODeleteRequest2(url, data = {}, config={}) {
  return client2.delete(url, data, );
}

const conver = {
  "trf" : "traffic",
  "tra" : "targetachievementrate",
  "pb" : "passersby",
  "dw" : "storedwelltime",
  "sls" : "sales",
  "trn" : "transactions",
  "is" : "unitssold",
  "sh" : "staffhours",
  "cn" : "conversion",
  "cr" : "capturerate",
  "ap" : "avgpurchase",
  "aip" : "avgitemprice",
  "spt" : "salespersqm",
  "tpt" : "trafficpersqm",
  "upt" : "upt",
  "rov" : "rov",
  "sl" : "stafflevel",
  "cs" : "closingstrength",
  "se" : "staffefficiency",
}

export const addKpiCustomization = function (kpiData) {
  const storeState: any = store.state;
  let kpisList = Object.entries(kpiData).map(([kpiKey, kpiValue]: any) => {
    const kpi = storeState.user?.kpiPreferences.find(
      k => k.metricKey === kpiKey
    );
    // console.log(kpi)
    kpiValue.title = (kpi?.title ?? kpiValue.metricKeyShort).split(" ").map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(" ");
    kpiValue.metricKey = kpi?.metricKey ?? kpiKey;
    kpiValue.explanation = kpi?.explanation;
    kpiValue.videoUrl = kpi?.videoUrl;
    kpiValue.order = kpi?.order;
    kpiValue.visibility = kpi?.visibility;
    if(kpi?.metricKey == 'spt')
    {
     let actualValue = Number(kpiValue.value.toString().replace(/[¥円,]/g, '')).toLocaleString();
     let compareValue = Number(kpiValue.comparedValue.toString().replace(/[¥円,]/g, '')).toLocaleString();

      kpiValue.value = addSign(kpi.metricKey,actualValue.toString());
      kpiValue.comparedValue = addSign(kpi.metricKey,compareValue.toString());
    }
    // console.log(kpi?.title || kpiKey, kpi?.order, kpi?.visibility, JSON.parse(JSON.stringify(kpi || {})))
    
    return kpiValue;
  });
  let t:any = kpisList.filter((itm) => itm.metricKey == "passersby" );

  if(t.length) {
    t = t[0]
    kpisList.forEach((itm)=> {
      if(itm.order > t.order) {
        itm.order += 1
      }
      if(itm.metricKey == "unitsold" && !itm.order) {
        itm.order = t.order + 1;
      }
    })
  }

  var urlPage = window.location.pathname;
  if(urlPage  != null  && urlPage !="" && urlPage =="/detailed-report") {
    kpisList = kpisList
    .filter(kpi => {
      return kpi;
    })
    .filter(kpi => {
      return kpi.visibility === 1;
    })
  } else{
    kpisList = kpisList
    .filter(kpi => {
      return kpi;
    })
    .filter(kpi => {
      return kpi.visibility === 1;
    })
    .sort((aO, bO) => {
      let a = aO.order;
      let b= bO.order
      if (a === undefined && b === undefined) {
        return 0;
      } else if (a === undefined) {
        return 1;
      } else if (b === undefined) {
        return -1;
      } else {
        return a - b;
      }
    });
  }

  return kpisList;
};
// export const filterAvailableKpis = function(filterKpis) {
//   const storeState: any = store.state;
//   let kpiOptions = storeState.user.kpiPreferences
//     .filter(kpi => filterKpis.includes(kpi.metricKey))
//     .map(k => k.metricKey);
//   return kpiOptions;
// };

export const filterAvailableKpis = function (filterKpis) {
  const storeState: any = store.state;
  let kpiOptions = [];
  if (storeState?.user?.kpiList?.length != null) {
    kpiOptions = storeState.user.kpiList
      .filter(kpi => filterKpis.includes(kpi.metricKey))
      .map(k => k.metricKey);
  }
  return kpiOptions;
};

export function SSOGetRequestFida(url, config = {}) {
  return client2.get(url, config);
}

export const getKpiKeyByName = function (inputValue) {
  const storeState: any = store.state;
  const kpiList = storeState.user.kpiLabelValue;

  var matchingObject = kpiList.find(obj => obj.value === inputValue);
  if(!matchingObject)
    matchingObject = kpiList.find(obj => obj.label === inputValue);

  if (matchingObject) {
      return matchingObject;
  } else {
      // If no match is found, you can return a default object or handle it based on your requirement.
      return {
          label: "Not Found",
          value: inputValue
      };
  }

};
