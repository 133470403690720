import {
  APIFetchDemographicsByHour,
  APIFetchDemographicsByPeriod,
  APIFetchDemographicsByStore,
  APIFetchDemographicsByWeek,
  APIFetchDemographicsDistributionByGroup,
  APIFetchDemographicsFidaAll
} from '@/api/demographics';
import store from '@/store/';
import {getStoreMinAndMaxHourForDatesAndStores, getDateRange} from '@/util/utilFidaFunctions';

import { groupNamesList } from '@/util/mockData/groupName';
interface IInitialState {
  kpiMetrics: any[];
  demoGroup: any[];
  demoGender: any[];
  demoHourly: any[];
  demoPeriod: any[];
  demoWeekly: any[];
  demoPeriodValue: any[];
  demoGenderValue: any[];
  demoGroupValue: any[];
  groupMetricKey: any;
}

const initialState: IInitialState = {
  kpiMetrics: [],
  demoGroup: [],
  demoGender: [],
  demoHourly: [],
  demoWeekly: [],
  demoPeriod: [],
  demoPeriodValue: [],
  demoGenderValue: [],
  demoGroupValue: [],
  groupMetricKey: false
};

export default {
  state: initialState, // state
  mutations: {
    setDemographicsByPeriod(state, payload) {
      state.demoPeriod = payload;
    },
    setDemographicsByPeriodValue(state, payload) {
      state.demoPeriodValue = payload;
    },
    //
    setDemographicsByWeek(state, payload) {
      state.demoWeekly = payload;
    },
    setDemographicsByHour(state, payload) {
      state.demoHourly = payload;
    },
    setDemographicsDistributionByGroup(state, payload) {
      state.demoGroup = payload;
    },
    
    setDemographicsDistributionByGroupValue(state, payload) {
      state.demoGroupValue = payload;
    },
    //
    setDemographicsByGender(state, payload) {
      state.demoGender = payload;
    },
    setDemographicsByGenderValue(state, payload) {
      state.demoGenderValue = payload;
    },
    setDemographicsByStore(state, payload) {
      state.kpiMetrics = payload;
    }
  }, // mutations
  actions: {
    //APIFetchDemographicsFidaAll for fida
    async fetchDemographicsFidaAll(
      { commit },
      { storeCodes, startDate, endDate, type, frequency = 'daily' }
    )
    {
      if(type === 'weekly')
      {
        frequency = 'daily';
      }

      let response = await APIFetchDemographicsFidaAll({
        storeCodes,
        startDate,
        endDate,
        frequency,
        typeD:'byPeriod'
      });

      var finalResponseGroup = groupNamesList.map((group) => {
        const groupId = group.DGGroupID;
        const groupName = group.DGGroup_JP;
        const groupEN = group.DGGroup_EN;
        const groupColor = group.DGGroupDisplayColor;
        const count = response.reduce((sum, item) => {
          const ageGroupKey = `ageGroup_${groupId}`;
          return sum + Number(item[ageGroupKey]);
        }, 0);
        return {
          group: groupName,
          groupEN: groupEN,
          count: count,
          color: groupColor,
        };
      });

      //
      var finalResponseGroupValue = groupNamesList.map((group) => {
        const groupId = group.DGGroupID;
        const groupName = group.DGGroup_JP;
        const groupEN = group.DGGroup_EN;
        const groupColor = group.DGGroupDisplayColor;
        const count = response.reduce((sum, item) => {

          const ageGroupKey = `ageGroup_${groupId}_total`;
          const value = item.hasOwnProperty(ageGroupKey) ? Number(item[ageGroupKey]) : 0;
          return sum + (isNaN(value) ? 0 : value);
        }, 0);
        return {
          group: groupName,
          groupEN: groupEN,
          count: count,
          color: groupColor,
        };
      });
      console.log('finalResponseGroupValue',finalResponseGroupValue);

      var finalResponseByGender = [
        {
          count: response.reduce((sum, item) => {
            let count = 0;
            groupNamesList.slice(0, 4).forEach((group) => {
              const ageGroupKey = `ageGroup_${group.DGGroupID}`;
              count += Number(item[ageGroupKey]);
            });
            return sum + count;
          }, 0),
          color: "#007FBA",
          genderEN: "Male",
          gender: "男性"
        },
        {
          count: response.reduce((sum, item) => {
            let count = 0;
            groupNamesList.slice(4, 8).forEach((group) => {
              const ageGroupKey = `ageGroup_${group.DGGroupID}`;
              count += Number(item[ageGroupKey]);
            });
            return sum + count;
          }, 0),
          color: "#cc5a93",
          genderEN: "Female",
          gender: "女性",
          genderJP: "女性"
        },
      ];

      var finalResponseByGenderValue =[{
        count: response.reduce((sum, item) =>
           {
          let count = 0;
          groupNamesList.slice(0, 4).forEach((group) => {
            
            // const ageGroupKey = `ageGroup_${group.DGGroupID}_total`;
            // count += Number(item[ageGroupKey]);
            const ageGroupKey = `ageGroup_${group.DGGroupID}_total`;
            count += item.hasOwnProperty(ageGroupKey) && !isNaN(Number(item[ageGroupKey])) 
              ? Number(item[ageGroupKey]) 
              : 0;

          });
          return sum + count;
        }, 0),
        color: "#007FBA",
        genderEN: "Male",
        gender: "男性"
      },
      {
        count: response.reduce((sum, item) => {
          let count = 0;
          groupNamesList.slice(4, 8).forEach((group) => {
            const ageGroupKey = `ageGroup_${group.DGGroupID}_total`;
            count += item.hasOwnProperty(ageGroupKey) && !isNaN(Number(item[ageGroupKey])) 
              ? Number(item[ageGroupKey]) 
              : 0;


            // const ageGroupKey = `ageGroup_${group.DGGroupID}_total`;
            // count += Number(item[ageGroupKey]);
            
          });
          return sum + count;
        }, 0),
        color: "#cc5a93",
        genderEN: "Female",
        gender: "女性",
        genderJP: "女性"
      },
    ];
      // By Period
      const finalResponse2: Record<string, Array<Record<string, any>>> = {};
      response.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== '_id') {
            const groupId = Number(key.replace('ageGroup_', ''));
            const groupNameObj = groupNamesList.find((group) => group.DGGroupID === groupId);
            if (groupNameObj) {
              const groupKey = groupNameObj.DGGroup;
              const groupValue = finalResponse2[groupKey] || [];
              const sum = item[key]; // calculate average count

      
                groupValue.push({
                  demographicsGroup: groupNameObj.DGGroup_JP,
                  demographicsGroupEN: groupNameObj.DGGroup_EN,
                  count: sum,
                  timelineLabel: item._id.dayName,
                  color: groupNameObj.DGGroupDisplayColor,
                });
              finalResponse2[groupKey] = groupValue;
            }
          }
        });
      });

      const finalResponse2Value: Record<string, Array<Record<string, any>>> = {};
      
      response.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== '_id') {
            const groupId = Number(key.replace(/^ageGroup_(\d+)_.*$/, '$1'));

            const groupNameObj = groupNamesList.find((group) => group.DGGroupID === groupId);
   
            if (groupNameObj) {
              const groupKey = groupNameObj.DGGroup;
              const groupValue = finalResponse2Value[groupKey] || [];
              const sum = item[key]; // calculate average count

      
                groupValue.push({
                  demographicsGroup: groupNameObj.DGGroup_JP,
                  demographicsGroupEN: groupNameObj.DGGroup_EN,
                  count: sum,
                  timelineLabel: item._id.dayName,
                  color: groupNameObj.DGGroupDisplayColor,
                });
                finalResponse2Value[groupKey] = groupValue;
            }
          }
        });
      });

      var desiredResponseValue;
      desiredResponseValue = getMissingDataByWeek(finalResponse2Value);

      var desiredResponse;
      desiredResponse = getMissingDataByWeek(finalResponse2);

    console.log('desiredResponseValue',desiredResponseValue);
    console.log('desiredResponse',desiredResponse);



        // End By Period
      commit('setDemographicsDistributionByGroup', finalResponseGroup);
      commit('setDemographicsDistributionByGroupValue', finalResponseGroupValue);
      //demoGroupValue
      commit('setDemographicsByGender', finalResponseByGender);
      commit('setDemographicsByGenderValue', finalResponseByGenderValue);

      commit('setDemographicsByPeriod', desiredResponse);
      
      commit('setDemographicsByPeriodValue', desiredResponseValue);
      
      // commit('setDemographicsByPeriod', res);
    },
    async fetchDemographicsByPeriod(
      { commit },
      { storeCodes, startDate, endDate, type, frequency = 'hourly' }
    ) {

        if(type === 'weekly')
        {
          frequency = 'daily';
        }

        let response = await APIFetchDemographicsFidaAll({
          storeCodes,
          startDate,
          endDate,
          frequency,
          typeD:'byPeriod'
        });
        if(frequency == 'hourly')
        {
          const storeState: any = store.state;
          const uniqueDates = getDateRange(startDate, endDate);
          let storeCodes = storeState.store.storesCodeNameList.map(store => store.storeCode);
          const res:any = getStoreMinAndMaxHourForDatesAndStores(uniqueDates, storeCodes);
          response = fillMissingHours(response, res.minOpening, res.maxClosing);
        }
        //  //   const groupId = Number(key.replace(/^ageGroup_(\d+)_.*$/, '$1'));
        const finalResponse2: Record<string, Array<Record<string, any>>> = {};
        response.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key !== '_id') {
              const groupId = Number(key.replace('ageGroup_', ''));
              const groupNameObj = groupNamesList.find((group) => group.DGGroupID === groupId);
              if (groupNameObj) {
                const groupKey = groupNameObj.DGGroup;
                const groupValue = finalResponse2[groupKey] || [];
                const sum = item[key]; // calculate average count

                if(frequency == 'daily')
                {
                  groupValue.push({
                    demographicsGroup: groupNameObj.DGGroup_JP,
                    demographicsGroupEN: groupNameObj.DGGroup_EN,
                    count: sum,
                    timelineLabel: item._id.dayName,
                    color: groupNameObj.DGGroupDisplayColor,
                  });
                }
                else//hourly
                {
                  groupValue.push({
                    demographicsGroup: groupNameObj.DGGroup_JP,
                    demographicsGroupEN: groupNameObj.DGGroup_EN,
                    count: sum,
                    timelineLabel: ''+item._id['hour']+':00',
                    color: groupNameObj.DGGroupDisplayColor,
                  });
                }
                finalResponse2[groupKey] = groupValue;
              }
            }
          });
        });

        var desiredResponse
        if(frequency == 'daily') {
          desiredResponse = getMissingDataByWeek(finalResponse2);
        }
        else {
          desiredResponse = getHourlySortedData(finalResponse2);
        }

        
        const finalResponse2Value: Record<string, Array<Record<string, any>>> = {};
        response.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key !== '_id') {
              const groupId = Number(key.replace(/^ageGroup_(\d+)_.*$/, '$1'));
              const groupNameObj = groupNamesList.find((group) => group.DGGroupID === groupId);
              if (groupNameObj) {
                const groupKey = groupNameObj.DGGroup;
                const groupValue = finalResponse2Value[groupKey] || [];
                const sum = item[key]; // calculate average count

                if(frequency == 'daily')
                {
                  groupValue.push({
                    demographicsGroup: groupNameObj.DGGroup_JP,
                    demographicsGroupEN: groupNameObj.DGGroup_EN,
                    count: sum,
                    timelineLabel: item._id.dayName,
                    color: groupNameObj.DGGroupDisplayColor,
                  });
                }
                else//hourly
                {
                  groupValue.push({
                    demographicsGroup: groupNameObj.DGGroup_JP,
                    demographicsGroupEN: groupNameObj.DGGroup_EN,
                    count: sum,
                    timelineLabel: ''+item._id['hour']+':00',
                    color: groupNameObj.DGGroupDisplayColor,
                  });
                }
                finalResponse2Value[groupKey] = groupValue;
              }
            }
          });
        });

        var desiredResponseValue
        if(frequency == 'daily') {
          desiredResponseValue = getMissingDataByWeek(finalResponse2Value);
        }
        else {
          desiredResponseValue = getHourlySortedData(finalResponse2Value);
        }
    
      commit('setDemographicsByPeriodValue', desiredResponseValue);
      
        commit('setDemographicsByPeriod', desiredResponse);

    },
    //Seems like no use
    async fetchDemographicsByWeek(
      { commit },
      { storeCodes, startDate, endDate }
    ) {
      let res = await APIFetchDemographicsByWeek({
        storeCodes,
        startDate,
        endDate
      });
    },
    //Seems like no use
    async fetchDemographicsByHour(
      { commit },
      { storeCodes, startDate, endDate }
    ) {
      let res = await APIFetchDemographicsByHour({
        storeCodes,
        startDate,
        endDate
      });
    },

    async fetchDemographicsByStore(
      { commit },
      { storeCodes, startDate, endDate, kpis, daysOfWeek, groupByMetricKey }
    ) {
      let res = await APIFetchDemographicsByStore({
        storeCodes,
        startDate,
        endDate,
        kpis,
        daysOfWeek,
        groupByMetricKey
      });
    }
  }
}; // export default

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function getMissingDataByWeek(originalData: Record<string, any[]>) {
  const response: Record<string, any[]> = {};

 for (const [key, value] of Object.entries(originalData)) {

      const newData = [];

      for (const day of daysOfWeek) {
          const existingEntry = value.find((item: any) => item.timelineLabel === day);
          const newEntry = {
              "demographicsGroup": existingEntry ? existingEntry.demographicsGroup : key,
              "demographicsGroupEN": existingEntry ? existingEntry.demographicsGroupEN : key,
              "count": existingEntry ? existingEntry.count : 0,
              "timelineLabel": day,
              "color": existingEntry ? existingEntry.color : ""
          };
          newData.push(newEntry);
      }
      response[key] = newData;
  }
  return response;
}

function getHourlySortedData(apiResponse) {
  // Iterate over each demographics group and sort its data by timelineLabel
  for (const demographicsGroup in apiResponse) {
    if (apiResponse.hasOwnProperty(demographicsGroup)) {
      apiResponse[demographicsGroup].sort((a, b) => {
        // Convert timelineLabel to a comparable format, e.g., "13:00" -> 1300
        const timeA = parseInt(a.timelineLabel.replace(':', ''));
        const timeB = parseInt(b.timelineLabel.replace(':', ''));

        return timeA - timeB;
      });
    }
  }

  // Return the sorted API response
  return apiResponse;
}

function fillMissingHours(data, minHour, maxHour) {
const filledData = [];

for (let hour = minHour; hour <= maxHour; hour++) {
  const foundData = data.find(entry => entry._id.hour === hour);

  if (foundData) {
    filledData.push(foundData);
  } else {
    filledData.push({
      _id: { hour },
      ageGroup_1: 0,
      ageGroup_2: 0,
      ageGroup_3: 0,
      ageGroup_4: 0,
      ageGroup_5: 0,
      ageGroup_6: 0,
      ageGroup_7: 0,
      ageGroup_8: 0
    });
  }
}

return filledData;
}