import { convertFromCustomerDTO } from '@/models/formatters/CustomerFormatter';
import CustomerController from '@/services/management/CustomerController';
import { map } from 'lodash';

export default class CustomerRepository {
  private readonly customerController: CustomerController;

  constructor(customerController: CustomerController) {
    this.customerController = customerController;
  }

  public async getCustomers() {
    const { content } = await this.customerController.getCustomers();
    return {
      customers: map(content, dto => convertFromCustomerDTO(dto))
    };
  }
}
