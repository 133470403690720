import { PaginatedResponse } from '@/models/Pagination';
import { PeerGroupDTO } from '@/models/PeerGroup';
import { StoreDTO } from '@/models/Store';
import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { AxiosInstance } from 'axios';

export type GetPeerGroupsResponse = PaginatedResponse<PeerGroupDTO>;
export type GetPeerGroupResponse = PeerGroupDTO;

export interface CreatePeerGroupRequest {
  name: string;
  parent?: string;
  storeIds: string[];
}
export interface UpdatePeerGroupRequest {
  name: string;
  parent?: string;
  peergroupid: Number;
  storeIds: string[];
}

export interface CreatePeerGroupResponse {
  id: string;
  name: string;
  parentId: string;
  stores: StoreDTO[];
}

export default class PeerGroupController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getPeerGroups() {
    try {
      const { data } = await this.client.get('/peer-group', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetPeerGroupsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPeerGroup(id: string) {
    try {
      const { data } = await this.client.get(`/peer-group/${id}`);
      return data as GetPeerGroupResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createPeerGroup(req: CreatePeerGroupRequest) {
    try {
      const { data } = await this.client.post('/peer-group', req);
      return data as CreatePeerGroupResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updatePeerGroup(id: string, req: UpdatePeerGroupRequest) {
    try {
      const { data } = await this.client.put(`/peer-group/${id}`, req);
      return data as CreatePeerGroupResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deletePeerGroup(id: string, msg: string) {
    return this.deletePeerGroups([id], msg);
  }

  public async deletePeerGroups(ids: string[], msg: string) {
    try {
      const data = await this.client.delete('/peer-group', {
        data: ids
      });
      data.statusText = msg;
      createBulkDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
