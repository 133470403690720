import * as XLSX from 'xlsx';
import { getNameByStoreCode } from '@/util/utilFunctions';
import {
  getStoreTimings,
  formatDate,
  formatTime,
  getListOfDates,
  getStoreMinAndMaxHourForDatesAndStores,
  formatIntDate,
  getEntranceTitle
} from '@/util/utilFidaFunctions';

import { max } from 'lodash';
import store from '@/store';
import store2 from '@/store/modules/traffic';

export async function downloadTrafficReportFida(requestBody) {
  const fileFormat = requestBody.fileFormat;
  delete requestBody.fileFormat;

  const apiResponse1: any = store2.state.trafficInList;
  const apiResponse2: any = store2.state.trafficOutList;

  const apiResponse = [
    ...apiResponse1.trafficInList,
    ...apiResponse2.trafficOutList
  ];
  var resultArray;
  // Grouping and aggregating the data
  if (apiResponse && apiResponse.length > 0) {
    var groupedData;

    if (requestBody.frequency == 'Daily') {
      groupedData = apiResponse.reduce((result, kpi) => {
        // const key = `${formatDate(kpi.startTimestamp)}_${kpi.storeCode}`;
        let i = 0;
        
        for (const key2 in kpi.trf) {
          const entranceID = key2;
          const date1 = kpi._id.split('_')[0];
          const storeCode = kpi._id.split('_')[3];
          const key = `${date1}_${storeCode}__${entranceID}`;
          if (!result[key]) {
            result[key] = {
              date: formatIntDate(date1),
              storeCode: storeCode,
              storeName: getNameByStoreCode(storeCode),
              entrance: getEntranceTitle(storeCode, entranceID),//entranceID,
              inCount: 0,
              outCount: 0
            };
          }
          // result[key]['entrance'] = key2;
          // Check if the current key has the "out" property
          if (kpi.trf[key2].hasOwnProperty('out'))
            result[key].outCount += kpi.trf[key2].out;
          else if (kpi.trf[key2].hasOwnProperty('in'))
            // Add the out value to the sum
            result[key].inCount += kpi.trf[key2].in;
          // }
        }

        return result;
      }, {});
      resultArray = Object.values(groupedData);

      resultArray.sort((a, b) => {
        const dateComparison = a.date.localeCompare(b.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return a.storeCode - b.storeCode;
      });
    } else {
      groupedData = apiResponse.reduce((result, kpi) => {
        const formatedDate = formatIntDate(kpi._id.split('_')[0]);
        const hour = kpi._id.split('_')[1];
        const storeCode = kpi._id.split('_')[3];
      
        for (const key2 in kpi.trf) {
          const entranceID = key2;
          const key = `${formatedDate}_${storeCode}_${entranceID}_${hour}`;
      
          if (!result[key]) {
            result[key] = {
              date: formatedDate,
              hour,
              entrance: getEntranceTitle(storeCode, entranceID),
              storeCode: storeCode,
              storeName: getNameByStoreCode(storeCode),
              inCount: 0,
              outCount: 0
            };
          }
      
          if (kpi.trf[key2].hasOwnProperty('out'))
            result[key].outCount += kpi.trf[key2].out;
          if (kpi.trf[key2].hasOwnProperty('in'))
            result[key].inCount += kpi.trf[key2].in;
        }
      
        return result;
      }, {});
      
      const datesList = getListOfDates(requestBody.startDate, requestBody.endDate);
      const storeCodesList = requestBody.storeCodes;
      const { minOpening, maxClosing } = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
      
      const allHours = Array.from({ length: maxClosing - minOpening + 1 }, (_, i) => (minOpening + i).toString().padStart(2, '0'));
      
      // Create a new object to avoid directly modifying groupedData
      let completeGroupedData = {};
      
      Object.keys(groupedData).forEach(key => {
        const entry = groupedData[key];
        const { date, storeCode, entrance, hour } = entry;
      
        const entryKey = `${date}_${storeCode}_${entrance}_${hour}`;
      
        if (!completeGroupedData[entryKey]) {
          completeGroupedData[entryKey] = {
            date,
            hour,
            entrance,
            storeCode,
            storeName: entry.storeName,
            inCount: 0,
            outCount: 0
          };
        }
      
        // Aggregate counts from groupedData[key]
        completeGroupedData[entryKey].inCount += entry.inCount;
        completeGroupedData[entryKey].outCount += entry.outCount;
      });
      
      
      resultArray = Object.values(completeGroupedData);
      resultArray.sort((a, b) => {
        const storeCodeComparison = a.storeCode.localeCompare(b.storeCode);
        if (storeCodeComparison !== 0) return storeCodeComparison;
      
        const dateComparison = a.date.localeCompare(b.date);
        if (dateComparison !== 0) return dateComparison;
      
        const entranceComparison = a.entrance.localeCompare(b.entrance);
        if (entranceComparison !== 0) return entranceComparison;
      
        return parseInt(a.hour) - parseInt(b.hour);
      });
    }

    // Converting the grouped data to an array

    //entrance
    const dateForTitle =
      formatDate(requestBody.startDate) + '-' + formatDate(requestBody.endDate);
    if (fileFormat == 'CSV') {
      downloadCSV(resultArray, requestBody.fileType, dateForTitle);
    } else if (fileFormat == 'Excel') {
      downloadExcel(resultArray, requestBody.fileType, dateForTitle);
    } else {
      console.error('Unsupported file format specified.');
    }
  }
  return;
}

function formatDateTime(dateString) {
  const result = dateString.split(':')[0];
  return result;
}

function downloadCSV(data, typetime, dateForTitle) {
  // const csvData = convertToCSV(data);
  var headers;
  var csvData1;
  const acceptedLanguage =
    store.getters['user/getLocale'] === 'en' ? 'en' : 'ja';
  if (typetime == 'Daily') {
    if (acceptedLanguage == 'ja') {
      const header = [
        '店コード',
        '店名',
        '日付',
        '入口',
        'INカウント',
        'OUTカウント'
      ];
      headers = header.join(',') + '\n';
    } else headers = 'Store Code,Store Name,Date,Entrance,In Count,Out Count\n';
    // headers = ['Date', 'Store Code','Store Name', 'In Count', 'Out Count'];

    csvData1 = data
      .map(
        entry =>
          `${entry.storeCode},${entry.storeName},${entry.date},${entry.entrance},${entry.inCount},${entry.outCount}`
      )
      .join('\n');
  } else {
    //店コード
    if (acceptedLanguage == 'ja')
      headers = '店コード,店名,日付,時間,入口,INカウント,OUTカウント\n';
    else
      headers = 'Store Code,Store Name,Date,Hour,Entrance,In Count,Out Count\n';

    csvData1 = data
      .map(
        entry =>
          `${entry.storeCode},${entry.storeName},${entry.date},${entry.hour},${entry.entrance},${entry.inCount},${entry.outCount}`
      )
      .join('\n');
  }
  // return headers + csvData1;
  const csvData = headers + csvData1;

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
 const url = URL.createObjectURL(blob);
  var csvContent = '\uFEFF' + csvData; // BOM character for Excel compatibility
  // var encodedUri = encodeURI(csvContent);
  link.href = url;
  link.download = 'Traffic Report-Export - ' + dateForTitle + '.csv';
  // link.href = url + encodedUri;
  //link.target = '_blank';

  // link.href = window.URL.createObjectURL(blob);
  // link.download = 'Traffic Report-Export - ' + dateForTitle + '.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadExcel(resultArray, typetime, dateForTitle) {
  // Convert array of objects to array of arrays

  var dataArray, headers, sheetData;

  if (typetime == 'Daily') {
    dataArray = resultArray.map(entry => [
      entry.storeCode,
      entry.storeName,
      entry.date,
      entry.entrance,
      entry.inCount,
      entry.outCount
    ]);
    const acceptedLanguage =
      store.getters['user/getLocale'] === 'en' ? 'en' : 'ja';

    if (acceptedLanguage == 'ja')
      headers = [
        '店コード',
        '店名',
        '日付',
        '入口',
        'INカウント',
        'OUTカウント'
      ];
    else
      headers = [
        'Store Code',
        'Store Name',
        'Date',
        'Entrance',
        'In Count',
        'Out Count'
      ];

    sheetData = [headers, ...dataArray];
  } //hourly
  else {
    dataArray = resultArray.map(entry => [
      entry.storeCode,
      entry.storeName,
      entry.date,
      entry.hour,
      entry.entrance,
      entry.inCount,
      entry.outCount
    ]);

    const acceptedLanguage =
      store.getters['user/getLocale'] === 'en' ? 'en' : 'ja';

    if (acceptedLanguage == 'ja')
      headers = [
        '店コード',
        '店名',
        '日付',
        '時間',
        '入口',
        'INカウント',
        'OUTカウント'
      ];
    else
      headers = [
        'Store Code',
        'Store Name',
        'Date',
        'Hour',
        'Entrance',
        'In Count',
        'Out Count'
      ];

    sheetData = [headers, ...dataArray];
  }

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(
    workbook,
    XLSX.utils.aoa_to_sheet(sheetData),
    'Sheet1'
  );

  const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  const excelData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const excelUrl = URL.createObjectURL(excelData);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = excelUrl;
  //link.download = 'Traffic Report-Export - '+ dateForTitle+'.csv';
  link.download = 'Traffic Report-Export - ' + dateForTitle + '.xlsx';
  link.click();
}
