
import {
  formatCurrency,
  getCurrencySymbol
} from '@/models/formatters/CurrencyFormatter';
import { generateImageDataUrl } from '@/util/file';
import Sensor from '@/models/Sensor';

export default {
  props: {
    orders: { type: Array, required: true }
  },
  data() {
    return {
      formatCurrency: formatCurrency,
      getCurrencySymbol: getCurrencySymbol
    };
  },
  methods: {
    fetcher(sensor: Sensor) {
      return async () => await this.$repo.sensor.getSensorImageAsBase64(sensor);
    },
    formatter(input, url) {
      return generateImageDataUrl(input, url);
    }
  }
};
