
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';

import { mapActions, mapState } from 'vuex';
export default {
  components: { BoxHeader, LoadingSpinner },
  data() {
    return {
      loading: true,
      slide: 0
    };
  }, // data
  computed: {
    ...mapState('prediction', ['expectations'])
  },
  watch: {
    expectations() {
      this.loading = false;
    }
  }
};
