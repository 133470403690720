import config from '@/config';
import Config from '@/config/config';
import Session from '@/models/Session';
import router from '@/router';
import store from '@/store';
import axios, { AxiosInstance } from 'axios';
import { handleErrorUI } from '@/util/error';
import Vue from 'vue';


function genericSuccess(response) {
  return response;
}
async function goToLogin() {
  console.log("movingToLogin", (router as any)?.history?.current?.name,(router as any)?.history?.current?.path, (router as any)?.history)
  if((router as any)?.history?.current?.name != "auth:login") {
    await store.dispatch('user/clearSession');
    await localStorage.clear();
    await store.dispatch('tenant/reset');
    router.push({ name: 'auth:login' }).catch(_ => { });
  } else {
    console.log("re-Routing Denied  ")
  }
}

function canReject(error) {   
  console.log(error)   
  if(error.response && error.response.status === 401) {
    return (router as any)?.history?.current?.name != "auth:login"
  } else {
    return true;
  }
}

async function genericError(error) {
  if (error.response) {
    if ([400, 402, 403, 409].includes(error.response.status)) {
      handleErrorUI(error.response);
    }
    if (error.response.status === 401) {
      await goToLogin();
    }
  }
  let { profile } = store.state["user"];
  Vue.prototype?.$mixpanel?.track('webapp_api_error', {
    version: localStorage.getItem("App_Version"),
    email: profile?.email,
    response: error?.response,
    url: error?.request?.responseURL
  });
  
  if(canReject(error)) {
    return Promise.reject(error);
  } else {
    return Promise.reject();
  }
}
export class ClientManager {
  public readonly testingClient: AxiosInstance;
  public readonly testingClient2: AxiosInstance;

  public readonly coreClient: AxiosInstance;
  public readonly devCoreClient: AxiosInstance;
  public readonly apiGatewayClient: AxiosInstance;
  public readonly consoleClient: AxiosInstance;
  public readonly testingServerClient: AxiosInstance;
  public readonly ssoClient: AxiosInstance;
  public readonly ssoClient2: AxiosInstance;
  public readonly eddyClient: AxiosInstance;
  public readonly sso: AxiosInstance;
  public readonly fidaExportUrl: AxiosInstance;

  constructor(config: Config) {
    // var ssoUrl = localStorage.getItem('sso');
    // alert(ssoUrl);
    this.testingClient = axios.create({
      baseURL: config.reportingURL
    });
    this.fidaExportUrl = axios.create({
      baseURL: config.flowgo2Url//'http://54.248.3.159:5000/api/'
    });
    this.fidaExportUrl.interceptors.request.use(
      function (config) {
        let token = '';
        if (store.getters['tenant/hasResetAccount']) {
          token = store.getters['user/getClientToken'];
        } else {
          token = store.getters['user/getToken'];
        }

        if (token) {
          config.headers = {
            Authorization: `bearer ${token}`,
            'Accept-Language': store.getters['user/getLocale']
              ? store.getters['user/getLocale']
              : 'en-us'
          };
        }
        return config;
      },
      async function (error) {
        
        if (error.response && error.response.status === 401) {
          //  Vue.prototype.$intercom.shutdown();
          await goToLogin()
        }
        if(canReject(error)) {
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
      }
    );

    this.fidaExportUrl.interceptors.response.use(
      function (response) {
        const token = store.getters['user/getSession'];
        return response.data;
      },
      async function (error) {
        if (error.response) {
          if ([400, 402, 403, 409].includes(error.response.status)) {
            handleErrorUI(error.response);
          }
          if (error.response && error.response.status === 401) {
            //  Vue.prototype.$intercom.shutdown();
            await goToLogin()
          }
        }
        if(canReject(error)) {
          return Promise.reject(error?.response?.message || error.response.data.message);
        } else {
          return Promise.reject();
        }
      }
    );
    //
    this.testingClient2 = axios.create({
      baseURL: config.reportingURL
    });
    //flowgo2Url
    this.ssoClient2 = axios.create({
      baseURL: config.flowgo2Url
    });
    
    this.ssoClient2.interceptors.response.use(genericSuccess, genericError);

    this.ssoClient = axios.create({
      baseURL: config.ssoUrl
    });
    this.eddyClient = axios.create({
      baseURL: config.eddyUrl
    });
    this.sso = axios.create({
      baseURL: config.sso
    });
    this.sso.interceptors.response.use(genericSuccess, genericError );

    this.testingClient.interceptors.request.use(
      function (config) {
        let token = '';
        if (store.getters['tenant/hasResetAccount']) {
          token = store.getters['user/getClientToken'];
        } else {
          token = store.getters['user/getToken'];
        }

        if (token) {
          config.headers = {
            Authorization: `bearer ${token}`,
            'Accept-Language': store.getters['user/getLocale']
              ? store.getters['user/getLocale']
              : 'en-us'
          };
        }
        return config;
      },
      async function (error) {
        if (error.response && error.response.status === 401) {
          //  Vue.prototype.$intercom.shutdown();
          await goToLogin()
        }
        if(canReject(error)) {
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
        
      }
    );
    this.testingClient2.interceptors.request.use(
      function (config) {
        let token = '';
        if (store.getters['tenant/hasResetAccount']) {
          token = store.getters['user/getClientToken'];
        } else {
          token = store.getters['user/getToken'];
        }

        if (token) {
          // if(config.url == "kpi/target/sales")
          // {
          //   config.headers = {
          //     Authorization: `bearer ${token}`,
          //     'Accept-Language': store.getters['user/getLocale']
          //       ? 'en'
          //       : 'en'
          //   };
          // }
          // else
          // {
          config.headers = {
            Authorization: `bearer ${token}`,
            'Accept-Language': 'en'
          };
        // }
        }
        return config;
      },
      async function (error) {
        if (error.response && error.response.status === 401) {
          //  Vue.prototype.$intercom.shutdown();
          await goToLogin()
        }
        
        if(canReject(error)) {
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
      }
    );

    this.testingClient2.interceptors.response.use(
      function (response) {
        const token = store.getters['user/getSession'];
        return response.data;
      },
      async function (error) {
        if (error.response) {
          if ([400, 402, 403, 409].includes(error.response.status)) {
            handleErrorUI(error.response);
          }
          if (error.response.status === 401) {
            // Vue.prototype.$intercom.shutdown();
            await goToLogin()
          }
        }
        if(canReject(error)) {
          return Promise.reject(error?.response?.message || error.response.data.message);
        } else {
          return Promise.reject();
        }
      }
    );
    this.testingClient.interceptors.response.use(
      function (response) {
        const token = store.getters['user/getSession'];
        return response.data;
      },
      async function (error) {
        if (error.response) {
          if ([400, 402, 403, 409].includes(error.response.status)) {
            handleErrorUI(error.response);
          }
          if (error.response.status === 401) {
            // Vue.prototype.$intercom.shutdown();
            await goToLogin()
          }
        }

        if(canReject(error)) {
          return Promise.reject(error?.response?.message || error.response.data.message);
        } else {
          return Promise.reject();
        }
      }
    );

    this.coreClient = axios.create({
      baseURL: config.coreURL,
      withCredentials: true
    }); // :8083

    this.coreClient.interceptors.request.use(
      function (config) {
        let token = '';
        if (store.getters['tenant/hasResetAccount']) {
          token = store.getters['user/getClientToken'];
        } else {
          token = store.getters['user/getToken'];
        }

        if (token) {
          config.headers = {
            Authorization: `bearer ${token}`
          };
          config.headers['Accept-Language'] = store.getters['user/getLocale']
            ? store.getters['user/getLocale']
            : 'en-us';
        }
        return config;
      },
      async function (error) {
        // console.log('Here is the error: client.ts -> this.coreClient.interceptors.request.use' );
        // console.log(error);
        if (error.response && error.response.status === 401) {

          // console.log('Here is the error2: client.ts -> this.coreClient.interceptors.request.use' );
          // console.log(error.response);


          Vue.prototype?.$mixpanel?.track('sign out');
          Vue.prototype?.$mixpanel?.reset();
          this.clearSession();
          this.reset();
          Vue.prototype.$intercom.shutdown();

          await goToLogin()


        }
        if(canReject(error)) {
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
      }
    );

    this.devCoreClient = axios.create({
      baseURL: config.coreURL,
      withCredentials: true
    }); // :8083

    this.testingServerClient = axios.create({
      baseURL: config.coreURL,
      withCredentials: true
    });

    this.apiGatewayClient = axios.create({
      baseURL: config.apiGatewayURL,
      withCredentials: true
    }); // :8081

    this.consoleClient = axios.create({
      baseURL: config.apiGatewayURL,
      xsrfCookieName: 'CONSOLE-XSRF-TOKEN',
      xsrfHeaderName: 'X-CONSOLE-XSRF-TOKEN',
      withCredentials: true
    }); // :8090

    [this.apiGatewayClient, this.coreClient, this.consoleClient].forEach(v => {
      v.interceptors.request.use(
        function (config) {
          config.headers = {
            'Accept-Language':
              store.getters['user/getLocale'] &&
                store.getters['user/getLocale'] === 'ja'
                ? 'ja-ja'
                : 'en-us'
          };
          return config;
        },
        async function (error) {
          return Promise.reject(error);
        }
      );

      v.interceptors.response.use(
        function (response) {
          return response;
        },
        async function (error) {
          if (error.response && error.response.status === 401) {
            await store.dispatch('user/clearSession');
            await localStorage.clear();
            await store.dispatch('tenant/reset');
            // Vue.prototype.$intercom.shutdown();
            await goToLogin()
          }
          
        if(canReject(error)) {
          return Promise.reject(error);
        } else {
          return Promise.reject();
        }
        }
      );
    });
  }

  public setSession(session: Session) {
    this.coreClient.defaults.headers['Tenant'] = encodeURIComponent(
      session.tenant
    );
    this.apiGatewayClient.defaults.headers['Tenant'] = encodeURIComponent(
      session.tenant
    );
  }
}

const clientManager = new ClientManager(config);

export default clientManager;
