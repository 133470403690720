
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'InfoOverview',
  props: {
    title: {
      type: String,
      default: ''
    },
    infovalue: {
      type: String,
      default: ''
    },
    rate: {
      type: String,
      default: ''
    }
  },
  computed: {}, // computed
  async created() {},
  methods: {} // methods
}; // export default
