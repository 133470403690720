
import { mapGetters, mapActions, mapState } from 'vuex';
//import { kpiListOccupancy } from '@/util/mockData/kpiMapping';

export default {
  data: function() {
    return {
      metricKey: {
        label: this.$t('BoxOccupancyPowerHour.maxOccupancy'),
        value: 'maxOccupancy'
      },
      maxVal: 0,
      minVal: 0,
      median: 0,
      daysOfWeek: [
        this.$t('FilterDaysOfWeek.monday'),
        this.$t('FilterDaysOfWeek.tuesday'),
        this.$t('FilterDaysOfWeek.wednesday'),
        this.$t('FilterDaysOfWeek.thursday'),
        this.$t('FilterDaysOfWeek.friday'),
        this.$t('FilterDaysOfWeek.saturday'),
        this.$t('FilterDaysOfWeek.sunday')
      ],
      options: [],
      mode: 'actualValue',
      loading: false,
      formattedValuesList: [],
      colorPallete: [
        '#ebf2fa',
        '#ebf2fa',
        '#d8e5f6',
        '#c5d7f1',
        '#b2cbed',
        '#9ebde8',
        '#8bb0e4',
        '#78a3df',
        '#6596db',
        '#5189d6',
        '#3e7cd2',
        '#3e7cd2',
        '#3e7cd2'
      ],
      series: [],
      kpiListOccupancy: [
 // 'averageOccupancy', //'avgOccupancy', value and lable is changed only in BoxOccupancyPowerHour.vue occupancy report
  'maxOccupancy',
  'minOccupancy',
 // 'avgOccupancyPercent'
],
      chartOptions: {
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            distributed: true,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              min: '#3e7cd21a',
              max: '#3e7cd2'
            }
          }
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        dataLabels: {
          // enabled: true
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          },
          formatter: (val, series) => {
            return this.formattedValuesList[series.seriesIndex][
              series.dataPointIndex
            ];
          }
        },
        xaxis: {
          type: 'category',
          categories: []
        },
        colors: ['#3e7cd2'],
        title: {},
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
              <q-card class="flex column q-pa-md">
                     ${this.formattedValuesList[seriesIndex][dataPointIndex]}
                </q-card>
            `;
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]), // mapGetters
    kpiOptions() {
      return this.kpiListOccupancy.map(kpi => (
      {
        label: this.$t(`BoxOccupancyPowerHour.${kpi}`),
        value: kpi
      }));
    },
    ...mapState('occupancy', ['powerHourData']),
    valueRanges() {
      let intMax = parseFloat(this.maxVal);
      let intMin = parseFloat(this.minVal);
      let dv = intMax - intMin;
      return [
        Math.log(0),
        intMin,
        parseInt((dv * 0.1).toFixed(1) + intMin),
        parseInt((dv * 0.2).toFixed(1) + intMin),
        parseInt((dv * 0.3).toFixed(1) + intMin),
        parseInt((dv * 0.4).toFixed(1) + intMin),
        parseInt((dv * 0.5).toFixed(1) + intMin),
        parseInt((dv * 0.6).toFixed(1) + intMin),
        parseInt((dv * 0.7).toFixed(1) + intMin),
        parseInt((dv * 0.8).toFixed(1) + intMin),
        parseInt((dv * 0.9).toFixed(1) + intMin),
        intMax,
        Infinity
      ];
    }
  }, // computed
  watch: {
    async getDaysOfWeekList() {
      var StoreOccupancyId = localStorage.getItem("StoreOccupancyId");
      this.loading = true;
      await this.fetchPowerHourData({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        storeCodes: this.getStoreCodesList,
        kpiMetricData: this.metricKey.value,
        daysOfWeek: this.getDaysOfWeekList,
        storeId: StoreOccupancyId
      });
    },
    async getStartingPeriod() {
      var StoreOccupancyId = localStorage.getItem("StoreOccupancyId");
      this.loading = true;
      await this.fetchPowerHourData({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        storeCodes: this.getStoreCodesList,
        kpiMetricData: this.metricKey.value,
        daysOfWeek: this.getDaysOfWeekList,
        storeId: StoreOccupancyId
      });
    },
    async getEndingPeriod() {
      var StoreOccupancyId = localStorage.getItem("StoreOccupancyId");
      this.loading = true;
      await this.fetchPowerHourData({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        storeCodes: this.getStoreCodesList,
        kpiMetricData: this.metricKey.value,
        daysOfWeek: this.getDaysOfWeekList,
        storeId: StoreOccupancyId
      });
    },
    metricKey: async function(val) {
      this.loading = true;
      // await this.fetchPowerHourData({
      //   startDate: this.getStartingPeriod,
      //   endDate: this.getEndingPeriod,
      //   storeCodes: this.getStoreCodesList,
      //   kpiMetricData: this.metricKey.value,
      //   daysOfWeek: this.getDaysOfWeekList
      // });
     this.populateHeatMap(this.metricKey.value);
      this.loading = false;
    },
    powerHourData() {
      this.populateHeatMap(this.metricKey.value);
      this.loading = false;
    },
    kpiOptions(newValue) {
      this.metricKey = newValue[0] ?? {};
    }
  },
  created: async function() {
    this.loading = true;
    // this.kpiListOrder = [...kpiListOccupancy];
    this.options = this.kpiOptions;
    // await this.fetchPowerHourData({
    //   startDate: this.getStartingPeriod,
    //   endDate: this.getEndingPeriod,
    //   storeCodes: this.getStoreCodesList,
    //   kpiMetricData: this.metricKey.value,
    //   daysOfWeek: this.getDaysOfWeekList
    // });
    this.populateHeatMap(this.metricKey.value);
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('BoxOccupancyPowerHour.powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('BoxOccupancyPowerHour.powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('BoxOccupancyPowerHour.powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };

    this.loading = false;
  }, //created
  methods: {
    ...mapActions('occupancy', ['fetchPowerHourData']),

    populateHeatMap: function(metricKeyValue) {
      this.loading = true;
      let dataList = [];

      if (this.powerHourData && Object.keys(this.powerHourData).length > 0) {
        this.series = Object.values(this.powerHourData)
          .map((v, key) => {
            //  dataList = [...dataList, ...Object.values(v).map(dat => ((dat.enters-dat.exits)>=0?(dat.enters-dat.exits):0 ))];
            if(metricKeyValue == 'maxOccupancy')
            {
             dataList = [...dataList, ...Object.values(v).map(dat => ((dat.maxOccupancy)>=0?(dat.maxOccupancy):0 ))];
             return {
              name: this.$t(
                `BoxOccupancyPowerHour.${Object.keys(this.powerHourData)[
                  key
                ].toLowerCase()}`
              ),
              data: Object.values(v).map(dat => (dat.maxOccupancy)>=0?(dat.maxOccupancy):0),
              formattedValue: Object.values(v).map(dat => ''+(dat.maxOccupancy)>=0?(dat.maxOccupancy):0+ ' '),
              // data: Object.values(v).map(dat => (dat.enters-dat.exits)>=0?(dat.enters-dat.exits):0),
              // formattedValue: Object.values(v).map(dat => ''+(dat.enters-dat.exits)>=0?(dat.enters-dat.exits):0+ ' '),


              // data: v.map(dat => dat.maxOccupancy),
              // formattedValue: v.map(dat => dat.maxOccupancy)
            };
            } else if(metricKeyValue == 'minOccupancy')
            {
              dataList = [...dataList, ...Object.values(v).map(dat => ((dat.minOccupancy)>=0?(dat.minOccupancy):0 ))];
              return {
              name: this.$t(
                `BoxOccupancyPowerHour.${Object.keys(this.powerHourData)[
                  key
                ].toLowerCase()}`
              ),
              data: Object.values(v).map(dat => (dat.minOccupancy)>=0?(dat.minOccupancy):0),
              formattedValue: Object.values(v).map(dat => ''+(dat.minOccupancy)>=0?(dat.minOccupancy):0+ ' '),
              };
            }

            else if(metricKeyValue == 'averageOccupancy')
            {
              dataList = [...dataList, ...Object.values(v).map(dat => (Math.round(dat.avgOccupancy)>=0?(Math.round(dat.avgOccupancy)):0 ))];
              return {
              name: this.$t(
                `BoxOccupancyPowerHour.${Object.keys(this.powerHourData)[
                  key
                ].toLowerCase()}`
              ),
              data: Object.values(v).map(dat => (Math.round(dat.avgOccupancy)>=0?(Math.round(dat.avgOccupancy)):0)),
              formattedValue: Object.values(v).map(dat => ''+(Math.round(dat.avgOccupancy))>=0?(Math.round(dat.avgOccupancy)):0+ ' '),
              };
            }

            else if(metricKeyValue == 'avgOccupancyPercent')
            {
              var maxConfOccupancy = Number(localStorage.getItem("MaxConfigOccupancy"));
              //((dat.avgOccupancy/maxConfOccupancy)*100)

              dataList = [...dataList, ...Object.values(v).map(dat => (( Math.round((dat.avgOccupancy/maxConfOccupancy)*100))>=0?( Math.round((dat.avgOccupancy/maxConfOccupancy)*100)):0 ))];
              return {
              name: this.$t(
                `BoxOccupancyPowerHour.${Object.keys(this.powerHourData)[
                  key
                ].toLowerCase()}`
              ),
              data: Object.values(v).map(dat => ( Math.round((dat.avgOccupancy/maxConfOccupancy)*100))>=0?( Math.round((dat.avgOccupancy/maxConfOccupancy)*100)):0),
              formattedValue: Object.values(v).map(dat => ''+( Math.round((dat.avgOccupancy/maxConfOccupancy)*100))>=0?( Math.round((dat.avgOccupancy/maxConfOccupancy)*100)):0+ ' '),
              };
            }
            else
            {
              dataList = [...dataList, ...Object.values(v).map(dat => ((dat.maxOccupancy)>=0?(dat.maxOccupancy):0 ))];
              return {
              name: this.$t(
                `BoxOccupancyPowerHour.${Object.keys(this.powerHourData)[
                  key
                ].toLowerCase()}`
              ),
              data: Object.values(v).map(dat => (dat.maxOccupancy)>=0?(dat.maxOccupancy):0),
              formattedValue: Object.values(v).map(dat => ''+(dat.maxOccupancy)>=0?(dat.maxOccupancy):0+ ' '),
            };
            }

          })
          .sort(this.daysOfWeekSorter)
          .reverse();
        this.maxVal = parseInt(Math.max(...dataList)).toFixed(0);
        this.minVal = parseInt(Math.min(...dataList)).toFixed(0);
        this.median = (
          (parseInt(this.maxVal) + parseInt(this.minVal)) /
          2
        ).toFixed(0);
        this.formattedValuesList = [];
        this.series.map(v => {
          this.formattedValuesList = [
            ...this.formattedValuesList,
            v.formattedValue
          ];
        });
        let categories = [];
        if (this.powerHourData) {
          categories = Object.keys(this.powerHourData[Object.keys(this.powerHourData)[0]]);
          categories = categories.map(x => {
            if(Number(x)<=9)
            {
              x = '0'+x +':'+'00';
            }
            else
            {
              x = x+':'+'00';
            }
            return x;
            });
        }
        this.chartOptions = {
          chart: {
            height: 350,
            type: 'heatmap',
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
              customIcons: []
            }
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              shadesIntensity: 0,
              colorScale: {
                ranges: this.valueRanges.map((rangeVal, index) => {
                  const rangeObj = {
                    from: rangeVal,
                    to: this.valueRanges[index + 1]
                      ? this.valueRanges[index + 1]
                      : this.valueRanges[this.valueRanges.length - 1],
                    color: this.colorPallete[index]
                      ? this.colorPallete[index]
                      : this.colorPallete[this.colorPallete.length - 1],
                    name: undefined
                  };
                  return rangeObj;
                })
              }
            }
          },
          legend: {
            show: false
          },
          dataLabels: {
            // enabled: true
            background: {
              enabled: true,
              foreColor: '#ff3046'
            },
            style: {
              fontSize: '14px',
              colors: ['#000000']
            },
            formatter: (val, series) => {
              return this.formattedValuesList[series.seriesIndex][
                series.dataPointIndex
              ];
            }
          },
          xaxis: {
            type: 'category',
            categories: [...categories]
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return `
           <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${w.globals.labels[dataPointIndex]}</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip">${w.globals.seriesNames[seriesIndex]}:</div>
                     <label class="text-bold">${this.formattedValuesList[seriesIndex][dataPointIndex]}</label></div>
                </q-card>
            `;
            }
          },
          title: {}
        };
      }
      this.loading = false;
    },
    daysOfWeekSorter(x, y) {
      return this.daysOfWeek.indexOf(x.name) - this.daysOfWeek.indexOf(y.name);
    }
  }
};
