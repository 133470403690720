import { APIFetchTrend, APIFetchTrendHourly } from '@/api/trend';
import {  APIFetchTargetsSales } from '@/api/target';
import { forEach } from 'lodash';
import {convertResponsePulseTrend} from '@/util/utilFidaFunctions';

const initialState = {
  selectedPeriod: [],
  comparedPeriod: [],
  targetSelectedPeriod: [],
  targetComparedPeriod: [],
  sales:{},//temp save api data
  apiCall:false,
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setSelectedPeriod(state, { selectedPeriod }) {
      state.selectedPeriod = selectedPeriod;
    }, // setSelectedPeriod
    setComparedPeriod(state, { comparedPeriod }) {
      state.comparedPeriod = comparedPeriod;
    }, // setComparedPeriod
    setTargetSelectedPeriod(state, { targetSelectedPeriod }) {
      state.targetSelectedPeriod = targetSelectedPeriod;
    }, // setSelectedPeriod
    setTargetComparedPeriod(state, { targetComparedPeriod }) {
      state.targetComparedPeriod = targetComparedPeriod;
    }, // setComparedPeriod
    setSales(state, payload) {
      state.sales = payload;
    }, // setComparedPeriod
    setLoaded(state, payload){
      state.apiCall = payload
    }
  }, // mutations
  actions: {
    async fetchTargetAchive({commit, rootState}, payload){
      try {
        payload.client_id = rootState.user?.clientId,
        payload.client_secret = rootState.user?.clientSecret
        var res:any = await APIFetchTargetsSales(payload);
        var data = res.data.response?.kpis;
        var makeObj = {};
        var myArr = [];
        if(!payload.performancePage){
          data?.forEach(v => {
            makeObj[v._id.date] =  (makeObj[v._id.date] || 0) + v.salesTarget
          })
        }
        if(payload.performancePage){
          data?.forEach(v => {
            makeObj[v._id.storeCode] =  (makeObj[v._id.storeCode] || 0) + v.salesTarget
          })
        }
        // Iterate through the object
        for (const key in makeObj) {
          if (makeObj.hasOwnProperty(key)) {
            myArr.push({timeLabel: key, value: makeObj[key], formattedValue: "¥" + makeObj[key]?.toLocaleString()})
          }
        }
        var sortedArr = myArr.sort((a,b) => {
          const date1:any = new Date(a.timeLabel)
          const date2:any = new Date(b.timeLabel)
          return date1 - date2
        })
        console.log(sortedArr, 'sortedArr')
        if(payload.target){
          commit('setTargetSelectedPeriod', {targetSelectedPeriod:sortedArr})}
          else{
          commit('setTargetComparedPeriod', {targetComparedPeriod:sortedArr})
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchTrend(
      { commit, rootState },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        metricKey
      }
    ) {
      const res:any = await APIFetchTrend({
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        metricKey
      });
      const apiresponse = res;
      const finalResult = convertResponsePulseTrend(apiresponse,metricKey,startDate,endDate,compareStartDate,compareEndDate);
      commit('setSelectedPeriod', { selectedPeriod: finalResult.data?.selectedPeriod });
      commit('setComparedPeriod', { comparedPeriod: finalResult.data?.comparedPeriod });
      commit('setSales', {selectedPeriod:finalResult.data?.selectedPeriod,comparedPeriod:finalResult.data?.comparedPeriod})
      var toggle = !rootState.trend.apiCall // watch
      commit('setLoaded', toggle)
      return res;
    },
    async fetchTrendHour(
      { commit , rootState},
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        metricKey
      }
    ) {
      const res = await APIFetchTrendHourly({
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        daysOfWeek,
        metricKey
      });
      commit('setSelectedPeriod', { selectedPeriod: res.data?.selectedPeriod });
      commit('setComparedPeriod', { comparedPeriod: res.data?.comparedPeriod });
      commit('setSales', {selectedPeriod:res.data?.selectedPeriod,comparedPeriod:res.data?.comparedPeriod})
      var toggle = !rootState.trend.apiCall // for watch
      commit('setLoaded', toggle)
      return res;
    } // fetchTrend
  } // actions
}; // export default
