
import { OrderPages } from '@/components/Tools/Order/OrderPages';
import ToolsMixin from '@/mixins/ToolsMixin';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [ToolsMixin, CommonMixin],
  data() {
    return {
      OrderPages: OrderPages,
      activeTab: OrderPages.WAITING_FOR_REVIEW,
      options: [
        {
          label: this.$t('PurchaseOrders.WaitingForReview'),
          value: OrderPages.WAITING_FOR_REVIEW,
          name: 'tools:orders:waiting-for-review'
        },
        {
          label: this.$t('PurchaseOrders.WaitingForFinalOrder'),
          value: OrderPages.WAITING_FOR_FINAL_ORDER,
          name: 'tools:orders:waiting-for-final-order'
        },
        {
          label: this.$t('PurchaseOrders.WaitingForPayment'),
          value: OrderPages.WAITING_FOR_PAYMENT,
          name: 'tools:orders:waiting-for-payment'
        },
        {
          label: this.$t('PurchaseOrders.WaitingForSchedule'),
          value: OrderPages.WAITING_FOR_SCHEDULE,
          name: 'tools:orders:waiting-for-schedule'
        },
        {
          label: this.$t('PurchaseOrders.Finished'),
          value: OrderPages.FINISHED,
          name: 'tools:orders:finished'
        }
      ]
    };
  },
  async created() {
    const routeName = this.$route.name;
    const currentTab = this.options.find(option => option.name === routeName);
    if (currentTab != null) {
      this.activeTab = currentTab.value;
    }
  },
  methods: {
    navigate(event: OrderPages) {
      const target = this.options.find(option => option.value === event);
      if (target !== null) {
        this.$router.push({ name: target.name });
      }
    }
  }
};
