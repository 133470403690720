
export default {
  props: {
    value: {
      type: null,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateValue(evt) {
      this.$emit('input', evt.target.value);
    }
  }
};
