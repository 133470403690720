import HttpController from '../HttpController';
import { PaymentCardDTO } from '@/models/Payment';
import { TenantDTO } from '@/models/Tenant';
import { createBackendError } from '@/util/error';
import { PaginatedResponse } from '@/models/Pagination';
import { BillingRecordDTO } from '@/models/BillingRecord';
import { PaginationRequest } from '@/util/pagination';

export interface ChangeTenantPlanRequest {
  plan: string;
  tenantId: string;
  cardId: string;
}

export interface ChangeTenantPlanResponse {
  id: string;
  storeId: string;
  type: string;
  tenant: TenantDTO;
  tenantCard: PaymentCardDTO;
}

export type GetBillingResult = PaginatedResponse<BillingRecordDTO>;

export default class BillingController extends HttpController {
  public async getCurrentBilling(
    tenantId: string,
    pagination: PaginationRequest
  ): Promise<GetBillingResult> {
    const { data } = await this.client.get(
      `/console/payment/billing-plan/${tenantId}/current-billing`,
      {
        params: {
          ...pagination
        }
      }
    );
    return data as GetBillingResult;
  }

  public async getBillingHistory(
    tenantId: string,
    pagination: PaginationRequest
  ): Promise<GetBillingResult> {
    const { data } = await this.client.get(
      `/console/payment/billing-plan/${tenantId}/history-billing`,
      {
        params: {
          ...pagination
        }
      }
    );
    return data as GetBillingResult;
  }

  public async getBillingRecordTypes() {
    const { data } = await this.client.get(
      '/console/payment/billing-plan/types'
    );
    return data;
  }

  public async changeTenantPlan(req: ChangeTenantPlanRequest) {
    try {
      const { data } = await this.client.post(
        '/console/payment/billing-plan/change-plan-tenant',
        {
          storeId: req.tenantId,
          type: 'PLAN'
        },
        {
          params: {
            cardId: req.cardId,
            plan: req.plan
          }
        }
      );
      return data as ChangeTenantPlanResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async downloadInvoice(id: string): Promise<BlobPart> {
    try {
      const { data } = await this.client.get(
        `/console/payment/billing-plan/${id}/pdf`
      );
      return data as BlobPart;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
