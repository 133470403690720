import { SignupFlag } from '@/models/Auth';
import {
  convertFromUserDTO,
  convertToUserDTO
} from '@/models/formatters/UserFormatter';
import clientManager from '../util/client';
import { BaseTenant } from '@/models/Tenant';
import User, { BaseUser, UpdateUserDTO, UserDTO } from '@/models/User';
import AuthController from '@/services/account/AuthController';
import LoginController from '@/services/account/LoginController';
import TenantController from '@/services/account/TenantController';
import CustomerController from '@/services/management/CustomerController';
import { parseEmailDomain } from '@/util/parser';
import UserController from '@/services/management/UserController';

export type UpdateUser = User;

export interface UserFormModel extends BaseUser {
  password: string;
  confirmPassword: string;
}

export interface TenantFormModel extends BaseTenant {
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
}

export interface PasswordFormModel {
  email: string;
  password: string;
  confirmPassword: string;
}

export interface SignupResponse {
  flag: SignupFlag;
  domain: string;
}

export default class AuthRepository {
  private readonly authController: AuthController;
  private readonly loginController: LoginController;
  private readonly tenantController: TenantController;
  private readonly customerController: CustomerController;
  private readonly userController: UserController;

  constructor(
    authController: AuthController,
    loginController: LoginController,
    tenantController: TenantController,
    customerController: CustomerController,
    userController: UserController
  ) {
    this.authController = authController;
    this.loginController = loginController;
    this.tenantController = tenantController;
    this.customerController = customerController;
    this.userController = userController;
  }

  public async getUser() {
    const dto = await this.authController.getUser();
    return convertFromUserDTO(dto);
  }

  public async updateUser(user: UpdateUser, token: string) {
    const dto = await this.authController.updateUser(
      convertToUserDTO(user, token) as UpdateUserDTO
    );
    return convertFromUserDTO(dto);
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    await this.authController.changePassword(oldPassword, newPassword);
  }

  public async forgetPassword(verificationCode: string, newPassword: string) {
    await this.authController.forgetPassword(verificationCode, newPassword);
  }

  public login(username: string, password: string) {
    return this.loginController.login(username, password);
  }

  public logout() {
    return this.loginController.logout();
  }
  public async signup(email: string): Promise<SignupResponse> {
    const res = await this.customerController.signup(email);
    clientManager.setSession({ tenant: res?.tenant?.name ?? '' });
    const { admin, invitedUser, requestedUser, domain } = res;
    let flag: SignupFlag;
    if (admin) {
      flag = SignupFlag.ACCOUNT_ADMIN;
    } else if (requestedUser) {
      flag = SignupFlag.ACCOUNT_REQUESTS_JOIN;
    } else if (invitedUser) {
      flag = SignupFlag.ACCOUNT_INVITED;
    } else {
      flag = SignupFlag.NONE;
    }
    return { flag, domain };
  }

  public verify(email: string, code: string) {
    return this.customerController.verify(email, code);
  }

  public getCustomerByEmail(email: string) {
    return this.customerController.getCustomerByEmail(email);
  }

  public isUserDisabled(email: string, tenantName: string) {
    clientManager.setSession({ tenant: tenantName });
    return this.userController.userIsDisabled(email);
  }

  public forgotPassword(email: string) {
    return this.customerController.forgetPassword(email);
  }

  public setPassword(password: PasswordFormModel, tenant) {
    return this.authController.setPassword(
      {
        email: password.email,
        password: password.password,
        confirmPassword: password.confirmPassword
      },
      tenant
    );
  }
  public setConsoleUser(password: PasswordFormModel) {
    return this.customerController.setPassword({
      email: password.email,
      password: password.password,
      confirmPassword: password.confirmPassword
    });
  }
  public async createTenant(user: UserFormModel, tenant: TenantFormModel) {
    const tenantPhone =
      tenant.phone.countryCode + ' ' + tenant.phone.phoneNumber;
    const userPhone = user.phone.countryCode + ' ' + user.phone.phoneNumber;
    const res = await this.tenantController.createTenant({
      user: {
        approvalPrivilege: true,
        username: user.username,
        email: user.email,
        firstname: user.firstName,
        lastname: user.lastName,
        password: user.password,
        passwordConfirm: user.confirmPassword,
        phone: userPhone
      },
      tenant: {
        companyName: tenant.companyName,
        email: tenant.email,
        phone: tenantPhone,
        domain: parseEmailDomain(tenant.email) as string,
        industry: { id: tenant.industryId }
      }
    });
    return res.tenant;
  }

  public async requestJoinToAdmin(
    domain: string,
    email: string,
    requestedUser: boolean = false
  ) {
    return await this.customerController.notifyAdministrator(
      domain,
      email,
      requestedUser
    );
  }
}
