import { ClientManager } from '@/util/client';
import BrandController from './BrandController';
import BusinessTypeController from './BusinessTypeController';
import CompanyController from './CompanyController';
import CurrencyController from './CurrencyController';
import DefaultSettingController from './DefaultSettingController';
import GeneralController from './GeneralController';
import IndustryController from './IndustryController';
import OperatingSystemController from './OperatingSystemController';
import SalesController from './SalesController';
import StoreTypeController from './StoreTypeController';

export interface CoreControllers {
  brand: BrandController;
  company: CompanyController;
  defaultSettings: DefaultSettingController;
  storeType: StoreTypeController;
  businessType: BusinessTypeController;
  operatingSystem: OperatingSystemController;
  general: GeneralController;
  sales: SalesController;
  industry: IndustryController;
  currency: CurrencyController;
}

export default (clientManager: ClientManager): CoreControllers => ({
  brand: new BrandController(clientManager.coreClient),
  company: new CompanyController(clientManager.coreClient),
  defaultSettings: new DefaultSettingController(clientManager.coreClient),
  storeType: new StoreTypeController(clientManager.coreClient),
  businessType: new BusinessTypeController(clientManager.coreClient),
  operatingSystem: new OperatingSystemController(clientManager.coreClient),
  general: new GeneralController(clientManager.apiGatewayClient),
  sales: new SalesController(clientManager.coreClient),
  industry: new IndustryController(clientManager.consoleClient),
  currency: new CurrencyController(clientManager.consoleClient)
});
