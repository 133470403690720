

export default {
  methods: {
    onFileSelected(evt) {
      try {
        const file = (evt.target).files[0];
        this.$emit('import', file);
      } catch (e) {
        //console.log(e)
      }
    }
  }
};
