
const props = {
  type: {
    type: String,
    default: 'text'
  },
  hint: {
    type: String,
    default: ''
  },
  value: {
    type: [String, Boolean],
    default: ''
  }
};

const render = (h, context) => {
  const { $style, props, listeners } = context;
  const options = {
    attrs: {
      type: props.type,
      placeholder: props.hint,
      value: props.value
    },
    on: {}
  };
  if (props.type !== 'checkbox') {
    options.attrs.class = $style.input;
  } else {
    options.attrs.value = !!props.value;
  }
  const updateListener = listeners.input;
  if (updateListener) {
    options.on.change = evt => {
      updateListener(evt.target.value);
    };
  }
  return h('input', options);
};

export default {
  name: 'LoginInput',
  functional: true,
  props,
  render
};
