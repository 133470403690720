
import BarChart from './BarChart.vue';
import { mapActions } from 'vuex';
import { format } from 'date-fns';
import { APIGetTriggeredMessages } from '@/api/fidaApi';

export default {
  components: {
    BarChart
  },
  data() {
    return {
      sugestedModal: true,
      sugestedOpen: false,
      latestMessage: {},
      kpiData: {},
      responseData: [],
      showFullMessage: true,
      maxLines: 3
    };
  },
  computed: {
    showViewMoreButton() {
      const maxLines = 3; // Maximum lines to display before truncating
      const lines = this.latestMessage.message.split('</p>');
      return lines.length > maxLines;
    },
    truncatedMessage() {
      const maxLines = 3; // Maximum lines to display before truncating
      const lines = this.latestMessage.message.split('</p>');
      if (!this.showFullMessage) {
        return lines.slice(0, maxLines).join('</p>');
      } else {
        return this.latestMessage.message;
      }
    }
  },

  watch: {
    sugestedOpen(val) {
      this.sugestedModal = true;
      if (val) {
        this.fetchKpiData();
      }
    }
  },
  methods: {
    ...mapActions('overview', ['fetchOverview']),
    getSkillName() {
      const keyData = this.latestMessage.skillName;
      // const tran = this.$store.state.user.translate[keyData];
      return keyData;
    },
    openChat() {
      setTimeout(() => {
        document.body['id'] = 'data-side';
      }, 500);
    },
    async fetchKpiData() {
      var response = await APIGetTriggeredMessages({});
      var resp = response.data.response;
      if (resp.messages.length == 0) {
        console.log('no record found');
        return;
      }
      //     this.responseData = resp.messages;//resp.kpis.data
      //     console.log('this.responseData',this.responseData)

      const newMessage = resp.messages.reduce((prev, current) =>
        prev.cDate.timeStamp > current.cDate.timeStamp ? prev : current
      );
      this.latestMessage = newMessage;
      this.latestMessage.responseData = resp.kpis.data;
    },
    getDateFormatted() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(today.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    toggleMessage() {
      this.showFullMessage = !this.showFullMessage;
    }
  }
};
