
import { mapGetters, mapState } from 'vuex';
import { formatYAxis } from '../../../util/legend';
import { filterAvailableKpis } from '@/util/kpis';

export default {
  props: {
    series: {
      type: Array,
      required: false,
      default: () => []
    },
    comparekpi: {
      type: String,
      required: false,
      default: () => 'cn'
    }, // options
    tab: {
      type: String,
      required: false,
      default: () => ''
    }, // options
    reflines: {
      type: Array,
      default: () => []
    }
  }, // props
  data() {
    return {
      perConversion: 0,
      stats: ['sls', 'cn', 'ap', 'cr', 'trf'],
      seriesB: [],
      options: {},
      keyConv: {sls: "Sales", cn: "conversion", ap: "avgPurchase", cr: "captureRate", trf: "traffic"},
      keyConvRev: {sales: "sls", conversion: "cn", avgPurchase: "ap", captureRate: "cr", traffic: "trf"},
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('user', ['kpiPreferences']),
    hasStatPresent() {
      return this.keyConv[this.comparekpi.toLowerCase()];
    },
    avgTraffic() {
      return (
        this.series
          .map(x => parseInt(x.xAxisValue, 10))
          .reduce((prev, curr) => {
            return prev + curr;
          }) / this.series.length
      );
    },
    medTraffic() {
      const values = this.series.map(x => parseInt(x.xAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) {
        return values[half];
      }
      return (values[half - 1] + values[half]) / 2;
    },
    maxTraffic() {
      const values = this.series.map(x => parseInt(x.xAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      return values[values.length - 1];
    },
    minTraffic() {
      const values = this.series.map(x => parseInt(x.xAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      return values[0];
    },
    avgConversion() {
      return (
        this.series
          .map(x => parseInt(x.yAxisValue, 10))
          .reduce((prev, curr) => {
            return prev + curr;
          }) / this.series.length
      );
    },
    medConversion() {
      const values = this.series.map(x => parseInt(x.yAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) {
        return values[half];
      }
      return (values[half - 1] + values[half]) / 2;
    },
    maxConversion() {
      const values = this.series.map(x => parseInt(x.yAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      return values[values.length - 1];
    },
    minConversion() {
      const values = this.series.map(x => parseInt(x.yAxisValue, 10));
      values.sort(function(a, b) {
        return a - b;
      });
      return values[0];
    }
  },
  watch: {
    kpiPreferences() {
      this.stats = filterAvailableKpis(this.stats);
      this.options = {
        ...this.options,
        yaxis: {
          title: {
            text: this.getLabel(this.comparekpi)
          }
        },
        xaxis: {
          title: {
            text: this.getLabel('trf'),
            offsetY: 10
          }
        }
      }; // chartOptionsB
    },
    async series() {
      this.setChartOptions();
      await this.setData();
      // this.$refs.chart.addYaxisAnnotation({
      //   y: this.avgConversion,
      //   strokeDashArray: 6,
      //   borderWidth: 2
      // });
      //
      // this.$refs.chart.addXaxisAnnotation({
      //   x: this.avgTraffic,
      //   strokeDashArray: 6,
      //   borderWidth: 2
      // });
    },
    reflines() {
      this.updateAnnotations();
    }
  },
  async created() {
    this.stats = filterAvailableKpis(this.stats);
    this.options = {
      chart: {
        animations: {
          enabled: false
        },
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        height: 450,
        type: 'bubble',
        stacked: false,
        toolbar: {
          autoSelected: 'zoom',
          tools: {
            pan: false,
            zoom: true,
            download: '<i class="fas fa-download"></i>',
            customIcons: [
                   {
                    icon:
                      `<span><svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg> <span class="view-report">${this.$store.state.user.translate.view_location_comparison_report}</span></span>`,
                    title: "View location comparison report",
                    class: "custom-icon custom-tool-icon",
                    click:  () => {
                      let obj = {}
                      obj.kpi = this.comparekpi
                      obj.selectedTab = this.tab
                      this.$store.commit("comparison/setSelectedkpiTabs", obj);
                      this.$router.push('location-comparison-report')
                    },
                  },
                ],
          },
          export: {
            svg: {
              filename: `${this.$t('comparison')} ${this.$t(
                'KpiAnalysis.selected'
              )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
                'KpiAnalysis.compared'
              )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
            },
            png: {
              filename: `${this.$t('comparison')} ${this.$t(
                'KpiAnalysis.selected'
              )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
                'KpiAnalysis.compared'
              )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
            },
            csv: {
              filename: `${this.$t('comparison')} ${this.$t(
                'KpiAnalysis.selected'
              )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
                'KpiAnalysis.compared'
              )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
            }
          }
        }
      },
      legend: {
        showForSingleSeries: true,
        position: 'top',
        markers: {
          width: 16,
          height: 6,
          fillColors: undefined,
          radius: 0
        },
        itemMargin: {
          horizontal: 3
        },
        tooltipHoverFormatter: function(seriesName, opts) {
          return (
            seriesName +
            ' - <strong>' +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            '</strong>'
          );
        }
      },
      dataLabels: {
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          return (
            w.config.series[seriesIndex].data[dataPointIndex] &&
            w.config.series[seriesIndex].data[dataPointIndex][3].name?.substr(
              0,
              4
            )
          );
        }
      },
      colors: ['#3290ed', '#f5405d', '#26ab95', '#9d53f2'],
      fill: {
        opacity: 1
      },
      plotOptions: {
        bubble: {
          minBubbleRadius: 5,
          maxBubbleRadius: 50
        }
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.65
          }
        }
      },

      grid: {
        padding: {
          left: 50,
          right: 50
        }
      },
      yaxis: {
        max: max => {
          return max * 1.5;
        },
        forceNiceScale: true,
        decimalsInFloat: Number,
        labels: {
          formatter: value => {
            return value.toFixed(3) + this.$t(`unit.${this.comparekpi}Unit`);
          }
        },
        title: {
          text: this.getLabel(this.comparekpi)
        }
      },
      xaxis: {
        tickAmount: 10,
        // min: this.minVal - 800,
        // max: this.maxVal + 800,
        type: 'numeric',
        tooltip: { enabled: false },
        labels: {
          formatter: value => {
            return (
              Math.round(value).toLocaleString() + this.$t('BoxComparison.unit')
            );
          }
        },
        title: {
          text: this.getLabel('trf'),
          offsetY: 10
        }
      },
      annotations: {
        position: 'back'
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return `
          <div class="tooltip-container flex column q-card">
          <div class="q-card__section q-card__section--vert">
            <div class="text-bold q-my-md">
                ${w.config.series[seriesIndex].data[dataPointIndex] &&
                  w.config.series[seriesIndex].data[dataPointIndex][3].name}
                </div>
              <div class="tooltip-title q-mb-sm flex items-center">
                <div class="tooltip-title-color" style="background:${
                  w.config.colors[seriesIndex]
                }"></div>
                <label>${w.globals.seriesNames[seriesIndex]}</label>
                </div>
                  ${
                    !this.hasStatPresent
                      ? `<div class="flex items-center">
                  ${this.getLabel(this.comparekpi)}
                  <div class="q-ml-xs text-bold tool-tip-value">
                      ${w.config.series[seriesIndex].data[dataPointIndex] &&
                        w.config.series[seriesIndex].data[dataPointIndex][3]
                          .value}
                  </div>
                </div>`
                      : ''
                  }
                  ${this.getTooltip(
                    w.config.series[seriesIndex].data[dataPointIndex]
                  )}
            </div>
            </div>`;
        }
      },
      responsive: [
        {
          breakpoint: 769,
          options: {
            legend: {
              horizontalAlign: 'left',
              markers: {
                radius: 0,
                width: 16,
                height: 6
              }
            }
          }
        },
        {
          breakpoint: 426,
          options: {
            legend: {
              position: 'bottom',
              markers: {
                radius: 0,
                width: 16,
                height: 6
              }
            }
          }
        }
      ]
    }; // chartOptionsB
    this.options.chart.toolbar = {
      ...this.options.chart.toolbar
    };
    await this.setData();
  }, // created
  mounted() {
    // To load default reflines
    setTimeout(() => {
      this.updateAnnotations();
    }, 2500);
  },
  methods: {
    getLabel(value) {
      return this.$t(value);
      //  let name = this.kpiPreferences.find(val => val.metricKey === value);
      //  return name ? name.title : '';
    },
    xAxisAnnotation(value, name) {
      return {
        x: value,
        strokeDashArray: 6,
        borderWidth: 2,
        label: {
          borderColor: 'transparent',
          offsetY: -10,
          text: name,
          textAnchor: 'middle',
          position: 'top',
          orientation: 'horizontal'
        }
      };
    },
    yAxisAnnotation(value, name) {
      return {
        y: value,
        strokeDashArray: 6,
        borderWidth: 2,
        label: {
          borderColor: 'transparent',
          offsetX: 50,
          text: name,
          textAnchor: 'end',
          position: 'right',
          orientation: 'horizontal'
        }
      };
    },
    updateAnnotations() {
      const linesNameArray = [
        `${this.$t('Home.average')}`,
        `${this.$t('Home.median')}`,
        `${this.$t('Home.maximum')}`,
        `${this.$t('Home.minimum')}`
      ];
      const trafficFuncArray = [
        this.avgTraffic,
        this.medTraffic,
        this.maxTraffic,
        this.minTraffic
      ];

      const conversionFuncArray = [
        this.avgConversion,
        this.medConversion,
        this.maxConversion,
        this.minConversion
      ];
      let Traffics = {};
      linesNameArray.forEach((lineName, index) => {
        Traffics[lineName] = trafficFuncArray[index];
      });

      let Conversions = {};
      linesNameArray.forEach((lineName, index) => {
        Conversions[lineName] = conversionFuncArray[index];
      });
      const trafficAnnotations = this.reflines.map(v => {
        return this.xAxisAnnotation(Traffics[v], v);
      });
      const conversionAnnotations = this.reflines.map(v => {
        return this.yAxisAnnotation(Conversions[v], v);
      });

      this.options = {
        ...this.options,
        annotations: {
          position: 'back',
          xaxis: trafficAnnotations,
          yaxis: conversionAnnotations
        }
      };
    },
    getTooltip(w) {
      let tooltip = '';
      this.stats.map(kpi => {
        tooltip += `<div class="flex items-center">
                <label>
                ${this.getLabel(kpi)}:
                </label>
                <div class="q-ml-xs text-bold tool-tip-value">
              ${
                kpi === 'trf'
                  ? w &&
                    w[3][this.keyConv[kpi].toLowerCase()].toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      this.$t('BoxComparison.unit')
                  : w && w[3][this.keyConv[kpi].toLowerCase()]
              }
                </div>
            </div>`;
      });
      return tooltip;
    },
    setChartOptions() {
      this.options = {};
      this.options = {
        chart: {
          redrawOnWindowResize: true,
          redrawOnParentResize: true,
          type: 'bubble',
          stacked: false,
          toolbar: {
            autoSelected: 'zoom',
            tools: {
              pan: false,
              zoom: false,
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        legend: {
          showForSingleSeries: true,
          position: 'top',
          markers: {
            width: 16,
            height: 6,
            fillColors: undefined,
            radius: 0
          },
          itemMargin: {
            horizontal: 2
          },
          tooltipHoverFormatter: function(seriesName, opts) {
            return (
              seriesName +
              ' - <strong>' +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              '</strong>'
            );
          }
        },
        dataLabels: {
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return (
              w.config.series[seriesIndex].data[dataPointIndex] &&
              w.config.series[seriesIndex].data[dataPointIndex][3].name
            );
          }
        },
        colors: ['#b4d1ff', '#F62D4F', '#bad898', '#e0cddf'],
        plotOptions: {
          bubble: {
            minBubbleRadius: 5,
            maxBubbleRadius: 50
          }
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.65
            }
          }
        },

        grid: {
          padding: {
            left: 50,
            right: 50
          }
        },
        yaxis: {
          max: max => {
            return max * 1.5;
          },
          forceNiceScale: true,
          decimalsInFloat: Number,
          labels: {
            formatter: value => {
              return (
                formatYAxis(value.toFixed(0)) +
                this.$t(`unit.${this.comparekpi}Unit`)
              );
            }
          },
          title: {
            text: this.getLabel(this.comparekpi)
          }
        },
        xaxis: {
          tickAmount: 5,
          // min: this.minVal - 800,
          // max: this.maxVal + 800,
          type: 'numeric',
          tooltip: { enabled: false },
          labels: {
            formatter: value => {
              return this.series.length === 1
                ? formatYAxis(value.toFixed(0))
                : formatYAxis(Math.round(value.toFixed(0) / 100) * 100) +
                    this.$t('BoxComparison.unit');
            }
          },
          title: {
            text: this.getLabel('trf'),
            offsetY: -5
          }
        },
        annotations: {
          position: 'back',
          xaxis: [
            {
              x: this.avgTraffic,
              strokeDashArray: 6,
              borderWidth: 2
            }
          ],
          yaxis: [
            {
              y: this.avgConversion,
              strokeDashArray: 6,
              borderWidth: 2
            }
          ]
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
            <div class="tooltip-container flex column q-card">
            <div class="q-card__section q-card__section--vert">
              <div class="text-bold q-my-md">
                  ${this.seriesB[seriesIndex].data[dataPointIndex] &&
                    this.seriesB[seriesIndex].data[dataPointIndex][3].name}
                  </div>
                <div class="tooltip-title q-mb-sm flex items-center">
                  <div class="tooltip-title-color" style="background:${
                    w.config.colors[seriesIndex]
                  }"></div>
                  <label>${this.seriesB[seriesIndex].name}</label>
                  </div>
                    ${
                      !this.hasStatPresent
                        ? `<div class="flex items-center">
                    ${this.getLabel(this.comparekpi)}
                    <div class="q-ml-xs text-bold tool-tip-value">
                        ${this.seriesB[seriesIndex].data[dataPointIndex] &&
                          this.seriesB[seriesIndex].data[
                            dataPointIndex
                          ][3].value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      ${this.$t('BoxComparison.unit')}
                    </div>
                  </div>`
                        : ''
                    }
                    ${this.getTooltip(
                      w.config.series[seriesIndex].data[dataPointIndex]
                    )}
              </div>
              </div>`;
          }
        },
        responsive: [
          {
            breakpoint: 769,
            options: {
              legend: {
                horizontalAlign: 'left'
              }
            }
          },
          {
            breakpoint: 426,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }; // chartOptionsB
    },
    async setData() {
      try {
        const max = Math.max(
          ...this.series.map(x => x['value'].replace(/,/g, ''))
        );

        const min = Math.min(
          ...this.series.map(x => x['value'].replace(/,/g, ''))
        );

        let marketing_oportunity = {
          name: this.$t('BoxComparison.MarketingOpportunity'),
          data: []
        };
        let low_performance = {
          name: this.$t('BoxComparison.LowPerformance'),
          data: []
        };
        let hight_performance = {
          name: this.$t('BoxComparison.HighPerformance'),
          data: []
        };
        let conversion_oportunity = {
          name: this.$t('BoxComparison.ConversionOpportunity'),
          data: []
        };
        let maxYAxis = Math.max(
          ...this.series.map(x =>
            parseInt(x.yAxisValue.replace('%', '').trim())
          )
        );
        this.series.forEach(serie => {
          let data = [
            parseInt(serie.xAxisValue, 10),
            parseInt(serie.yAxisValue),
            this.calculateBallSize(
              parseInt(serie.value.replace(/,/g, ''), 11),
              max
            ),
            {
              name: serie.valueLabel,
              value: serie.formattedYAxisValue,
              sales: serie.sls,
              conversion: serie.cn,
              avgpurchase: serie.ap,
              capturerate: serie.cr,
              traffic: serie.xAxisValue
            }
          ];
          this.perConversion = 0;
          this.perConversion = Number(
            serie.yAxisValue
              .replace('%', ' ')
              .replace('％', '')
              .trim()
          );
          if (serie.xAxisValue > this.avgTraffic) {
            if (this.perConversion > this.avgConversion) {
              hight_performance.data.push(data);
            } else {
              conversion_oportunity.data.push(data);
            }
          } else {
            if (this.perConversion > this.avgConversion) {
              marketing_oportunity.data.push(data);
            } else {
              low_performance.data.push(data);
            }
          }
        });
        this.seriesB = [];
        this.seriesB.push(marketing_oportunity);
        this.seriesB.push(low_performance);
        this.seriesB.push(hight_performance);
        this.seriesB.push(conversion_oportunity);
        let trafficDat = this.series
          .map(v => {
            return +v.trf.replace(/[^0-9]/g, '');
          })
          .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        let dataLen = trafficDat.length;
        this.minVal = 0;
        this.maxVal = 0;
        this.minVal = trafficDat[0];
        this.maxVal = trafficDat[trafficDat.length - 1];
        let valRange = this.maxVal - this.minVal;
        let rangeFactor = valRange < 0 ? valRange * -1 : valRange;
        this.options = {
          ...this.options,
          yaxis: {
            ...this.options.yaxis,
            max: maxYAxis + Math.ceil(maxYAxis * 0.16),
            labels: {
              formatter: value => {
                return (
                  formatYAxis(value.toFixed(0))
                  // +
                  // this.$t(`unit.${this.comparekpi}Unit`)
                );
              }
            },
            title: {
              text: this.getLabel(this.comparekpi)
            }
          },
          xaxis: {
            ...this.options.xaxis,
            min: 0,//this.minVal - rangeFactor * 0.75,
            max: this.maxVal + rangeFactor * 0.25
          }
        };
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      }
    },
    calculateBallSize(value, max) {
      return (value * 6) / max + 5;
      // return (value / max) * (20 - 15 + 1) + 5;
    },
    calculateMaxValue(maxValue) {
      const numToSeparate = maxValue;
      const arrayOfDigits = Array.from(String(numToSeparate), Number);
      const firstElement = arrayOfDigits[0].toString();
      const length = numToSeparate.toString().length;
      const padding = firstElement.padStart(length + 1, '0');
      let reverse = padding
        .split('')
        .reverse()
        .join('');
      reverse = +reverse;
      return (1 + maxValue / reverse) * maxValue;
    },
    calculateMinimumValue(minValue) {
      const numToSeparate = minValue;
      const arrayOfDigits = Array.from(String(numToSeparate), Number);
      const firstElement = arrayOfDigits[0].toString();
      const length = numToSeparate.toString().length;
      const padding = firstElement.padStart(length + 1, '0');

      let reverse = padding
        .split('')
        .reverse()
        .join('');
      reverse = +reverse;
      return (1 - minValue / reverse) * minValue;
    }
  }
};
