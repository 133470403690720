import Button from '@/components/Common/Button.vue';
import Buttons from '@/components/Common/Buttons.vue';
import Field from '@/components/Common/Field.vue';
import Input from '@/components/Common/Input.vue';
import CommonFormMixin from '@/components/Common/Mixins/FormMixin';
import Select from '@/components/Common/Select.vue';

export default {
  mixins: [CommonFormMixin],
  components: {
    'm-btn': Button,
    'm-btns': Buttons,
    'm-field': Field,
    'm-input': Input,
    'm-select': Select
  }
};
