
import { createColumn, formatAsDateTime } from '@/util/table';

export default {
  props: {
    input: { type: Array, default: () => [] },
    appends: { type: Array, default: () => {} },
    loading: { type: Boolean, default: false },
    pagination: { type: Object, default: () => ({}) }
  },
  computed: {
    columns() {
      const columnList = [
        createColumn('id', 'Order ID'),
        createColumn('company', 'Company'),
        createColumn('store', 'Store'),
        createColumn('requestDate', 'Request Date', formatAsDateTime)
      ];

      return [...columnList, ...this.appends, createColumn('action', 'Action')];
    }
  }
};
