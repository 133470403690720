
import Vue from 'vue';
import { QImg } from 'quasar';

export default Vue.extend({
  render: function(h) {
    return h(QImg, {
      props: {
        src: 'img/logo/footerIcon.png'
      } as QImg
    });
  } // render
});
