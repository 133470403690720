
import { mapMutations, mapState } from 'vuex';
// import { kpiListGet } from '@/util/mockData/kpiMapping';
export default {
  data() {
    return {
      search: '',
      markedOptions: [],
      displayedKpiOptions: [],
      kpiListOrder: []
    };
  },
  computed: {
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('user', ['kpiLabelValue']),
    allKpiOption() {
      return this.kpiListOrder.map(kpi => ({
        label: kpi.label,
        value: kpi.value
      }));
    }
  },
  watch: {
    search(searchText) {
      const filteredKpi =
        searchText !== ''
          ? this.allKpiOption.filter(
              option =>
                option.label
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
            )
          : this.allKpiOption;
      this.displayedKpiOptions = [...filteredKpi];
    }
  },
  async created() {
    // const kpiList = await kpiListGet();
    // this.kpiListOrder = [...kpiList];

    this.kpiListOrder = [...this.kpiLabelValue];
    this.markedOptions = [...this.selectedKPIOptions];
    this.displayedKpiOptions = [...this.allKpiOption];
    // this.displayedKpiOptions = [...this.allKpiOption, {label:this.$store.state.user.translate.target_achievement_rate, value:'targetAchive'}];
  },
  methods: {
    ...mapMutations('distribution', ['setPersistentKPI']),
    submit() {
      if (this.markedOptions.length < 4) {
        return false;
      }
      this.setPersistentKPI(this.markedOptions);
      this.$refs.filterKPI.hide();
    },
    cancel() {
      this.markedOptions = [...this.selectedKPIOptions];
      this.$refs.filterKPI.hide();
    }
  }
};
