
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    }, // name
    options: {
      type: Object,
      required: false,
      default: () => {}
    } // options
  } // props
}; // export default
