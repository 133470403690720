
export default {
  data() {
    return {
      dataTable: [
        {
          name: 'Tutuanna',
          val1: 365,
          val2: '171'
        },
        {
          name: 'Tutuanna Store',
          val1: 365,
          val2: '171'
        },
        {
          name: 'Keyword',
          val1: 365,
          val2: '171'
        },
        {
          name: 'Keyword',
          val1: 365,
          val2: '171'
        },
        {
          name: 'Keyword',
          val1: 365,
          val2: '171'
        }
      ],
      overviewSelection: 'region'
    };
  }
};
