export default {
  data() {
    return {
      areaUnits: [
        { label: 'sqm', value: 'SQM' },
        { label: 'tsubo', value: 'TSUBO' }
      ]
    };
  }
};
