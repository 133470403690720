
import { deepObjectAssign } from '@/util/component';
import Store from '@/models/Store';
import CommonMixin from '@/mixins/CommonMixin';
import merge from 'lodash/merge';

export default {
  mixins: [CommonMixin],
  props: {
    value: { type: Object, default: () => {} }
  },
  data() {
    return {
      filters: {
        selectedStore: [],
        selectedPeriod: {
          from: null,
          to: null
        },
        typeFilters: []
      }
    };
  },
  async created() {
    const types = await this.$repo.billing.getBillingRecordTypes();
    this.filters.typeFilters = types.map(t => ({ label: t, value: t }));
  },
  methods: {
    selectStore(stores: Store[]) {
      const latest = stores.pop();
      if (latest == null) {
        this.filters.selectedStore = [];
        this.updateFilter({ storeId: null });
      } else {
        this.filters.selectedStore = [latest];
        this.updateFilter({ storeId: latest.id });
      }
    },
    selectPeriod(value: string, key: string) {
      const filters = deepObjectAssign(key, value);
      this.filters = merge({}, this.filters, filters);
      this.updateFilter({
        billingDate: {
          from: this.filters.selectedPeriod.from,
          to: this.filters.selectedPeriod.to
        }
      });
    },
    updateFilter(update = {}) {
      const updated = {
        ...this.value,
        ...update
      };
      this.$emit('input', updated);
    }
  }
};
