
const render = (h, context) => {
  const { $style, data, children, listeners } = context;
  const options = {
    class: [$style.form, data.class],
    on: listeners
  };
  return h('form', options, children);
};

export default {
  name: 'LoginForm',
  functional: true,
  render
};
