export interface Criterions {
  [key: string]: string;
}

export const buildQuery = (criterions: Criterions) => {
  return Object.keys(criterions)
    .map(key => {
      const value = criterions[key];
      return `${key}:${value}`;
    })
    .join(',');
};
