
import InformationBody from './InformationBody.vue';
import { BaseCompany } from '@/models/Company';
import { mapGetters } from 'vuex';

export default {
  components: {
    InformationBody
  },
  props: {
    data: BaseCompany
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    fields: function() {
      const { data } = this;
      const contact =
        data.contacts && data.contacts.length > 0
          ? data.contacts[0]
          : { phone: '' };
      return [
        {
          label: this.$t('AuthPage.company_name'),
          value: data.name
        },
        {
          label: this.$t('AuthPage.phonenumber'),
          value: contact.phone
        },
        {
          label: this.$t('AuthPage.industry'),
          value:
            this.getLocale === 'ja' ? data.industryNameJp : data.industryName
        }
      ];
    }
  }
};
