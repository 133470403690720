
import moment from 'moment';
export default {
  props: {
    staffDetails: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {};
  },
  watch: {
    staffDetails(newValue) {}
  },
  created() {},
  methods: {
    getTime(val) {
      return val ? moment(new Date(val)).format('LT') : '';
    }
  }
};
