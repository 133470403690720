/**
 *  Color pallet
 */
import store from '../store';
/**
 * Primary Colors
 */
export const primary: string[] = ['#3290ED', '#A7C040'];

/**
 * Secondary Colors
 */
export const secondary: string[] = [
  '#F7B500',
  '#F5405D',
  '#3290ED',
  '#9D53F2',
  '#26AB95',
  '#A7C040'
];

export const MONO_TONE = ['#3989f5'];
export const DUO_TONES = ['#3989f5', '#aac7fa', '#FEB019', '#ffa5008a'];
export const TRI_TONES = ['#3989f5', '#aac7fa', '#737373'];
export const QUAD_TONES = ['#418CFF', '#F7B500', '#418CFF', '#F7B500'];
export const QUAD_RADAR = ['#3290ED', '#EEB03B', '#A0BF15', '#B2B2B2'];
export var MULTI_COLORS = [
  '#3290ED',
  '#A7C040',
  '#F7B500',
  '#F5405D',
  '#9D53F2',
  '#26AB95',
  '#404040',
  '#737373'
];
export const getTransMethod = (key, index) => {
  // console.log(key, index, 'keys')
  const storeState: any = store.state;
  const translate = storeState.user.translate;
  const id = storeState.user.profile.tenant._id;

  if(key == 'Male <20' || key == "Male-under 10's"){
    //
    if(translate[`male_child_${id}`]){
      MULTI_COLORS[index] = translate[`male_child_color_${id}`]
      return translate[`male_child_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`male_child_color`]
      return translate[`male_child`]
    }
    //
  }
  else if(key == 'Male 20-30' || key == "Male-20's"){
    //
    if(translate[`male_young_${id}`]){
      MULTI_COLORS[index] = translate[`male_young_color_${id}`]
      return translate[`male_young_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`male_young_color`]
      return translate[`male_young`]
    }
    //
  }

  else if(key == 'Male 30-50' || key == "Male-30/40's"){
    //
    if(translate[`male_adult_${id}`]){
      MULTI_COLORS[index] = translate[`male_adult_color_${id}`]
      return translate[`male_adult_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`male_adult_color`]
      return translate[`male_adult`]
    }
    //
  }
  else if(key == 'Male >50' || key == "Male-over 50's"){
    //
    if(translate[`male_senior_${id}`]){
      MULTI_COLORS[index] = translate[`male_senior_color_${id}`]
      return translate[`male_senior_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`male_senior_color`]
      return translate[`male_senior`]
    }
    //
  }
  else if(key == 'Female <20' || key == "Female-under 10's"){
    //
    if(translate[`female_child_${id}`]){
      MULTI_COLORS[index] = translate[`female_child_color_${id}`]
      return translate[`female_child_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`female_child_color`]
      return translate[`female_child`]
    }
    //
  }
  else if(key == 'Female 20-30' || key == "Female-20's"){
    //
    if(translate[`female_young_${id}`]){
      MULTI_COLORS[index] = translate[`female_young_color_${id}`]
      return translate[`female_young_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`female_young_color`]
      return translate[`female_young`]
    }
    //
  }
  else if(key == 'Female 30-50' || key == "Female-30/40's"){
    //
    if(translate[`female_adult_${id}`]){
      MULTI_COLORS[index] = translate[`female_adult_color_${id}`]
      return translate[`female_adult_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`female_adult_color`]
      return translate[`female_adult`]
    }
    //
  }
  else if(key == 'Female >50' || key == "Female-over 50's"){
    //
    if(translate[`female_senior_${id}`]){
      MULTI_COLORS[index] = translate[`female_senior_color_${id}`]
      return translate[`female_senior_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`female_senior_color`]
      return translate[`female_senior`]
    }
    //
  }
  else if(key == 'male'|| key == 'Male')// this.getLocale === 'ja' ? v.gender : v.genderEN)
  {
    return translate[`male`]? translate[`male`] :'Male'
  }
  else if(key == 'female' || key == 'Female')// this.getLocale === 'ja' ? v.gender : v.genderEN)
  {
    return translate[`female`]? translate[`female`] :'Female'
  }
  else{
    var color = MULTI_COLORS[index]
    MULTI_COLORS[index] = color
    return key
  }
};

export const getLabelTransMethod = (key, index) => {
  // console.log(key, index, 'keys')
  const storeState: any = store.state;
  const translate = storeState.user.translate;
  const id = storeState.user.profile.tenant._id;

  if(translate[`male_child_${id}`])
  if(key == 'Male <20' || key == "Male-under 10's"){
    //
    if(translate[`male_child_${id}`]){
      MULTI_COLORS[index] = translate[`male_child_color_${id}`]
      return translate[`male_child_${id}`]
    }else{
      MULTI_COLORS[index] = translate[`male_child_color`]
      return translate[`male_child`]
    }
    //
  }

};
export default { primary, secondary };
