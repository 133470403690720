
import AddDomain from "@/components/modals/AddDomain.vue";
import tenants from "@/services/tenants";

export default {
  components: { AddDomain },
  data() {
    return {
      openModel: false,
      columns: [
        // {
        //   name: "id",
        //   align: "center",
        //   label: this.$store.state.user.translate.id,
        //   field: "id",
        //   sortable: true,
        // },

        { name: "domain", label: this.$store.state.user.translate.domain, field: "domain" },
      ],
      whitelistDomains: [],
       pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
      visible: false,
      loading: false,
    }
  },
    computed: {
    pagesNumber() {
      return Math.ceil(this.whitelistDomains.length / this.pagination.rowsPerPage);
    },
  },
  mounted() {
    this.visible = true
    this.getDomainWhiteList()
  },
  methods: {
    closeModel() {
      this.openModel = !this.openModel
    },
    getDomainWhiteList() {
      this.visible = true
      this.loading = true
      tenants.getTenantDetails().then((response) => {
        this.whitelistDomains = response.data.response.whitelistDomains.reverse()
        this.visible = false
        this.loading = false
      }).catch((error) => {
        // console.log(error)
        this.visible = false
        this.loading = false
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
      })
    },
    async updateWhitelistDomains() {
      await this.getDomainWhiteList()
      this.openModel = false
    }
  }
};
