
import { mapState, mapActions, mapMutations } from 'vuex';
import uniq from 'lodash/uniq';
import { APICreatePreferences, APIGetSavedPreferences } from '@/api/kpi';

export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    } // elevated
  }, // props

  data() {
    return {
      search: '',
      markedOptions: [],
      storeCodesSelected: []
    };
  },
  computed: {
    ...mapState('filter', ['filter', 'stores']),
    ...mapState('store', ['stores']),
    ...mapState('user', ['profile']),
    allStores() {
      if (this.stores.regions) {
        let stores = this.stores.regions
          .map(region => region.districts)
          .flat()
          .map(district => district.stores)
          .flat();

        return (
          stores
            // Create an array of object containing label and value
            .map(store => store.storeCode)
        );
      } // if (this.stores.regions)

      return [];
    }, // options

    options() {
      if (this.stores) {
        let areaManagers = {};
        let perGroups = {};
        let regions = {};
        let districts = {};
        areaManagers.name = this.$t('FilterStoreLocation.area_managers');
        areaManagers.children = this.stores.areaManagers.map((v, key) => {
          return {
            name: v.name,
            children:
              v.stores.map(val => ({
                name: val.storeCode
              })) || []
            // .filter(v => this.profile.stores?.includes(v.name))
          };
        });
        // .filter(v => v.children.length > 0);
        perGroups.name = this.$t('FilterStoreLocation.peer_groups');
        perGroups.children = this.stores.peerGroups.map((v, key) => {
          return {
            name: v.name,
            children: v.stores.map(val => ({
              name: val.storeCode 
            }))
            // + " (" + v.stores.length + ")"
            // .filter(v => this.profile.stores?.includes(v.name))
          };
        });
        // .filter(v => v.children.length > 0);
        regions.name = this.$t('FilterStoreLocation.regions');
        regions.children = this.stores.regions
          .map(({ name, districts, key }) => {
            return {
              name,
              stores: districts.map(({ stores }) => stores).flat()
            };
          })
          .map((v, key) => {
            return {
              name: v.name,
              children: v.stores.map(val => ({
                name: val.storeCode
              }))
              // .filter(v => this.profile.stores?.includes(v.name))
            };
          });
        // .filter(v => v.children.length > 0);
        districts.name = this.$t('FilterStoreLocation.districts');
        districts.children = this.stores.regions
          .map(region => region.districts)
          .flat()
          .map((v, key) => {
            return {
              name: v.name,
              children: v.stores.map(val => ({
                name: val.storeCode
              }))
              // .filter(v => this.profile.stores?.includes(v.name))
            };
          });
        // .filter(v => v.children.length > 0);
        return {
          areaManagers: [areaManagers],
          perGroups: [perGroups],
          regions: [regions],
          districts: [districts]
        };
      }

      return [];
    }, // options
    loading() {
      if (Object.values(this.options)[0][0].children.length) {
        return false;
      } else {
        return false;
      }
    }
  }, // created
  watch: {
    'filter.storeCodes': {
      handler: 'changeStoreCodes'
    }
  }, // computed
  async created() {
    // Set default selection

    // var urlPage = window.location.pathname;
    var urlPage = "/";
    var storesList;
    let preSelectedStores;
//can comment
    storesList = await APIGetSavedPreferences(urlPage);
    let hasList = typeof storesList == 'object' ? storesList.length > 0 : storesList != null;
    if (hasList) {
      var currentStores = localStorage.getItem('storeCodes') ?? '';
      if (storesList.toString() != currentStores) {
        this.$emit('update:selectedOptions', this.storesList);
        this.setStoreCodes({ storeCodes: storesList });
      }
      preSelectedStores = storesList.toString();
    } else {
      preSelectedStores = localStorage.getItem('firstList') ?? '';
      let array = preSelectedStores.split(',');
      this.$emit('update:selectedOptions', array);
      this.setStoreCodes({ storeCodes: array });
    }
    await this.fetchStores();
    if (preSelectedStores !== '') {
      this.markedOptions = preSelectedStores?.length
        ? preSelectedStores.split(',')
        : [];
    }
  },
  methods: {
    ...mapActions('store', ['fetchStores']),
    ...mapMutations('filter', ['setStoreCodes']),
    changeStoreCodes() {
      this.markedOptions = this.filter.storeCodes;
    },
    /**
     * Selects all options
     *
     * FIXME: This functino need to be rewrited to adapt to the new implementation.
     * NOTE: it's not being used.
     */
    storeSelectAll() {
      this.markedOptions = this.options.map(store => store.value);
      this.refreshPage();
    }, // storeSelectAll
    /**
     * Unselect all options
     * NOTE: it's not being used.
     */
    storeUnselectAll() {
      this.markedOptions = [];
      this.refreshPage();
    }, // storeUnselectAll
    /**
     * Update selected options before closing the filter
     */
    async submit() {
      // Update selected options
      // this.selectedOptions = this.markedOptions;
      // Updates the parent

      this.storeCodesSelected = this.markedOptions.splice(
        0,
        this.markedOptions.length,
        ...new Set(this.markedOptions)
      );
      let actualStores = this.storeCodesSelected.filter(element =>
        this.allStores.includes(element)
      );
      if (this.storeCodesSelected.length !== 0) {
        this.$emit('update:selectedOptions', this.storeCodesSelected);
        this.setStoreCodes({ storeCodes: actualStores });

        var urlPage = window.location.pathname;
        await APICreatePreferences(urlPage, actualStores);
        localStorage.removeItem('storeCodesDashboardF');
        localStorage.removeItem('storeCodesExportF');
        localStorage.removeItem('storeCodesDetailedReportF');

        localStorage.removeItem('traffic-reportF');
        localStorage.removeItem('location-reportF');
        localStorage.removeItem('demographics-reportF');
        localStorage.removeItem('location-comparison-reportF');
        localStorage.removeItem('queue-reportF');
        ///traffic-report

        this.$refs.filterStoreLocation.hide();
      } else {
        this.$q.notify({
          type: 'negative',
          message:
            'This location does not have any store associated with it. Please select another location'
        });
      }
    }, // submit
    /**
     * Closes the filter without saving selected options
     */
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterStoreLocation.hide();
    } // hide
  }
};
