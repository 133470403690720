export function convertApiResponseAllStores(apiResponse) {
  const convertedResponse = {};
  for (const item of apiResponse) {
    if (item.storeCode) {
      convertedResponse[item.storeCode] = {
        storeID: item.id,//parseInt(item.id, 36) % 100, // You can change this calculation as needed
        storeCode: item.storeCode,
        storeName: item.name,
      };
    }
  }

  return convertedResponse;
}
export function getWeekdayToday() {
  // Get the current date
  const today = new Date();
  
  // Array of weekday names
  const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  
  // Get the day index (0-6)
  const dayIndex = today.getDay();
  
  // Get the weekday name
  return weekdays[dayIndex];
}

export function sumKeysAndRemoveId(data) {
  // Initialize an object to store the sums of each key
  const sums = {};

  // Iterate through each object in the array
  data.forEach(item => {
      // Iterate through each key in the current object
      Object.keys(item).forEach(key => {
          // Skip the _id key
          if (key === '_id') return;

          // Get the value for the current key, default to 0 if it's null or undefined
          const value = item[key] ?? 0;

          // Initialize the sum for the key if it doesn't exist
          if (!sums[key]) {
              sums[key] = 0;
          }

          // Add the value to the corresponding key in sums
          sums[key] += value;
      });
  });

  // Return the result with sums and without _id
  return sums;
}
