
import { addWeeks, startOfWeek } from 'date-fns';
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
export default {
  data() {
    return {
      dateValue: {
        title: this.$t('title'),
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared,
        daysOfWeek: 'monday,tuesday,wednesday,thursday,friday'
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState'
    ])
  },
  mounted() {
    const datesBtwSlt = this.getBetweenDates(
      this.getPulseStartingPeriod,
      this.getPulseEndingPeriod
    );
    const datesBtwCpm = this.getBetweenDates(
      this.getPulseStartingCompared,
      this.getPulseEndingCompared
    );
    const [startDate, ...endDate] = this.weekendDates(datesBtwSlt);
    const [startDateC, ...endDateC] = this.weekendDates(datesBtwCpm);
    this.dateValue = {
      startDate: startDate,
      endDate: endDate[endDate.length -1],
      compareStartDate: startDateC,
      compareEndDate: endDateC[endDate.length -1],
      daysOfWeek: 'monday,tuesday,wednesday,thursday,friday',
      title: this.$t('title')
    };
  },
  methods: {
    ...mapMutations('periodicReport', ['setWeeklyLoading']),
    changeLoading(val) {
      this.setWeeklyLoading({ component: 'weekdaysTrend', value: val });
    },
    weekendDates(dateList) {
      const weekdays = [];
      dateList.forEach(date => {
        const currentMoment = moment(date);
        if (!(currentMoment.day() === 0 || currentMoment.day() === 6)) {
          weekdays.push(currentMoment.format('YYYY-MM-DD'));
        }
      });
      return weekdays;
    },
    getBetweenDates(startDate, endDate) {
      const dates = [];
      let currentDate = moment(startDate);
      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
      }
      return dates;
    }
  }
};
