
import Vue from 'vue';
import flatMap from 'lodash/flatMap';
import { colors, kpis } from '@/util';
import { APIFetchStoreDistribution, APIFetchStoresList } from '@/api/store';
import { formatYAxis } from '@/util/legend';
import { mapState, mapActions, mapGetters } from 'vuex';
import { APIFetchOverview } from '@/api/overview';
import {
  GetReportingSumDataByLocationFidaV2,convertApiResponseForComparisonReport
} from '@/api/fida';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';

export default Vue.extend({
  props: {
    kpis: {
      type: Array,
      required: true
    },
    period: {
      type: Object,
      default: () => {}
    },
    peergroupssel: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectedPeerGroupsList: [],
      data: [],
      columns: [],
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: 'radar'
        },
        fill: {
          opacity: 0
        },

        title: {
          // text: 'Basic Radar Chart'
        },
        colors: colors.secondary,
        xaxis: {
          categories: []
        },
        // yaxis: { logarithmic: true },
        yaxis: {
          tickAmount: 5,
          labels: {
            offsetX: 20,
            formatter: val => {
              return formatYAxis(val) + '%';
            }
          }
        },
        legend: {
          show: false
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: true
          }
        }
      },
      loading: false,
      labels: [],
      colors: []
    };
  },
  computed: {
    ...mapState('store', ['stores']),
    orderedKPI() {
      return this.kpis.map(kpi => this.$t(`KPI.${kpi}`));
    }
  },
  watch: {
    async peergroupssel(newVal) {
      this.selectedPeerGroupsList = newVal;
      this.loadData();
    },
    async stores() {
      this.loadData();
    }
  },
  async created() {
    this.selectedPeerGroupsList = this.peergroupssel;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.loadKpiMetrics();
      this.loading = false;
    },
    async loadKpiMetrics() {
      if (this.selectedPeerGroupsList.length > 0) {
        var groupList = this.options2();

        let values = [];
        let _columns = [];
        let _kpiIndex = null;
        let chartCategories = [];
        let chartSeries = [];
        let fetchData = [];

        _columns.push({
          name: 'kpi',
          required: true,
          label: 'KPI',
          align: 'left',
          field: 'label'
        });
        for (let j = 0; j < groupList.length; j++) {
          // var groupName = groupList[j].name;
          var currentStores = groupList[j].children.map(x => x.name);

          if (currentStores.length != 0) {
            let res;

            const response2 = await GetReportingSumDataByLocationFidaV2({
              startDate: this.period.start,
              endDate: this.period.end,
              "storeCodes": currentStores,
              "kpiTypes": this.kpis,
              "frequency": "all",
                "page": "comparisonOther"

            });
            const res2 = {data:
            {startDate:this.period.start,endDate:this.period.end,kpis:response2.kpis}};
            res  = convertApiResponseForComparisonReport(res2.data);



            var res1 = flatMap(res?.data[0]?.kpis);
            fetchData.push(res1);
          }
        }
        let data1 = [];
        data1.push({
          data: {
            kpis: fetchData
          }
        });
        const data = data1[0].data;
        if (data != null) {
          var count = 0;
          Object.entries(data.kpis).map(store => {
            var groupName = groupList[count].name;
            count++;
            _columns.push({
              name: groupName,
              label: groupName,
              field: groupName
            });
            // store[1].sort((x, y) => {
            //   return (
            //     this.orderedKPI.indexOf(x.metricKey) -
            //     this.orderedKPI.indexOf(y.metricKey)
            //   );
            // });
            store[1].forEach(kpi => {
              _kpiIndex = values.findIndex(e => {
                return e.label === kpi.metricKey;
              });
              if (_kpiIndex == -1) {
                chartCategories.push(kpi.metricKey);

                values.push({
                  name: kpi.metricKey.toLowerCase(),
                  label: kpi.metricKey,
                  [groupName]: kpi.value
                });
              } else {
                values[_kpiIndex] = {
                  ...values[_kpiIndex],
                  [groupName]: kpi.value
                };
              }
            });
            let p = store[1].map(kpi => {
              return kpi.value.replace(/[^\d.-]/g, '');
            });
            chartSeries.push({
              name: groupName,
              rawData: p
            });
          });

          this.columns = _columns;
          this.data = values;

          this.$refs.chart.updateOptions({
            xaxis: {
              categories: chartCategories
            }
          });
          let beingComparedTo = chartSeries[0];
          for (let i = 0; i < chartSeries.length; i++) {
            let _data = [];
            for (let j = 0; j < chartSeries[i].rawData.length; j++) {
              _data.push(
                (
                  (chartSeries[i].rawData[j] / beingComparedTo.rawData[j]) *
                  100
                ).toFixed(2)
              );
            }

            chartSeries[i].data = _data;
          }
          this.$refs.chart.updateSeries(chartSeries);
          this.$refs.chart.toggleDataPointSelection(0, 0);
          this.$refs.chart.toggleDataPointSelection(1, 0);
          this.$refs.chart.toggleDataPointSelection(2, 0);
        }
      }
    },
    options2() {
      if (this.stores) {
        let perGroups = {};
        perGroups = this.stores.peerGroups.map((v, key) => {
          for (var i = 0; i < this.selectedPeerGroupsList.length; i++) {
            if (this.selectedPeerGroupsList[i] == v.name) {
              return {
                name: v.name,
                children: v.stores.map(val => ({
                  name: val.storeCode
                }))
              };
            }
          }
        });
        perGroups = perGroups.filter(function(element) {
          return element !== undefined;
        });
        return perGroups;
      }
      return [];
    },
    rowClick(evt, row, index) {
      this.$emit('rowClick', {
        kpi: row.label
      });
    }
  }
});


