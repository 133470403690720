
import CommonMixin from '@/mixins/CommonMixin';
import PeerGroup from '@/models/PeerGroup';
import { createColumn } from '@/util/table';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import ConfirmDeleteDialog from '@/components/Common/Dialog/ConfirmDeleteDialog.vue';
import { mapState } from 'vuex';
import DeleteDialog from '@/components/Common/Dialog/DeleteDialog.vue';
import { checkPermission } from '@/api/utils';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'PeerGroupTable',
  components: { DeleteDialog },
  // components: { ConfirmDeleteDialog },
  mixins: [CommonMixin, PaginationMixin],
  props: {
    data: { type: Array, required: true, default: () => [] },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        createColumn('title', this.$t('SettingsManagement.PeerGroup')),
        createColumn('action', this.$t('SettingsManagement.actions'))
      ],
      rowDataSelected: {},
      deleteConfirmationShown: false
    };
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    async deletePeerGroup() {
      try {
        const msgs = this.$t('SettingsManagement.successfully_deleted');
        await this.$repo.peerGroup.deletePeerGroup(
          this.rowDataSelected.id,
          msgs
        );
        this.$emit('reload');
      } catch (err) {
        handleErrorUI(err);
      }
    },
    showDeleteConfirmation(peerGroup) {
      this.deleteConfirmationShown = true;
      this.rowDataSelected = peerGroup;
    }
  }
};
