import CityController from '@/services/location/CityController';
import CountryController from '@/services/location/CountryController';
import DistrictController from '@/services/location/DistrictController';
import { LocationService } from '@/services';
import PrefectureController from '@/services/location/PrefectureController';
import RegionController from '@/services/location/RegionController';

export default class LocationRepository {
  private countryController: CountryController;
  private regionController: RegionController;
  private prefectureController: PrefectureController;
  private districtController: DistrictController;
  private cityController: CityController;

  constructor(service: LocationService) {
    this.countryController = service.country;
    this.regionController = service.region;
    this.prefectureController = service.prefecture;
    this.districtController = service.district;
    this.cityController = service.city;
  }

  public async getCountries() {
    const { content } = await this.countryController.getCountries();
    return content;
  }

  public async getRegions() {
    const { content } = await this.regionController.getRegions();
    return content;
  }

  public async getPrefectures() {
    const { content } = await this.prefectureController.getPrefectures();
    return content;
  }

  public async getDistricts() {
    const { content } = await this.districtController.getDistricts();
    return content;
  }

  public async getCities() {
    const { content } = await this.cityController.getCities();
    return content;
  }

  public async getRegionsByCountry({ id }) {
    const { content } = await this.regionController.getRegionsByCountry(id);
    return content;
  }

  public async getDistrictsByRegion(regionId) {
    const { content } = await this.districtController.getDistrictsByRegion(
      regionId
    );
    return content;
  }

  public async getPrefecturesByDistrict(districtId) {
    const {
      content
    } = await this.prefectureController.getPrefecturesByDistrict(districtId);
    return content;
  }

  public async getCitiesByPrefecture(prefectureId) {
    const { content } = await this.cityController.getCitiesByPrefecture(
      prefectureId
    );
    return content;
  }
}
