export default {
  props: {
    value: { type: Boolean, required: true }
  },
  methods: {
    updateShown(shown: boolean) {
      this.$emit('input', shown);
    },
    closeModal() {
      this.updateShown(false);
    }
  }
};
