import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=835e05cc&"
import script from "./Page.vue?vue&type=script&lang=ts&"
export * from "./Page.vue?vue&type=script&lang=ts&"
import style0 from "./Page.vue?vue&type=style&index=0&id=835e05cc&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection})
