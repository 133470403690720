
import { mergeToAttrs, normalizeProps } from '@/util/component';

export default {
  functional: true,
  render: (h, context) => {
    const $style = (context as any).$style;
    const { data, children, listeners } = context;
    const props = normalizeProps(context.props);
    const options = {
      props,
      attrs: {
        class: mergeToAttrs(
          data.class,
          data.staticClass,
          $style.container,
          props.centered ? $style.centered : null,
          props.fullWidth ? $style.fullWidth : null
        ),
        style: mergeToAttrs(data.style, data.staticStyle)
      },
      on: listeners
    };
    return h('div', options, children);
  }
};
