import TaxController from '../services/management/TaxController';

export default class TaxRepository {
  private readonly taxController: TaxController;

  constructor(taxController: TaxController) {
    this.taxController = taxController;
  }

  public async getTax() {
    return await this.taxController.getTax();
  }
}
