
import stores from '@/services/stores';
import brands from '@/services/brands';
import resources from '@/services/resources';
import moment from 'moment';
import Vue from 'vue';
import store from '@/store/';
// import { validate } from "vee-validate";

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedStore', 'storeList'],

  data() {
    return {
      disableStatus: 0,
      companyPhone: '',
      dateOpen: '',
      dateClose: '',
      brandNames: [],
      brandList: [],
      loadingBtn: false,
      storetypeList: [],
      selectionDays: {
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true
      },
      val: false,
      configuration: true,
      storeName: '',
      storeNumber: '',
      sundayStartTime: '10:00',
      mondayStartTime: '10:00',
      tuesdayStartTime: '10:00',
      wednesdayStartTime: '10:00',
      thursdayStartTime: '10:00',
      saturdayStartTime: '10:00',
      fridayStartTime: '10:00',
      sundayEndTime: '20:00',
      mondayEndTime: '20:00',
      tuesdayEndTime: '20:00',
      wednesdayEndTime: '20:00',
      thursdayEndTime: '20:00',
      saturdayEndTime: '20:00',
      fridayEndTime: '20:00',
      storeBrand: '',
      storeType: '',
      loadedStore: {},
      storeStatus: [
        { label: this.$store.state.user.translate.open, value: '0' },
        { label: this.$store.state.user.translate.close, value: '1' }
      ],
      storeTypeOPtions: [
        {
          label: this.$store.state.user.translate.street_store,
          value: this.$store.state.user.translate.street_store
        },
        {
          label: this.$store.state.user.translate.within_facilities,
          value: this.$store.state.user.translate.within_facilities
        }
        // { label: "Street Store", value: "Street Store" },
        // { label: "Within Facilities", value: "Within Facilities" },
      ],
      status: '',
      floorLevel: '',
      storeArea: '',
      openingDate: '',
      countryCode: '',
      country: 109,
      region: null,
      district: '',
      prefecture: '',
      city: null,
      address: '',
      date: '2019/02/01',
      selectedRole: [],
      email: '',
      loading: false,
      countryOptions: [
        {
          id: 109,
          name: this.$store.state.user.translate.japan
        }
      ],
      districtOptions: [],
      prefectureOptions: [],
      regionOptions: [],
      cityOptions: [],
      allRegions: [],
      allCities: [],
      storeTimings: [],
      weekdays: [
        { label: 'monday', value: 'mon' },
        { label: 'tuesday', value: 'tues' },
        { label: 'wednesday', value: 'wednes' },
        { label: 'thursday', value: 'thurs' },
        { label: 'friday', value: 'fri' },
        { label: 'saturday', value: 'satur' },
        { label: 'sunday', value: 'sun' }
      ]
    };
  },
  computed: {
    adminCheck() {
      const { botAdmin, email } = this.$store.state.user.profile;
      return email == 'demo@flow-solutions.com' || email.startsWith('botadmin@') ? true : botAdmin;
    },
    title() {
      return this.selectedStore
        ? this.$store.state.user.translate.edit +
            ' ' +
            this.$store.state.user.translate.store
        : this.$store.state.user.translate.add +
            ' ' +
            this.$store.state.user.translate.store;
    },
    getHours() {
      const hoursD = [];
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? '0' + i + ':00' : i + ':00';
        hoursD.push(hour.toString());
      }
      return hoursD;
    }
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit('closeModel', false);
      }
    }
  },
  async mounted() {
    this.getBrands();
    this.getStoresType();
    var data = await resources.getCountries();
    // this.countryOptions = data.countries
    // this.countryOptions = data.countries.filter((c) => c.name == "Japan");
    this.allRegions = data.states;
    this.regionOptions = this.allRegions.map(n => n.name);
    // this.allCities = data.cities;
    // this.cityOptions = this.allCities..map(n => n.name);

    if (this.selectedStore) {
      this.floorLevel = this.selectedStore?.level;
      this.storeName = this.selectedStore.title;
      this.storeNumber = this.selectedStore.storeNumber;
      this.storeBrand = this.selectedStore?.brands?.toString();
      this.storeType = this.selectedStore?.types?.toString();
      // (this.storeBrand = this.brandList?.find(
      //   (c) => c._id == this.selectedStore.brands
      // )?.title),
      // (this.storeType = this.storetypeList?.find(
      //   (c) => c._id == this.selectedStore.types
      // )),
      // (this.storeType = this.selectedStore.storeType),
      // (this.status = this.selectedStore.status),
      var myOpenDate = new Date(
        this.selectedStore?.openDate
      ).toLocaleDateString();
      var myCloseDate = new Date(
        this.selectedStore?.closeDate
      ).toLocaleDateString();
      var formatedDate = moment(myOpenDate).format('YYYY-MM-DD');
      var formatedCloseDate = moment(myCloseDate).format('YYYY-MM-DD');

      (this.storeArea = this.selectedStore?.area),
        (this.dateOpen = formatedDate),
        // (this.dateClose = formatedCloseDate),
        // (this.country = this.countryOptions.find(
        //   (c) => c.id == this.selectedStore.locationDetails.country
        // ).id),
        // (this.status = this.storeStatus.find(
        //   (c) => c.value == this.selectedStore.status
        // )),
        (this.region = this.selectedStore?.locationDetails?.region),
        // (this.region = this.allRegions?.find(
        //   (c) => c.name == this.selectedStore?.locationDetails?.region
        // )?.name),
        (this.district = this.selectedStore?.locationDetails?.district),
        (this.prefecture = this.selectedStore?.locationDetails?.prefecture),
        (this.companyPhone = this.selectedStore?.locationDetails?.phone),
        (this.city = this.selectedStore?.cityId),
        (this.address = this.selectedStore?.address);
      if (this.selectedStore.status) {
        console.log('localStorage.getItem(isAdmin)',localStorage.getItem('isAdmin'));
        if(localStorage.getItem('isAdmin') == "true")
          this.disableStatus = 0;
        else
          this.disableStatus = 1;
        
        this.status = {
          label: this.$store.state.user.translate.close,
          value: '1'
        };
        this.dateClose = formatedCloseDate;
      } else {
        this.disableStatus = 0;
        this.status = {
          label: this.$store.state.user.translate.open,
          value: '0'
        };
      }
      this.updateDistrictDetails('false');
      this.updatePrefectureDetails('false');
      this.updateCityDetails('false');
      var arr = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ];
      var daysArr = [];
      this.selectedStore.storeTimings.forEach(v => {
        daysArr.push(v.day);
      });
      arr.forEach(el => {
        var check = daysArr.includes(el);
        if (!check) {
          this.selectionDays[el] = false;
        } else {
          this.selectionDays[el] = true;
        }
      });

      this.selectedStore.storeTimings?.forEach(element => {
        if (element.day === 'monday') {
          this.mondayStartTime = element.openingTime.slice(0, 5);
          this.mondayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'tuesday') {
          this.tuesdayStartTime = element.openingTime.slice(0, 5);
          this.tuesdayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'wednesday') {
          this.wednesdayStartTime = element.openingTime.slice(0, 5);
          this.wednesdayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'thursday') {
          this.thursdayStartTime = element.openingTime.slice(0, 5);
          this.thursdayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'friday') {
          this.fridayStartTime = element.openingTime.slice(0, 5);
          this.fridayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'saturday') {
          this.saturdayStartTime = element.openingTime.slice(0, 5);
          this.saturdayEndTime = element.closingTime.slice(0, 5);
        } else if (element.day === 'sunday') {
          this.sundayStartTime = element.openingTime.slice(0, 5);
          this.sundayEndTime = element.closingTime.slice(0, 5);
        }
      });
    }

    this.loadStore();
  },
  methods: {
    loadStore() {
      if (this.selectedStore) {
        this.loadedStore = JSON.parse(
          JSON.stringify(this.getDetails(true, true))
        );
      } else {
        this.loadedStore = {};
      }
    },
    diffLoadedStore() {
      let retVal = {};
      const currSt = JSON.parse(JSON.stringify(this.getDetails(true, true)));
      Object.keys(currSt).forEach(k => {
        if (this.loadedStore[k] !== currSt[k]) {
          retVal[k] = currSt[k];
        }
      });
      return retVal;
    },
    sendTrackingEvent(type) {
      let data = this.diffLoadedStore();
      let { profile } = store.state['user'];
      data.email = profile?.email;
      Vue.prototype?.$mixpanel?.track('webapp_store_' + type, data);
    },
    checkRegions() {
      if (!this.region) {
        this.$q.notify({
          message: this.$store.state.user.translate.select_prefectures,
          color: 'red'
        });
      }
    },
    getCountryName(code) {
      var name = this.countryOptions.find(c => c.id == code)?.name;
      return name;
    },
    getRegionName(code) {
      var name = this.allRegions.find(c => c.id == code)?.name;
      return name;
    },
    getCityName(code) {
      var name = this.allCities.find(c => c.city_id == code)?.city_name;
      return name;
    },
    getBrands() {
      brands.getBrands().then(response => {
        this.brandList = response.data.response?.brands;
        this.brandNames = this.brandList.map(v => v.title);
        this.storeBrand = this.selectedStore?.brands?.toString();
      });
    },
    getStoresType() {
      stores
        .getStoreTypes()
        .then(response => {
          this.storetypeList = response.data.response?.types;
          // var obj = this.storetypeList?.find((c) => c._id == this.selectedStore?.types);
          if (this.selectedStore?.types) {
            this.storeType = {
              label: this.selectedStore?.types.toString(),
              value: this.selectedStore?.types.toString()
            };
          }
        })
        .catch(error => {
          // console.log(error);

          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
        });
    },
    updateStateDetails(value) {
      this.allRegions.forEach(element => {
        if (element.country_id === value) {
          this.regionOptions.push(element);
          this.countryCode = element.country_code;
        }
      });
    },

    updateDistrictDetails(op) {
      let districtOptions = this.allRegions.find(
        element => this.region == element.name
      );
      if (op != 'false') {
        this.district = '';
        this.prefecture = '';
        this.city = '';
      }
      this.districtOptions = districtOptions?.districts.map(v => v.name);
    },
    updatePrefectureDetails(op) {
      let districtOptions = this.allRegions.find(
        element => this.region == element.name
      );
      if (op != 'false') {
        this.prefecture = '';
        this.city = '';
      }
      let preFecture = districtOptions?.districts.find(
        v => v.name === this.district
      );
      this.prefectureOptions = preFecture?.prefectures.map(v => v.name);
    },
    updateCityDetails(op) {
      let districtOptions = this.allRegions.find(
        element => this.region == element.name
      );
      if (op != 'false') {
        this.city = '';
      }
      let preFecture = districtOptions?.districts.find(
        v => v.name === this.district
      );
      let cities = preFecture?.prefectures.find(
        v => v.name === this.prefecture
      );
      this.cityOptions = cities?.cities;
    },
    validateDate() {
      const openDate = new Date(this.dateOpen);
      const closeDate = new Date(this.dateClose);
      if (openDate < closeDate) {
        return false;
      } else if (openDate >= closeDate) {
        this.$q.notify({
          message: this.$store.state.user.translate
            .closing_date_later_then_Opening,
          color: 'red'
        });
        return true;
      }
    },
    getDetails(getStoreId, emptyClosingDate) {
      let storeDetails = {
        ...(getStoreId && {
          storeId: this.selectedStore && this.selectedStore._id
        }),
        level: this.floorLevel,
        title: this.storeName,
        storeNumber: this.storeNumber,
        storeBrand: this.storeBrand,
        storeType: this.storeType?.value,
        status: parseInt(this.status.value),
        storeArea: this.storeArea,
        openingDate: this.dateOpen,
        closingDate: parseInt(this.status.value) ? this.dateClose : '',
        country: this.country,
        // countryCode: this.countryCode,
        companyPhone: this.companyPhone,
        region: this.region,
        prefecture: this.prefecture,
        district: this.district,
        area: this.city,
        address: this.address,
        storeTimings: this.storeTimings
      };

      if (emptyClosingDate) {
        storeDetails.closingDate = parseInt(this.status.value)
          ? this.dateClose
          : '';
      } else {
        if (parseInt(this.status.value) != 0) {
          storeDetails.closingDate = this.dateClose;
        }
      }
      return storeDetails;
    },
    saveUserDetails() {
      // validateion date
      if (this.validateDate()) return;
      //
      if (this.selectedStore) {
        if (this.selectionDays.sunday) {
          this.storeOpenCloseTime(
            'sunday',
            this.sundayStartTime,
            this.sundayEndTime
          );
        }
        if (this.selectionDays.monday) {
          this.storeOpenCloseTime(
            'monday',
            this.mondayStartTime,
            this.mondayEndTime
          );
        }
        if (this.selectionDays.tuesday) {
          this.storeOpenCloseTime(
            'tuesday',
            this.tuesdayStartTime,
            this.tuesdayEndTime
          );
        }
        if (this.selectionDays.wednesday) {
          this.storeOpenCloseTime(
            'wednesday',
            this.wednesdayStartTime,
            this.wednesdayEndTime
          );
        }
        if (this.selectionDays.thursday) {
          this.storeOpenCloseTime(
            'thursday',
            this.thursdayStartTime,
            this.thursdayEndTime
          );
        }
        if (this.selectionDays.friday) {
          this.storeOpenCloseTime(
            'friday',
            this.fridayStartTime,
            this.fridayEndTime
          );
        }
        if (this.selectionDays.saturday) {
          this.storeOpenCloseTime(
            'saturday',
            this.saturdayStartTime,
            this.saturdayEndTime
          );
        }
        let storeDetails = this.getDetails(true, true);

        this.loadingBtn = true;
        stores
          .updateStore(storeDetails)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: 'green'
            });
            this.loadingBtn = false;
            this.$emit('updateStore');
            this.sendTrackingEvent('updateStore');
          })
          .catch(error => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: 'red'
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: 'red'
              });
            }
            this.loadingBtn = false;
            this.$emit('closeModel', false);
          });
      } else {
        if (this.selectionDays.sunday) {
          this.storeOpenCloseTime(
            'sunday',
            this.sundayStartTime,
            this.sundayEndTime
          );
        }
        if (this.selectionDays.monday) {
          this.storeOpenCloseTime(
            'monday',
            this.mondayStartTime,
            this.mondayEndTime
          );
        }
        if (this.selectionDays.tuesday) {
          this.storeOpenCloseTime(
            'tuesday',
            this.tuesdayStartTime,
            this.tuesdayEndTime
          );
        }
        if (this.selectionDays.wednesday) {
          this.storeOpenCloseTime(
            'wednesday',
            this.wednesdayStartTime,
            this.wednesdayEndTime
          );
        }
        if (this.selectionDays.thursday) {
          this.storeOpenCloseTime(
            'thursday',
            this.thursdayStartTime,
            this.thursdayEndTime
          );
        }
        if (this.selectionDays.friday) {
          this.storeOpenCloseTime(
            'friday',
            this.fridayStartTime,
            this.fridayEndTime
          );
        }
        if (this.selectionDays.saturday) {
          this.storeOpenCloseTime(
            'saturday',
            this.saturdayStartTime,
            this.saturdayEndTime
          );
        }

        let storeDetails = this.getDetails(false, false);
        console.log('> storeDetails>', storeDetails);
        var isExist = this.storeList.find(
          v => v.storeNumber == this.storeNumber
        );
        if (isExist) {
          this.$q.notify({
            message: this.$store.state.user.translate.store_already_exist,
            color: 'red'
          });
          return false;
        }
        this.loadingBtn = true;
        stores
          .createStore(storeDetails)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate
                .store_created_successfully,
              color: 'green'
            });
            this.loadingBtn = false;
            this.$emit('updateStore');
            this.sendTrackingEvent('createStore');
          })
          .catch(error => {
            // console.log(error);
            if (error.response?.data?.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: 'red'
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: 'red'
              });
            }
            this.loadingBtn = false;
            this.$emit('closeModel', false);
          });
      }
    },

    storeOpenCloseTime(day, startTime, EndTime) {
      let openCloseTime = {
        day: day,
        openingTime: startTime,
        closingTime: EndTime
      };
      this.storeTimings.push(openCloseTime);
    }
  }
};
