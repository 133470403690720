

export default {
  props: {
    value : {
      type: String,
      default:""
    },
    classes: {
      type: String,
      default:""
    },
    label: {
      type: String,
      default:""
    },
    color: {
      type: String,
      default:""
    },
    heading: {
      type: String,
      default:""
    },
    options: {
      type: Array,
      default: () => []
    },
  },

  // props: ['value', 'class', 'label','color','heading','options'],
 
  data () {
    return {
      content: this.value
    }
  },
  created() {
    this.content = this._props.value
  },
  methods: {
    handleInput (e) {
      this.content = e.value
      this.$emit('input', e.value)
    }
  }
};
