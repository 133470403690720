import DrawerHeader from '../Drawer/Header.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    DrawerHeader
  },
  methods: {
    closeDrawer() {
      // Workaround due to noImplicitThis option
      const self: Vue = this as any;
      self.$emit('close');
    }
  }
});
