import { TenantFormModel } from '@/repositories/AuthRepository';
import { IS_DEV } from '@/util/env';

export const createData = (
  email: string
): {
  industryName: string;
  industryId: string;
  phone: { phoneNumber: string; countryCode: string };
  industryNameJp: string;
  companyName: string;
  email: string;
} =>
  IS_DEV
    ? {
        companyName: '',
        email,
        phone: {
          countryCode: '+81',
          phoneNumber: ''
        },
        industryId: '',
        industryName: '',
        industryNameJp: ''
      }
    : {
        companyName: '',
        email,
        phone: {
          countryCode: '+81',
          phoneNumber: ''
        },
        industryId: '',
        industryName: '',
        industryNameJp: ''
      };

export const createI18n = () => ({
  companyName: 'Company Name',
  phoneNumber: 'Phone',
  industryLabel: 'Industry',
  industry: {
    entertainment: 'Entertainment',
    farming: 'Farming & Gardening',
    healthcare: 'Healthcare',
    humanResources: 'Human Resources',
    informationTech: 'Information & Technology',
    lifestyle: 'Lifestyle'
  }
});

export interface Styles {
  step: string;
  done: string;
  active: string;
}
