
import { addWeeks, startOfWeek, startOfYesterday } from 'date-fns';
import moment from 'moment';
export default {
  data() {
    return {
      dateValue: {
        title: this.$t('title'),
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        compareStartDate: moment(startOfYesterday()).format('YYYY-MM-DD'),
        compareEndDate: moment(startOfYesterday()).format('YYYY-MM-DD'),
        daysOfWeek: 'monday,tuesday,wednesday,thursday,friday,saturday,sunday'
      }
    };
  }
};
