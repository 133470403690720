
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      overview: {},
      total: 0,
      loading: true,
      occPer: 0,
      timestamp: ""
    };
  },
  computed: {
    ...mapState('occupancy', [
      'availableStores',
      'currentOverview',
      'overviewLoadingState'
    ]),
    // currentTime() {
    //   const mom = moment(new Date(), 'HHmmss');
    //   return mom.format('HH:mm');
    //   // return moment(new Date()).format('HH:MM');
    // }
  },
  watch: {
    overviewLoadingState(newValue) {
      this.loading = newValue;
    },
    currentOverview() {
      this.overview = this.currentOverview;
      this.total = parseInt(
        this.currentOverview && this.currentOverview.capacity
          ? this.currentOverview.capacity
          : 0
      );
      this.occPer = parseInt(
        this.overview && this.overview.occupancyPercentage
          ? this.overview.occupancyPercentage
          : ''
      );
    }
  },
  created() {
    this.loading = this.overviewLoadingState;
    setInterval(this.getNow, 1000);

    // const mom = moment(new Date(), 'HHmmss');
    //   return mom.format('HH:mm');

  },
  methods: {
    getNow: function() {
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = time;
        this.timestamp = dateTime;
    },
    getClass(val) {
      return val > 0 ? 'recovered-color' : 'positive-color';
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = date.toISOString().substr(0, 10);
      const [year, month, day] = newDate.split('-');
      return `${year}/${month}/${day}`;
    },
    getFullDay(val) {
      if (val === 0) {
        return 'Sunday';
      }
      if (val === 1) {
        return 'Monday';
      }
      if (val === 2) {
        return 'Tuesday';
      }
      if (val === 3) {
        return 'Wednesday';
      }
      if (val === 4) {
        return 'Thursday';
      }
      if (val === 5) {
        return 'Friday';
      }
      if (val === 6) {
        return 'Saturday';
      }
    }
  }
};
