
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import User from '@/models/User';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import { UpdateUser } from '@/repositories/UserRepository';
import { validateMandatoryFields } from '@/util/validation';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      user: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: {
          phoneCountryCode: '',
          phoneNumber: ''
        },
        phoneCountryCode: '',
        phoneNumber: '',
        stores: '',
        roleId: ''
      },
      removedStores: [],
      loading: true,
      changePassword: true,
      newPassword: '',
      confirmPassword: ''
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    canSubmit() {
      return validateMandatoryFields(this.user, [
        'firstName',
        'lastName',
        'username',
        'email',
        'roleId',
        'stores'
      ]);
    }
  },
  async created() {
    this.loading = true;
    try {
      const user = await this.$repo.user.getUser(this.userId);
      const store = await this.$repo.store.getUserStore(this.userId);
      const data = user.response.userDetails;
      this.user = {
        username: data.username,
        ...data.profile,
        email: data.email,
        phone: {
          phoneCountryCode: data.profile.phoneCountryCode,
          phoneNumber: data.profile.phoneNumber
        },
        phoneCountryCode:data.profile.phoneCountryCode,
        phoneNumber:data.profile.phoneNumber,
        stores: store.map(v => v.store),
        roleId: data.roleDetails._id
      };
    } catch (err) {
      handleErrorUI(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapGetters('user', ['getProfile', 'getClientSession']),
    ...mapMutations('user', ['setProfile']),
    ...mapActions('user', ['updateProfileSSO', 'updatePasswordSSO']),
    removeStores(val) {
      this.removedStores = [...this.removedStores, val];
    },
    async submitPasswordChange() {
      if (this.newPassword !== this.confirmPassword) {
        throw new Error("Confirmation password doesn't match");
      }
      try {
        this.loading = true;
        await this.updatePasswordSSO({
          _id:this.userId,
          newPassword: this.newPassword,
          newConfirmPassword: this.confirmPassword
        });
        this.$q.notify({
          position: 'top',
          message: `${this.$t('AuthPage.password_changed_successfully')}`,
          type: 'positive'
        });
        this.$router.push({ name: 'management:user' });
      } catch (err) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('AuthPage.invalid_old_password')
          });
      } finally {
        this.loading = false;
      }
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    async submitUser() {
      try {
        if (this.user.stores.length === 0) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('AuthPage.at_least_one_store')
          });
          this.loading = false;
          return;
        }
        await this.$repo.store.updateUserStore(
          this.userId,
          this.user.stores.map(e => e.id)
        );
        const u = {
          ...this.user
        };
        delete u.stores;
        await this.$repo.user.updateUser(this.userId, {
          ...u
        });

        await this.$repo.user.updateUserRole(this.userId, this.user.roleId);

        handleSuccessUI(this.$t('SettingsManagement.userUpdatedSuccessfully'));
        this.$router.push({ name: 'management:user' });
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
