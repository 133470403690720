import Store from '@/models/Store';
import localForage from 'localforage';
import BrandController from '@/services/core/BrandController';
import PlanController from '@/services/management/PlanController';
import { CoreService } from '@/services';
import StoreController from '@/services/management/StoreController';
import StoreTypeController from '@/services/core/StoreTypeController';
import {
  convertStoreDTO,
  convertToPayload
} from '@/models/formatters/StoreFormatter';
import { CancelToken } from 'axios';
import { Pagination, PaginationResponse } from '@/util/pagination';
const STORED_UPLOAD_RESULT_PREFIX = 'store';

export interface GetStoresResult {
  data: Store[];
  pagination: PaginationResponse;
}

export interface CreateStore extends Store {
  brandId: string;
  storeTypeId: string;
  status: string;
  selectedCountry: string;
  selectedRegion: string;
  selectedDistrict: string;
  selectedPrefecture: string;
  selectedCity: string;
}

export type UpdateStore = CreateStore;

export interface StoreImages {
  storeId: string;
  filePaths: string[];
}

export default class StoreRepository {
  private readonly storeController: StoreController;
  private readonly brandController: BrandController;
  private readonly storeTypeController: StoreTypeController;
  private readonly planController: PlanController;

  constructor(
    storeController: StoreController,
    service: CoreService,
    planController: PlanController
  ) {
    this.storeController = storeController;
    this.brandController = service.brand;
    this.storeTypeController = service.storeType;
    this.planController = planController;
  }

  public async getStores(
    pagination: Pagination = new Pagination()
  ): Promise<GetStoresResult> {
    const { content, ...meta } = await this.storeController.getStores(
      pagination.toRequest()
    );

    const data = [];
    for (const dto of content) {
      if (dto.planOrder != null && dto.planOrder.planId != null) {
        const plan = await this.planController.getPlanById(
          dto.planOrder.planId
        );
        dto.planOrder.plan = plan;
      }
      const store = convertStoreDTO(dto.store, dto.planOrder);
      data.push(store);
    }

    return {
      data,
      pagination: meta
    } as GetStoresResult;
  }

  public async getStore(id: string): Promise<Store> {
    const storeDto = await this.storeController.getStore(id);
    return convertStoreDTO(storeDto, null);
  }

  public async createStore(store: CreateStore) {
    const dto = await this.storeController.createStore(convertToPayload(store));
    return convertStoreDTO(dto, null);
  }

  public async updateUserStore(userId: string, stores: string[]) {
    return await this.storeController.updateUserStore(userId, stores);
  }
  public async getUserStore(userId: string) {
    return await this.storeController.getUserStore(userId);
  }

  public async updateStore(id: string, store: UpdateStore) {
    const dto = await this.storeController.updateStore(
      id,
      convertToPayload(store)
    );
    return convertStoreDTO(dto, null);
  }

  public async getStoreTypes() {
    const res = await this.storeTypeController.getStoreTypes();
    return res.content;
  }

  public async deleteStore(id: string) {
    await this.storeController.deleteStore([id]);
  }

  public async uploadStoreImage(
    file: File,
    cancelToken?: CancelToken,
    onUploadProgress?: Function
  ) {
    const content = await this.storeController.uploadStoreImage(
      file,
      cancelToken,
      onUploadProgress
    );
    return content;
  }

  public async uploadStoreImageForBulkUpdate(
    storeId: string,
    file: File,
    sessionId: string,
    cancelToken?: CancelToken,
    onUploadProgress?: Function
  ) {
    const content = await this.storeController.uploadStoreImageForBulkUpdate(
      storeId,
      file,
      sessionId,
      cancelToken,
      onUploadProgress
    );
    return content;
  }

  public async bulkUpdateStoreImage(sessionId: string, images: StoreImages[]) {
    const res = await this.storeController.bulkUpdateStoreImage({
      browserId: sessionId,
      requests: images
    });
    return res;
  }

  public async downloadFile(id: string) {
    if (id == null) return;
    return await this.storeController.downloadFile(id);
  }

  public async getStoreImageAsBase64(identifier: string) {
    const content = await this.storeController.getStoreImageAsBase64(
      identifier
    );
    return content;
  }

  public async getImportTemplate() {
    return await this.storeController.getImportTemplate();
  }

  public async importStores(file: File) {
    return await this.storeController.importStores(file);
  }

  public async saveImportResult(id: string, result: any) {
    await localForage.setItem(`${STORED_UPLOAD_RESULT_PREFIX}::${id}`, result);
  }

  public async getImportResult(id: string) {
    return await localForage.getItem(`${STORED_UPLOAD_RESULT_PREFIX}::${id}`);
  }
}
