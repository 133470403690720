
export default {
  name: 'SettingsList',
  props: {
    menus: {
      type: Array,
      required: true
    },
    selectedIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    menuClass(index) {
      const { $style, selectedIndex } = this;
      return index === selectedIndex ? $style.selectedMenu : null;
    }
  }
};
