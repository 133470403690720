
export default {
  props: {
    tableColumn: {
      type: Array,
      default: () => []
    },
    tableRows: {
      type: Array,
      default: () => []
    },
    weatherList: {
      type: Array,
      default: () => []
    },
    dateList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      separator: 'cell'
    };
  }
};
