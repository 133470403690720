
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('overview', {
      data: 'salespersqm'
    })
  } // computed
}; // export default
