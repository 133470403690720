import config from '@/config';
import { ClientManager } from '@/util/client';
import { PostRequest } from '@/util/http';
import { StoreParams } from '../util/interface/Occupancy';
import { SSOGetRequest2,SSOPutRequest2 } from '@/util/kpis';
import store from '@/store';
import { IEddyConfig } from 'src/store/modules/interface';
import { debug } from 'webpack';

const clientManager = new ClientManager(config);
const eddyClient = clientManager.eddyClient;

// export function APIFetchOverview(params: any) {
//   return PostRequest('/occupancy/overview', params);
// } // APIFetchOverview
export const APIFetchOverview = (): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {

      // var url = new URL("https://fida-stg.flow-insight.com/api/" + "getKpiStores");
      // url.searchParams.append("kpiType", "occupancy");
      // url.searchParams.append("client_id", "aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688");
      // url.searchParams.append("client_secret", "fp329-polk80s-ye04p1yy-45hx874z06");

      // const rawResponse = await fetch(url.href, {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIyLTExLTE0IiwidGltZSI6IjAxOjUwOjI3IiwidGltZVN0YW1wIjoxNjY4MzU4MjI3NjI0LCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjItMTEtMTQiLCJ0aW1lIjoiMDE6NTA6MjciLCJ0aW1lU3RhbXAiOjE2NjgzNTgyMjc2MjQsImdtdCI6IiswOTAwIn0sImlhdCI6MTY2NTc2NjIyNywiZXhwIjoxNjY1NzY2Mjg3fQ.SiMhBQRNTjxRgwSKrWVR8EiYVcBgyw82KUFBNcvKQ74"//this.token
      //   },
      // });
      // debugger;

      // var res = rawResponse;
      // resolve(res);



      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const apiResponse: any = await SSOGetRequest2(
        '/api/getKpiStores?kpiType=occupancy&client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:'Bearer '+ token
          }
        }
      );
      var res = apiResponse.data.response;
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
//getKpiStores
// export function APIFetchOverview(params: any) {
//   return eddyClient.post('/rest/occupancy/overview', params);
// } // APIFetchOverview

// export function APIFetchDaily(params: StoreParams) {
//   return PostRequest('occupancy/daily', params);
// } // APIFetchDaily

export const APIFetchDaily = (params: any): Promise<any[]> => {
  // debugger;
  // if(params.storeId == null || params.storeId )
  // var StoreOccupancyId = localStorage.getItem("StoreOccupancyId");
  return new Promise(async (resolve, reject) => {
    try {
  if(params.storeId == null || params.storeId == undefined )
  params.storeId= localStorage.getItem("StoreOccupancyId");

      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const apiResponse: any = await SSOGetRequest2(
        '/api/getSensorDailyDataGrouped?client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:'Bearer '+ token
          },
          params
        }
      );
      var res = apiResponse.data.response.kpis;
      res = res.sort((a, b) => (a._id < b._id) ? -1 : 1);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

// export function APIFetchHourly(params: StoreParams) {
//   return PostRequest('occupancy/hourly', params);
// } // APIFetchHourly

//////////////
export const APIFetchHourly = (params: StoreParams): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const apiResponse: any = await SSOGetRequest2(
        '/api/getSensorHourlyDataGrouped?client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:'Bearer '+ token
          },
          params
        }
      );
      var res = apiResponse.data.response.kpis;
      res = res.sort((a, b) => (a._id < b._id) ? -1 : 1);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
/////////////
// export function APIFetchTokenForStore(params: any) {
//   return PostRequest('/occupancy/getToken', params);
// }
export function APIFetchTokenForStore(params: any) {
  return eddyClient.post('/rest/occupancy/getToken', {
    ...params
    //,tenant: 'FlowSolutions'
  });
}

// export function APIFetchEddyConfig(storeCode) {
//   return PostRequest('/occupancy/getEddyConfigs', {
//     storeCode,
//     tenant: 'FlowSolutions'
//   });
// }
export function APIFetchEddyConfig(storeDataId) {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const apiResponse: any = await SSOGetRequest2(
        '/api/getStoreDetail?_id='+storeDataId+'&client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:'Bearer '+ token
          },
        }
      );
      var res = apiResponse.data.response.store;
      const obj:IEddyConfig = {
        tenant: "",
        storeName: res.title,
        showHourlyIndicators: res.occupancyConfig.showHourlyIndicator,
        capacity: res.occupancyConfig.maxOccupancy,//res.capacity,

        greenIndicatorMsg: res.occupancyConfig.defaultWidget.safe.description,
        greenIndicatorLabel: res.occupancyConfig.defaultWidget.safe.title,
        greenIndicatorThreshold: 0,

        redIndicatorMsg: res.occupancyConfig.defaultWidget.unsafe.description,
        redIndicatorLabel: res.occupancyConfig.defaultWidget.unsafe.title,
        redIndicatorThreshold: res.occupancyConfig.defaultWidget.unsafe.thresholdActual,

        yellowIndicatorLabel: res.occupancyConfig.defaultWidget.warning.title,
        yellowIndicatorMsg: res.occupancyConfig.defaultWidget.warning.description,
        yellowIndicatorThreshold: res.occupancyConfig.defaultWidget.warning.thresholdActual,

        widgetGreenIndicatorLabel: res.occupancyConfig.safeUnSafeWidget.safe.title,
        widgetGreenIndicatorMsg:  res.occupancyConfig.safeUnSafeWidget.safe.description,
        widgetGreenIndicatorThreshold:  res.occupancyConfig.safeUnSafeWidget.safe.thresholdActual,

        widgetRedIndicatorLabel: res.occupancyConfig.safeUnSafeWidget.unsafe.title,
        widgetRedIndicatorMsg: res.occupancyConfig.safeUnSafeWidget.unsafe.description,
        widgetRedIndicatorThreshold:  res.occupancyConfig.safeUnSafeWidget.unsafe.thresholdActual
      };

      resolve(obj);
    } catch (error) {
      reject(error);
    }
  });
}

//export function APIFetchEddyConfig(storeCode) {
//  return eddyClient.post('/rest/occupancy/getEddyConfigs', {
//    storeCode,
//    tenant: 'FlowSolutions'
//  });
//}
// export function APISetEddyConfig(eddyConfig) {

//   return eddyClient.post(`/rest/occupancy/setEddyConfigs`, eddyConfig);
// }

export const APISetEddyConfig = (eddyConfig): Promise<any[]> => {
  var finalArray = {};
  var occupancyConfig = {};


  occupancyConfig = {
    maxOccupancy: eddyConfig.capacity,
    showHourlyIndicator: eddyConfig.showHourlyIndicators,
    defaultWidget: {
            unsafe: {
                title: eddyConfig.redIndicatorLabel,
                description: eddyConfig.redIndicatorMsg,
                thresholdActual: eddyConfig.redIndicatorThreshold,
                isPercentage: eddyConfig.percent1//false// check this
            },
            safe: {
                title: eddyConfig.greenIndicatorLabel,
                description: eddyConfig.greenIndicatorMsg
            },
            warning: {
                title: eddyConfig.yellowIndicatorLabel,
                description: eddyConfig.yellowIndicatorMsg,
                thresholdActual: eddyConfig.yellowIndicatorThreshold,
                isPercentage: eddyConfig.percent1
            }
        },
        safeUnSafeWidget: {
            unsafe: {
                title: eddyConfig.widgetRedIndicatorLabel,
                description: eddyConfig.widgetRedIndicatorMsg,
                thresholdActual:eddyConfig.widgetRedIndicatorThreshold,
                isPercentage: eddyConfig.percent2
            },
            safe: {
                title: eddyConfig.widgetGreenIndicatorLabel,
                description: eddyConfig.widgetGreenIndicatorMsg
            }
        }
    };
    finalArray = {
      storeId: eddyConfig.storeCode,
      occupancyConfig
    }

  // return eddyClient.post(`/rest/occupancy/setEddyConfigs`, eddyConfig);
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;


      const apiResponse: any = await SSOPutRequest2(
        '/api/updateStore',
        {
          client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
          client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
          storeId: eddyConfig.storeCode,
          occupancyConfig
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + token
          }
        }
      );
      var res = apiResponse.data;
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
  };

// export function APIFetchPowerHour(params: any, kpiMetricKey) {
//   return PostRequest(`occupancy/powerHour/maxOccupancy`, params);
// }

export const APIFetchPowerHour = (params: any, kpiMetricKey): Promise<any[]> => {
return new Promise(async (resolve, reject) => {
  try {
    const storeState: any = store.state;
    const token = storeState.user.sessionClient.access_token;
    const apiResponse: any = await SSOGetRequest2(
      '/api/getSensorDayDataGrouped/?client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer '+ token
        },
        params
      }
    );
    var res = apiResponse.data.response.kpis;

    resolve(res);
  } catch (error) {
    reject(error);
  }
});
};


export const APIFetchStoreSensors = (): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const apiResponse: any = await SSOGetRequest2(
        '/api/getStoreSensors/?client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06&kpiType=occupancy',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:'Bearer '+ token
          }
          // ,
          // params
        }
      );
      var res = apiResponse.data.response[0];
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
  };
