export interface IEddyConfig {
  tenant: string;
  storeName: string;
  showHourlyIndicators: boolean;
  capacity: number;
  greenIndicatorMsg: string;
  greenIndicatorLabel: string;
  greenIndicatorThreshold: number;

  redIndicatorMsg: string;
  redIndicatorLabel: string;
  redIndicatorThreshold: number;

  yellowIndicatorLabel: string;
  yellowIndicatorMsg: string;
  yellowIndicatorThreshold: number;

  widgetGreenIndicatorLabel: string;
  widgetGreenIndicatorMsg: string;
  widgetGreenIndicatorThreshold: number;
  widgetRedIndicatorLabel: string;
  widgetRedIndicatorMsg: string;
  widgetRedIndicatorThreshold: number;
}

export const eddyConfigDefault = {
  capacity: 0,
  greenIndicatorLabel: '',
  greenIndicatorMsg: '',
  greenIndicatorThreshold: 0,
  redIndicatorLabel: '',
  redIndicatorMsg: '',
  redIndicatorThreshold: 0,
  showHourlyIndicators: false,
  storeName: '',
  tenant: '',
  widgetGreenIndicatorLabel: '',
  widgetGreenIndicatorMsg: '',
  widgetGreenIndicatorThreshold: 0,
  widgetRedIndicatorLabel: '',
  widgetRedIndicatorMsg: '',
  widgetRedIndicatorThreshold: 0,
  yellowIndicatorLabel: '',
  yellowIndicatorMsg: '',
  yellowIndicatorThreshold: 0
};
