
import { handleErrorUI } from '@/util/error';

export default {
  methods: {
    onFileSelected(evt: Event) {
      try {
        const file = (evt.target as HTMLInputElement).files[0];
        this.$emit('import', file);
      } catch (e) {
        handleErrorUI(e);
      }
    }
  }
};
