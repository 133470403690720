
import { createColumn } from '@/util/table';
import Card from '@/components/Common/Card';
import PaginationMixin from '@/mixins/PaginationMixin';

const mockData = () => [
  {
    row: 7,
    descriptions: 'Column Floor Area is invalid'
  },
  {
    row: 12,
    descriptions: 'Column Opening Date, Floor Area is invalid'
  },
  {
    row: 19,
    descriptions: 'Column Opening Date, Floor Area is invalid'
  },
  {
    row: 94,
    descriptions:
      'Column Opening Date, Floor Area, Number of Entrances is invalid'
  },
  {
    row: 1094,
    descriptions:
      'Column Opening Date, Floor Area, Number of Entrances is invalid'
  }
];

const columns = [
  createColumn('row', 'Row'),
  createColumn('descriptions', 'Descriptions')
];

export default {
  components: {
    Card
  },
  mixins: [PaginationMixin],
  data: () => ({
    data: mockData(),
    columns
  })
};
