import { ClientManager } from '@/util/client';
import OrderController from './OrderController';
import PlanOrderController from './PlanOrderController';

export interface TransactionalControllers {
  order: OrderController;
  planOrder: PlanOrderController;
}

export default (clientManager: ClientManager) => ({
  order: new OrderController(clientManager.consoleClient),
  planOrder: new PlanOrderController(clientManager.consoleClient)
});
