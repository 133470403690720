
import { mapGetters, mapActions } from 'vuex';
import { required, email, sameAs } from 'vuelidate/lib/validators';

import CommonMixin from '../../mixins/CommonMixin';
import { handleErrorUI } from '../../util/error';
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '../../consts/pages';
import { SignupFlag } from '@/models/Auth';
import LangSwitch from '../Common/LangSwitch.vue';

export default {
  components: { LangSwitch },
  mixins: [CommonMixin],
  data() {
    return {
      email: null,
      agreed: false,
      loading: false,
      disable: false,
      modalShown: false
    };
  }, // data
  computed: {
    ...mapGetters('user', ['getLocale'])
  },
  methods: {
    ...mapActions('user', ['registerEmailVerificationSSO']),
    async registerSSO() {
      this.loading = true;
      this.disable = true;
      this.email = this.email === null ? '' : this.email;
      this.$v.$touch();
      try {
        if (!this.$v.$invalid) {
          //console.log('1-1');
          const response = await this.registerEmailVerificationSSO({
            email: this.email
          });
          await this.$router
            .push({
              name: 'auth:signup:request',
              query: { email: this.email }
            })
            .catch(_ => {});

          /* switch (flag) {
            case SignupFlag.ACCOUNT_REQUESTS_JOIN:
              await this.$router
                .push({
                  name: 'auth:signup:request',
                  query: { email: this.email, domain }
                })
                .catch(_ => {});
              break;
            case SignupFlag.ACCOUNT_LIMIT:
              await this.$router
                .push({
                  name: 'auth:signup:limit'
                })
                .catch(_ => {});
              break;
            default:
              await this.$router
                .push({
                  name: 'auth:signup:create',
                  query: { email: this.email }
                })
                .catch(_ => {});
              break;
          } */
        }
        localStorage.setItem('signupEmail', this.email);
      } catch (err) {
        const msg =  err.response?.data?.message || err.response?.message || err;
        if(msg == "this domain is backlisted") {
          this.$q.notify({
            message: this.$t('AuthPage.this_domain_is_backlisted'),
            type: 'negative',
            position: 'top',
            timeout: 3000
          });
        } else {
          this.$q.notify({
            message: this.$t('AuthPage.signup_error_message'),
            type: 'negative',
            position: 'top',
            timeout: 3000
          });
        }
        localStorage.clear();
        this.$v.$reset();
        // handleErrorUI({
        //   message: err.response?.data?.message || err.response?.message || err
        // });
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.$reset();
      } // finally
    },

    async login() {
      this.loading = true;
      this.disable = true;
      this.email = this.email === null ? '' : this.email;

      this.$v.$touch();

      try {
        if (!this.$v.$invalid) {
          const { flag, domain } = await this.$repo.auth.signup(this.email);
          switch (flag) {
            case SignupFlag.ACCOUNT_REQUESTS_JOIN:
              await this.$router
                .push({
                  name: 'auth:signup:request',
                  query: { email: this.email, domain }
                })
                .catch(_ => {});
              break;
            case SignupFlag.ACCOUNT_LIMIT:
              await this.$router
                .push({
                  name: 'auth:signup:limit'
                })
                .catch(_ => {});
              break;
            default:
              await this.$router
                .push({
                  name: 'auth:signup:create',
                  query: { email: this.email }
                })
                .catch(_ => {});
              break;
          }
        }
      } catch (err) {
        handleErrorUI({ message: err });
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.$reset();
      } // finally
    },
    emailExists(v) {
      return v?.length === 0;
    },
    validateEmail(email) {
      this.email = String(email).toLowerCase();
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
      );
    },
    showTerms(val) {
      if (val === 'service') {
        window.open(TERMS_AND_CONDITIONS, '_blank');
      } else {
        window.open(PRIVACY_POLICY, '_blank');
      }
      // this.modalShown = true;
    }
  },
  validations: {
    email: {
      required,
      email
    },
    agreed: {
      sameAs: sameAs(() => true)
    }
  }
};
