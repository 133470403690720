import { render, staticRenderFns } from "./KPITrafficReport.vue?vue&type=template&id=2f25e4e6&"
import script from "./KPITrafficReport.vue?vue&type=script&lang=js&"
export * from "./KPITrafficReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./KPITrafficReport.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./KPITrafficReport.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QCard,QCardSection,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon})
