
import roles from "@/services/roles.js";
import users from "@/services/users.js";
import stores from "@/services/stores.js";
import peergroups from "@/services/peergroups.js";

import StoreModel from "@/components/modals/StoreModel";
// import PeerGroupsModal from "@/components/modals/PeerGroupsModal";
export default {
  components: {
    StoreModel,
    // PeerGroupsModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedUser", "userDetails", "dataList"],
  data() {
    return {
      oversees: [],
      workAt: [],
      selectedStores: [],
      selectedGroup: [],
      configuration: true,
      firstname: "",
      lastname: "",
      phoneNumber: "",
      username: "",
      password: "",
      confirmPassword: "",
      openStoreModel: false,
      // openPeerGroupsModel: false,
      userLocation: [],
      email: "",
      selectedRole: null,
      selectedStore: "",
      loading: false,
      loadingBtn: false,
      roleOptions: [],
      finalUserData: [],
      storeData: [],
      allPeerGroups: [],
      // storeOptions: [],
      dataAccessibleColumns: [
        {
          name: "name",
          align: "center",
          label: this.$store.state.user.translate.visible_stores,
          field: "name",
          sortable: true,
        },
        {
          name: "storeNumber",
          align: "left",
          label: this.$store.state.user.translate.oversees,
          field: "storeNumber",
        },
        // { name: "brand", label: "Brand", field: "brand" },
        {
          name: "myStore",
          label: this.$store.state.user.translate.works_at,
          field: "myStore",
          tooltipIcon: "fas fa-question-circle",
          tooltipText: "Displays the total orders imported through this store",
        },
        {
          name: "availability",
          label: this.$store.state.user.translate.actions,
          field: "availability",
        },
        // { name: "action", label: this.$store.state.user.translate.actions, field: "action" },
      ],
      dataAccessibleRows: [],

      peerGroupColumns: [
        {
          name: "peerGroupType",
          align: "center",
          label: this.$store.state.user.translate.peer_group_am_type,
          field: "peerGroupType",
          sortable: true,
        },
        {
          name: "peerGroupName",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: "peerGroupName",
          sortable: true,
        },
        {
          name: "availability",
          label: this.$store.state.user.translate.actions,
          field: "availability",
        },
      ],
      peerGroupRows: [],
    };
  },
  computed: {
    title() {
      return this.selectedUser
        ? this.$store.state.user.translate.edit +
            " " +
            this.$store.state.user.translate.user
        : this.$store.state.user.translate.add_user;
    },
  },
  watch: {
    selectedRole(newValue){
      if(newValue.title === 'FlowTenantAdmin' || newValue.title === '本社' || newValue.title === 'HQ'){
        this.dataAccessibleRows = this.storeData;
        this.selectedStores = this.dataAccessibleRows
        this.oversees = this.storeData?.map(v => v.storeNumber)
      }
      else{
        this.dataAccessibleRows = [];
         this.selectedStores= [];
        this.oversees = [];
      }
    },
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
  },
  mounted() {
    this.getAllStores();
    // this.getAllPeerGroups()
    this.getRoles();
    if (this.selectedUser) {
      this.getUserLocation();
      this.firstname = this.selectedUser.profile.firstName;
      this.lastname = this.selectedUser.profile.lastName;
      this.email = this.selectedUser.email;
      this.username = this.selectedUser.username;
      this.phoneNumber = this.selectedUser.mobile;
      this.selectedRole = this.selectedUser.role;
    }
  },
  methods: {
    validateUsername(username) {
      var usernameReg = /^(?=[a-zA-Z0-9._]{1,30}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      return usernameReg.test(username);
    },
    validateUserMethod() {
      if (!this.validateUsername(this.firstname)) {
        setTimeout(() => {
          this.$refs.providerr.applyResult({
            errors: [this.$store.state.user.translate.invalid_user_name], // array of string errors
            valid: this.validateUsername(this.firstname), // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }, 1000);
      }
    },
    validateMailMethod() {
      if(this.selectedUser?._id) return
      var isExist = this.dataList.find(v => v.email == this.email)
      if (isExist) {
        setTimeout(() => {
          this.$refs.providerdomain.applyResult({
            errors: [this.$store.state.user.translate.email_already_exist], // array of string errors
            valid: !isExist, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }, 1000);
      }
    },
    getRoles() {
      roles.getRoles().then((response) => {
        this.roleOptions = response.data.response.roles;
      });
    },
    getAllStores() {
      stores.getStores().then((response) => {
        this.storeData = response.data.response.stores.reverse();

      });
    },

    getUserLocation() {
      this.loading = true;
      users
        .getUserLocations(this.selectedUser._id)
        .then((response) => {
          this.userLocation = response.data.response;
          let dataAccessStores = response.data.response.dataAccessStores
          let myStores = response.data.response.myStores
          let data = [...response.data.response.myStores, ...dataAccessStores];
          this.dataAccessibleRows = data.filter((a, i) => data.findIndex((s) => a.storeNumber === s.storeNumber) === i)
          this.selectedStores = this.dataAccessibleRows;
          this.workAt = myStores.map(v => v.storeNumber)
          this.oversees = dataAccessStores.map(v => v.storeNumber)
          this.peerGroupRows = response.data.response.myPeerGroups;
          this.selectedGroup = response.data.response.myPeerGroups;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    async updateStore() {
      await this.getUserLocation();
      this.openStoreModel = false;
      // this.openPeerGroupsModel = false;
    },
    async updateStoreValue(value) {
      this.dataAccessibleRows = value;
      this.selectedStores = this.dataAccessibleRows;
      this.oversees = value.map(v => v.storeNumber);
      this.workAt = [];
      this.openStoreModel = false;
    },
    // async updatePeerGroupValue(value) {
    //   this.peerGroupRows = value;
    //   this.openPeerGroupsModel = false;
    // },
    closeStoreModel() {
      this.openStoreModel = false;
    },
    closePeerGroupsModel() {
      this.openPeerGroupsModel = false;
    },
    getAllPeerGroups() {
      peergroups.getPeerGroups().then((response) => {
        this.allPeerGroups = response.data.response.peerGroups;
      });
    },
    containsSpecialChars(str) {
      let ln = str && str.length <= 3 ? false: true;
      return ln
      // const specialChars = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?!.*\s).{8,}$/;
      // return specialChars.test(str);
    },

    saveUserDetails() {
      if (!this.selectedRole) {
        this.$q.notify({
          message: this.$store.state.user.translate.please_select_role,
          color: "red",
        });
        return;
      }
      // if (!this.selectedStores.length) {
      //   this.$q.notify({
      //     message: this.$store.state.user.translate.please_select_stores,
      //     color: "red",
      //   });
      //   return;
      // }
      // if (!this.workAt.length && !this.oversees.length) {
      //   this.$q.notify({
      //     message: this.$store.state.user.translate.please_select_atleast_oneStore,
      //     color: "red",
      //   });
      //   return;
      // }
      // if (!this.oversees.length) {
      //   this.$q.notify({
      //     message: "Please Select Atleast One Oversees Store",
      //     color: "red",
      //   });
      //   return;
      // }

      if (this.selectedUser) {
        if (!this.containsSpecialChars(this.password) &&  this.password && this.confirmPassword) {
          this.$refs.provider.applyResult({
            errors: [
              this.$store.state.user.translate.password_validations,
            ], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
          var myDiv = document.getElementById("containerDiv");
          myDiv.scrollTop = 0;
          return;
        }
        let userUpdatedProfile = {
          id: this.selectedUser._id,
          firstName: this.firstname,
          lastName: this.lastname,
          mobile: this.phoneNumber,
          username: this.username,
          // password: this.password,
          roleId: this.selectedRole._id,
          emailInfo: this.email,
          toAdd: true,
        };
        // this.updateUserRole()
        this.loadingBtn = true;
        users
          .updateUserProfile(userUpdatedProfile)
          .then((response) => {
            this.roleOptions = response.data.response.roles;
            this.setUserLocation(this.selectedUser._id);
            this.password &&
              this.confirmPassword &&
              this.updateUserPassword(this.selectedUser._id);
            this.$emit("updateUserProfile");
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.$emit("closeModel", false);
            this.loadingBtn = false;
          })
          .catch((error) => {
            this.loadingBtn = false;
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
          });
      } else {
        if (!this.containsSpecialChars(this.password)) {
          this.$refs.provider.applyResult({
            errors: [
              this.$store.state.user.translate.password_validations,
            ], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
          var myPass = document.getElementById("containerDiv");
          myPass.scrollTop = 0;
          return;
        }
        let newUserDetails = {
          firstName: this.firstname,
          lastName: this.lastname,
          username: this.username,
          email: this.email,
          roleId: this.selectedRole._id,
          password: this.password,
          mobile: this.phoneNumber,
          toAdd: true,
        };
        this.loadingBtn = true;
        let newUser = [];
        newUser.push(newUserDetails);
        users
          .addUser(newUser)
          .then((response) => {
            this.setUserLocation(response.data.response.users[0]._id);
            this.updateUserPassword(response.data.response.users[0]._id);
            this.$q.notify({
              message: this.$store.state.user.translate.user_added_successfully,
              color: "green",
            });
            this.$emit("updateUserProfile", true);
            this.$emit("closeModel", false);
            this.loadingBtn = false;
          })
          .catch((error) => {
            // this.$emit("closeModel", false);
            if (error.response.data.message) {
              if (error.response.data.message === "you are not allowed to create user") {
                const slug = this.email.substring(this.email.indexOf("@") + 1);
                this.$q.notify({
                  message: `"${slug}" domain is not in the whitelist, please use the whitelist domains for email`,
                  color: "red",
                });
              } else {
                this.$q.notify({
                  message: error.response.data.message,
                  color: "red",
                });
              }
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.loadingBtn = false;
          });
      }
    },
    setUserLocation(user) {
      let userStores = [];
      let userPeerGroups = [];
      this.dataAccessibleRows.forEach((element) => {
        userStores.push(element.storeNumber);
      });
      // this.peerGroupRows.forEach((element) => {
      //   userPeerGroups.push(element._id)
      // })
      if (userStores.length === 0) {
        return;
      }
      let UsrDetails = {
        userId: user,
        locations: [
          {
            type: "mystores",
            storeCodes: this.workAt,
          },
          {
            type: "dataaccess",
            storeCodes: this.oversees
          },
          {
            type: "peergroups",
            peerGroupIds: userPeerGroups,
          },
        ],
      };
      users
        .setUserLocations(UsrDetails)
        .then((response) => {
          this.$emit("updateUserProfile");
        })
        .catch((error) => {
          // console.log(error);
          this.$q.notify({
            message:
              this.$store.state.user.translate.assigning_stores_error,
            color: "red",
          });
        });
    },

    updateUserPassword(user) {
      // if (this.selectedUser) {
      let UsrDetails = {
        // id: this.selectedUser._id,
        id: user,
        password: this.password,
        newConfirmPassword: this.confirmPassword,
      };
      users
        .updateUserPassword(UsrDetails)
        .then((response) => {
          this.$emit("updateUserProfile");
        })
        .catch((error) => {
          // console.log(error);
          this.$q.notify({
            message: this.$store.state.user.translate.confirm_your_password,
            color: "red",
          });
        });
      // }
    },
    unassignUserStore(data) {
      let userDetails = {
        storeId: data.row._id,
        userId: this.userDetails._id,
      };
      this.oversees = this.oversees.filter(v => v != data.row.storeNumber)
      this.workAt = this.workAt.filter(v => v != data.row.storeNumber)
      this.dataAccessibleRows.splice(data.rowIndex, 1);
      stores
        .unassignUserStore(userDetails)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.store_unassigned_successfully,
            color: "green",
          });
          // this.dataAccessibleRows.splice(data.rowIndex, 1);
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },

    removeUserPeerGroup(data) {
      // console.log(data)
      setTimeout(() => {
        const index = Math.floor(Math.random() * this.peerGroupRows.length);
        this.peerGroupRows = [
          ...this.peerGroupRows.slice(0, index),
          ...this.peerGroupRows.slice(index + 1),
        ];
      }, 500);
    },
  },
};
