
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  data: function() {
    return {
      granularityOptions: [
        { label: this.$t('Date.hourly'), value: 'hourly' },
        { label: this.$t('Date.daily'), value: 'daily' },
        { label: this.$t('Date.weekly'), value: 'weekly' },
        { label: this.$t('Date.monthly'), value: 'monthly' }
      ],
      analysisType: 'daily',
      selectedKPI: 'sls',
      compareKPI: 'trf',
      core: [
        { label: this.$t('KPI.sales'), code: 'sls' },
        { label: this.$t('KPI.traffic'), code: 'trf' },
        { label: this.$t('KPI.transactions'), code: 'trn' },
        { label: this.$t('KPI.conversion'), code: 'cn' },
        { label: this.$t('KPI.avgpurchase'), code: 'ap' },
        { label: this.$t('KPI.upt'), code: 'upt' },
        { label: this.$t('KPI.rov'), code: 'rov' },
        { label: this.$t('KPI.avgitemprice'), code: 'aip' },
        { label: this.$t('KPI.salespersqm'), code: 'spt' },
        { label: this.$t('KPI.trafficpersqm'), code: 'tpt' }
      ],
      staff: [
        { label: this.$t('KPI.staffefficiency'), code: 'se' }
      ],
      trends: [
        { label: this.$t('KPI.passersby'), code: 'pb' },
        { label: this.$t('KPI.capturerate'), code: 'cr' },
        // { label: this.$t('KPI.facadestops'), code: 'facadestops' },
        { label: this.$t('KPI.storedwelltime'), code: 'dw' },
        { label: this.$t('KPI.engagementrate'), code: 'se' },
        { label: this.$t('KPI.closingstrength'), code: 'cs' },
       // { label: this.$t('KPI.bouncerate'), code: 'bouncerate' }
      ],
      kpiList: [
        { label: this.$t('KPI.sales'), code: 'sls' },
        { label: this.$t('KPI.avgpurchase'), code: 'ap' },
        { label: this.$t('KPI.capturerate'), code: 'cr' },
        { label: this.$t('KPI.engagementrate'), code: 'se' },
        { label: this.$t('KPI.closingstrength'), code: 'cs' },
        { label: this.$t('KPI.salespersqm'), code: 'spt' },
        { label: this.$t('KPI.transactions'), code: 'tpt' },
        { label: this.$t('KPI.staffefficiency'), code: 'se' },
        { label: this.$t('KPI.avgitemprice'), code: 'aip' },
        { label: this.$t('KPI.storedwelltime'), code: 'dw' },
        // { label: this.$t('KPI.facadestops'), code: 'facadestops' },
        { label: this.$t('KPI.rov'), code: 'rov' },
        // { label: this.$t('KPI.bouncerate'), code: 'bouncerate' },
        { label: this.$t('KPI.upt'), code: 'upt' },
        { label: this.$t('KPI.passersby'), code: 'pb' },
        { label: this.$t('KPI.trafficpersqm'), code: 'tpt' },
        { label: this.$t('KPI.conversion'), code: 'cn' },
        { label: this.$t('KPI.traffic'), code: 'trf' }
      ],
      kpiCategories: [],
      // series: [],
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          },
          height: 400,
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          }
        },
        colors: ['#e93f33', '#38a2fb', '#8a211a', '#1e5f95'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            color: '#fff', // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 3,
          radius: 2,
          hover: {
            size: 3,
            sizeOffset: 3
          }
        },
        xaxis: {
          categories: this.kpiCategories,
          tooltip: { enabled: false }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
              <q-card class="flex column q-pa-md">
                <div class="text-bold">
                  ${w.globals.seriesNames[0].split(' ')[0]}
                </div>
                <div class="flex">
                ${w.globals.seriesNames[0].split(' ')[1]}
                ${w.globals.seriesNames[0].split(' ')[2]}
                  :<div class="text-bold q-pl-md">
                    ${series[0][dataPointIndex] ? series[0][dataPointIndex] : 0}
                    ${this.getUnits(w.globals.seriesNames[0].split(' ')[0])}
                  </div>
                </div>

                <div class="flex">
                  ${w.globals.seriesNames[2].split(' ')[1]}
                  ${w.globals.seriesNames[2].split(' ')[2]}
                  :<div class="text-bold q-pl-md">
                    ${series[2][dataPointIndex] ? series[2][dataPointIndex] : 0}
                    ${this.getUnits(w.globals.seriesNames[2].split(' ')[0])}
                  </div>
                </div>
                <div class="text-bold q-mt-sm">
                  ${w.globals.seriesNames[1].split(' ')[0]}
                </div>

                <div class="flex">
                  ${w.globals.seriesNames[1].split(' ')[1]}
                  ${w.globals.seriesNames[1].split(' ')[2]}
                  :<div class="text-bold q-pl-md">
                    ${series[1][dataPointIndex] ? series[1][dataPointIndex] : 0}
                    ${this.getUnits(w.globals.seriesNames[1].split(' ')[0])}
                    </div>
                </div>

                <div class="flex">
                  ${w.globals.seriesNames[3].split(' ')[1]}
                  ${w.globals.seriesNames[3].split(' ')[2]}:
                  <div class="text-bold q-pl-md">
                    ${series[3][dataPointIndex] ? series[3][dataPointIndex] : 0}
                    ${this.getUnits(
                      w.globals.seriesNames[3].split(' ')[0]
                    )}</div>
                  </div>
              </q-card>`;
          }
        },
        yaxis: [],
        legend: {
          show: false,
          position: 'top',
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingCompared',
      'getStartingCompared',
      'getEndingPeriod',
      'getCustomCalenderState'
    ]), // mapGetters
    ...mapState('comparison', ['kpiAnalysis'])
    // getChartCategories: function() {
    //   if (this.kpiAnalysis) {
    //     return [
    //       ...this.kpiAnalysis.selectedKPITrend.selectedPeriod.map(
    //         kpi => kpi.timelineLabel
    //       )
    //     ];
    //   } else {
    //     return [];
    //   }
    // }
  }, // computed
  watch: {
    selectedKPI: {
      handler: 'loadData',
      immediate: true
    },
    compareKPI: {
      handler: 'loadData'
    },
    analysisType: {
      handler: 'loadData'
    },

    kpiCategories: async function(newValue) {
      this.chartOptions.xaxis.categories = this.kpiCategories;
    }
  },
  methods: {
    ...mapActions('comparison', ['fetchKPIAnalysis2']),
    // Loads data and update chart
    async loadData() {
      try {
        this.loading = true;
        await this.fetchKPIAnalysis2({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          compareEndDate: this.getEndingCompared,
          storeCodes: this.getStoreCodesList,
          useCustomCalender: this.getCustomCalenderState,
          selectedKPI: this.selectedKPI,
          compareKPI: this.compareKPI,
          type: this.analysisType
        });
        this.kpiCategories = this.kpiAnalysis.selectedKPITrend.selectedPeriod.map(
          kpi => {
            return (
              kpi.timelineLabel +
              cosn` (${this.$t(
                this.getFullDay(new Date(kpi.timelineLabel).getDay())
              )})`
            );
          }
        );
        await this.setSeriesData();
        await this.setChartOptions();
        this.loading = false;
      } catch (err) {
   //     this.$q.notify({
   //       type: 'negative',
  //        position: 'top',
  //        message: `Comparison Error: ${err}`
  //      });
      } finally {
        this.loading = false;
      }
    }, // loadData
    getLabel(value) {
      let name = this.kpiList.find(val => val.code === value);
      return name ? name.label : '';
    },
    setSeriesData() {
      const {
        kpiAnalysis: { comparedKPITrend, selectedKPITrend }
      } = this;

      let selectedKPIselectPeriod = {
        name:
          this.getLabel(this.selectedKPI) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        data: selectedKPITrend.selectedPeriod.map(kpi => kpi.metricValue)
      };

      let selectedKPIcomparePeriod = {
        name:
          this.getLabel(this.selectedKPI) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        data: selectedKPITrend.comparedPeriod.map(kpi => kpi.metricValue)
      };

      let compareKPIselectPeriod = {
        name:
          this.getLabel(this.compareKPI) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        data: comparedKPITrend.selectedPeriod.map(kpi => kpi.metricValue)
      };

      let compareKPIcomparePeriod = {
        name:
          this.getLabel(this.compareKPI) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        data: comparedKPITrend.comparedPeriod.map(kpi => kpi.metricValue)
      };

      this.series = [
        selectedKPIselectPeriod,
        compareKPIselectPeriod,
        selectedKPIcomparePeriod,
        compareKPIcomparePeriod
      ];
    },
    // setCategories() {
    //   this.kpiCategories = [
    //     ...this.kpiAnalysis.selectedKPITrend.selectedPeriod.map(
    //       kpi => kpi.timelineLabel
    //     )
    //   ];
    // },
    getFullDay(val) {
      if (val === 0) {
        return 'Sun';
      }
      if (val === 1) {
        return 'Mon';
      }
      if (val === 2) {
        return 'Tue';
      }
      if (val === 3) {
        return 'Wed';
      }
      if (val === 4) {
        return 'Thu';
      }
      if (val === 5) {
        return 'Fri';
      }
      if (val === 6) {
        return 'Sat';
      }
    },
    getUnits() {
      if (val === this.$t('sls')) {
        return '円';
      }
      if (val === this.$t('trf')) {
        return '人';
      }
      if (val === this.$t('trn')) {
        return '人';
      }
      if (val === this.$t('cn')) {
        return '%';
      }
      if (val === this.$t('averagesale')) {
        return '円';
      }
      if (val === this.$t('upt')) {
        return '点';
      }
      if (val === this.$t('rov')) {
        return '円';
      }
      if (val === this.$t('aip')) {
        return '円';
      }
      if (val === this.$t('spt')) {
        return '円';
      }
      if (val === this.$t('tpt')) {
        return '人';
      }
      if (val === this.$t('sl')) {
        return '人';
      }
      if (val === this.$t('sh')) {
        return '人';
      }
      if (val === this.$t('cs')) {
        return '人';
      }
      if (val === this.$t('se')) {
        return '円';
      }
      if (val === this.$t('pb')) {
        return '人';
      }
      if (val === this.$t('cr')) {
        return '%';
      }
      // if (val === this.$t('facadestops')) {
      //   return '人';
      // }
      if (val === this.$t('dw')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('repeatrate')) {
        return '-';
      }
      if (val === this.$t('engagementrate')) {
        return '％';
      }
      if (val === this.$t('bouncerate')) {
        return '％';
      }
      if (val === this.$t('gendercomparison')) {
        return '％';
      }
      if (val === this.$t('age')) {
        return '％';
      }
      if (val === this.$t('fittingroomvisits')) {
        return '人';
      }
      if (val === this.$t('fittingroom %')) {
        return '％';
      }
      if (val === this.$t('fittingroomconversion %')) {
        return '％';
      }
      if (val === this.$t('fittingroomdwelltime')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('areapassersby')) {
        return '人';
      }
      if (val === this.$t('areastops')) {
        return '人';
      }
    },
    setChartOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: this.kpiCategories },
        yaxis: [
          {
            decimalsInFloat: 0
          },
          {
            decimalsInFloat: 0,
            opposite: true
          }
        ]
      };
    }
  }
};
