import { PostRequest } from '@/util/http';
import { GetReportingSumDataByLocationFidaV2LocationRep } from '@/api/fida';

export async function APIFetchTrend(params: any) {

  params.kpiTypes = params.metricKey.split(',');
  params.frequency = 'daily';
  params.storeCodes = params.storeCodes.split(',');
  if((params.storeCodes.length == 1 && params.storeCodes[0] == "") || params.startDate ==  null) 
      return [];
  else
  {
    // const response = await APIGetReportingSumByLocationDataFidaV2(params);
    const response = await GetReportingSumDataByLocationFidaV2LocationRep(params);
    return  response;//PostRequestFidaExport('/api/getReportingSumByLocationDataV2', params);
  }

  //return PostRequest(`kpi/trend/${params.metricKey}`, params);
} // APIFetchTrend

export function APIFetchTrendHourly(params: any) {
  return PostRequest(`kpi/trend/${params.metricKey}/hourly`, params);
} // APIFetchTrend
