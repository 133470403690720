import {
  APIDeleteReport,
  APIGetAllReport,
  APIGetSingleReport,
  APISubmitReportFida,
  APICreaPulse,
  APIUpdateReport,
  ReportType,
  ReportBody,
  APIGetFileURL,
  APIFetchTargetValues,
  APIFetchMonthlyPerformance,
  APISalesTrendWithBudget,
  APIGetFormLastPeriodValues,
  APIWeeklyBudgetTable
} from '@/api/PeriodicReport';
import {APIGetSavedPreferencesForPulse} from '@/api/kpi';
import {transformPulseGetAll} from '@/util/utilFidaFunctions';
import {
  base64ToArrayBuffer,
  saveByteArray,
  saveByteArrayPdf
} from '../../util/utilFunctions';
import moment from 'moment';
import {convertResponsePulseSalesTrend} from '@/util/utilFidaFunctions';
import store from '@/store';
import { APICreatePreferencesPulse } from '@/api/kpi';

export interface PeriodicReport {
  attendanceStaff: number;
  compareEndDate: string;
  compareStartDate: string;
  endDate: string;
  kpis: string;
  marketReport: string;
  nextActionPlan: string;
  numberOfEmployees: number;
  lastWeekActionPlan?: string;
  reportID: number;
  reportTitle: string;
  reportType: string;
  startDate: string;
  storeCodes: string;
  updatedBy: string;
  useCustomCalendar: false;
}

const dailyReportDefault = {
  startDate: '',
  numberOfEmployees: '',
  attendanceStaff: '',
  reportTitle: '',
  marketReport: '',
  lastWeekActionPlan: ''
};

export interface FilterForm {
  numberOfEmployees: string;
  attendanceStaff: string;
  reportTitle: string;
  marketReport: string;
  nextActionPlan: string;
  lastWeekActionPlan: string;
  reviewActionPlan: string;
}

export interface PeriodicReportState {
  reportList: PeriodicReport[];
  dailyReportList: PeriodicReport[];
  currentReport: PeriodicReport | {};
  filterFormState: FilterForm;
  monthlyTarget: any;
  weeklyTarget: any;
  selectedStoreCode: String;
  selectedStoreCodePulse: String;
  selectedStore: any;
  selectedStorePulse: any;
  startDate: String;
  endDate: String;
  compareStartDate: String;
  salesTrendData: any;
  compareEndDate: String;
  lastTimeStamp: any;
  lastPeriodValues: any;
  weeklyBudgetTableList: any;
  monthPoints: any;
  yearPoints: any;
  weeklyLoading: any;
  monthlyLoading: any;
  hourlyDate:any;
  weeklyRadarData:any;
}

const initialState: PeriodicReportState = {
  reportList: [],
  weeklyLoading: {
    weeklyRadar: true,
    kpiAchievement: true,
    weeklyTarget: true,
    dailySalesTrend: true,
    weekdaysTrend: true,
    weekendsTrend: true
  },
  monthlyLoading: {
    monthlyRadar: true,
    kpiAchievement: true,
    salesTrend: true,
    trafficTrend: true,
    passersByTrend: true,
    monthlyWeekdays: true,
    monthlyWeekends: true
  },
  dailyReportList: [],
  currentReport: {},
  filterFormState: {
    numberOfEmployees: '',
    attendanceStaff: '',
    reportTitle: '',
    marketReport: '',
    nextActionPlan: '',
    lastWeekActionPlan: '',
    reviewActionPlan: ''
  },
  lastPeriodValues: {},
  monthlyTarget: { selected: {}, compared: {} },
  weeklyTarget: { selected: {}, compared: {} },
  selectedStoreCode: '',
  selectedStoreCodePulse: '',
  selectedStore: {},
  selectedStorePulse: {},
  salesTrendData: {},
  lastTimeStamp: '',
  startDate: '',
  endDate: '',
  compareStartDate: '',
  compareEndDate: '',
  weeklyBudgetTableList: [],
  monthPoints: {},
  yearPoints: {},
  hourlyDate:{},
  weeklyRadarData:{},
};

export default {
  state: initialState,
  mutations: {
    setWeeklyRadar(state, payload) {
      state.weeklyRadarData = payload;
    },
    setWeeklyLoading(state, payload) {
      state.weeklyLoading[payload.component] = payload.value;
    },
    setMonthlyLoading(state, payload) {
      state.monthlyLoading[payload.component] = payload.value;
    },
    setReportList(
      state: PeriodicReportState,
      { reportList }: { reportList: PeriodicReport[] }
    ) {
      state.reportList = reportList;
    },
    setDailyReportList(
      state: PeriodicReportState,
      { reportList, dailyDateList }: { reportList: any; dailyDateList: any }
    ) {
      const dailyReportList = dailyDateList.map(dailyDate => {
        return reportList[dailyDate]
          ? reportList[dailyDate]
          : { ...dailyReportDefault, startDate: dailyDate };
      });
      state.dailyReportList = [...dailyReportList];
    },
    resetFilterForm(state: PeriodicReportState) {
      const formKey = Object.keys(state.filterFormState);
      formKey.forEach(key => (state.filterFormState[key] = null));
    },
    setFilterForm(
      state: PeriodicReportState,
      {
        filterFormKey,
        filterFormValue
      }: { filterFormKey: string; filterFormValue: string }
    ) {
      state.filterFormState[filterFormKey] = filterFormValue;
    },
    setMonthlyTarget: (state: PeriodicReportState, payload) => {
      state.monthlyTarget = payload;
    },
    setLastTimeStamp: (state: PeriodicReportState) => {
      state.lastTimeStamp = Date.now();
    },
    setHoulyChartDate: (state: PeriodicReportState, payload) => {
      state.hourlyDate = payload;
    },
    setWeeklyTarget: (state: PeriodicReportState, payload) => {
      state.weeklyTarget = payload;
    },
    setSelectedStore: (state, payload) => {
      state.selectedStore = payload;
    },
    setSelectedStorePulse: (state, payload) => {
      state.selectedStorePulse = payload;
    },
    setSalesTrendData: (state: PeriodicReportState, payload) => {
      state.salesTrendData = payload;
    },
    setStore(state: PeriodicReportState, storeCode: string) {
      state.selectedStoreCode = storeCode;
    },
    setStorePulse(state: PeriodicReportState, storeCode: string) {
      state.selectedStoreCodePulse = storeCode;
    },
    setCurrentReport(
      state: PeriodicReportState,
      { currentReport }: { currentReport: PeriodicReport }
    ) {
      state.currentReport = { ...currentReport };
    },
    // async deleteReport(
    //   state: PeriodicReportState,
    //   { reportId }: { reportId: string }
    // ) {
    //   const deleteResponse  = await APIDeleteReport(reportId);
    //   console.log('deleteResponse');
    //   console.log(deleteResponse);
    //   console.log('state.reportList');
    //   console.log(state.reportList);
    //   console.log('state.reportId');
    //   console.log(reportId);
    //   const filteredReport = state.reportList.filter(
    //     report => report.reportID.toString() !== reportId
    //   );
    //   state.reportList = [...filteredReport];
    // },
    setLastPeriodValues(state: PeriodicReportState, payload: any) {
      state.lastPeriodValues = payload ? payload : '';
    },
    setWeeklyBudgetTable(state: PeriodicReportState, payload: any) {
      state.weeklyBudgetTableList = payload;
    },
    setMonthlyPoints(state: any, payload: any) {
      state.monthPoints = payload;
    },
    setYearlyPoints(state: any, payload: any) {
      state.yearPoints = payload;
    }
  },
  getters: {
    getWeeklyLoading(state) {
      return Object.values(state.weeklyLoading).some(v => v === true);
    },
    getMonthlyLoading(state) {
      return Object.values(state.monthlyLoading).some(v => v === true);
    }
  },
  actions: {
    getTargetTableValue: async (
      { commit },
      { startDate, endDate, compareStartDate, compareEndDate, storeCodes, type }
    ) => {
      // const res = await APIFetchTargetValues({
      //   startDate: startDate,
      //   endDate: endDate,
      //   compareStartDate: compareStartDate,
      //   compareEndDate: compareEndDate,
      //   storeCodes: storeCodes
      // });
      commit('setMonthlyTarget', {
        selected: [],
        compared: []
      });
      // commit('setMonthlyTarget', {
      //   selected: res.data.selectedPeriod,
      //   compared: res.data.comparePeriod
      // });
    },
    getSalesTrendWithBudget: async (
      { commit },
      { startDate, endDate, compareStartDate, compareEndDate, storeCodes }
    ) => {
      const res:any = await APISalesTrendWithBudget({
        startDate: startDate,
        endDate: endDate,
        compareStartDate: compareStartDate,
        compareEndDate: compareEndDate,
        storeCodes: storeCodes
      });
    
      const apiresponse = res;
      const finalResult = convertResponsePulseSalesTrend(apiresponse);
      // kpis  //kpisCompare
      //Transform response
      
      commit('setSalesTrendData', finalResult.data);

    },
    getTargetTableValueWeekly: async (
      { commit },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        storeCodes,
        type,
        yearStartingDate,
        yearEndingDate,
        compareYearStartingDate,
        compareYearEndingDate,
        monthStartingDate,
        monthEndingDate,
        compareMonthStartingDate,
        compareMonthEndingDate
      }
    ) => {
      const res = await APIFetchMonthlyPerformance({
        startDate: startDate,
        endDate: endDate,
        compareStartDate: compareStartDate,
        compareEndDate: compareEndDate,
        storeCodes: storeCodes,
        yearStartingDate: yearStartingDate,
        yearEndingDate: yearEndingDate,
        compareYearStartingDate: compareYearStartingDate,
        compareYearEndingDate: compareYearEndingDate,
        monthStartingDate: monthStartingDate,
        monthEndingDate: monthEndingDate,
        compareMonthStartingDate: compareMonthStartingDate,
        compareMonthEndingDate: compareMonthEndingDate
      });
///have to change
      commit('setWeeklyTarget', [res.data]);
    },
    getAllPeriodicReport: async ({ commit }, params = {}) => {

      var parm:any;
      var parm2:any;
      parm = params;
      parm2 = params;
 
      if(parm2.storeCodes.split(',').length > 1 || parm2.storeCodes == 'all')
        delete parm2.storeCodes;//all
      const apiResponse2  = await APIGetAllReport(parm2);
      
      var apiResponse = apiResponse2.data.response.reports;
    
    commit('setReportList', {
      reportList: apiResponse ? apiResponse : []
    });
      // commit('setReportList', {
      //   reportList: apiResponse.data ? apiResponse.data : []
      // });
    },
    getDailyPeriodicReport: async ({ commit }, params) => {
      // const apiResponse = await APIGetAllReport(params);
      var apiResponse:any = await APIGetSavedPreferencesForPulse(params);
     // apiResponse = transformPulseGetAll(apiResponse,params);
      const dailyDateArray = [];
      for (let i = 0; i < 7; i++) {
        // * create an array and fill all the date between start and end date
        dailyDateArray.push(
          moment(params.startDate)
            .add(i, 'days')
            .format('YYYY-MM-DD')
        );
      }
      const reportList =
        apiResponse && apiResponse.length
          ? apiResponse.reduce(function(map, obj) {
              // * convert to hashmap
              map[obj.startDate] = obj;
              return map;
            }, {})
          : {};
      commit('setDailyReportList', {
        reportList: apiResponse ? reportList : {},
        dailyDateList: dailyDateArray
      });
    },
    deleteReport: async ({ commit }, reportId) => {
      const apiResponse = await APIDeleteReport(reportId);
     // commit('deleteReport', { reportId });
    },
    createPeriodicReport: async (
      { commit },
      { body }: {  body }
    ) => {
      const apiResponse = await APICreaPulse(body);//APISubmitReport(reportType, body);
      return apiResponse;
    },


    submitPeriodicReport: async (
      { commit },
      { reportType, body }: { reportType: ReportType; body: ReportBody }
    ) => {


      const apiResponse = await APISubmitReportFida(reportType, body);//APISubmitReport(reportType, body);
      return apiResponse;
    },
    getSingleReport: async ({ commit }, reportId,reportType) => {
      var params = {}
      params = {
        _id :reportId,
        reportType: reportType
      };
      const apiResponse = await APIGetAllReport(params);
 
      commit('setCurrentReport', { currentReport: apiResponse.data.response.reports[0] });
    },
    
    updatePeriodicReport: async (
      { commit },
      {  body }: {body }
    ) => {
      const apiResponse = await APIUpdateReport( body);
      return apiResponse;
    },
    downloadPeriodicReport: async ({ commit }, filename) => {
      const apiResponse = await APIGetFileURL(filename);
      const finalURL = apiResponse.data.response.url
      console.log(finalURL);
      await downloadPdfFromUrl(finalURL);

      // const sampleArr = base64ToArrayBuffer(apiResponse.data.encodedData);
      // saveByteArrayPdf(`${apiResponse.data.fileName}`, sampleArr);
      // const apiResponse = await APIDownloadReport(reportId);
      // const sampleArr = base64ToArrayBuffer(apiResponse.data.encodedData);
      // saveByteArrayPdf(`${apiResponse.data.fileName}`, sampleArr);
    },
    async getFormLastPeriodValues(
      { commit },
      { reportType, startDate, endDate, storeCodes }
    ) {
      // let res = await APIGetFormLastPeriodValues(
      //   reportType,
      //   startDate,
      //   storeCodes,
      //   endDate
      // );
      // commit('setLastPeriodValues', res.data);
      let res = null;
      commit('setLastPeriodValues', res);
      // commit('setLastPeriodValues', res.data);
    },
    fetchWeeklyBudgetTable: async ({ commit }, filterParams) => {
      const apiResponse = await APIWeeklyBudgetTable(filterParams);
      commit('setWeeklyBudgetTable', apiResponse.data);
    }
  }
};
async function downloadPdfFromUrl(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const filename = "Pulse_Report.pdf";
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
}
