import Vue from 'vue';
import VueIntercom from '@mathieustan/vue-intercom';

var appId = 'i469xv5b';
var status = localStorage.getItem("status");
if(status == 'staging')
{
  appId = 'zrv8wvij';
}
Vue.use(VueIntercom, { appId: appId});
