import { render, staticRenderFns } from "./KpiBudgetTable.vue?vue&type=template&id=0042f96d&"
import script from "./KpiBudgetTable.vue?vue&type=script&lang=js&"
export * from "./KpiBudgetTable.vue?vue&type=script&lang=js&"
import style0 from "./KpiBudgetTable.vue?vue&type=style&index=0&id=0042f96d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./KpiBudgetTable.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./KpiBudgetTable.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QMarkupTable} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMarkupTable})
