
import imports from "@/services/imports";
import { downloadAsBlob } from "@/util/file";
// import ImportSection from '@/components/modals/ImportSection';
import ImportResult from "./ImportResult";
import { mapGetters, mapActions } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import ToolsMixin from "@/mixins/ToolsMixin";
import { mapState } from "vuex";

export default {
  components: {
    ImportResult,
  },
  mixins: [CommonMixin, ToolsMixin],
  //   props: {
  //     saleTargeOpen: Boolean
  //   },
  data() {
    return {
      loading: false,
      addSales: true,
      saleTargetOpen: false,
      importData: null,
      selectedKPI: "st",
      isSuccess: false,
      // options: [
      //   {
      //     label: this.$t('KPI.sales'),
      //     value: 'sales'
      //   },
      //   {
      //     label: this.$t('KPI.salesTarget'),
      //     value: 'sales-target'
      //   },
      //   {
      //     label: this.$t('KPI.traffic'),
      //     value: 'traffic'
      //   },
      //   {
      //     label: this.$t('KPI.staffhours'),
      //     value: 'staff-hours'
      //   }
      // ],
      selectedSalesMappingTemplate: null,
      salesMappingTemplates: [],
    };
  },
  computed: {
    ...mapGetters("store", ["getStoreCodeList"]),
    ...mapGetters("user", ["getLocale"]),
  },
  watch: {
    saleTargetOpen(val) {
      this.loading = false;
      this.addSales = true;
      this.importData = null;
    },
  },
  methods: {
    ...mapActions("user", ["fetchStores"]),
    addStore() {},
    onFileSelected(evt) {
      try {
        const file = evt.target.files[0];
        this.handleUpload(file);
        evt.target.files[0] = "";
      } catch (e) {
        //console.log(e)
      }
    },
    tryAgain() {
      this.saleTargetOpen = true;
      this.loading = false;
      this.addSales = true;
      this.importData = null;
    },
    updateSelectedKPI(kpi) {
      this.selectedKPI = kpi.value;
    },
    async downloadTemplate() {
      const salesResponse =
        "Store Code,Transaction Date,Transaction Hour,Total Transactions,Items Sold,Sales Amount\nxxx,YYYYMMDD,hhmm,1111,1111,1111";
      const salesTargetResponse = "Store Code,Date,Sales Target\nxxx,YYYYMMDD,1111";
      const trafficResponse =
        "Store Code,Date,Time,Entrance,IN_OUT,Traffic\nxxx,YYYYMMDD,hhmm,xxx,xxx,xxx";
      const staffHoursResponse =
        "Store Code,Staff Hour Date,Staff Hour Time,Staff In Store\nxxx,YYYYMMDD,hhmm,xxx";

      const selectedKPI = this.selectedKPI + "-template.csv";
      const fileType = "text/csv";

      if (this.selectedKPI == "sls") downloadAsBlob(salesResponse, fileType, selectedKPI);
      else if (this.selectedKPI == "st")
        downloadAsBlob(salesTargetResponse, fileType, "Sales-target");
      else if (this.selectedKPI == "traffic")
        downloadAsBlob(trafficResponse, fileType, selectedKPI);
      else if (this.selectedKPI == "staff-hours")
        downloadAsBlob(staffHoursResponse, fileType, selectedKPI);
    },

    downloadAsBlob(raw, type, filename) {
      const blob = new Blob([raw], { type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleUpload(file) {
      try {
        var storeCodes;
        var finaltoresList;
        this.loading = true;
        this.addSales = false;
        if (!this.getStoreCodeList) {
          storeCodes = await this.fetchStores();
          finaltoresList = storeCodes.stores.map((x) => x.storeCode);
        } else {
          finaltoresList = this.getStoreCodeList;
        }
        const response = await imports.importReportData(
          file,
          //  this.selectedSalesMappingTemplate.id,
          this.selectedKPI,
          finaltoresList,
          this.getLocale
        );

        this.loading = false;
        this.importData = response;
        if (
          response == "" ||
          (response.successCount ? response.successCount : 0) > 0 ||
          response?.status == 200
        ) {
          await this.showUploadResult(response);
          this.isSuccess = true;
          // this.$q.notify({
          //   message: $store.state.user.translate.importedSuccessfully,
          //   color: 'green'
          // });
        } else {
          await this.showUploadResult(response);
          this.isSuccess = false;
          // this.$q.notify({
          //   message: response.message,
          //   color: 'red'
          // });
        }
      } catch (error) {
        this.loading = false;
        this.tryAgain()
        await this.showUploadResult({
          message: this.$t(`Something went wrong`),
        });
        this.isSuccess = false;
        this.importData = error?.response?.data || error;
        const errorMessage = this.$t(`Something went wrong`); //this.$store.state.user.translate.something_went_wrong;
        this.$q.notify({
          message: errorMessage,
          color: "red",
        });
        
      }
    },
    showUploadResult(err) {
      if (err.message && err.message?.error && err.message?.error?.key) {
        var failCount = err.message?.error?.failed;
        err.message = err.message?.error?.key;
        err.failedCount = failCount ? failCount : err.failedCount;
      }
      //   const randomFactor = Math.random()
      //     .toString(36)
      //     .substr(2);
      //   const idSrc = `000000${randomFactor}`;
      //   const id = idSrc.substr(idSrc.length - 6);
      //   const res = await this.$repo.extension.saveUploadResult(
      //     id,
      //     err,
      //     this.selectedKPI
      //   );

      // if( message == '{{successCount}} data(s) has been successfully imported, but there are still {{failedCount}} error(s)')
      // {
      //   message = this.$t('Extension.SuccessFailCount')
      // }

      // if( message == 'There are {{failedCount}} error(s)')
      // {
      //   message = this.$t('Extension.ThereError')
      // }
      //  message = message.replace(`{{failedCount}}`, res.failedCount);

      // if( message == '{{successCount}} data(s) has been successfully imported')
      // {
      //   message = this.$t('Extension.SuccessMsg')
      // }
      // message = message.replace(`{{failedCount}}`, 0);

      // message = message.replace(`{{successCount}}`, res.successCount);

      // if ([400, 401, 403, 402].includes(res?.status)) {
      //   if(message=='No message available') {
      //     this.$q.notify({
      //       message: this.$t('Extension.Message'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //     }
      //   else if(message=='Unexpected error has occurred') {
      //      this.$q.notify({
      //       message: this.$t('Extension.UnexpectedError'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else if(message=='Failed to upload because the file format is not valid') {
      //      this.$q.notify({
      //       message: this.$t('Extension.FailedToUpload'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else {
      //     this.$q.notify({
      //     message: message,
      //     position: 'top',
      //     type: 'negative'
      //     });
      //   }
      // } else {
      //   if(message=='No message available') {
      //     this.$q.notify({
      //       message: this.$t('Extension.Message'),
      //       position: 'top',
      //       type: 'positive'
      //     });
      //   }
      //   else
      //   if(message=='Invalid header file'){
      //     this.$q.notify({
      //       message:  this.$t('Import.invalid_header'),
      //       position: 'top',
      //       type: 'negative'
      //     })
      //   }
      //   else
      //   if(message=='Failed to upload because the file format is not valid') {
      //      this.$q.notify({
      //       message: this.$t('Extension.FailedToUpload'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else{
      //     this.$q.notify({
      //       message: message,
      //       position: 'top',
      //       type: 'positive'
      //     });
      //   }
      // }
      //   await this.$router.push({
      //     name: 'tools:extension:upload-result',
      //     params: {
      //       id
      //     }
      //   });
    },
  },
};
