
import { mapGetters, mapState } from 'vuex';

import User from '@/models/User';
import map from 'lodash/map';
import { handleErrorUI } from '@/util/error';
import PaginationMixin from '@/mixins/PaginationMixin';
import Filters from '@/components/Common/Filters.vue';
import EmployeeTable from '@/components/Management/User/EmployeeTable.vue';
import { checkPermission } from '@/api/utils';

export default {
  components: { EmployeeTable },
  mixins: [PaginationMixin],
  data() {
    return {
      data: [],
      deleteConfirmationShown: false,
      selectedUser: {
        status: 'DISABLED'
      },
      loading: false,
      type: 'disable'
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount']),
    ...mapState('user', ['defaultSettings']),
    fullNameOptions() {
      return map(this.data, user => ({
        label: user.fullName,
        value: user.fullName
      }));
    },
    usernameOptions() {
      return map(this.data, user => ({
        label: user.username,
        value: user.username
      }));
    },
    emailOptions() {
      return map(this.data, user => ({
        label: user.email,
        value: user.email
      }));
    },
    selectedUserName() {
      if (this.selectedUser == null) return '';
      return `${this.selectedUser.fullName} (${this.selectedUser.username ||
        this.selectedUser.email})`;
    }
  },
  async mounted() {
    await this.fetchUsers();
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    showDeleteConfirmation(user: any) {
      this.selectedUser = user;
      this.deleteConfirmationShown = true;
    },
    resendInvite: async function(user: any) {
      try {
        await this.$repo.user.reInvite({
          email: user.email
        });
        this.$q.notify({
          position: 'top',
          type: 'positive',
          message: this.$t('AuthPage.sentSuccessfully')
        });
      } catch (err) {
        this.$q.notify({
          position: 'top',
          type: 'positive',
          message: err.response.data.message
        });
      }
    },
    onUserDisable: async function() {
      try {
        if (this.selectedUser.email === this.defaultSettings.user.response.userDetails.email) {
          this.$q.notify({
            position: 'top',
            type: 'negative',
            message: this.$t('AuthPage.cannot_disable_yourself')
          });
        } else {
          await this.$repo.user.deleteUserSSO({
              id: this.selectedUser._id
            });
            this.$q.notify({
              position: 'top',
              type: 'positive',
              message: this.$t('AuthPage.user_deleted_successfully')
            });

          /*
          if (this.selectedUser.status === 'ENABLED') {
            await this.$repo.user.deleteUserSSO({
              id: this.selectedUser._id,
              status: 'DISABLED'
            });
            this.$q.notify({
              position: 'top',
              type: 'positive',
              message: this.$t('AuthPage.user_disabled_successfully')
            });
          } else {
            await this.$repo.user.deleteUserSSO({
              id: this.selectedUser._id,
              status: 'ENABLED'
            });
            this.$q.notify({
              position: 'top',
              type: 'positive',
              message: this.$t('AuthPage.user_enabled_successfully')
            });
          }*/
        }
      } catch (e) {
        handleErrorUI(e);
      }
    },
    removeUser() {
      const user = this.data.find(user => user._id === this.selectedUser._id);
      if (this.defaultSettings.user.response.userDetails.email !== user.email) {
        /*user.status =
          this.selectedUser.status === 'DISABLED' ? 'ENABLED' : 'DISABLED';*/
          this.data.splice(this.selectedUser._id, 1);
      }
    },
    async fetchUsers() {
      this.loading = true;
      try {
        const { users, invitedUsers } = await this.$repo.user.getUsersSSO();
        this.data = [...users, ...invitedUsers].map(
          ({ email, fullName, username,roleName, _id, enabled }) => ({
            fullName,
            username,
            email,
            roleName:roleName=="FlowTenantAdmin"?this.$t('SettingsManagement.Administrativerole'):roleName,
            status:
              _id == undefined ? 'PENDING' : enabled ? 'ENABLED' : 'DISABLED',
            _id
          })
        );
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchUsers();
    }
  }
};
