
//JSON.parse(localStorage.getItem('vuex')).user.locale

const isNumeric = value => !isNaN(parseFloat(value)) && isFinite(value);
function checkFormatAndColumns(fileData, requiredColumns) {
  if (fileData.length === 0) {
    return 'invalidFileFormat';
  }
  if (fileData[0].length !== requiredColumns.length) {
    return 'columnMismatch';
  }

  if (fileData.length < 2 ) {
    return 'emptyFileError';
  }

  for (let i = 0; i < requiredColumns.length; i++) {
    if (fileData[0][i] !== requiredColumns[i]) {
      return 'columnMismatch';
    }
  }

  return ''
}
function checkColumnLength(i, rowData, requiredColumns) {
  if (rowData.length !== requiredColumns.length) {
    return 'invalidDataRow'+','+i + '\n';
  }
  return ''
}
function checkIfRowNumberic(i, rowData, requiredColumns, s = 0, indexes) {
  let retVal = ''
  if(!indexes) {
    for (let j = s; j < rowData.length; j++) {
      if ( !isNumeric(rowData[j])) {
        retVal += 'invalidNumericValueColumn'+','+i+','+requiredColumns[j] + '\n';
      }
    }
    return retVal;
  } else {
    indexes.forEach((itm) => {
      if ( !isNumeric(rowData[itm])) {
        retVal += 'invalidNumericValueColumn'+','+i+','+requiredColumns[itm] + '\n';
      }
    })
    return retVal;
  }
}
function checkEmptyString(val, ind) {
  if(val == '') {
    return 'entranceNameMissing'+','+ind + '\n'
  }

  return ''
}
function forcedValueCheck(val, possibleValues, ind, colName) {
  if(!possibleValues.includes(val.toUpperCase())) {
    return 'invalidValue'+','+ind +',' +colName+ '\n'
  }
  return ''
}
function isTimeValid(val, ind, colName) {
  if(!isNumeric(val)) {
    return 'invalidNumericValueColumn'+','+ind +','+colName+ '\n'
  }
  let nVal = parseInt(val)
  if(nVal < 0 || nVal%100 !== 0 || (nVal/100) > 24) {
    return 'invalidValue'+','+ind +',' +colName+ '\n'
  }
  return ''
}

async function  importFileValidations(file, kpi, validStoreCodes, language = 'en') {
  let retError = {
    message: 'fileRequired',
    successCount: 0,
    failedCount: 0
  };
  if (!file) {
    return {...retError, ...{message: "fileRequired"}}
  }

  if (typeof kpi !== 'string' || kpi.trim() === '') {
    return {...retError, ...{message: "kpiInvalid"}}
  }

  if(validStoreCodes == '')  {
    return {...retError, ...{message: "storeCodesNotFound"}}
  }
  const fileData = await readFile(file);
  if (!fileData) {
    return {...retError, ...{message: "invalidFileFormat"}}

  }

  let validationMessage;
  if (kpi === 'sls') {
    validationMessage = validateSaleFile(fileData, validStoreCodes, language);
  } else if (kpi === 'trf') {
    validationMessage = validateTrafficFile(fileData, validStoreCodes, language);
  } else if (kpi === 'sh') {
    validationMessage = validateStaffHoursFile(fileData, validStoreCodes, language);
  } else if (kpi === 'st') {
    validationMessage = validateSalesTargetFile(fileData, validStoreCodes, language);
   
  } else {
    validationMessage = invalidKPIValue;
  }
  var success = 0;
  var fail = 0;
  validationMessage = [...new Set(validationMessage.split('\n'))].join('\n');
  invalidIndexes = validationMessage.split('\n').map((msg) => msg?.split(',')?.[1] ).filter(ele => ele != undefined);
  var msg = validationMessage;
  if(validationMessage === 'Success')
  {
    success = fileData.length -1;
    msg = 'success';//KEY
  }
  else
  {
    fail = fileData.length -1;
  }
  console.log({
    message: msg,//validationMessage === 'Success' ? 'Success' : validationMessage,
    successCount: success,
    failedCount: fail
  },'error'
)
  return {
    message: msg,//validationMessage === 'Success' ? 'Success' : validationMessage,
    successCount: success,
    failedCount: fail
  }

}

function validateSaleFile(fileData, validStoreCodes, language) {
  const requiredColumns = ['Store Code', 'Transaction Date', 'Transaction Hour', 'Total Transactions', 'Items Sold', 'Sales Amount'];
  let retVal = ''

  retVal = checkFormatAndColumns(fileData, requiredColumns);
  if(retVal) return retVal;
  // for (let i = 1; i < fileData.length; i++) {
  //   const rowData = fileData[i];
  //   retVal += checkColumnLength(i, rowData, requiredColumns)
  //   retVal += checkIfRowNumberic(i, rowData, requiredColumns, 1)
  //   retVal += isTimeValid(rowData[2], i, requiredColumns[2]);
  // }
  return retVal || 'Success'
}

function validateTrafficFile(fileData, validStoreCodes, language) {
  const requiredColumns = ['Store Code', 'Date', 'Time', 'Entrance', 'IN_OUT', 'Traffic'];
  let retVal = ''

  retVal = checkFormatAndColumns(fileData, requiredColumns);
  if(retVal) return retVal;
  // for (let i = 1; i < fileData.length; i++) {
  //   const rowData = fileData[i];
  //   retVal += checkColumnLength(i, rowData, requiredColumns)
  //   retVal += checkIfRowNumberic(i, rowData, requiredColumns, 0, [0, 1, 2, 5])
  //   retVal += isTimeValid(rowData[2], i, requiredColumns[2]);
  //   retVal += checkEmptyString(rowData[3], i)
  //   retVal += forcedValueCheck(rowData[4], ["IN", "OUT"], i, requiredColumns[4])
  // }
  return retVal || 'Success';
}

function validateStaffHoursFile(fileData, validStoreCodes, language) {
  const requiredColumns = ['Store Code', 'Staff Hour Date', 'Staff Hour Time', 'Staff In Store'];
  let retVal = ''

  retVal = checkFormatAndColumns(fileData, requiredColumns);
  if(retVal) return retVal;
  // for (let i = 1; i < fileData.length; i++) {
  //   const rowData = fileData[i];
  //   retVal += checkColumnLength(i, rowData, requiredColumns)
  //   retVal += checkIfRowNumberic(i, rowData, requiredColumns)
  //   retVal += isTimeValid(rowData[2], i, requiredColumns[2]);
  // }
  return retVal || 'Success';
}


function validateSalesTargetFile(fileData, validStoreCodes, language) {
  const requiredColumns = ['Store Code', 'Date', 'Sales Target'];
  let retVal = ''

  retVal = checkFormatAndColumns(fileData, requiredColumns);
  // if(retVal) return retVal;
  // for (let i = 1; i < fileData.length; i++) {
  //   const rowData = fileData[i];
  //   retVal += checkColumnLength(i, rowData, requiredColumns)
  //   retVal += checkIfRowNumberic(i, rowData, requiredColumns)
  // }

  return retVal || 'Success';
}

async function readFile(file) {
return new Promise((resolve, reject) => {
  const fileReader = new FileReader();

  fileReader.onload = (event) => {
    const fileData = parseCSV(event.target.result);
    resolve(fileData);
  };

  fileReader.onerror = (event) => {
    reject(event.target.error);
  };

  fileReader.readAsText(file);
});
}

function parseCSV(csvData) {
  const lines = csvData.split('\n');
  const fileData = [];

  for (let i = 0; i < lines.length; i++) {
    const row = lines[i].split(',').map(value => value.trim());


    if (i === lines.length - 1 && row.every(value => value.trim() === '')) {
      // Skip the last row if it's empty
      continue;
    }

    fileData.push(row);
  }

  return fileData;
}

module.exports = {
  importFileValidations,
  readFile
};
