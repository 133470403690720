
import am from "@/services/am";
import users from "@/services/users.js";
import stores from "@/services/stores.js";
import StoreModel from "@/components/modals/StoreModel";

export default {
  components: {
    StoreModel,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedAreaManager", "storeData"],
  // props: {
  //   selectedAreaManager: {
  //     type: Object,
  //     default: null,
  //   },
  //   storeData: {
  //     type: Array,
  //     default: () => [],
  //   }
  // },
  data() {
    return {
      loadingBtn: false,
      configuration: true,
      title: "",
      // storeData: [],
      selectedStores:[],
      openStoreModel: false,
      dataAccessibleColumns: [
        {
          name: "name",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "name",
          sortable: true,
        },
        {
          name: "storeNumber",
          align: "left",
          label: this.$store.state.user.translate.store_number,
          field: "storeNumber",
          sortable: true,
        },
        // { name: "brand", label: "Brand", field: "brand" },
        {
          name: "myStore",
          label: this.$store.state.user.translate.my_store,
          field: "myStore",
        },
        {
          name: "availability",
          label: this.$store.state.user.translate.actions,
          field: "availability",
        },
      ],
      dataAccessibleRows: [],
      name: "",
      description: "",
      selectedStore: "",
    };
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
  },
  mounted() {
    this.requestUserDetails();
    if (this.selectedAreaManager) {
      this.title =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.area_manager;
      this.name = this.selectedAreaManager.title;
      this.dataAccessibleRows = this.selectedAreaManager.stores;
       this.selectedStores =  this.selectedAreaManager.stores;
      this.description = this.selectedAreaManager.description;
    } else {
      this.title =
        this.$store.state.user.translate.add +
        " " +
        this.$store.state.user.translate.area_manager;
    }
  },
  methods: {
    createAreaManager() {
      if (this.selectedAreaManager) {
        let storeIds = [];
        this.dataAccessibleRows.forEach((element) => {
          storeIds.push(element.storeNumber);
        });
        let areaManagerData = {
          title: this.name,
          description: this.description,
          storeCodes: storeIds,
          areaManagerId: this.selectedAreaManager._id,
        };
        if (this.dataAccessibleRows.length > 0) {
           this.loadingBtn = true
          am.updateAreaManager(areaManagerData)
            .then(() => {
              this.$q.notify({
                message: this.$store.state.user.translate.updated_successfully,
                color: "green",
              });
              this.loadingBtn = false
              this.$emit("updateAreaManager");
            })
            .catch((error) => {
              // console.log(error);
              if (error.response.data.message) {
                this.$q.notify({
                  message: error.response.data.message,
                  color: "red",
                });
              } else {
                this.$q.notify({
                  message: this.$store.state.user.translate
                    .something_went_wrong,
                  color: "red",
                });
              }
                 this.loadingBtn = false
              this.$emit("closeModel", false);
            });
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.add_stores,
            color: "red",
          });
        }
      } else {
        let storeIds = [];
        this.dataAccessibleRows.forEach((element) => {
          storeIds.push(element.storeNumber);
        });
        let areaManagerData = {
          title: this.name,
          description: this.description,
          storeCodes: storeIds,
        };
        
        if (this.dataAccessibleRows.length > 0) {
          this.loadingBtn = true
          am.createAreaManager(areaManagerData)
            .then(() => {
              this.$q.notify({
                message: this.$store.state.user.translate.areaManager_created_successfully,
                color: "green",
              });
                 this.loadingBtn = false
              this.$emit("updateAreaManager");
            })
            .catch((error) => {
              // console.log(error);
              if (error.response.data.message) {
                this.$q.notify({
                  message: error.response.data.message,
                  color: "red",
                });
              } else {
                this.$q.notify({
                  message: this.$store.state.user.translate
                    .something_went_wrong,
                  color: "red",
                });
              }
              this.loadingBtn = false
              this.$emit("closeModel", false);
            });
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.add_stores,
            color: "red",
            type: 'negative',
            position: 'top',
            timeout: 3000
          });
        }
      }
    },

    unassignUserStore(data) {
      let storeData = {
        storeId: data.row._id,
        userId: this.userDetails._id,
      };
      stores
        .unassignUserStore(storeData)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.store_unassigned_successfully,
            color: "green",
          });
           var copy = JSON.parse(JSON.stringify(this.dataAccessibleRows));
          this.dataAccessibleRows = copy.filter((item) => item._id != data.row._id);
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    requestUserDetails() {
      users.getUserDetails().then((response) => {
        this.userDetails = response.data.response.userDetails;
      });
    },
    closeStoreModel() {
      this.openStoreModel = false;
    },
    async updateStoreValue(value) {
      this.dataAccessibleRows = value;
      this.openStoreModel = false;
    },
  },
};
