import { FTPConfig } from '@/models/FTPConfig';
import FTPSetupController from '@/services/tools/FTPSetupController';
import localForage from 'localforage';
import { Pagination, PaginationResponse } from '@/util/pagination';
import SalesTemplateController from '@/services/tools/SalesTemplateController';
import { SalesTemplateMap } from '@/models/SalesTemplateMap';
const STORED_UPLOAD_RESULT_PREFIX = 'extension';

export interface GetSalesTemplateMapResult {
  data: SalesTemplateMap[];
  pagination: PaginationResponse;
}

export default class ExtensionRepository {
  ftpSetupController: FTPSetupController;
  salesTemplateController: SalesTemplateController;

  constructor(
    ftpSetupController: FTPSetupController,
    salesTemplateController: SalesTemplateController
  ) {
    this.ftpSetupController = ftpSetupController;
    this.salesTemplateController = salesTemplateController;
  }

  public async saveUploadResult(id: string, result: any) {
    let res = await localForage.setItem(
      `${STORED_UPLOAD_RESULT_PREFIX}::${id}`,
      result
    );
    return res;
  }

  public async getUploadResult(id: string) {
    return await localForage.getItem(`${STORED_UPLOAD_RESULT_PREFIX}::${id}`);
  }

  public async getFTPConfiguration() {
    const data = await this.ftpSetupController.getConfig();
    return FTPConfig.fromDTO(data);
  }

  public async upsertFTPConfiguration(config: FTPConfig) {
    if (config.id == null) {
      return this.createFTPConfiguration(config);
    } else {
      return this.updateFTPConfiguration(config);
    }
  }

  public createFTPConfiguration(config: FTPConfig) {
    return this.ftpSetupController.createConfig(config.toDTO());
  }

  public updateFTPConfiguration(config: FTPConfig) {
    return this.ftpSetupController.updateConfig(config.toDTO());
  }

  public checkFTPConnection(config: FTPConfig) {
    return this.ftpSetupController.checkConnection(config.toDTO());
  }

  public async getSalesTemplates(
    kpi: string,
    pagination: Pagination = new Pagination()
  ) {
    const {
      content,
      ...meta
    } = await this.salesTemplateController.getSalesTemplates(
      kpi,
      pagination.toRequest()
    );
    return {
      data: content.map(dto => SalesTemplateMap.fromDTO(dto)),
      pagination: meta
    } as GetSalesTemplateMapResult;
  }

  public async getSalesTemplateMaster() {
    return await this.salesTemplateController.getSalesTemplateMaster();
  }

  public async uploadSalesTemplate(file: File) {
    return await this.salesTemplateController.uploadSalesTemplate(file);
  }

  public async saveSalesTemplateMapping(name, filename, filepath, mapping) {
    return await this.salesTemplateController.saveMapping({
      mappingName: name,
      fileName: filename,
      filePath: filepath,
      mapping
    });
  }

  public async updateSalesTemplateMapping(
    id,
    name,
    filename,
    filepath,
    mapping
  ) {
    return await this.salesTemplateController.updateMapping(id, {
      mappingName: name,
      fileName: filename,
      filePath: filepath,
      mapping
    });
  }

  public async downloadSalesMappingTemplate(
    map: SalesTemplateMap,
    kpi: string
  ) {
    return await this.salesTemplateController.downloadSalesMappingTemplate(
      map.id,
      kpi
    );
  }

  public async deleteSalesTemplateMapping(id: string, kpi: string) {
    await this.salesTemplateController.deleteSalesMappingTemplate([id], kpi);
  }
}
