var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('q-card',{staticClass:"dialog dialog-card",staticStyle:{"width":"100%","max-width":"640px"}},[_c('q-card-section',{staticClass:"dialog-header"},[_c('h5',[_vm._v(_vm._s(_vm.$t('PaymentMethod.AddPaymentMethod')))])]),[_c('q-card-section',{staticClass:"scroll"},[_c('div',{staticClass:"q-mb-md"},[_c('c-input',{attrs:{"no-margin":"","label":_vm.$t('PaymentMethod.CardHolderName'),"placeholder":_vm.$t('PaymentMethod.TypeCardholderName')},model:{value:(_vm.cardholderName),callback:function ($$v) {_vm.cardholderName=$$v},expression:"cardholderName"}})],1),_c('div',{staticClass:"row q-gutter-md"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"q-mb-md"},[_c('c-field',{attrs:{"label":_vm.$t('PaymentMethod.CardNumber'),"no-margin":""}},[_c('div',{ref:"card-number",class:[_vm.$style['card-field']]})])],1),_c('div',{staticClass:"row q-gutter-sm q-mb-md"},[_c('c-field',{staticClass:"col",attrs:{"label":_vm.$t('PaymentMethod.CVV'),"no-margin":""}},[_c('div',{ref:"card-cvc",class:[_vm.$style['card-field']]})]),_c('c-field',{staticClass:"col",attrs:{"label":"Expiry Date","no-margin":""}},[_c('div',{ref:"card-expiry",class:[_vm.$style['card-field']]})])],1),_c('div',{staticClass:"q-mb-md"},[_c('c-input',{attrs:{"no-margin":"","label":_vm.$t('PaymentMethod.PostalCode'),"placeholder":_vm.$t('PaymentMethod.TypePostalCode'),"rules":[
                  v => !!v || _vm.$t('AuthPage.field_is_required'),
                  v => /^[0-9]*$/.test(v) || _vm.$t('AuthPage.use_only_numbers'),
                  v => !/\s/g.test(v) || _vm.$t('AuthPage.use_only_numbers')
                ]},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1)]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"q-mb-md"},[_c('c-input',{attrs:{"no-margin":"","label":_vm.$t('PaymentMethod.BillingAddress'),"placeholder":_vm.$t('PaymentMethod.TypeBillingAddress'),"type":"textarea"},model:{value:(_vm.billingAddress),callback:function ($$v) {_vm.billingAddress=$$v},expression:"billingAddress"}})],1)])])]),_c('q-card-actions',{staticClass:"dialog-actions q-py-md"},[_c('c-btns',[_c('c-btn',{attrs:{"elevated":"","label":_vm.submitting
                ? _vm.$t('PaymentMethod.Saving')
                : _vm.$t('PaymentMethod.AddPaymentMethod'),"disable":_vm.submitting,"color":"secondary"},on:{"click":_vm.addPaymentMethod}})],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }