
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { MONO_TONE } from '../../util/colors';

export default {
  components: { BoxHeader, LoadingSpinner },
  data() {
    return {
      loading: false,
      series: [],
      formattedValuesSelected: [],
      formattedValuesCompared: [],
      temperature: [],
      weatherIcon: [],
      chartOptions: {
        chart: {
          // parentHeightOffset: 0,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        colors: MONO_TONE,
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        legend: {
          show: false
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const dataPoint =
              w.config &&
              w.config.series &&
              w.config.series.length &&
              w.config.series[0].data
                ? w.config.series[0].data[dataPointIndex]
                : [];
            return `
                <q-card class="q-pa-md tooltip-container">
                  <div class="flex q-mb-sm q-px-md justify-between">
                    <label>
                      ${dataPoint.x} (
                        ${this.$t(
                          `Date.${this.getFullDay(
                            new Date(dataPoint.x).getDay()
                          )}`
                        )}
                      )
                    </label>
                  <div class="q-ml-xl flex">
                    ${
                      this.getStoreCodesList.split(',').length === 1
                        ? `
                          <div class="flex q-ml-sm items-center justify-between weather-tooltip">
                            <i
                              class="wi ${
                                dataPoint.z.weatherIcon
                                  ? dataPoint.z.weatherIcon
                                  : ''
                              }"
                            ></i>
                          </div>
                          <div class="">
                            ${
                              dataPoint.z.temperature
                                ? dataPoint.z.temperature.toFixed(2) + '°C'
                                : ''
                            }
                          </div>`
                        : ''
                    }
                </div>
                </div>
                <div class="tooltip-title flex q-mx-md items-center">
                  <div
                    class="tooltip-title-color"
                    style="background:${w.config.colors[0]}"
                  ></div>
                  <label>${this.$t(
                    `Home.${w.globals.seriesNames[0]}`
                  )} :</label>
                  <div class="q-ml-xs text-bold">
                    ${series[0][dataPointIndex]}
                  </div>
                </div>
              </q-card>`;
          },
          x: {
            format: 'dd/MM/yy HH:mm'
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetY: -60
          }
        },
        grid: {
          row: {
            color: '#fff',
            opacity: 0.5
          }
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: Number,
          tickAmount: 3
        },

        xaxis: {
          tooltip: { enabled: false },
          rotate: -45,
          rotateAlways: false,
          labels: {
            formatter: val => {
              const { locale } = this.$i18n;
              if (locale === 'en-us' || locale === 'en' || locale === 'en-US') {
                return moment(val).format('MMM-DD');
              } else if (locale === 'ja') {
                return moment(val).format('MM月DD日');
              } else {
                return 'invalid date';
              }
            },
            style: {
              fontSize: '9px',
              cssClass: 'small-title'
            },
            rotate: 0,
            offsetX: -3
          }
        }
      } // chartOptions
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('prediction', ['traffic'])
  }, // computed
  async created() {
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('Home.prediction_timeline')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}`
        },
        png: {
          filename: `${this.$t('Home.prediction_timeline')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}`
        },
        csv: {
          filename: `${this.$t('Home.prediction_timeline')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}`
        }
      }
    };
    try {
      this.loading = true;
      await this.fetchTrafficPrediction({
        storeCodes: this.getStoreCodesList,
        daysOfWeek: this.getDaysOfWeekList
      });
      this.populateChart();
    } catch (error) {
     // this.$q.notify({
     //   type: 'negative',
     //   position: 'top',
    //    message: `${error}`
    //  });
    } finally {
      this.loading = false;
    }
  }, // created
  methods: {
    ...mapActions('prediction', ['fetchTrafficPrediction']),
    populateChart() {
      const current =
        (this.traffic &&
          this.traffic.last7DaysData &&
          this.traffic.last7DaysData.map(previousData => {
            return {
              x: previousData.date,
              y: parseInt(previousData.value.replace(/,/g, ''), 10),
              z: {
                temperature: previousData.temperature,
                weatherIcon: previousData.weatherIcon
              }
            };
          })) ||
        [];
      const prediction =
        (this.traffic &&
          this.traffic.predictionData &&
          this.traffic.predictionData.length &&
          this.traffic.predictionData.map(prediction => {
            return {
              x: prediction.date,
              // eslint-disable-next-line
              y: parseInt(prediction.value.replace(/,/g, ''), 10),
              z: {
                temperature: prediction.temperature,
                weatherIcon: prediction.weatherIcon
              }
            };
          })) ||
        [];
      const xaxisCategoryPrevious = current.map(singlePoint => singlePoint.x);
      const xaxisCategoryPrediction =
        prediction.map(singlePrediction => singlePrediction.x) || [];
      let multilineChart = [
        {
          name: this.$t('traffic'),
          data: [...prediction]
        }
      ];
      this.series = [...multilineChart];
      this.chartOptions.xaxis['categories'] = [...xaxisCategoryPrediction];
    },
    getFullDay(val) {
      if (val === 0) {
        return 'Sun';
      }
      if (val === 1) {
        return 'Mon';
      }
      if (val === 2) {
        return 'Tue';
      }
      if (val === 3) {
        return 'Wed';
      }
      if (val === 4) {
        return 'Thu';
      }
      if (val === 5) {
        return 'Fri';
      }
      if (val === 6) {
        return 'Sat';
      }
    }
  }
};
