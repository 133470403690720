
import { mapActions, mapGetters, mapState } from 'vuex';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [PaginationMixin],
  data() {
    return {
      searchKeyword: ['', '', '', '', '', '', '', '', ''],
      parameter: '',
      average: true,
      mode: 'weekly',
      expanded: [],
      weathers: [],
      weatherIcons: [],
      temperatures: [],
      loading: false,
      dateColumn: {
        name: 'date',
        align: 'center',
        label: this.$t('BoxQueueTable.Date'),
        field: 'tableDate',
        sortable: true,
        required: true,
        format: val => `${val}`
      },
      weatherColumn: {
        name: 'weatherCode',
        align: 'center',
        label: this.$t('BoxQueueTable.Weather'),
        field: 'weatherCode',
        headerClasses: 'entrance-col text-capitalize',
        sortable: true
      },
      rowData: [],
      queueKpis: [
        'avgusersinline',
        'avgwaitingtime',
        'numberofalerts',
        'numberofrequests',
        'requesttakenperalert',
        'timebeforetakingrequest',
        'trafficinline'
      ]
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingCompared',
      'getStartingCompared',
      'getEndingPeriod',
      'getStoreCodesList'
    ]), // mapGetters
    ...mapState('queue', [
      'posID',
      'floorID',
      'storeID',
      'tableData',
      'storeCodes'
    ]),
    ...mapState('user', ['kpiPreferences']),
    columns() {
      return this.queueKpis.map(key => {
        const kpi = this.kpiPreferences.find(k => k.metricKey === key);
        return {
          name: key,
          align: 'center',
          label: kpi?.title ?? this.$t(`KPI.${key}`),
          field: key,
          headerClasses: 'entrance-col',
          sortable: true
        };
      });
    },
    columnMode() {
      return [this.dateColumn, ...this.columns];
    },
    columnMode2() {
      return [this.dateColumn, this.weatherColumn, ...this.columns];
    }
  }, // computed
  watch: {
    tableData: async function() {
      this.loading = true;
      let dates = this.tableData ? Object.keys(this.tableData) : [];
      let tbData = this.tableData ? Object.values(this.tableData) : [];
      this.weathers = [];
      this.rowData = tbData.map((dat, key) => {
        if (dat['Weather']) {
          this.weathers = [
            ...this.weathers,
            dat['Weather'] ? dat['Weather'] : ''
          ];
          this.temperatures = [...this.temperatures, dat['Temperature']];
          this.weatherIcons = [...this.weatherIcons, dat['WeatherIcon']];
        }

        return {
          id: key,
          tableDate: `${dates[key]}`,
          numberofalerts: dat.numberofalerts,
          numberofrequests: dat.numberofrequests,
          requesttakenperalert: dat.requesttakenperalert,
          timebeforetakingrequest: dat.timebeforetakingrequest,
          trafficinline: dat.trafficinline,
          avgusersinline: dat.avgusersinline,
          avgwaitingtime: dat.avgwaitingtime
        };
      });
      this.rowData = this.rowData
        .slice()
        .sort((a, b) => new Date(a.tableDate) - new Date(b.tableDate));
    },
    average: async function() {
      this.loading = true;
    },
    async posID() {
      this.loading = true;
      await this.fetchTableData();
      this.loading = false;
    },
    searchKeyword() {
      let obj = {
        tableDate: this.searchKeyword[0],
        weatherCode: this.searchKeyword[1],
        numberofalerts: this.searchKeyword[2],
        numberofrequests: this.searchKeyword[3],
        requesttakenperalert: this.searchKeyword[4],
        timebeforetakingrequest: this.searchKeyword[5],
        trafficinline: this.searchKeyword[6],
        avgusersinline: this.searchKeyword[7],
        avgwaitingtime: this.searchKeyword[8]
      };
      let dates = Object.keys(this.tableData);
      let tbData = Object.values(this.tableData);
      this.weathers = [];
      let filtered = tbData.map((dat, key) => {
        if (dat['Weather']) {
          this.weathers = [
            ...this.weathers,
            dat['Weather'] ? dat['Weather'] : ''
          ];
          this.temperatues = [...this.temperatues, dat['Temperature']];
          this.weatherIcons = [...this.weatherIcons, dat['WeatherIcon']];
        }
        return {
          tableDate: `${dates[key]}`,
          numberofalerts: dat.numberofalerts,
          numberofrequests: dat.numberofrequests,
          requesttakenperalert: dat.requesttakenperalert,
          timebeforetakingrequest: dat.timebeforetakingrequest,
          trafficinline: dat.trafficinline,
          avgusersinline: dat.avgusersinline,
          avgwaitingtime: dat.avgwaitingtime
        };
      });
      this.rowData = filtered.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  }, //watch
  async created() {
    this.loading = true;
    await this.fetchTableData();
    this.loading = false;
  }, // created
  methods: {
    ...mapActions('queue', ['fetchQueueNotifierDate']),
    async fetchTableData() {
      try {
        this.loading = true;
        await this.fetchQueueNotifierDate({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          kpis: 'all',
          posID: this.posID,
          daysOfWeek: this.getDaysOfWeekList,
          floorID: this.floorID,
          storeCodes: this.storeID
        });
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Comparison Error: ${error}`
        });
      } finally {
        this.loading = false;
      } // finally
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('BoxQueueTable.Sun');
      }
      if (dayVal === 1) {
        return this.$t('BoxQueueTable.Mon');
      }
      if (dayVal === 2) {
        return this.$t('BoxQueueTable.Tue');
      }
      if (dayVal === 3) {
        return this.$t('BoxQueueTable.Wed');
      }
      if (dayVal === 4) {
        return this.$t('BoxQueueTable.Thu');
      }
      if (dayVal === 5) {
        return this.$t('BoxQueueTable.Fri');
      }
      if (dayVal === 6) {
        return this.$t('BoxQueueTable.Sat');
      }
    }
  } // methods
}; // export default
