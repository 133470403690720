
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      separator: 'cell',
      tableColumn: [],
      dateList: [],
      tableRows: [],
      salesList: []
    };
  },
  computed: {
    ...mapState('periodicReport', ['weeklyBudgetTableList']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('periodicReport', ['selectedStoreCode']),
    ...mapGetters('filter', ['getPulseStartingPeriod', 'getPulseEndingPeriod']),
    firstSubTableLength() {
      return this.tableColumn.length >= 18 ? 18 : this.tableColumn.length;
    },
    firstSubTable() {
      if (this.salesList.length > 0 && this.tableColumn.length > 6) {
        return true;
      }
      return this.salesList.length === 0 && this.tableColumn.length > 9;
    },
    secondSubTableLength() {
      return this.tableColumn.length >= 27 ? 27 : this.tableColumn.length;
    },
    secondSubTable() {
      if (this.salesList.length > 0 && this.tableColumn.length > 14) {
        return true;
      }
      return this.salesList.length === 0 && this.tableColumn.length > 18;
    },
    thirdSubTable() {
      if (this.salesList.length > 0 && this.tableColumn.length > 24) {
        return true;
      }
      return this.salesList.length === 0 && this.tableColumn.length > 27;
    }
  },
  async created() {
    await this.fetchWeeklyBudgetTable({
      endDate: this.getPulseEndingPeriod,
      kpis: this.selectedKPIOptions.join(','),
      startDate: this.getPulseStartingPeriod,
      storeCodes: this.selectedStoreCode
    });
    if (this.weeklyBudgetTableList) {
      const dateList = Object.keys(this.weeklyBudgetTableList);
      const weatherList = dateList.map(
        obj => this.weeklyBudgetTableList[obj].weather
      );
      const salesList = dateList
        .map(obj => this.weeklyBudgetTableList[obj].sales)
        .filter(Boolean);
      const budgetData = dateList.map(obj => {
        const singleData = { ...this.weeklyBudgetTableList[obj] };
        delete singleData.weather;
        delete singleData.sales;
        return {
          ...singleData
        };
      });
      const tableColumn = Object.keys(budgetData[0]);

      this.dateList = dateList;
      this.salesList = salesList;
      this.weatherList = weatherList;
      this.tableColumn = tableColumn;
      this.tableRows = budgetData;
    }
  },
  methods: {
    ...mapActions('periodicReport', ['fetchWeeklyBudgetTable'])
  }
};
