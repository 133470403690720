import { checkPermission } from '@/api/utils';
import store from '../store/index';

export default [
  {
    path: '/management/store',
    name: 'management:store',
    component: () => import('@/pages/Management/Store/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store
  {
    path: '/management/store/view/:id',
    name: 'management:store:view',
    component: () => import('@/pages/Management/Store/Edit.vue'),
    meta: {
      accessGiven: true,
      isEdit: false
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:view
  {
    path: '/management/store/add',
    name: 'management:store:add',
    component: () => import('@/pages/Management/Store/Add.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:add
  {
    path: '/management/store/edit/:id',
    name: 'management:store:edit',
    component: () => import('@/pages/Management/Store/Edit.vue'),
    meta: {
      accessGiven: true,
      isEdit: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementEdit');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:edit
  {
    path: '/management/store/import',
    name: 'management:store:import',
    component: () => import('@/pages/Management/Store/Import.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:import
  {
    path: '/management/store/import/result/:id',
    name: 'management:store:import:result',
    component: () => import('@/pages/Management/Store/UploadResult.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:import:result
  {
    path: '/management/store/upload-floor-plan',
    name: 'management:store:upload-floor-plan',
    component: () => import('@/pages/Management/Store/UploadFloorPlan.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/sensor');
      }
    }
  }, // management:store:upload-floor-plan
  {
    path: '/management/user',
    name: 'management:user',
    component: () => import('@/pages/Management/User/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user
  {
    path: '/management/role',
    name: 'management:user:role',
    component: () => import('@/pages/Management/User/Role.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowRoleMangementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/invite');
      }
    }
  }, // management:user:role
  {
    path: '/management/user/invite',
    name: 'management:user:invite',
    component: () => import('@/pages/Management/User/Invite.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user:invite
  {
    path: '/management/user/edit/:id',
    name: 'management:user:edit',
    component: () => import('@/pages/Management/User/Edit.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementEdit');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user:view
  {
    path: '/management/user/view/:id',
    name: 'management:user:view',
    component: () => import('@/pages/Management/User/View.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user:edit
  {
    path: '/management/user/import',
    name: 'management:user:import',
    component: () => import('@/pages/Management/User/Import.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user:import
  {
    path: '/management/user/import/result/:id',
    name: 'management:user:import:result',
    component: () => import('@/pages/Management/User/UploadResult.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowUserManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/user/role');
      }
    }
  }, // management:user:import:result
  {
    path: '/management/user/role/add',
    name: 'management:user:role:add',
    component: () => import('@/pages/Management/User/AddRole.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowRoleMangementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/management/brand');
      }
    }
  }, // management:user:role:add
  {
    path: '/management/brand',
    name: 'management:brand',
    component: () => import('@/pages/Management/Brand/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowBrandManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/management/plan');
      }
    }
  }, // management:brand
  {
    path: '/management/knowledge',
    name: 'management:knowledge',
    component: () => import('@/pages/Management/Knowledge/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = store.state['user']?.userPermissions?.Knowledge?.VIEW;

      if (viewAllowed) {
        next();
      } else {
        next('/management/plan');
      }
    }
  }, // management:knowledge
  {
    path: '/management/knowledge/detail',
    name: 'management:knowledge:detail',
    component: () => import('@/pages/Management/Knowledge/Detail.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = store.state['user']?.userPermissions?.Knowledge?.VIEW;

      if (viewAllowed) {
        next();
      } else {
        next('/management/plan');
      }
    }
  }, // management:knowledge:detail
  {
    path: '/management/product',
    name: 'management:product',
    component: () => import('@/pages/Management/Product/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.ProductManagement?.VIEW;

      if (viewAllowed) {
        next();
      } else {
        next('/management/customer');
      }
    }
  }, // management:product
  {
    path: '/management/product/add',
    name: 'management:product:add',
    component: () => import('@/pages/Management/Product/Upsert.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.ProductManagement?.CREATE;

      if (viewAllowed) {
        next();
      } else {
        next('/management/customer');
      }
    }
  }, // management:product:add
  {
    path: '/management/product/:id',
    name: 'management:product:view',
    component: () => import('@/pages/Management/Product/View.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.ProductManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/management/customer');
      }
    }
  }, // management:product:edit
  {
    path: '/management/product/:id/edit',
    name: 'management:product:edit',
    component: () => import('@/pages/Management/Product/Upsert.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.ProductManagement?.UPDATE;

      if (viewAllowed) {
        next();
      } else {
        next('/management/customer');
      }
    }
  } // management:product:edit
];
