
import { BillingModel } from '@/models/Plan';

export default {
  props: {
    value: { default: BillingModel.MONTHLY, type: Number }
  },
  data() {
    return {
      BillingModel: BillingModel
    };
  }
};
