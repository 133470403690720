import { GetRequest, PostRequest } from '@/util/http';
import {convertApiResponseForComparisonCharts,convertApiResponseForComparisonCharts2,
  GetReportingSumDataByLocationFidaCompDetailV2,
  GetReportingSumDataFidaV2
} from '@/api/fida';
import DashboardManager from '@/api/apiDataManagers/dashboardManager.js';


export async function APIFetchAllComparisions(params: any) {
  return DashboardManager.getComparisionByLocation(params, ["regions", "districts", "stores", "peerGroups", "areaManagers"])
} // APIFetchRegions

async function fetchSpecificComparision(params, type) {
  let retVal = await DashboardManager.getComparisionByLocation(params, [type])
  retVal.data = retVal.data[type]
  return retVal
}

export async function APIFetchRegions(params: any) {
  return fetchSpecificComparision(params, "regions")
} // APIFetchRegions

export function APIFetchDistricts(params: any) {
  return fetchSpecificComparision(params, "districts")
} // APIFetchDistricts

export async function APIFetchStores(params: any) {
  return fetchSpecificComparision(params, "stores")
}

export function APIFetchGroups(params: any) {
  return fetchSpecificComparision(params, "peerGroups")
} // APIFetchGroups

export function APIFetchSVs(params: any) {
  return fetchSpecificComparision(params, "areaManagers")
} // APIFetchSVs

export async function APIFetchKPIAnalysisPulse(params, type) {
  if(params.startDate != null && params.startDate != undefined)
    {
      let days = params.daysOfWeek || [];
      if(typeof days == "string") {
        days = days.split(",")
      }
 var response2 = await GetReportingSumDataFidaV2({
    startDate: params.startDate,
    endDate: params.endDate,
    "storeCodes": params.storeCodes.split(','),
    "kpiTypes": params.selectedKPI.split(','),
    "frequency": type,
    compareStartDate: params.compareStartDate,
    compareEndDate: params.compareEndDate,
    days
  });

  response2.kpis = calculateHourlySums(response2.kpis);
  
  response2.kpisCompare = calculateHourlySums(response2.kpisCompare);

  var result;
  if(params.daysOfWeek == "monday,tuesday,wednesday,thursday,friday")
    result = generateResponse(response2.kpis.weekday, response2.kpisCompare.weekday);
  else
    result = generateResponse(response2.kpis.weekend, response2.kpisCompare.weekend);

  }
  else
  {
    result = null;
  }
return result;
}

const calculateHourlySums = (data) => {
  const hourlySums = {
    weekday: { sls: {}, trf: {} },
    weekend: { sls: {}, trf: {} }
  };

  data.forEach(entry => {
    const id = entry._id.split('_');
    const date = formatDate(id[0]);
    const hour = id[1];
    const dayOfWeek = new Date(date).getDay();

    // Sum of sls
    const slsValue = entry.sls ? Number(entry.sls) : 0;
    const slsSum = (hourlySums[dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'].sls[hour] || 0) + slsValue;
    hourlySums[dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'].sls[hour] = slsSum;

    // Sum of trf
    entry?.trf && Object.keys(entry.trf).forEach(key => {
      const trfValue = entry.trf[key].trf ? Number(entry.trf[key].trf) : 0;
      const trfSum = (hourlySums[dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'].trf[hour] || 0) + trfValue;
      hourlySums[dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'].trf[hour] = trfSum;
    });
  });

  return hourlySums;
};

// const calculateHourlySums = (data) => {
//   const hourlySums = {
//     weekday: { sls: {}, trf: {} },
//     weekend: { sls: {}, trf: {} }
// };

//   data.forEach(entry => {
//     const id = entry._id.split('_');
//       const date = formatDate(id[0]);
//       const hour = id[1];
//       const dayOfWeek = new Date(date).getDay();
      
//       const slsSum = (hourlySums[dayOfWeek >= 1 && dayOfWeek <= 5 ? 'weekday' : 'weekend'].sls[hour] || 0) + entry.sls?Number(entry.sls):0;
//       hourlySums[dayOfWeek >= 1 && dayOfWeek <= 5 ? 'weekday' : 'weekend'].sls[hour] = slsSum;

//       // Sum of trf
//       Object.keys(entry.trf).forEach(key => {
//           const trfSum = (hourlySums[dayOfWeek >= 1 && dayOfWeek <= 5 ? 'weekday' : 'weekend'].trf[hour] || 0) + entry.trf[key].trf?Number(entry.trf[key].trf):0;
//           hourlySums[dayOfWeek >= 1 && dayOfWeek <= 5 ? 'weekday' : 'weekend'].trf[hour] = trfSum;
//       });
//     });
//   return hourlySums;
// };
const formatDate = (dateStr) =>  {
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6); // Months are 0-indexed
  const day = dateStr.slice(6, 8);
  //const date = new Date(year, month, day);
  return year+"-"+month+"-"+day;
};

const generateHourlyData = (hourlySums,metricKey) => {
  const hourlyData = [];
  for (const hour in hourlySums) {
    var sales = 0;
    var traffic = 0;
    var formattedSum =  '0 ';
    if(metricKey == 'sls')
    {
      sales = hourlySums[hour];
      traffic = 0;
      formattedSum =  `¥${hourlySums[hour].toLocaleString()} `;
    }
    else
    {
      traffic = hourlySums[hour];
      sales = 0;

      formattedSum =  `${hourlySums[hour]} `;
    }
      hourlyData.push({
          temperature: 0.0,
          minTemp: 0.0,
          maxTemp: 0.0,
          weather: "",
          weatherIcon: "",
          formattedMetricValue: formattedSum,
          metricValue: hourlySums[hour],
          timelineLabel: `${hour}:00`,
          traffic: `${traffic} `,
          sales: `¥${sales.toLocaleString()} `,
          conversion: "0.00 %"
      });
  }
  return hourlyData;
};

const generateResponse = (selectedPeriod, comparedPeriod) => {
  return {
      selectedKPITrend: {
          metricKey: "Sales",
          metricID: "sls",
          selectedPeriod: generateHourlyData(selectedPeriod.sls,'sls'),
          comparedPeriod: generateHourlyData(comparedPeriod.sls,'sls')
      },
      comparedKPITrend: {
          metricKey: "Traffic",
          metricID: "trf",
          selectedPeriod: generateHourlyData(selectedPeriod.trf,'trf'),
          comparedPeriod: generateHourlyData(comparedPeriod.trf,'trf')
      }
  };
};

export async function APIFetchKPIAnalysis(params, type) {
    var response2;
    if(params.type){
      response2 = await GetReportingSumDataByLocationFidaCompDetailV2({
        startDate: params.startDate,
        endDate: params.endDate,
        "storeCodes": params.storeCodes.split(','),
        "kpiTypes": params.selectedKPI,
        "frequency": type
      });
    }
    else
    {
      response2 = await GetReportingSumDataByLocationFidaCompDetailV2({
        startDate: params.startDate,
        endDate: params.endDate,
        "storeCodes": params.storeCodes.split(','),
        "kpiTypes": params.selectedKPI,
        "frequency": type,
        compareStartDate: params.compareStartDate,
        compareEndDate: params.compareEndDate
      });
    }
    const convertedResponse = convertApiResponseForComparisonCharts(response2,params.selectedKPI.split(',')[0],params.startDate,params.endDate,type);

    return convertedResponse;
}

export function APIFetchCompareDates(params: any) {
  // return PostRequest('customCalendar', params);
  return {"data":null,"description":"Custom Calendar not found!!","status":"ERROR"}
}

export function APIFetchMarketingCampaign(params: any) {
  return PostRequest('marketingCampaigns', params);
}

export function APIFetchKPI(params: any) {
  return PostRequest('kpiMetrics', params);
}

export async function APIFetchKPIAnalysis2(params, type) {
    var response2;

      response2 = await GetReportingSumDataByLocationFidaCompDetailV2({
        startDate: params.startDate,
        endDate: params.endDate,
        "storeCodes": params.storeCodes.split(','),
        "kpiTypes": params.selectedKPI,
        "frequency": type,
        "days": params.daysOfWeek.split(','),
        compareStartDate: params.compareStartDate,
        compareEndDate: params.compareEndDate,
        reportType: 'kpianalysis'
      });
      var response3;

      response3 = await GetReportingSumDataByLocationFidaCompDetailV2({
        startDate: params.startDate,
        endDate: params.endDate,
        "storeCodes": params.storeCodes.split(','),
        "kpiTypes": params.compareKPI,
        "frequency": type,
        "days": params.daysOfWeek.split(','),
        compareStartDate: params.compareStartDate,
        compareEndDate: params.compareEndDate,
        reportType: 'kpianalysis'
      });
      
      function sortData(array) {
        return array?.sort((a, b) => {
            const [numA, yearA] = a._id.split('_').map(Number);
            const [numB, yearB] = b._id.split('_').map(Number);
            
            // First compare by year, then by the numeric part
            if (yearA !== yearB) {
                return yearA - yearB;
            }
            return numA - numB;
        });
    }
    if(type === 'weekly' || type === 'monthly'){
      response3.kpisCompare = sortData(response3?.kpisCompare)
    }
    const convertedResponse = convertApiResponseForComparisonCharts2(response2,response3,params.selectedKPI,params.compareKPI,params.startDate,params.endDate,type);
    return convertedResponse;
  // else
  // {
  //   return PostRequest('kpi/kpiComparison/' + type, params);
  // }
}

// export function APIFetchKPIPreferences(params: any) {
//   return PostRequest('getKPIPreferences', params);
// }