
import Vue from 'vue';
import flatMap from 'lodash/flatMap';
import { colors, kpis } from '@/util';
import { APIFetchStoreDistribution } from '@/api/store';
import { formatYAxis } from '@/util/legend';
import PaginationMixin from '@/mixins/PaginationMixin';
import { mapActions, mapState } from 'vuex';
import { convertApiResponseAllStores } from '@/helper/helperFunction';
import {
  GetReportingSumDataByLocationFidaV2
} from '@/api/fida';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import { getKpiTitle } from '@/util/utilFidaFunctions';
import store from '@/store';
//getKpiTitle
export default Vue.extend({
  mixins: [PaginationMixin],
  props: {
    stores: {
      type: Array,
      required: true
    },
    kpis: {
      type: Array,
      required: true
    },
    period: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      data: [],
      columns: [],
      series: [],
      chartOptions: {
        chart: {
          height: 500,
          type: 'radar'
        },
        fill: {
          opacity: 0
        },

        title: {
          // text: 'Basic Radar Chart'
        },
        colors: colors.secondary,
        xaxis: {
          categories: []
        },
        // yaxis: { logarithmic: true },
        yaxis: {
          tickAmount: 5,
          labels: {
            offsetX: 20,
            formatter: val => {
              return formatYAxis(val) + '%';
            }
          }
        },
        legend: {
          show: false
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: true
          }
        }
      },
      loading: false,
      labels: [],
      colors: []
    };
  },
  computed: {
    ...mapState('user', ['kpiPreferences']),
    orderedKPI() {
      return this.kpis.map(kpi => kpi);
    }
  },

  async created() {
    this.loading = true;
    await this.loadKpiMetrics();
    this.loading = false;
  },
  methods: {
    ...mapActions('store', ['fetchStores']),
    async loadKpiMetrics() {
      let values = [];
      let storeList;
      let _columns = [];
      let _kpiIndex = null;
      let chartCategories = [];
      let chartSeries = [];
      let data=[];
 
        if(this.kpis == "")
        return;

      const res1 = await GetReportingSumDataByLocationFidaV2({
        startDate: this.period.start,
        endDate: this.period.end,
        "storeCodes": this.stores.map(store => store.storeCode),
        "kpiTypes": this.kpis,
        "frequency": "all",
        "page": "comparison"
      });

      // we need it by store api calling
      const res22 = {data:
        {startDate:this.period.start,endDate:this.period.end,kpis:res1.kpis}};
      data  = convertApiResponseComparisonStoreByStore(res22.data).data;

    let res2 = await this.fetchStores();

    let res3 = convertApiResponseAllStores(res2.stores? res2.stores: res2.data.stores);
      storeList = flatMap(res3);
      _columns.push({
        name: 'kpi',
        required: true,
        label: 'KPI',
        align: 'left',
        field: 'label'
      });

      if (data != null) {
        Object.entries(data.kpis).map(store => {
          let _storeIndex = (_storeIndex = storeList.findIndex(e => {
            return e.storeCode == store[0];
          }));
if(_storeIndex != -1){
          _columns.push({
            name: storeList[_storeIndex]?.storeName,
            label: storeList[_storeIndex]?.storeName,
            field: store[0]
          });

          store[1].sort((x, y) => {
            return (
              this.orderedKPI.indexOf(x.metricID) -
              this.orderedKPI.indexOf(y.metricID)
            );
          });

          store[1].forEach(kpi => {
            _kpiIndex = values.findIndex(e => {
              return e.name === kpi.metricID.toLowerCase();
            });

            if (_kpiIndex == -1) {
              chartCategories.push(
                this.kpiPreferences.find(k => k.metricKey === kpi.metricID)
                  .title
              );

              values.push({
                name: kpi.metricID.toLowerCase(),
                label: this.kpiPreferences.find(
                  k => k.metricKey === kpi.metricID
                ).title,
                [store[0]]: kpi.value
              });
            } else {
              values[_kpiIndex] = {
                ...values[_kpiIndex],
                [store[0]]: kpi.value
              };
            }
          });

          let p = store[1].map(kpi => {
            return kpi.value.replace(/[^\d.-]/g, '');
          });

          chartSeries.push({
            name: storeList[_storeIndex].storeName,
            rawData: p
          });
        }
        });

        this.columns = _columns;
        this.data = values;

        this.$refs.chart.updateOptions({
          xaxis: {
            categories: chartCategories
          }
        });
        
        const maxIndex = findIndexWithLeastZeros(chartSeries);

        let beingComparedTo = chartSeries[maxIndex];

        for (let i = 0; i < chartSeries.length; i++) {
          let _data = [];
          for (let j = 0; j < chartSeries[i].rawData.length; j++) {
          if(beingComparedTo.rawData[j] == 0)
          {
            chartSeries[i].rawData[j] = 0;
            _data.push('0');
          }
          else
            _data.push(
              (
                (chartSeries[i].rawData[j] / beingComparedTo.rawData[j]) *
                100
              ).toFixed()
            );
          }

          chartSeries[i].data = _data;
        }
        this.$refs.chart.updateSeries(chartSeries);

        this.$refs.chart.toggleDataPointSelection(0, 0);
        this.$refs.chart.toggleDataPointSelection(1, 0);
        this.$refs.chart.toggleDataPointSelection(2, 0);
      }
    },
    rowClick(evt, row, index) {
      this.$emit('rowClick', {
        kpi: row.label
      });
    }
  }
});
function findIndexWithLeastZeros(data) {
    let minZerosIndex = 0;
    let minZerosCount = Infinity;

    for (let i = 0; i < data.length; i++) {
        let zerosCount = data[i].rawData.filter(value => value === "0").length;
        if (zerosCount < minZerosCount) {
            minZerosCount = zerosCount;
            minZerosIndex = i;
        }
    }

    return minZerosIndex;
}
function convertApiResponseComparisonStoreByStore(res)
{
const convertedData = {
  "data": {
    "startDate": res.startDate,
    "endDate": res.endDate,
    "kpis": {}
  }
};
res.kpis.forEach(kpi => {

  const storeCode = kpi._id;
  const convertedKpi = kpiToConvertedFormat(kpi);

  if (!convertedData.data.kpis[storeCode]) {
    convertedData.data.kpis[storeCode] = [];
  }

  convertedData.data.kpis[storeCode].push(...convertedKpi);
});

  return convertedData;
}

function formatDewellTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

const lang = store.getters['user/getLocale'];
  if(lang.toLowerCase() == 'en' || lang.toLowerCase() == 'en-us')
    return `${minutes}min : ${remainingSeconds}s`;
  else
    return `${minutes}分  : ${remainingSeconds}秒`;
}
function removeTrailingZeros(value) {
    // Convert the value to a string if it's a number
    let stringValue = (typeof value === 'number') ? value.toString() : value;

    // Check if the string ends with '.00'
    if (stringValue.endsWith('.00')) {
        // Remove the trailing '.00'
        stringValue = stringValue.slice(0, -3);
    }

    // Convert the value back to a number if it was originally a number
    return (typeof value === 'number') ? parseFloat(stringValue) : stringValue;
}
function kpiToConvertedFormat(kpi) {

  const convertedKpis = [];
  for (const key in kpi) {
    if (key !== "_id") {
      // if(key == 'dw')//variation
      // {}
      var value = kpi[key]?kpi[key]:0;
      var variation = (typeof value === 'number' ? `${formatNumber(value)}` : value);
      // if(key == 'dw')
      // {
      //   variation = '0';
      // }
      const titleObj = getKpiTitle(key);
      const convertedKpi = {
        "metricKey": titleObj,//key.charAt(0).toUpperCase() + key.slice(1),
        "metricKeyShort": titleObj,
        "value": getSignsLeftSide(key)+(typeof value === 'number' ? `${formatNumber(value)}` : value)+getSignsRightSide(key)+'',
        "comparedValue": getSignsLeftSide(key)+"0"+getSignsRightSide(key)+'',
        "variation": variation,
        "variationPercentage": "0.00%",
        "growthIndicator": 1,
        "variance": "0",
        "metricID": key
      };
      convertedKpis.push(convertedKpi);
    }
  }
  return convertedKpis;
}

function formatNumber(number) {
  return number.toLocaleString();
}


