import { APIImportLogs } from '@/api/importLogs';

const initialState = {
  importLogData: []
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setImportLogs(state, { importLogs }) {
      state.importLogData = importLogs;
    } // setStores
  }, // mutations
  actions: {
    async getImportLogs({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res: any = await APIImportLogs();
          commit('setImportLogs', { importLogs: res.data });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    } // fetchStores
  } // actions
}; // export default
