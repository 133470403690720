
import Vue from 'vue';
import { QInnerLoading, QSpinnerTail } from 'quasar';

export default Vue.extend({
  props: {
    /**
     * Control if the loading is in action or not
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Size in CSS units, including unit name or standard size name (xs|sm|md|lg|xl)
     */
    size: {
      type: String,
      default: 'xl'
    }
  }, // props
  render: function(h) {
    return h(
      QInnerLoading,
      {
        props: {
          showing: this.loading
        } as QInnerLoading
      },
      [
        h(QSpinnerTail, {
          props: {
            size: this.size
          } as QSpinnerTail
        })
      ]
    );
  } // render
});
