
export default {
  props: {
    value: { type: [String, Number, Symbol], required: true },
    name: { type: [String, Number, Symbol], required: true },
    label: { type: String, required: true }
  },
  methods: {
    activeColor(value: string) {
      return this.value === value ? 'secondary' : 'white';
    },
    activeTextColor(value: string) {
      return this.value === value ? 'white' : 'secondary';
    }
  }
};
