
import Image from '@/models/Image';

export default {
  props: {
    uploadHandler: { type: Function, required: true },
    value: { type: Array, default: () => [] },
    accept: { type: String, default: 'image/*' },
    multipleSelection: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    confirmBeforeDeleting: { type: Boolean, default: false },
    formatter: { type: Function, default: null },
    fetcher: { type: Function, default: null }
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    isLimitReached() {
      return !this.multipleSelection && this.value && this.value.length > 0;
    }
  },
  methods: {
    async onInput(event: Event) {
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.$emit('uploading');
      const files = (event.target as HTMLInputElement).files;
      const uploaded: Image[] = [];
      for (const file of files) {
        uploaded.push({
          path: await this.uploadHandler(file),
          filename: file.name
        });
      }
      this.$emit('input', [...this.value, ...uploaded]);
      this.$emit('uploaded');
      this.processing = false;
    },
    removeImage(index) {
      this.$emit(
        'input',
        this.value.filter((_, i) => i !== index)
      );
    },
    handleDownload(image) {
      this.$emit('download', image);
    }
  }
};
