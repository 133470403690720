import axios from 'axios';
import { HAPI_KEY } from '@/components/LeadCalculator/config';

export const APIAddContact = properties => {
  return axios.post(
    'https://api.hsforms.com/submissions/v3/integration/submit/1811367/2607aac1-b4be-422a-9683-b587fd6543a3?hapikey=603184bb-ccf3-4f2e-90ee-48a04afd1e5d',
    properties,
    {
      headers: {
        Accept: 'application/json',
        Authorization: '',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const APIAddCompany = properties => {
  return axios.post(
    'https://api.hsforms.com/submissions/v3/integration/submit/1811367/993d217c-a76c-489c-9a2a-6b64e9bace31?hapikey=603184bb-ccf3-4f2e-90ee-48a04afd1e5d',
    properties,
    {
      headers: {
        Accept: 'application/json',
        Authorization: '',
        'Content-Type': 'application/json'
      }
    }
  );
};
