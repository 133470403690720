import clientManager from '@/util/client';
import account, { AccountControllers } from './account';
import core, { CoreControllers } from './core';
import location, { LocationControllers } from './location';
import management, { ManagementControllers } from './management';
import onboarding, { OnboardingControllers } from './onboarding';
import settings, { SettingsControllers } from './settings';
import tools, { ToolsControllers } from './tools';
import transactional, { TransactionalControllers } from './transactional';

export type CoreService = CoreControllers;
export type LocationService = LocationControllers;
export type ManagementService = ManagementControllers;
export type ToolsService = ToolsControllers;
export type SettingsService = SettingsControllers;
export type AccountService = AccountControllers;
export type OnboardingService = OnboardingControllers;
export type TransactionalService = TransactionalControllers;

export interface Services {
  core: CoreControllers;
  tax: ManagementService;
  location: LocationService;
  management: ManagementService;
  tools: ToolsService;
  settings: SettingsService;
  account: AccountService;
  onboarding: OnboardingService;
  transactional: TransactionalService;
}

export default {
  core: core(clientManager),
  tax: management(clientManager),
  location: location(clientManager),
  management: management(clientManager),
  tools: tools(clientManager),
  settings: settings(clientManager),
  account: account(clientManager),
  onboarding: onboarding(clientManager),
  transactional: transactional(clientManager)
} as Services;
