
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    rootStyles() {
      return this.reactiveStyles(this.$style.root);
    },
    overlayStyles() {
      return this.reactiveStyles(this.$style.overlay);
    },
    containerStyles() {
      return this.reactiveStyles(this.$style.container);
    }
  },
  watch: {
    value(val) {
      this.maybeDisableScroll(val);
    }
  },
  mounted() {
    this.maybeDisableScroll(this.value);
  },
  methods: {
    reactiveStyles(base) {
      const { $style, value } = this;
      return {
        [base]: true,
        [$style.shown]: value,
        [$style.hidden]: !value
      };
    },
    closeDrawer() {
      this.$emit('input', false);
    },
    maybeDisableScroll(val) {
      if (val) {
        document.body.style = 'overflow: hidden';
      } else {
        document.body.style = '';
      }
    }
  }
};
