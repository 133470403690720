import Sensor from '@/models/Sensor';
import SensorController from '@/services/management/SensorController';
import { convertFromSensorDTO } from '@/models/formatters/SensorFormatter';
import { Pagination, PaginationResponse } from '@/util/pagination';
import map from 'lodash/map';

export interface GetSensorsResult {
  sensors: Sensor[];
  pagination: PaginationResponse;
}

export default class SensorRepository {
  private sensorController: SensorController;

  constructor(sensorController: SensorController) {
    this.sensorController = sensorController;
  }

  public async getSensors(
    pagination: Pagination = new Pagination()
  ): Promise<GetSensorsResult> {
    const { content, ...meta } = await this.sensorController.getSensors(
      pagination.toRequest()
    );
    return {
      sensors: map(content, dto => convertFromSensorDTO(dto)),
      pagination: meta
    } as GetSensorsResult;
  }

  public async getSensorImageAsBase64(sensor: Sensor) {
    const content = await this.sensorController.getSensorImageAsBase64(
      sensor.id
    );
    return content;
  }
}
