import map from 'lodash/map';
import range from 'lodash/range';

export function zeropad(suffix: string | number, length = 2): string {
  const temp = map(range(length), () => '0').join('') + suffix;
  return temp.substring(temp.length - length);
}

export function splitPhoneNumber(value: string) {
  const separator = ' ';
  let idx = value.indexOf(separator);
  let callingCode = value.substring(0, idx);
  let phoneNumber = value.substring(idx + 1);

  return {
    callingCode,
    phoneNumber
  };
}
