
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI } from '@/util/error';

export default {
  name: 'ConfirmDeleteDialog',
  mixins: [CommonMixin],
  props: {
    name: { type: String, default: '' },
    show: { type: Boolean, default: true },
    reversible: { type: Boolean, default: false },
    deleteButtonLabel: { type: String, default: 'Delete' },
    onDelete: { type: Function, default: null }
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    async onDeleteButtonClick() {
      this.processing = true;
      try {
        if (this.onDelete != null) {
          this.$emit('deleting');
          await this.onDelete();
          this.$emit('deleted');
        }
        this.$emit('close');
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.processing = false;
      }
    },
    closeDialog() {
      if (this.processing) return;
      this.$emit('close');
    }
  }
};
