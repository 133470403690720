import { isNil, isObject } from 'lodash';
import { getTime, startOfDay, endOfDay } from 'date-fns/esm/index';

export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface FieldFilters {
  [field: string]: any;
}

export interface SortField {
  field: string;
  direction: SORT_DIRECTION;
}

export interface PaginationRequest {
  page: number;
  size: number;
  sort?: string;
  query?: string;
}

export interface PaginationResponse {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface QuasarPagination {
  sortBy?: string;
  descending?: boolean;
  page: number;
  rowsPerPage?: number;
  rowsNumber?: number;
}

export class Pagination {
  page: number;
  size: number;
  totalPage?: number;
  totalItemCount?: number;
  sort?: SortField;
  query?: FieldFilters;

  constructor(page = 0, size = 1000, sort = null, query = {}) {
    this.page = page;
    this.size = size;
    this.sort = sort;
    this.query = query;
  }

  addQuery(field: string, value: string | number) {
    if (this.query == null) {
      this.query = {} as FieldFilters;
    }
    this.removeQuery(field);
    this.query[field] = value;
  }

  removeQuery(field: string) {
    if (this.query != null && this.query.length > 0) {
      delete this.query[field];
    }
  }

  setFilter(filter: FieldFilters) {
    this.query = filter;
  }

  toRequest(): PaginationRequest {
    let sort = {};
    if (this.sort != null) {
      sort = {
        sort: `${this.sort.field},${this.sort.direction}`
      };
    }

    let query = {};
    if (this.query != null && Object.keys(this.query).length > 0) {
      const queryItems = Object.keys(this.query)
        .map(key => {
          if (!isNil(this.query[key])) {
            let value = this.query[key];
            if (isObject(this.query[key])) {
              value = this.buildDateRangeQuery(this.query[key], key);
            } else {
              value = `${key}:${value}`;
            }

            return value;
          }
        })
        .filter(query => query != null);
      query = {
        query: queryItems.join(',')
      };
    }

    return {
      page: this.page,
      size: this.size,
      ...sort,
      ...query
    };
  }

  buildDateRangeQuery(value: any, key: string) {
    const result = [];

    if (!isNil(value.from)) {
      result.push(`${key}>${getTime(startOfDay(new Date(value.from)))}`);
    }

    if (!isNil(value.to)) {
      result.push(`${key}<${getTime(endOfDay(new Date(value.to)))}`);
    }

    return result;
  }

  updateByResponse(response: PaginationResponse) {
    this.size = response.size;
    this.page = response.number;
    this.totalItemCount = response.totalElements;
    this.totalPage = response.totalPages;
  }

  updateByPaginationChange(change: QuasarPagination) {
    this.page = change.page - 1;
    this.size = change.rowsPerPage;
    if (change.sortBy == null) {
      this.sort = null;
    } else {
      this.sort = {
        field: change.sortBy,
        direction: change.descending ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
      };
    }
  }

  toQuasarPagination(): QuasarPagination {
    let sort = {};
    if (this.sort != null) {
      sort = {
        sortBy: this.sort.field,
        descending: this.sort.direction === SORT_DIRECTION.DESC
      };
    }
    let rowsNumber = {};
    if (this.totalItemCount != null) {
      rowsNumber = {
        rowsNumber: this.totalItemCount
      };
    }

    return {
      page: this.page + 1,
      rowsPerPage: this.size,
      ...sort,
      ...rowsNumber
    };
  }
}
