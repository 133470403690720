
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      selected: {},
      recommended: [],
      integrations: [],
      detailSelected: {}
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    ...mapState('marketplace', [
      'packages',
      'featuredPackages',
      'pricing',
      'selectedTag'
    ])
  },
  watch: {
    pricing() {
      if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() === 'free'
      ) {
        this.integrations = this.thirdParty.filter(v => {
          return v.rate.toLowerCase() === 'free';
        });
      } else if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() !== 'free'
      ) {
        In;
        this.integrations = this.thirdParty.filter(
          v => v.rate.toLowerCase() !== 'free'
        );
      } else {
        this.integrations = this.thirdParty;
      }
    },
    selectedTag() {
      this.integrations = this.thirdParty.filter(v =>
        [v.label.toLowerCase(), ...v.tags].includes(this.selectedTag)
      );
    }
  },
  created() {
    this.fetchListOfPackages();
    this.fetchFeaturedListOfPackages();
    this.integrations = this.packages;
    this.recommended = this.featuredPackages;
  },
  methods: {
    ...mapActions('marketplace', [
      'fetchListOfPackages',
      'fetchFeaturedListOfPackages'
    ]),
    openDialog(int) {
      this.selected = int;
      this.show = true;
    },
    getImgUrl(pic) {
      return require('../../../assets/img/icons/' + pic + '.png');
    },
    showDialog(int) {
      this.detailSelected = int;
      this.show = true;
    },
    closeModal() {
      this.show = false;
    }
  }
};
