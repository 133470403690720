import BillingController from '@/services/account/BillingController';
import TenantController from '@/services/account/TenantController';
import { Pagination } from '@/util/pagination';
import BillingRecord from '@/models/BillingRecord';

export default class BillingRepository {
  private billingController: BillingController;
  private tenantController: TenantController;

  constructor(
    billingController: BillingController,
    tenantController: TenantController
  ) {
    this.billingController = billingController;
    this.tenantController = tenantController;
  }

  public async getCurrentBilling(
    tenantName: string,
    pagination: Pagination = new Pagination()
  ) {
    const tenant = await this.tenantController.getTenant(tenantName);
    const { content, ...meta } = await this.billingController.getCurrentBilling(
      tenant.id,
      pagination.toRequest()
    );
    return {
      data: content.map(dto => BillingRecord.fromDTO(dto)),
      pagination: meta
    };
  }

  public async getBillingHistory(
    tenantName: string,
    pagination: Pagination = new Pagination()
  ) {
    const tenant = await this.tenantController.getTenant(tenantName);
    const { content, ...meta } = await this.billingController.getBillingHistory(
      tenant.id,
      pagination.toRequest()
    );
    return {
      data: content.map(dto => BillingRecord.fromDTO(dto)),
      pagination: meta
    };
  }

  public async getBillingRecordTypes() {
    return await this.billingController.getBillingRecordTypes();
  }

  public async downloadInvoice(billingId: string) {
    return await this.billingController.downloadInvoice(billingId);
  }
}
