export default {
  data() {
    return {
      months: [
        { label: this.$t('FilterBar.January'), value: 'JANUARY' },
        { label: this.$t('FilterBar.February'), value: 'FEBRUARY' },
        { label: this.$t('FilterBar.March'), value: 'MARCH' },
        { label: this.$t('FilterBar.April'), value: 'APRIL' },
        { label: this.$t('FilterBar.May'), value: 'MAY' },
        { label: this.$t('FilterBar.June'), value: 'JUNE' },
        { label: this.$t('FilterBar.July'), value: 'JULY' },
        { label: this.$t('FilterBar.August'), value: 'AUGUST' },
        { label: this.$t('FilterBar.September'), value: 'SEPTEMBER' },
        { label: this.$t('FilterBar.November'), value: 'NOVEMBER' },
        { label: this.$t('FilterBar.December'), value: 'DECEMBER' }
      ],
	  locationFilterOptions: [
	   { label: this.$t('FilterBar.ENABLED'), value: true},
	    { label: this.$t('FilterBar.DISABLED'), value: false },
	  ],
      comparisonPeriods: [
        { label: this.$t('FilterBar.today'), value: 'TODAY' },
        { label: this.$t('FilterBar.yesterday'), value: 'YESTERDAY' },
        { label: this.$t('FilterBar.this_week'), value: 'THIS_WEEK' },
        { label: this.$t('FilterBar.last_week'), value: 'LAST_WEEK' },
        { label: this.$t('FilterBar.this_month'), value: 'THIS_MONTH' },
        { label: this.$t('FilterBar.last_month'), value: 'LAST_MONTH' },
        { label: this.$t('FilterBar.this_year'), value: 'THIS_YEAR' },
        { label: this.$t('FilterBar.last_year'), value: 'LAST_YEAR' },
		{ label: this.$t('FilterBar.previous_period'),value: 'PREVIOUS_PERIOD'}
    //,
        // { label: this.$t('FilterBar.previous_year_same_period'), value: 'PREVIOUS_YEAR_SAME_PERIOD'}
      ],
	  comparisonPeriodsCompare: [
        { label: this.$t('FilterBar.previous_period'),value: 'PREVIOUS_PERIOD'},
        { label: this.$t('FilterBar.previous_year_same_period'), value: 'PREVIOUS_YEAR_SAME_PERIOD'},
        { label: this.$t('FilterBar.previous_year_same_day_of_week'), value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'}
      ],

      thresholds: [{ label: 'Seconds', value: 'SECONDS' }],
      weekdays: [
        { label: this.$t('Date.monday'), value: 'MONDAY' },
        { label: this.$t('Date.tuesday'), value: 'TUESDAY' },
        { label: this.$t('Date.wednesday'), value: 'WEDNESDAY' },
        { label: this.$t('Date.thursday'), value: 'THURSDAY' },
        { label: this.$t('Date.friday'), value: 'FRIDAY' },
        { label: this.$t('Date.saturday'), value: 'SATURDAY' },
        { label: this.$t('Date.sunday'), value: 'SUNDAY' }
      ]
    };
  }
};
