
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      kpiFormatted: {},
      selectOptionsKpi: [],
      loading: false,
      series: [
        {
          name: 'Shoes',
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: 'Hats',
          data: [53, 32, 33, 52, 13, 43, 32]
        },
        {
          name: 'Shirts',
          data: [12, 17, 11, 9, 15, 11, 20]
        },
        {
          name: 'Socks',
          data: [9, 7, 5, 8, 6, 9, 4]
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        colors: ['#75abf7', '#f4a640', '#75b265', '#f5c132'],
        xaxis: {
          categories: [
            'Group 0',
            'Group 1',
            'Group 2',
            'Group 3',
            'Group 4',
            'Group 5',
            'Group 6'
          ],
          labels: {
            formatter: function(val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: 40,
          offsetY: 30
        }
      } // chartOptions
    };
  }
};
