
import { mapState } from 'vuex';

export default {
  props: {
    elevated: {
      type: Boolean,
      required: false,
      default: false
    } // elevated
  }, // props

  computed: {
    ...mapState('queue', {
      data: 'AverageUsersInLine'
    })
  }, // computed
  watch: {
    data() {}
  }
}; // export default
