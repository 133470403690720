import { default as Payment, default as PaymentCard } from '@/models/Payment';
import { convertFromPaymentCardDTO } from '@/models/formatters/PaymentFormatter';

import PaymentController from '@/services/management/PaymentController';
import TenantController from '@/services/account/TenantController';
import map from 'lodash/map';
import { Pagination } from '@/util/pagination';

export interface GetPaymentsResult {
  payments: Payment[];
}

export type UpsertPayment = PaymentCard;

export default class PaymentRepository {
  private readonly paymentController: PaymentController;
  private readonly tenantController: TenantController;

  constructor(
    paymentController: PaymentController,
    tenantController: TenantController
  ) {
    this.paymentController = paymentController;
    this.tenantController = tenantController;
  }

  public async getPaymentCards(
    tenantName: string,
    pagination: Pagination = new Pagination()
  ) {
    const tenant = await this.tenantController.getTenant(tenantName);
    const { content, ...meta } = await this.paymentController.getPaymentCards(
      tenant.id,
      pagination.toRequest()
    );
    return {
      data: map(content, dto => convertFromPaymentCardDTO(dto)),
      pagination: meta
    };
  }

  public async removePayment(id: string) {
    await this.paymentController.deletePayment(id);
  }

  public async createSetupIntent(tenantName: string) {
    const tenant = await this.tenantController.getTenant(tenantName);
    return await this.paymentController.createSetupIntent(tenant.id);
  }
}
