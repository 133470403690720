import { CreateStore, UpdateStore } from '@/repositories/StoreRepository';
import Store, { PlanOrder, StoreDTO, StoreFloorPlanImage } from '../Store';

export function convertStoreDTO(dto: any, planDto: PlanOrder): Store {
  let city = dto.city;
  let prefecture = {
    id: null,
    perfecture: null,
    district: null,
    perfectureid: null
  };
  let district = { id: null, district: null, region: null, districtid: null };
  let region = { id: null, region: null, country: null, regionid: null };
  let country = { id: null, name: null };

  if (city.prefecture != null) {
    prefecture = city.prefecture;
  }
  if (prefecture.district != null) {
    district = prefecture.district;
  }
  if (district.region != null) {
    region = district.region;
  }
  if (region.country != null) {
    country = region.country;
  }
  let businessHour = dto.storeBusinessHours || [];
  if (businessHour.length > 0) {
    businessHour = sortBusinessHour(businessHour);
  }

  let planEntity = null;
  if (planDto != null && planDto.plan != null) {
    planEntity = planDto.plan;
  }
  return {
    id: dto.id,
    name: dto.storename,
    brandId: dto.brand.id,
    storeTypeId: dto.storeType.id,
    address: dto.address,
    contactNumber: dto.contactNumber,
    storeNumber: dto.storeNumber,
    area: dto.area,
    areaUnit: dto.areaUnit,
    floorLevel: dto.floorLevel,
    cityID: city.cityid,
    cityId: city.id,
    city: city.city,
    districtId: district.id,
    districtID: district.districtid,
    district: district.district,
    prefectureId: prefecture.id,
    prefectureID: prefecture.perfectureid,
    prefecture: prefecture.perfecture,
    regionId: region.id,
    regionID: region.regionid,
    region: region.region,
    countryId: country.id,
    country: country.name,
    storeFloorPlan: dto.storeFloorPlan,
    storeBusinessHours: businessHour,
    storeFloorPlanImages: dto.storeFloorPlanImages.map(
      image =>
        ({
          id: image.id,
          filename: image.image.filename,
          path: image.image.path
        } as StoreFloorPlanImage)
    ),
    openingDate: dto.openingDate,
    postalCode: dto.postalCode,
    status: dto.status,
    plan: planEntity,
    storeid: dto.storeid,
    precedence: dto.precedence,
    routerPortNumber: dto.routerPortNumber,
    brandReporting: dto.brandReporting,
    storetypeid: dto.storetypeid,
    storecode: dto.storecode,
    mondayCloseHour: dto.mondayCloseHour,
    mondayOpenHour: dto.mondayOpenHour,
    tuesdayCloseHour: dto.tuesdayCloseHour,
    tuesdayOpenHour: dto.tuesdayOpenHour,
    wednesdayCloseHour: dto.wednesdayCloseHour,
    wednesdayOpenHour: dto.wednesdayOpenHour,
    thursdayCloseHour: dto.thursdayCloseHour,
    thursdayOpenHour: dto.thursdayOpenHour,
    fridayCloseHour: dto.fridayCloseHour,
    fridayOpenHour: dto.fridayOpenHour,
    saturdayCloseHour: dto.saturdayCloseHour,
    saturdayOpenHour: dto.saturdayOpenHour,
    sundayCloseHour: dto.sundayCloseHour,
    sundayOpenHour: dto.sundayOpenHour
  };
}

export const convertFromStoreDTO = convertStoreDTO;

function sortBusinessHour(hours) {
  const correctOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  const copy = [...hours];
  return correctOrder.map(day => {
    const item = copy.find(
      value => value.day.toLowerCase() === day.toLowerCase()
    );
    if (item != null) {
      return {
        ...item,
        day
      };
    }
    return {};
  });
}

export function convertToPayload(store: CreateStore | UpdateStore | any) {
  return {
    storename: store.name,
    name: store.name,
    address: store.address,
    contactNumber: store.contactNumber,
    perfectureID: store.perfectureID || store.prefectureID,
    prefecture: store.prefecture,
    prefectureId: store.prefectureId,
    districtID: store.districtID,
    district: store.district,
    storeTypeId: store.storeTypeId,
    regionID: store.regionID,
    region: store.region,
    storeid: store.storeid,
    countryID: store.countryID,
    countryId: store.countryId,
    country: store.country,
    cityReporting: store.city,
    cityID: store.cityID,
    city: { id: store.cityId },
    postalCode: store.postalCode,
    brand: {
      id: store.brandId
    },
    storeType: {
      id: store.storeTypeId
    },
    storeFloorPlan: store.storeFloorPlan,
    storeFloorPlanImages: store.storeFloorPlanImages.map(image => ({
      id: image.id,
      image: {
        filename: image.filename,
        path: image.path
      }
    })),
    storeNumber: store.storeNumber,
    area: store.area,
    areaUnit: store.areaUnit,
    floorLevel: store.floorLevel,
    openingDate: store.openingDate,
    storeBusinessHours: store.storeBusinessHours.map(businessHour => ({
      ...businessHour,
      day: businessHour.day.toUpperCase()
    })),
    status: store.status,
    precedence: store.storeid,
    routerPortNumber: store.routerPortNumber || 0,
    brandReporting: store.brandReporting,
    storetypeid: store.storetypeid,
    storecode: store.storeNumber,
    mondayCloseHour: store.mondayCloseHour,
    mondayOpenHour: store.mondayOpenHour,
    tuesdayCloseHour: store.tuesdayCloseHour,
    tuesdayOpenHour: store.tuesdayOpenHour,
    wednesdayCloseHour: store.wednesdayCloseHour,
    wednesdayOpenHour: store.wednesdayOpenHour,
    thursdayCloseHour: store.thursdayCloseHour,
    thursdayOpenHour: store.thursdayOpenHour,
    fridayCloseHour: store.fridayCloseHour,
    fridayOpenHour: store.fridayOpenHour,
    saturdayCloseHour: store.saturdayCloseHour,
    saturdayOpenHour: store.saturdayOpenHour,
    sundayCloseHour: store.sundayCloseHour,
    sundayOpenHour: store.sundayOpenHour
  };
}

export function convertStoreDTOAreaManager(
  dto: any,
  planDto: PlanOrder
): Store {
  let prefecture: any = { id: null, prefecture: null, district: null };
  let district: any = { id: null, district: null, region: null };
  let region: any = { id: null, region: null, country: null };
  let country: any = { id: null, city: null };
  if (dto.city.prefecture != null) {
    prefecture = dto.city.prefecture;
  }
  if (prefecture.district != null) {
    district = prefecture.district;
  }
  if (district.region != null) {
    region = district.region;
  }
  if (region.country != null) {
    country = region.country;
  }
  let businessHour = dto.storeBusinessHours || [];
  if (businessHour.length > 0) {
    businessHour = sortBusinessHour(businessHour);
  }

  let planEntity = null;
  if (planDto != null && planDto.plan != null) {
    planEntity = planDto.plan;
  }

  return {
    id: dto.id,
    name: dto.storename,
    brandId: dto.brand.id,
    storeTypeId: dto.storeType.id,
    address: dto.address,
    contactNumber: dto.contactNumber,
    storeNumber: dto.storeNumber,
    area: dto.area,
    areaUnit: dto.areaUnit,
    floorLevel: dto.floorLevel,
    cityId: dto.city.id,
    cityID: dto.cityID,
    city: dto.city.city,
    districtId: dto.districtID,
    district: dto.district,
    prefectureId: dto.prefectureID,
    prefecture: dto.prefecture,
    regionId: dto.regionID,
    region: dto.region,
    countryId: country.id,
    country: country.name,
    storeFloorPlan: dto.storeFloorPlan,
    storeBusinessHours: businessHour,
    storeFloorPlanImages: dto.storeFloorPlanImages.map(
      image =>
        ({
          id: image.id,
          filename: image.image.filename,
          path: image.image.path
        } as StoreFloorPlanImage)
    ),
    openingDate: dto.openingDate,
    postalCode: dto.postalCode,
    status: dto.status,
    plan: planEntity
  };
}
