
import { addMonths, startOfWeek, startOfMonth } from 'date-fns';
import { mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      dateValue: {
        title: this.$t('title'),
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared,

        /*startDate: startOfMonth(new Date()),
        endDate: new Date(),
        compareStartDate: addMonths(startOfMonth(new Date()), -1),
        compareEndDate: addMonths(new Date(), -1),*/
        daysOfWeek: 'monday,tuesday,wednesday,thursday,friday'
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState'
    ])
  },
  methods: {
    ...mapMutations('periodicReport', ['setMonthlyLoading']),
    changeLoading(val) {
      this.setMonthlyLoading({ component: 'monthlyWeekdays', value: val });
    }
  }
};
