
import { mapActions } from 'vuex';

export default {
  props: {
    details: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      datName: '',
      datUsername: '',
      datEmail: '',
      datPhone: '',
      loading: false
    };
  },
  methods: {
    ...mapActions('marketplace', ['sendEmailToUser']),
    async submitForm() {
      const details = {
        firstName: this.datName,
        lastName: '',
        email: this.datEmail,
        contactNumber: this.datPhone,
        company: '',
        department: '',
        message: '',
        plan: this.details.name
      };
      this.loading = true;
      await this.sendEmailToUser(details);
      this.loading = false;
      this.closeModal();
    }
  }
};
