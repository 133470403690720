

function getZeroData(obj) {
  let retVal = {}
  obj.forEach((k) => {
    retVal[k] = 0;
  })
  return retVal;
}

function insertEmptyDates(kpis, extData) {
  let mD = 0;

  const {startDate, endDate, userKPIs, storeCodes} = extData;
  let zeroData = JSON.stringify(getZeroData(userKPIs))
  let data_flags = {};
  kpis.forEach((itm) => {
    data_flags[itm._id] = true;
  });
  let sD = new Date(startDate);
  let eD = new Date(endDate);

  let cDate = sD;
  while(cDate <= eD) {
    let conD = cDate.toISOString().split('T')[0].split('-').join('')
    for(let i=0; i<storeCodes.length; i++) {
      let id = conD + "_" + storeCodes[i];
      if(!data_flags[id]) {
        let zD = JSON.parse(zeroData);
        zD._id = id
        kpis.push(zD);
        data_flags[id] = true;
        mD++;
      }
    }
    cDate.setDate(cDate.getDate() + 1)
  }
  console.log("Missing Data added Count; ", mD)
}

let week = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

function insertEmptyHours(kpis, extData) {
  let mD = 0;

  const {startDate, endDate, userKPIs, storeCodes, stores} = extData;

  let zeroData = JSON.stringify(getZeroData(userKPIs))
  let data_flags = {};
  let storeTime = {}

  let sD = new Date(startDate);
  let day = week[sD.getDay()]
  stores.forEach((s) => {
    let t = s?.storeTimings?.filter((st) => st.day == day)[0]
    let o=10, c=20;
    if(t) {
      o = Number(t.openingTime.split(":")[0])
      c = Number(t.closingTime.split(":")[0])
      for(let i=o; i<= c; i++) {
        data_flags[i +"_"+  s.storeNumber] = false;
      }  
    }
     
  })
  kpis.forEach((itm) => {
    data_flags[itm._id] = true;
  });


  let keys = Object.keys(data_flags)
  for(let i=0; i<keys.length; i++) {
    let h = keys[i].split("_")[0]
    let s = keys[i].split("_")[1]
    if(!data_flags[keys[i]]) {
      let zD = JSON.parse(zeroData);
      zD._id = h.toString() + "_" + s;
      kpis.push(zD);
      data_flags[keys[i]] = true;
      mD++;
    }
  }
  console.log("Missing Data added Count; ", mD)
}

export function fillEmptyData(kpis, extData) {
  const {startDate, endDate, userKPIs, storeCodes} = extData;
  if(startDate !== endDate) {
    insertEmptyDates(kpis, extData)
  } else {
    insertEmptyHours(kpis, extData)
  }
  
}