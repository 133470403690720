
export default {
  props: {
    label: { type: String, required: true },
    backLabel: { type: String, default: '' },
    backIcon: { type: String, default: 'fas fa-arrow-left' }
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
    backDrawer() {
      this.$emit('back');
    }
  }
};
