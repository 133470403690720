import { PaginationRequest } from '@/util/pagination';
import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@/models/Pagination';
import { SensorDTO } from '@/models/Sensor';
import { createBackendError } from '@/util/error';

export type GetSensorsResponse = PaginatedResponse<SensorDTO>;

export default class SensorController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getSensors(pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/console/item', {
        params: {
          ...pagination
        }
      });
      return data as GetSensorsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getSensorImageAsBase64(sensorId: string) {
    try {
      const { data } = await this.client.get(
        `/console/item/${sensorId}/photo/base64`
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
