

const data = [{
  kpi: 'Passers by',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: 10000,
  comparisonV: 10000,
  comparison: 21.3,
}, {
  kpi: 'Traffic',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: 5000,
  comparisonV: 8.000,
  comparison: 11.5,
}, {
  kpi: 'Capture Rate',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: '20%',
  comparisonV: '10%',
  comparison: 17.9,
}, {
  kpi: 'Transactions',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: 200,
  comparisonV: 200,
  comparison: 3.1,
}, {
  kpi: 'Conversion',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: '5%',
  comparisonV: '5%',
  comparison: 5.7,
}, {
  kpi: 'Sales',
  standardCampaing: [1, 2, 3, 4, 5, 6],
  comparisonCampaing: [10, 9, 8, 3, 2],
  standardV: 205.000,
  comparisonV: 305.00,
  comparison: 12.4,
}];

var randomizeArray = function (arg) {
  var array = arg.slice();
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

var sparklineData = [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51, 35, 41];

var spark3 = {
  chart: {
    id: 'sparkline3',
    group: 'sparklines',
    type: 'line',
    height: 50,
    sparkline: {
      enabled: true
    },
    // dropShadow: {
    //   enabled: true,
    //   top: 1,
    //   left: 0,
    //   blur: 2,
    //   opacity: 0.2,
    // }
  },
  stroke: {
    curve: 'smooth'
  },
  fill: {
    opacity: 1,
  },
  labels: [...Array(14).keys()].map(n => `2018-09-0${n + 1}`),
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    min: 0
  },
  colors: ['#008FFB'],
  //colors: ['#5564BE'],
  // title: {
  //   text: '$135,965',
  //   offsetX: 30,
  //   style: {
  //     fontSize: '24px',
  //     cssClass: 'apexcharts-yaxis-title'
  //   }
  // },
  // subtitle: {
  //   text: 'Profits',
  //   offsetX: 30,
  //   style: {
  //     fontSize: '14px',
  //     cssClass: 'apexcharts-yaxis-title'
  //   }
  // }
}

export default {
  data () {
    return {
      tabC: 'passersby',
      weatherToggle: true,
      cType: 'trends',
      data,
      spark3,
      series: [{
        name: 'Profits',
        data: randomizeArray(sparklineData)
      }],

    };
  },

};
