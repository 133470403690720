
import { required } from 'vuelidate/lib/validators';

import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import Role from '@/models/Role';
import { handleErrorUI } from '../../../util/error';
import { validateMandatoryFields } from '@/util/validation';
import { mapGetters } from 'vuex';
import DefaultSettingsMixin from '@/mixins/DefaultSettingsMixin';
import AreaFormMixin from '@/mixins/AreaFormMixin';
import DefaultSettings, {
  initialDefaultSettings
} from '@/models/onboarding/DefaultSettings';
import store from '@/store';

export default {
  mixins: [CommonMixin, FormMixin, DefaultSettingsMixin, AreaFormMixin],
  props: {
    mode: { type: String, default: 'Edit' },
    role: { type: Object, required: true },
  },
  data() {
    return {
      request: new Role({
        _id: -1,
        title: '',
        description: '',
        permissions: [],
        selectedPeriod: '',
        comparePeriod: '',
        locationFilter: false
        //,tenantId:-1
      }),
      data: initialDefaultSettings(),
      permissions: [],
      selectedPeriod: '',
      comparePeriod: '',
      locationFilter: false,
      menus: [],
      allowedAuthorities: [],
      authorityTableData: [],
      loading: false
    };
  },
  computed: {
    canSubmit() {
      return validateMandatoryFields(this.request, ['title', 'description','selectedPeriod','comparePeriod','locationFilter']);
    },
    atLeastOnePermissionSelected() {
      return (
        this.authorityTableData.filter(
          row => Object.values(row.actions).filter(e => e.active).length > 0
        ).length > 0
      );
    }
  },
  async created() {
    this.request = Object.assign({}, this.role);
    const authorities = await this.$repo.role.getAuthorities();
    this.authorities = authorities;
    let initialValue = await this.getAuthoritiesInitialValue(this.role);
    this.authorityTableData = await this.getAuthorityTableData(
      authorities,
      initialValue,
      0,
      0
    );
    this.loading = false;

    if(this.role?.config?.comparePeriod !=null)
    {

      const defaultSettings = this.role?.config;
        this.selectedPeriod = defaultSettings.selectedPeriod;
        this.comparePeriod= defaultSettings.comparePeriod;
        this.locationFilter =defaultSettings.allowLocationFilter;
    }
    else{
      const defaultSettings = store.getters['user/getDefaultSettings'];
        this.selectedPeriod = defaultSettings.headquartersSelectPeriods;
        this.comparePeriod= defaultSettings.headquartersComparePeriods;
        this.locationFilter =defaultSettings.locationFilter;
    }
  },
  methods: {
    ...mapGetters('user', ['getLocale']),
    getAuthoritiesInitialValue(role) {
      if (role == null || role._id == null) {
        return [];
      }
      const roleAuthorities = role.permissions;
      const defaultSettings = store.getters['user/getDefaultSettings'];
      return roleAuthorities.map(authority => authority.title);
    },
    async getAuthorityTableData(authorities, initialValue,fromCount,toCount) {
      const lang = this.getLocale() || 'jp';
      let auth1 = {};
      let i=0;
      let j=fromCount;
      authorities.map(authority => {
      i++;
      if((i>=fromCount && i<=toCount) || (fromCount ==0 && toCount ==0))
      {
        let column = authority.title;

        if (authority.title.endsWith('Create')) {
          column = 'Create';
        } else if (authority.title.endsWith('Edit')) {
          column = 'Edit';
        } else if (authority.title.endsWith('View')) {
          column = 'View';
        } else if (authority.title.endsWith('Delete')) {
          column = 'Delete';
        } else if (authority.title.endsWith('Download')) {
          column = 'Download';
        } else if (authority.title.endsWith('Upload')) {
          column = 'Upload';
        } else if (authority.title.endsWith('Import')) {
          column = 'Import';
        }

        auth1[authority?.module.en] =
          {
            name: authority?.module[lang],
            actions: {
              ...auth1[authority?.module.en]?.actions,
              [column]: {
                ...authority,
                active: !!initialValue.includes(authority.title)
              }
            }
          } || {};
      }
      });
      return Object.values(auth1);
    },
    onPermissionChanged(change) {
      this.authorityTableData.map(row => {
        if (row.name === change.module.name) {
          row.actions[change.action].active = !row.actions[change.action]
            .active;
        }
      });
    },
    async onSave() {
      this.loading = true;
        try {
          this.$v.request.$touch();
          if (this.atLeastOnePermissionSelected) {
            //const getProfileData = store.getters['user/getProfile'];
            //this.request.tenantId = getProfileData.tenant._id;
            this.request.selectedPeriod = this.selectedPeriod;
            this.request.comparePeriod = this.comparePeriod;
            this.request.locationFilter = this.locationFilter;
            const final = [];
            this.authorityTableData.map(res => {
              Object.values(res.actions).map(action => {
                if (action.active) {
                  final.push(action._id);
                }
              });
            });
            if (this.request._id == null) {
              await this.createNewRole();
              this.$emit('created', this.request);
            } else {
              await this.updateRole();
              this.$emit('created', this.request);
            }
            this.$emit('close');
          } else {
            this.$q.notify({
              type: 'negative',
              message: this.$t('AuthPage.at_least_one_permission'),
              position: 'top'
            });
          }
        } catch (err) {
          handleErrorUI(err);
        } finally {
          this.loading = false;
          this.$v.request.$reset();
        }

    },
    async createNewRole() {
      try {
        const listOfAuthorities = [];
        this.authorityTableData.map(row =>
          Object.values(row.actions).map(action => {
            if (action.active) {
              listOfAuthorities.push(action.title);
            }
          })
        );

        await this.$repo.role.createRole(this.request, listOfAuthorities);

        this.$q.notify({
          message: this.$t('SettingsManagement.roleCreatedSuccessfully'),
          type: 'positive',
          position: 'top',
          timeout: 3000
        });
      } catch (err) {
        this.$q.notify({
          message:
            err?.respose?.data?.message || this.$t(`Something went wrong`),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
      }
    },
    async updateRole() {
      await this.updateRoleAuthorities();
      const permissions = [];
      this.authorityTableData.map(row =>
        Object.values(row.actions).map(action => {
          if (action.active) {
            permissions.push(action);
          }
        })
      );
      this.request.permissions = permissions;
    },
    async updateRoleAuthorities() {
      const listOfAuthorities = [];
      this.authorityTableData.map(row =>
        Object.values(row.actions).map(action => {
          if (action.active) {
            listOfAuthorities.push(action.title);
          }
        })
      );
      try {
        await this.$repo.role.updateRoleAuthorities(
          this.request.title,
          this.request.description,
          this.request._id,
          listOfAuthorities,
          this.request.selectedPeriod,
          this.request.comparePeriod,
          this.request.locationFilter
        );

        this.$q.notify({
          message: this.$t('SettingsManagement.roleUpdatedSuccessfully'),
          type: 'positive',
          position: 'top',
          timeout: 3000
        });
      } catch (err) {
        this.$q.notify({
          message:
            err?.respose?.data?.message || this.$t(`Something went wrong`),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
      }
    },
    closeDialog() {
      if (this.loading) return;
      this.$emit('close');
    }
  },
  validations: {
    request: {
      name: {
        required
      },
      description: {
        required
      },
      selectedPeriod: {
        required
      },
      comparePeriod: {
        required
      },
      locationFilter: {
        required
      }
    }
  }
};
