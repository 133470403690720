
import clone from 'lodash/clone';
import { checkFieldErrors } from '@/util/error';
import i18nMixin from '@/mixins/i18nMixin';
import AuthMixin from '@/mixins/AuthMixin';

export const createErrors = () => ({
  password: '',
  confirmPassword: '',
  isHide: true
});
export default {
  mixins: [i18nMixin, AuthMixin],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      errors: createErrors()
    };
  },
  methods: {
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    getData() {
      return clone(this.value);
    },
    validate() {
      const value = this.value;
      const errors = createErrors();
      if (!value.password)
        errors.password = this.$t('AuthPage.field_is_required');
      if (!value.confirmPassword)
        errors.confirmPassword = this.$t('AuthPage.field_is_required');
      else if (value.confirmPassword !== value.password)
        errors.confirmPassword = "Password confirmation doesn't match";
      this.errors = errors;
      return checkFieldErrors(errors);
    },
    sync() {
      this.$emit('input', this.getData());
    }
  }
};
