import { AxiosInstance } from 'axios';
import { City } from '@/models/Location';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';

export type GetCitiesResponse = PaginatedResponse<City>;

export default class CityController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getCities() {
    try {
      const { data } = await this.client.get('/city', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 100,
          paged: false
        }
      });
      return data as GetCitiesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getCitiesByPrefecture(prefectureId) {
    try {
      const { data } = await this.client.get('/city', {
        params: {
          query: `prefectureId:${prefectureId}`
        }
      });
      return data as GetCitiesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
