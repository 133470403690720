
import { QTime } from 'quasar';
import Field from './Field';

export default {
  components: {
    Field,
    QTime
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    format24h: {
      type: Boolean,
      default: false
    },
    withSeconds: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: 'HH:mm'
    },
    options: {
      type: [Array, Function],
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    timeReadonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    focus: false
  }),
  computed: {
    inputClass() {
      const { $style, focus } = this;
      return {
        [$style.input]: true,
        [$style.focus]: focus
      };
    },
    inputFieldMask() {
      return this.withSeconds ? 'fulltime' : 'time';
    }
  },
  methods: {
    handleFocus() {
      this.focus = true;
    },
    handleBlur() {
      this.focus = false;
    }
  }
};
