
import Vue from 'vue';
import { ApexOptions } from 'apexcharts';
import VueApexCharts from 'vue-apexcharts';

export default Vue.extend({
  props: {
    /**
     *
     */
    series: {
      type: Array,
      default: () => []
    },
    /**
     *
     */
    labels: {
      type: Array,
      default: () => []
    },
    /**
     *
     */
    colors: {
      type: Array,
      default: () => []
    }
  },
 
  methods: {

    renderChart(h) {
      const apexOptions: ApexOptions = {
        chart: {
          type: 'pie',
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            },
             export: {
              csv: {
                headerValue: 'percentage (%)',
              }
             }
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -20
            }
          }
        },
        // tooltip: {
        //   enabled: true,
        //
        // },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `<q-card class="q-pa-md box-trend-tooltip tooltip-container bg-white">
                    <div class="flex items-center">
                    <div class="pie-dot q-mr-sm"
                      style="height: 8px;width: 8px;
                    border-radius: 8px;background:${this.colors[seriesIndex]}"></div>
                    <label>${
                      this.labels[seriesIndex]
                    }:</label><span>&nbsp;${
              series[seriesIndex]
            } %</span>
              </div>
            </q-card>`;
          }
        },

        legend: {
          show: true,
          position: 'right',
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        },
        labels: this.labels,
        colors: this.colors,
        dataLabels: {
          formatter: (val: any, series: any): any => {
            return ['', `${val.toFixed(2)}%`];//we can add label
          },
         
          style: {
            fontSize: '14px',
          },
          dropShadow: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      };

      return h(VueApexCharts, {
        class: 'group-pie',
        props: {
          height: 350,
          series: this.series as ApexNonAxisChartSeries,
          options: apexOptions
        }
      });
    }
  }, // methods
  render(h) {
    if (this.series.reduce((x, y) => x + y, 0) !== 0) {
      return this.renderChart(h);
    } else {
      return h('span', { class: 'full-width' }, 'No data available');
    }
  } // render
});
