
import FormMixin from '@/components/Management/Mixins/FormMixin';
import { downloadAsBlob } from '@/util/file';

export default {
  mixins: [FormMixin],
  data() {
    return {
      selectedFile: null
    };
  },
  computed: {
    selectedFileName() {
      if (this.selectedFile == null) return null;
      return this.selectedFile.name;
    }
  },
  methods: {
    async downloadTemplate() {
      const data = await this.$repo.store.getImportTemplate();
      downloadAsBlob(data, 'text/csv', 'store-template.csv');
    },
    onFileSelected(evt: Event) {
      const file = (evt.target as HTMLInputElement).files[0];
      this.selectedFile = file;
    }
  }
};
