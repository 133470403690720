
import Vue from "vue";
import { convertApiResponseAllStores } from "@/helper/helperFunction";

import {
  Box,
  BoxHeader,
  BoxPerformanceComparisonRadar,
  BoxPerformanceComparisonLine,
  BoxPerformanceComparisonRadarRegion,
  BoxPerformanceComparisonRadarPeerGroup,
  BoxPerformanceComparisonRadarDistricts,
  BoxPerformanceComparisonRadarAM,
  BoxPerformanceComparisonLineRegion,
  BoxPerformanceComparisonLineDistrict,
  BoxPerformanceComparisonLinePeerGroup,
  BoxPerformanceComparisonLineAM,
} from "@/components/Box";

import LoadingSpinner from "@/components/Common/Loading/LoadingSpinner.vue";

import flatMap from "lodash/flatMap";

import FilterKPI from "@/components/Filter/FilterKPI.vue";
import DateRangeSelectorSingle from "@/components/Filter/DateRangeSelectorSingle.vue";
//import FilterHierarchy from '@/components/Filter/FilterHierarchy.vue';

import { colors } from "@/util";

import { APIFetchStoresList } from "@/api/store";
import BoxCard from "@/components/Box/BoxCard";
import { mapState, mapActions, mapMutations } from "vuex";
// import FilterStoreLocation from '@/components/Filter/FilterStoreLocation';

export default Vue.extend({
  components: {
    BoxCard,
    LoadingSpinner,
    BoxHeader,
    BoxPerformanceComparisonRadar,
    BoxPerformanceComparisonLine,
    BoxPerformanceComparisonRadarRegion,
    BoxPerformanceComparisonRadarPeerGroup,
    BoxPerformanceComparisonRadarDistricts,
    BoxPerformanceComparisonRadarAM,
    BoxPerformanceComparisonLineRegion,
    BoxPerformanceComparisonLineDistrict,
    BoxPerformanceComparisonLinePeerGroup,
    BoxPerformanceComparisonLineAM,
    FilterKPI,
    //FilterHierarchy,
    DateRangeSelectorSingle,
    //FilterStoreLocation
  },
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    }, // elevated
  }, // props
  data() {
    return {
      tabCompare: "store",
      lastTimeStamp: Date.now(),
      lastTimeStampK: Date.now(),
      selectedStores: [],
      selectedRegion: [],
      selectedPeerGroups: [],
      selectedAreaManagers: [],
      selectedDistricts: [],
      options: [],
      loading: false,
      series: [],
      labels: [],
      selectedKpis: [], //['sls', 'trf', 'cn', 'trn'],
      selectedKpi: "Sales",
      selectedHierarchy: "store",
      selectedRange: {},
      search: "",
      markedOptions: [],
      storeCodesSelected: [],
      // ,
      // selectedStoresForRegion: []
    };
  }, // data
  computed: {
    ...mapState("home", ["comparisonTab"]),
    ...mapState("filter", ["filter", "stores"]),
    ...mapState("store", ["stores"]),
    ...mapState("comparison", ["selectedkpiTabs"]),
    ...mapState("user", ["kpiPreferences", "kpiLabelValue"]),
    options2() {
      if (this.stores) {
        let areaManagers = {};
        let perGroups = {};
        let regions = {};
        let districts = {};
        areaManagers.name = this.$t("FilterStoreLocation.area_managers");

        areaManagers.children = this.stores.areaManagers?.map((v, key) => {
          return {
            name: v.name,
            children:
              v.stores.map((val) => ({
                name: val.storeCode,
              })) || [],
            // .filter(v => this.profile.stores?.includes(v.name))
          };
        });

        // .filter(v => v.children.length > 0);
        perGroups.name = this.$t("FilterStoreLocation.peer_groups");
        perGroups.children = this.stores.peerGroups?.map((v, key) => {
          return {
            name: v.name,
            children: v.stores.map((val) => ({
              name: val.storeCode,
            })),
            // .filter(v => this.profile.stores?.includes(v.name))
          };
        });
        // .filter(v => v.children.length > 0);
        regions.name = this.$t("FilterStoreLocation.regions");
        regions.children = this.stores.regions
          .map(({ name, districts, key }) => {
            return {
              name,
              stores: districts.map(({ stores }) => stores).flat(),
            };
          })
          .map((v, key) => {
            return {
              name: v.name,
              children: v.stores.map((val) => ({
                name: val.storeCode,
              })),
              // .filter(v => this.profile.stores?.includes(v.name))
            };
          });
        // .filter(v => v.children.length > 0);
        districts.name = this.$t("FilterStoreLocation.districts");
        districts.children = this.stores.regions
          .map((region) => region.districts)
          .flat()
          .map((v, key) => {
            return {
              name: v.name,
              children: v.stores.map((val) => ({
                name: val.storeCode,
              })),
              // .filter(v => this.profile.stores?.includes(v.name))
            };
          });
        // .filter(v => v.children.length > 0);
        //console.log(areaManagers);
        //    return areaManagers.filter(x => this.name.indexOf(x) === -1);
        return {
          areaManagers: [areaManagers],
          perGroups: [perGroups],
          regions: [regions],
          districts: [districts],
        };
      }

      return [];
    },

    colors: () => colors.secondary,
    selectionsAvailable() {
      return this.options.filter((x) => this.selectedStores.indexOf(x) === -1);
    },
    selectedStoresForRegion() {
      var result = this.options2.regions[0].children[0].children.map((x) => x.name)[0];
      return result;
    },
    selectionsAvailableForRegion() {
      return this.options2.regions[0].children.map((x) => x.name);
    },
    selectionsAvailableAreaManagers() {
      return this.options2.areaManagers[0].children.map((x) => x.name);
    },
    selectionsAvailablePeerGroups() {
      return this.options2.perGroups[0].children.map((x) => x.name);
    },
    selectionsAvailableDistricts() {
      return this.options2.districts[0].children.map((x) => x.name);
    },
    tabOptionsCompare() {
      return [
        { label: this.$t("BoxComparison.region"), value: "region" },
        { label: this.$t("BoxComparison.district"), value: "district" },
        { label: this.$t("BoxComparison.store"), value: "store" },
        { label: this.$t("BoxComparison.peer_group"), value: "group" },
        { label: this.$t("BoxComparison.area_manager"), value: "sv" },
      ];
    },
  }, // computed
  watch: {
    selectedKpis: {
      handler: "refreshPage",
      deep: true,
    },
    selectedKpi: {
      handler: "refreshPageK",
      deep: true,
    },
    selectedStores(newVal) {
      this.setSelectedStores({ stores: newVal });
    },
    selectedRegion(newVal) {},
    selectedAreaManagers(newVal) {},
    selectedDistricts(newVal) {},
    selectedPeerGroups(newVal) {},
    tabCompare(newValue) {
      this.setComparisonTab({ tab: newValue });
      this.tabCompare = newValue;
    },
    "filter.storeCodes": {
      handler: "changeStoreCodes",
    },
  },
  async mounted() {
    this.loading = true;
    const storedKPI = localStorage.getItem("selectedComparisonKPI");
    if (storedKPI && storedKPI.length) {
      this.selectedKpis = storedKPI.split(",");
    }
    this.selectedKpi = this.selectedKpis[0];
    if(Object.keys(this.selectedkpiTabs).length){
      let {kpi, selectedTab} = this.selectedkpiTabs;
      let selectedKpisVal = this.kpiLabelValue.find(el => el.label === kpi)?.value;
      this.tabCompare = selectedTab;
      //
      if(!this.selectedKpis.includes(selectedKpisVal)){
        this.selectedKpis = [...this.selectedKpis, selectedKpisVal]
      }
    }
    await this.loadData();
    this.loading = false;
  }, // created
  async created() {
    if (!this.kpiPreferences || this.kpiPreferences.length <= 3) {
      for (var p = 0; p < this.kpiPreferences.length; p++) {
        if (this.kpiPreferences[p].metricKey)
          this.selectedKpis.push(this.kpiPreferences[p].metricKey);
      }
      this.selectOptionsKpi = this.selectOptionsKpi.filter(
        (item) => item !== "undefined"
      );
    }
    // this.selectedKpis= ['trf'];
    else this.selectedKpis = ["sls", "trf", "cn", "trn"];

    this.tabCompare = localStorage.getItem("tabComparison") ?? "store";
    const data = await this.fetchStores();

    const allRegions = data.regions?.map((x) => x.name);
    const allAreaManagers = data.areaManagers?.map((x) => x.name);
    const allPeerGroups = data.peerGroups?.map((x) => x.name);
    const allDistricts = this.stores?.regions?.map((region) => region?.districts).flat().map((x) => x.name);
    let preSelectedStores = localStorage.getItem("storeCodes") ?? "";
    if (preSelectedStores !== "") {
      this.markedOptions = preSelectedStores?.length ? preSelectedStores.split(",") : [];
    }
    if (allRegions?.length > 0) this.selectedRegion = allRegions.slice(0, 3);

    if (allPeerGroups?.length > 0) this.selectedPeerGroups = allPeerGroups.slice(0, 3);
    if (allAreaManagers?.length > 0)
      this.selectedAreaManagers = allAreaManagers.slice(0, 3);
    if (allDistricts?.length > 0) this.selectedDistricts = allDistricts.slice(0, 3);
  },
  methods: {
    ...mapMutations("comparison", ["setSelectedStores"]),
    ...mapMutations("home", ["setComparisonTab"]),
    ...mapActions("store", ["fetchStores"]),
    ...mapMutations("filter", ["setStoreCodes"]),
    refreshPage() {
      this.lastTimeStamp = Date.now();
    },
    refreshPageK() {
      this.lastTimeStampK = Date.now();
    },
    changeLineKpi(data) {
      this.selectedKpi = data.kpi;
    },
    updateDateRange(data) {
      if (data) {
        this.selectedRange = data;
      }
    }, // updateDateRange
    async loadData() {
      const localStore = localStorage.getItem("selectedStoreComparison");
      let data = null;

      let res2 = await this.fetchStores();
      data = convertApiResponseAllStores(res2.stores? res2.stores: res2.data.stores ); //data.stores

      this.options = flatMap(data);
      if (localStore) {
        const stores = flatMap(JSON.parse(localStore));
        this.selectedStores.push(...stores);
        return;
      }
      this.selectedStores.push(...this.options.slice(0, 3));
    },
  }, // methods
});
