
import { createColumn } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [PaginationMixin],
  props: {
    data: { type: Array, required: true }
  },
  data() {
    return {
      columns: [
        createColumn('storeName', 'Store Name'),
        createColumn('storeNumber', 'Store Number'),
        createColumn('itemSold', 'Item Sold'),
        createColumn('salesAmount', 'Sales Amount')
      ]
    };
  }
};
