
import { mapActions, mapState } from 'vuex';
export default {
  component: ['BoxMarketPlaceDialog'],
  data() {
    return {
      show: false,
      selected: {},
      recommended: [],
      hardwares: [],
      filteredHardwares: [],
      filteredRecommended: []
    };
  },
  computed: {
    ...mapState('marketplace', [
      'iot',
      'iotRecommended',
      'selectedTag',
      'pricing'
    ])
  },
  watch: {
    pricing() {
      // this.hardwares= this.iot.map(v=>)
      if (this.pricing.length === 1 && this.pricing[0] === 'Free') {
        this.hardwares = this.iot.filter(v => v.rate === 'Free');
      } else if (this.pricing.length === 1 && this.pricing[0] !== 'Free') {
        this.hardwares = this.iot.filter(v => v.rate !== 'Free');
      } else {
        this.hardwares = this.iot;
      }
    },
    selectedTag() {
      this.hardwares = this.iot.filter(v => {
        return [
          v.label.toLowerCase(),
          ...v.tags.map(t => t.toLowerCase())
        ].includes(this.selectedTag.toLowerCase());
      });
    }
  },
  created() {
    this.fetchIotRecommended();
    this.fetchIot();
    this.hardwares = this.iot;
    this.recommended = this.iotRecommended;
  },
  methods: {
    ...mapActions('marketplace', ['fetchIotRecommended', 'fetchIot']),
    getImgUrl(pic) {
      return require('../../../assets/img/icons/' + pic + '.png');
    },
    openDialog(int) {
      this.selected = int;
      this.show = true;
    }
  }
};
