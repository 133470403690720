import { APIExport, APIExportEN } from '@/api/export';
import { base64ToArrayBuffer, saveByteArrayExport } from '../../util/utilFunctions';
import { APIFetchStoreDistributionEN } from '@/api/store';
import { GetRequestFidaStores } from '@/util/http';
import store from '@/store';

const initialState = {}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setStores(state, { stores }) {
      state.stores = stores;
    } // setStores
  }, // mutations
  actions: {
    async postExport({ commit }, exportRequestBody) {
      return new Promise(async (resolve, reject) => {
        try {

          const prvType = localStorage.getItem('PREVIOUS_YEAR_SAME_PERIOD')//'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
          const res: any = await APIExportEN(exportRequestBody);
          const sampleArr = base64ToArrayBuffer(res.data.encodedData);

          const startDate = new Date(exportRequestBody.compareStartDate); // Assuming exportRequestBody.compareStartDate is a valid date string or timestamp
          const endDate = new Date(exportRequestBody.compareEndDate); // Assuming exportRequestBody.compareEndDate is a valid date string or timestamp

          // Calculate the time difference in milliseconds
          const timeDifference = endDate.getTime() - startDate.getTime();

          // Calculate the number of days
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

          if(exportRequestBody.reportType == "detailed" && daysDifference == 6) {
            const previousYearDates = getPreviousYearSameWeekDates(exportRequestBody.startDate,exportRequestBody.endDate);

            const result = await GetRequestFidaStores('/api/getStores');

            const filteredStoreNumbers = filterStores(result.data.response.stores, exportRequestBody.storeCodes, previousYearDates.prevYearStart, previousYearDates.prevYearEnd);

            //const StoresList = result.data.response.stores;

            if(prvType == "PREVIOUS_YEAR_SAME_DAY_OF_WEEK")
            {
              previousYearDates.prevYearStart = exportRequestBody.compareStartDate;
              previousYearDates.prevYearEnd = exportRequestBody.compareEndDate;
            }

            const { data } = await APIFetchStoreDistributionEN({
              startDate: previousYearDates.prevYearStart,//exportRequestBody.compareStartDate,
              endDate: previousYearDates.prevYearEnd,//exportRequestBody.compareEndDate,
              kpis: exportRequestBody.kpis,
              groupByMetricKey: false, //optional and true by default
              storeCodes: filteredStoreNumbers//exportRequestBody.storeCodes
            });


            if(data)
              saveByteArrayExport(`${res.data.fileName}`, sampleArr,exportRequestBody.reportType,data.kpis);
            else
            saveByteArrayExport(`${res.data.fileName}`, sampleArr,exportRequestBody.reportType,null);
          }
          else
          //saveByteArray(`${res.data.fileName}`, sampleArr);
          saveByteArrayExport(`${res.data.fileName}`, sampleArr,exportRequestBody.reportType, null);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    } // fetchStores
  } // actions
}; // export default
function getPreviousYearSamePeriodDates(startDate, endDate) {

  const start: any = new Date(startDate);
  const end: any = new Date(endDate);



// Subtract a year
start.setFullYear(start.getFullYear() - 1);
end.setFullYear(end.getFullYear() - 1);

// Format the resulting date as "yyyy-MM-dd"
const startedDate = start.toISOString().slice(0, 10);
const endedDate = end.toISOString().slice(0, 10);



  // Create new dates for the previous year
  const prevYearStart = startedDate;
  const prevYearEnd = endedDate;

  return { prevYearStart, prevYearEnd };
}

function getPreviousYearSameWeekDates(startDate, endDate) {
  const start:any = new Date(startDate);
  var end:any = new Date(endDate);
  // Calculate the day of the week for the start date (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const inputDayOfWeek = start.getDay();

  // Subtract a year from both start and end dates
  start.setFullYear(start.getFullYear() - 1);
  end.setFullYear(end.getFullYear() - 1);

  // Find the first date in the previous year with the same day of the week
  while (start.getDay() !== inputDayOfWeek) {
    start.setDate(start.getDate() + 1);
  }

  // Calculate the end date based on the start date and the duration of the input period

  let diffInDays = (end - start) / (1000 * 60 * 60 * 24);
  diffInDays = diffInDays+1;
  var endStartDate:any = new Date(start);

  endStartDate.setDate(endStartDate.getDate() + diffInDays);
  end = endStartDate;

  // Format the resulting dates as "yyyy-MM-dd"
  const prevYearStart = start.toISOString().slice(0, 10);
  const prevYearEnd = end.toISOString().slice(0, 10);

  return { prevYearStart, prevYearEnd };
}


function filterStores(apiResponse, storeNumberString, startDate, endDate) {

  const storeNumbers = storeNumberString.split(',');

  // Convert the input dates to Date objects
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  const filteredStoreNumbers = apiResponse
      .filter(store => {
          const openDate = new Date(store.openDate);

          return (
              storeNumbers.includes(store.storeNumber) &&
              store.status === 0 &&
              openDate <= endDate
          );
      })
      .map(store => store.storeNumber);

  return filteredStoreNumbers.join(',');
}

// // Example usage:
// const apiResponse = /* your API response */;
// const storeNumberString = "4636,4761,4762,4769"; // Comma-separated store numbers
// const startDate = "2018-06-01"; // Start date
// const endDate = "2018-12-31"; // End date

// const filteredStoreNumbers = filterStores(apiResponse, storeNumberString, startDate, endDate);
// console.log(filteredStoreNumbers);
// module export
