import { createBackendError } from '@/util/error';
import { AxiosInstance } from 'axios';
import { PaymentConfirmation } from '@/models/RequestOrder';
import PlanOrderQuote from '@/models/PlanOrderQuote';

export interface PlanOrderRequest {
  tenantId: string;
  storeId: string;
  planId: string;
  paymentMethod: PaymentConfirmation;
}

export interface PlanOrderCalculationRequest {
  tenantId: string;
  storeId: string;
  planId: string;
}

export default class PlanOrderController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async createOrder(req: PlanOrderRequest) {
    try {
      const { data } = await this.client.post(
        `/console/order/plan/${req.tenantId}`,
        {
          planId: req.planId,
          storeId: req.storeId,
          ...req.paymentMethod
        }
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async cancelPlan(req: { tenantId: string; storeId: string }) {
    try {
      const { data } = await this.client.post(
        `/console/order/plan/${req.tenantId}/cancel`,
        null,
        {
          params: {
            storeId: req.storeId
          }
        }
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPlanOrderCalculation(req: PlanOrderCalculationRequest) {
    try {
      const { data } = await this.client.post(
        `/console/order/plan/${req.tenantId}/calculation`,
        {
          planId: req.planId,
          storeId: req.storeId
        }
      );
      return PlanOrderQuote.fromDTO(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPlanForStore(storeId: string) {
    try {
      const { data } = await this.client.get(
        `/console/order/plan/subscription/${storeId}`
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
