
export default {
  data() {
    return {
      flowServices: [
        {
          icon: 'conversion',
          label: 'conversionOptimization',
          price: '¥6,900',
          serviceFeature: [
            {
              label: 'kpiBot',
              features: []
            },
            {
              label: 'trafficAnalysis',
              features: [
                'traffic',
                'trafficPerSQM',
                'returnOnVisit',
                'conversion'
              ]
            },
            {
              label: 'staffOptimization',
              features: []
            }
          ],
          enabled: true
        },
        {
          icon: 'datadriven',
          label: 'dataDrivenRetailing',
          price: '¥3,900',
          serviceFeature: [
            {
              label: 'taskBot',
              features: []
            },
            {
              label: 'wifiAnalysis',
              features: ['engagementRate', 'bounceRate']
            },
            {
              label: 'passerbyPrediction',
              features: [
                'passersBy',
                'captureRate',
                'storeDwellTime'
                // 'facadeStops'
              ]
            }
          ],
          enabled: false
        },
        {
          icon: 'optimization',
          label: 'staffOptimization',
          price: '¥4,900',
          serviceFeature: [
            {
              label: 'advancedStaffReporting',
              features: []
            },
            {
              label: 'storeOccupancy',
              features: [
                'staffPathmaps',
                'salesPerStaffMember',
                'staffEfficiencyRating'
              ]
            },
            {
              label: 'staffPrediction',
              features: ['comingSoon']
            }
          ],
          enabled: false
        }
      ]
    };
  },
  methods: {
    getImgUrl(pic) {
      return require('../../../assets/img/icons/' + pic + '.png');
    }
  }
};
