
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    } // options
  },
  data: function() {
    return {
      maxVal: 0,
      minVal: 0,
      median: 0,
      daysOfWeek: [
        this.$t('BoxTrafficPowerHourByLine.monday'),
        this.$t('BoxTrafficPowerHourByLine.tuesday'),
        this.$t('BoxTrafficPowerHourByLine.wednesday'),
        this.$t('BoxTrafficPowerHourByLine.thursday'),
        this.$t('BoxTrafficPowerHourByLine.friday'),
        this.$t('BoxTrafficPowerHourByLine.saturday'),
        this.$t('BoxTrafficPowerHourByLine.sunday')
      ],
      mode: 'actualValue',
      loading: false,
      formattedValuesList: [],
      floor: '',
      series: [].reverse(),
      chartOptions: {
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              min: '#3e7cd21a',
              max: '#3e7cd2'
            }
          }
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        dataLabels: {
          // enabled: true
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          }
        },
        xaxis: {
          type: 'category',
          categories: []
        },
        colors: ['#3e7cd2'],
        title: {},
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
                  <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${
              w.globals.labels[dataPointIndex]
            }</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip">${
                         w.globals.seriesNames[seriesIndex]
                       }:</div>
                     <label class="text-bold">  ${
                       this.formattedValuesList[seriesIndex]
                         ? this.formattedValuesList[seriesIndex][dataPointIndex]
                         : 0
                     }</label></div>
                </q-card>
            `;
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getCustomCalenderState'
    ]), // mapGetters
    ...mapState('queue', ['powerHourLine', 'posID', 'floorID', 'storeID']),
    valueRanges() {
      let intMax = parseFloat(this.maxVal);
      let intMin = parseFloat(this.minVal);
      let dv = intMax - intMin;
      return [
        intMin,
        parseInt((dv * 0.1).toFixed(1) + intMin),
        parseInt((dv * 0.2).toFixed(1) + intMin),
        parseInt((dv * 0.3).toFixed(1) + intMin),
        parseInt((dv * 0.4).toFixed(1) + intMin),
        parseInt((dv * 0.5).toFixed(1) + intMin),
        parseInt((dv * 0.6).toFixed(1) + intMin),
        parseInt((dv * 0.7).toFixed(1) + intMin),
        parseInt((dv * 0.8).toFixed(1) + intMin),
        parseInt((dv * 0.9).toFixed(1) + intMin),
        intMax
      ];
    }
  }, // computed
  watch: {
    powerHourLine() {
      this.populateHeatMap();
    },
    async getStartingPeriod() {
      await this.loadData();
    },
    async getEndingPeriod() {
      await this.loadData();
    },
    async mode() {
      this.loading = true;
      await this.loadData();
      this.populateHeatMap();
      this.loading = false;
    },
    async posID() {
      this.series = [];
      this.loading = true;
      await this.loadData();
      await this.populateHeatMap();
      this.loading = false;
    }
  },
  created: async function() {
    this.loading = true;
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('BoxTrafficPowerHourByLine.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('BoxTrafficPowerHourByLine.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('BoxTrafficPowerHourByLine.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };

    await this.loadData();
  }, //created
  methods: {
    ...mapActions('queue', ['fetchPowerHourDataPos']),
    async populateHeatMap() {
      let seriesName = [];
      if (this.powerHourLine && Object.values(this.powerHourLine).length > 0) {
        let dataList = [];
        this.series = Object.values(this.powerHourLine)
          .map((v, key) => {
            dataList = [...dataList, ...v.map(dat => dat.value)];
            return {
              name: this.$t(
                `BoxTrafficPowerHourByLine.${Object.keys(this.powerHourLine)[
                  key
                ].toLowerCase()}`
              ),
              data: v.map(dat => dat.value),
              formattedValue: v.map(dat => dat.formattedValue)
            };
          })
          .sort(this.daysOfWeekSorter);
        // .reverse();
        this.maxVal = parseInt(Math.max(...dataList)).toFixed(0);
        this.minVal = parseInt(Math.min(...dataList)).toFixed(0);
        this.median = (
          (parseInt(this.maxVal) + parseInt(this.minVal)) /
          2
        ).toFixed(0);

        this.formattedValuesList = [];
        let categories = [];
        if (this.powerHourLine) {
          categories = Object.keys(this.powerHourLine)
            .sort(
              (x, y) => this.daysOfWeek.indexOf(x) - this.daysOfWeek.indexOf(y)
            )
            .map(v => this.powerHourLine[v])[0]
            .map(v => v.hour);
        }
        this.series.map(v => {
          this.formattedValuesList = [
            ...this.formattedValuesList,
            v.formattedValue
          ];
        });
        if (
          this.powerHourLine &&
          Object.values(this.powerHourLine).length > 0
        ) {
          seriesName = Object.values(this.powerHourLine)[0].map(v => v.hour);
        }

        this.chartOptions = {
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: true,
              tools: {
                download: '<i class="fas fa-download"></i>'
              }
            }
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              shadesIntensity: 0,
              colorScale: {
                ranges: [
                  {
                    from: this.valueRanges[0],
                    to: this.valueRanges[1],
                    color: '#ebf2fa',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[1],
                    to: this.valueRanges[2],
                    color: '#d8e5f6',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[2],
                    to: this.valueRanges[3],
                    color: '#c5d7f1',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[3],
                    to: this.valueRanges[4],
                    color: '#b2cbed',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[4],
                    to: this.valueRanges[5],
                    color: '#9ebde8',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[5],
                    to: this.valueRanges[6],
                    color: '#8bb0e4',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[6],
                    to: this.valueRanges[7],
                    color: '#78a3df',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[7],
                    to: this.valueRanges[8],
                    color: '#6596db',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[8],
                    to: this.valueRanges[9],
                    color: '#5189d6',
                    name: undefined
                  },
                  {
                    from: this.valueRanges[9],
                    to: this.valueRanges[10],
                    color: '#3e7cd2',
                    name: undefined
                  }
                ]
              }
            }
          },
          legend: {
            show: false
          },
          yaxis: {
            reversed: true
          },
          dataLabels: {
            // enabled: true
            background: {
              enabled: true,
              foreColor: '#ff3046'
            },
            style: {
              fontSize: '14px',
              colors: ['#000000']
            },
            formatter: (val, series) => {
              return this.formattedValuesList[series.seriesIndex][
                series.dataPointIndex
              ];
            }
          },
          xaxis: {
            type: 'category',
            categories: [...categories]
          },
          noData: {
            text: 'No Data Available',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '14px',
              fontFamily: undefined
            }
          },
          colors: ['#3e7cd2'],
          title: {},
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return `
                  <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${
              w.globals.labels[dataPointIndex]
            }</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip">${
                         w.globals.seriesNames[seriesIndex]
                       } :</div>
                     <label class="text-bold">  ${
                       this.formattedValuesList[seriesIndex]
                         ? this.formattedValuesList[seriesIndex][dataPointIndex]
                         : 0
                     }</label></div>
                </q-card>
            `;
            }
          },
          responsive: [
            {
              breakpoint: 426,
              options: {
                dataLabels: {
                  enabled: false
                }
              }
            }
          ]
        };
      }
      this.loading = false;
    },
    async loadData() {
      await this.fetchPowerHourDataPos({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        kpis: 'all',
        posID: this.posID,
        floorID: this.floorID,
        storeCodes: this.storeID,
        daysOfWeek: this.getDaysOfWeekList,
        useCustomCalender: this.getCustomCalenderState,
        type: this.mode
      });
    },
    daysOfWeekSorter(x, y) {
      return this.daysOfWeek.indexOf(x.name) - this.daysOfWeek.indexOf(y.name);
    }
  }
};
