
import { mapMutations } from 'vuex';
export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    } // elevated
  }, // props

  data() {
    return {
      markedOptions: [],
      listState: true
    };
  }, // data
  computed: {
    options() {
      return [
        { label: this.$t('FilterDaysOfWeek.monday'), value: 'monday' },
        { label: this.$t('FilterDaysOfWeek.tuesday'), value: 'tuesday' },
        { label: this.$t('FilterDaysOfWeek.wednesday'), value: 'wednesday' },
        { label: this.$t('FilterDaysOfWeek.thursday'), value: 'thursday' },
        { label: this.$t('FilterDaysOfWeek.friday'), value: 'friday' },
        { label: this.$t('FilterDaysOfWeek.saturday'), value: 'saturday' },
        { label: this.$t('FilterDaysOfWeek.sunday'), value: 'sunday' }
      ];
    } // options
  }, // computed
  created() {
    // Set default selection
    this.markedOptions = [...new Set(this.selectedOptions)];
  }, // created
  methods: {
     ...mapMutations('filter', ['setDaysOfWeek']),
    /**
     * Fired when user click on 'All' checkbox
     */
    listStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markedOptions = this.options.map(option =>
          option.value.toLowerCase()
        );
      } else {
        // Unmark all checkboxes
        this.markedOptions = [];
      }
    }, // listStateInput
    /**
     * Fired when user click on any checkbox apart from 'All'
     */
    markedOptionsInput(newValue) {
      if (newValue.length === this.options.length) {
        // 'All' checkbox totally state
        this.listState = true;
      } else if (newValue.length === 0) {
        // 'All' checkbox empty state
        this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        this.listState = null;
      }
    }, // markedOptionsInput
    /**
     * Update selected options before closing the filter
     */
    submit() {
      // Updates the parent
      if (!this.markedOptions.length) {
        return;
      }
      this.$emit('update:selectedOptions', this.markedOptions);
      this.setDaysOfWeek({ daysOfWeek: this.markedOptions });
      // Hide the filter
      this.$refs.filterDaysOfWeek.hide();
    }, // submit
    /**
     * Closes the filter without saving selected options
     */
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterDaysOfWeek.hide();
    } // hide
  }
};
