
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      tab: ''
    };
  },
  computed: {
    ...mapState('home', ['comparisonTab'])
  },
  watch: {
    comparisonTab(newValue) {
      this.tab = newValue;
    }
  },
  created() {
    this.tab = this.comparisonTab;
  }, // data
  methods: {
    ...mapMutations('home', ['setComparisonTab']),
    changeTab(value) {
      this.setComparisonTab({ tab: value });
    }
  }
}; // export default
