
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';

import { UpdateStore } from '@/repositories/StoreRepository';
import { handleErrorUI } from '@/util/error';
import { StoreFloorPlan } from '@/models/Store';
import { validateMandatoryFields } from '@/util/validation';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      loading: true,
      isEdit: true,
      storeId: '',
      store: {}
    };
  },
  computed: {
    canSubmit() {
      this.$refs.storeForm.validate();
      return validateMandatoryFields(this.store, [
        'name',
        'storeNumber',
        'brandId',
        'storeTypeId',
        'status',
        'openingDate',
        'areaUnit',
        'area',
        'storeFloorPlan',
        'cityId',
        'storeBusinessHours'
      ]);
    }
  },
  async created() {
    this.loading = true;
    const storeId = this.$route.params.id;
    this.isEdit = this.$route.matched[0].meta.isEdit;
    try {
      const store = await this.$repo.store.getStore(storeId);
      if (!store.storeFloorPlan) {
        store.storeFloorPlan = {} as StoreFloorPlan;
      }
      this.storeId = store.id;
      this.store = store;
      this.store.selectedCountry = {
        id: store.countryId,
        country: store.country
      };
      this.store.selectedRegion = store.regionId;
      this.store.selectedDistrict = store.districtId;
      this.store.selectedPrefecture = store.prefectureId;
      this.store.selectedCity = store.cityId;
    } catch (err) {
      handleErrorUI(err);
      this.$router.push({ name: 'management:store' });
    }
    this.loading = false;
  },
  methods: {
    async submitStore() {
      if (this.canSubmit) {
        try {
          await this.$repo.store.updateStore(
            this.storeId,
            this.store as UpdateStore
          );
          this.$q.notify({
          message: this.$t('SettingsManagement.store_update'),
          type: 'positive',
          position: 'top',
          timeout: 3000
        });
          this.$router.push({ name: 'management:store' }).catch(_ => {});
        } catch (err) {
          handleErrorUI(err);
        }
      } else {
        this.$q.notify({
          type: 'negative',
          message: this.$t('Form.resolve_errors'),
          position: 'top'
        } as any);

        if (this.store.storeFloorPlanImages.length === 0) {
          this.$q.notify({
            type: 'negative',
            message: this.$t('AuthPage.image_required'),
            position: 'top'
          });
        }
      }
    }
  }
};
