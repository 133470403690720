
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { addMonths, addWeeks, startOfMonth, startOfWeek } from 'date-fns';
import { map } from 'lodash';
import { fetchOverview } from '../fetchData.ts';

export default {
  data() {
    return {
      kpiFormatted: {},
      selectOptionsKpi: [],
      loading: false,
      series: [],
      customTitles: [
        'Sales',
        'Capture Rate',
        'Conversion',
        'Average Purchase',
        'Passersby',
        'Traffic',
        'Transactions',
        'Average Item Price',
        'Store Dwell Time',
        // 'Facade Stops',
        'Staff Efficiency',
        'Return on Visit',
        'Sales Per Sqm',
        'Units Per Transaction',
        'Traffic Per Sqm',
        'Staff per hour',
        'Closing Strength',
        'Engagement Rate',
        'Bounce Rate'
      ],
      customSort: [
      this.$t('KPI.sls'),
        this.$t('KPI.aip'),
        this.$t('KPI.upt'),
        this.$t('KPI.ap'),
        this.$t('KPI.cn'),
        this.$t('KPI.trn'),
        this.$t('KPI.trf'),
        this.$t('KPI.pb'),
        this.$t('KPI.spt'),
        this.$t('KPI.tpt'),
        this.$t('KPI.cr'),
        this.$t('KPI.dw'),
        this.$t('KPI.er'),
        this.$t('KPI.br'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.sl'),
        this.$t('KPI.sh'),
        this.$t('KPI.cs'),
        this.$t('KPI.se'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      chartOptions: {
        chart: {
          type: 'radar',
          height: 460,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },

        colors: ['#75ABF7', '#F7B500'],
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top',
          offsetY: 20,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        },
        stroke: {
          width: 2
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
                  <q-card class="tooltip-container flex column q-pa-md">
                     <label>${w.globals.labels[dataPointIndex]}</label>
                     <div class="tooltip-title flex items-center">
                      <div class="tooltip-title-color" style="background:${
                        w.globals.colors[seriesIndex]
                      }"></div>
                    <label>${w.globals.seriesNames[seriesIndex]} :</label>
                    <label class="q-ml-xs text-bold"> ${this.getRadarValue(
                      series[seriesIndex][dataPointIndex],
                      seriesIndex,
                      dataPointIndex
                    )}
                    </label>
                    </div>
                </q-card>
            `;
          },
          y: {
            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
              if (seriesIndex === 0) {
                return this.data.comparedKPIValues[dataPointIndex]
                  .formattedValue;
              } else if (seriesIndex === 1) {
                return this.data.selectedKPIValues[dataPointIndex]
                  .formattedValue;
              }
              return value;
            }
          }
        },
        fill: {
          opacity: 0
        },
        markers: {
          size: 3
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: val => {
              return val + '%';
            }
          }
        }
      }
    }; // chartOptions
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared'
    ]), // mapGetters
    ...mapState('distribution', { data: 'byPeriod' }),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('overview', { kpis: 'kpis' }),
    ...mapState('filter', ['radarChartKpiOptions']),
    ...mapState('periodicReport', ['selectedStoreCode', 'monthlyTarget', 'weeklyRadarData']),
    ...mapState('user', ['kpiPreferences'])
  },
  watch: {
    selectOptionsKpi: {
      handler: 'loadData',
      immediate: true
    },
    selectedKPIOptions() {
      this.selectOptionsKpi = this.selectedKPIOptions;
    },
    selectedStoreCode: {
      handler: 'loadData',
      immediate: true
    }
  }, // computed
  created() {
    if (this.selectedStoreCode !== '') {
      this.selectOptionsKpi = this.selectedKPIOptions;
      this.loadData();
    }
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByPeriod']),
    ...mapActions('overview', ['fetchOverview']),
    ...mapMutations('periodicReport', ['setMonthlyLoading']),
    kpisorter(x, y) {
      return (
        this.kpiPreferences.find(k => k.metricKey === x.metricID).order -
        this.kpiPreferences.find(k => k.metricKey === y.metricID).order
      );
    },
    getRadarValue(value, seriesIndex, dataPointIndex) {
      if (seriesIndex === 1) {
        return this.data.comparedKPIValues[dataPointIndex].formattedValue;
      } else if (seriesIndex === 0) {
        return this.data.selectedKPIValues[dataPointIndex].formattedValue;
      }
      return value;
    },
 async loadData(newValue, oldValue) {
      try {
        this.series = []; // Will reset the chart
        this.loading = true;
        this.setMonthlyLoading({ component: 'monthlyRadar', value: true });
        this.series = []; // Will reset the chart
        this.loading = true;

        var arr = [...this.selectedKPIOptions];
        var index = arr.indexOf('targetAchive');
        if (index !== -1) {
          arr[index] = 'sales';
        }
      let uniqueArray = [...new Set(arr)];
      const kpiOverview = await fetchOverview({
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared,
        storeCodes: this.selectedStoreCode,
        kpis: uniqueArray.join(','),
        daysOfWeek: this.getDaysOfWeekList,
        groupByMetricKey: true
      });
        var selected = kpiOverview.map(item => {
          let inti = item.value.replaceAll("¥",'')
          return {
            "metricKey": item.metricKey,
            "value": parseInt(inti.replaceAll(",",'')),
            "formattedValue": item.value,
            "metricID": item.metricID,
          }
        })
        var selectedC = kpiOverview.map(item => {
          let inti = item.comparedValue.replaceAll("¥",'')
          return {
            "metricKey": item.metricKey,
            "value": parseInt(inti.replaceAll(",",'')),
            "formattedValue": item.comparedValue,
            "metricID": item.metricID,
          }
        })
        var payload = {
          selectedKPIValues:selected.filter(el => uniqueArray.includes(el.metricID)),
          comparedKPIValues:selectedC.filter(el => uniqueArray.includes(el.metricID)),
        }
        this.fetchDistributionByPeriod(payload);
        // block
        // await this.fetchOverview({
        //   startDate: this.getPulseStartingPeriod,
        //   endDate: this.getPulseEndingPeriod,
        //   compareStartDate: this.getPulseStartingCompared,
        //   compareEndDate: this.getPulseEndingCompared,
        //   storeCodes: this.selectedStoreCode,
        //   daysOfWeek: this.getDaysOfWeekList,
        //   kpis: this.selectedKPIOptions.join(',')
        // });

        // await this.fetchDistributionByPeriod({
        //   startDate: this.getPulseStartingPeriod,
        //   endDate: this.getPulseEndingPeriod,
        //   compareStartDate: this.getPulseStartingCompared,
        //   compareEndDate: this.getPulseEndingCompared,
        //   storeCodes: this.selectedStoreCode,
        //   daysOfWeek: this.getDaysOfWeekList,
        //   kpis: this.selectedKPIOptions.join(',')
        // });

        // block
        var targetAchive = this.weeklyRadarData;
        if(Object.keys(targetAchive).length){
          this.data.selectedKPIValues = [
          ...this.data.selectedKPIValues,
          {
            formattedValue: targetAchive.value,
            metricID: 'tra',
            metricKey: targetAchive.metricKey,
            value: parseInt(targetAchive.value.replace('%', ''))
          }
        ];
        this.data.comparedKPIValues = [
          ...this.data.comparedKPIValues,
          {
            formattedValue: targetAchive.comparedValue,
            metricID: 'tra',
            metricKey: targetAchive.metricKey,
            value: parseInt(targetAchive.comparedValue.replace('%', ''))
          }
        ];
        }
        // block
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: [
              ...this.data.selectedKPIValues
                .sort(this.kpisorter)
                .map(
                  kpi =>
                    this.kpiPreferences.find(k => k.metricKey === kpi.metricID)
                      .title
                )
            ]
          }
        };
          this.chartOptions.chart.toolbar = {
             export: {
              svg: {
                filename: `${this.$t('RadarChart.radar')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              },
              png: {
                filename: `${this.$t('RadarChart.radar')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              },
              csv: {
                filename: `${this.$t('RadarChart.radar')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              }
            }
           
          };

        this.series = [];
        const dataSorted = {
          selectedKPIValues: this.data.selectedKPIValues.sort(this.kpisorter),
          comparedKPIValues: this.data.comparedKPIValues.sort(this.kpisorter)
        };
        this.series.push({
          name: this.$t('selected_period'),
          data: dataSorted.selectedKPIValues.map(
            (kpi, index) =>
             {
                const selectedValue = kpi.value;
                const comparedValue = dataSorted.comparedKPIValues[index].value;
                if (selectedValue !== null && comparedValue !== null && comparedValue !== 0) {
                  return (kpi.value / comparedValue) * 100;
                } else {
                  return 0; // or any other value that represents the absence of data
                }
            }
            
            //  (kpi.value / dataSorted.comparedKPIValues[index].value) * 100
          )
        });
        this.series.push({
          name: this.$t('compared_period'),
          data: dataSorted.comparedKPIValues
            .sort(this.kpisorter)
            .map(
              (kpi, index) =>
              {
                const selectedValue = kpi.value;
                const comparedValue = dataSorted.selectedKPIValues[index].value;
                if (selectedValue !== null && comparedValue !== null && comparedValue !== 0) {
                  return (kpi.value / comparedValue) * 100;
                } else {
                  return 0; // or any other value that represents the absence of data
                }
              }
//                (kpi.value / dataSorted.selectedKPIValues[index].value) * 100
            )
        });
      } catch (error) {
        // this.$q.notify({
        //   type: 'negative',
        //   position: 'top',
        //   message: `${error}`
        // });
      }
      this.loading = false;
      this.setMonthlyLoading({ component: 'monthlyRadar', value: false });
    } // loadData
  } // methods
}; // export default
