
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('overview', {
      data: 'traffic'
    })
  } // computed
}; // export default
