const initialState = {
  comparisonTab: 'store',
  placeText:{},
};

export default {
  state: initialState,
  mutations: {
    setComparisonTab(state, { tab }) {
      state.comparisonTab = tab;
      localStorage.removeItem('tabComparison');
      localStorage.setItem('tabComparison', tab);
    },
    setPlaceHolder(state, payload) {
      state.placeText = payload;
    },
  }
};
