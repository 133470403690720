import axios from "axios"
import store from '@/store';
import { SSOPutRequest2, SSOPostRequest2, SSOPutRequest, SSODeleteRequest2, SSOGetRequest2, SSOPostReq } from '@/util/kpis';
export default {
  // getBrands() {
  //   return axios.get(store.state?.baseKpiUrl+'/api/getStoreBrands?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token 
  //     },
  //   })
  // },
  // createBrands(brandData) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "brands": brandData,
  //   };
  //   return axios.post(store.state?.baseKpiUrl+'/api/createStoreBrand', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token 
  //     },
  //   })
  // },
  // updateBrand(brandData) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "brandId": brandData.brandId,
  //     "title": brandData.title,
  //     "description": brandData.description,
  //   };
  //   return axios.put(store.state?.baseKpiUrl+'/api/updateStoreBrand', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token 
  //     },
  //   })
  // },
  // deleteBrand(brandId) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "brandId": brandId,
  //   };
  //   return axios.delete(store.state?.baseKpiUrl+'/api/deleteStoreBrand', {
  //     data: userData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async getBrands() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
    }
    const apiResponse = await SSOGetRequest2(
      '/api/getStoreBrands',
      {
        params: paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async createBrands(brandData) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "brands": brandData,
    };
    const apiResponse = await SSOPostRequest2(
      '/api/createStoreBrand', paramsData,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },

  async updateBrand(brandData) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "brandId": brandData.brandId,
      "title": brandData.title,
      "description": brandData.description,
    };
    const apiResponse = await SSOPutRequest2(
      '/api/updateStoreBrand', paramsData,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async deleteBrand(brandId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "brandId": brandId,
    };
    const apiResponse = await SSODeleteRequest2(
      '/api/deleteStoreBrand',
      {
        data:paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
}

