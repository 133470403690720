
import { useStoreOption } from './useStore';
import { mapActions, mapState, mapMutations } from 'vuex';
import moment from 'moment'
export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    additionaloption: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      markedOptions: [],
      selectedOptions: null,
      searchResult: [],
      storeOption: [],
      selectAllStore: false,
      selectedStorePulse: null
    };
  },
  computed: {
    ...mapState('periodicReport', ['selectedStoreCode', 'hourlyDate']),
    ...mapState(['storeList'])
  },
  watch: {
    selectedStoreCode() {
      this.selectedStorePulse = this.selectedStoreCode;
      // this.selectedCities([{cityId:this.selectedOptions.cityId}])
    },
    search(keyword) {
      const searchedStore = keyword
        ? this.storeOption.filter(
            option =>
              option.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
          )
        : this.storeOption;
      this.searchResult = [...searchedStore];
    },
    selectedOptions(newValue) {
      if (
        newValue === 'All' ||
        newValue.value === 'All' ||
        newValue === 'すべて' ||
        newValue.value === 'すべて'
      ) {
        //send the list of all 
        const allStores = this.storeOption
          .filter(store => store.value !== 'all') // Exclude elements with value 'all'
          .map(store => store.value)
          .join(',');
        
        this.setStorePulse(allStores);
      } else {
        this.setSelectedStorePulse(this.selectedOptions);
        this.setStorePulse(newValue.value);
      }
    }
  },
  async created() {

 var storeList = [];
    if (!this.storeList) {
      storeList = await this.fetchStoreList();
    }
    else
     storeList = this.storeList;//await useStoreOption();

    let storeOptionList = [
    ...new Map(storeList.map((item) => [item["value"], item])).values(),
    ];
    if (this.additionaloption !== '') {
      this.storeOption = [
        { label: this.additionaloption, value: this.additionaloption },
        ...storeOptionList
      ];
    } else {
      this.storeOption = [...storeOptionList];
    }
    this.searchResult = [...storeOptionList];

    if (this.additionaloption !== '') {
      this.selectedOptions = this.additionaloption;
    } else {
      this.selectedOptions =
        this.selectedStoreCode && this.selectedStoreCode !== 'all'
          ? storeOptionList.filter(
              store => store.value === this.selectedStoreCode
            )[0]
          : storeOptionList[0];
    }

    if(this.selectedOptions == null)
    {
      this.selectedOptions = storeList[0];
    }
   
    this.setSelectedStorePulse(this.selectedOptions);
    this.setStorePulse(this.selectedOptions.value);
  },
  methods: {
    ...mapActions("user",['fetchStoreList']),
    ...mapMutations('periodicReport', [
      'setStorePulse',
      'setSelectedStorePulse'
    ]),

     filterFn(val, update) {
      val
        ? update(
            () =>
              (this.searchResult = this.storeOption.filter(
                v => v.label.toLowerCase().indexOf(val.toLowerCase()) > -1
              ))
          )
        : update(() => (this.searchResult = [...this.storeOption]));
    }
  }
};
