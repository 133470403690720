
import ButtonDropdown from '@/components/Common/ButtonDropdown';
interface Option {
  label: string;
  value: any;
}

export default {
  components: {
    ButtonDropdown
  },
  props: {
    label: { type: String, default: '' },
    value: { default: () => {}, type: Object },
    options: { type: Array, default: () => [] }
  },
  computed: {
    labelCounter() {
      const count = this.options ? this.options.length : 0;
      return `${this.label} (${count})`;
    }
  }
};
