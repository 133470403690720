
import { createColumn } from '@/util/table';
import { colors } from '@/styles/management';
import CommonMixin from '@/mixins/CommonMixin';
import { mapState } from 'vuex';
import { checkPermission } from '@/api/utils';

const createColumns = noAction =>
  [
    createColumn('title', this.$t('SettingsManagement.roleName')),
    createColumn('description', this.$t('SettingsManagement.description')),
    createColumn('action', this.$t('SettingsManagement.action'))
  ].filter(Boolean);

export default {
  mixins: [CommonMixin],
  props: {
    data: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    noAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { noAction } = this;
    return {
      searchKeyword: ['', ''],
      columns: [
        createColumn('title', this.$t('SettingsManagement.roleName')),
        createColumn('description', this.$t('SettingsManagement.description')),
        createColumn('action', this.$t('SettingsManagement.actions'))
      ].filter(Boolean),
      rowData: [],
      colors
    };
  },
  watch: {
    data() {
      //this.rowData = this.data;

      this.rowData = this.data.map(dat => {
        if (dat['title'].toUpperCase() == 'FlowTenantAdmin'.toUpperCase()) {
          dat['title'] = this.$t('SettingsManagement.Administrativerole');
          dat['description'] = this.$t(
            'SettingsManagement.AdministrativeroleDesc'
          );
        }
        return {
          _id: dat['_id'],
          title: dat['title'],
          description: dat['description'],
          permissions: dat['permissions'],
          config: dat['config']
        };
      });
    },
    searchKeyword() {
      let obj = {
        _id: this.searchKeyword[0],
        title: this.searchKeyword[1],
        description: this.searchKeyword[2]
      };
      let tbData = Object.values(this.data);
      let filtered = tbData.map((dat, key) => {
        return {
          _id: dat['_id'],
          title: dat['title'],
          description: dat['description']
        };
      });
      this.rowData = filtered.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    }
  }
};
