import Customer, { CustomerDTO } from '../Customer';

export const convertFromCustomerDTO = (dto: CustomerDTO): Customer => ({
  id: dto.id,
  email: dto.email,
  firstname: dto.firstname,
  lastname: dto.lastname,
  phone: dto.phone,
  companyName: dto.tenant.companyName
});
