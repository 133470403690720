var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DetailCard',{attrs:{"no-center":"","title":_vm.$t('SettingsManagement.review_default_settings')},scopedSlots:_vm._u([{key:"right-header",fn:function(){return [_c('div',[_c('q-btn-group',{staticClass:"button-group",attrs:{"flat":""}},[(_vm.viewAllowed('FlowDefaultSettingsView'))?_c('q-btn',{attrs:{"no-caps":"","color":_vm.activeTab === _vm.flags.TAB_REVIEW ? 'secondary' : null,"text-color":_vm.activeTab !== _vm.flags.TAB_REVIEW ? 'secondary' : null,"label":_vm.$t('SettingsManagement.review'),"icon":"fas fa-check"},on:{"click":function($event){_vm.activeTab = _vm.flags.TAB_REVIEW}}}):_vm._e(),(_vm.viewAllowed('FlowDefaultSettingsEdit'))?_c('q-btn',{attrs:{"no-caps":"","color":_vm.activeTab === _vm.flags.TAB_EDIT ? 'secondary' : null,"text-color":_vm.activeTab !== _vm.flags.TAB_EDIT ? 'secondary' : null,"label":_vm.$t('SettingsManagement.edit'),"icon":"fas fa-pencil-alt"},on:{"click":function($event){_vm.activeTab = _vm.flags.TAB_EDIT}}}):_vm._e()],1)],1)]},proxy:true}])},[[_c('div',{staticStyle:{"margin-bottom":"32px"}},[_vm._v(" "+_vm._s(_vm.$t('SettingsManagement.default_settings_description'))+" ")]),(
        _vm.activeTab === _vm.flags.TAB_REVIEW &&
          _vm.viewAllowed('FlowDefaultSettingsView')
      )?_c('ReviewTab',{attrs:{"data":_vm.data}}):_vm._e(),(
        _vm.activeTab === _vm.flags.TAB_EDIT &&
          _vm.viewAllowed('FlowDefaultSettingsEdit')
      )?_c('EditTab',{model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }