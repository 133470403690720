import { BasePaymentCardDTO, PaymentCardDTO } from '@/models/Payment';

import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError, createDeleteBackendError } from '@/util/error';
import { PaginationRequest } from '@/util/pagination';

export type GetPaymentCardsResponse = PaginatedResponse<PaymentCardDTO>;

export interface CreatePaymentCardRequest extends BasePaymentCardDTO {
  tenant: {
    id: string;
  };
}

export type CreatePaymentCardResponse = PaymentCardDTO;

export default class PaymentController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getPaymentCards(
    tenantId: string,
    pagination: PaginationRequest
  ) {
    try {
      const { data } = await this.client.get(
        `/console/payment/card/${tenantId}/tenant`,
        {
          params: {
            ...pagination
          }
        }
      );
      return data as GetPaymentCardsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createSetupIntent(tenantId: string) {
    try {
      const { data } = await this.client.post(
        `/console/payment/card/${tenantId}/setup-intent`
      );
      return data as string;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createPaymentCard(req: CreatePaymentCardRequest) {
    try {
      const { data } = await this.client.post('/console/payment/card', req);
      return data as CreatePaymentCardResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deletePayment(id: string) {
    try {
      const data = await this.client.delete('/console/payment/card', {
        data: id
      });

      createDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
