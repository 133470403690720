
import { createColumn } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import { mapState } from 'vuex';
import { checkPermission } from '@/api/utils';

export default {
  mixins: [CommonMixin],
  props: {
    data: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    noAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { noAction } = this;
    return {
      searchKeyword: ['', '', '', ''],
      columns: [
        createColumn('fullName', this.$t('SettingsManagement.employeeName')),
        createColumn('username', this.$t('SettingsManagement.username')),
        createColumn('email', this.$t('SettingsManagement.email')),
        createColumn('roleName', this.$t('SettingsManagement.roleName')),
        createColumn('status', this.$t('SettingsManagement.status'), {
          format: val => this.$t('SettingsManagement.' + val.toLowerCase())
        }),
        createColumn('actions', this.$t('SettingsManagement.actions'))
      ].filter(Boolean),
      rowData: []
    };
  },
  computed: {
    ...mapState('user', ['defaultSettings']),
    isUserAdmin() {
      return true; // this.defaultSettings.user.roles[0].roleType === 'ADMIN';
    }
  },
  watch: {
    data(val) {
      this.rowData = val;
    },
    searchKeyword() {
      let obj = {
        fullName: this.searchKeyword[0],
        username: this.searchKeyword[1],
        email: this.searchKeyword[2],
        roleName: this.searchKeyword[3],
        status: this.searchKeyword[4]
      };
      let tbData = Object.values(this.data);
      let filtered = tbData.map((dat, key) => {
        return {
          _id: dat['_id'],
          fullName: dat['fullName'],
          username: dat['username'],
          email: dat['email'],
          roleName: dat['roleName'],
          status: dat['status']
        };
      });
      this.rowData = filtered.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  },
  methods: {
    canDelete(role) {
      if (this.isUserAdmin) {
        return true;
      } else if (role !== 'ADMIN') {
        return true;
      } else {
        return false;
      }
    },
    viewAllowed(name) {
      return checkPermission(name);
    },
    isAdmin: userRoles => {
      if (userRoles.length === 0) return false;
      const roles = userRoles.find(userRole => userRole.roleType === 'ADMIN');
      return !!roles;
    }
  }
};
