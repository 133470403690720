import { convertFromPeerGroupDTO } from '@/models/formatters/PeerGroupFormatter';
import PeerGroup from '@/models/PeerGroup';
import PeerGroupController from '@/services/settings/PeerGroupController';
import map from 'lodash/map';

export interface GetPeerGroupsResult {
  peerGroups: PeerGroup[];
}

export default class PeerGroupRepository {
  private peerGroupController: PeerGroupController;

  constructor(peerGroupController: PeerGroupController) {
    this.peerGroupController = peerGroupController;
  }

  public async getPeerGroups() {
    const res = await this.peerGroupController.getPeerGroups();
    return {
      peerGroups: map(res.content, dto => convertFromPeerGroupDTO(dto))
    };
  }

  public async getPeerGroup(id: string) {
    const dto = await this.peerGroupController.getPeerGroup(id);
    return convertFromPeerGroupDTO(dto);
  }

  public async upsertPeerGroup(peerGroup: PeerGroup, storeIds: string[]) {
    if (peerGroup.id) {
      await this.updatePeerGroup(
        peerGroup.id,
        peerGroup.peergroupid,
        peerGroup.title,
        storeIds
      );
    } else {
      await this.createPeerGroup(peerGroup.title, storeIds);
    }
  }

  public async deletePeerGroup(id: string, msg: string) {
    await this.peerGroupController.deletePeerGroup(id, msg);
  }

  private async createPeerGroup(name: string, storeIds: string[]) {
    await this.peerGroupController.createPeerGroup({ name, storeIds });
  }

  private async updatePeerGroup(
    id: string,
    peergroupid: Number,
    name: string,
    storeIds: string[]
  ) {
    await this.peerGroupController.updatePeerGroup(id, {
      name,
      peergroupid,
      storeIds
    });
  }
}
