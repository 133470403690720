import {
  convertFromProductDTO,
  convertToProductDTO
} from '@/models/formatters/ProductFormatter';
import Product from '@/models/Product';
import ProductController from '@/services/management/ProductController';
import { map } from 'lodash';

export interface GetProducts {
  products: Product[];
}

export default class ProductRepository {
  private readonly productController: ProductController;

  constructor(productController: ProductController) {
    this.productController = productController;
  }

  public async getProducts() {
    const { content } = await this.productController.getProducts();
    return {
      products: map(content, dto => convertFromProductDTO(dto))
    } as GetProducts;
  }

  public async getProduct(id: string) {
    return convertFromProductDTO(await this.productController.getProduct(id));
  }

  public async upsertProduct(product: Product) {
    const dto = convertToProductDTO(product);
    if (product.id) {
      return this.productController.updateProduct(product.id, dto);
    } else {
      return this.productController.createProduct(dto);
    }
  }

  public getProductTypes() {
    return this.productController.getProductTypes();
  }

  public uploadProductImage(file: File) {
    return this.productController.uploadProductImage(file);
  }

  public downloadProductImage(path: string) {
    return this.productController.downloadProductImage(path);
  }
}
