
export default {
  props: {
    order: { default: () => ({}), type: Object }
  },
  computed: {
    installationDate() {
      if (this.order == null || this.order.installationDate == null) return '';
      const date = new Date(this.order.installationDate);
      return date.toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    }
  }
};
