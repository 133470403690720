import { UserFormModel } from '@/repositories/AuthRepository';

export const createData = (email: string): UserFormModel => ({
  email,
  firstName: '',
  lastName: '',
  username: '',
  phone: {
    countryCode: '+81',
    phoneNumber: ''
  },
  password: '',
  confirmPassword: ''
});

export const createI18n = () => ({
  firstName: 'First Name',
  lastName: 'Last Name',
  username: 'Username',
  phone: 'Phone',
  password: 'Password',
  confirmPassword: 'Confirm Password'
});
