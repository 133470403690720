
import kpi from "@/services/kpi";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedKpi"],
  // props: {
  //   selectedKpi: {
  //     type: Object,
  //     default: null
  //   }
  // },
  data() {
    return {
      configuration: true,
      name: "",
      variationUnit: "",
      dialogtitle: "",
      japaneseUnit: "",
      englishUnit: "",
      shortJapaneseName: "",
      japaneseName: "",
      shortEnglishName: "",
      englishName: "",
      metric: [],
      metricOptions: [
        { label: "Single", value: "single" },
        { label: "Multiple", value: "multiple" },
      ],
      category: [],
      categoryOptions: [
        { label: "Staff", value: "staff" },
        { label: "Manager", value: "manager" },
        { label: "Sales", value: "sales" },
      ],
    };
  },
  watch: {
    configuration(newValue){
      if(!newValue){
        this.$emit("closeModel", false);
      }
    }
  },
  mounted() {
    if (this.selectedKpi) {
      this.dialogtitle = "Edit KPI";
      // this.name = this.selectedKpi.name
      // this.variationUnit = this.selectedKpi.variationUnit
      this.japaneseUnit = this.selectedKpi.unit.ja;
      this.englishUnit = this.selectedKpi.unit.en;
      this.shortJapaneseName = this.selectedKpi.explanation.ja;
      this.japaneseName = this.selectedKpi.title.ja;
      this.shortEnglishName = this.selectedKpi.explanation.en;
      this.englishName = this.selectedKpi.title.en;
      // this.metric = this.selectedKpi.metric
      // this.category = this.selectedKpi.kpiType
    } else {
      this.dialogtitle = "Add KPI";
    }
  },
  methods: {
    createKpi() {
      if (this.selectedKpi) {
        let kpiData = {
          kpiId: this.selectedKpi._id,
          name: this.name,
          // variationUnit: this.variationUnit,
          japaneseUnit: this.japaneseUnit,
          englishUnit: this.englishUnit,
          shortJapaneseName: this.shortJapaneseName,
          japaneseName: this.japaneseName,
          shortEnglishName: this.shortEnglishName,
          englishName: this.englishName,
          // metric: this.metric.value,
          // category: this.category.value,
        };
        kpi
          .updateKpiDefination(kpiData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.$emit("updateKpiDefination");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.$emit("closeModel", false);
          });
      } else {
        let kpiData = {
          name: this.name,
          // variationUnit: this.variationUnit,
          japaneseUnit: this.japaneseUnit,
          englishUnit: this.englishUnit,
          shortJapaneseName: this.shortJapaneseName,
          japaneseName: this.japaneseName,
          shortEnglishName: this.shortEnglishName,
          englishName: this.englishName,
          // metric: this.metric.value,
          // category: this.category.value,
        };
        kpi
          .createKpiDefination(kpiData)
          .then(() => {
            this.$q.notify({
              message: "KPI defination Created Successfully",
              color: "green",
            });
            this.$emit("updateKpiDefination");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.$emit("closeModel", false);
          });
      }
    },
  },
};
