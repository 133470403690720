
export default {
  props: {
    title: { type: String, required: true },
    category: {
      type: String,
      default: 'SettingsManagement.management'
    }
  }
};
