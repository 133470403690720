
import { fromPairs } from 'lodash';
import { createColumn } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    show: { type: Boolean, default: false },
    initialValue: { type: Object, default: null }
  },
  data() {
    return {
      id: null,
      templateName: '',
      uploadedTemplate: null,
      data: [],
      attributes: [],
      loading: false,
      columns: [
        createColumn('column', 'Source Column', {
          sortable: false
        }),
        createColumn('attribute', 'Sales Template', {
          sortable: false
        }),
        createColumn('description', 'Description', {
          sortable: false
        })
      ]
    };
  },
  computed: {
    templateFilename() {
      if (this.uploadedTemplate == null) return '';
      return this.uploadedTemplate.fileName;
    },
    canClose() {
      return !this.loading;
    },
    canSubmit() {
      if (this.loading) return false;
      return !(
        (this.templateName || '') === '' || (this.templateFilename || '') === ''
      );
    }
  },
  watch: {
    show(show) {
      if (show) {
        this.id = null;
        this.templateName = '';
        this.uploadedTemplate = null;
        this.attributes = [];
        this.data.forEach(item => (item.columnMapping = null));

        this.processInitialValue();
      }
    }
  },
  async mounted() {
    await this.getTemplateMaster();
  },
  methods: {
    close() {
      if (this.canClose) {
        this.$emit('close');
      }
    },
    setColumnMapping(row, value) {
      this.data.forEach(item => {
        if (item.columnMapping !== value) return;
        item.columnMapping = null;
      });
      row.columnMapping = value;
    },
    async submit() {
      if (!this.canSubmit) return;
      this.loading = true;
      try {
        if (this.id == null) {
          await this.createSalesTemplateMapping();
        } else {
          await this.updateSalesTemplateMapping();
        }
        this.loading = false;
        this.$emit('saved');
        this.$emit('close');
      } catch (err) {
        handleErrorUI(err);
      }
    },
    async createSalesTemplateMapping() {
      const mappingList = this.data.map(item => {
        return [item.attribute, item.columnMapping];
      });
      const mapping = fromPairs(mappingList);
      await this.$repo.extension.saveSalesTemplateMapping(
        this.templateName,
        this.uploadedTemplate.fileName,
        this.uploadedTemplate.filePath,
        mapping
      );
      handleSuccessUI(
        `Sales template mapping ${this.templateName} saved successfully`
      );
    },
    async updateSalesTemplateMapping() {
      const mappingList = this.data.map(item => {
        return [item.attribute, item.columnMapping];
      });
      const mapping = fromPairs(mappingList);
      await this.$repo.extension.updateSalesTemplateMapping(
        this.id,
        this.templateName,
        this.uploadedTemplate.fileName,
        this.uploadedTemplate.filePath,
        mapping
      );
      handleSuccessUI(
        `Sales template mapping ${this.templateName} updated successfully`
      );
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (const file of files) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      this.loading = true;
      try {
        const data = await this.$repo.extension.uploadSalesTemplate(file);
        this.uploadedTemplate = data;
        const attributes = data.attributes.map(attr => ({
          value: attr,
          label: attr
        }));

        this.data.forEach(item => (item.columnMapping = null));
        this.attributes = [{ value: null, label: '(None)' }, ...attributes];

        this.loading = false;
      } catch (err) {
        handleErrorUI(err);
      }
    },
    async getTemplateMaster() {
      const data = await this.$repo.extension.getSalesTemplateMaster();
      this.data = data.map(item => ({
        ...item,
        columnMapping: null
      }));
    },
    processInitialValue() {
      if (this.initialValue != null) {
        this.id = this.initialValue.id;
        this.uploadedTemplate = {
          fileName: this.initialValue.fileName,
          filePath: this.initialValue.filePath
        };
        this.templateName = this.initialValue.mappingName;
        this.attributes = Object.values(this.initialValue.mapping);
        Object.keys(this.initialValue.mapping).forEach(key => {
          const item = this.data.find(item => item.attribute === key);
          if (item != null) {
            item.columnMapping = this.initialValue.mapping[key];
          }
        });
      }
    }
  }
};
