import Company, { BaseCompanyDTO, CompanyDTO } from '../Company';
import {
  convertFromContactDTO,
  convertToContactDTO
} from '@/models/formatters/ContactFormatter';

import { City } from '@/models/Location';
import map from 'lodash/map';

export function convertFromCompanyDTO(dto: CompanyDTO): Company {
  let city: Partial<City> = { id: null, name: null, prefecture: null };
  let prefecture = { id: null, name: null, district: null };
  let district = { id: null, name: null, region: null };
  let region = { id: null, name: null, country: null };
  let country = { id: null, name: null };
  if (dto.city != null) {
    city = dto.city;
  }
  if (city.prefecture != null) {
    prefecture = dto.city.prefecture;
  }
  if (prefecture.district != null) {
    district = prefecture.district;
  }
  if (district.region != null) {
    region = district.region;
  }
  if (region.country != null) {
    country = region.country;
  }
  return {
    id: dto.id,
    name: dto.name,
    postalCode: dto.zipcode,
    cityId: city.id,
    city: city.name,
    districtId: district.id,
    district: district.name,
    prefectureId: prefecture.id,
    prefecture: prefecture.name,
    regionId: region.id,
    region: region.name,
    countryId: country.id,
    country: country.name,
    address: dto.addressStreet,
    contacts: map(dto.contacts, contactDto =>
      convertFromContactDTO(contactDto)
    ),
    totalStores: dto.totalStores,
    businessTypeId: dto.businessType ? dto.businessType.id : null,
    businessType: dto.businessType ? dto.businessType.name : null,
    browserHq: dto.browserHq,
    browserStore: dto.browserStore,
    osId: dto.os ? dto.os.id : null,
    os: dto.os ? dto.os.name : null,
    industryId: dto.industry ? dto.industry.id : null,
    industryName: dto.industry ? dto.industry.name : null,
    registrationNumber: dto.registrationNumber ? dto.registrationNumber : ''
  };
}

export function convertFromCompanyDTOSSO({
  tenant,
  businessContacts,
  operationContacts
}) {
  const dto = tenant;
  let city = null;
  let prefecture = null;
  let district = null;
  let region = null;
  let country = null;
  if (dto.tenantInfo?.city) {
    city = dto.tenantInfo.city;
  }
  if (dto.tenantInfo?.prefecture != null) {
    prefecture = dto.tenantInfo.prefecture;
  }
  if (dto.tenantInfo?.district != null) {
    district = dto.tenantInfo.district;
  }
  if (dto.tenantInfo?.region != null) {
    region = dto.tenantInfo.region;
  }
  if (dto.tenantInfo?.country != null) {
    country = dto.tenantInfo.country;
  }
  return {
    id: dto._id,
    name: dto.intercom.companyName,
    postalCode: dto.tenantInfo?.postalCode,
    cityId: city,
    city: city,
    districtId: district,
    district: district,
    prefectureId: prefecture,
    prefecture: prefecture,
    regionId: region,
    region: region,
    countryId: country,
    country: country,
    address: dto.tenantInfo?.address,
    contacts: [
      ...map(businessContacts, contactDto =>
        convertFromContactDTO({
          ...contactDto,
          phone: contactDto.phoneNumber,
          type: 'BUSINESS'
        })
      ),
      ...map(operationContacts, contactDto =>
        convertFromContactDTO({
          ...contactDto,
          phone: contactDto.phoneNumber,
          type: 'OPERATION'
        })
      )
    ]
  };
}

export const convertToCompanyDTO = (company: Company): BaseCompanyDTO => ({
  id: company.id,
  name: company.name,
  phone:
    company.contacts?.length > 0
      ? `${company.contacts[0].phone.countryCode} ${company.contacts[0].phone.phoneNumber}`
      : '',
  addressStreet: company.address,
  browserHq: company.browserHq,
  browserStore: company.browserStore,
  totalStores: company.totalStores,
  zipcode: company.postalCode,
  contacts: map(company.contacts, contact => convertToContactDTO(contact)),
  city: {
    id: company.cityId,
    city: company.city
  },
  businessType: {
    id: company.businessTypeId,
    name: company.businessType
  },
  os: {
    id: company.osId,
    name: company.os
  },
  industry: {
    id: company.industryId,
    name: company.industryName
  },
  registrationNumber: company.registrationNumber
});
