
import CommonMixin from '@/mixins/CommonMixin';
import LocationFormMixin from '@/mixins/LocationFormMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import Company from '@/models/Company';
import { entityOptions } from '@/util/select';
import { handleErrorUI } from '@/util/error';
import { validateMandatoryFields } from '@/util/validation';
import countryCodes from 'country-codes-list';
import { downloadAsBlob, getMimeFromFilename } from '@/util/file';
import map from 'lodash/map';
import { checkPermission } from '@/api/utils';

export default {
  mixins: [CommonMixin, FormMixin, LocationFormMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }, // props
  data() {
    return {
      data: {
        name: '',
        postalCode: '',
        address: '',
        cityId: ''
      },
      businessContacts: [],
      operationContacts: [],
      callingCodeOptions: [
        {
          label: '+81',
          value: '+81'
        }
      ],
      loading: true
    };
  },
  computed: {
    countryCodesList() {
      return Object.keys(
        countryCodes.customList('countryCallingCode', '[{countryCallingCode}]')
      ).map(v => ({ label: '+' + v, value: '+' + v }));
    },
    isViewAllowed() {
      if (
        this.viewAllowed('FlowCompanyInfoView') == true &&
        this.viewAllowed('FlowCompanyInfoEdit') == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    canSubmit() {
      return (
        validateMandatoryFields(this.data, [
          'name',
          'postalCode',
          'address',
          'cityId'
        ]) &&
        validateMandatoryFields(this.businessContacts[0], ['name', 'phone'])
      );
    }
  },
  async created() {
    const company = await this.$repo.settings.getCompanyInfo();
    this.loading = false;
    if (company != null) {
      this.data = company;
      if (company?.country != null) {
        this.selectedCountry = company.country;
        await this.selectCountry(company.country);
      }
      if (company?.region != null) {
        await this.selectRegion(company.region);
      }
      if (company?.district != null) {
        await this.selectDistrict(company.district);
      }

      if (company?.prefecture != null) {
        await this.selectPrefecture(company?.prefectureId);
      }
      await this.selectCity(company.city);

      const contacts = company?.contacts || [];
      this.businessContacts = contacts.filter(
        contact => contact.type === 'BUSINESS'
      );
      this.operationContacts = contacts.filter(
        contact => contact.type === 'OPERATION'
      );
    }

    const emptyBusinessContact = 2 - this.businessContacts.length;
    for (let i = 0; i < emptyBusinessContact; i++) {
      this.businessContacts.push({
        phone: { countryCode: '+81', phoneNumber: '' }
      });
    }
    const emptyOperationContact = 2 - this.operationContacts.length;
    for (let i = 0; i < emptyOperationContact; i++) {
      this.operationContacts.push({
        phone: { countryCode: '+81', phoneNumber: '' }
      });
    }
  },
  methods: {
    isValidEmail(val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val);
    },
    viewAllowed(name) {
      return checkPermission(name);
    },
    updateField(value) {
      const copy = { ...this.data, ...value };
      this.data = copy;
      // this.$emit('input', copy);
    },
    updateSubField(subfield, value) {
      const copy = {
        ...this.value
      };
      copy[subfield] = {
        ...copy[subfield],
        ...value
      };
      this.$emit('input', copy);
    },
    toNumber(value) {
      const number = Number(value);
      return isNaN(number) ? null : number;
    },
    storeImageUploadHandler(file) {
      return this.$repo.store.uploadStoreImage(file);
    },
    fetcher(url) {
      return this.$repo.store.getStoreImageAsBase64(url);
    },
    selectedDisplayValue(options, valueId) {
      const selectedOption = options.filter(opt => opt.value.id === valueId);
      if (selectedOption.length) {
        this.data.city = selectedOption[0].label;
        return selectedOption[0].label;
      } else {
        return;
      }
    },
    async handleDownload(file) {
      const data = await this.$repo.store.downloadFile(file.id);
      downloadAsBlob(data, getMimeFromFilename(file.fileName), file.fileName);
    },
    async selectLocations() {
      const cityId = this.data.cityId;
      try {
        if (this.value.selectedCountry != null) {
          await this.selectCountry(this.value.selectedCountry);
        }
        if (this.value.selectedRegion != null) {
          await this.selectRegion(this.value.selectedRegion);
        }
        if (this.value.selectedDistrict != null) {
          await this.selectDistrict(this.value.selectedDistrict);
        }
        if (this.value.selectedPrefecture != null) {
          await this.selectPrefecture(this.value.selectedPrefecture);
        }
        await this.selectCity(cityId);
      } catch (err) {
        handleErrorUI(err);
      }
    },
    async submitCompany() {
      try {
        const {
          $repo,
          $router,
          data,
          businessContacts,
          operationContacts
        } = this;

        const companyInfo = {
          ...data,
          tenantId: data.id,
          companyName: data.name
        };
        await $repo.settings.upsertCompanyInfo({
          companyInfo,
          businessContacts,
          operationContacts
        });
        this.$q.notify({
          message: this.$t('SettingsManagement.companyUpdated'),
          type: 'positive',
          position: 'top',
          timeout: 3000
        });
        $router.push({ name: 'home' });
      } catch (e) {
        handleErrorUI(e);
      }
    }
  }
};
