
import CalculatorResult from './CalculatorResult';
import { getCalculatedResults } from './methods';
import { formatCompanyData, formatContactData } from './formatter';
import { APIAddCompany, APIAddContact } from './api';
export default {
  name: 'CalculatorStepper',
  components: { CalculatorResult },
  data() {
    return {
      step: 1,
      calculatorValues: {
        locations: 0,
        monthlyRevenue: 0,
        companyWebsiteUrl: '',
        segment: '',
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        averagePurchasePrice: 0
      },
      segmentOptions: [
        { label: 'ファッションセレクトショップ', value: 'Fashion' },
        { label: 'ファストファッション', value: 'Fast Fashion' },
        { label: '家電量販店', value: 'Electronics' },
        { label: 'ラグジュアリーファッション ', value: 'Luxury' }
      ],
      results: {
        monthlySalesImprovement: 0,
        savingsFromReportOptimizationInYenPerMonth: 0,
        roi: 0
      },
      calculatedValues: {
        savingsFromReportOptimizationInHoursPerMonth: 0,
        totalInitialCosts: 0,
        totalMonthlyCosts: 0,
        total1stYearCost: 0,
        genericConversionRate: 0,
        currentEstimatedTraffic: 0,
        futureConversionRate: 0,
        futureExpectedSales: 0,
        currentSales: 0,
        currentReportingExpenses: 0
      }
    };
  },
  computed: {
    barWidth() {
      return ((this.step * 100) / 6).toFixed(0);
    },
    calculatedResults() {
      return {
        sales: {
          current: this.calculatedValues.currentSales,
          improvement: this.results.monthlySalesImprovement.toFixed(2),
          unit: ''
        },
        optimization: {
          current: this.calculatedValues.currentReportingExpenses,
          improvement: this.results.savingsFromReportOptimizationInYenPerMonth.toFixed(
            2
          ),
          unit: ''
        },
        roi: {
          current: 0,
          improvement: this.results.roi.toFixed(2),
          unit: '%'
        }
      };
    }
  },
  methods: {
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
      );
    },
    changeSteps(val) {
      if (this.step + val === -1) {
        this.step = 0;
      } else if (this.step + val >= 7) {
        this.sendToHubspot();
        this.calculateResults();
        this.step = 7;
      } else {
        this.step = this.step + val;
      }
    },
    calculateResults() {
      [this.calculatedValues, this.results] = getCalculatedResults(
        this.calculatorValues
      );
    },
    sendToHubspot() {
      const companyData = formatCompanyData(this.calculatorValues);
      const contactData = formatContactData(this.calculatorValues);
      Promise.all([APIAddContact(contactData), APIAddCompany(companyData)]);
    }
  }
};
