import Product, { ProductDTO } from '../Product';
import {
  convertFromCurrencyDTO,
  convertToCurrencyDTO
} from './CurrencyFormatter';

export const convertFromProductDTO = (dto: ProductDTO): Product => ({
  id: dto.id,
  name: dto.name,
  code: dto.code,
  type: dto.type,
  description: dto.description,
  price: dto.price,
  currency: convertFromCurrencyDTO(dto.currency),
  image: dto.image,
  activatedDate: dto.activatedDate,
  deactivatedDate: dto.deactivatedDate,
  active: dto.visibility === 'SHOWN'
});

export const convertToProductDTO = (product: Product): ProductDTO => ({
  id: product.id,
  code: product.code,
  type: product.type,
  name: product.name,
  description: product.description,
  image: product.image,
  price: product.price,
  currency: convertToCurrencyDTO(product.currency),
  activatedDate: product.activatedDate,
  deactivatedDate: product.deactivatedDate,
  visibility: product.active ? 'SHOWN' : 'NOT_SHOWN'
});
