
import InformationBody from './InformationBody.vue';
import { BaseUser } from '@/models/User';

export default {
  components: {
    InformationBody
  },
  props: {
    data: BaseUser
  },
  computed: {
    fields: function() {
      const { data } = this;
      return [
        {
          label: this.$t('AuthPage.firstname'),
          value: data.firstName
        },
        {
          label: this.$t('AuthPage.lastname'),
          value: data.lastName
        },
        {
          label: this.$t('AuthPage.username'),
          value: data.username
        },
        {
          label: this.$t('AuthPage.phonenumber'),
          value: `${data.phone.countryCode} ${data.phone.phoneNumber}`
        }
      ];
    }
  }
};
