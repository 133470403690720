import { splitPhoneNumber } from '@/util/string';
import map from 'lodash/map';
import Role from '../Role';
import User, { BaseUpadteUserDTO, BaseUserDTO, UserDTO } from '../User';

export const convertFromUserDTO = (dto: UserDTO): User => ({
  id: dto.id,
  username: dto.username,
  email: dto.email,
  fullName: dto.lastname ? `${dto.firstname} ${dto.lastname}` : dto.firstname,
  firstName: dto.firstname,
  lastName: dto.lastname,
  status: dto.status,
  phone: {
    countryCode: dto.phone ? splitPhoneNumber(dto.phone).callingCode : '',
    phoneNumber: dto.phone ? splitPhoneNumber(dto.phone).phoneNumber : ''
  },
  roles: map(dto.userRoles, userRole => Role.fromDTO(userRole.role)),
  storeIds: map(dto.stores, dto => {
    if (dto.ref) {
      return dto.ref;
    }
  })
});

export const convertToUserDTO = (
  user: User,
  token: string
): BaseUpadteUserDTO => ({
  username: user.username,
  email: user.email,
  firstname: user.firstName,
  lastname: user.lastName,
  token: token
});
