var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.StoreFloorAreaUnit'),"value":_vm.$t(
        `SettingsManagement.${_vm.mapToLabel(_vm.areaUnits, _vm.data.storeFloorAreaUnit)}`
      )}}),_c('detail-separator',{attrs:{"label":_vm.$t('SettingsManagement.store')}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-6"},[_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.SelectPeriod'),"value":_vm.mapToLabel(_vm.comparisonPeriods, _vm.data.storeSelectPeriods)}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.LocationFilter'),"value":_vm.data.locationFilter
            ? _vm.$t('SettingsManagement.enabled')
            : _vm.$t('SettingsManagement.disabled')}})],1),_c('div',{staticClass:"col col-md-6"},[_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.ComparePeriod'),"value":_vm.mapToLabel(_vm.comparisonPeriodsCompare, _vm.data.storeComparePeriods)}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.AddonAdvSettings'),"value":_vm.data.allowAdvertiseItems
            ? _vm.$t('SettingsManagement.enabled')
            : _vm.$t('SettingsManagement.disabled')}})],1)]),_c('detail-separator'),_c('div',{staticClass:"row q-pb-md"},[_c('div',{staticClass:"col col-md-6"},[_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.WeekStartDay'),"value":_vm.mapToLabel(_vm.weekdays, _vm.data.weekStartDay)}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.WeeklyEmailReportSendDay'),"value":_vm.mapToLabel(_vm.weekdays, _vm.data.weeklyEmailReportSendDay)}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.MonthlyEmailReportSendDay'),"value":_vm.mapToLabel(_vm.weekdays, _vm.data.monthlyEmailReportSendDay)}})],1),_c('div',{staticClass:"col col-md-6"},[_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.FiscalCalendarStart'),"value":_vm.mapToLabel(_vm.months, _vm.data.fiscalCalendarStart)}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.WeeklyEmailReportSendTime'),"value":_vm.data.weeklyEmailReportSendTime}}),_c('review-text',{attrs:{"label":_vm.$t('SettingsManagement.MonthlyEmailReportSendTime'),"value":_vm.data.monthlyEmailReportSendTime}})],1)]),_c('div',{staticClass:"q-mt-lg"},[_c('c-btns',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[(_vm.viewAllowed('FlowDefaultSettingsEdit'))?_c('c-btn',{attrs:{"bordered":"","text-color":"secondary","label":_vm.$t('SettingsManagement.cancel')}}):_vm._e()],1),(_vm.viewAllowed('FlowDefaultSettingsEdit'))?_c('c-btn',{attrs:{"elevated":"","color":"secondary","label":_vm.$t('SettingsManagement.confirm')},on:{"click":_vm.submitDefaultSettings}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }