
import { createColumn, formatAsCurrency } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: { default: false, type: Boolean },
    order: { default: () => ({}), type: Object }
  },
  data() {
    return {
      tableColumns: [
        createColumn('sensorName', 'Sensor Name'),
        createColumn('quantity', 'Quantity'),
        createColumn('price', 'Price', formatAsCurrency()),
        createColumn('subtotal', 'Subtotal', formatAsCurrency())
      ],
      tableData: []
    };
  },
  watch: {
    value(show) {
      if (show) {
        this.formatTableData();
      }
    }
  },
  methods: {
    formatTableData() {
      if (this.order == null) return [];
      const orderList = this.order.orders || [];
      const orders = orderList.map(item => ({
        id: item.id,
        sensorName: item.product.name,
        quantity: item.finalQuantity,
        currency: this.order.currency.code,
        price: item.product.price,
        subtotal: item.finalSubtotal
      }));
      this.tableData = orders;
    },
    closeDialog() {
      this.$emit('input', false);
    }
  }
};
