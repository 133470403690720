
export default {
  data() {
    return {
      loading: false,
      activeTask: 1,
      total: 0,
      tasks: [
        {
          name: 'Marketing',
          value: 4,
          indicator: 1
        },
        {
          name: 'Staff',
          value: 3,
          indicator: -1
        },
        {
          name: 'Logistics',
          value: 4,
          indicator: 1
        },
        {
          name: 'Other',
          value: 4,
          indicator: -1
        }
      ]
    };
  },
  created() {
    this.total = this.tasks
      .forEach(val => val.value)
      .reduce(function(previous, current) {
        return previous + current;
      }, 0);
  }
};
