import Brand from '@/models/Brand';
import HttpController from '../HttpController';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { PaginationRequest } from '@/util/pagination';

export type GetBrandsResponse = PaginatedResponse<Brand>;
export interface CreateBrandRequest {
  code: string;
  brand: string;
}

// export type UpdateBrandRequest = CreateBrandRequest;

export interface UpdateBrandRequest {
  brandid: Number;
  code: string;
  brand: string;
}
export default class BrandController extends HttpController {
  public async getBrands(pagination: PaginationRequest) {
    const { data } = await this.client.get('brand', {
      params: {
        ...pagination
      }
    });
    return data as GetBrandsResponse;
  }

  public async createBrand(req: CreateBrandRequest) {
    try {
      const { data } = await this.client.post('brand', req);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateBrand(id: string, req: UpdateBrandRequest) {
    try {
      const { data } = await this.client.put(`brand/${id}`, req);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteBrand(ids: string[]) {
    // try {
    const data = await this.client.delete('brand', {
      data: ids
    });

    //   // createBulkDeleteBackendError(data);
    // } catch (err) {
    //   throw createBackendError(err);
    // }
  }
}
