
import { BillingModel } from '@/models/Plan';
import AddOnCard from '@/components/Management/Plan/AddOnCard.vue';

export default {
  components: { AddOnCard },
  props: {
    addOns: { type: Array, default: () => [] },
    purchasedAddOns: { type: Array, default: () => [] },
    disableMaxHeight: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    billingModel: { type: Number, default: 0 }
  },
  computed: {
    purchasedAddOnsId() {
      return this.purchasedAddOns.map(addOn => addOn.id);
    },
    period() {
      if (this.billingModel === BillingModel.MONTHLY) return 'monthly';
      return 'annually';
    }
  }
};
