
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';
import {  getTransMethod } from '@/util/colors';
import { formatYAxis } from '@/util/legend';
import store from '@/store';
import _ from 'lodash';
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';

export default Vue.extend({
components: { LoadingSpinner, BoxHeader },
data() {
  return {
    daysOfWeek: [
      this.$store.state.user.translate.monday,
      this.$store.state.user.translate.tuesday,
      this.$store.state.user.translate.wednesday,
      this.$store.state.user.translate.thursday,
      this.$store.state.user.translate.friday,
      this.$store.state.user.translate.saturday,
      this.$store.state.user.translate.sunday,
      // this.$t('Date.Tuesday'),
      // this.$t('Date.Wednesday'),
      // this.$t('Date.Thursday'),
      // this.$t('Date.Friday'),
      // this.$t('Date.Saturday'),
      // this.$t('Date.Sunday')
    ],
    categories: [],
    colors: [],//MULTI_COLORS,
    kpiFormatted: {},
    selectOptionsKpi: [],
    periodType: 'weekly',
    periodType2: 'value',
    loading: false,
    show: true,
    series: [],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 450,
        stacked: true,
        stackType: '100%',
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            },
            export: {
              csv: {
                enabled: false,
                show: false
              }
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      colors: ['#75abf7', '#f4a640', '#75b265', '#f5c132'],
      dataLabels: {
      formatter: (val, opts) => {
        const seriesIndex = opts.seriesIndex;
        const dataPointIndex = opts.dataPointIndex;

        if (this.periodType2 === 'percentage') {
          // If periodType2 is 'percentage', return the value rounded with a '%' symbol
          return `${Math.round(val)}% `; // change this line for . to fixed(2) to display
        } else {
          // Otherwise, return the specific value from the series
          return `${opts.w.globals.series[seriesIndex][dataPointIndex].toLocaleString()}`;
          }
        }
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function(val) {
            return val;
          }
        }
      },
      yaxis: {
        categories: [],
        labels: {
          formatter: function(val) {
            return val + '%';
          }
        }
      },

      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        
      //  horizontalAlign: 'right',

        markers: {
          radius: 0,
          width: 16,
          height: 6
        }
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          // Check the period type to decide whether to display percentage or actual value
          if (this.periodType2 === 'percentage') {
              let seriesPercent = w.globals.seriesPercent[seriesIndex];
              return `<q-card class="q-pa-md box-trend-tooltip tooltip-container bg-white">
                      <div class="flex items-center no-wrap">
                      <div class="pie-dot q-mr-sm"
                        style="height: 8px;width: 8px;
              border-radius: 8px;background:${this.colors[seriesIndex]}"></div>
              <label>${
                        w.globals.seriesNames[seriesIndex]
                      }:</label><span>&nbsp; ${seriesPercent[
                        dataPointIndex
                      ].toFixed(2)}%</span>
            </div>
                  </q-card>`;
          } else {
              let seriesValue = series[seriesIndex][dataPointIndex];
              return `<q-card class="q-pa-md box-trend-tooltip tooltip-container bg-white">
                      <div class="flex items-center no-wrap">
                      <div class="pie-dot q-mr-sm"
                        style="height: 8px;width: 8px;
              border-radius: 8px;background:${this.colors[seriesIndex]}"></div>
              <label>${
                        w.globals.seriesNames[seriesIndex]
                      }:</label><span>&nbsp; ${seriesValue}</span>
            </div>
                  </q-card>`;
          }
      },

        y: {
          formatter: value => {
            return this.$t('this.$store.state.user.translate.n_of_people', [value]);
          }
        }
      }
    } // chartOptions
  };
},
computed: {
  ...mapGetters('filter', [
    'getStoreCodesList',
    'getDaysOfWeekList',
    'getStartingPeriod',
    'getEndingPeriod',
    'getStartingCompared',
    'getEndingCompared'
  ]),
  ...mapState('demographics', ['demoPeriod','demoPeriodValue']),
  ...mapGetters('user', ['getLocale'])
},
watch: {
  async periodType2() {
    this.loading = true;
    await this.loadKpiMetrics();
  },
  
  demoPeriod() {
    this.colors = [];
    this.categories = [];

    const storeState = store.state;
    const translate = storeState.user.translate;
    const id = storeState.user.profile.tenant._id;
    try {

      if(this.periodType2 == 'percentage')
      {
        this.show = true;
        this.series = Object.values(this.demoPeriod).map((v, i) => {
          return {
            name:
            getTransMethod(v[0].demographicsGroupEN , i),
            data: v.map(val => {
              return val.count;
            })
          };
        });
        this.series = this.calculateAveragePercentages(this.series);
      }
      else
      {
        this.show = false;
        this.series = Object.values(this.demoPeriodValue).map((v, i) => {
          return {
            name:
            getTransMethod(v[0].demographicsGroupEN , i),
            data: v.map(val => {
              return val.count;
            })
          };
        });
      }  
    if(id == '4TxrckSM8PrcEvfx9')
    {

      this.series.splice(-2); // Removes the last two elements

      this.series[0].name = translate[`male_child_${id}`]?translate[`male_child_${id}`]:translate['male_child'];
      this.series[1].name = translate[`male_young_${id}`]?translate[`male_young_${id}`]:translate['male_young'];
      this.series[2].name = translate[`male_adult_${id}`]?translate[`male_adult_${id}`]:translate['male_adult'];
      //this.labels[3] = translate[`male_senior_${id}`]?translate[`male_senior_${id}`]:translate['male_senior']//translate.male_senior;
      this.series[3].name = translate[`female_child_${id}`]?translate[`female_child_${id}`]:translate['female_child']//translate.female_child;
      this.series[4].name = translate[`female_young_${id}`]?translate[`female_young_${id}`]:translate['female_young']//translate.female_young;
      this.series[5].name = translate[`female_adult_${id}`]?translate[`female_adult_${id}`]:translate['female_adult']//translate.female_adult;
      //this.labels[7] = translate[`female_senior_${id}`]?translate[`female_senior_${id}`]:translate['female_senior']//translate.female_senior;

      this.colors[0] = translate[`male_child_color_${id}`]?translate[`male_child_color_${id}`]:translate['male_child_color'];
      this.colors[1] = translate[`male_young_color_${id}`]?translate[`male_young_color_${id}`]:translate['male_young_color'];
      this.colors[2] = translate[`male_adult_color_${id}`]?translate[`male_adult_color_${id}`]:translate['male_adult_color'];
      //this.colors[3] = translate[`male_senior_color_${id}`]?translate[`male_senior_color_${id}`]:translate['male_senior_color']//translate.male_senior;
      this.colors[3] = translate[`female_child_color_${id}`]?translate[`female_child_color_${id}`]:translate['female_child_color']//translate.female_child;
      this.colors[4] = translate[`female_young_color_${id}`]?translate[`female_young_color_${id}`]:translate['female_young_color']//translate.female_young;
      this.colors[5] = translate[`female_adult_color_${id}`]?translate[`female_adult_color_${id}`]:translate['female_adult_color']//translate.female_adult;

    }
    else
    {
      this.colors[0] = translate[`male_child_color_${id}`]?translate[`male_child_color_${id}`]:translate['male_child_color'];
          this.colors[1] = translate[`male_young_color_${id}`]?translate[`male_young_color_${id}`]:translate['male_young_color'];
          this.colors[2] = translate[`male_adult_color_${id}`]?translate[`male_adult_color_${id}`]:translate['male_adult_color'];
          this.colors[3] = translate[`male_senior_color_${id}`]?translate[`male_senior_color_${id}`]:translate['male_senior_color']//translate.male_senior;
          this.colors[4] = translate[`female_child_color_${id}`]?translate[`female_child_color_${id}`]:translate['female_child_color']//translate.female_child;
          this.colors[5] = translate[`female_young_color_${id}`]?translate[`female_young_color_${id}`]:translate['female_young_color']//translate.female_young;
          this.colors[6] = translate[`female_adult_color_${id}`]?translate[`female_adult_color_${id}`]:translate['female_adult_color']//translate.female_adult;
          this.colors[7] = translate[`female_senior_color_${id}`]?translate[`female_senior_color_${id}`]:translate['female_senior_color']//translate.female_senior;


          this.series[0].name = translate[`male_child_${id}`]?translate[`male_child_${id}`]:translate['male_child'];
          this.series[1].name = translate[`male_young_${id}`]?translate[`male_young_${id}`]:translate['male_young'];
          this.series[2].name = translate[`male_adult_${id}`]?translate[`male_adult_${id}`]:translate['male_adult'];
          this.series[3].name = translate[`male_senior_${id}`]?translate[`male_senior_${id}`]:translate['male_senior']//translate.male_senior;
          this.series[4].name = translate[`female_child_${id}`]?translate[`female_child_${id}`]:translate['female_child']//translate.female_child;
          this.series[5].name = translate[`female_young_${id}`]?translate[`female_young_${id}`]:translate['female_young']//translate.female_young;
          this.series[6].name = translate[`female_adult_${id}`]?translate[`female_adult_${id}`]:translate['female_adult']//translate.female_adult;
          this.series[7].name = translate[`female_senior_${id}`]?translate[`female_senior_${id}`]:translate['female_senior']//translate.female_senior;
    }
    
    if(this.periodType2 == 'percentage')
    {this.show = true;
      this.categories = Object.values(this.demoPeriod)[0]?.map(val => {
          if (val) {
            return this.periodType === 'weekly'
              ? this.$t('Date.' + val.timelineLabel.toLowerCase())
              : val.timelineLabel.toLowerCase();
          }
        })
        .sort(
          (x, y) => this.daysOfWeek.indexOf(x) - this.daysOfWeek.indexOf(y)
        );

    }
    else
    {
      this.show = false;
      this.categories = Object.values(this.demoPeriodValue)[0]?.map(val => {
        if (val) {
          return this.periodType === 'weekly'
            ? this.$t('Date.' + val.timelineLabel.toLowerCase())
            : val.timelineLabel.toLowerCase();
        }
      })
      .sort(
        (x, y) => this.daysOfWeek.indexOf(x) - this.daysOfWeek.indexOf(y)
      );
      
    }
  
    this.setChartOptions();
    this.loading = false;
  } catch (error) {
  this.loading = false;
    }
  },
  async periodType() {
    this.loading = true;
    await this.loadKpiMetrics();
  },
  
},
async created() {
  this.loading = true;
  await this.loadKpiMetrics();
},
methods: {
  ...mapActions('demographics', ['fetchDemographicsByPeriod']),
  calculateAveragePercentages(data) {
    const totalEntries = data.length;
    const dataLength = data[0].data.length;

    // Initialize an array to hold the sum of values for each index
    const sums = new Array(dataLength).fill(0);

    // Calculate the total sum of values at each index, handling null and NaN
    data.forEach(item => {
        item.data.forEach((value, index) => {
            if (isNaN(value) || value === null) {
                value = 0; // Replace NaN or null with 0
            }
            sums[index] += value;
        });
    });
    // Calculate the percentage for each value in the original data set
    const percentageData = data.map(item => {
        return {
            name: item.name,
            data: item.data.map((value, index) => {
                var percentage = 0;
                if (sums[index] == 0) {
                    percentage = 0;
                }
                else
                {
                 percentage =(value / sums[index]) * 100;
                }
                return parseFloat(percentage.toFixed(2));
            })
        };
    });
    return percentageData;
},
 
  async loadKpiMetrics() {
    if(localStorage.getItem('FirstTime') == '1' ) {
      await this.fetchDemographicsByPeriod({
        storeCodes: this.getStoreCodesList,
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        type: this.periodType
      });
    }
    localStorage.setItem('FirstTime','1');
  },
  setChartOptions() {
    this.chartOptions = {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: '<i class="fas fa-download"></i>'
          },
          export: {
            csv: {
              enabled: false,
              show: false
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      stroke: {
        width: 1,
      },
      colors: this.colors,
      xaxis: {
        categories: this.categories,
        labels: {
          formatter: function(val) {
            return formatYAxis(val);
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        markers: {
          radius: 0,
          width: 16,
          height: 6
        }
      }
    };
  },
  daysOfWeekSorter(x, y) {
    return this.daysOfWeek.indexOf(x.name) - this.daysOfWeek.indexOf(y.name);
  }
}
});
