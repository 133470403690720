import { GetRequest } from '@/util/http';
import axios from 'axios';

export const APIFetchRSSInsightNews = params => {
  return GetRequest(
    'https://www.flow-solutions.com/insight_blog/rss.xml',
    {},
    { headers: null }
  );
};
