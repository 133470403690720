import axios from "axios"
import store from '@/store';
import { SSOPutRequest2, SSOPostRequest2, SSOPutRequest, SSODeleteRequest2, SSOGetRequest2, SSOPostReq } from '@/util/kpis';
export default {
  // getAreaManagers() {
  //   return axios.get(store.state?.baseKpiUrl+'/api/getAreaManagers?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // createAreaManager(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "title": data.title,
  //     "description": data.description,
  //     "storeCodes": data.storeCodes,
  //   };
  //   return axios.post(store.state?.baseKpiUrl+'/api/createAreaManager', contactData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // updateAreaManager(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "title": data.title,
  //     "description": data.description,
  //     "storeCodes": data.storeCodes,
  //     "areaManagerId": data.areaManagerId
  //   };
  //   return axios.put(store.state?.baseKpiUrl+'/api/updateAreaManager',contactData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getAreaManagers() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      
    }
    const apiResponse= await SSOGetRequest2(
      '/api/getAreaManagers',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async createAreaManager(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "title": data.title,
      "description": data.description,
      "storeCodes": data.storeCodes,
    };
    const apiResponse= await SSOPostRequest2(
      '/api/createAreaManager', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  
  async updateAreaManager(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "title": data.title,
      "description": data.description,
      "storeCodes": data.storeCodes,
      "areaManagerId": data.areaManagerId
    };
    const apiResponse= await SSOPutRequest2(
      '/api/updateAreaManager', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async deleteAreaManager(areaManagerId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "areaManagerId": areaManagerId,
    };
    const apiResponse= await SSODeleteRequest2(
      '/api/deleteAreaManager',
      {
        data:paramsData,
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  // deleteAreaManager(areaManagerId) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "areaManagerId": areaManagerId,
  //   };
  //   return axios.delete(store.state?.baseKpiUrl+'/api/deleteAreaManager',{
  //     data: userData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
}