
export default {
  props: {
    status: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default: 'exclamation-triangle'
    },
    topMargin: {
      type: Boolean,
      default: false
    },
    bottomMargin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    boxClass() {
      const { $style, status, topMargin, bottomMargin } = this;
      return {
        [$style.messageBox]: true,
        [$style.success]: status === 'success',
        [$style.warning]: status === 'warning',
        [$style.topMargin]: topMargin,
        [$style.bottomMargin]: bottomMargin
      };
    }
  }
};
