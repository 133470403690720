
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    lastTimeStamp: {
      type: Number,
      default: 0
    },
    stores: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    kpi: {
      type: String,
      default: ''
    },
    lines: {
      type: Array,
      default: () => []
    }
  }, // props

  data() {
    return {
    };
  }, // data
  computed: {
  },
  watch: {
    async kpi(newVal) {
      this.$emit('refreshPageComparision', newVal)
    }
  }, // computed
  async created() {
    
  }, // created
  methods: {
    async loadData() {
      
    }
  } // methods
}; // export default
