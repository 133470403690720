import {
  OrderTransactionRequest,
  OrderTransactionResponse,
  OrderInstallationScheduleRequest
} from '@/models/Order';
import { PaymentCardDTO } from '@/models/Payment';

import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@/models/Pagination';
import {
  RequestOrderDTO,
  RequestOrderUpdateDTO,
  PaymentConfirmation
} from '@/models/RequestOrder';
import { createBackendError } from '@/util/error';
import { PaginationRequest } from '@/util/pagination';
import { BankDTO } from '@/models/Bank';

export type GetOrdersResponse = PaginatedResponse<RequestOrderDTO>;
export type GetBanksResponse = PaginatedResponse<BankDTO>;
export type GetCreditCardsResponse = PaginatedResponse<PaymentCardDTO>;

export default class OrderController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getOrdersWithStatus(
    tenantId: string,
    status: string,
    pagination: PaginationRequest
  ) {
    const { data } = await this.client.get(
      `/console/order/sensor/${tenantId}/${status}`,
      {
        params: {
          ...pagination
        }
      }
    );
    return data as GetOrdersResponse;
  }

  public async createOrderSuggestion(
    orderId: string,
    suggestion: RequestOrderUpdateDTO
  ) {
    try {
      const { data } = await this.client.post(
        `/console/order/sensor/${orderId}/suggest`,
        suggestion
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async finalizeOrder(orderId: string, final: RequestOrderUpdateDTO) {
    try {
      const { data } = await this.client.post(
        `/console/order/sensor/${orderId}/finalized`,
        final
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async confirmPayment(orderId: string, payment: PaymentConfirmation) {
    try {
      const { data } = await this.client.post(
        `/console/order/sensor/${orderId}/confirm-payment`,
        payment
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createOrder(req: OrderTransactionRequest) {
    try {
      const { data } = await this.client.post(
        `/console/order/sensor/${req.tenantId}`,
        {
          orders: req.orders
        }
      );
      return data as OrderTransactionResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async acceptPayment(orderId: string) {
    try {
      await this.client.post(
        `/console/order/sensor/${orderId}/accept-payment`,
        {}
      );
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async scheduleInstallation(
    orderId: string,
    req: OrderInstallationScheduleRequest
  ) {
    try {
      const { data } = await this.client.post(
        `/console/order/sensor/${orderId}/schedule`,
        {
          scheduleDate: new Date(req.date).toISOString(),
          scheduleTime: req.time
        }
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async cancelOrder(orderId: string) {
    try {
      await this.client.post(
        `/console/order/sensor/${orderId}/cancel-payment`,
        {}
      );
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPaymentMethodTypes() {
    try {
      const { data } = await this.client.get(
        '/console/payment/billing-plan/payment-method'
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getBank(pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/console/payment-methods', {
        params: {
          ...pagination
        }
      });
      return data as GetBanksResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getCreditCards(tenantId: string) {
    try {
      const { data } = await this.client.get(
        `/console/payment/card/${tenantId}/tenant`
      );
      return data as GetCreditCardsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
