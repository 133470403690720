// @ts-ignore
export const formatContactData = data => ({
  submittedAt: Date.now(),
  fields: [
    {
      objectTypeId: '0-1',
      name: 'email',
      value: data.email
    },
    {
      objectTypeId: '0-1',
      name: 'firstname',
      value: data.firstName
    },
    {
      objectTypeId: '0-1',
      name: 'lastname',
      value: data.lastName
    },
    {
      objectTypeId: '0-1',
      name: 'companyname',
      value: data.companyName
    },
    {
      objectTypeId: '0-1',
      name: 'website',
      value: data.companyWebsiteUrl
    }
  ]
});

// @ts-ignore
export const formatCompanyData = data => ({
  submittedAt: Date.now(),
  fields: [
    {
      objectTypeId: '0-1',
      name: 'domainname',
      value: data.companyWebsiteUrl
    },
    {
      objectTypeId: '0-1',
      name: 'numberofstore',
      value: data.locations
    },
    {
      objectTypeId: '0-1',
      name: 'averagepurchase',
      value: data.averagePurchasePrice
    },
    {
      objectTypeId: '0-1',
      name: 'companyname',
      value: data.companyName
    },
    {
      objectTypeId: '0-1',
      name: 'monthlysales',
      value: data.monthlyRevenue
    }
  ]
});
