
import CommonMixin from '@/mixins/CommonMixin';
import LocationFormMixin from '@/mixins/LocationFormMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import AreaFormMixin from '@/mixins/AreaFormMixin';
import Store from '@/models/Store';
import { uuidv4 } from '@/util/random';
import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin, FormMixin, LocationFormMixin, AreaFormMixin],
  data() {
    return {
      sessionId: '',
      stores: [],
      uploadFormShown: false,
      selectedStore: null
    };
  },
  async created() {
    this.sessionId = uuidv4();
  },
  methods: {
    showUploadForm(store: Store) {
      this.uploadFormShown = true;
      this.selectedStore = store;
    },
    async submitFloorPlan() {
      try {
        await this.$repo.store.bulkUpdateStoreImage(
          this.sessionId,
          this.stores.map(store => {
            const images = store.storeFloorPlanImages || [];
            return {
              storeId: store.id,
              filePaths: images.map(image => image.path)
            };
          })
        );
        this.$router.push({ name: 'management:store' });
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
