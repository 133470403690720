
import { mapState, mapActions, mapMutations } from 'vuex';
import { APICreatePreferences } from '@/api/kpi';

export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    } // elevated
  }, // props

  data() {
    return {
      search: '',
      markedOptions: [],
      listState: null,
       flag: false,
    };
  },
  computed: {
    ...mapState('store', ['stores']),
    ...mapState('filter', ['filter']),

    /**
     * List of options. It gets updated if the user operates an search.
     */
    searchResult() {
      if (this.search) {
        return this.options?.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.options;
      }
    }, // searchResult
    options() {
      if (this.stores) {
        let stores = this.stores.stores ?? [];

        if(this.flag === false)
        {
         stores.sort((a, b) => {
            return a.storeCode - b.storeCode;
          });
       
        }
        else
        {
         stores.sort((a, b) => {
            return b.storeCode - a.storeCode;
          });
        
        }

        return (
          stores?.map(store => {
              return {
                label: store.storeCode + ' -  ' + store.name,
                value: store.storeCode
              };
            })
                 );
      } // if (this.stores.regions)

      return [];
    }, // options
    loading() {
      if (this.options.length) {
        return false;
      } else {
        return true;
      }
    }
  }, // computed
  watch: {
    'filter.storeCodesCal': {
      handler: 'setStoreCodesFromHierarchy'
    }
  },
  async created() {
    // Set default selection
    console.log('this.stores-1',this.stores)
    console.log('this.stores?.stores-1',this.stores?.stores)
    ///fetchStoresForTraffic
    if(this.stores == null || this.stores == undefined || this.stores?.stores == undefined)
    {
      await this.fetchStores();
    }
    console.log('this.stores-2',this.stores)
    console.log('this.stores?.stores-2',this.stores?.stores)
     if(this.stores != null || this.stores?.stores != undefined)
     {
       console.log('this.stores-3',this.stores)
    console.log('this.stores?.stores-3',this.stores?.stores)
      var allStoresList = this.stores?.stores?.map(s => s.storeCode);

     //   console.log('this.stores-4',allStoresList)
    //  if(this.filter?.storeCodesCal.length == 0) {
         console.log('this.filter?.storeCodesCal',this.filter?.storeCodesCal)
        this.markedOptions  =  allStoresList;
        this.submit();
    //  }
    //  else
    //  {
    //    console.log('allStoresList?.storeCodesCal',allStoresList)
    //    this.markedOptions  =  allStoresList?.filter(v => this.filter?.storeCodesCal?.includes(v)) || [];//this.selectedOptions;
    //  }
     }
  }, // created

  methods: {
    ...mapActions('store', ['fetchStores']),
    ...mapMutations('filter', ['setStoreCodesCal']),
    /**
     * Mark all options as selected|
     */
    setStoreCodesFromHierarchy() {
      var allStoresList = this.stores?.stores?.map(s => s.storeCode);
      this.markedOptions  =  allStoresList?.filter(v => this.filter?.storeCodesCal?.includes(v)) || [];
      // this.markedOptions = this.filter.storeCodesCal;
    },
    markAllOptions() {
      this.markedOptions = this.options?.map(option => option.value);
    },
    /**
     * Fired when user click on 'All' checkbox
     */
    listStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markAllOptions();
      } else {
        // Unmark all checkboxes
        this.markedOptions = [];
      }
    }, // listStateInput
    /**
     * Fired when user click on any checkbox apart from 'All'
     */
    markedOptionsInput(newValue) {
      if (newValue.length === this.options.length) {
        // 'All' checkbox totally state
        this.listState = true;
      } else if (newValue.length === 0) {
        // 'All' checkbox empty state
        this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        this.listState = null;
      }
    }, // markedOptionsInput
    /**
     * Update selected options before closing the filter
     */
    submit() {
      
      // var urlPage = window.location.pathname;
      
      if (this.markedOptions.length === 0) {
        return false;
      }
      // APICreatePreferences(urlPage, this.markedOptions);
      // Updates the parent
      this.$emit('update:selectedOptions', this.markedOptions);
      this.setStoreCodesCal({ storeCodesCal: this.markedOptions });

      // Hide the filter
      this.$refs.filterStoreCal.hide();
      
    }, // submit
    /**
     * Closes the filter without saving selected options
     */
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterStoreCal.hide();
    }, // hide
    sortOptions() {
      if (this.stores) {
        let stores = this.stores.stores ?? [];
        if(this.flag === false)
        {
           this.flag= true;
        }
        else
        {
          this.flag= false;
        }
      }
    }
  }
};
