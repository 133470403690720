
import CommonMixin from '@/mixins/CommonMixin';
import { OrderSensorRequest, ItemRequest } from '@/models/Order';
import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin],
  props: {
    orders: { type: Array, required: true },
    tax: { type: Object, required: true }
  },
  methods: {
    async submitOrders() {
      const orders: OrderSensorRequest[] = [];
      const orderPerStore: Map<string, ItemRequest[]> = new Map();
      this.orders.forEach(orderItem => {
        const sensorId = orderItem.sensor.id;
        orderItem.storeOrders.forEach(order => {
          const storeId = order.store.id;
          if (!orderPerStore.has(storeId)) {
            orderPerStore.set(storeId, []);
          }
          const cart = orderPerStore.get(storeId);
          cart.push({
            productId: sensorId,
            quantity: order.quantity
          });
        });
      });

      orderPerStore.forEach((request, storeId) => {
        orders.push({
          storeId,
          itemRequests: request
        });
      });

      try {
        const { tenant } = await this.$repo.session.fetch();
        await this.$repo.order.createOrder({ tenant, orders });
        this.$emit('submit');
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
