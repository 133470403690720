import Button from '../Button.vue';
import Buttons from '../Buttons.vue';
import FormCard from '../Form/Card.vue';
import FormSeparator from '../Form/Separator.vue';
import Input from '../Input.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    'form-card': FormCard,
    'form-separator': FormSeparator,
    'form-input': Input,
    'form-button': Button,
    'form-buttons': Buttons
  }
});
