import BackendError, {
  DeleteEntities,
  DeleteError
} from '@/models/BackendError';
import { AxiosError, AxiosResponse } from 'axios';
import each from 'lodash/each';
import map from 'lodash/map';
import { Notify } from 'quasar';
import router from '@/router';
import { TENANTEXISTS } from '@/util/errorMessages';
import { navigateIfTenantExists } from '@/util/navigation';

export const createBackendError = (err: AxiosError): Error => {
  const { errors, message } = (err?.response?.data ||
    'Network Error. Please try again later.') as BackendError;
  if (errors) {
    return new Error(
      map(errors, err => {
        if (err.field) {
          return `${err.field} ${err.message}`;
        } else {
          return `${err.message}`;
        }
      }).join('\r\n')
    );
  } else if (message) {
    navigateIfTenantExists(message);
    return new Error(message);
  }
  return err;
};

export const createBulkDeleteBackendError = (resp: AxiosResponse) => {
  const { failEntities, successEntities } = resp.data as DeleteError;

  if (failEntities.length) {
    return handleErrorUI(
      new Error(
        map(failEntities, failEntity =>
          failEntity.id.concat(' ').concat(failEntity.message)
        ).join('\r\n')
      )
    );
  }

  if (successEntities.length && resp.statusText != '') {
    return handleSuccessUI(
      map(successEntities, successEntity =>
        resp.statusText
      ).join('\r\n')
    );
  } else if (successEntities.length) {
    return handleSuccessUI(
      map(successEntities, successEntity =>
        'Successfully delete '.concat(successEntity.id)
      ).join('\r\n')
    );
  }
};

export const createDeleteBackendError = (resp: AxiosResponse) => {
  const response = resp.data as DeleteEntities;

  if (response.message) {
    return handleErrorUI(
      new Error(response.id.concat(' ').concat(response.message))
    );
  } else {
    return handleSuccessUI('Successfully delete '.concat(response.id));
  }
};

export const handleSuccessUI = (message: string) => {
  Notify.create({
    icon: 'fas fa-check',
    type: 'positive',
    position: 'top',
    message
  });
};

export const handleErrorUI = (err: Error) => {
  const messages = err?.message?.split('\r\n');
  each(messages, message => {
    Notify.create({
      icon: 'fas fa-exclamation-triangle',
      type: 'negative',
      position: 'top',
      message,
      timeout: 3000
    });
  });
};

export const checkFieldErrors = (errors: any) => {
  let valid = true;
  each(errors, value => {
    if (value) {
      valid = false;
      return false;
    }
  });
  return valid;
};

export const getErrorCodes = (err: AxiosError) => {
  const { code } = (err?.response?.data ||
    'Network Error. Please try again later.') as BackendError;
  return code;
};
