
import Vue from 'vue';
import { QCardSection, QIcon, QTooltip } from 'quasar';

export default Vue.extend({
  props: {
    /**
     * Title to be displayed.
     */
    title: {
      type: String,
      default: 'No title defined'
    },
    /**
     * Tooltip content. If empty, tooltip icon won't be displayed.
     */
    tooltip: {
      type: String,
      default: ''
    }
  }, // props
  render: function(h) {
    // Conditionally render tooltip and icon if tooltip is not null or empty
    const _icon = this.tooltip
      ? h(
          QIcon,
          {
            class: 'info-icon fas fa-info-circle'
          },
          [
            h(
              QTooltip,
              {
                props: {
                  anchor: 'top middle',
                  self: 'bottom middle',
                  contentClass: 'w-300',
                  offset: [10, 10]
                }
              },
              this.tooltip
            )
          ]
        )
      : null;

    return h(QCardSection, { class: 'q-py-sm' }, [
      h('div', { class: 'text-h6' }, [this.title, _icon, this.$slots.append])
    ]);
  } // render
});
