import {
  APIFetchStaffOverview,
  APIFetchStaffRanking
} from '@/api/staffPerformance';
import {
  IOverviewInfo,
  IStaffPerformanceState,
  IStaffRanking
} from '../../util/interface/StaffPerformance';

const initialState: IStaffPerformanceState = {
  overviewInfo: [],
  staffRanking: []
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setOverviewInfo(
      state: IStaffPerformanceState,
      { overviewInfo }: { overviewInfo: IOverviewInfo[] }
    ) {
      state.overviewInfo = overviewInfo;
    },
    setStaffRankingData(
      state: IStaffPerformanceState,
      { staffRanking }: { staffRanking: IStaffRanking[] }
    ) {
      state.staffRanking = staffRanking;
    }
  }, // mutations
  actions: {
    async getOverViewData({ commit }) {
      const res = await APIFetchStaffOverview();
      commit('setOverviewInfo', { overviewInfo: res.data });
    },
    async getStaffRanking({ commit }) {
      const res = await APIFetchStaffRanking();
      commit('setStaffRankingData', { staffRanking: res.data });
    }
  } // actions
}; // export default
