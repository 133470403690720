
import CommonMixin from '@/mixins/CommonMixin';
import AreaFormMixin from '@/mixins/AreaFormMixin';
import DefaultSettingsMixin from '@/mixins/DefaultSettingsMixin';
import { handleSuccessUI, handleErrorUI } from '@/util/error';
import { mapMutations, mapState } from 'vuex';
import { setUsersParamsValues } from '@/util/userParams';
import { checkPermission } from '@/api/utils';

export default {
  mixins: [CommonMixin, DefaultSettingsMixin, AreaFormMixin],
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    ...mapState('user', ['userPermissions', 'defaultSettings'])
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    ...mapMutations('user', [
      'setLocale',
      'setUserPermissions',
      'setDefaultSettings'
    ]),
    async submitDefaultSettings() {
      try {
        delete this.data.headquartersComparePeriods;
        delete this.data.headquartersSelectPeriods;
        delete this.data.name;
        let res = await this.$repo.settings.upsertDefaultSettings(this.data);

        this.setDefaultSettings({ ...this.defaultSettings, ...res });
        setUsersParamsValues();
        handleSuccessUI(this.$t('SettingsManagement.defaultSettingsSaved'));
      } catch (err) {
        handleErrorUI(err);
      }
    },
    mapToLabel(list, value) {
      if (list == null || list.length === 0) return value;
      const obj = list.find(l => l.value === value);
      if (obj == null) {
        return value;
      }
      return obj.label;
    }
  }
};
