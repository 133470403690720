import { UserDTO, UpdateUserDTO } from '@/models/User';
import { createBackendError } from '@/util/error';
import HttpController from '../HttpController';
import { SetPasswordRequest } from '@/services/management/CustomerController';
import { APIResetPasswordSSO } from '@/api/authSSO';

export type GetUserResponse = UserDTO;
export type UpdateUserRequest = UpdateUserDTO;
export type UpdateUserResponse = UserDTO;

export default class AuthController extends HttpController {
  public async getUser() {
    try {
      const { data } = await this.client.get('/auth/me');
      return data as GetUserResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    try {
      await this.client.put('/auth/change-password', {
        oldPassword,
        newPassword
      });
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateUser(req: UpdateUserRequest) {
    try {
      const { data } = await this.client.put('/auth/user-profile', req);
      return data as UpdateUserResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async setPassword(req: SetPasswordRequest, tenant) {
    try {
      await this.client.post('users/set-password', req);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async forgetPassword(verificationCode: string, newPassword: string) {
    try {
      await APIResetPasswordSSO({
        code: verificationCode,
        password: newPassword,
        confirmPassword: newPassword
      });
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
