//VIEW
export const VIEW_BRAND = 'VIEW_BRAND';
export const VIEW_IMPORT_LOGS = 'VIEW_IMPORT_LOGS';
export const VIEW_SALES_MAPPING = 'VIEW_SALES_MAPPING';
export const VIEW_FTP = 'VIEW_FTP';
export const VIEW_STORES = 'VIEW_STORES';
export const VIEW_USERS = 'VIEW_USERS';
export const VIEW_LOGS = 'VIEW_LOGS';
export const VIEW_PEER_GROUPS = 'VIEW_PEER_GROUPS';
export const VIEW_ROLES = 'VIEW_ROLES';
export const VIEW_PRIVILEGE = 'VIEW_PRIVILEGE';
export const VIEW_ROLES_PERMISSION = 'VIEW_ROLES_PERMISSION';
export const VIEW_USER_ROLES = 'VIEW_USER_ROLES';
export const VIEW_DEFAULT_SETTINGS = 'VIEW_DEFAULT_SETTINGS';
export const VIEW_COMPANIES = 'FlowCompanyInfoView';
export const VIEW_SALES = 'VIEW_SALES';
export const VIEW_SALES_DASHBOARD = 'VIEW_SALES_DASHBOARD';

//CREATE
export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_SALES_MAPPING = 'CREATE_SALES_MAPPING';
export const CREATE_FTP = 'CREATE_FTP';
export const CREATE_USERS = 'CREATE_USERS';
export const CREATE_STORES = 'CREATE_STORES';
export const CREATE_PEER_GROUPS = 'CREATE_PEER_GROUPS';
export const CREATE_ROLES = 'CREATE_ROLES';
export const CREATE_COMPANIES = 'CREATE_COMPANIES';
export const CREATE_SALES = 'CREATE_SALES';

//UPDATE
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const UPDATE_SALES_MAPPING = 'UPDATE_SALES_MAPPING';
export const UPDATE_STORES = 'UPDATE_STORES';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_PEER_GROUPS = 'UPDATE_PEER_GROUPS';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_PRIVILEGE = 'UPDATE_PRIVILEGE';
export const UPDATE_ROLES_PERMISSION = 'UPDATE_ROLES_PERMISSION';
export const UPDATE_DEFAULT_SETTINGS = 'UPDATE_DEFAULT_SETTINGS';
export const UPDATE_COMPANIES = 'FlowCompanyInfoEdit';

//DELETE
export const DELETE_BRAND = 'DELETE_BRAND';
export const DELETE_SALES_MAPPING = 'DELETE_SALES_MAPPING';
export const DELETE_STORES = 'DELETE_STORES';
export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_PEER_GROUPS = 'DELETE_PEER_GROUPS';
export const DELETE_ROLES = 'DELETE_ROLES';
export const DELETE_USER_ROLES = 'DELETE_USER_ROLES';

//UPLOAD
export const UPLOAD_STORE = 'UPLOAD_STORE';
export const UPLOAD_SALES_MAPPING = 'UPLOAD_SALES_MAPPING';

//DOWNLOAD
export const DOWNLOAD_STORE = 'DOWNLOAD_STORE';
export const DOWNLOAD_SALES_MAPPING = 'DOWNLOAD_SALES_MAPPING';

//IMPORT
export const IMPORT_STORE = 'IMPORT_STORE';

//CANCEL SALES MAPPING
export const CANCEL_SALES_MAPPING = 'CANCEL_SALES_MAPPING';
