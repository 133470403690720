
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import LoginTabs from '@/components/Auth/Tabs.vue';
import CommonMixin from '../../../mixins/CommonMixin';
import { handleErrorUI } from '../../../util/error';

export default {
  components: {
    LoginTabs
  },
  mixins: [CommonMixin],
  data() {
    return {
      email: '',
      screen: '',
      code: '',
      loading: false,
      disable: false
    };
  }, // data
  computed: {
    ...mapGetters('user', ['getLocale'])
  },
  created() {
    const email = this.$router.currentRoute.query.email;
    const screen = this.$router.currentRoute.query.screen;
    if (!email) {
      return this.$router.push({ name: 'auth:signup' }).catch(_ => {});
    }
    this.email = email.toString();
    this.screen = screen.toString();
  },
  methods: {
    ...mapMutations('user', ['setLocale']),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    ...mapActions('user', [
      'registerCodeVerificationSSO',
      'codeVerificationSSO',
      'inviteCodeVerificationSSO',
      'registerEmailVerificationSSO'
    ]),
    async resendCode() {
  this.loading = true;
  this.disable = true;
	   const signupEmail = localStorage.getItem('signupEmail');
     try {
			const response = await this.registerEmailVerificationSSO({
            email: signupEmail
          });
      } catch (err) {
        this.$q.notify({
          message: this.$t('AuthPage.signup_error_message'),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
       // localStorage.clear();
        handleErrorUI({
          message: err.response?.data?.message || err.response?.message || err
        });
      } finally {
        this.loading = false;
        this.disable = false;
        this.$q.notify({
          message: this.$t('AuthPage.verification_code_sent'),
          type: 'positive',
          position: 'top',
          timeout: 3000
        });
      }
		},
    async verifyCodeSSO() {
      this.loading = true;
      this.disable = true;

      this.$v.$touch();

      try {
        if (!this.$v.$invalid) {
          if (this.screen === 'forgot') {
            try{
            await this.codeVerificationSSO({
              code: this.code
            });
            this.$emit('continue');
            await this.$router
              .push({
                name: 'auth:reset',
                query: { email: this.email, code: this.code }
              })
               }
            catch(err){
              this.$q.notify({
              position: 'top',
              type: 'negative',
              message: this.$t('AuthPage.InvalidCode')
              });
            }
          } else if (this.screen === 'invite') {
            try{
            await this.inviteCodeVerificationSSO({
              code: this.code
            });
            this.$emit('continue');
            await this.$router
              .push({
                name: 'auth:signup:createAccount',
                query: { email: this.email, code: this.code }
              })
             }
            catch(err){
              this.$q.notify({
              position: 'top',
              type: 'negative',
              message: this.$t('AuthPage.InvalidCode')
              });
            }
          } else {
            try{
            await this.registerCodeVerificationSSO({
              code: this.code
            });
            this.$emit('continue');
            await this.$router
              .push({
                name: 'auth:signup:create',
                query: { email: this.email, code: this.code }
              })
            }
            catch(err){
              this.$q.notify({
              position: 'top',
              type: 'negative',
              message: this.$t('AuthPage.InvalidCode')
              });
            }
          }
        }
      } catch (err) {
        this.$q.notify({
          position: 'top',
          type: 'negative',
          message: this.$t(
            'AuthPage.' + err?.message.replace(/ /g, '').replace("'", '')
          )
        });
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.$reset();
      } // finally
    },
    async login() {
      this.loading = true;
      this.disable = true;

      this.$v.$touch();

      try {
        if (!this.$v.$invalid) {
          const res = await this.$repo.auth.verify(this.email, this.code);
          this.$emit('continue');
          this.$emit('verify', res);
        }
      } catch (err) {
        this.$q.notify({
          position: 'top',
          type: 'negative',
          message: this.$t(
            'AuthPage.' + err?.message.replace(/ /g, '').replace("'", '')
          )
        });
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.$reset();
      } // finally
    },
    setupLocale(locale) {
      this.setLocale({ locale });
    } // setupLocale
  },
  validations: {
    code: {
      required
    }
  }
};
