
import { mapState, mapActions, mapGetters } from 'vuex';
import { DUO_TONES } from '../../util/colors';
import { formatYAxis } from '../../util/legend';

export default {
  data() {
    return {
      kpiFormatted: {},
      selectOptionsKpi: [],
      loading: false,
      series: [],
      kpisList: {},
      customTitles: [
        this.$t('KPI.sales'),
        this.$t('KPI.avgitemprice'),
        this.$t('KPI.upt'),
        this.$t('KPI.avgpurchase'),
        this.$t('KPI.conversion'),
        this.$t('KPI.transactions'),
        this.$t('KPI.traffic'),
        this.$t('KPI.passersby'),
        this.$t('KPI.salespersqm'),
        this.$t('KPI.trafficpersqm'),
        this.$t('KPI.capturerate'),
        this.$t('KPI.storedwelltime'),
        this.$t('KPI.engagementrate'),
        this.$t('KPI.bouncerate'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.stafflevel'),
        this.$t('KPI.staffhours'),
        this.$t('KPI.closingstrength'),
        this.$t('KPI.staffefficiency'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      chartOptions: {
        chart: {
          type: 'radar',
          height: 460,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        colors: DUO_TONES,
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'top',
          offsetY: 20,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        },
        stroke: {
          width: 2
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
                  <q-card class="tooltip-container flex column q-pa-md">
                    <label>${w.globals.labels[dataPointIndex]}</label>
                    <div class="tooltip-title flex items-center">
                      <div
                        class="tooltip-title-color"
                        style="background:${
                          w.globals.colors[seriesIndex]
                        }"></div>
                      <label>${w.globals.seriesNames[seriesIndex]} :</label>
                      <label class="q-ml-xs text-bold">
                        ${this.getRadarValue(
                          series[seriesIndex][dataPointIndex],
                          seriesIndex,
                          dataPointIndex
                        )}
                      </label>
                    </div>
                  </q-card>
            `;
          },
          y: {
            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
              if (seriesIndex === 0) {
                return this.byPeriod.comparedKPIValues[dataPointIndex]
                  .formattedValue;
              } else if (seriesIndex === 1) {
                return this.byPeriod.selectedKPIValues[dataPointIndex]
                  .formattedValue;
              }
              return value;
            }
          }
        },
        fill: {
          opacity: 0
        },
        markers: {
          size: 3
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: val => {
              return formatYAxis(val) + '%';
            }
          }
        }
      }
    }; // chartOptions
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]), // mapGetters
    ...mapState('distribution', ['byPeriod']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('overview', ['kpis']),
    ...mapState('filter', ['radarChartKpiOptions'])
  },
  watch: {
    selectOptionsKpi: {
      handler: 'loadData',
      immediate: true
    },
    selectedKPIOptions() {
      this.selectOptionsKpi = this.selectedKPIOptions;
    },
    kpis(newValue) {
      this.kpisList = newValue;
    }
  },
  created() {
    this.kpisList = this.kpis;
    this.selectOptionsKpi = this.selectedKPIOptions;
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByPeriod']),
    ...mapActions('overview', ['fetchOverview']),

    kpisorter(x, y) {
      return (
        this.customTitles.indexOf(x.metricKey.trim()) -
        this.customTitles.indexOf(y.metricKey.trim())
      );
    },
    getRadarValue(value, seriesIndex, dataPointIndex) {
      if (seriesIndex === 1) {
        return this.byPeriod.comparedKPIValues[dataPointIndex].formattedValue;
      } else if (seriesIndex === 0) {
        return this.byPeriod.selectedKPIValues[dataPointIndex].formattedValue;
      }
      return value;
    },
    async loadData(newValue, oldValue) {
      try {
        if (newValue.length) {
          this.loading = true;
          const filterParams = {
            startDate: this.getStartingPeriod,
            endDate: this.getEndingPeriod,
            compareStartDate: this.getStartingCompared,
            compareEndDate: this.getEndingCompared,
            storeCodes: this.getStoreCodesList,
            daysOfWeek: this.getDaysOfWeekList,
            kpis: newValue.join(',')
          };
          await Promise.all([
            this.fetchOverview(filterParams),
            this.fetchDistributionByPeriod(filterParams)
          ]);
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              categories: [
                ...this.byPeriod.selectedKPIValues
                  .sort(this.kpisorter)
                  .map(kpi => kpi.metricKey)
              ]
            }
          };
          this.series = [];
          const selectedKPIValues = this.byPeriod.selectedKPIValues.sort(
            this.kpisorter
          );
          const comparedKPIValues = this.byPeriod.comparedKPIValues.sort(
            this.kpisorter
          );

          const selected = selectedKPIValues.map((kpi, index) => {
            {
              const val = (kpi.value / comparedKPIValues[index].value) * 100;
              return isNaN(val) ? 0 : val;
            }
          });
          const compared = comparedKPIValues.map((kpi, index) => {
            const val = (kpi.value / comparedKPIValues[index].value) * 100;
            return isNaN(val) ? 0 : val;
          });

          this.series = [
            {
              name: this.$t('KpiAnalysis.selected_period'),
              data: [...selected]
            },
            {
              name: this.$t('KpiAnalysis.compared_period'),
              data: [...compared]
            }
          ];
        }
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
