
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    
    lastTimeStamp: {
      type: Number,
      default: 0
    },
    svs: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    kpi: {
      type: String,
      default: ''
    },
    lines: {
      type: Array,
      default: () => []
    }
  }, // props

  
  data() {
    return {
    };
  }, // data
  computed: {
  }, // computed
  watch: {
    async kpi() {
      this.$emit('refreshPageComparision')
    }
  },
  async created() {
  }, // created
  methods: {
  } // methods
}; // export default
