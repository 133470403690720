
import moment from 'moment';
import xml2js from 'xml2js';
import { APIFetchRSSInsightNews } from '../../api/insightNews';
import { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';

export default {
  props: {
    shownews: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      feedUrl: 'https://www.flow-solutions.com/insight_blog/rss.xml',
      name: '',
      feedList: [],
      limit: 5
    };
  },
  computed: {},

  created() {
    const parser = new xml2js.Parser();
    (async () => {
      try {
        this.loading = true;
        // const rssAxios = new VueAxios();
        // let feedXML = axios.get(
        //   'https://www.flow-solutions.com/insight_blog/rss.xml'
        // );
        let feedXML = await APIFetchRSSInsightNews();
        const parsedFeed = await parser.parseStringPromise(feedXML);
        const feed =
          parsedFeed &&
          parsedFeed.rss &&
          parsedFeed.rss.channel &&
          parsedFeed.rss.channel.length &&
          parsedFeed.rss.channel[0].item
            ? parsedFeed.rss.channel[0].item
            : [];
        const feedList = feed.map(item => {
          return {
            title: item.title,
            thumbnailUrl: this.extractImgSrc(item['content:encoded']),
            link: item.link,
            creator: item.creator,
            date: item.date,
            content: item.contentSnippet
          };
        });
        this.feedList = [...feedList];
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    })();
  },
  methods: {
    extractImgSrc(content) {
      const tmp = document.createElement('div');
      tmp.innerHTML = content;
      const src = tmp.querySelector('img').getAttribute('src');
      return src;
    },
    formattedDate(dateString) {
      return moment(dateString).format('YYYY/MM/DD HH:mm');
    }
  }
};
