
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  props: {
    setselectedstore: {
      type: Function,
      required: true
    },
    togglegeneratewidget: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      stores: [],
      filteredStores: [],
      searchKeyword: ''
    };
  },
  computed: {
    ...mapState('occupancy', [
      'selectedStore',
      'availableStores',
      'storeListLoading'
    ])
  },
  watch: {
    availableStores() {
      this.stores = this.availableStores;
      this.filteredStores = this.stores;
    },
    searchKeyword() {
      this.filteredStores = this.stores.filter(
        val =>
          val.storeName.includes(this.searchKeyword) ||
          val.storeCode.includes(this.searchKeyword)
      );
    },
    storeListLoading(newValue) {
      this.loading = newValue;
    }
  },
  created() {
    this.loading = this.storeListLoading;
  },
  methods: {
    handleSettingClick(store) {
      this.setselectedstore(store);
      this.togglegeneratewidget(true);
    },
    getClass(val) {
      let color = 'recovered-|color';
      if (val < 33.33) {
        color = 'recovered-color';
      } else if (val < 66.66) {
        color = 'warning-color';
      } else {
        color = 'danger-color';
      }
      return color;
    },
    changeStoreData(code,id, stre) {
      localStorage.setItem("StoreOccupancyId",id);
      localStorage.setItem("MaxConfigOccupancy",stre.occupancyConfig.maxOccupancy);
      // localStorage.setItem("MaxConfigOccupancy",stre.occupancyConfig.maxOccupancy);
      this.setOverviewLoading({ isLoading: true });
      this.setStoreValue(code);
      this.setCurrentStoreData(code);
      this.setOverviewLoading({ isLoading: false });
    },
    ...mapActions('occupancy', ['setStoreValue', 'setCurrentStoreData']),
    ...mapMutations('occupancy', ['setOverviewLoading'])
  }
};
