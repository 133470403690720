
import { mapActions, mapGetters } from 'vuex';
  export default {
    components: {},
    data() {
      return {
        currentTrend: '',
        loading: false
      };
    },
    computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getCustomCalenderState'
    ])
  }, // computed
  async created() {

  }, // created
  methods: {
    ...mapActions('overview', ['fetchOverview'])
  } // methods
  };
  