export const weatherObj = {
  Snow: '\u2744\uFE0F',
  Sunny: '\u2600\uFE0F',
  Rain: '\uD83C\uDF27\uFE0F',
  Cloudy: '\uD83C\uDF25\uFE0F',
  Clouds: '\uD83C\uDF25\uFE0F',
  Thunderstorm: '\u26A1',
  Tornado: '\uD83C\uDF2A\uFE0F',
  Fog: '\uD83C\uDF2B\uFE0F',
  Clear: '\u2600\uFE0F',
  // 'Clear sky': '\u2609\uFE0F',
  'Clear sky': '\u2600\uFE0F',

  雪: '\u2744\uFE0F',
  晴: '\u2600\uFE0F',
  雨: '\uD83C\uDF27\uFE0F',
  曇: '\uD83C\uDF25\uFE0F',
  雷雨: '\u26A1',
  竜巻: '\uD83C\uDF2A\uFE0F', //from google
  霧: '\uD83C\uDF2B\uFE0F', //from google
  快晴: '\u2609\uFE0F'

}
