
import CommonMixin from '@/mixins/CommonMixin';
import DrawerMixin from '@/components/Common/Mixins/DrawerMixin';

const initialFilter = () => ({
  storeName: null,
  storeNumber: null,
  region: null,
  district: null,
  prefecture: null,
  city: null,
  status: null
});

export default {
  mixins: [CommonMixin, DrawerMixin],
  data: () => ({
    filter: initialFilter()
  }),
  methods: {
    resetData() {
      this.filter = initialFilter();
    }
  }
};
