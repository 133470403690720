import { QBtnDropdown } from 'quasar';
import Vue from 'vue';

export default Vue.component('ButtonDropdown', {
  props: {
    label: {
      type: String,
      required: true
    }
  },
  render: function(h) {
    return h(
      QBtnDropdown,
      {
        props: {
          ripple: false,
          unelevated: true,
          noCaps: true
        },
        scopedSlots: {
          label: () => h('div', this.label)
        }
      },
      this.$slots.default
    );
  }
});
