
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import LogoFlow from '../Common/Logo/LogoFlow';

export default {
  name: 'CalculatorResult',
  props: {
    results: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      content: {
        sales: {
          title: '売上改善(月次)',
          subtitle: '売上改善(月次)',
          description: 'Flow導入活用によって売上が月value円アップ',
          increment: true
        },
        optimization: {
          title: '業務改善効果(月次)',
          subtitle: 'Description of this kpi',
          description: 'Flow導入活用によってコストがvalue円削減',
          increment: false
        },
        roi: {
          title: '費用対効果(月次)',
          subtitle: 'Description of this kpi',
          description:
            'Flow導入活用によってvalue％の 投資効果が得ることができます',
          increment: false
        }
      }
    };
  },
  computed: {
    currentDate() {
      return moment(Date.now()).format('YYYY/MM/DD');
    }
  },
  methods: {
    openContact() {
      window.open('https://www.flow-solutions.com/contact', '_blank');
    },
    exportPNG() {
      return new Promise(async (resolve, reject) => {
        try {
          let box = document.querySelector('.calculator-result');
          let boxWidth = box.clientWidth;
          let boxHeight = box.clientHeight;
          let ratio = boxHeight / boxWidth;
          const canvas = await html2canvas(box, {
            height: boxHeight,
            width: boxWidth,
            scale: '2'
          });
          const imgData = canvas.toDataURL('image/png');
          let url = imgData.replace(
            /^data:image\/png/,
            'data:application/octet-stream'
          );
          let downloadLink = document.createElement('a');
          downloadLink.setAttribute('download', 'Result.png');
          downloadLink.setAttribute('href', url);
          downloadLink.click();
        } catch (error) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: `${error}`
          });
          reject(error);
        }
      });
    },
    getDescription(val, key) {
      return parseInt(
        Math.abs(this.results[key].improvement - this.results[key].current) +
          this.results[key].unit
      ).toLocaleString();
    },
    getCurrentHeight(inc, dec) {
      return (inc * 100) / dec;
    },
    getToFixedValue(val) {
      return Number.parseFloat(val).toFixed(0);
    }
  }
};
