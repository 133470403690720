import Log from '@/models/Log';
import LogController from '@/services/tools/LogController';
import { convertLogDTO } from '@/models/formatters/LogFormatter';
import map from 'lodash/map';

export interface GetLogsResult {
  logs: Log[];
}

export default class LogRepository {
  private logController: LogController;

  constructor(logController: LogController) {
    this.logController = logController;
  }

  public async getLogs() {
    const res = await this.logController.getLogs();
    return {
      logs: map(res.content, dto => convertLogDTO(dto))
    };
  }
}
