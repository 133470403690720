
import { createColumn, formatAsCurrency } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: { default: false, type: Boolean },
    order: { default: () => ({}), type: Object }
  },
  data() {
    return {
      tableColumns: [],
      tableData: []
    };
  },
  computed: {
    orderTitle() {
      if (this.order == null || this.order.id == null) return '';
      const date = this.order.requestDate.toLocaleString();
      return `Order from ${this.order.company} (${this.order.store}) on ${date}`;
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.formatTableData();
        this.tableColumns = [
          createColumn('sensorName', 'Sensor Name'),
          createColumn('quantity', 'Quantity'),
          createColumn('price', 'Price', formatAsCurrency()),
          createColumn('subtotal', 'Subtotal', formatAsCurrency())
        ].filter(Boolean);
      }
    }
  },
  methods: {
    formatTableData() {
      if (this.order == null) return [];
      const orderList = this.order.orders || [];
      const orders = orderList.map(item => ({
        id: item.id,
        sensorName: item.product.name,
        quantity: item.finalQuantity,
        currency: this.order.currency.code,
        price: item.product.price,
        subtotal: item.finalSubtotal
      }));
      this.tableData = orders;
    },
    formatWithCurrency(value) {
      return formatCurrencyWithSymbol(value, this.order.currency.code);
    },
    acceptPayment() {
      this.$emit('acceptPayment');
    },
    closeDialog() {
      this.$emit('input', false);
    }
  }
};
