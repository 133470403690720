import Vue from 'vue';
import VueMixpanel from 'vue-mixpanel';
console.log(location.origin === 'https://app.flow-insight.com', location.origin.indexOf('fe-flow-app-stg'))
if(location.origin === 'https://app.flow-insight.com' ) {
  console.log('Activating Mixpanel')
  Vue.use(VueMixpanel, {
    token: '63c686310f1889081fc5ff49dd3604ef',
    config: {
      debug: process.env.NODE_ENV === 'production' ? false : true
    }
  });
}
