
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import UserForm from './UserForm.vue';
import { handleErrorUI } from '@/util/error';

export default {
  components: {
    UserForm
  },
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      user: {
        phone: {
          phoneCountryCode: '+81',
          phoneNumber: null
        },
        stores: []
      },
      loading: false
    };
  },
  computed: {
    userForm: function() {
      return this.$refs.userForm;
    }
  },
  methods: {
    async submitUser() {
      var res = '';
      try {
        this.loading = true;
        this.userForm.sync();
        
         if (this.user.stores.length === 0) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('AuthPage.at_least_one_store')
          });
          this.loading = false;
          return;
        }

        const response = await this.$repo.user.inviteUser(this.user);
        res = response.message;
        if(res =="Invalid tenant for the following record in response.")
        {
          this.$q.notify({
            type: 'negative',
            message: this.$t('UserManagement.InviteUser.IncorrectEmailDomain'),
            position: 'top'
          });
           this.loading = false;
        } else {
            await this.$repo.store.updateUserStore(
              response.response.invitedUserId,
              this.user.stores.map(s => s.id)
            );
            res = response;
            this.$q.notify({
                type: 'positive',
                message: this.$t('UserManagement.InviteUser.InviteUserSent'),
                position: 'top'
              });
            this.$router.push({ name: 'management:user' });
        }
      } catch (err) {
        this.$q.notify({
          message: this.$t('SettingsManagement.inviteUserEmailExist'),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
 //       let message = res.data.message;
 //       this.$q.notify({
 //         message: this.$t(message),
 //         type: 'negative',
 //         position: 'top',
 //         timeout: 3000
 //       });
      } finally {
        this.loading = false;
      }
    }
  }
};
