
import { mapState, mapActions, mapGetters } from 'vuex';
import { eddyConfigDefault } from '../../store/modules/interface';
import store from '../../store/';
import Config from '@/config';

export default {
  props: {
    display: {
      required: true,
      type: Boolean
    },
    toggle: {
      required: true,
      type: Function
    },
    store: {
      required: true,
      type: Object
    },
    widgetloading: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      url1: '',
      url2: '',
      frame1: ``,
      frame2: ``,

      frame1Tooltip: 'copy',
      frame2Tooltip: 'copy',
      storeData: {},
      eddyConfigData: this.eddyConfig || eddyConfigDefault,
      loading: false,
      storeCapacity: null,
      percent1: true,
      percent2: true,
      tab: 'meter',
      isSaving: false
    };
  },
  computed: {
    ...mapState('occupancy', [
      'selectedStore',
      'availableStores',
      'widgetTokenLoadingState',
      'eddyConfig'
    ]),
    ...mapGetters('tenant', ['hasResetAccount']),
    ...mapGetters('user', ['getLocale'])
  },
  watch: {
    store(newValue) {
      this.storeData = newValue;
      this.percent1 = this.storeData.occupancyConfig.defaultWidget.unsafe.isPercentage;
      this.percent2 = this.storeData.occupancyConfig.safeUnSafeWidget.unsafe.isPercentage;

      var storeID = this.storeData.id;
      var language = this.getLocale;
      var url = Config.eddyUrl;
      this.url1 = `${url}?sensorId=${storeID}&type=default&lang=${language}`; // `http://eddy.flow-insight.com.s3-website-ap-northeast-1.amazonaws.com/?token=${newValue}`;
      this.url2 = `${url}?sensorId=${storeID}&type=safeUnsafe&lang=${language}`; // `http://eddy-v2.flow-insight.com.s3-website-ap-northeast-1.amazonaws.com/?token=${newValue}`;
      this.frame1 = `<iframe src="${url}?sensorId=${storeID}&type=default&lang=${language}">`;
      this.frame2 = `<iframe src="${url}?sensorId=${storeID}&type=safeUnsafe&lang=${language}">`;
    },
    widgetTokenLoadingState(newValue) {
      this.loading = false;//newValue;
    },
    eddyConfig(newValue) {
      this.eddyConfigData = newValue;
    },
    percent1(newValue) {
      let config = { ...this.eddyConfigData };
      if (newValue) {
        config.greenIndicatorThreshold = this.convertToPercentage(
          +config.greenIndicatorThreshold,
          +config.capacity
        );
        config.redIndicatorThreshold = this.convertToPercentage(
          +config.redIndicatorThreshold,
          +config.capacity
        );
        config.yellowIndicatorThreshold = this.convertToPercentage(
          +config.yellowIndicatorThreshold,
          +config.capacity
        );
      } else {
        config.greenIndicatorThreshold = this.convertToValue(
          +config.greenIndicatorThreshold,
          +config.capacity
        );
        config.redIndicatorThreshold = this.convertToValue(
          +config.redIndicatorThreshold,
          +config.capacity
        );
        config.yellowIndicatorThreshold = this.convertToValue(
          +config.yellowIndicatorThreshold,
          +config.capacity
        );
      }
      this.eddyConfigData = { ...config };
    },
    percent2(newValue) {
      let config = { ...this.eddyConfigData };
      if (newValue) {
        config.widgetGreenIndicatorThreshold = 0

        // this.convertToPercentage(
        //   +config.widgetGreenIndicatorThreshold,
        //   +config.capacity
        // );
        config.widgetRedIndicatorThreshold = this.convertToPercentage(
          +config.widgetRedIndicatorThreshold,
          +config.capacity
        );
      } else {
        config.widgetGreenIndicatorThreshold = 0;
        // this.convertToValue(
        //   +config.widgetGreenIndicatorThreshold,
        //   +config.capacity
        // );
        config.widgetRedIndicatorThreshold = this.convertToValue(
          +config.widgetRedIndicatorThreshold,
          +config.capacity
        );
      }
      this.eddyConfigData = { ...config };
    }
  },
  methods: {
    handleClose() {
      this.toggle(false);
      this.frame = '';
    },
    copyToClipBoard(str, snippetNum) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (snippetNum === 1) {
        this.frame1Tooltip = 'Copied to clipboard';
      } else if (snippetNum === 2) {
        this.frame2Tooltip = 'Copied to clipboard';
      }
    },
    async handleSave() {
      let config = { ...this.eddyConfigData };
      // if (!store.getters['tenant/hasResetAccount']) {
      //   this.$q
      //     .dialog({
      //       title: this.$t('GenerateWidget.demoVersionAlertTitle'),
      //       message: this.$t('GenerateWidget.demoVersionAlert')
      //     })
      //     .catch(() => {});
      // } else
      {
        // if (!this.validateForm()) {
        //   this.tab === 'meter' ? (this.tab = 'meter') : (this.tab = 'safety');
        //   this.$q.notify({
        //     type: 'negative',
        //     position: 'top',
        //     message: 'Invalid Input field, Re-check form before submission'
        //   });
        //   return;
        // }
// debugger;
//         if (!this.this.percent1) {
//           config.greenIndicatorThreshold = this.convertToPercentage(
//             +config.greenIndicatorThreshold,
//             +config.capacity
//           );
//           config.redIndicatorThreshold = this.convertToPercentage(
//             +config.redIndicatorThreshold,
//             +config.capacity
//           );
//           config.yellowIndicatorThreshold = this.convertToPercentage(
//             +config.yellowIndicatorThreshold,
//             +config.capacity
//           );
//         }
//         debugger;
//         if (!this.percent2) {
//           config.widgetGreenIndicatorThreshold = this.convertToPercentage(
//             +config.widgetGreenIndicatorThreshold,
//             +config.capacity
//           );
//           config.widgetRedIndicatorThreshold = this.convertToPercentage(
//             +config.widgetRedIndicatorThreshold,
//             +config.capacity
//           );
//         }
        config.percent1 = this.percent1;
        config.percent2 = this.percent2;
        this.isSaving = true;
        try {
          const response = await this.setEddyConfig({
            ...this.changeValuesToNumber(config),
            // storeCode: this.store.storeCode
            storeCode: this.store.id
          });
          if (response.transKey && response.transKey === 'SUCCESS') {
            this.$q.notify({
              type: 'positive',
              position: 'top',
              message: this.$t('Occupancy.eddy_config_success_message')
            });
          } else if (res.transKey && response.transKey === 'ERROR') {
            this.$t.notify({
              type: 'negative',
              position: 'top',
              message: this.$t('Occupancy.eddy_config_error_message')
            });
          }
        } catch (error) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('Occupancy.eddy_config_error_message')
          });
        }
        this.isSaving = false;
        this.handleClose(false);
      }
    },
    convertToPercentage(value, capacity) {
      return +((value / capacity) * 100).toFixed(2);
    },
    convertToValue(percentvalue, capacity) {
      return +((percentvalue / 100) * capacity).toFixed();
    },
    changeValuesToNumber(eddyConfigData) {
      eddyConfigData.greenIndicatorThreshold = +eddyConfigData.greenIndicatorThreshold;
      eddyConfigData.redIndicatorThreshold = +eddyConfigData.redIndicatorThreshold;
      eddyConfigData.yellowIndicatorThreshold = +eddyConfigData.yellowIndicatorThreshold;
      eddyConfigData.widgetGreenIndicatorThreshold = 0;//+eddyConfigData.widgetGreenIndicatorThreshold;
      eddyConfigData.widgetRedIndicatorThreshold = +eddyConfigData.widgetRedIndicatorThreshold;
      eddyConfigData.capacity = +eddyConfigData.capacity;
      return eddyConfigData;
    },
    validateForm() {
      let isValid = true;
      let fieldsKey = Object.keys(this.eddyConfigData);
      fieldsKey.forEach(field => {
        if (
          this.eddyConfigData[field] === '' ||
          this.eddyConfigData[field] === undefined
        ) {
          isValid = false;
        }
        if (field === 'redIndicatorThreshold') {
          if (
            this.eddyConfigData[field] <=
              this.eddyConfigData['greenIndicatorThreshold'] ||
            this.eddyConfigData[field] <=
              this.eddyConfigData['yellowIndicatorThreshold']
          ) {
            isValid = false;
          }
        }
        if (field === 'yellowIndicatorThreshold') {
          if (
            this.eddyConfigData[field] <
              this.eddyConfigData['greenIndicatorThreshold'] ||
            this.eddyConfigData[field] >
              this.eddyConfigData['redIndicatorThreshold']
          ) {
            isValid = false;
          }
        }
        if (field === 'greenIndicatorThreshold') {
          if (
            this.eddyConfigData[field] >
              this.eddyConfigData['redIndicatorThreshold'] ||
            this.eddyConfigData[field] >
              this.eddyConfigData['yellowIndicatorThreshold']
          ) {
            isValid = false;
          }
        }
        // if (field === 'widgetRedIndicatorThreshold') {
        //   if (
        //     this.eddyConfigData[field] <
        //     this.eddyConfigData['widgetGreenIndicatorThreshold']
        //   ) {
        //     isValid = false;
        //   }
        // }
        // if (field === 'widgetGreenIndicatorThreshold') {
        //   if (
        //     this.eddyConfigData[field] >
        //     this.eddyConfigData['widgetRedIndicatorThreshold']
        //   ) {
        //     isValid = false;
        //   }
        // }
      });
      return isValid;
    },
    ...mapActions('occupancy', [
      'setStoreValue',
      'setCurrentStoreData',
      'setEddyConfig'
    ])
  }
};
