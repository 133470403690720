import { GetRequestFidaExport2, DeleteRequestFida, PostRequest } from '@/util/http';
import { SSOPutRequest, SSOGetRequest2, SSOGetRequest } from '@/util/kpis';
import { PostRequestFidaExport, PutRequestFidaExport } from '@/util/http';
import { kMaxLength } from 'buffer';
import store from '@/store';
import { GetReportingSumDataByLocationFidaV2OnlyExport } from '@/api/fida';
import {APIGetSavedPreferencesForPulseById} from '@/api/kpi';
export type ReportType = 'DAILY' | 'WEEKLY' | 'MONTHLY';
export interface ReportBody {
  compareEndDate: string;
  compareStartDate: string;
  endDate: string;
  kpis: string;
  startDate: string;
  storeCodes: string;
  numberOfEmployees: number;
  attendanceStaff: string;
  reportTitle: string;
  marketReport: string;
  nextActionPlan: string;
}

const client_id = 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688';
const client_secret = 'fp329-polk80s-ye04p1yy-45hx874z06';

export function APICreaPulse(body) {
  // const storeState:any = store.state;
  // const token = storeState.user.sessionClient.access_token;
  body.client_id = client_id;
  body.client_secret = client_secret;

  return PostRequestFidaExport('/api/createPulseReport', body );
}

export function APISubmitReportFida(reportType: ReportType, body: ReportBody) {
  const storeState:any = store.state;
  const token = storeState.user.sessionClient.access_token;

  const config = {
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization:'Bearer '+ token
    }
  };

  return SSOPutRequest(`api/uploadContent`, body,config);
}

export function APIGetFileURL(fileName) {
  const storeState:any = store.state;
  const token = storeState.user.sessionClient.access_token;

  const config = {
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization:'Bearer '+ token
    }
  };

  return SSOGetRequest(`api/getUploadedContentUrl?filename=${fileName}`, config);
}

export function APIGetAllReport(params) {
  return GetRequestFidaExport2(`api/getPulseReports`,params);
}

export function APIDeleteReport(reportId: string) {
  return DeleteRequestFida('api/deletePulseReport',{'_id':reportId});
}
export async function APIGetSingleReport(reportId: string) {

  const params = {
    _id:reportId,
    reportType: 'WEEKLY'
  }
  var apiResponse:any = await APIGetSavedPreferencesForPulseById(params);
 
  return apiResponse?apiResponse[0]:[];
  // return PostRequest(`periodicReport/details/${reportId}`);
}
export function APIUpdateReport(body) {
  return PutRequestFidaExport('api/updatePulseReport/', body);
}


export function APIDownloadReport(reportId: number) {
  return PostRequest(`periodicReport/download/${reportId}`);
}

export function APIFetchTargetValues(params) {
  return PostRequest(`kpi/sales/target`, params);
}

export function APIFetchMonthlyPerformance(params) {
  return {
    "data": {
        "totalSales": "0 ",//"¥608,270 ",
        "totalLastYearSales": "¥0 ",
        "thisMonthTarget": {
            "salesTarget": "¥0 ",
            "remaining": "¥0 ",
            "progress": "0.00 %"
        },
        "lastYearTarget": {
            "salesTarget": "¥0 ",
            "remaining": "¥0 ",
            "progress": "0.00 %"
        }
    },
    "description": "Success",
    "status": "SUCCESS"
};
  //  return PostRequest(`kpi/monthlyPerformance`, params);
}

export function APISalesTrendWithBudget(params) {

  params.kpiTypes = ['sls','st'];
  params.frequency = 'daily';
  params.reportType = 'daily';
  params.storeCodes = params.storeCodes.split(',');
  const response =  GetReportingSumDataByLocationFidaV2OnlyExport(params);
  return  response;//PostRequestFidaExport('/api/getReportingSumByLocationDataV2', params);

 // return PostRequest(`kpi/sales/target/trend`, params);
}

export function APIGetFormLastPeriodValues(
  reportType,
  startDate,
  storeCodes,
  endDate
) {
  return PostRequest(`periodicReport/${reportType}/previousPeriod`, {
    startDate,
    endDate,
    storeCodes
  });
}
export function APIWeeklyBudgetTable(params) {
  return PostRequest(`kpi/distribution/bySelectedAndComparedDate`, params);
}
