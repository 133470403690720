import { UPDATE_COMPANIES, VIEW_COMPANIES } from '@/consts/permissions';
import _ from 'lodash';
const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export const getAuthoritiesObj = val => {
  const authorities = val.map(v => {
    return v.authorities.map(val => {
      return val;
    });
  })[0];

  const groupedAuthorities = _.groupBy(authorities, v => v.menu.name);
  const reducedAuth = {};
  Object.values(groupedAuthorities).forEach((v, i) => {
    let val = Object.keys(groupedAuthorities)[i];
    reducedAuth[val.replace(/ /g, '')] = v.reduce((res, item, index) => {
      res[`${item.action}`] = true;
      return res;
    }, {});
  });
  return reducedAuth;
};
