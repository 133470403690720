
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      lastTimeStamp: Date.now(),
      options: [
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
        { label: 'Sunday', value: 'sunday' }
      ] // options
    };
  }, // data
  computed: {
    ...mapState('filter', ['filter']),
    ...mapState('store', ['stores']),
    storeList() {
      if (this.stores.regions[0] && this.stores.regions[0].districts) {
        return (
          [
            ...this.stores.regions[0].districts[0].stores,
            ...this.stores.regions[0].districts[1].stores,
            ...this.stores.regions[0].districts[2].stores,
            ...this.stores.regions[0].districts[3].stores,
            ...this.stores.regions[1].districts[0].stores,
            ...this.stores.regions[1].districts[1].stores,
            ...this.stores.regions[1].districts[2].stores,
            ...this.stores.regions[1].districts[3].stores,
            ...this.stores.regions[1].districts[4].stores
          ]
            // Create an array of object containing label and value
            .map(store => {
              return { label: store.name, value: store.storeCode };
            })
        );
      } // if (this.stores.regions[0] && this.stores.regions[0].districts)

      return [];
    } // storeList
  }, // computed
  async created() {
    await this.fetchStores();
  }, // created
  methods: {
    ...mapMutations('filter', [
      'setStoreCodes',
      'setDaysOfWeek',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared'
    ]), // mapMutations
    ...mapActions('store', ['fetchStores']),
    startingPeriodChanged(val) {
      this.setStartingPeriod({ startingPeriod: val });
    }, // startingPeriodChanged
    endingPeriodChanged(val) {
      this.setEndingPeriod({ endingPeriod: val });
    }, // endingPeriodChanged
    startingComparedChanged(val) {
      this.setStartingCompared({ startingCompared: val });
    }, // startingComparedChanged
    endingComparedChanged(val) {
      this.setEndingCompared({ endingCompared: val });
    }, // endingComparedChanged
    storeCodesChanged(val) {
      this.setStoreCodes({ storeCodes: val });
      this.refreshPage();
    }, // storeCodesChanged
    daysOfWeekChanged(val) {
      this.setDaysOfWeek({ daysOfWeek: val });
    }, // daysOfWeekChanged
    refreshPage() {
      this.$emit('refreshPage');
    }, // refreshPage
    storeSelectAll() {
      this.filter.storeCodes = this.storeList.map(store => store.value);
      this.refreshPage();
    }, // storeSelectAll
    storeUnselectAll() {
      this.filter.storeCodes = [];
      this.refreshPage();
    }, // storeUnselectAll
    submitDate() {
      this.$refs.periodFilter.hide();
      this.refreshPage();
    }, // submitDate
    hide() {
      this.$refs.periodFilter.hide();
    } // hide
  } // methods
}; // export default
