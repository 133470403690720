import { checkPermission } from '@/api/utils';
import store from '../store/index';
export default [
  {
    path: '/tools/billing',
    name: 'tools:billing',
    component: () => import('@/pages/Tools/Billing.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.BillingManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/tools/extension');
      }
    }
  }, // tools:billing
  {
    path: '/tools/billing/payment-method',
    name: 'tools:billing:payment-method',
    component: () => import('@/pages/Tools/PaymentMethod.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.PaymentMethodsManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/tools/extension');
      }
    }
  }, // settings:payment-method
  {
    path: '/tools/import',
    name: 'tools:import',
    component: () => import('@/pages/Tools/Extension.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/tools/billing');
      }
    }
  }, // tools:extension
  {
    path: '/tools/import/upload-result/:id',
    name: 'tools:import:upload-result',
    component: () => import('@/pages/Tools/UploadResult.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      // const viewAllowed =
      //   store.state['user']?.userPermissions?.StoreManagement?.VIEW;
      // if (viewAllowed) {
      //   next();
      // } else {
      //   next('/tools/billing');
      // }
      next();
    }
  }, // tools:extension:upload-result
  {
    path: '/tools/extension/manage-template',
    name: 'tools:extension:manage-template',
    component: () => import('@/pages/Tools/ManageTemplate.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowStoreManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/tools/billing');
      }
    }
  }, // tools:extension:manage-template
  {
    path: '/tools/log',
    name: 'tools:log',
    component: () => import('@/pages/Tools/Log.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowImportlogView');
      if (viewAllowed) {
        next();
      } else {
        next('/tools/ftp-setup');
      }
    }
  }, // tools:log
  {
    path: '/tools/ftp-setup',
    name: 'tools:ftp-setup',
    component: () => import('@/pages/Tools/FTPSetup.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions['Extension-FTPConnectionSetup']
          ?.UPDATE;
      if (viewAllowed) {
        next();
      } else {
        next('/tools/orders');
      }
    }
  }, // tools:ftp-setup
  {
    path: '/tools/orders',
    name: 'tools:orders',
    component: () => import('@/pages/Store/Orders/WaitingForReview.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  },
  {
    path: '/tools/orders/waiting-for-review',
    name: 'tools:orders:waiting-for-review',
    component: () => import('@/pages/Store/Orders/WaitingForReview.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  },
  {
    path: '/tools/orders/waiting-for-final-order',
    name: 'tools:orders:waiting-for-final-order',
    component: () => import('@/pages/Store/Orders/WaitingForFinalOrder.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  },
  {
    path: '/tools/orders/waiting-for-payment',
    name: 'tools:orders:waiting-for-payment',
    component: () => import('@/pages/Store/Orders/WaitingForPayment.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  },
  {
    path: '/tools/orders/waiting-for-schedule',
    name: 'tools:orders:waiting-for-schedule',
    component: () => import('@/pages/Store/Orders/WaitingForSchedule.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  },
  {
    path: '/tools/orders/finished',
    name: 'tools:orders:finished',
    component: () => import('@/pages/Store/Orders/Finished.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.OrderManagement?.VIEW;
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/plan');
      }
    }
  }
];
