
import { mapMutations, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('periodicReport', ['lastTimeStamp'])
  },
  // beforeDestroy() {
  //   this.resetFilterForm();
  // },
  methods: {
    ...mapMutations('periodicReport', ['resetFilterForm'])
  }
};
