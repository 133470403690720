import { GetRequestFidaExport, PostRequest, PostRequestEN } from '@/util/http';
import store from '@/store/';
const  demosStores = require('@/services/demostores.json')
export function APIFetchStores() {
  return PostRequest('locationHierarchy');
}
export async function APIFetchStoresFidaApi() {
  if (userCheck()) {
    return getFakeData()
  } else {
  return GetRequestFidaExport('api/getUserLocations');
  }
}
export const APIGetAllStores = (): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const apiResponse = await PostRequest('locationHierarchy');
      const storeList =
        apiResponse.data && apiResponse.data.stores
          ? [...apiResponse.data.stores]
          : [];
      resolve(storeList);
    } catch (error) {
      reject(error);
    }
  });
};
export function APIFetchStoreRanking(params: any) {
  return PostRequest('store/ranking/ascending', params);
} // APIFetchStoreRanking

export function APIFetchStoreDistribution(params: any) {
  return PostRequest('kpis/distribution/byStore', params);
} // APIFetchDemographicsByWeek

export function APIFetchStoresList(params: any) {
  return PostRequest('allStores', params);
} // APIFetchPowerHourByPos

export function APIFetchStoreDistributionEN(params: any) {
  return PostRequestEN('kpis/distribution/byStore', params);
}
function userCheck() {
  // let { user } = store.state['user']['defaultSettings'];
  // let isDemo = user?.response['userDetails']['tenant']['isDemo'];
  // return isDemo;
  return false;
}

let fakeTimings =  [
    {
      "day": "monday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "tuesday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "wednesday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "thursday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "friday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "saturday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    },
    {
      "day": "sunday",
      "openingTime": "11:00:00",
      "closingTime": "19:00:00"
    }
  ]
  demosStores.dataAccessStores.forEach((itm) => itm.storeTimings = fakeTimings)
  demosStores.myStores.forEach((itm) => itm.storeTimings = fakeTimings)
function getFakeData() {


  let data = {
    data: {
      "status": 200,
      "message": "Success",
      "transKey": "SUCCESS",
      "response": demosStores,
      "callName": ""
    }
  }
  return data
}