
interface Menu {
  name: string;
  title: string;
}

export default {
  props: {
    title: { default: 'Flow Service', type: String },
    subtitle: { default: 'Connect IoT and Apps to your stores', type: String }
  },
  data() {
    return {
      menuItems: [
        {
          name: 'marketplace:plan',
          title: 'Flow Packages'
        },
        {
          name: 'marketplace:add-ons',
          title: 'Flow Add-ons'
        },
        {
          name: 'marketplace:sensor',
          title: 'IoT Sensors'
        }
      ]
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    }
  },
  methods: {
    isActiveMenu(menu: Menu) {
      return this.currentRoute === menu.name;
    }
  }
};
