import CityController from './CityController';
import { ClientManager } from '@/util/client';
import CountryController from './CountryController';
import DistrictController from './DistrictController';
import PrefectureController from './PrefectureController';
import RegionController from './RegionController';

export interface LocationControllers {
  country: CountryController;
  region: RegionController;
  prefecture: PrefectureController;
  district: DistrictController;
  city: CityController;
}

export default (clientManager: ClientManager): LocationControllers => ({
  country: new CountryController(clientManager.coreClient),
  region: new RegionController(clientManager.coreClient),
  prefecture: new PrefectureController(clientManager.coreClient),
  district: new DistrictController(clientManager.coreClient),
  city: new CityController(clientManager.coreClient)
});
