
  import { mapActions, mapGetters, mapState } from 'vuex';
  import PaginationMixin from '@/mixins/PaginationMixin';
  import { addSign } from '@/util/replaceFidaKpi';

  export default {
    mixins: [PaginationMixin],
    props: {
      entrancemode: {
        type: String,
        required: true
      },
      entranceaverage: {
        type: Boolean,
        required: true
      },
      setentrancemode: {
        type: Function,
        required: true
      },
      setentranceaverage: {
        type: Function,
        required: true
      },
      entranceinout: {
        type: String,
        required: true
      },
      setentranceinout: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        entranceModeLocalDropdown: { label: this.$t('weekly'), value: 'weekly' },
        entranceModeLocal: '',
        average: true,
        trafficCountingFlow:'IN',
        mode: 'weekly',
        expanded: [],
        loading2:false,
        columns: [],
        weatherIcons: [],
        columnMode: {
          weekly: [
            {
              name: 'storeName',
              align: 'center',
              label: this.$t('store_name'),
              field: 'storeName',
              sortable: true,
              required: true,
              format: val => `${val}`
            },
            {
              name: 'storeCode',
              align: 'center',
              label: this.$t('code'),
              field: 'storeCode',
              headerClasses: 'entrance-col',
              sortable: true
            },
            {
              name: 'entranceName',
              align: 'center',
              label: this.$t('TrafficReport.EntranceName'),
              field: 'entranceName',
              headerClasses: 'entrance-col',
              sortable: true
            }
          ],
          hourly: [
            {
              name: 'storeName',
              label: this.$t('store_name'),
              align: 'center',
              field: 'storeName',
              sortable: true,
              required: true,
              format: val => `${val}`
            },
            {
              name: 'storeCode',
              label: this.$t('code'),
              align: 'center',
              field: 'storeCode',
              headerClasses: 'entrance-col',
              sortable: true
            },
            {
              name: 'entranceName',
              align: 'center',
              label: this.$t('entranceName'),
              field: 'entranceName',
              headerClasses: 'entrance-col',
              sortable: true
            }
          ]
        },
        rowData: [],
        entranceData: []
      };
    }, // data
    computed: {
      ...mapGetters('filter', [
        'getStoreCodesList',
        'getDaysOfWeekList',
        'getStartingPeriod',
        'getEndingCompared',
        'getStartingCompared',
        'getEndingPeriod'
      ]),

      // ...mapState('traffic', ['byEntrance', 'byWeek']),
      // ...mapState('traffic', ['trafficInList', 'trafficOutList', 'trafficList'])
      ...mapState('traffic', [
      'byEntrance',
      'byWeek',
      'trafficInList',
      'trafficOutList',
      'trafficList',
      'loading'
    ]),

    },
    watch: {
      trafficOutList: {
        handler: 'handleTrafficListsChange',
      },

      entranceaverage: async function(newValue) {

        this.setentranceaverage({ average: newValue });
        await this.changeWeeklyMode();
        await this.changeEntranceMode();
      },
      entranceinout: async function(newValue) {
        this.setentranceinout({ trafficCountingFlow: newValue });
        await this.changeWeeklyMode();
        await this.changeEntranceMode();
      },
      //setentranceinout
      entranceModeLocal: async function(newValue) {
        this.setentrancemode({ mode: newValue });
        this.mode = newValue;
        await this.changeWeeklyMode();
        await this.changeEntranceMode();
        this.columns = this.columnMode[this.entrancemode];
      },

    }, //watch
    async created() {

      this.entranceModeLocal = this.entrancemode;
      try {
        const days = this.getDaysOfWeekList.split(',');
        days.map(day => {
          this.columnMode.weekly.push({
            name: day,
            align: 'center',
            label: this.$t(day),
            field: day,
            headerClasses: 'entrance-col',
            sortable: true
          });
        });

        this.columns = this.columnMode[this.mode];
        this.populateRowDataByWeek();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Comparison Error: ${error}`
        });
      }
    }, // created

    methods: {

      ...mapActions('traffic', ['fetchWeeklyInOut','fetchEntranceInOut','fetchTrafficByWeek', 'fetchTrafficByEntrance']),
      addSigns(kpi,val){
        return addSign(kpi,val);
      },
  
     displayValue(col) {
        if (col.name === 'storeName' || col.name === 'storeCode' || col.name === 'entranceName') {
          return col.value;
        } else {
          return col.value.toLocaleString();
        }
      },
      changeKeyObjects(arr, replaceKeys) {
        return arr.map(item => {
          const newItem = {};
          Object.keys(item).forEach(key => {
            newItem[replaceKeys[key]] = item[[key]];
          });
          return newItem;
        });
      },
      getStoreEntranceData: function(code) {
        if (code && this.byEntrance) {
          let entrances = this.byEntrance.find(ent => ent.storeCode === code);

          let data = [];
          if (this.mode === 'hourly') {
            data = Object.values(entrances?.traffic).map((ent, key) => {
              let columnDataHourly = {};
              Object.keys(ent)
                .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                .forEach((v, key2) => {
                  Object.assign(columnDataHourly, {
                    ['entrance ' + v]: ent[v]
                  });
                });
              return {
                storeName: '',
                storeCode: '',
                entranceName: Object.keys(entrances.traffic)[key],
                ...columnDataHourly
              };
            });
          } else {
            const days = this.getDaysOfWeekList?.split(',');

            data = Object.values(entrances?.traffic).map((ent, key) => {
              let datas = entrances.traffic[Object.keys(entrances.traffic)[key]];
              let d = {};

              days.map(day => {
                Object.keys(datas).map(k => {
                  if (k.toLowerCase() === day) {
                    d[k] = datas[k];
                  }
                });
              });

              return {
                storeName: '',
                storeCode: '',
                entranceName: Object.keys(entrances.traffic)[key],
                ...d
              };
            });
          }
          return data;
        } else {
          return {};
        }
      },
      changeWeeklyMode: async function() {
        try {
            var trafficInOut;
            if(this.entranceinout == 'IN')
              trafficInOut = this.trafficInList.trafficInList;
            else
              trafficInOut = this.trafficOutList.trafficOutList;

            this.fetchWeeklyInOut({trafficInOut:trafficInOut,weeklyOrHourly:this.mode,modeInOut:this.entranceinout,startDate:this.getStartingPeriod, endDate:this.getEndingPeriod,storeCodes: this.getStoreCodesList,entranceaverage: this.entranceaverage});

        } catch (error) {
      // Handle errors (e.g., display error message)
      console.error('Error:', error);
    }

      },
      changeEntranceMode: async function() {
        try {
          var trafficInOut;
          if(this.entranceinout == 'IN')
            trafficInOut = this.trafficInList.trafficInList;
          else
            trafficInOut = this.trafficOutList.trafficOutList;

          await this.fetchEntranceInOut({trafficInOut:trafficInOut,weeklyOrHourly:this.mode,modeInOut:this.entranceinout,storeCodes:this.getStoreCodesList,startDate:this.getStartingPeriod, endDate:this.getEndingPeriod,entranceaverage: this.entranceaverage});
        await this.populateRowDataByWeek();
        // this.loading = false;
      } catch (error) {
      // Handle errors (e.g., display error message)
      console.error('Error:', error);
    } finally{
      // this.loading = false;
    }
      },
      populateRowDataByWeek: function() {
        this.rowData = [];
        this.columnMode.hourly = this.columnMode.hourly.slice(0, 3);
        if (this.mode === 'hourly') {
          Object.keys(this.byWeek[0].weeklyTraffic)
            .sort((a, b) => (a < b ? -1 : a > 1 ? 1 : 0))
            .map(v => {
              this.columnMode.hourly = [
                ...this.columnMode.hourly,
                {
                  name: 'entrance ' + v,
                  label: v,
                  align: 'center',
                  field: 'entrance ' + v,
                  headerClasses: 'entrance-col',
                  sortable: true
                }
              ];
            });
        }
        this.byWeek?.forEach(property => {
          let columnMode = {};
          if (this.mode === 'weekly') {
            columnMode = {
              monday: property.weeklyTraffic['Monday'],
              tuesday: property.weeklyTraffic['Tuesday'],
              wednesday: property.weeklyTraffic['Wednesday'],
              thursday: property.weeklyTraffic['Thursday'],
              friday: property.weeklyTraffic['Friday'],
              saturday: property.weeklyTraffic['Saturday'],
              sunday: property.weeklyTraffic['Sunday']
            };
          } else {
            let columnDataHourly = {};
            Object.keys(property.weeklyTraffic).map((v, key) => {
              Object.assign(columnDataHourly, {
                ['entrance ' + v]: Object.values(property.weeklyTraffic)[key]
              });
            });
            columnMode = columnDataHourly;
          }
          this.rowData.push({
            storeName: property.storeName,
            storeCode: property.storeCode,
            ...columnMode
          });
        });
      },
      async handleTrafficListsChange() {
    // Check if necessary data is available
    try
    {
      if (this.trafficOutList && this.trafficInList) {
        await this.changeWeeklyMode();
        await this.changeEntranceMode();
      }
    }
    catch (error) {
        console.lopg(error);
      }
      }
    } // methods
  }; // export default
  