import { PostRequest } from '@/util/http';

export function APIImportUpload(formData: any, importRequestBody) {
  // const config = {
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // };
  return PostRequest(`data/import/${importRequestBody.fileType}`, formData);
} // APIFetchExpectations

export function APIDownloadSample(params: any) {
  return PostRequest(`data/import/template`, params);
} // APIFetchExpectations

export function APIDownloadManualFile(params: any) {
  return PostRequest(`data/import/template`, params);
}
