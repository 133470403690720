import Plan, { BillingModel, SubscriptionType } from '@/models/Plan';
import BillingController from '@/services/account/BillingController';
import TenantController from '@/services/account/TenantController';
import PlanController from '@/services/management/PlanController';

export interface GetPlansResult {
  plans: Plan[];
}

export interface GetAddOnsResult {
  addOns: Plan[];
}

export default class PlanRepository {
  private readonly planController: PlanController;
  private readonly tenantController: TenantController;
  private readonly billingController: BillingController;

  constructor(
    planController: PlanController,
    tenantController: TenantController,
    billingController: BillingController
  ) {
    this.planController = planController;
    this.tenantController = tenantController;
    this.billingController = billingController;
  }

  public async getPlans(billingModel: BillingModel = BillingModel.MONTHLY) {
    const { content } = await this.planController.getPlans(
      this.toSubscriptionType(billingModel)
    );
    return {
      plans: content.map(dto => Plan.fromDTO(dto))
    } as GetPlansResult;
  }

  public async changePlan(tenantName: string, cardId: string, plan: string) {
    const tenant = await this.tenantController.getTenant(tenantName);
    await this.billingController.changeTenantPlan({
      tenantId: tenant.id,
      cardId,
      plan
    });
  }

  public async getAddOns(billingModel: BillingModel = BillingModel.MONTHLY) {
    const { content } = await this.planController.getAddOns(
      this.toSubscriptionType(billingModel)
    );
    return {
      addOns: content.map(dto => Plan.fromDTO(dto))
    };
  }

  private toSubscriptionType(billingModel: BillingModel) {
    return billingModel === BillingModel.ANNUALLY
      ? SubscriptionType.YEARLY
      : SubscriptionType.MONTHLY;
  }
}
