import { render, staticRenderFns } from "./SurveyModel.vue?vue&type=template&id=62c4c7d0&scoped=true&"
import script from "./SurveyModel.vue?vue&type=script&lang=js&"
export * from "./SurveyModel.vue?vue&type=script&lang=js&"
import style0 from "./SurveyModel.vue?vue&type=style&index=0&id=62c4c7d0&prod&lang=css&"
import style1 from "./SurveyModel.vue?vue&type=style&index=1&id=62c4c7d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c4c7d0",
  null
  
)

/* custom blocks */
import block0 from "./SurveyModel.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./SurveyModel.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QDialog,QCard,QCardSection,QSpace,QBtn,QSelect,QItem,QItemSection,QColor,QField,QInput,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSpace,QBtn,QSelect,QItem,QItemSection,QColor,QField,QInput})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
