
import { createColumn } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';

const columns = [
  createColumn('storeName', 'Store Name'),
  createColumn('storeNumber', 'Store Number'),
  createColumn('region', 'Region'),
  createColumn('district', 'District'),
  createColumn('prefecture', 'Prefecture'),
  createColumn('city', 'City'),
  createColumn('devices', 'Devices', {
    format: devices => devices.join(' ')
  }),
  createColumn('status', 'Status', {
    format: status => (status ? 'Enabled' : 'Disabled')
  }),
  createColumn('action', 'Action')
];

export default {
  mixins: [PaginationMixin],
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    columns
  })
};
