import Button from '@/components/Common/Button.vue';
import Buttons from '@/components/Common/Buttons.vue';
import DatePicker from '@/components/Common/DatePicker.vue';
import Drawer from '@/components/Common/Drawer.vue';
import Field from '@/components/Common/Field.vue';
import FilterDropdown from '@/components/Common/FilterDropdown.vue';
import Filters from '@/components/Common/Filters.vue';
import Input from '@/components/Common/Input.vue';
import Pagination from '@/components/Common/Pagination.vue';
import TimePicker from '@/components/Common/TimePicker.vue';
import ImagePicker from '@/components/Common/ImagePicker.vue';
import Select from '@/components/Common/Select.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    'c-btn': Button,
    'c-btns': Buttons,
    'c-filters': Filters,
    'c-filter-dropdown': FilterDropdown,
    'c-field': Field,
    'c-input': Input,
    'c-date-picker': DatePicker,
    'c-time-picker': TimePicker,
    'c-image-picker': ImagePicker,
    'c-select': Select,
    'c-drawer': Drawer,
    'c-pagination': Pagination
  }
});
