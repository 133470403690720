import { BaseCompanyDTO, CompanyDTO } from '@/models/Company';

import { AxiosInstance } from 'axios';
import { ContactDTO } from '@/models/Contact';
import {
  APIGetTenantContactsSSO,
  APIGetUserDetailsSSO,
  APIUpdateTenantSSO
} from '@/api/authSSO';

export type GetCompanyResponse = CompanyDTO;
export type GetCompanyResponseSSO = {
  id: string;
  name: string;
  code?: string;
  registrationNumber?: string;
  phone?: string;
  addressStreet?: string;
  city?: string;
  businessType?: string;
  browserHq?: string;
  browserStore?: string;
  totalStores?: number;
  zipcode?: string;
  os?: string;
  industry?: string;
};

export interface CompanyRequest extends BaseCompanyDTO {
  contacts: ContactDTO[];
  industry?: { id: string; name: string };
}

export type CreateCompanyRequest = CompanyRequest;
export type CreateCompanyResponse = CompanyDTO;
export type UpdateCompanyRequest = CompanyRequest;
export type UpdateCompanyResponse = CompanyDTO;

export default class CompanyController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getCompany() {
    const {
      response: {
        userDetails: { tenant }
      }
    } = await APIGetUserDetailsSSO({});
    const { response } = await APIGetTenantContactsSSO({
      tenantId: tenant._id
    });
    try
    {
      const { businessContacts, operationContacts } = response.tenantContacts[0];
      return { tenant, businessContacts, operationContacts };
    }
    catch(e)
    {
      const businessContacts = [];
      const operationContacts = [];
      return  {tenant,businessContacts, operationContacts};
    }
  }

  public async createCompany(req: CreateCompanyRequest) {
    const { data } = await this.client.post('/companies', req);
    return data as CreateCompanyResponse;
  }

  public async updateCompany(data) {
    const { response } = await APIUpdateTenantSSO({ data });
    return response;
  }
}
