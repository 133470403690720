
import brands from "@/services/brands";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedBrand", "brandList"],
  // props: {
  //   selectedBrand: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
       loadingBtn: false,
      configuration: true,
      brandName: "",
      description: "",
      title: "",
    };
  },
 watch: {
    configuration(newValue){
      if(!newValue){
        this.$emit("closeModel", false);
      }
    }
  },
  mounted() {
    if (this.selectedBrand) {
      this.title =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.brand;
      this.brandName = this.selectedBrand.title;
      this.description = this.selectedBrand.description;
    } else {
      this.title =
        this.$store.state.user.translate.add +
        " " +
        this.$store.state.user.translate.brand;
    }
  },
  methods: {
    change(){
      var notValid = this.brandList.find((v) => v.title == this.brandName)
      if(notValid && !this.selectedBrand?._id){
         setTimeout(() => {
            this.$refs.provider.applyResult({
            errors: [
              this.$store.state.user.translate.brand_already_exist,
            ], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
          }, 1000);
      }
     
    },
    createBrand() {
      if (this.selectedBrand) {
        let brandData = {
          title: this.brandName,
          description: this.description,
          brandId: this.selectedBrand._id,
        };
          this.loadingBtn = true;
        brands
          .updateBrand(brandData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
              this.loadingBtn = false;
            this.$emit("updateBrands");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
              this.loadingBtn = false;
            this.$emit("closeModel", false);
          });
      } else {
        let newBrandData = [];
        let brandData = {
          title: this.brandName,
          description: this.description,
        };
         var isExist = this.brandList.find((v) => v.title.toLowerCase() == this.brandName.toLowerCase());
        if (isExist) {
          this.$q.notify({
            message: this.$store.state.user.translate.brand_already_exist,
            color: "red",
          });
          return false;
        }
        newBrandData.push(brandData);
        this.loadingBtn = true;
        brands
          .createBrands(newBrandData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.brand_created_successfully,
              color: "green",
            });
              this.loadingBtn = false;
            this.$emit("updateBrands");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
              this.loadingBtn = false;
            this.$emit("closeModel", false);

          });
      }
    },
  },
};
