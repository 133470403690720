
import Vue from 'vue';
import { props } from '@/components/LandingPage/ButtonLinkDefs';
import ButtonLink from '@/components/LandingPage/ButtonLink.vue';

export default Vue.component('LoginButtonLink', {
  functional: true,
  props,
  render: (h, context) => {
    const { props, scopedSlots } = context;
    return h(ButtonLink, {
      props,
      scopedSlots
    });
  }
});
