export default class BillingRecord {
  id: string;
  balance: number;
  billingAmount: number;
  billingDate?: Date;
  dueDate?: Date;
  invoiceNumber: string;
  paymentAmount?: number;
  paymentDate?: Date;
  paymentMethod: string;
  status: string;
  storeId: string;
  storeName: string;
  tenantId: string;
  tenantName: string;
  type: string;

  constructor(initializer: Partial<BillingRecord>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: BillingRecordDTO): BillingRecord {
    return new BillingRecord({
      id: dto.id,
      balance: Number.parseFloat(dto.balance),
      billingAmount: Number.parseFloat(dto.billingAmount),
      billingDate: dto.billingDate == null ? null : new Date(dto.billingDate),
      dueDate: dto.dueDate == null ? null : new Date(dto.dueDate),
      invoiceNumber: dto.invoiceNumber,
      paymentAmount: Number.parseFloat(dto.paymentAmount),
      paymentDate: dto.paymentDate == null ? null : new Date(dto.paymentDate),
      paymentMethod: dto.paymentMethod,
      status: dto.status,
      storeId: dto.storeId,
      storeName: dto.storeName,
      tenantId: dto.tenantId,
      tenantName: dto.tenantName,
      type: dto.type
    });
  }
}

export interface BillingRecordDTO {
  id: string;
  balance: string;
  billingAmount: string;
  billingDate?: string;
  dueDate?: string;
  invoiceNumber: string;
  paymentAmount: string;
  paymentDate?: string;
  paymentMethod: string;
  status: string;
  storeId: string;
  storeName: string;
  tenantId: string;
  tenantName: string;
  type: string;
}
