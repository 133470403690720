export const colors = {
  green: '#A7C040',
  white: '#FFF',
  transparent: 'transparent',
  darkGrey: '#383D42',
  lightGreen: '#E8F1C1',
  red: '#E02020'
};

export const dims = {
  font: {
    bold: 700
  }
};
