import { checkPermission } from '@/api/utils';
import { UPDATE_COMPANIES, VIEW_COMPANIES } from '@/consts/permissions';
import store from '../store/index';

export default [
  {
    path: '/marketplace/plan',
    name: 'marketplace:plan',
    component: () => import('@/pages/Marketplace/Plan.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowMarketplaceView');
      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/add-ons');
      }
    }
  }, // marketplace:plan
  {
    path: '/marketplace/add-ons',
    name: 'marketplace:add-ons',
    component: () => import('@/pages/Marketplace/AddOns.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.MarketAddons?.VIEW;

      if (viewAllowed) {
        next();
      } else {
        next('/marketplace/sensor');
      }
    }
  }, // marketplace:add-ons
  {
    path: '/marketplace/sensor',
    name: 'marketplace:sensor',
    component: () => import('@/pages/Marketplace/Sensor.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed =
        store.state['user']?.userPermissions?.MarketSensor?.VIEW;

      if (viewAllowed) {
        next();
      } else {
        next('/settings/default');
      }
    }
  }, // marketplace:sensor,
  {
    path: '/settings/default',
    name: 'settings:default',
    exact: true,
    component: () => import('@/pages/Settings/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowDefaultSettingsView');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/peer-group');
      }
    }
  }, // settings:default
  {
    path: '/settings/peer-group',
    name: 'settings:peer-group',
    exact: true,
    component: () => import('@/pages/Settings/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPeerGroupManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/area-manager');
      }
    }
  }, // settings:peer-groups
  {
    path: '/settings/area-manager',
    name: 'settings:area-manager',
    exact: true,
    component: () => import('@/pages/Settings/index.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAreaManagerManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/company');
      }
    }
  }, // settings:area-manager
  {
    path: '/settings/peer-group/create',
    name: 'settings:peer-group:create',
    exact: true,
    component: () => import('@/pages/Settings/CreatePeerGroup.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPeerGroupManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/area-manager');
      }
    }
  }, // settings:peer-group:create
  {
    path: '/settings/peer-group/view',
    name: 'settings:peer-group:view',
    exact: true,
    component: () => import('@/pages/Settings/ViewPeerGroup.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPeerGroupManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/area-manager');
      }
    }
  },
  {
    path: '/settings/peer-group/edit',
    name: 'settings:peer-group:edit',
    exact: true,
    component: () => import('@/pages/Settings/EditPeerGroup.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowPeerGroupManagementEdit');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/area-manager');
      }
    }
  },
  {
    path: '/settings/area-manager/create',
    name: 'settings:area-manager:create',
    exact: true,
    component: () => import('@/pages/Settings/CreateAreaManager.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAreaManagerManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/company');
      }
    }
  },
  {
    path: '/settings/area-manager/view',
    name: 'settings:area-manager:view',
    exact: true,
    component: () => import('@/pages/Settings/ViewAreaManager.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAreaManagerManagementView');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/company');
      }
    }
  }, // settings:area-manager:create
  {
    path: '/settings/area-manager/edit',
    name: 'settings:area-manager:edit',
    exact: true,
    component: () => import('@/pages/Settings/EditAreaManager.vue'),
    meta: {
      accessGiven: true
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowAreaManagerManagementCreate');
      if (viewAllowed) {
        next();
      } else {
        next('/settings/company');
      }
    }
  }, // settings:area-manager:edit
  {
    path: '/settings/company',
    name: 'settings:company',
    exact: true,
    component: () => import('@/pages/Settings/index.vue'),
    meta: {
      accessGiven: true,
      authorityCodes: [VIEW_COMPANIES, UPDATE_COMPANIES]
    },
    beforeEnter: (to, from, next) => {
      const viewAllowed = checkPermission('FlowCompanyInfoView');
      if (viewAllowed) {
        next();
      } else {
        next('/*');
      }
    }
  } // settings:company
];
