
import { createColumn } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';
export default {
  mixins: [PaginationMixin],
  props: {
    loading: { type: Boolean, required: false, default: false },
    permissionMap: { type: Array, required: true, default: () => [] },
    editable: { type: Boolean, default: true }
  },
  data() {
    return {
      columns: [
        createColumn('name', this.$t('SettingsManagement.module')),
        createColumn('Create', this.$t('SettingsManagement.create')),
        createColumn('View', this.$t('SettingsManagement.view')),
        createColumn('Update', this.$t('SettingsManagement.update')),
        createColumn('Delete', this.$t('SettingsManagement.delete')),
        createColumn('Import', this.$t('SettingsManagement.import')),
        createColumn('Download', this.$t('SettingsManagement.download')),
        createColumn('Upload', this.$t('SettingsManagement.upload'))
      ]
    };
  },
  methods: {
    permissionChanged(module, action) {
      if (this.editable) {
        this.$emit('permissionChanged', {
          module,
          action
        });
      }
    }
  }
};
