
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI } from '@/util/error';
import AreaManagerTable from '@/components/Settings/AreaManager/Table.vue';
import { checkPermission } from '@/api/utils';
export default {
  components: { AreaManagerTable },
  mixins: [CommonMixin],
  data() {
    return {
      data: [],
      loading: true,
      checkPermission: checkPermission('FlowAreaManagerManagementCreate')
    };
  },
  async mounted() {
    await this.loadAreaManagers();
    this.loading = false;
  },
  methods: {
    async loadAreaManagers() {
      try {
        const { areaManagers } = await this.$repo.areaManager.getAreaManagers();
        this.data = areaManagers;
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
