
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    showPreviewModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    toggleModal: {
      type: Function,
      default: () => {},
      required: true,
    },
    submitReport: {
      type: Function,
      default: () => {},
      required: true,
    },
    isReportSaving: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...mapState("periodicReport", ["selectedStore"]),
    ...mapState("user", ["profile"]),
    ...mapGetters("filter", ["getPulseStartingPeriod"]),
    getButtonLabel() {
      if (this.$route.name === 'periodic_report_list' || this.$route.name === 'periodic_report_list_demo') {
        return this.$store.state.user.translate.download;
      } else {
        return this.$t('Library.OK');
      }
    }
  },
};
