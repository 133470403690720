
import stores from "@/services/stores";
import Vue from "vue";
import { mapActions } from 'vuex';
import store from '@/store/';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedSensor", "allSensor"],
  data() {
    return {
      changedFeature:false,
      urlImage: null,
      selectedFile: null,
      features: [],
      sensorDetail: {
        isConnected: 1,
        isEnabled: null,
        pathwayDepth:null,
        detectionLength:null,
        brand:"",
        defaultTrafficFlow: 'out'
      },
      disabledInputs: {
        traffic: true,
        pb: true,
        dm: true,
        dw: true,
      },
      //
      configuration: true,
      loadingBtn: false,
      radioChecked: 'pb',
      brand: null,
      defaultTrafficFlow: [
        {
          label: "In",
          value: "in",
        },
        {
          label: "Out",
          value: "out",
        },
        {
          label: "None",
          value: "",
        },
      ],
      selectDeviceStatus: [
        {
          label: this.$store.state.user.translate.enable,
          value: 1,
        },
        {
          label: this.$store.state.user.translate.disable,
          value: 0,
        },
      ],
      selectbrandOptions: [
        {
          label: this.$store.state.user.translate.brickstream,
          value: "brickstream",
        },
        {
          label: this.$store.state.user.translate.irysis,
          value: "irysis",
        },

        {
          label: this.$store.state.user.translate.tdi,
          value: "tdi",
        },

        {
          label: this.$store.state.user.translate.quividi,
          value: "quividi",
        },
        {
          label: this.$store.state.user.translate.datto,
          value: "datto",
        },

        {
          label: this.$store.state.user.translate.vion,
          value: "vion",
        },
        {
          label: this.$store.state.user.translate.clicker,
          value: "clicker",
        },
      ],
      loadedSensor: {}
    };
  },
  computed: {
    title() {
      return this.selectedSensor.btnsShow === "edit"
        ? this.$store.state.user.translate.edit_sensor_details
        : this.selectedSensor.btnsShow === "view"
        ? this.$store.state.user.translate.view_sensor_details
        : this.$store.state.user.translate.add_sensor_details;
    },
  },
  watch: {
    // selectedSensor(newValue){
    //    this.sensorList = newValue;
    //    console.log(this.sensorList, '-> list')
    // },
    sensorDetail: {
      handler: function (newValue) {
        if (newValue.brand == "brickstream" || newValue.brand == "tdi") {
          this.disabledInputs = {
            traffic: false,
            pb: false,
            dm: true,
            dw: true,
          };
        } else if (newValue.brand == "irysis") {
          this.disabledInputs = {
            traffic: false,
            pb: true,
            dm: true,
            dw: true,
          };
        } else if (newValue.brand == "vion") {
          this.disabledInputs = {
            traffic: false,
            pb: true,
            dm: false,
            dw: false,
          };
        } else if (newValue.brand == "quividi") {
          this.disabledInputs = {
            traffic: true,
            pb: false,
            dm: false,
            dw: true,
          };
        } else if (newValue.brand == "datto") {
          this.disabledInputs = {
            traffic: true,
            pb: false,
            dm: true,
            dw: false,
          };
        } else if (newValue.brand == "clicker") {
          this.disabledInputs = {
            traffic: false,
            pb: true,
            dm: true,
            dw: true,
          };
        } else if (!newValue.brand) {
          this.disabledInputs = {
            traffic: true,
            pb: true,
            dm: true,
            dw: true,
          };
        } else if (newValue.brand) {
          this.disabledInputs = {
            traffic: false,
            pb: false,
            dm: false,
            dw: false,
          };
        }
      },
      deep: true,
    },
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
    // features(newValue) {
    //   this.sensorDetail.entranceId = "";
    //   this.sensorDetail.pathwayDepth = "";
    //   this.sensorDetail.detectionLength = "";
    // },
  },
  mounted() {
    if (this.selectedSensor.id && this.selectedSensor.imgUrl) {
      stores
        .ApiGetImage(this.selectedSensor.imgUrl)
        .then((res) => {
          var { url } = res.response;
          this.urlImage = url;
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    if (!this.selectedSensor.id) return;
    const {
      entranceName,
      entranceId,
      isConnected,
      isEnabled,
      defaultTrafficFlow,
      partNumber,
      brand,
      features,
      macAddress,
      serialNumber,
      firmwareVersion,
      ipAddress,
      subnetMask,
      defaultGateway,
      dns,
      poePort,
      pathwayDepth,
      detectionLength,
      installPosition,
      location,
      imgUrl,
    } = this.selectedSensor;
    this.features = features || [];
    // this.isEnabled = isEnabled
    this.sensorDetail = {
      entranceName,
      entranceId,
      isConnected,
      isEnabled,
      defaultTrafficFlow: defaultTrafficFlow ? defaultTrafficFlow: 'out' ,
      partNumber,
      brand: brand,
      macAddress,
      serialNumber,
      firmwareVersion,
      ipAddress,
      subnetMask,
      defaultGateway,
      dns,
      poePort,
      pathwayDepth,
      detectionLength,
      installPosition,
      location,
      imgUrl,
    };
    this.loadedSensor = {
      features: JSON.parse(JSON.stringify(this.features)),
      sensorDetails: JSON.parse(JSON.stringify(this.features))
    }
  },
  methods: {
      ...mapActions('user', [
      'fetchStores',
    ]),
    sendTrackingEvent(type) {
        console.log(1)
      let { profile } = store.state["user"];
      let data = {sensorDetail: {}, features: [], email: profile?.email}
      if(type == "Added") {
        data = {sensorDetail: this.sensorDetail, features: this.features, email: profile?.email}
      } else {
        console.log(2)
        Object.keys((this.sensorDetail)).forEach((k) => {
          if(JSON.stringify(this.sensorDetail[k]) !== JSON.stringify(this.loadedSensor.sensorDetails[k])) {
            data.sensorDetail[k] = this.sensorDetail[k];
          }
        })
        console.log(3)
        this.features.forEach((k) => {
          if(this.loadedSensor.features.indexOf(k) < 0) {
            data.features.push(k)
          }
        })
      }
        console.log(4)
      Vue.prototype?.$mixpanel?.track(type, data);
    },
    clcEntrance() {
      const { pathwayDepth, detectionLength, entranceId } = this.sensorDetail;
      let editMode = this.selectedSensor.btnsShow === "edit" && !entranceId
      let pthNdepth = pathwayDepth && detectionLength
      if (pthNdepth && !this.selectedSensor.btnsShow || pthNdepth && editMode) {
        this.sensorDetail.entranceId = (pathwayDepth / detectionLength);
      }
    },
    
    typeHander(val) {
      if (val === 0) {
        this.sensorDetail.isEnabled = 0;
      } else {
        this.sensorDetail.isEnabled = "";
      }
    },
    validateIp() {
      if (!this.Validate_It(this.sensorDetail.ipAddress)) {
        setTimeout(() => {
          this.$refs.ip.applyResult({
            errors: this.$store.state.user.translate.invalid_ip_address, // array of string errors
            valid: true, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }, 500);
      }
    },
    preventSpace(event) {
      if (event.keyCode === 32) {
        event.preventDefault();
        setTimeout(() => {
          this.$refs.mac.applyResult({
            errors: this.$store.state.user.translate.space_not_allowed, // array of string errors
            valid: true, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }, 500);
      }
    },
    validateMac() {
      if (!this.isValidMACAddress(this.sensorDetail.macAddress)) {
        setTimeout(() => {
          this.$refs.mac.applyResult({
            errors: this.$store.state.user.translate.invalid_mac_address, // array of string errors
            valid: true, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }, 500);
      }
    },
    methodChange(newValue) {
      if (this.sensorDetail.brand == "brickstream" || this.sensorDetail.brand == "tdi") {
        this.features = [newValue];
      }
      if (this.sensorDetail.brand == 'vion') {
        const valueMap = {
          traffic: 'trfunq',
          trfunq: 'traffic',
          dm: 'dmunq',
          dmunq: 'dm'
        };
        const targetValue = valueMap[newValue];
        if (targetValue) {
          const isExist = this.features.includes(targetValue);
          if (isExist) {
            this.features = this.features.filter(i => i !== targetValue);
          }
        }
      }
      // if(this.selectedSensor.btnsShow === "edit") return
      this.changedFeature= true;
      this.sensorDetail.entranceId = null;
      this.sensorDetail.pathwayDepth = null;
      this.sensorDetail.detectionLength = null;
    },

    isValidMACAddress(str) {
      // Regex to check valid
      // MAC_Address
      let regex = new RegExp(
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/
      );

      // if str
      // is empty return false
      if (str == null) {
        return false;
      }

      // Return true if the str
      // matched the ReGex
      if (regex.test(str) == true) {
        return true;
      } else {
        return false;
      }
    },
    Validate_It(IP) {
      // Regex expression for validating IPv4
      let ipv4 = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;

      // Regex expression for validating IPv6
      let ipv6 = /((([0-9a-fA-F]){1,4}):){7}([0-9a-fA-F]){1,4}/;

      // Checking if it is a valid IPv4 addresses
      if (IP.match(ipv4)) return true;
      // Checking if it is a valid IPv6 addresses
      else if (IP.match(ipv6)) return true;

      // Return Invalid
      return false;
    },
    onFileChanged() {
      this.selectedFile = event.target.files[0];
      const url = URL.createObjectURL(this.selectedFile);
      this.urlImage = url;
    },
    deleteStore(store) {
      this.selectedStore = store;
      this.confirm = true;
    },
    async saveUserDetails() {
      try {
        this.loadingBtn = true;
        if (this.sensorDetail.isConnected) {
          if (this.selectedFile?.name) {
            let timeStamp = Date.now();
            var fileName = timeStamp + "-" + this.selectedFile.name;
            const formData = new FormData();
            formData.append("file", this.selectedFile, fileName);
            await stores.uploadFile(formData);
            this.sensorDetail.imgUrl = fileName;
          }
        }
        this.updadeSensor();
      } catch (error) {
        console.log(error);
      }
    },
    updadeSensor() {
      if(this.sensorDetail.pathwayDepth){
        this.sensorDetail.pathwayDepth = Number(this.sensorDetail.pathwayDepth)
      }
      if(this.sensorDetail.detectionLength){
        this.sensorDetail.detectionLength = Number(this.sensorDetail.detectionLength)
      }
      //
      let findOtherSensors = this.allSensor;
      
      if (this.selectedSensor) {
        findOtherSensors = this.allSensor?.filter(
          (item) => item.id != this.selectedSensor.id
        );
      }
      //
      if (!this.features.includes('traffic') && !this.features.includes('trfunq') ) {
        delete this.sensorDetail.defaultTrafficFlow;
      }
      // if (!this.features.includes('trfunq') ) {
      //   delete this.sensorDetail.defaultTrafficFlow;
      // }
      let device = {
        storeId: this.$route.query.id,
        deviceInfo: [
          {
            ...this.sensorDetail,
            features: this.features,
          },
        ],
      };
      // add sensor
       if(findOtherSensors && findOtherSensors.length){
        device.deviceInfo.push(...findOtherSensors)
       }
      stores
        .updateSensor(device)
        .then(async() => {
            await this.fetchStores();
          this.$q.notify({
            message: this.selectedSensor.btnsShow == "edit" ? this.$store.state.user.translate.sensor_has_been_Updated_successfully : this.$store.state.user.translate.sensor_added_successfully,
            color: "green",
          });

          this.sendTrackingEvent(this.selectedSensor.btnsShow == "edit" ? "Updated" : "Added") 
          this.loadingBtn = false;
          this.$emit("updateStore");
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
          this.loadingBtn = false;
          this.$emit("closeModel", false);
        });
    },
  },
};
