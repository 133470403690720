
import users from "@/services/users";
import resources from "@/services/resources";
import tenants from "@/services/tenants";

export default {
  props: {
    openModel: Boolean,
  },
  data() {
    return {
        loadingBtn: false,
      companyName: null,
      companyId: null,
      companyPhone: null,
      industry: null,
      visible: false,
      country: 109,
      region: null,
      city: null,
      address: "",
      companyHp: null,
      userDetails: [],
      allRegions: [],
      allCities: [],
      industryOptions: [
      {
        title:this.$store.state.user.translate?.Information_technology
      },
      {
        title:this.$store.state.user.translate?.convenience_store
      },
      {
        title:this.$store.state.user.translate?.department_store
      },
      {
        title:this.$store.state.user.translate?.drug_store
      },
      {
        title:this.$store.state.user.translate?.electronics
      },
      {
        title:this.$store.state.user.translate?.fashion
      },
      {
        title:this.$store.state.user.translate?.hospitality
      },
      {
        title:this.$store.state.user.translate?.luxury_fashion
      },
      {
        title:this.$store.state.user.translate?.mall_operator
      },
      {
        title:this.$store.state.user.translate?.service_restaurant
      },
      {
        title:this.$store.state.user.translate?.sporting_goods
      },
      {
        title:this.$store.state.user.translate?.supermarket
      },
      {
        title:this.$store.state.user.translate?.other_restaurant
      },
      {
        title:this.$store.state.user.translate?.other_retail
      },
      {
        title:this.$store.state.user.translate?.other_industry
      },
      {
        title:this.$store.state.user.translate?.automobile_showroom
      },
      ],
      district: null,
      districtOptions: [],
      prefecture: null,
      prefectureOptions: [],
      countryOptions: [
        {
          id: 109,
          name: "Japan",
        },
      ],
      regionOptions: [],
      cityOptions: [],
    };
  },
  computed:{
    admin(){
      let a = this.$store.state?.user?.profile?.email.startsWith('botadmin@');
      return a
    },
    isValidFeilds(){
      if(!this.city || !this.prefecture) return;
      return true
    }
  },
  // created() {
  //   this.getCountries();
  //   this.getUserDetails();
  // },
  mounted() {
    // this.countryOptions = countries
    // this.allRegions = states
    // this.allCities = cities
    // this.visible = true
    // this.getUserDetails()
    this.getCountries();
    this.getUserDetails();
  },
  methods: {
    updateDistrictDetails(op) {
      let districtOptions = this.allRegions.find(
        (element) => this.region == element.name
      );
      if (op != "false") {
        this.district = "";
        this.prefecture = "";
        this.city = "";
      }
      this.districtOptions = districtOptions?.districts.map((v) => v.name);
    },
    updatePrefectureDetails(op) {
      let districtOptions = this.allRegions.find(
        (element) => this.region == element.name
      );
      if (op != "false") {
        this.prefecture = "";
        this.city = "";
      }
      let preFecture = districtOptions?.districts.find((v) => v.name === this.district);
      this.prefectureOptions = preFecture?.prefectures.map((v) => v.name);
    },
    updateCityDetails(op) {
      let districtOptions = this.allRegions.map((element) => element.districts).flat();
      if (op != "false") {
        this.city = "";
      }
      let preFecture = districtOptions.map((v) => v.prefectures).flat();
      let cities = preFecture?.find((v) => v.name === this.prefecture);
      this.cityOptions = cities?.cities;
    },
    checkRegions() {
      if (!this.region) {
        this.$q.notify({
          message: this.$store.state.user.translate.select_prefectures,
          color: "red",
        });
      }
    },
    async getUserDetails() {
      this.visible = true;
      this.getIndustries();
      users.getUserDetails().then((response) => {
        this.userDetails = response.data.response.userDetails;
        this.companyName = this.userDetails.tenant.intercom?.companyName;
        this.companyId = this.userDetails.tenant.intercom?.companyId;
        this.companyPhone = this.userDetails.tenant.tenantInfo?.companyPhone;
        this.industry = this.userDetails.tenant.tenantInfo?.industry;
        this.address = this.userDetails.tenant.tenantInfo?.address;
        this.setRegion();
        this.city = this.userDetails?.tenant?.tenantInfo?.city;
        this.district = this.userDetails?.tenant?.tenantInfo?.district;
        this.prefecture = this.userDetails?.tenant?.tenantInfo?.prefecture;
        // this.updateDistrictDetails("false");
        // this.updatePrefectureDetails("false");
        this.updateCityDetails("false");
        this.visible = false;
      });
    },
    async getCountries() {
      // console.log("Started")
      this.visible = true;
      var data = await resources.getCountries();
      // this.countryOptions = data.countries
      // this.countryOptions = data.countries.filter((c) => c.name == "Japan");
      this.allRegions = data.states;
      var districts = this.allRegions.map(key => {return key.districts}).flat()
      var prefectures = districts.map(key => {return key.prefectures}).flat()
      this.prefectureOptions = prefectures.map(key => {return key.name}).flat()
      // var cities = prefectures.map(key => {return key.cities}).flat()
      // this.cityOptions = cities;
      // this.regionOptions = this.allRegions.map((n) => n.name);
      // this.allCities = data.cities;
      // this.cityOptions = this.allCities;
      this.visible = false;
    },

    getIndustries() {
      // users.getIndustries().then((response) => {
      //   this.industryOptions = response.data.response;
      // });
    },

    updateStateDetails(value) {
      this.allRegions.forEach((element) => {
        if (element.country_id === value) {
          this.regionOptions.push(element);
          this.countryCode = element.country_code;
        }
      });
    },

    setRegion() {
      if (this.allRegions.length === 0) {
        setTimeout(() => {
          this.setRegion();
        }, 500);
      } else {
        // this.country = parseInt(this.userDetails?.tenant?.tenantInfo?.country);
        // this.allRegions.forEach((element) => {
        //   if (
        //     element.country_id === parseInt(this.userDetails?.tenant?.tenantInfo?.country)
        //   ) {
        //     this.regionOptions.push(element);
        //   }
        // });
        this.region = this.userDetails?.tenant?.tenantInfo?.region;

        this.setCity();
      }
    },

    setCity() {
      if (this.allCities.length === 0) {
        setTimeout(() => {
          this.setCity();
        }, 500);
      } else {
        this.allCities.forEach((element) => {
          if (element.city_id == parseInt(this.userDetails?.tenant?.tenantInfo?.city)) {
            this.cityOptions.push(element);
          }
        });
        this.city = parseInt(this.userDetails?.tenant?.tenantInfo?.city);
      }
    },
    saveCompanyInfo() {
      this.visible = true;
      let companyInfo = {
        companyName: this.companyName,
        companyPhone: this.companyPhone || "-",
        industry: this.industry,
        country: this.country,
        city: this.city,
        address: this.address,
        // region: this.region,
        prefecture: this.prefecture,
        // district: this.district,
      };
      this.loadingBtn = true;
      // this.updateUserRole()
      tenants
        .updateTenant(companyInfo)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.updated_successfully,
            color: "green",
          });
          this.loadingBtn = false;
          this.visible = false;
        })
        .catch((error) => {
          this.visible = false;
          this.loadingBtn = false;
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
  },
};
