import map from 'lodash/map';
import Authority, { AuthorityDTO } from './Authority';
import Entity from './Entity';

class Role {
  _id?: string;
  title?: string;
  description?: string;
  permissions?: Authority[];

  static fromDTO(dto: RoleDTO): Role {
    return {
      _id: dto._id,
      title: dto.title,
      description: dto.description,
      permissions: dto.permissions
        ? map(dto.permissions, authorityDto => Authority.fromDTO(authorityDto))
        : null
    };
  }
}

export interface RoleDTO extends Entity {
  _id: string;
  title: string;
  description: string;
  permissions?: AuthorityDTO[];
}

export default Role;
