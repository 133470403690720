
import { createColumn } from '@/util/table';

export default {
  name: 'StoreTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      default: null
    },
    noAction: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { noAction } = this;
    return {
      searchKeyword: ['', '', '', '', '', '', '', '', ''],
      rowData: [],
      columns: [
        createColumn('name', this.$t('SettingsManagement.name')),
        createColumn('storeNumber', this.$t('SettingsManagement.number')),
        createColumn('region', this.$t('SettingsManagement.region')),
        createColumn('district', this.$t('SettingsManagement.district')),
        createColumn('prefecture', this.$t('SettingsManagement.prefecture')),
        createColumn('city', this.$t('SettingsManagement.city')),
        createColumn('status', this.$t('SettingsManagement.status'), 
        {
          format: status => this.$t('SettingsManagement.' + status)
        }
        ),
        // createColumn('plan', this.$t('SettingsManagement.plan')),
        createColumn('action', this.$t('SettingsManagement.actions'), {
          sortable: false
        })
      ].filter(Boolean)
    };
  },
  watch: {
    data() {
      this.rowData = this.data.map(v => ({
        ...v,
        plan: v?.plan?.name || this.$t('SettingsManagement.noPlanAssigned')
      }));
    },
    searchKeyword() {
      let obj = {
        name: this.searchKeyword[0],
        storeNumber: this.searchKeyword[1],
        region: this.searchKeyword[2],
        district: this.searchKeyword[3],
        prefecture: this.searchKeyword[4],
        city: this.searchKeyword[5],
        status: this.searchKeyword[6],
        plan: this.searchKeyword[8]
      };
      let tbData = Object.values(this.data);

      this.rowData = tbData.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  }
};
