
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { MONO_TONE } from '../../util/colors';

export default {
  data() {
    return {
      loading: false,
      series: [],
      formattedValuesSelected: [],
      formattedValuesCompared: [],
      weatherIcon: [],
      temperature: [],
      chartOptions: {
        chart: {
          parentHeightOffset: 0,
          type: 'line',
          width: 485,
          zoom: {
            enabled: false
          },
          toolbar: {
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        colors: MONO_TONE,
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [2, 2],
          curve: 'straight'
        },
        legend: {
          show: false
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const dataPoint =
              w.config &&
              w.config.series &&
              w.config.series.length &&
              w.config.series[0].data
                ? w.config.series[0].data[dataPointIndex]
                : '';
            return `
            <q-card class="q-pa-md tooltip-container">
              <div class="flex q-mb-sm q-px-md justify-between">
                <label>
                  ${this.getXaxisDates(dataPoint.x)} ( ${this.$t(
                      this.getFullDay(new Date(dataPoint.x).getDay())
                 )} )
                </label>
                    <div class="q-ml-xl flex">
                        ${
                          this.getStoreCodesList.split(',').length === 1
                            ? `<div class="flex q-ml-sm items-center justify-between weather-tooltip">
                                <i class="wi ${dataPoint.z.weatherIcon}"></i>
                                </div>
                                <div class="">
                                ${dataPoint.z.temperature.toFixed(2) + '°C'}
                                </div>`
                            : ''
                        }
                   </div>
                    </div>
                      <div class="tooltip-title flex q-mx-md items-center">
                        <div
                          class="tooltip-title-color"
                          style="background:${w.config.colors[0]}"
                        >
                        </div>
                        <label>${this.$t(w.globals.seriesNames[0])} :</label>
                        <div class="q-ml-xs text-bold">
                          ${series[0][dataPointIndex]}
                        </div>
                      </div>
            </q-card>`;
          },
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        },
        grid: {
          row: {
            color: '#fff', // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: Number,
          tickAmount: 3
        },

        xaxis: {
          tooltip: { enabled: false },
          labels: {
            formatter: val => {
              let lang = this.$store.state.user.locale
              return moment(val).locale(lang).format(lang == 'ja' ?'M月DD日': 'MMM DD')
              // const { locale } = this.$i18n;
              // if (locale === 'en-us' || locale === 'en') {
              //   return moment(val).format('MMM-DD');
              // } else if (locale === 'ja') {
              //   return moment(val).format('MM月DD日');
              // } else {
              //   return 'invalid date';
              // }
            },
            rotate: 0,
            style: {
              fontSize: '10px',
              cssClass: 'small-title'
            },
            offsetX: -3
          }
        }
      } // chartOptions
    };
  }, // data
  computed: {
    ...mapGetters('filter', ['getStoreCodesList', 'getDaysOfWeekList']),
    ...mapState('prediction', ['traffic', 'kpiPrediction'])
  }, // computed
  async created() {
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('title')} ${this.$t('KpiAnalysis.selected')}꞉ ${
            this.getStartingPeriod
          }˷${this.getEndingPeriod}  ${this.$t('KpiAnalysis.compared')}꞉ ${
            this.getStartingCompared
          }˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('title')} ${this.$t('KpiAnalysis.selected')}꞉ ${
            this.getStartingPeriod
          }˷${this.getEndingPeriod}  ${this.$t('KpiAnalysis.compared')}꞉ ${
            this.getStartingCompared
          }˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('title')} ${this.$t('KpiAnalysis.selected')}꞉ ${
            this.getStartingPeriod
          }˷${this.getEndingPeriod}  ${this.$t('KpiAnalysis.compared')}꞉ ${
            this.getStartingCompared
          }˷${this.getEndingCompared}`
        }
      }
    };
    try {
      this.loading = true;
      await this.fetchKpiPrediction({
        metricKey: 'traffic',
        storeCodes: this.getStoreCodesList,
        daysOfWeek: this.getDaysOfWeekList
      });
      this.populateChart();
    } catch (error) {
      // this.$q.notify({
      //   type: 'negative',
      //   position: 'top',
      //   message: `${error}`
      // });
    } finally {
      this.loading = false;
    }
  }, // created
  methods: {
    ...mapActions('prediction', [
      'fetchTrafficPrediction',
      'fetchKpiPrediction'
    ]),
    getXaxisDates(date){
      let lang = this.$store.state.user.locale;
      let dateFormat = moment(date).locale(lang).format("ll")
      return dateFormat
    },
    populateChart() {
      
      const current =
        this.traffic &&
        this.kpiPrediction.previousData &&
        this.kpiPrediction.previousData.map(prevData => {
          return {
            x: prevData.date,
            y: parseInt(prevData.value.replace(/,/g, ''), 10),
            z: {
              temperature: prevData.temperature,
              weatherIcon: prevData.weatherIcon
            }
          };
        });
      const prediction =
        this.traffic && this.kpiPrediction && this.kpiPrediction.predictionData
          ? this.kpiPrediction.predictionData.map(prediction => {
              return {
                x: prediction.date,
                // eslint-disable-next-line
            y: parseInt(prediction.value.replace(/,/g, ''), 10),
                z: {
                  temperature: prediction.temperature,
                  weatherIcon: prediction.weatherIcon
                }
              };
            })
          : [];
      const xaxisCategoryCurrent = current.map(point => point.x);
      const xaxisCategoryPrediction = prediction.map(point => point.x);
      let multilineChart = [
        {
          name: this.$t('Traffic'),
          data: [...prediction]
        }
      ];
      this.series = [...multilineChart];
      this.chartOptions.xaxis['categories'] = [
        // ...xaxisCategoryCurrent,
        ...xaxisCategoryPrediction
      ];
    },
    getFullDay(val) {
      if (val === 0) {
        return 'Sun';
      }
      if (val === 1) {
        return 'Mon';
      }
      if (val === 2) {
        return 'Tue';
      }
      if (val === 3) {
        return 'Wed';
      }
      if (val === 4) {
        return 'Thu';
      }
      if (val === 5) {
        return 'Fri';
      }
      if (val === 6) {
        return 'Sat';
      }
    }
  } // methods
}; // export default
