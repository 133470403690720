import Vue from 'vue';

import '../styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import 'quasar/dist/quasar.addon.css';
import iconSet from 'quasar/icon-set/fontawesome-v5-pro.js';
import { Cookies, Quasar, Notify, Dialog } from 'quasar';

import store from '../store';

Vue.use(Quasar, {
  config: {},
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: { Notify, Dialog, Cookies },
  iconSet: iconSet
});



if(store.getters['user/getLocale'] == 'en')
{
import(
  /* webpackInclude: /(ja|en-us)\.js$/ */
  'quasar/lang/' + 'en-us'
).then(lang => {
  Quasar.lang.set(lang.default);
});
}
else
{
  import(
    /* webpackInclude: /(ja|en-us)\.js$/ */
    'quasar/lang/' + store.getters['user/getLocale']
  ).then(lang => {
    Quasar.lang.set(lang.default);
  });
}

// export default async () => {
//   await import(
//     /* webpackInclude: /(ja|en-us)\.js$/ */
//     'quasar/lang/' + store.getters['user/getLocale']
//   ).then(lang => {
//     Quasar.lang.set(lang.default);
//   });
// };
