import { AddOnDTO } from '@/models/AddOn';
import { PaginatedResponse } from '@/models/Pagination';
import { PlanDTO, PlanType, SubscriptionType } from '@/models/Plan';
import { createBackendError } from '@/util/error';
import { buildQuery } from '@/util/query';
import { AxiosInstance } from 'axios';

export type GetPlansResponse = PaginatedResponse<PlanDTO>;
export type GetAddOnsResponse = PaginatedResponse<AddOnDTO>;

export default class PlanController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getAddOns(
    subscriptionType: SubscriptionType = SubscriptionType.MONTHLY
  ) {
    const res = await this.getPlans(subscriptionType, PlanType.ADDONS);
    return res as GetAddOnsResponse;
  }

  public async getPlans(
    subscriptionType: SubscriptionType = SubscriptionType.MONTHLY,
    type: PlanType = PlanType.PLAN
  ) {
    const query = buildQuery({ type, subscriptionType });
    try {
      const { data } = await this.client.get('/console/plans', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false,
          query
        }
      });
      return data as GetPlansResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPlanById(id: string) {
    try {
      const { data } = await this.client.get(`/console/plans/${id}`);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
