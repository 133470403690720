
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isPwd: true,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      roleName: 'superuser',
      storeCodes: '',
      userName: '',
      password: '',
      enable2fa: false,
      enableUserLock: false,
      lockUser: false,
      useTOTPAuthenticator: false,
      lineID: null,
      role: '',
      rolesListOptions: [],
      storesList: [],
      storesListOptions: [],
      roleKeyword: '',
      storeKeyword: '',
      loading: true
    };
  },
  computed: {
    ...mapState('usermgmt', ['user', 'settingsMode', 'roles', 'userID']),
    ...mapState('store', ['stores'])
  },
  watch: {
    enableUserLock() {
      if (!this.enableUserLock) {
        this.lockUser = false;
      }
    },
    enable2fa() {
      if (!this.enable2fa) {
        this.useTOTPAuthenticator = false;
      }
    },
    roles() {
      if (this.roles && this.roles.length > 0) {
        this.rolesListOptions = this.roles.map(val => {
          return {
            label: val.roleName,
            value: val.roleName
          };
        });
      }
    },
    stores() {
      if (this.stores && Object.keys(this.stores).length > 0) {
        this.storesListOptions = this.stores.stores.map(val => {
          return {
            label: val.name,
            value: val.storeCode
          };
        });
      }
    }
  },
  async created() {
    this.loading = true;
    await this.fetchStores();
    await this.fetchRoles();
    if (this.settingsMode === 'edit') {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.phoneNumber = this.user.phoneNumber;
      this.emailAddress = this.user.emailAddress;
      this.role = this.user.role.roleName;
      this.storesList = this.user.stores.map(v => v.storeCode);
      this.userName = this.user.userName;
      this.enable2fa = this.user.enable2fa;
      this.enableUserLock = this.user.enableUserLock;
      this.lockUser = this.user.lockUser;
      this.useTOTPAuthenticator = this.user.useTOTPAuthenticator;
      this.lineID = this.user.lineID;
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('usermgmt', [
      'addUser',
      'updateUser',
      'updateUserByID',
      'deleteUserByID',
      'fetchRoles',
      'fetchUsers'
    ]),
    validateAlphanumeric(val) {
      return /[^a-zA-Z0-9]/.test(val);
    },
    ...mapActions('store', ['fetchStores']),
    ...mapMutations('usermgmt', ['setSettingsMode', 'setDialog']),
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    async addUserData() {
      let addData = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        emailAddress: this.emailAddress === '' ? null : this.emailAddress,
        roleName: this.role.value,
        storeCodes: this.storesList?.join(',') ?? '',
        userName: this.userName,
        password: this.password,
        enable2fa: this.enable2fa,
        enableUserLock: this.enableUserLock,
        lockUser: this.enableUserLock,
        useTOTPAuthenticator: this.useTOTPAuthenticator,
        lineID: this.lineID
      };
      if (this.settingsMode === 'add') {
        await this.addUser(addData);
      } else {
        await this.updateUser({
          user: {
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            emailAddress: this.emailAddress,
            roleName: this.roleName,
            storeCodes: this.storesList?.join(',') ?? '',
            userName: this.userName,
            password: this.password,
            enable2fa: this.enable2fa,
            enableUserLock: this.enableUserLock,
            lockUser: this.lockUser
          },
          id: this.userID
        });
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('updateMessage')
        });
      }
      await this.fetchUsers();
      await this.setDialog(false);
      this.setSettingsMode('add');
    }
  }
};
