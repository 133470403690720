
import { mapState } from 'vuex';

export default {
  props: {
    charttitle: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      categoryData: [],
      seriesData1: [
        {
          name: this.$t('BoxOccupancyPowerHour.maxOccupancy'),
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: this.$t('BoxOccupancyPowerHour.minOccupancy'),
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      seriesData: [
        {
          name: this.$t('BoxOccupancyPowerHour.maxOccupancy'),
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: this.$t('BoxOccupancyPowerHour.minOccupancy'),
          type: 'line',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      formattedSeriesValue: {},
      chartOptions: {
        chart: { toolbar: {} }
      }
    };
  },
  computed: {
    ...mapState('user', ['locale'])
  },
  watch: {
    series() {
      let allData = [];
      this.seriesData1 = this.series?.map(v => {
        if(v.name == 'Max Occupancy' || v.name == 'Min Occupancy')
        {
        allData = [...allData, ...v.data];
        return {
          ...v,
          name: this.$t(`BoxHourlyMixChart.${v.name.replace(/\s/g, '')}`)
        };
      }
      else
          return null;
      });
      this.seriesData = this.seriesData1.filter(elements => {
      return elements !== null;
    });
      const hashmapSeries = this.seriesData?.reduce(function(hash, value) {
        const key = value['name'];
        hash[key] = value['formattedValue'];
        return hash;
      }, {});
      this.formattedSeriesValue = { ...hashmapSeries };
      let val = allData?.sort((a, b) => (a > b ? 1 : -1)).reverse()[0] + 10;
      this.chartOptions = {

        chart: {
          toolbar: {
            tools: {
              download: '<i class="fas fa-download"></i>',
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
              customIcons: []
            }
          }
        },
        stroke: {
          width: [1, 3]
        },
        title: {},
        dataLabels: {
          enabled: false,
          enabledOnSeries: [1]
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
                <q-card class="tooltip-container flex column q-pa-md items-center">
                  <div class="flex justify-between items-center">
                    <div class="text-bold">
                      ${this.categories[dataPointIndex]}
                    </div>
                  </div>
                  <div class="data-stats">
                     ${w.globals.seriesNames
                       .map((seriesLabel, index) => {
                         return `
                          <div class="tooltip-title flex items-center">
                            <div class="tooltip-title-color" style="background:${w.config.colors[index]}"></div>
                            <div>${seriesLabel}: &nbsp; </div>
                            <div class="text-bold">
                              ${this.seriesData[index]?.formattedValue[dataPointIndex]}
                            </div>
                          </div>`;
                       })
                       .join('')}
                  </div>
                </q-card>`;
          }
        },
        colors: ['#26a0fc', '#ff9800'],
        xaxis: {
          categories: this.categories
        },
        yaxis: [
          {
            show: false,
            max: val,
            labels: {
              formatter: value => {
                return value.toFixed();
              }
            }
          },
          {
            max: val,
            labels: {
              formatter: value => {
                return value.toFixed();
              }
            }
          }
        ]
      };
    }
  },
  created() {
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('BoxHourlyMixChart.HourlyTrends')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('BoxHourlyMixChart.HourlyTrends')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('BoxHourlyMixChart.HourlyTrends')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
  },
  methods: {
    setChartOptions() {
      // let val =
      //   this.series[1].data.sort((a, b) => (a > b ? 1 : -1)).reverse()[0] + 100;
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.categories
        },
        yaxis: {
          // max: val
        }
      };
    }
  }
};
