
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI } from '@/util/error';
import PeerGroupTable from '@/components/Settings/PeerGroup/Table.vue';
import { mapGetters, mapState } from 'vuex';
import { isNonDemoUser } from '@/util/userUtils';
import { checkPermission } from '@/api/utils';
export default {
  components: { PeerGroupTable },
  mixins: [CommonMixin],
  data() {
    return {
      data: [],
      loading: true,
      isNonDemoUser: isNonDemoUser()
    };
  },
  mounted() {
    this.loadPeerGroups();
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    async loadPeerGroups() {
      try {
        const { peerGroups } = await this.$repo.peerGroup.getPeerGroups();
        this.data = peerGroups;
      } catch (err) {
        handleErrorUI(err);
      }
      this.loading = false;
    }
  }
};
