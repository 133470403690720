/* eslint-disable no-console no-self-assign*/

import { register } from 'register-service-worker';

const swVersion = "92";

console.log("serviceWorker", localStorage.getItem("swVersion"))

function callRegister() {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(arg) {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
      const cswVersion = localStorage.getItem("swVersion")
      if(swVersion !== cswVersion) {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for(let registration of registrations) {
            console.log('Service worker has been un-registered.');
            registration.unregister();
            localStorage.setItem("swVersion", swVersion)
            setTimeout(() => {
              window.location.reload(true);
            }, 1000)
          }
        });
      }

    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}

async function processSW() {
  if (process.env.NODE_ENV === 'production') {
    callRegister();
  }
}
processSW();

