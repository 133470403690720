export default [
  {
    path: '/market-place/plan',
    name: 'marketplace:plan',
    component: () => import('@/pages/Marketplace/Plan.vue'),
    meta: {
      accessGiven: true
    }
  }, // marketplace:plan
  {
    path: '/market-place/add-ons',
    name: 'marketplace:add-ons',
    component: () => import('@/pages/Marketplace/AddOns.vue'),
    meta: {
      accessGiven: true
    }
  }, // marketplace:add-ons
  {
    path: '/market-place/sensor',
    name: 'marketplace:sensor',
    component: () => import('@/pages/Marketplace/Sensor.vue'),
    meta: {
      accessGiven: true
    }
  } // marketplace:sensor
];
