
import { createColumn } from '@/util/table';
import { composeLogDescription } from '@/models/formatters/LogFormatter';
import PaginationMixin from '@/mixins/PaginationMixin';

const columns = [
  createColumn('timestamp', 'Log Period', {
    field: row => new Date(row.timestamp).toLocaleString('ja-JP')
  }),
  createColumn('username', 'Username'),
  createColumn('menu', 'Menu'),
  createColumn('description', 'Description', {
    field: row => composeLogDescription(row)
  })
];

export default {
  mixins: [PaginationMixin],
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    columns
  })
};
