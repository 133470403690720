
export default {
  data() {
    return {
      hardwares: [
        {
          icon: 'brickStream',
          label: 'Brickstream',
          description: 'brickStreamContent',
          installs: '>1,500',
          rate: 'brickStreamRate',
          enabled: false
        },
        {
          icon: 'irisSystem',
          label: 'Irisys',
          description: 'irisSystemContent',
          installs: '>1,000',
          rate: 'irisSystemRate',
          enabled: false
        },
        {
          icon: 'atcast',
          label: 'Actcast',
          description: 'atcastContent',
          installs: '<100',
          rate: 'atcastRate',
          enabled: false
        },
        {
          icon: 'quividi',
          label: 'Quividi',
          description: 'quividiContent',
          installs: '<100',
          rate: 'quividiRate',
          enabled: false
        },
        {
          icon: 'meraki',
          label: 'Cisco Meraki',
          description: 'merakiContent',
          installs: '>1,500',
          rate: 'merakiRate',
          enabled: false
        },
        {
          icon: 'datto',
          label: 'Datto',
          description: 'dattoContent',
          installs: '>500',
          rate: 'dattoRate',
          enabled: false
        }
      ]
    };
  },
  methods: {
    getImgUrl(pic) {
      return require('../../assets/img/icons/' + pic + '.png');
    }
  }
};
