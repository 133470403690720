
import { mapMutations, mapState } from 'vuex';
export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    radarChartSelected: {
      type: Boolean,
      default: false
    }
    // elevated
    // loaddata: {
    //   type: Function,
    //   default: () => {}
    // }
  }, // props

  data() {
    return {
      search: '',
      markedOptions: []
    };
  },
  computed: {
    /**
     * List of options. It gets updated if the user operates an search.
     */
    ...mapState('filter', ['radarChartKpiOptions']),
    searchResult() {
      if (this.search) {
        return this.options.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.options;
      }
    }, // searchResult
    options() {
      return [
        { label: 'Group1', name: 'Group1' },
        { label: 'Group2', name: 'Group2' },
        { label: 'Group3', name: 'Group3' },
        { label: 'Group4', name: 'Group4' },
        { label: 'Group5', name: 'Group5' },
        { label: 'Group6', name: 'Group6' }
      ];
    } // options
  }, // created
  watch: {}, // computed
  created() {
    // Set default selection
    this.markedOptions = this.selectedOptions;
  },
  methods: {
    ...mapMutations('distribution', ['setPersistentKPI']),
    /**
     * Update selected options before closing the filter
     */
    submit() {
      // Check if minimum of 3 kpis are selected
      // if (this.markedOptions.length < 4) {
      //   return false;
      // }
      // Update selected options
      // if (this.radarChartSelected) {
      //   this.selectedOptions = this.markedOptions;
      //   this.setPersistentKPI(this.markedOptions);
      // } else {
      //   this.selectedOptions = this.markedOptions;
      // }

      // Updates the parent
      this.$emit('update:selectedOptions', this.markedOptions);
      // this.loaddata();
      // Hide the filter

      this.hide();
    }, // submit
    /**
     * Closes the filter without saving selected options
     */
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterGroups.hide();
    }, // hide
    ...mapMutations('filter', ['setRadarChartKpiOptions'])
  }
};
