
import { mapGetters, mapState } from 'vuex';

import Role from '@/models/Role';
import { handleErrorUI } from '@/util/error';
import PaginationMixin from '@/mixins/PaginationMixin';
import Filters from '@/components/Common/Filters.vue';
import { checkPermission } from '@/api/utils';

export default {
  components: { Filters },
  mixins: [PaginationMixin],
  data() {
    return {
      roles: [],
      selectedRole: null,
      dialogShown: false,
      deleteConfirmationShown: false,
      permissionsMode: 'Edit',
      loading: false,
      roleOptions: []
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount'])
    // roleOptions() {
    //   return map(this.roles, role => ({
    //     label: role.name,
    //     value: role.id
    //   }));
    // }
  },
  async mounted() {
    await this.fetchRoles();
    this.roleOptions = this.roles.map(role => ({
      label: role.title,
      value: role._id
    }));
  },

  methods: {
    roleDelete(val) {
      this.fetchRoles();
    },
    viewAllowed(name) {
      return checkPermission(name);
    },
    createRole() {
      this.permissionsMode = 'Create';
      this.selectedRole = {
        name: '',
        description: ''
      };
      this.dialogShown = true;
    },
    viewRole(role: Role) {
      this.permissionsMode = 'Edit';
      this.selectedRole = role;
      this.dialogShown = true;
    },
    viewRoleDetails(role: Role) {
      this.permissionsMode = 'View';
      this.selectedRole = role;
      this.dialogShown = true;
    },
    showDeleteConfirmation(role: Role) {
      this.selectedRole = role;
      this.deleteConfirmationShown = true;
    },
    updateSelectedRole(updatedEntity) {
      this.selectedRole = Object.assign(this.selectedRole, updatedEntity);
    },
    appendRole(newEntity) {
      this.fetchRoles();
      // this.roles.push(newEntity);
    },
    async fetchRoles() {
      this.loading = true;
      try {
        const {
          response: { roles }
        } = await this.$repo.role.getRolesSSO();
        this.roles = roles;
        // this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchRoles();
    }
  }
};
