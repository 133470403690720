
import Vue from 'vue';
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import { APIFetchStoreRanking } from '@/api/store';
import { mapGetters, mapState } from 'vuex';
import store from '@/store';
import {
  GetReportingSumDataByLocationFidaV2LocationRep,
  convertApiResponseForLocationReport
} from '@/api/fida';
// import { kpiListGet } from '@/util/mockData/kpiMapping';

export default Vue.extend({
  data() {
    return {
      modelL: 10,
      modelR: 10,
      loading: false,
      sortBy: 'rank',
      sortOrder: 'asc',
      columns: [
        {
          name: 'rank',
          label: '',
          field: 'rank'
        },
        {
          name: 'store',
          label: this.$t('store'),
          field: 'title',
          sortable: false,
          align: 'left'
        },
        {
          name: 'value',
          label: this.$t('value'),
          field: 'value',
          align: 'center',
          sortable: false,
          sort: (a, b) =>
            parseInt(a.replace(/,/g, '').replace(/円|¥/g, ''), 10) -
            parseInt(b.replace(/,/g, '').replace(/円|¥/g, ''), 10)
        },
        {
          name: 'variation',
          label: this.$t('variation'),
          field: 'variation',
          align: 'center',
          sortable: true,
          sort: (a, b) => parseFloat(a) - parseFloat(b)
        }
      ],

      data: [],
      kpi: { label: this.$t('KPI.sales'), value: 'sls' },
      options: []
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('user', ['kpiLabelValue']),
    allKpiOption() {
      return this.kpiListOrder.map(kpi => ({
        label: kpi.label,
        value: kpi.value
      }));
    }
  },
  watch: {
    async kpi(newValue) {
      await this.loadKData();
    },
    kpiOptions(newValue) {
      this.kpi = newValue[0];
    }
  },

  async created() {
    // const kpiList = await kpiListGet();
    // this.kpiListOrder = [...kpiList];

    this.kpiListOrder = [...this.kpiLabelValue];

    this.options = this.allKpiOption;
    this.kpi = this.options[0];
    await this.loadKData();
  },
  methods: {
    toggleSort(column) {
      if (this.sortBy === column) {
        // Toggle between ascending and descending
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Set new column for sorting
        this.sortBy = column;
        this.sortOrder = 'asc';
      }
      this.sortData();
    },
    sortData() {
      this.data.sort((a, b) => {
        const valA = a[this.sortBy];
        const valB = b[this.sortBy];
        if (this.sortOrder === 'asc') {
          return valA > valB ? 1 : valA < valB ? -1 : 0;
        }
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      });
    },
    isSortedBy(column, order) {
      return this.sortBy === column && this.sortOrder === order;
    },
    async loadKData() {
      this.loading = true;

      var res = await GetReportingSumDataByLocationFidaV2LocationRep({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        compareStartDate: this.getStartingCompared,
        compareEndDate: this.getEndingCompared,
        storeCodes: this.getStoreCodesList.split(','),
        kpiTypes: this.kpi.value.split(','),
        frequency: 'all'
      });
      const storesList = store.getters['user/getStoresCodeNameList'];

      const data = await convertApiResponseForLocationReport(
        res,
        this.kpi.value,
        storesList,
        this.getStartingPeriod,
        this.getEndingPeriod
      );
      const limit = 10;
      this.data =
        data?.length < limit
          ? [
              ...data,
              ...new Array(limit - data.length).fill({
                storeCode: '-',
                store: '-',
                value: '-',
                variation: '-',
                title: '-'
              })
            ]
          : data;
      data.forEach((row, index) => {
        row.index = index + 1;
      });

      this.loading = false;
    }
  }
});
