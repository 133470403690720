import PaymentCard from '@/models/Payment';
import Plan, { SubscriptionType } from '@/models/Plan';
import Sensor from '@/models/Sensor';
import Store from '@/models/Store';
import User from '@/models/User';
import map from 'lodash/map';
import range from 'lodash/range';

export const mockStores = (size = 10) =>
  map(
    range(size),
    (index): Store => {
      const character = String.fromCharCode(65 + index);
      const number = Number.POSITIVE_INFINITY;
      return {
        id: character,
        name: 'Store ' + character,
        storeNumber: index,
        regionId: character,
        region: 'Region ' + character,
        districtId: character,
        district: 'District ' + character,
        prefectureId: character,
        prefecture: 'Prefecture ' + character,
        cityId: character,
        cityID: number,
        city: 'City ' + character,
        status: '',
        storeFloorPlan: {
          ceilingHeight: 10,
          dimension: 10,
          numberOfEntrances: 1
        },
        precedence: number,
        routerPortNumber: 0,
        brandReporting: 'Brand' + character,
        storetypeid: number
      };
    }
  );

export const mockUsers = (size = 10) =>
  map(
    range(size),
    (): User => {
      return {
        fullName: 'John Smith',
        firstName: 'John',
        lastName: 'Smith',
        username: 'john.smith',
        phone: {
          countryCode: '+81',
          phoneNumber: '123456789012'
        },
        email: 'john.smith@company.com',
        roles: [],
        storeIds: []
      };
    }
  );

export const mockPayments = (): PaymentCard[] => [
  {
    id: '1',
    name: 'Jeremías Romero',
    cardNumber: '0000 1111 2222 3333',
    expired: '01/22',
    address: '79 Old Wild Rose Lane Sarasota, FL 34231',
    customerId: 'cust_123'
  },
  {
    id: '2',
    name: 'Monika Derfflinger',
    cardNumber: '1111 2222 3333 4444',
    expired: '02/22',
    address: '90 Summer Drive Norwalk, CT 06851',
    customerId: 'cust_123'
  },
  {
    id: '3',
    name: 'Nado Husa',
    cardNumber: '2222 3333 4444 5555',
    expired: '03/22',
    address: '53 Iroquois St. Birmingham, AL 35209',
    customerId: 'cust_123'
  }
];

export const mockBilling = (size = 10) =>
  map(range(size), index => {
    const storeChar = String.fromCharCode(index + 65);
    const type = index < 3 ? 'Sensor' : index < 6 ? 'User' : 'Plan';
    return {
      storeName: `Store ${storeChar}`,
      date: '2020.03.02',
      type
    };
  });

export const mockCurrencies = {
  id: '1',
  code: '001',
  name: 'Japan',
  baseCurrency: true
};

export const mockPeerGroups = () => [
  { name: 'Peer Group A', action: null },
  { name: 'Peer Group B', action: null },
  { name: 'Peer Group C', action: null },
  { name: 'Peer Group D', action: null },
  { name: 'Peer Group E', action: null }
];

export const mockPlans = (): Plan[] => {
  return [
    {
      id: '1',
      name: 'Starter',
      currency: mockCurrencies,
      price: 19.99,
      benefits: '# Free Food',
      subscriptionType: SubscriptionType.MONTHLY
    },
    {
      id: '2',
      name: 'Standard',
      currency: mockCurrencies,
      price: 199.99,
      benefits: '# Free Food\n# Priority Access',
      subscriptionType: SubscriptionType.MONTHLY
    },
    {
      id: '3',
      name: 'Enterprise',
      currency: mockCurrencies,
      price: 299.99,
      benefits: '# Free Food\n# Priority Access\n# Exclusive Dishes',
      subscriptionType: SubscriptionType.MONTHLY
    }
  ];
};

export const mockRoles = (size = 10) =>
  map(range(size), index => ({
    roleName: `Role ${index + 1}`,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    privileges: ['View', 'Create', 'Update', 'Delete']
  }));

export const mockModules = (size = 5) =>
  map(range(size), index => ({
    name: `Module ${index + 1}`,
    create: index <= 3,
    view: index <= 2,
    update: index <= 1,
    delete: index <= 0
  }));

export const mockSensors = (size = 10): Sensor[] =>
  map(range(size), index => ({
    id: index.toString(),
    name: `Sensor ${index + 1}`,
    image: {
      path: '',
      filename: ''
    },
    description: '',
    price: 19.99,
    currency: '¥'
  }));
