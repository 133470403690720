import { render, staticRenderFns } from "./CategoryForm.vue?vue&type=template&id=5bc943f2&"
import script from "./CategoryForm.vue?vue&type=script&lang=ts&"
export * from "./CategoryForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSpace,QBtn,QForm,QInput,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSpace,QBtn,QForm,QInput,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
