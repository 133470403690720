
export default {
  props: {
    value: { default: 0, type: Number },
    min: { default: null, type: Number },
    max: { default: null, type: Number },
    incrementValue: { default: 1, type: Number }
  },
  computed: {
    canIncrement() {
      if (this.max == null) return true;
      return this.value + this.incrementValue <= this.max;
    },
    canDecrement() {
      if (this.min == null) return true;
      return this.value - this.incrementValue >= this.min;
    }
  },
  methods: {
    change(e) {
      let newValue = parseInt(e.target.value);
      if (isNaN(newValue)) newValue = this.value;
      else if (this.max != null && newValue > this.max) newValue = this.max;
      else if (this.min != null && newValue < this.min) newValue = this.min;
      this.$emit('input', newValue);
    },
    increment() {
      this.$emit('input', this.value + this.incrementValue);
    },
    decrement() {
      this.$emit('input', this.value - this.incrementValue);
    }
  }
};
