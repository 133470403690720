import axios from "axios"
import { SSOGetRequest, SSOPutRequest, SSODeleteRequest, SSOPostReq } from '@/util/kpis';
import store from '@/store';
export default {
  // getTenantContacts() {
  //   return axios.get(store.state?.baseSSOUrl+'/api/getTenantContacts?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // getTenantDetails() {
  //   return axios.get(store.state?.baseSSOUrl+'/api/getTenantDetails?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // createTenantContacts(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "name": data.name,
  //     "department": data.department,
  //     "title": data.title,
  //     "email": data.email,
  //     "phoneNumber": data.phoneNumber,
  //     "contactType": data.contactType
  //   };
  //   return axios.post(store.state?.baseSSOUrl+'/api/createTenantContact', contactData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // updateTenantDefaultSettings(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "selectedPeriod": data.selectedPeriod,
  //     "comparePeriod": data.comparePeriod,
  //     "allowLocationFilter": data.allowLocationFilter,
  //     "fiscalStartMonth": data.fiscalStartMonth,
  //     "weeklyReportDay": data.weeklyReportDay,
  //     "weeklyReportTime": data.weeklyReportTime,
  //     "monthlyReportDay": data.monthlyReportDay,
  //     "monthlyReportTime": data.monthlyReportTime,
  //     "storeFloorAreaUnit": data.storeFloorAreaUnit,
  //     "weekStartDay": data.weekStartDay,
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/updateTenantDefaultSettings',contactData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // updateTenantContact(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "name": data.name,
  //     "department": data.department,
  //     "title": data.title,
  //     "email": data.email,
  //     "phoneNumber": data.phoneNumber,
  //     "contactType": data.contactType,
  //     "contactId": data.contactId,
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/updateTenantContact',contactData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // updateTenant(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "companyName": data.companyName,
  //     "companyPhone": data.companyPhone,
  //     "industry": data.industry,
  //     "country": data.country,
  //     "region": data.region,
  //     "city": data.city,
  //     "address": data.address
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/updateTenant',contactData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // removeTenantContacts(contactId) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "contactId": contactId,
  //   };
  //   return axios.delete(store.state?.baseSSOUrl+'/api/removeTenantContact',{
  //     data: userData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },

  async getTenantContacts() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      
    }
    const apiResponse= await SSOGetRequest(
      '/api/getTenantContacts',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async getTenantDetails() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      
    }
    const apiResponse= await SSOGetRequest(
      '/api/getTenantDetails',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async createTenantContacts(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "name": data.name,
      "department": data.department,
      "title": data.title,
      "email": data.email,
      "phoneNumber": data.phoneNumber,
      "contactType": data.contactType
    };
    const apiResponse= await SSOPostReq(
      '/api/createTenantContact', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  
  async updateTenantDefaultSettings(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "selectedPeriod": data.selectedPeriod,
      "comparePeriod": data.comparePeriod,
      "allowLocationFilter": data.allowLocationFilter,
      "fiscalStartMonth": data.fiscalStartMonth,
      "weeklyReportDay": data.weeklyReportDay,
      "weeklyReportTime": data.weeklyReportTime,
      "monthlyReportDay": data.monthlyReportDay,
      "monthlyReportTime": data.monthlyReportTime,
      "storeFloorAreaUnit": data.storeFloorAreaUnit,
      "weekStartDay": data.weekStartDay,
    };
    const apiResponse= await SSOPutRequest(
      '/api/updateTenantDefaultSettings', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async updateTenantContact(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "name": data.name,
      "department": data.department,
      "title": data.title,
      "email": data.email,
      "phoneNumber": data.phoneNumber,
      "contactType": data.contactType,
      "contactId": data.contactId,
    };
    const apiResponse= await SSOPutRequest(
      '/api/updateTenantContact', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async updateTenant(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "companyName": data.companyName,
      "companyPhone": data.companyPhone,
      "industry": data.industry,
      "country": data.country,
      "region": data.region,
      "city": data.city,
      "address": data.address,
      "prefecture":data.prefecture,
      "district":data.district
    };
    const apiResponse= await SSOPutRequest(
      '/api/updateTenant', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async removeTenantContacts(contactId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "contactId": contactId,
    };
    const apiResponse= await SSODeleteRequest(
      '/api/removeTenantContact',
      {
        data: userData,
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
}