
export default {
  props: {
    label: { type: String, default: '' },
    message: { type: String, default: '' },
    mandatory: { type: Boolean, default: false }
  },
  computed: {
    labelClass() {
      const { $style } = this;
      return {
        [$style.mandatory]: this.mandatory,
        [$style.label]: true
      };
    }
  }
};
