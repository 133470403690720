import store from '@/store';
import { PostRequest } from '@/util/http';
import { mapGetters } from 'vuex';

export function APIFetchHolidays(params: any) {
  // var apiUrl = 'https://holidays-jp.github.io/api/v1/date.json';
  // fetch(apiUrl).then(response => {
  //   return response.json();
  // }).then(data => {
  //   // Work with JSON data here
  //   console.log(data)
  //   return {data}
  // }).catch(err => {
  //   console.log(err)
  // });
  return PostRequest('calendar/holidays', params);
}

export function checkPermission(permission: string) {
  const role = store.getters['user/getRole'];
  const keys = Object.keys(store.getters['user/getPermissions']);
  //return role.title?.includes('FlowTenantAdmin') || keys.includes(permission);
  return keys.includes(permission);
}

export function isAdAllow() {
  return store.getters['user/getAllowAdvertiseItem'] ?? false;
}
