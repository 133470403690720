
import AddBusinessContact from "@/components/modals/AddBusinessContact.vue";
import tenants from "@/services/tenants";

export default {
  components: { AddBusinessContact },
  data() {
    return {
      openModel: false,
      visible: false,
      loading: false,
      columns: [
        {
          name: "name",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "name",
          sortable: true,
        },

        // { name: "department", label: this.$store.state.user.translate.department, field: "department" },
        // { name: "title", label: "title", field: "title" },
        // { name: "phoneNumber", label: this.$store.state.user.translate.phone_number, field: "phoneNumber" },
        { name: "email", label: this.$store.state.user.translate.email, field: "email" },
        { name: "action", label: this.$store.state.user.translate.actions, field: "action" },
      ],
      contactData: [],
      selectedContact: [],
      confirm: false,
      pagination:{
      page: 1,
      rowsPerPage: 10,
     },
      selected: [],
      filter: "",
    }
  },
 computed: {
    pagesNumber () {
      return Math.ceil(this.contactData.length / this.pagination.rowsPerPage)
    }
  },
  mounted() {
    this.visible = true
    this.getTenantContacts()
  },
  methods: {
    closeModel() {
      this.openModel = false;
    },
    getTenantContacts() {
      this.visible = true
      this.loading = true
      tenants.getTenantContacts().then((response) => {
        this.contactData = response.data.response.tenantContacts[0].businessContacts.reverse()
        this.visible = false
        this.loading = false
      }).catch((error) => {
        // console.log(error)
        this.visible = false
        this.loading = false
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
      })
    },
    async updateTenantContacts() {
      await this.getTenantContacts()
      this.openModel = false
    },
    editContactDialog(contact) {
      this.selectedContact = contact
      this.openModel = true
    },
    openContactDialog() {
      this.selectedContact = null
      this.openModel = true
    },
    deleteContact(contact) {
      this.selectedContact = contact
      this.confirm = true
    },
    confirmDeleteContact() {
      tenants.removeTenantContacts(this.selectedContact.id).then(() => {
        this.$q.notify({
          message: this.$store.state.user.translate.removed_successfully,
          color: 'green'
        })
        this.updateTenantContacts()
      }).catch((error) => {
        // console.log(error)
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
      })
      this.confirm = false
    }
  }
};
