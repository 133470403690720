
import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';

import { mapGetters } from 'vuex';

export default defineComponent({
  data() {
    return {
      url: '',
      baseUrl: 'https://www.flow-solutions.com',
      showContainer: true
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    reportName() {
      return this.$route.params.route.split('-')[0];
    }
  },
  mounted() {
    this.url = `${this.baseUrl}${this.getLocale === 'en' ? '/en' : ''}/${
      this.$route.params.route
    }`;
  },
  methods: {
    async likeBtnClick() {
      const capitalizeName =
        this.reportName.charAt(0).toUpperCase() +
        this.reportName.slice(1) +
        '_LP_Interest';
      await Vue.prototype?.$mixpanel?.track(capitalizeName, {
        referrer: document.referrer
      });
      this.showContainer = false;
      this.sendNotification();
    },
    async DislikeBtnClick() {
      const capitalizeName =
        this.reportName.charAt(0).toUpperCase() +
        this.reportName.slice(1) +
        '_LP_Not_Interest';
      await Vue.prototype?.$mixpanel?.track(capitalizeName, {
        referrer: document.referrer
      });
      this.showContainer = false;
      this.sendNotification();
    },
    sendNotification() {
      this.$q.notify({
        message: this.$t('LPPage.alert'),
        type: 'positive',
        position: 'top',
        timeout: 3000
      });
    }
  }
});
