export interface FTPConfigDTO {
  id?: string;
  remoteAddress: string;
  port: number;
  clientId: string;
  clientPass: string;
}

export class FTPConfig {
  id?: string;
  host: string;
  port: number;
  username: string;
  password: string;
  filepath: string;
  filename: string;

  constructor(initializer?: Partial<FTPConfig>) {
    Object.assign(this, initializer);
  }

  toDTO(): FTPConfigDTO {
    return {
      id: this.id,
      remoteAddress: this.host,
      port: this.port,
      clientId: this.username,
      clientPass: this.password
    };
  }

  static fromDTO(dto: FTPConfigDTO): FTPConfig {
    return new FTPConfig({
      id: dto.id,
      host: dto.remoteAddress,
      port: dto.port,
      username: dto.clientId,
      password: dto.clientPass
    });
  }
}
