export class Bank {
  id: string;
  name: string;
  code: string;
  instructions?: string;
  transferType?: string;
  transferFee?: number;

  constructor(initializer: Partial<Bank>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: BankDTO) {
    return new Bank({
      id: dto.bank.id,
      code: dto.bank.code,
      name: dto.bank.name,
      instructions: dto.instructions,
      transferType: dto.transferType,
      transferFee: dto.transferFee
    });
  }

  public toDTO(): BankDTO {
    return {
      id: this.id,
      bank: {
        id: this.id,
        name: this.name,
        code: this.code
      },
      instructions: this.instructions,
      transferType: this.transferType,
      transferFee: this.transferFee
    };
  }
}

export interface BankDTO {
  id: string;
  bank: {
    id: string;
    name: string;
    code: string;
  };

  instructions: string;
  transferType: string;
  transferFee: number;
}
