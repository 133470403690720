import { Currency } from './Currency';
import { SensorDTO } from './Sensor';
import Sensor from '@/models/Sensor';
import { BankDTO } from '@/models/Bank';

class RequestOrder {
  id: string;
  company: string;
  store: string;
  productId: string;
  email: string;
  phone: string;
  status: string;
  orders: RequestOrderItem[];
  total: number;
  tax: number;
  currency: Currency;
  grandTotal: number;
  requestDate: Date;
  reviewDate?: Date;
  finalizedDate?: Date;
  installationDate: Date;
  installationTime: string;
  invoiceNumber: string;
  paymentMethod?: string;
  installationSchedule?: Date;

  constructor(initializer?: Partial<RequestOrder>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: RequestOrderDTO): RequestOrder {
    const requestOrder = new RequestOrder({
      id: dto.orderId,
      company: dto.companyName,
      store: dto.storeName,
      email: dto.email,
      phone: dto.phone,
      status: dto.status,
      requestDate: new Date(dto.requestDate),
      currency: dto.currency || {
        id: '0',
        code: 'JPY',
        name: 'Japanese Yen',
        baseCurrency: true
      },
      total: dto.total,
      tax: dto.totalTax,
      grandTotal: dto.grandTotal,
      reviewDate: dto.reviewedDate == null ? null : new Date(dto.reviewedDate),
      finalizedDate:
        dto.finalizedDate == null ? null : new Date(dto.finalizedDate),
      installationDate:
        dto.scheduleDate == null ? null : new Date(dto.scheduleDate),
      installationTime: dto.scheduleTime,
      invoiceNumber: dto.invoiceNumber,
      paymentMethod: dto.paymentMethod,
      orders: dto.itemResponses.map(item => RequestOrderItem.fromDTO(item))
    });
    return requestOrder;
  }

  public toRequestOrderUpdateDTO(
    mode: RequestOrderUpdateType
  ): RequestOrderUpdateDTO {
    return {
      orderItems: this.orders.map(order => order.toOrderItemDTO(mode))
    };
  }
}

export class RequestOrderItem {
  id: string;
  product: Sensor;
  currentPrice?: number;
  initialQuantity: number;
  suggestedQuantity?: number;
  finalQuantity?: number;
  initialSubtotal: number;
  suggestedSubtotal?: number;
  finalSubtotal?: number;

  constructor(initializer?: Partial<RequestOrderItem>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: RequestOrderItemDTO): RequestOrderItem {
    const item = new RequestOrderItem({
      id: dto.id,
      product: {
        id: dto.itemProduct.id,
        name: dto.itemProduct.name,
        currency: null,
        price: dto.itemProduct.price,
        description: dto.itemProduct.description,
        image: dto.itemProduct.image
      },
      currentPrice: dto.price,
      initialQuantity: dto.initialQuantity,
      suggestedQuantity: dto.suggestedQuantity,
      finalQuantity: dto.finalQuantity,
      initialSubtotal: dto.initialQuantity * dto.price,
      suggestedSubtotal:
        dto.suggestedQuantity == null
          ? null
          : dto.suggestedQuantity * dto.price,
      finalSubtotal:
        dto.finalQuantity == null ? null : dto.finalQuantity * dto.price
    });
    return item;
  }

  public toOrderItemDTO(mode: RequestOrderUpdateType): OrderItemDTO {
    let quantity = this.initialQuantity;
    if (mode === RequestOrderUpdateType.SUGGEST) {
      quantity = this.suggestedQuantity;
    } else if (mode === RequestOrderUpdateType.FINAL) {
      quantity = this.finalQuantity;
    }
    return {
      orderItemId: this.id,
      quantity
    };
  }
}

export interface RequestOrderDTO {
  orderId: string;
  storeId: string;
  storeName: string;
  companyName: string;
  email: string;
  phone: string;
  status: string;
  requestDate: string;
  reviewedDate?: string;
  finalizedDate?: string;
  scheduleDate?: string;
  scheduleTime?: string;
  paymentMethod?: string;
  currency: Currency;
  itemResponses: RequestOrderItemDTO[];
  invoiceNumber?: string;
  total: number;
  totalTax: number;
  grandTotal: number;
}

export interface RequestOrderItemDTO {
  id: string;
  itemProduct: SensorDTO;
  price: number;
  totalPrice: number;
  initialQuantity: number;
  suggestedQuantity?: number;
  finalQuantity?: number;
}

export enum RequestOrderUpdateType {
  SUGGEST,
  FINAL
}

export interface RequestOrderUpdateDTO {
  orderItems: OrderItemDTO[];
}

export interface OrderItemDTO {
  orderItemId: string;
  quantity: number;
}

export interface PaymentConfirmation {
  tenantCardId: string;
  paymentMethod: BankDTO;
}

export default RequestOrder;
