import { Bank } from '@/models/Bank';
import { convertFromPaymentCardDTO } from '@/models/formatters/PaymentFormatter';
import Order, {
  OrderInstallationScheduleRequest,
  OrderSensorRequest
} from '@/models/Order';
import RequestOrder, {
  PaymentConfirmation,
  RequestOrderUpdateType
} from '@/models/RequestOrder';
import { TenantDTO } from '@/models/Tenant';
import TenantController from '@/services/account/TenantController';
import OrderController from '@/services/transactional/OrderController';
import lazy from '@/util/lazy';
import { Pagination, PaginationResponse } from '@/util/pagination';
import localForage from 'localforage';
import map from 'lodash/map';
import SessionRepository from './SessionRepository';

const ORDER_CART_KEY = 'sensor-order-cart';

export interface CreateOrder {
  tenant: string;
  orders: OrderSensorRequest[];
}

export interface GetOrdersResult {
  orders: RequestOrder[];
}

export interface GetBanksResult {
  data: Bank[];
  pagination: PaginationResponse;
}

export enum OrderStatus {
  WAITING_FOR_REVIEW = 'waiting-for-review',
  WAITING_FOR_FINAL_ORDER = 'waiting-final-order',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  WAITING_FOR_SCHEDULE = 'waiting-for-schedule',
  FINISHED = 'finished'
}

export default class OrderRepository {
  private readonly orderController: OrderController;
  private readonly tenantController: TenantController;

  private readonly lazyTenant: () => Promise<TenantDTO>;

  constructor(
    orderController: OrderController,
    tenantController: TenantController,
    sessionRepository: SessionRepository
  ) {
    this.orderController = orderController;
    this.tenantController = tenantController;

    this.lazyTenant = lazy(async () => {
      const { tenant } = await sessionRepository.fetch();
      return tenantController.getTenant(tenant);
    });
  }

  public async getOrderWithStatus(
    tenantName: string,
    status: OrderStatus,
    pagination: Pagination = new Pagination()
  ) {
    const tenant = await this.tenantController.getTenant(tenantName);
    const { content, ...meta } = await this.orderController.getOrdersWithStatus(
      tenant.id,
      status,
      pagination.toRequest()
    );
    return {
      data: content.map(dto => RequestOrder.fromDTO(dto)),
      pagination: meta
    };
  }

  public async createOrder(order: CreateOrder) {
    const tenant = await this.tenantController.getTenant(order.tenant);
    return await this.orderController.createOrder({
      orders: order.orders,
      tenantId: tenant.id
    });
  }

  public async createOrderSuggestion(order: RequestOrder) {
    return await this.orderController.createOrderSuggestion(
      order.id,
      order.toRequestOrderUpdateDTO(RequestOrderUpdateType.SUGGEST)
    );
  }

  public async finalizeOrder(order: RequestOrder) {
    const updatedOrder = await this.orderController.finalizeOrder(
      order.id,
      order.toRequestOrderUpdateDTO(RequestOrderUpdateType.FINAL)
    );
    return RequestOrder.fromDTO(updatedOrder);
  }

  public async confirmPayment(order: any, confirmation: PaymentConfirmation) {
    return await this.orderController.confirmPayment(
      order.productId,
      confirmation
    );
  }

  public async acceptPayment(order: RequestOrder) {
    await this.orderController.acceptPayment(order.productId);
  }

  public async scheduleInstallation(
    order: RequestOrder,
    schedule: OrderInstallationScheduleRequest
  ) {
    return await this.orderController.scheduleInstallation(order.id, schedule);
  }

  public async cancelOrder(order: RequestOrder) {
    await this.orderController.cancelOrder(order.id);
  }

  public async getPaymentMethodTypes() {
    return await this.orderController.getPaymentMethodTypes();
  }

  public async getBank(
    pagination: Pagination = new Pagination()
  ): Promise<GetBanksResult> {
    const { content, ...meta } = await this.orderController.getBank(
      pagination.toRequest()
    );
    return {
      data: content.map(dto => Bank.fromDTO(dto)),
      pagination: meta
    };
  }

  public async getCreditCards(tenantName: string) {
    const tenant = await this.tenantController.getTenant(tenantName);
    const { content } = await this.orderController.getCreditCards(tenant.id);
    return map(content, dto => convertFromPaymentCardDTO(dto));
  }

  public async saveCart(orders: Order[]) {
    const key = await this.getOrderCartKey();
    await localForage.setItem(key, orders);
  }

  public async loadCart() {
    const key = await this.getOrderCartKey();
    const orders = await localForage.getItem(key);
    return (orders || []) as Order[];
  }

  public async resetCart() {
    const key = await this.getOrderCartKey();
    await localForage.removeItem(key);
  }

  private async getOrderCartKey() {
    const tenant = await this.lazyTenant();
    return `${ORDER_CART_KEY}:${tenant.id}`;
  }
}
