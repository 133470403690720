import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { PaginatedResponse } from '@/models/Pagination';
import { RoleDTO } from '@/models/Role';
import HttpController from '../HttpController';
import { AuthorityDTO } from '@/models/Authority';
import { PaginationRequest } from '@/util/pagination';
import {
  APICreateRoleSSO,
  APIDeleteRoleSSO,
  APIGetPermissionsSSO,
  APIGetRolesSSO,
  APIUpdateRoleSSO
} from '@/api/authSSO';

interface CreateRoleRequest {
  title: string;
  description: string;
}
type UpdateRoleRequest = CreateRoleRequest;
export type GetRolesResponse = PaginatedResponse<RoleDTO>;
export type GetAuthoritiesResponse = PaginatedResponse<AuthorityDTO>;

export default class RoleController extends HttpController {
  public async getRoles(pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/roles', {
        params: {
          ...pagination
        }
      });
      return data as GetRolesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getRolesSSO() {
    try {
      const data = await APIGetRolesSSO();
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createRole(req: CreateRoleRequest, authorities) {
    try {
      const response = await APICreateRoleSSO({
        ...req,
        authorities
      });
      return response;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateRole(id: string, req: UpdateRoleRequest) {
    try {
      const { data } = await this.client.put(`/roles/${id}`, req);
      return data as RoleDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteRoles(ids: string[]) {
    try {
      const data = await this.client.delete('/roles', {
        data: ids
      });

      // createBulkDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteRole(roleId: string) {
    try {
      var _id = roleId;
      const response = await APIDeleteRoleSSO({ data: { _id } });
      return response;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getAuthorities() {
    try {
      const {
        response: { permissions }
      } = await APIGetPermissionsSSO({
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return permissions;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getAuthoritiesByRole(roleId) {
    try {
      const { data } = await this.client.get(`/roles/${roleId}/authorities`, {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetAuthoritiesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateRoleAuthorities(title, description, roleId, authorities, selectedPeriod,
    comparePeriod,
    locationFilter) {
    try {
      const response = await APIUpdateRoleSSO({
        title, description,
        roleId, authorities, selectedPeriod
        , comparePeriod, locationFilter
      });
      return response;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
