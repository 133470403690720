import Tax from '@/models/Tax';
import HttpController from '@/services/HttpController';

import { createBackendError } from '@/util/error';

export default class TaxController extends HttpController {
  public async getTax() {
    try {
      const { data } = await this.client.get('/console/taxes/current');
      return data as Tax;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
