import config from '@/config';
import router from '@/router';
import Vue from 'vue';
import lazy from './lazy';
import store from '@/store';
import { TENANTEXISTS } from '@/util/errorMessages';

export const redirectToLogin = () =>
  window.location.replace(config.loginPageURL);

export const loginDashboard = async (tenant: string) => {
  // const session = await Vue.repo.session.put(tenant);
  // Vue.client.setSession(session);
  // await store.dispatch('tenant/initialize');
  // router.push({ name: 'home' });
  redirectToLogin();
};

export const navigateIfTenantExists = message => {
  if (message === TENANTEXISTS) {
    window.open(
      'https://www.flow-solutions.com/get-started?hsCtaTracking=fa2aa6d4-26e4-46d2-9ad4-3b22ae12c719%7C2f9598a1-dfe0-4a11-bed4-b59c2f6e1d4e',
      '_self'
    );
  }
};

const lazyUser = lazy(() => Vue.repo.user.getUser('me'));

export async function checkAuthorities(authorityCodesToCheck: string[]) {
  if (authorityCodesToCheck == null || authorityCodesToCheck.length === 0) {
    return true;
  }

  const {
    response: {
      userDetails: { role }
    }
  } = await lazyUser();

  let userAuthorities = [];

  role?.permissions?.map(authority =>
    authority ? userAuthorities.push(authority.title) : null
  );

  // let allowed = role?.title?.includes('FlowTenantAdmin') || false;
  let allowed = false;
  authorityCodesToCheck.forEach(code => {
    if (allowed) return;
    if (userAuthorities.includes(code)) allowed = true;
  });
  return allowed;
}
