
import Vue from 'vue';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { VIEW_COMPANIES, UPDATE_COMPANIES } from '@/consts/permissions';
import each from 'lodash/each';
import groupBy from 'lodash/groupBy';
import {
  reportMenu,
  VMDMenu,
  toolsMenu,
  academyMenu,
  settingMenu,
  SETTING_MENU_ITEMS,
  pulseMenu
} from '../../util/mockData/menuList';
import { addWeeks, startOfWeek } from 'date-fns';
import { handleErrorUI } from '@/util/error';
import CryptoJS from 'crypto-js';
import { getAuthoritiesObj } from '@/util/authoritiesCheck';
import { checkPermission } from '@/api/utils';
import { APIUpdateLangSSO } from '@/api/authSSO';

const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export default {
  name: 'ContainerHeader',
  data() {
    return {
      user: {},
      releaseShow: false,
      accessibleModules: {},
      showNews: false,
      showVMD: false,
      showTools: false,
      showSetting: true,
      accessibleReportMenu: [],
      accessibleVMDMenu: [],
      accessiblePulseMenu: [],
      accessibleToolsMenu: [],
      accessibleAcademyMenu: [],
      accessibleSettingMenu: [],
      viewPermission: {
        'settings:company': true
      }
    };
  },
  computed: {
    ...mapState('user', [
      'profile',
      'userPermissions',
      'defaultSettings',
      'clientProfile'
    ]),
    ...mapState('store', ['stores']),
    ...mapGetters('store', ['getStoreNameList', 'getStoreCodeList']),

    ...mapGetters('user', ['getLocale', 'isAuthenticated','getToken']),
    ...mapGetters('tenant', ['config']),
    showSettingsItem() {
      return SETTING_MENU_ITEMS.map(v => this.viewAllowed(v)).some(Boolean);
    }
  },
  watch: {
    profile: function(newValue) {
      this.accessibleModules = this.profile.accessibleModules;
    },
    userPermissions() {
      this.generateAllMenus();
    },
    stores() {
      this.updateMixpanelUser();
    }
  },
  async created() {
    try {
      this.displayChat();
      const session = await this.clientProfile;
      if (!session) return;
      // await this.initialize();
      if (Object.keys(this.defaultSettings).length === 0) {
        const user = await this.$repo.user.getUser('me');
        const userDetails = this.defaultSettings;

        this.setDefaultSettings({ ...userDetails, user });
        if (Object.keys(this.userPermissions).length === 0) {
          let permissions = await getAuthoritiesObj(user?.roles);
          this.setUserPermissions(permissions);
        }
      }
      if (Object.keys(this.userPermissions).length > 0) {
        this.generateAllMenus();
      }
      // this.$intercom.shutdown();
      //this.bootIntercom();
      if (!this.$intercom.isBooted) {
        this.bootIntercom();
      }
      this.updateIntercom();

      this.initializeWootrics();
    } catch (err) {
      handleErrorUI(err);
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      if (localStorage.getItem('storeCodes')) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      }
      this.setRetailCalendar({
        dates: JSON.parse(localStorage.getItem('retailCalendar'))
      });
      this.setStartingPeriod({
        startingPeriod: localStorage.getItem('startingPeriod')
      });
      this.setEndingPeriod({
        endingPeriod: localStorage.getItem('endingPeriod')
      });
      this.setStartingCompared({
        startingCompared: localStorage.getItem('startingCompared')
      });
      this.setEndingCompared({
        endingCompared: localStorage.getItem('endingCompared')
      });
      this.setLibraryStartingPeriod({
        startingPeriod:
          localStorage.getItem('startingLibraryPeriod') ??
          startOfWeek(new Date())
      });
      this.setLibraryEndingPeriod({
        endingPeriod: localStorage.getItem('endingLibraryPeriod') ?? new Date()
      });
      this.setLibraryStartingCompared({
        startingCompared:
          localStorage.getItem('startingLibraryCompared') ??
          addWeeks(startOfWeek(new Date()), -1)
      });
      this.setLibraryEndingCompared({
        endingCompared:
          localStorage.getItem('endingLibraryCompared') ??
          addWeeks(new Date(), -1)
      });
      this.setCustomCalenderFlag({
        endingCompared: localStorage.getItem('useCustomCalender')
      });

      let selectedKPI = localStorage.getItem('selectedTrafficKPI');

      let url_string = window.location.href;
      let url = new URL(url_string);
      let req = url;
      if (selectedKPI !== undefined) {
        this.getLocalStoreKPI({ selectedKPI, req });
      } else {
        this.getLocalStoreKPI({ selectedKPI: [], req });
      }

      // this.getLocalStoreKPI({selectedKPI !== undefined ? selectedKPI:[],req});

      // this.accessibleModules = this.profile?.accessibleModules || {};
    }
  },
  methods: {
    ...mapMutations('user', [
      'setLocale',
      'setUserPermissions',
      'setDefaultSettings'
    ]),

    ...mapMutations('distribution', ['getLocalStoreKPI']),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapMutations('marketplace', [
      'setLibraryStartingPeriod',
      'setLibraryEndingPeriod',
      'setLibraryStartingCompared',
      'setLibraryEndingCompared'
    ]),
    ...mapActions('user', ['clearSession', 'fetchKpiPreferences', 'logout']),
    ...mapActions('user', ['fetchKpiPreferences'
    // , 'fetchKpiPreferences2'
  ]),
    ...mapActions('tenant', ['reset']),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    displayChat(){
        const usrChat = localStorage.getItem('usernameChat');
        const tokenChat = this.getToken;
        const lang = this.getLocale;


        var appStatus = false;
        if(localStorage.getItem('status') == 'production' )
        {
          appStatus = true;
        } else {
          appStatus = false;
        }

        var ip = localStorage.getItem('chatIP');
        var isprod = localStorage.getItem('chatIsProd');


      if(usrChat != null) {
        var obj = {
          token: tokenChat,
          lang: lang,
          isSandbox: isprod
        }

       obj.addBotCallback =  function() {
        window.open('/Explore', '_self');
      }

        window.renderFlowChat("flow-chat",obj);
      }
    },
    generateAllMenus() {
      this.generateMenu(reportMenu, 'accessibleReportMenu');
      this.generateMenu(VMDMenu, 'accessibleVMDMenu');
      this.generateMenu(pulseMenu, 'accessiblePulseMenu');
      this.generateMenu(toolsMenu, 'accessibleToolsMenu');
      this.generateMenu(academyMenu, 'accessibleAcademyMenu');
      this.generateMenu(settingMenu, 'accessibleSettingMenu');
    },
    displayMenu(name) {
      if (name === 'tools') {
        return ['FlowLogView', 'FlowImportlogView', 'FlowStoreManagementView']
          .map(v => this.viewAllowed(v))
          .some(Boolean);
      }
      return false;
    },
    openServiceLink() {
      window.open(
        'https://www.flow-solutions.com/hubfs/Agreements%20(internal)/Privacy%20Policy.pdf',
        '_blank'
      );
    },
    getClicker() {
      Vue.prototype?.$mixpanel?.track('Clicker', {
        referrer: document.referrer
      });
      if (this.getLocale === 'en') {
        window.open('https://www.flow-solutions.com/en/clicker-lp', '_blank');
        return;
      }
      window.open('https://www.flow-solutions.com/clicker-lp', '_blank');
    },
    releaseShows() {
      window.open('https://flow-platform.canny.io/changelog', '_blank');
    },
    apidocumentShows() {
      window.open(
        'https://documenter.getpostman.com/view/14067608/UVsFzodC',
        '_blank'
      );
    },
    async signout() {
      // const session = await this.$repo.session.fetch();
      Vue.prototype?.$mixpanel?.track('sign out');
      Vue.prototype?.$mixpanel?.reset();
      await this.logout('');
      // await this.$repo.auth.logout();
      await this.clearSession();
      this.reset();
      this.$intercom.shutdown();
      await this.$router.push({ name: 'auth:login' }).catch(_ => {});
      localStorage.clear();
      this.$router.go();
    }, // signout
    backToV2() {
      window.open('https://staging-ta.flow-insight.com', '_blank');
    },
    redirectTo(routeName, upgradable) {
      const allowAdvertiseItems =
        this.defaultSettings.allowAdvertiseItems ?? false;
      if (upgradable || allowAdvertiseItems) {
        if (
          routeName === 'weekly_report' ||
          routeName === 'daily_report' ||
          routeName === 'monthly_report' ||
          routeName === 'periodic_report_list'
        ) {
          this.$router.push({
            name: routeName,
            params: {
              route: 'pulse-lp'
            }
          });
          return;
        }
        if (routeName === 'occupancy_report') {
          this.$router
            .push({
              name: routeName,
              params: {
                route: 'occupancy-lp'
              }
            })
            .catch(_ => {});
          return;
        }
        if (routeName === 'demographics-reportF') {
          this.$router
            .push({
              name: routeName,
              params: {
                route: 'demographics-lp'
              }
            })
            .catch(_ => {});
          return;
        }
        if (routeName === 'queue_report') {
          this.$router
            .push({
              name: routeName,
              params: {
                route: 'queue-lp'
              }
            })
            .catch(_ => {});
          return;
        }
      }
      if (routeName) {
        Vue.prototype?.$mixpanel?.track(`navigate to ${routeName}`, {
          referrer: document.referrer
        });
        this.$router.push({ name: routeName }).catch(_ => {});
      }
    },
    generateMenu(menuList, accessibleMenuOptions) {
      const accessibleMenu = Object.keys(menuList).map((reportKey, key) => {
        if (this.viewAllowed(Object.values(menuList)[key].code)) {
          return {
            ...menuList[reportKey],
            upgradable: false
          };
        }
        return {
          ...menuList[reportKey],
          upgradable: true
        };
      });
      this[accessibleMenuOptions] = accessibleMenu.filter(Boolean);
    },
    async setupLocale(locale) {
      this.setLocale({ locale });
    }, // setupLocale
    viewAllowed(name) {
      return checkPermission(name);
    },
    generateHash(user_id) {
      var status = localStorage.getItem('status');
      if (status == 'production') {
        const hash = CryptoJS.HmacSHA256(
          user_id,
          '_I7ywUfT_YEV81RC0Kid7HYAw9Posnk3t1KAPT0W'
        );
        const hashInHex = CryptoJS.enc.Hex.stringify(hash);
        return hashInHex;
      } else {
        const hash = CryptoJS.HmacSHA256(
          user_id,
          'gTh5uTQivZ737cCkvMTRtW4WuTfT6GX37aAx8Fce'
        );
        const hashInHex = CryptoJS.enc.Hex.stringify(hash);
        return hashInHex;
      }
    },
    bootIntercom() {
      this.$intercom.boot();
    },
    updateIntercom() {
      var addonsId;
      var plansId;
      var addonsIds;
      var plansIds;
      var singlecode;
      var codes;
      const userdetails = this.defaultSettings?.user.response.userDetails ?? {};
      if (userdetails?.addons != 'undefined' && userdetails?.addons != null) {
        addonsId = userdetails?.addons.map(x => {
          return x._id;
        });
        addonsIds = addonsId.join(',');
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        plansId = userdetails.plans.map(x => {
          return x._id;
        });

        plansIds = plansId.join(',');
        //codes = code.join(",");
      }
      if (userdetails?.plans != 'undefined' && userdetails?.plans != null) {
        singlecode = userdetails.plans.map(x => {
          return x.code;
        });
        codes = singlecode.join(',');
      }
      if (addonsIds == null || addonsIds == 'undefined') {
        addonsIds = '';
      }
      userdetails.tenant.intercom.addonsIds = addonsIds;
      userdetails.tenant.intercom.plansIds = plansIds;
      userdetails.tenant.intercom.plan = codes;
      const user = this.clientProfile ?? {};
      var phone =
        userdetails?.profile?.phoneCountryCode +
        '' +
        userdetails?.profile?.phoneNumber;
      this.$intercom.boot({
        user_id: user?._id ?? '',
        name: `${user?.fullName}`,
        email: user?.email,
        phone: phone,
        user_hash: this.generateHash(user?._id ?? ''),
        language_override: this.getLocale,
        company: {
          company_id: userdetails?.tenant?.intercom?.companyId,
          name: userdetails?.tenant?.intercom?.companyName,
          plan: userdetails?.tenant?.intercom?.plan
        },
        custom_attributes: {
          addonsIds: userdetails?.tenant?.intercom?.addonsIds,
          plansIds: userdetails?.tenant?.intercom?.plansIds
        }
      });
      /* const user = this.clientProfile ?? {};
      this.$intercom.boot({
        user_id: user?._id ?? '',
        name: `${user?.fullName}`,
        email: user?.email,
        phone: user?.phone,
        user_hash: this.generateHash(user?._id ?? ''),
        language_override: this.getLocale,
        company: {
          company_id: user?.tenant?.intercom?.companyId,
          name: user?.tenant?.intercom?.companyName
        }
      });*/

      // this.$intercom.show();
    },
    initializeWootrics() {
      let wootricScript = document.createElement('script');
      wootricScript.setAttribute(
        'src',
        'https://cdn.wootric.com/wootric-sdk.js'
      );
      wootricScript.async = true;
      wootricScript.onload = () => {
        window.wootricSettings = {
          email: this.clientProfile?.email,
          created_at: Date.now(),
          account_token: 'NPS-c96acd0d' // This is your unique account token.
        };
        window.wootric('run');
      };

      document.head.appendChild(wootricScript);
    },
    updateMixpanelUser() {
      Vue.prototype?.$mixpanel?.people.unset([
        '$email',
        '$phone',
        '$first_name',
        '$last_name'
      ]);
      Vue.prototype?.$mixpanel?.people.set({
        $store_names: this.getStoreNameList,
        $store_codes: this.getStoreCodeList
      });
    },
    toAppStore() {
      window.open(
        'https://apps.apple.com/jp/app/flow-for-mobile/id1504601424?hsCtaTracking=a4ca061c-5db4-4d74-9817-d9af59ea22fe%7C6c5b6e22-41e3-4b28-9ed3-82c5eb04de84',
        '_target'
      );
    },
    toPlayStore() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.flow.go&hl=ja&gl=JP&hsCtaTracking=3f15eddd-e298-4af9-a58b-0bf9de9562b1%7Cbb279490-87f4-47ee-b762-4c61ed6a57a7',
        '_target'
      );
    }
  } // methods
}; // export default
