
import CommonMixin from '@/mixins/CommonMixin';
import {
  formatCurrency,
  getCurrencySymbol
} from '@/models/formatters/CurrencyFormatter';
import { generateImageDataUrl } from '@/util/file';

export default {
  name: 'SensorCard',
  mixins: [CommonMixin],
  props: {
    sensor: { type: Object, required: true }
  },
  data() {
    return {
      showDetailDialog: false,
      cachedImage: null
    };
  },
  computed: {
    formattedCurrency() {
      const symbol = getCurrencySymbol(this.sensor.currency);
      const value = formatCurrency(this.sensor.price, this.sensor.currency);
      return `${symbol} ${value}`;
    }
  },
  methods: {
    async fetcher() {
      if (this.cachedImage == null) {
        this.cachedImage = await this.$repo.sensor.getSensorImageAsBase64(
          this.sensor
        );
      }
      return this.cachedImage;
    },
    formatter(input, url) {
      return generateImageDataUrl(input, url);
    }
  }
};
