
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'KpiTrafficPrediction',
  props: {
    flat: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]), // mapGetters
    ...mapState('traffic', ['selectedPeriod', 'comparedPeriod']),
    ...mapState('trafficPrediction', ['trafficPrediction'])
  }, // computed
  async created() {
    try {
      this.loading = true;
      await this.fetchTrafficPrediction({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        compareStartDate: this.getStartingCompared,
        compareEndDate: this.getEndingCompared,
        storeCodes: this.getStoreCodesList
        // daysOfWeek: this.getDaysOfWeekList,
        // metricKey: 'traffic'
      });
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: `Comparison Error: ${error}`
      });
    } finally {
      this.loading = false;
    } // finally
  },
  methods: {
    ...mapActions('trafficPrediction', ['fetchTrafficPrediction'])
  } // methods
}; // export default
