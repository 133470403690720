
import Field from './Field';

export default {
  components: {
    Field
  },
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: false,
      default: 'any'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    haserror: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    focus: false
  }),
  computed: {
    inputClass() {
      const { $style, focus, haserror } = this;
      return {
        [$style.input]: true,
        [$style.focus]: focus && !haserror,
        'has-error': haserror
      };
    }
  },
  methods: {
    sanitizeOutput(value) {
      if (this.type.toLowerCase() === 'tel') {
        const stripNonNumber = /(^-+)|([^0-9-])|(-+$)/g;
        const stripMultiDashes = /(-+)/g;
        value = value.replace(stripNonNumber, '');
        value = value.replace(stripMultiDashes, '-');
      }
      this.$emit('input', value);
    },
    handleFocus() {
      this.focus = true;
    },
    handleBlur() {
      this.focus = false;
    }
  }
};
