export const formatYAxis = val => {
  let retVal = val;
  if (val && val.toString().length > 3 && parseInt(val) > 999) {
    //return val / 1000 + 'k';//Nabeel
    retVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }  else {
    retVal = val;
  }
  if(isNaN(retVal)) {
    return retVal;
  } else {
    return Number(retVal).toFixed(2)
  }
};
