
import Vue from 'vue';
import { ApexOptions } from 'apexcharts';
import VueApexCharts from 'vue-apexcharts';

export default Vue.extend({
  props: {
    /**
     *
     */
    series: {
      type: Array,
      default: () => []
    },
    /**
     *
     */
    labels: {
      type: Array,
      default: () => []
    },
    /**
     *
     */
    colors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    totalVal() {
      return this.series.reduce((acc, cur) => acc + cur, 0);
    }
  },
  methods: {
    getPercentage(val) {
      return ((val / this.totalVal) * 100).toFixed(2);
    },
    renderChart(h) {
      const apexOptions: ApexOptions = {
        chart: {
          type: 'pie',
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -20
            }
          }
        },
        // tooltip: {
        //   enabled: true,
        //
        // },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `<q-card class="q-pa-md box-trend-tooltip tooltip-container bg-white">
                    <div class="flex items-center">
                    <div class="pie-dot q-mr-sm"
                      style="height: 8px;width: 8px;
  border-radius: 8px;background:${this.colors[seriesIndex]}"></div>
                    <label>${
                      this.labels[seriesIndex]
                    }:</label><span  class="text-bold">${this.getPercentage(
              series[seriesIndex]
            )} %</span>
</div>
                </q-card>`;
          }
        },

        legend: {
          show: true,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        },
        labels: this.labels,
        colors: this.colors,
        dataLabels: {
          formatter: (val: any, series: any): any => {
            return [this.labels[series.seriesIndex], `${val.toFixed(2)}%`];
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            dropShadow: {}
          },
          style: {
            fontSize: '14px',
            colors: ['#333333']
          },
          dropShadow: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 426,
            options: {
              dataLabels: {
                enabled: false
              }
            }
          }
        ]
      };

      return h(VueApexCharts, {
        class: 'group-pie',
        props: {
          height: 350,
          series: this.series as ApexNonAxisChartSeries,
          options: apexOptions
        }
      });
    }
  }, // methods
  render(h) {
    if (this.series.reduce((x, y) => x + y, 0) !== 0) {
      return this.renderChart(h);
    } else {
      return h('span', { class: 'full-width' }, 'No data available');
    }
  } // render
});
