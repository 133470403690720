import axios from "axios"
import store from '@/store';
import { SSODeleteRequest, SSOPostRequest2, SSOPutRequest, SSOGetRequest, SSOGetRequest2, SSOPostReq } from '@/util/kpis';
export default {
  // getWhiteListDomains() {
  //   return axios.get(store.state?.baseSSOUrl+'/api/getWhiteListDomains?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // createWhiteListDomain(domain) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "domain": domain,
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/whitelistDomains', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer "+store.state?.token
  //     },
  //   })
  // },
  // updateWhiteListDomain(brandData) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "brandId": brandData.brandId,
  //     "title": brandData.title,
  //     "description": brandData.description,
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/updateStoreBrand', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // deleteWhiteListDomain(brandId) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "brandId": brandId,
  //   };
  //   return axios.delete(store.state?.baseSSOUrl+'/api/deleteStoreBrand', {
  //     data: userData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async getWhiteListDomains() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      
    }
    const apiResponse= await SSOGetRequest(
      '/api/getWhiteListDomains', paramsData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async createWhiteListDomain(domain) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "domain": domain,
    };
    const apiResponse= await SSOPutRequest(
      '/api/whitelistDomains', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  
  async  updateWhiteListDomain(brandData) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "brandId": brandData.brandId,
      "title": brandData.title,
      "description": brandData.description,
    };
    const apiResponse= await SSOPutRequest(
      '/api/updateStoreBrand', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async deleteWhiteListDomain(brandId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "brandId": brandId,
    };
    const apiResponse= await SSODeleteRequest(
      '/api/deleteStoreBrand',
      {
        data:paramsData,
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
}
