
export default {
  props: {
    configdata: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      eddyConfig: {}
    };
  },
  watch: {
    configdata(newValue) {
      this.eddyConfig = newValue;
    }
  }
};
