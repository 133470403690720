
import Vue from 'vue';
import { QBtn } from 'quasar';
import { normalizeProps } from '@/util/component';

export default Vue.component('Button', {
  functional: true,
  render: (h, context) => {
    const props = normalizeProps(context.props);
    const { listeners, $style } = context;
    const { flat, bordered, disable, id } = props;
    const classes = [
      $style.wrapper,
      !flat && bordered ? $style.bordered : null
    ];
    const baseProps = {
      flat: !props.elevated,
      noCaps: true,
      disable: disable
    };
    const options = {
      props: Object.assign({}, baseProps, props),
      on: listeners
    };
    const createButton = () => h(QBtn, options);
    if (props.noWrapper) {
      options.attrs = { style: 'font-weight: 700;' };
      return createButton();
    }
    return h(
      'span',
      {
        attrs: {
          class: classes.filter(Boolean).join(' '),
          id
        }
      },
      [createButton()]
    );
  }
});
