import axios from "axios"

export default {
  // async setLanguage(locale) {
  //   var url = "https://flow-public-resources.s3.ap-northeast-1.amazonaws.com/translations/translations-insight_settings-en.json"
  //   if (locale === "ja") {
  //     url = "https://flow-public-resources.s3.ap-northeast-1.amazonaws.com/translations/translations-insight_settings-ja.json"
  //   }
  //   var response = await axios.get(url,{headers:null})
  //   return response

  // },
  async getCountries() {
    // const countries = axios.get("https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/static/countries/countries.json",{headers:null})
    const regions = axios.get("https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/cities/cities.json",{headers:null})
    // const states = axios.get("https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/static/countries/states.json",{headers:null})
    const [rRegions] = await Promise.all([regions]);
    // let uniqueStates = [...new Map(rCities.map((item) => [item["prefecture"], item])).values()]
    // uniqueStates = uniqueStates.filter(item => item.prefecture) //  remove empty
    // let data = rCities?.regions.map(item => item.districts.map(v => v.prefectures)).flat()
    // let dataC = data?.map(item => item).flat()
    var rReg = rRegions?.regions.slice(1) // for 
    return { cities: rReg, states: rReg }
    // return { countries: rCountries, cities: rCities, states: rStates }
  }, 
}