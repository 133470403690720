
import Order from '@/models/Order';
import reduce from 'lodash/reduce';
import {
  formatCurrency,
  getCurrencySymbol
} from '@/models/formatters/CurrencyFormatter';

const orderSubtotal = (order: Order) =>
  reduce(
    order.storeOrders,
    (sum, store) => sum + store.quantity * order.sensor.price,
    0
  );

export default {
  props: {
    orders: { type: Array, required: true },
    tax: { type: Object, required: true }
  },
  computed: {
    currency() {
      const order = this.orders[0];
      if (!order) return '¥';
      return getCurrencySymbol(order.sensor.currency);
    },
    totalTax() {
      let taxAmount = 0;
      if (this.tax.taxAmountType === 'PERCENTAGE') {
        taxAmount = this.subtotal * (this.tax.amount / 100);
      }
      return taxAmount;
    },
    subtotal() {
      const result = reduce(
        this.orders,
        (sum, order) => sum + orderSubtotal(order),
        0
      );
      return result;
    }
  },
  methods: {
    formattedCurrency(amount) {
      return formatCurrency(amount, null);
    }
  }
};
