import { render, staticRenderFns } from "./SignupTab.vue?vue&type=template&id=7422512f&"
import script from "./SignupTab.vue?vue&type=script&lang=js&"
export * from "./SignupTab.vue?vue&type=script&lang=js&"
import style0 from "@/styles/landing/auth.scss?vue&type=style&index=0&prod&module=true&lang=scss&"
import style1 from "./SignupTab.vue?vue&type=style&index=1&id=7422512f&prod&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
import {QForm,QCardSection,QInput,QCheckbox,QBtn,QInnerLoading,QSpinnerTail} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QCardSection,QInput,QCheckbox,QBtn,QInnerLoading,QSpinnerTail})
