
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin],
  props: {
    selectLabel: { type: String, required: true },
    cancelLabel: { type: String, required: true },
    active: { type: Boolean, default: false }
  }
};
