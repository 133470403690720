import randomFill from 'randomfill';

export function randomString(
  length: number,
  charset: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
) {
  let result = '';
  while (result.length < length) {
    const rand = Math.floor(Math.random() * charset.length);
    const char = charset[rand];
    result = `${result}${char}`;
  }
  return result;
}

/**
 * Generates RFC-4122 Section 4.4-compliant UUID (Algorithms for Creating a
 * UUID from Truly Random or Pseudo-Random Numbers).
 *
 * This function relies on `randomfill` package to provide
 * a cryptographically-secure pseudo-random number generator (CSPRNG)
 */
export function uuidv4() {
  var buffer = new Uint8Array(16);
  const rand = randomFill.randomFillSync(buffer);

  //  Set the version number to UUIDv4
  //  (MSB of octet 6 should be 0 1 0 0)
  rand[6] = (rand[6] & 0x0f) | 0x40;
  //  Set variant code to the RFC-4122 definition
  //  (MSB of octet 8 should be 1 0 x)
  rand[8] = (rand[8] & 0x3f) | 0x80;

  const hexForm = [];
  rand.forEach(value => {
    const hex = `0${value.toString(16)}`;
    hexForm.push(hex.substr(hex.length - 2));
  });

  const timeLow = hexForm.splice(0, 4).join('');
  const timeMid = hexForm.splice(0, 2).join('');
  const timeHighAndVersion = hexForm.splice(0, 2).join('');
  const clockHighAndReserved = hexForm.splice(0, 1).join('');
  const clockSeqLow = hexForm.splice(0, 1).join('');
  const clock = `${clockHighAndReserved}${clockSeqLow}`;
  const node = hexForm.join('');
  return `${timeLow}-${timeMid}-${timeHighAndVersion}-${clock}-${node}`;
}
