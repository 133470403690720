
import CommonMixin from '@/mixins/CommonMixin';
import { createColumn, formatAsCurrency } from '@/util/table';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import { PaymentConfirmation } from '@/models/RequestOrder';
import { handleSuccessUI, handleErrorUI } from '@/util/error';
import { Bank } from '@/models/Bank';
import PaginationMixin from '@/mixins/PaginationMixin';

enum PaymentMethod {
  BANK,
  CREDIT_CARD
}

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: { default: false, type: Boolean },
    order: { default: () => ({}), type: Object }
  },
  data() {
    return {
      tableData: [],
      paymentMethodOption: [
        {
          label: 'Bank',
          value: PaymentMethod.BANK
        },
        {
          label: 'Credit Card',
          value: PaymentMethod.CREDIT_CARD
        }
      ],
      activePaymentMethod: PaymentMethod.BANK,
      banks: [],
      creditCards: [],
      selectedPaymentMethod: null,
      paymentMethods: [],
      tableColumns: [
        createColumn('sensorName', 'Sensor Name'),
        createColumn('quantity', 'Quantity'),
        createColumn('price', 'Price', formatAsCurrency()),
        createColumn('subtotal', 'Subtotal', formatAsCurrency())
      ]
    };
  },
  watch: {
    value(show) {
      if (show) {
        this.formatTableData();
      }
    },
    async activePaymentMethod(method) {
      await this.retrievePaymentMethods(method);
    }
  },
  async mounted() {
    await this.retrievePaymentMethods(this.activePaymentMethod);
  },
  methods: {
    formatWithCurrency(value) {
      return formatCurrencyWithSymbol(value, this.order.currency.code);
    },
    formatTableData() {
      if (this.order == null) return [];
      const orderList = this.order.orders || [];
      const orders = orderList.map(item => ({
        id: item.id,
        sensorName: item.productName,
        quantity: item.quantity,
        currency: 'JPY',
        price: item.price,
        subtotal: item.subtotal
      }));
      this.tableData = orders;
    },
    closeDialog() {
      this.$emit('input', false);
    },
    async retrievePaymentMethods(method: PaymentMethod) {
      this.selectedPaymentMethod = null;
      if (method === PaymentMethod.BANK) {
        if (this.banks.length === 0) await this.retrieveBankList();
        this.paymentMethods = this.banks.map(bank => ({
          label: bank.name,
          value: bank.code
        }));
      } else {
        if (this.creditCards.length === 0) await this.retrieveCreditCardList();
        this.paymentMethods = this.creditCards.map(card => ({
          label: card.cardNumber,
          value: card.id
        }));
      }
    },
    async retrieveBankList() {
      const { data } = await this.$repo.order.getBank();
      this.banks = data;
    },
    async retrieveCreditCardList() {
      const { tenant } = await this.$repo.session.fetch();
      this.creditCards = await this.$repo.order.getCreditCards(tenant);
    },
    async confirmPayment() {
      try {
        if (this.selectedPaymentMethod == null) return;
        const confirmation: PaymentConfirmation = {
          tenantCardId: null,
          paymentMethod: null
        };
        if (this.activePaymentMethod === PaymentMethod.BANK) {
          const selectedBank = this.banks.find(
            bank => bank.code === this.selectedPaymentMethod
          );
          confirmation.paymentMethod = selectedBank.toDTO();
        } else {
          confirmation.tenantCardId = this.selectedPaymentMethod;
        }
        await this.$repo.order.confirmPayment(
          this.order.orders[0],
          confirmation
        );
        handleSuccessUI('Payment confirmed successfully');
        this.success();
      } catch (err) {
        handleErrorUI(err);
        this.fail();
      }
    },
    success() {
      this.$emit('success', this.order);
    },
    fail() {
      this.$emit('fail');
    }
  }
};
