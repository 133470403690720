
import { mapState, mapMutations } from 'vuex';
// import { kpiListGet } from '@/util/mockData/kpiMapping';
export default {
  props: {
    lastTimeStamp: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    regions: {
      type: Array,
      default: () => []
    },
    districts: {
      type: Array,
      default: () => []
    },
    stores: {
      type: Array,
      default: () => []
    },  
    groups: {
      type: Array,
      default: () => []
    },
    areaManagers: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      tab: 'sv',
      kpi: [],
      options: [],
      kpiListOrder: [],
      refLines: [],
      refLinesOptions: [
        {
          label: `${this.$t('Home.average')}`,
          value: `${this.$t('Home.average')}`
        },
        {
          label: `${this.$t('Home.median')}`,
          value: `${this.$t('Home.median')}`
        },
        {
          label: `${this.$t('Home.maximum')}`,
          value: `${this.$t('Home.maximum')}`
        },
        {
          label: `${this.$t('Home.minimum')}`,
          value: `${this.$t('Home.minimum')}`
        }
      ]
    };
  },
  computed: {
    ...mapState('home', ['comparisonTab']),
    ...mapState('user', ['kpiLabelValue']),
    kpiOptions() {
      return this.kpiListOrder?.map(kpi => ({
        label: kpi.label,
        value: kpi.value
      }));
    },

    tabOptions() {
      return [
        { label: this.$t('BoxComparison.region'), value: 'region' },
        { label: this.$t('BoxComparison.district'), value: 'district' },
        { label: this.$t('BoxComparison.store'), value: 'store' },
        { label: this.$t('BoxComparison.peer_group'), value: 'group' },
        { label: this.$t('BoxComparison.area_manager'), value: 'sv' }
      ];
    }
  },
  watch: {
    comparisonTab(newValue) {
      this.tab = newValue;
    },
    kpi(newVal) {
      this.$emit('refreshPageComparision', newVal)
    }
  },
  async created() {
    // const kpiList = await kpiListGet();
    // this.kpiListOrder = [...kpiList];

    this.kpiListOrder = [...this.kpiLabelValue];
    this.tab = this.comparisonTab;
    this.kpi = this.kpiOptions[0] ?? {};
    this.options = this.kpiOptions;
    this.refLines = [this.refLinesOptions[0].value];

  }, // data
  methods: {
    ...mapMutations('home', ['setComparisonTab']),
    changeTab(value) {
      this.setComparisonTab({ tab: value });
    },

    refreshPageComparision(val) {
      // this.$emit('refreshPageComparision', val)
    },
  }
}; // export default
