var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-card',{attrs:{"page-name":"User Management","title":_vm.$t('SettingsManagement.EditEmployee'),"index-route-name":"management:user"}},[(_vm.loading)?_c('div',[_vm._v(" Loading user data... ")]):[_c('q-form',{staticClass:"q-pa-md",on:{"submit":function($event){$event.preventDefault();return _vm.submitUser.apply(null, arguments)}}},[_c('user-form',{attrs:{"edit-mode":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('div',{staticClass:"flex justify-end q-mt-lg"},[_c('router-link',{attrs:{"to":{ name: 'management:user' }}},[_c('q-btn',{attrs:{"outline":"","label":_vm.$t('SettingsManagement.cancel'),"text-color":"secondary"}})],1),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"elevated":"","type":"submit","label":_vm.$t('SettingsManagement.save'),"color":"secondary"}})],1)],1),_c('div',[_c('form-separator',{attrs:{"label":_vm.$t('AuthPage.changePassword')}}),_c('q-form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.submitPasswordChange.apply(null, arguments)}}},[_c('div',{staticClass:"col-md-6 q-pr-md"},[_c('q-input',{attrs:{"filled":"","borderless":"","label":_vm.$t('AuthPage.new_password'),"type":"password","placeholder":_vm.$t('AuthPage.type_new_password'),"rules":[
                  v => !!v || _vm.$t('AuthPage.field_is_required'),
                  v => v.length > 5 || _vm.$t('AuthPage.min_length'),
                  v =>
                    /^(?=.*\d).{1,}$/.test(v) ||
                    _vm.$t('AuthPage.password_one_number'),
                  v =>
                    /^(?=.*[A-Z]).{1,}$/.test(v) ||
                    _vm.$t('AuthPage.password_one_uppercase'),
                  v =>
                    /^(?=.*[a-z]).{1,}$/.test(v) ||
                    _vm.$t('AuthPage.password_one_lowercase'),
                  v =>
                    _vm.checkSpecialSymbols(v) ||
                    _vm.$t('AuthPage.password_special_symbol'),
                  v =>
                    _vm.checkFullwidth(v) ||
                    _vm.$t('AuthPage.no_full_width_characters'),
                  v => !/\s/g.test(v) || _vm.$t('AuthPage.no_white_space')
                ],"mandatory":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('q-input',{attrs:{"filled":"","borderless":"","rules":[
                  v => !!v || _vm.$t('AuthPage.field_is_required'),
                  v =>
                    v === _vm.newPassword ||
                    _vm.$t('AuthPage.password_does_not_match')
                ],"label":_vm.$t('AuthPage.confirm_new_password'),"type":"password","placeholder":_vm.$t('AuthPage.confirm_new_password'),"mandatory":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('div',{staticClass:"flex justify-end q-mt-lg"},[_c('router-link',{attrs:{"to":{ name: 'management:user' }}},[_c('q-btn',{attrs:{"outline":"","label":_vm.$t('SettingsManagement.cancel'),"text-color":"secondary"}})],1),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"elevated":"","type":"submit","label":_vm.$t('SettingsManagement.save'),"color":"secondary"}})],1)],1)])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }