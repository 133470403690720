
import { createI18n, createData } from './Forms/SetPasswordModels';
import AuthMixin from '@/mixins/AuthMixin';
import { loginDashboard } from '@/util/navigation';
import { handleErrorUI } from '@/util/error';
import LoginTabs from '@/components/Auth/Tabs.vue';
import moment from 'moment';
import { getAuthoritiesObj } from '@/util/authoritiesCheck';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Vue from 'vue';
import CommonMixin from '../../../mixins/CommonMixin';
import { UPDATE_COMPANIES, VIEW_COMPANIES } from '@/consts/permissions';
import { setUsersParamsValues } from '@/util/userParams';
import { Notify } from 'quasar';
import config from '@/config';


const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export default {
  components: { LoginTabs },
  mixins: [AuthMixin, CommonMixin],
  props: {
    email: { type: String, required: true, default: '' },
    username: { type: String, required: true, default: '' },
    tenant: { type: String, required: true, default: '' }
  },
  data() {
    return {
      userlang:null,
      password: '',
      confirmPassword: '',
      isHide: false
    };
  },
  computed: {
    ...mapState('user', ['profile', 'defaultSettings']),
    ...mapGetters('user', ['isAuthenticated', 'getLocale']),
    ...mapGetters('tenant', ['hasResetAccount']),
    ...mapGetters('user', ['getLocale'])
  },
  methods: {
    ...mapActions('user', [
      'authenticate',
      'fetchProfile',
      'authenticateDemo',
      'fetchClientProfile',
      'authenticateInvite',
      'authenticateSSO',
      'fetchKpiPreferences',
     // 'fetchKpiPreferences2',
      'fetchStores',
      'clearSession'
    ]),
    ...mapMutations('user', [
      'setUserPermissions',
      'setDefaultSettings'
    ]),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    togglePassword() {
      this.isHide = !this.isHide;
    },
    setUsersParams() {
      const storedUserId = localStorage.getItem('userID');
      if (storedUserId !== this.profile?.userID) {
        localStorage.removeItem('storeCodes');
      }
      localStorage.setItem('userID', this.profile?.userID);
      let stores = localStorage.getItem('storeCodes');
      if (stores !== null) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      } else {
        this.setStoreCodes({ storeCodes: this.profile?.stores?.split(',') });
        localStorage.setItem('storeCodes', this.profile?.stores);
      }
      this.setStartingPeriod({
        startingPeriod: this.profile?.defaultDateFilter?.startDate ?? new Date()
      });
      this.setEndingPeriod({
        endingPeriod: this.profile?.defaultDateFilter?.endDate ?? new Date()
      });
      this.setStartingCompared({
        startingCompared:
          this.profile?.defaultDateFilter?.compareStartDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      });
      this.setEndingCompared({
        endingCompared:
          this.profile?.defaultDateFilter?.compareEndDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      });
      this.setRetailCalendar({
        dates: this.profile?.custom445Calendar
          ? this.profile?.custom445Calendar
          : {}
      });
      this.setCustomCalenderFlag({
        value: this.profile?.defaultDateFilter?.useCustomCalender
      });
      localStorage.setItem(
        'startingPeriod',
        this.profile?.defaultDateFilter?.startDate ?? new Date()
      );
      localStorage.setItem(
        'endingPeriod',
        this.profile?.defaultDateFilter?.endDate ?? new Date()
      );
      localStorage.setItem(
        'startingCompared',
        this.profile?.defaultDateFilter?.compareStartDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      );
      localStorage.setItem(
        'endingCompared',
        this.profile?.defaultDateFilter?.compareEndDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      );
      localStorage.setItem(
        'useCustomCalender',
        this.profile?.defaultDateFilter?.useCustomCalendar || false
      );
    },
    async loginOld() {
      this.loading = true;
      this.disable = true;

      this.username = this.username === null ? '' : this.username;
      this.password = this.password === null ? '' : this.password;
      try {
        await this.authenticate({
          username: this.username,
          password: this.password
        });
        const { tenant } = await this.$repo.auth.login(
          this.username,
          this.password
        );
        await this.authenticateDemo({
          hasResetAccount: this.hasResetAccount
        });
        const session = await this.$repo.session.put(tenant);
        this.$client.setSession(session);
        await this.initialize();
        await this.flowLogin();
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
        this.disable = false;
        await this.$router.push({ name: 'home' }).catch(_ => {});
      } // finally
    },
    async flowLogin() {
      try {
        // const session = await this.$repo.session.fetch();
        // if (!session) return;
        const user = await this.$repo.user.getUser('me');
        // const userDetails = await this.$repo.user.getDefaultSettingsDetails();
        const userDetails = this.defaultSettings;
        this.setDefaultSettings({ ...userDetails, user });
        let permissions = getAuthoritiesObj(user?.roles);
        this.setUserPermissions(permissions);
        await this.fetchProfile();
        await this.fetchClientProfile();
        this.setUsersParams();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: 'Username or Password is incorrect. Please try again.',
          position: 'top',
          timeout: 3000
        });
      } finally {
        this.loading = false;
        this.disable = false;
      }
    },
     async login() {
      try {
          const tenant = await this.authenticateSSO({
            username: this.user.username,
            password: this.admin.password,
            userlang: this.getLocale
          });
        //  this.$repo.settings.getSchema(this.user.username, this.admin.password);
          const user = await this.$repo.user.getUser('me');
          await this.fetchStores();
          const userDetails = await this.$repo.settings.getDefaultSettings();
          const usrRoleSetting = user.response.userDetails.role.config;
          const usrTenentSetting = user.response.userDetails.tenant.config;
          if(usrRoleSetting != null && usrRoleSetting != 'undefined') {
            userDetails.locationFilter = usrRoleSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrRoleSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrRoleSetting.selectedPeriod;
          } else
          {
            userDetails.locationFilter = usrTenentSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrTenentSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrTenentSetting.selectedPeriod;
          }
          this.setDefaultSettings({ ...userDetails, user });
          ////////////////
          this.$client.setSession({
            tenant: tenant.intercom.companyName
          });
          await this.$repo.session.put(tenant.intercom.companyName);
          //,tenant.intercom.companyId,tenant.intercom.addonsIds,tenant.intercom.plansIds
          localStorage.setItem('userID', this.profile?._id);
          await this.setUsersParamsLogin();
          await this.fetchKpiPreferences();
       //   await this.fetchKpiPreferences2();
          await this.$router.push({ name: 'home' }).catch(_ => {});
      } catch (err) {
        this.$q.notify({
          message: this.$t('AuthPage.login_error_message'),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
        localStorage.clear();
        this.$v.$reset();
        this.signout();
      } finally {
        this.loading = false;
        this.disable = false;
      }
    },
    async setUsersParamsLogin() {
        await setUsersParamsValues();
    },
    async submitPassword() {
      try {
        const code = this.$router.currentRoute.query.code;
        this.email = this.$router.currentRoute.query.email;
        const data = {
          code: code,
          password: this.password
        };
        const res = await this.authenticateInvite(data);
           // this.$repo.settings.getSchema(this.email, this.password);
          const tenant = await this.authenticateSSO({
            username: this.email,
            password: this.password,
            userlang: this.getLocale
          });
        //  this.$repo.settings.getSchema(this.email, this.password);
          const user = await this.$repo.user.getUser('me');
          await this.fetchStores();
          const userDetails = await this.$repo.settings.getDefaultSettings();
          const usrRoleSetting = user.response.userDetails.role.config;
          const usrTenentSetting = user.response.userDetails.tenant.config;
          if(usrRoleSetting != null && usrRoleSetting != 'undefined') {
            userDetails.locationFilter = usrRoleSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrRoleSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrRoleSetting.selectedPeriod;
          } else
          {
            userDetails.locationFilter = usrTenentSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrTenentSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrTenentSetting.selectedPeriod;
          }
          this.setDefaultSettings({ ...userDetails, user });
          ////////////////
          this.$client.setSession({
            tenant: tenant.intercom.companyName
          });
          await this.$repo.session.put(tenant.intercom.companyName);
          //,tenant.intercom.companyId,tenant.intercom.addonsIds,tenant.intercom.plansIds
          localStorage.setItem('userID', this.profile?._id);
          await this.setUsersParamsLogin();
          await this.fetchKpiPreferences();
         // await this.fetchKpiPreferences2();
          await this.$router.push({ name: 'home' }).catch(_ => {});
      } catch (err) {
        handleErrorUI(err?.response?.data || err?.response);
      }
    }
  }
};
