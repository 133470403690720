
import { mapState, mapActions, mapGetters } from 'vuex';
import { addWeeks, differenceInDays, startOfWeek } from 'date-fns';
import moment from 'moment';
import { DUO_TONES } from '../../util/colors';
import { formatYAxis } from '../../util/legend';
import { weatherObj } from '@/util/weatherIcons';
import { addSign } from '@/util/replaceFidaKpi';

export default {
  props: {
    datevalue: {
      type: Object,
      required: true,
      default: () => {}
    },
    type: {
      type: String,
      required: true,
      default: ''
    } // options
  }, // props

  data() {
    return {
      loading: false,
      series: [],
      formattedSelected: [],
      formattedCompared: [],
      selectedIcon: [],
      weatherData: [],
      temperatures: [],
      chartOptions: {
        chart: {
          height: 230,
          type: 'line',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: '<i class="fas fa-download"></i>',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        colors: DUO_TONES,
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'straight',
          dashArray: [0, 6]
        },
        legend: {
          show: true,
          position: 'top',
          markers: {
            radius: 0,
            width: 16,
            height: 4,
            customHTML: function() {
              return `<style>
                    .box-trend .apexcharts-legend-series:nth-child(odd) .custom-marker-periodic-trend{
                          display:none;
                     }
</style>
                          <div class='custom-marker-periodic-trend' style='position:absolute; width:4px; height:6px; left:0;top:-2px;background:white'></div>
                        <div class='custom-marker-periodic-trend' style='position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white'></div>`;
            }
          }
        },
        yaxis: {
          type: 'number',
          forceNiceScale: true,
          tickAmount: 4,
          labels: {
            formatter: value => {
              return formatYAxis(value);
            }
          }
        },
        xaxis: {
          categories: [],
          tooltip: { enabled: false }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `<q-card class='q-pa-md box-trend-tooltip tooltip-container'>
                       <div class='flex q-mb-sm q-px-md justify-between'>
                          <label>
      ${
        this.getPulseStartingPeriod === this.getPulseEndingPeriod
          ? this.getPulseStartingPeriod +
            '  ' +
            (w.config.series[0].data[dataPointIndex]
              ? w.config.series[0].data[dataPointIndex].z
              : '')
          : w.config.series[0].data[dataPointIndex]
          ? w.config.series[0].data[dataPointIndex].z
          : ''
      }</label>
                        <div class='flex'>
                        ${
                          this.temperatures[dataPointIndex]
                            ? `<div class='flex items-center weather-tooltip'>
                            ${weatherObj[this.weatherData[dataPointIndex]]}
                                <div class='q-ml-xs'>${this.temperatures[
                                  dataPointIndex
                                ].toFixed(2)}°C</div></div>`
                            : ''
                        }
                        </div>
                    </div>
                <div class='tooltip-title flex q-mx-md items-center'>
                <div class='tooltip-title-color' style='background:${
                  w.config.colors[0]
                }'></div>
                <label>${this.$t('selected_period')} :</label>
                   <div class='q-ml-xs text-bold'>
                 ${this.formattedSelected[dataPointIndex]}
                </div>

                </div>
                <div class='tooltip-title q-mx-md flex items-center'>
                <div class='tooltip-title-color' style='background:${
                  w.config.colors[1]
                }'></div>
                <label> ${this.$t('compared_period')} :</label>
                  <div class='q-ml-xs text-bold'> ${
                    this.formattedCompared[dataPointIndex]
                      ? this.formattedCompared[dataPointIndex]
                      : '-'
                  }</div>
                  </div>
                </q-card>`;
          },
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      }
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getCustomCalenderState',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'filter',
      'retailCalendar'
    ]), // mapGetters
    ...mapState("trend", ["selectedPeriod", "comparedPeriod", "apiCall"]),
    ...mapState("weather", ["weather", "weatherCompare"]),
    ...mapState('periodicReport', ['selectedStoreCode']),
    datesList() {
      return {
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared
      };
    }
  }, // computed
  watch: {
    /* apiCall: {
      handler(newValue, oldValue) {
        this.addWeatherHander();
      },
      immediate:true
    },
   
    weatherCompare: {
      handler(newValue, oldValue) {
          this.addWeatherHander();
      },
    },*/
    async weather() {
      await this.loadData();
    },
    async selectedStoreCode() {
      await this.loadData();
    },
    datesList: {
      handler: 'loadData',
      immediate: true
    }
  },
  async created() {
    if (this.selectedStoreCode !== '') {
      await this.loadData();
    }
  }, // created
  methods: {
    ...mapActions('trend', ['fetchTrend', 'fetchTrendHour']),
    async loadData() {
      try {
        this.loading = true;
        this.$emit('changeLoading', true);

        let params = {
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseEndingPeriod,
          compareStartDate: this.getPulseStartingCompared,
          compareEndDate: this.getPulseEndingCompared,
          storeCodes: this.selectedStoreCode,
          daysOfWeek: this.datevalue.daysOfWeek,
          metricKey: this.type,
          useCustomCalender: this.getCustomCalenderState
        };
        if (this.getPulseStartingPeriod === this.getPulseEndingPeriod) {
          /*await this.fetchTrendHour({
            ...params
          });*/
        } else {
          await this.fetchTrend({
            ...params
          });
        }
 this.chartOptions = {
          ...this.chartOptions,
      };

      var filename1;
      if(this.type == 'sls')
        filename1 = `${this.$t('KPI.sls')} - ${this.$t('MonthlyReport.Trend')} ${this.$t(
                    'KpiAnalysis.selected'
                  )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                    'KpiAnalysis.compared'
                  )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`;
      else if(this.type == 'trf')
        filename1 = `${this.$t('KPI.trf')} - ${this.$t('MonthlyReport.Trend')} ${this.$t(
                    'KpiAnalysis.selected'
                  )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                    'KpiAnalysis.compared'
                  )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`;
      else if(this.type == 'pb')
        filename1 = `${this.$t('KPI.pb')} - ${this.$t('MonthlyReport.Trend')} ${this.$t(
                    'KpiAnalysis.selected'
                  )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                    'KpiAnalysis.compared'
                  )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`;
      else 
        filename1 = '';

      this.chartOptions.chart.toolbar = {
             export: {
              svg: {
                filename: filename1
              },
              png: {
                filename: filename1
              },
              csv: {
                filename: filename1
              }
            }
           
          };
    
        this.addWeatherHander();
      } catch (error) {
        // console.log(error);
        // this.$q.notify({
        //   type: 'negative',
        //   position: 'top',
        //   message: error
        // });
      }
      this.loading = false;
      this.$emit('changeLoading', false);
    },
    async addWeatherHander(){
      let lang = this.$store.state.user.locale;
      let jChar = lang == "ja" ? "日" : ""
      const data = [
        {
          name: this.$t('selected_period'),
          data: []
        },
        {
          name: this.$t('compared_period'),
          data: []
        }
      ];
      this.selectedIcon = []
      this.temperatures = []
      this.weatherData = []
      this.formattedSelected = [];
      this.formattedCompared = [];
      await this.selectedPeriod?.forEach(date => {
          this.formattedSelected = [
            ...this.formattedSelected,
             addSign(this.type,date.formattedValue.replace(/[¥円人]/g, ''))];

          var findObj = this.weather?.find(v => v.hasOwnProperty(date.timeLabel));
          this.weatherData = [...this.weatherData, findObj && findObj[date.timeLabel]?.icon];
          this.selectedIcon = [...this.selectedIcon, findObj && findObj[date.timeLabel]?.icon];
          this.temperatures = [...this.temperatures, findObj && findObj[date.timeLabel]?.temp];
          let label =
            this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? date.timeLabel + ':00'
              : moment(date.timeLabel).locale(lang).format(lang === 'en' ?'MMM D': 'MMMD') + jChar;
          data[0].data.push({
            x: label,
            y: date.value.toFixed(2),
            z: this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? date.timeLabel + ':00'
              : moment(date.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(date.timeLabel)})`,
          });
        });
        
        await this.comparedPeriod?.forEach((date, index) => {
          this.formattedCompared = [
            ...this.formattedCompared,
              addSign(this.type,date.formattedValue.replace(/[¥円人]/g, ''))];
          let label =
            this.datevalue.endDate === this.datevalue.startDate
              ? date.timeLabel + ':00'
              : moment(date.timeLabel).locale(lang).format(lang === 'en' ?'MMM D': 'MMMD') + jChar;
          data[1].data.push({
            x: label,
            y: date.value.toFixed(2) ? date.value.toFixed(2) : 0,
             z: this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? date.timeLabel + ':00'
              : moment(date.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(date.timeLabel)})`,
          });
        });
        this.series = data;
    },
    getUnits() {
      if (val === this.$t('sales')) {
        return '円';
      }
      if (val === this.$t('traffic')) {
        return '人';
      }
      if (val === this.$t('transactions')) {
        return '人';
      }
      if (val === this.$t('conversion')) {
        return '%';
      }
      if (val === this.$t('averagesale')) {
        return '円';
      }
      if (val === this.$t('averagepurchase')) {
        return '円';
      }
      if (val === this.$t('upt')) {
        return '点';
      }
      if (val === this.$t('rov')) {
        return '円';
      }
      if (val === this.$t('averageitemprice')) {
        return '円';
      }
      if (val === this.$t('salespersqm')) {
        return '円';
      }
      if (val === this.$t('trafficpersqm')) {
        return '人';
      }
      if (val === this.$t('stafflevel')) {
        return '人';
      }
      if (val === this.$t('staffhours')) {
        return '人';
      }
      if (val === this.$t('closingstrength')) {
        return '人';
      }
      if (val === this.$t('staffefficiency')) {
        return '円';
      }
      if (val === this.$t('passersby')) {
        return '人';
      }
      if (val === this.$t('capturerate')) {
        return '%';
      }
      // if (val === this.$t('facadestops')) {
      //   return '人';
      // }
      if (val === this.$t('storedwelltime')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('repeatrate')) {
        return '-';
      }
      if (val === this.$t('engagementrate')) {
        return '％';
      }
      if (val === this.$t('bouncerate')) {
        return '％';
      }
      if (val === this.$t('gendercomparison')) {
        return '％';
      }
      if (val === this.$t('age')) {
        return '％';
      }
      if (val === this.$t('fittingroomvisits')) {
        return '人';
      }
      if (val === this.$t('fittingroom %')) {
        return '％';
      }
      if (val === this.$t('fittingroomconversion %')) {
        return '％';
      }
      if (val === this.$t('fittingroomdwelltime')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('areapassersby')) {
        return '人';
      }
      if (val === this.$t('areastops')) {
        return '人';
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('Sun');
      }
      if (dayVal === 1) {
        return this.$t('Mon');
      }
      if (dayVal === 2) {
        return this.$t('Tue');
      }
      if (dayVal === 3) {
        return this.$t('Wed');
      }
      if (dayVal === 4) {
        return this.$t('Thu');
      }
      if (dayVal === 5) {
        return this.$t('Fri');
      }
      if (dayVal === 6) {
        return this.$t('Sat');
      }
    },
    getPreviousPeriod(val) {
      let compPeriod = { startingPeriod: null, endingPeriod: null };
      const diffDays = differenceInDays(val.startingPeriod, val.endingPeriod);
      const diffDaysPositive =
        diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
      const weeks = Math.ceil((+diffDaysPositive / 7).toFixed(2));
      compPeriod.startingPeriod = moment(
        addWeeks(val.startingPeriod, -weeks)
      ).format('YYYY-MM-DD');
      compPeriod.endingPeriod = moment(
        addWeeks(val.endingPeriod, -weeks)
      ).format('YYYY-MM-DD');
      return compPeriod;
    } // hide
  } // methods
}; // export default
