
import {
  getCurrencySymbol,
  formatCurrency
} from '@/models/formatters/CurrencyFormatter';

export default {
  props: {
    addOn: { type: Object, required: true },
    purchased: { type: Boolean, default: false }
  },
  computed: {
    menuList() {
      return '';
    },
    currencySymbol() {
      return getCurrencySymbol(this.addOn.currency.code);
    },
    price() {
      return formatCurrency(this.addOn.price, this.addOn.currency.code);
    }
  },
  methods: {
    selectAddOn() {
      let event = 'purchaseAddOn';
      if (this.purchased) {
        event = 'cancelAddOn';
      }
      this.$emit(event, this.addOn);
    }
  }
};
