
import OnboardingBanner from '@/components/Onboarding/OnboardingBanner';
import OnboardingDialog from '@/components/Onboarding/OnboardingDialog';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    OnboardingBanner,
    OnboardingDialog
  },
  data: () => ({
    dialogShown: false,
    scrolled: false,
	accountTypeStatus: localStorage.getItem('accountType')
  }),
  computed: {
    ...mapGetters('tenant', ['hasResetAccount']),
    belowTopBar() {
      return (
        this.$route.fullPath.includes('management') ||
        this.$route.fullPath.includes('export') ||
        this.$route.fullPath.includes('daily-report') ||
        this.$route.fullPath.includes('weekly-report') ||
        this.$route.fullPath.includes('monthly-report') ||
        this.$route.fullPath.includes('periodic-report-list') ||
        this.$route.fullPath.includes('academy') ||
        this.$route.fullPath.includes('partners') ||
        this.$route.fullPath.includes('partner-portal') ||
        this.$route.fullPath.includes('default') ||
        this.$route.fullPath.includes('settings') ||
        this.$route.fullPath.includes('company') ||
        this.$route.fullPath.includes('location-comparison-reportF') ||
        this.$route.fullPath.includes('account') ||
        this.$route.fullPath.includes('extension') ||
        this.$route.fullPath.includes('tools/log')
      );
    }
  },
  methods: {
    handleScroll(evt, el) {
      this.scrolled = evt > 10;
    }
  }
};
