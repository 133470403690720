
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import Store from '@/models/Store';
import PeerGroup from '@/models/PeerGroup';
import { handleErrorUI } from '@/util/error';
import map from 'lodash/map';
import StoreTable from '@/components/Management/Store/Table.vue';
import StoresCheckbox from '@/components/Common/StoresCheckbox/StoresCheckbox.vue';
import AreaManager from '@/models/AreaManager';

export default {
  name: 'PeerGroupForm',
  components: { StoresCheckbox, StoreTable },
  mixins: [CommonMixin, FormMixin],
  props: {
    backLocation: { type: [String, Object], required: true },
    isEdit: { type: Boolean, default: true },
    mode: { type: String, default: 'Edit' },
    peerGroupId: { type: String, default: '' }
  },
  data() {
    return {
      loading: false,
      peerGroup: {},
      selectedStores: []
    };
  },
  computed: {
    pageTitle() {
      return this.mode === 'View'
        ? this.$t('CreatePeerGroup.ViewPeerGroup')
        : this.isEdit
        ? this.$t('CreatePeerGroup.EditPeerGroup')
        : this.$t('CreatePeerGroup.CreatePeerGroup');
    }
  },
  mounted() {
    if (this.peerGroupId) this.loadPeerGroup(this.peerGroupId);
  },
  methods: {
    setStoresList(val) {
      this.selectedStores = val;
    },
    loadPeerGroup(id: string) {
      this.loading = true;
      this.$repo.peerGroup.getPeerGroup(id).then(peerGroup => {
        this.peerGroup = peerGroup;
        this.selectedStores = peerGroup.stores;
        this.loading = false;
      }, handleErrorUI);
    },
    removeStore(removedStore: Store) {
      this.selectedStores = this.selectedStores.filter(
        store => store.id !== removedStore.id
      );
    },
    async submitPeerGroup() {
      if (this.selectedStores.length > 0) {
        try {
          await this.$repo.peerGroup.upsertPeerGroup(
            this.peerGroup as PeerGroup,
            map(this.selectedStores, store => store.id)
          );
          if(this.peerGroup.id != null && this.peerGroup.id !=undefined && this.peerGroup.id !=undefined)
          {
            this.$q.notify({
            type: 'positive',
            message: this.$t('SettingsManagement.peerGroupUpdate'),
            position: 'top'
          });
          } else {
            this.$q.notify({
            type: 'positive',
            message: this.$t('SettingsManagement.peerGroupAdded'),
            position: 'top'
          });
          }
          
          
          await this.$router.push(this.backLocation).catch(_ => {});
        } catch (err) {
          handleErrorUI(err);
        }
      } else {
        this.$q.notify({
          position: 'top',
          message: this.$t('AuthPage.at_least_one_store'),
          type: 'negative'
        });
      }
    }
  }
};
