
import { weatherObj } from '@/util/weatherIcons';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import { formatYAxis } from '../../util/legend';
import moment from 'moment';
import weather from '../../../public/weather.js'
import { addSign } from '@/util/replaceFidaKpi';

export default {
  props: {
    datevalue: {
      type: Object,
      required: true,
      default: () => {}
    },
    selectedkpi: {
      type: String,
      default: '',
      required: false
    },
    comparekpi: {
      type: String,
      default: '',
      required: false
    },
    setselectedkpi: {
      type: Function,
      required: false,
      default: () => {}
    },
    setcomparekpi: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data: function() {
    return {
      apiCall: 0,
      granularityOptions: [
        { label: this.$t('Date.hourly'), value: 'hourly' },
        { label: this.$t('Date.daily'), value: 'daily' },
        { label: this.$t('Date.weekly'), value: 'weekly' },
        { label: this.$t('Date.monthly'), value: 'monthly' }
      ],
      axisVisibility: {
        index1: true,
        index2: true,
        index3: false,
        index4: false
      },
      selectedIcon: [],
      temperatures: [],
      weatherData: [],
      selectedIcon2: [],
      temperatures2: [],
      weatherData2: [],
      analysisType: 'daily',
      selectedKPI: '',
      compareKPI: '',
      weather:[],
      weatherCompare:[],
      kpiCategories: [],
      kpiCategories2: [],
      // series: [],
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          events: {
            legendClick: (chartContext, seriesIndex, config) => {
              if (seriesIndex === 0) {
                this.axisVisibility.index1 = !this.axisVisibility.index1;
                this.axisVisibility.index3 = !this.axisVisibility.index1;
              }
              if (seriesIndex === 1) {
                this.axisVisibility.index2 = !this.axisVisibility.index2;
                this.axisVisibility.index4 = !this.axisVisibility.index2;
              }
              this.setYaxisVals();
            }
          },
          height: 250,
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        fill: {
          type: 'solid',
          opacity: [1, 0.3, 1, 1]
        },
        colors: [
          '#418CFF',
          '#F7B500',
          '#418CFF',
          '#F7B500'
          // 'rgba(65,140,255,0.18)',
          // 'rgba(247,181,0,0.18)'
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          dashArray: [0, 0, 5, 5]
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            color: '#fff', // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 0,
          radius: 2,
          hover: {
            size: 3,
            sizeOffset: 3
          }
        },
        xaxis: {
          categories: this.kpiCategories,
          tooltip: { enabled: true }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return this.customTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w
            });
          }
        },
        yaxis: [],
        legend: {
          show: true,
          position: 'top',
          onItemClick: {
            toggleDataSeries: true
          },
          markers: {
            width: 16,
            height: 3,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: function() {
              return `<style>
.apexcharts-legend-series:nth-child(1) .custom-marker,
.apexcharts-legend-series:nth-child(2) .custom-marker{
    display:none;
}
</style>
                          <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:0;top:-2px;background:white"></div>
                        <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white"></div>`;
            },
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
       'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
    ]),
    ...mapState('filter', ['filter']),
    ...mapState('comparison', ['kpiAnalysis', 'kpiAnalysisByDow', 'byDowCounter']),
    ...mapState('detailedReport', ['kpiLineChart']),
    ...mapState('periodicReport', ['selectedStoreCode', 'selectedStore', 'hourlyDate']),
    ...mapState('user', ['kpiPreferences']),
    // ...mapState("weather", ["weather", "weatherCompare"]),
    datesList() {
      // return {
      //   startDate: this.getPulseStartingPeriod,
      //   endDate: this.getPulseEndingCompared,
      //   compareStartDate: this.getPulseStartingCompared,
      //   compareEndDate: this.getPulseEndingPeriod
      // };
      return {
        startDate: this.filter.pulseSelectedStart,
        endDate: this.filter.pulseSelectedEnd,
        compareStartDate: this.filter.pulseComparedStart,
        compareEndDate: this.filter.pulseComparedEnd
      }
    },
  }, // computed
  watch: {
    'kpiAnalysis.selectedKPITrend':{
      handler(newValue, oldValue) {
          this.addWeatherHander();
      },
      immediate: true,
    },
    
    'byDowCounter':{
      handler(newValue, oldValue) {
          this.addWeatherHander();
      }
    },
    weather: {
      handler(newValue, oldValue) {
          this.addWeatherHander();
      },
    },
    weatherCompare: {
      handler(newValue, oldValue) {
        this.addWeatherHander();
      },
    },
    selectedStoreCode() {
      if (this.selectedStoreCode) {
        this.loadData();
      }
    },
    datesList() {
      if (this.selectedStoreCode) {
        this.loadData();
      }
    },
    comparekpi(newValue, oldValue) {
      this.compareKPI = newValue;
    },
    selectedkpi(newValue, oldValue) {
      this.selectedKPI = newValue;
    },
    kpiCategories: async function() {
      this.chartOptions.xaxis.categories = this.kpiCategories;
    }
  },
  created() {
    this.selectedKPI = this.kpiPreferences[0].metricKey;
    this.compareKPI = this.kpiPreferences[2].metricKey;
    if (this.selectedStoreCode) {
      this.loadData();
    }
  },
  methods: {
    ...mapActions('comparison', ['fetchKPIAnalysisHourly']),
    ...mapMutations('detailedReport', ['setSelectedKPI', 'setCompareKPI']),
    // Loads data and update chart
    async hourlyWeather(){
          let startDate = moment(this.hourlyDate.selectedPeriod?.start)
          let endDate = moment(this.hourlyDate.selectedPeriod?.end)
          let startDateComp = moment(this.hourlyDate.comparedPeriod?.start)
          let endDateComp = moment(this.hourlyDate.comparedPeriod?.end)
          if(this.$route.path === '/weekly-report'  || this.$route.path === '/monthly-report'  ){
            startDate = endDate
            startDateComp = endDateComp
          }
          const res = await weather(
            startDate,
            endDate,
             [{cityId:this.selectedStore.cityId}]
          );
          this.weather = res?.weather;
          
          const res2 = await weather(
            startDateComp,
            endDateComp,
             [{cityId:this.selectedStore.cityId}]
          );
          this.weatherCompare = res2?.weather;
    },
   async loadData() {
      clearTimeout(this.apiCall)
      const _this = this;
      this.apiCall = setTimeout(async ()=> {
        _this.loading = true;
        _this.$emit('changeLoading', true);
      // try {
        await _this.fetchKPIAnalysisHourly({
          startDate: _this.getPulseStartingPeriod,
          endDate: _this.getPulseEndingPeriod,
          compareStartDate: _this.getPulseStartingCompared,
          compareEndDate: _this.getPulseEndingCompared,
          storeCodes: _this.selectedStoreCode,
          selectedKPI: 'sls,trf',
          compareKPI: _this.compareKPI,
          type: _this.analysisType,
          daysOfWeek: _this.datevalue?.daysOfWeek,
          setbyDow: JSON.stringify(_this.datesList)
        });
        _this.hourlyWeather()
        _this.addWeatherHander()
      // } catch (err) {
      //   _this.$q.notify({
      //     type: 'negative',
      //     position: 'top',
      //     message: `Comparison Error: ${err}`
      //   });
      // }
      _this.loading = false;
      _this.$emit('changeLoading', false);
      }, 1000)
    },
    async addWeatherHander(callCount = 1){
      this.weatherData = []
      this.selectedIcon = []
      this.temperatures = []
      let dowInd = this.datevalue?.daysOfWeek+JSON.stringify(this.datesList);
      if(!this.kpiAnalysisByDow[dowInd]) {
        if(callCount < 3) {
          setTimeout(this.addWeatherHander, 500, ++callCount)
        } else {
          
          if (this.selectedStoreCode) {
            this.loadData();
          } else {
            setTimeout(this.addWeatherHander, 500, ++callCount)
          }
        }
        return;
      }
      this.kpiCategories = this.kpiAnalysisByDow[dowInd]?.selectedKPITrend?.selectedPeriod?.map(
          kpi => {
            var findObj = this.weather && this.weather[0];
            kpi.timelineLabel = kpi.timelineLabel.slice(0,2) // for key 10:00 as 10
            this.weatherData = [...this.weatherData, findObj && findObj[kpi.timelineLabel]?.icon];
            this.selectedIcon = [...this.selectedIcon, findObj && findObj[kpi.timelineLabel]?.icon];
            this.temperatures = [...this.temperatures, findObj && findObj[kpi.timelineLabel]?.temp?.toFixed(2)];
            return kpi.timelineLabel;
          }
        );
      // 
      this.weatherData2 = []
      this.selectedIcon2 = []
      this.temperatures2 = []
        this.kpiCategories2 = this.kpiAnalysisByDow[dowInd]?.comparedKPITrend?.comparedPeriod?.map(
          kpi => {
            var findObj = this.weatherCompare && this.weatherCompare[0];
            kpi.timelineLabel = kpi.timelineLabel.slice(0,2)
            this.weatherData2 = [...this.weatherData2, findObj && findObj[kpi.timelineLabel]?.icon];
            this.selectedIcon2 = [...this.selectedIcon2, findObj && findObj[kpi.timelineLabel]?.icon];
            this.temperatures2 = [...this.temperatures2, findObj && findObj[kpi.timelineLabel]?.temp?.toFixed(2)];
            return kpi.timelineLabel;
          }
        );
        await this.setSeriesData();
        await this.setChartOptions();
        this.loading = false;
    },
    getLabel(value) {
      let name = this.kpiPreferences.find(val => val.metricKey === value);
      return name ? name.title : '';
    },
    setSeriesData() {

      let dowInd = this.datevalue?.daysOfWeek+JSON.stringify(this.datesList);
      const selectedKPITrend = this.kpiAnalysisByDow[dowInd]?.selectedKPITrend
      const comparedKPITrend = this.kpiAnalysisByDow[dowInd]?.comparedKPITrend
      // sort same by same dates
  
      let selectedTime = []
      let selectedCTime = []
      selectedKPITrend?.selectedPeriod.forEach(element => {
        const find = selectedKPITrend?.selectedPeriod?.find(item => element.timelineLabel == item.timelineLabel.slice(0,2))
        selectedTime.push(find)
      });
      comparedKPITrend?.comparedPeriod.forEach(element => {
        const find = comparedKPITrend?.comparedPeriod?.find(item => element.timelineLabel == item.timelineLabel.slice(0,2))
        selectedCTime.push(find)
      });
   
      if(selectedKPITrend == undefined)
      {
        this.series = [];
        return;
      }
      
       if(selectedKPITrend.selectedPeriod || comparedKPITrend.comparedPeriod){
         selectedKPITrend.selectedPeriod = selectedTime;
         comparedKPITrend.comparedPeriod = selectedCTime;
       }
      // sort same by same dates edn
      // console.log(selectedKPITrend, 'selectedKPITrend')
      // console.log(comparedKPITrend, 'comparedKPITrend')
      let selectedKPIselectPeriod = {
        name:
          this.$t(`KPI.${this.selectedKPI?this.selectedKPI:'sls'}`) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        type: 'line',
        data: selectedKPITrend?.selectedPeriod.map(kpi => addSign(this.selectedKPI,kpi.metricValue)),
        formattedData: selectedKPITrend?.selectedPeriod.map(
          kpi => addSign(this.selectedKPI,kpi.formattedMetricValue.replace('¥', ''))
        )
      };
      let compareKPIselectPeriod = {
        name:
          this.$t(`KPI.${this.compareKPI?this.compareKPI:'trf'}`) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        data: comparedKPITrend?.selectedPeriod?.map(kpi => addSign(this.compareKPI,kpi.metricValue)),
        type: 'area',
        formattedData: comparedKPITrend?.selectedPeriod?.map(
          kpi => addSign(this.compareKPI,Number(kpi.formattedMetricValue).toLocaleString())
        )
      };
      let selectedKPIcomparePeriod = {
        name:
          this.$t(`KPI.sls`) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        type: 'line',
        data: selectedKPITrend?.comparedPeriod?.map(kpi => addSign('sls',kpi.metricValue)),
        formattedData: selectedKPITrend?.comparedPeriod?.map(
          kpi => addSign('sls',kpi.formattedMetricValue.replace('¥', ''))
        )
      };
      let compareKPIcomparePeriod = {
        name:
          this.$t(`KPI.trf`) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        type: 'line',
        data: comparedKPITrend?.comparedPeriod?.map(kpi =>  addSign('trf',kpi.metricValue)),
        formattedData: comparedKPITrend?.comparedPeriod.map(
          kpi => addSign('trf',Number(kpi.formattedMetricValue).toLocaleString())
        )
      };

      this.series = [
        selectedKPIselectPeriod,
        compareKPIselectPeriod,
        selectedKPIcomparePeriod,
        compareKPIcomparePeriod
      ];
    },
    setCategories() {
      let dowInd = this.datevalue?.daysOfWeek+JSON.stringify(this.datesList);

      this.kpiCategories = [
        ...this.kpiAnalysisByDow[dowInd]?.selectedKPITrend?.selectedPeriod.map(
          kpi => kpi.timelineLabel
        )
      ];
    },
    getKpiLabel(val) {
      if (this.analysisType === 'daily') {
        return val + `${this.analysisType === 'daily' ? val : ''}`;
      } else if (this.analysisType === 'weekly') {
        return val || val === 'null' || val === 'undefined'
          ? val.replace('wk-', this.$t('week') + ' ')
          : '-';
      } else {
        return val ? val : '-';
      }
    },
    customTooltip({ series, seriesIndex, dataPointIndex, w }) {
      return `
        <q-card class="tooltip-container kpi-analysis-tooltip flex column q-pa-md items-center">
          <div class="flex justify-between items-center">
            <div class="">
              ${
                this.kpiCategories[dataPointIndex]
                  ? this.kpiCategories[dataPointIndex]
                  : '-'
              }
            </div>
            ${`<div class="flex">
                    <div class="flex items-center weather-tooltip">
                      ${weatherObj[this.weatherData[dataPointIndex]] ? weatherObj[this.weatherData[dataPointIndex]]: ''}
                    </div>
                    <div class="q-ml-xs">
                      ${this.temperatures[dataPointIndex] ? this.temperatures[dataPointIndex]+'°C': ''}
                    </div>
                  </div>`}
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color"
              style="background:${w.config.colors[0]}"
            ></div>
            <div>
              ${w.globals.seriesNames[0] ? w.globals.seriesNames[0] : '-'} :
            </div>
            <div class="q-pl-md">
              ${
                this.series[0]['formattedData'][dataPointIndex]
                  ? this.series[0]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color"
              style="background:${w.config.colors[1]}"
            ></div>
            <div>${w.globals.seriesNames[1]}:</div>
            <div class="q-pl-md">
              ${
                this.series[1]['formattedData'][dataPointIndex]
                  ? this.series[1]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="q-mt-md flex justify-between items-center">
            <div class="">
              ${
                this.kpiCategories2[dataPointIndex]
                  ? this.kpiCategories2[dataPointIndex]
                  : '-'
              }
            </div>
            ${`<div class="flex">
                    <div class="flex items-center weather-tooltip">
                      ${weatherObj[this.weatherData2[dataPointIndex]] ? weatherObj[this.weatherData2[dataPointIndex]]: ''}
                    </div>
                    <div class="q-ml-xs">
                      ${this.temperatures2[dataPointIndex] ? this.temperatures2[dataPointIndex]+'°C': ''}
                    </div>
                  </div>`}
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color dashed"
              style="background:${w.config.colors[2]}"
            ></div>
            <div>${w.globals.seriesNames[2]}:</div>
            <div class="q-pl-md">
              ${
                this.series[2]['formattedData'][dataPointIndex]
                  ? this.series[2]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color dashed"
              style="background:${w.config.colors[3]}"
            ></div>
            <div>${w.globals.seriesNames[3]}:</div>
            <div class="q-pl-md">
              ${
                this.series[3]['formattedData'][dataPointIndex]
                  ? this.series[3]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
        </q-card>`;
    },
    getFullDay(val) {
      if (val === 0) {
        return this.$t('Date.Sun');
      }
      if (val === 1) {
        return this.$t('Date.Mon');
      }
      if (val === 2) {
        return this.$t('Date.Tue');
      }
      if (val === 3) {
        return this.$t('Date.Wed');
      }
      if (val === 4) {
        return this.$t('Date.Thu');
      }
      if (val === 5) {
        return this.$t('Date.Fri');
      }
      if (val === 6) {
        return this.$t('Date.Sat');
      }
    },
    setChartOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: this.kpiCategories }
      };
      this.setYaxisVals();
    },
    setYaxisVals() {
      this.chartOptions = {
        ...this.chartOptions,
        yaxis: [
          {
            seriesName:
              this.getLabel(this.selectedKPI) +
              ' ' +
              this.$t('KpiAnalysis.selected_period'),
            decimalsInFloat: 0,
            show: this.axisVisibility.index1,
            labels: {
              formatter: value => {
                return formatYAxis(value);
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.compareKPI) +
              ' ' +
              this.$t('KpiAnalysis.compared_period'),
            decimalsInFloat: 0,
            opposite: true,
            show: this.axisVisibility.index2,
            labels: {
              formatter: value => {
                return formatYAxis(value);
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.selectedKPI) +
              ' ' +
              this.$t('KpiAnalysis.selected_period'),
            decimalsInFloat: 0,
            show: this.axisVisibility.index3,
            labels: {
              formatter: value => {
                return formatYAxis(value);
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.compareKPI) +
              ' ' +
              this.$t('KpiAnalysis.compared_period'),
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true
            },
            show: this.axisVisibility.index4,
            labels: {
              formatter: value => {
                return formatYAxis(value);
              }
            }
          }
        ]
      };
    }
  }
};
