
import tenants from "@/services/tenants";
import { stubFalse } from 'lodash';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedContact"],
  // props: {
  //   selectedContact: {
  //     type: Object,
  //     default: null
  //   }
  // },
  data() {
    return {
      loadingBtn: false,
      configuration: true,
      name: "",
      // description: "",
      department: "",
      title: "",
      dialogtitle: "",
      phoneNumber: "",
      email: "",
    };
  },
  watch: {
    configuration(newValue){
      if(!newValue){
        this.$emit("closeModel", false);
      }
    }
  },
  mounted() {
    if (this.selectedContact) {
      this.dialogtitle =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.business_contact;
      this.name = this.selectedContact.name;
      // this.description = this.selectedContact.description
      this.department = this.selectedContact.department;
      this.title = this.selectedContact.title;
      this.phoneNumber = this.selectedContact.phoneNumber;
      this.email = this.selectedContact.email;
    } else {
      this.dialogtitle =
        this.$store.state.user.translate.add +
        " " +
        this.$store.state.user.translate.business_contact;
    }
  },
  methods: {
    createContact() {
      if (this.selectedContact) {
        let contactData = {
          name: this.name,
          department: this.department,
          title: this.title,
          email: this.email,
          phoneNumber: this.phoneNumber,
          contactType: "business",
          contactId: this.selectedContact.id,
        };
        this.loadingBtn = true;
        tenants
          .updateTenantContact(contactData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.loadingBtn = false;
            this.$emit("updateTenantContacts");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.loadingBtn = false;
            this.$emit("closeModel", false);
          });
      } else {
        let contactData = {
          name: this.name,
          department: this.department,
          title: this.title,
          email: this.email,
          phoneNumber: this.phoneNumber,
          contactType: "business",
        };
         this.loadingBtn = true;
        tenants
          .createTenantContacts(contactData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.business_created_successfully,
              color: "green",
            });
             this.loadingBtn = false;
            this.$emit("updateTenantContacts");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
             this.loadingBtn = false;
            this.$emit("closeModel", false);
          });
      }
    },
  },
};
