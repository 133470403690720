import Log, { LogDTO } from '../Log';

export const convertLogDTO = (dto: LogDTO): Log => ({
  username: dto.username,
  menu: dto.menu,
  timestamp: dto.timestamp,
  action: dto.action
});

export function composeLogDescription(log: Log) {
  const toProperCase = (str: string) =>
    str.toLowerCase().replace(/^(.)/g, $1 => $1.toUpperCase());
  const action = toProperCase(log.action);
  const menu = toProperCase(log.menu);
  return `${action} an entry in ${menu} menu`;
}
