import { render, staticRenderFns } from "./ContainerHeaderOld.vue?vue&type=template&id=1f78e761&scoped=true&"
import script from "./ContainerHeaderOld.vue?vue&type=script&lang=js&"
export * from "./ContainerHeaderOld.vue?vue&type=script&lang=js&"
import style0 from "./ContainerHeaderOld.vue?vue&type=style&index=0&id=1f78e761&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f78e761",
  null
  
)

export default component.exports
import {QHeader,QToolbar,QBtnDropdown,QMenu,QList,QItem,QItemSection,QBtn,QSpace} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QHeader,QToolbar,QBtnDropdown,QMenu,QList,QItem,QItemSection,QBtn,QSpace})
