
export default {
  data() {
    return {
      overviewSelection: 'region',
      series: [
        {
          name: 'Bubble1',
          data: [30, 60, 45, 20, 12, 30, 65, 30, 23, 46]
        },
        {
          name: 'Bubble2',
          data: [16, 23, 34, 50, 20, 45, 10, 32, 50]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bubble'
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.8
        },
        title: {
          text: 'Simple Bubble Chart'
        },
        xaxis: {
          tickAmount: 12,
          type: 'category'
        },
        yaxis: {
          max: 70
        }
      }
    };
  }
};
