
import users from "@/services/users";
export default {
  name: "StoreModel",
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedUser","selectedGroup", "allPeerGroups", "userLocation"],
  // props: {
  //   userLocation: {
  //     type: Object,
  //     default: null,
  //   },
  //   allPeerGroups: {
  //     type: Array,
  //     default: () => [],
  //   },
  //   selectedUser: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
      openPeerGroupsModel: true,
      data: [],
      selected: [],
      peerGroupsAccessibleColumns: [
        {
          name: "peerGroupType",
          align: "center",
          label: this.$store.state.user.translate.peer_group_am_type,
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "peerGroupName",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: "peerGroupName",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.data = this.allPeerGroups;
    this.selected = this.selectedGroup;
  },

  methods: {
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } selected of ${this.data.length}`;
    },
    updatePeerGroupsStore() {
      if (this.selectedUser) {
        let userStores = [];
        let userPeerGroups = [];
        this.userLocation.myStores.forEach((element) => {
          userStores.push(element.storeNumber);
        });
        this.selected.forEach((element) => {
          userPeerGroups.push(element._id);
        });
        let UsrDetails = {
          userId: this.selectedUser._id,
          locations: [
            {
              type: "mystores",
              storeCodes: userStores,
            },
            {
              type: "peergroups",
              peerGroupIds: userPeerGroups,
            },
          ],
        };
        users
          .setUserLocations(UsrDetails)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.$emit("updateStore");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
          });
      } else {
        this.$emit("updatePeerGroupValue", this.selected);
      }
    },
  },
};
