import Menu from '@/models/Menu';

class Authority {
  _id: string;
  title: string;
  description: string;
  active: boolean;
  module?: Object;
  translations?: Object;
  platforms?: string[];

  constructor(initializer?: Partial<Authority>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: AuthorityDTO): Authority {
    const authority = new Authority({
      _id: dto._id,
      title: dto.title,
      description: dto.description,
      module: dto.module,
      translations: dto.translations,
      platforms: dto.platforms
    });
    return authority;
  }

  public toDTO(): AuthorityDTO {
    return {
      _id: this._id,
      title: this.title,
      description: this.description,
      module: this.module,
      translations: this.translations,
      platforms: this.platforms
    };
  }
}

export interface AuthorityDTO {
  _id: string;
  title: string;
  description?: string;
  active?: string;
  module?: Object;
  translations?: Object;
  platforms?: string[];
}

export default Authority;
