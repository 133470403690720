
import CommonMixin from '@/mixins/CommonMixin';
import Plan, { BillingModel } from '@/models/Plan';
import { handleErrorUI } from '@/util/error';
import OrderSummaryModal from '@/components/Management/Plan/OrderSummaryModal.vue';
import CancelPlanModal from '@/components/Management/Plan/CancelPlanModal.vue';

// import PlanOrderQuote from '@/models/PlanOrderQuote';

enum PlanManagementModalTab {
  PRIMARY_PLAN,
  ADD_ONS
}

export default {
  components: { OrderSummaryModal, CancelPlanModal },
  mixins: [CommonMixin],
  props: {
    value: { type: Boolean, default: false },
    store: { required: true, default: () => {}, type: Object }
  },
  data() {
    return {
      currentTab: PlanManagementModalTab.PRIMARY_PLAN,
      selectedBillingModel: BillingModel.MONTHLY,
      storeSubscription: {
        planId: null,
        subscriptionType: null,
        addOnIds: []
      },
      loading: {
        plan: true,
        addOn: true
      },
      planCache: {
        [BillingModel.MONTHLY]: [],
        [BillingModel.ANNUALLY]: []
      },
      addOnsCache: {
        [BillingModel.MONTHLY]: [],
        [BillingModel.ANNUALLY]: []
      },
      purchaseQuoteDialogShown: false,
      quote: null,
      selectedItem: null,
      cancelPlanDialogShown: false,
      PlanManagementModalTab: PlanManagementModalTab,
      BillingModel: BillingModel
    };
  },
  computed: {
    canChooseAddons() {
      return this.storeSubscription.planId != null;
    },
    subscriptionBillingPeriod() {
      const subscriptionType = this.storeSubscription.subscriptionType;
      switch (subscriptionType) {
        case 'MONTHLY':
          return BillingModel.MONTHLY;
        case 'YEARLY':
          return BillingModel.ANNUALLY;
      }
      return BillingModel.MONTHLY;
    },
    currentlyShownPlans() {
      const list = this.planCache[this.selectedBillingModel];
      if (list.length === 0) {
        this.fetchPlans(this.selectedBillingModel);
      }
      return list;
    },
    currentlyShownAddOns() {
      const list = this.addOnsCache[this.subscriptionBillingPeriod];
      if (list.length === 0) {
        this.fetchAddOns(this.subscriptionBillingPeriod);
      }
      return list;
    }
  },
  watch: {
    async value() {
      this.currentTab = PlanManagementModalTab.PRIMARY_PLAN;
      await this.loadPlanForStore();
      this.selectedBillingModel = this.subscriptionBillingPeriod;
    }
  },
  methods: {
    async fetchPlans(billingModel) {
      this.loading.plan = true;
      try {
        const { plans } = await this.$repo.plan.getPlans(billingModel);
        this.planCache[billingModel] = plans;
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading.plan = false;
      }
    },
    async fetchAddOns(billingModel) {
      this.loading.addOn = true;
      try {
        const { addOns } = await this.$repo.plan.getAddOns(billingModel);
        this.addOnsCache[billingModel] = addOns;
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading.addOn = false;
      }
    },
    handleChangeBillingModel(newBillingModel: BillingModel) {
      this.selectedBillingModel = newBillingModel;
    },
    async purchasePlan(plan: Plan) {
      this.selectedItem = plan;
      const { tenant } = await this.$repo.session.fetch();
      this.quote = await this.$repo.planOrder.getPlanOrderQuote(
        tenant,
        this.store,
        plan
      );
      this.purchaseQuoteDialogShown = true;
    },
    async purchaseAddOn(addOn: Plan) {
      await this.purchasePlan(addOn);
    },
    async cancelPlan() {
      const { tenant } = await this.$repo.session.fetch();
      await this.$repo.planOrder.cancelPlan(tenant, this.store);
      this.cancelPlanDialogShown = true;
    },
    async loadPlanForStore() {
      try {
        this.storeSubscription = await this.$repo.planOrder.getPlanForStore(
          this.store
        );
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading.plan = false;
      }
    },
    onOrderSuccess() {
      this.purchaseQuoteDialogShown = false;
      this.$emit('refetch');
    }
  }
};
