import { ClientManager } from '@/util/client';
import AreaManagerController from './AreaManagerController';
import PeerGroupController from './PeerGroupController';

export interface SettingsControllers {
  peerGroup: PeerGroupController;
  areaManager: AreaManagerController;
}

export default (clientManager: ClientManager): SettingsControllers => ({
  peerGroup: new PeerGroupController(clientManager.coreClient),
  areaManager: new AreaManagerController(clientManager.coreClient)
});
