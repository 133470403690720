import axios from "axios"
import store from '@/store';
import { SSOPostReq, SSOPutRequest2, SSOPutRequest, SSODeleteRequest2, SSOGetRequest2 } from '@/util/kpis';
export default {
  async getKpiDefinations() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,

    }
    const apiResponse= await SSOGetRequest2(
      '/api/getKpiDefinations',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async createKpiDefination(details)  {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "name": details.name,
      "threthhold": details.threthhold,
      "variationUnit": details.variationUnit,
      "variationForlumar": details.variationForlumar,
      "unit": {
        "ja": details.japaneseUnit,
        "en": details.englishUnit
      },
      "title": {
        "ja": details.japaneseName,
        "en": details.englishName
      },
      "explanation": {
        "ja": details.shortJapaneseName,
        "en": details.shortEnglishName,
      },
      "metric": details.metric,
      "kpiType": details.category,
    };
    const apiResponse= await SSOPostReq(
      '/api/createKpiDefination', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },

  async updateKpiDefination(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "_id": details.kpiId,
      // "name": details.name,
      // "variationUnit": details.variationUnit,
      "unit": {
        "ja": details.japaneseUnit,
        "en": details.englishUnit
      },
      "title": {
        "ja": details.japaneseName,
        "en": details.englishName
      },
      "explanation": {
        "ja": details.shortJapaneseName,
        "en": details.shortEnglishName,
      },
    };
    const apiResponse= await SSOPutRequest2(
      '/api/updateKpiDefinationV2', paramsData,
      {
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async deleteKpiDefination(kpiId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
     client_id :  storeState.user?.clientId,
      client_secret : storeState.user?.clientSecret,
      "_id": kpiId
    };
    const apiResponse= await SSODeleteRequest2(
      '/api/deleteKpiDefination',
      {
        data:paramsData,
        headers: {
          Authorization:'Bearer'+ token
        }
      }
    );
    return apiResponse
  },
  async APIGetSavedPreferences(pageUrl){
    try {
      if(pageUrl == '/export' && localStorage.getItem('storeCodesExportF')!=null && localStorage.getItem('storeCodesExportF') !=''  && localStorage.getItem('storeCodesExportF') != "undefined"){
        var res2 = localStorage.getItem('storeCodesExportF').split(",");
        return res2
      }
      const storeState = store.state;

      const userID= storeState.user.profile._id;
      const tenentID= storeState.user.profile.tenant._id;

      const baseUrl = 'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/user/'+''+tenentID+'/'+userID
      const timestamp = new Date().getTime(); // Current timestamp
      const urlWithTimestamp = `${baseUrl}?timestamp=${timestamp}`;

      const apiResponse = await getApiPrefrencses(urlWithTimestamp);
       var result = [];

       for (const key of Object.keys(apiResponse)) {
         const context = key;
         const values = apiResponse[key];

         const results = {
           context: context,
           values: values
         };

         result.push(results);
       }


        var resExport = result?.map(x => {
          if(x.context== '/export')
          {
            return x.values;
          }
         }).filter(function( element ) {
          return element !== undefined;
         });
         localStorage.setItem('storeCodesExportF',resExport[0]?.toString());
         return apiResponse
    } catch (error) {
      // console.log(error)
    }
  },
  // all kpis
      APIGetAllKpis (){
        try {

          const data = {
            "status": 200,
            "message": "Success",
            "data": {
              "response": {
                "sales": {
                  "order": 1,
                  "visibility": 1,
                  "title": {
                    "en": "Sales",
                    "ja": "売上"
                  },
                  "explanation": {
                    "en": "Sales = Traffic x Conversion x Average Purchase   Overall sales performance is heavily dependant on these three elements, therefore it is important that all business decisions actively seek to improve the KPI that lead to sales.",
                    "ja": "売上高＝来店客数×購買率×客単価\n売上の要素である3つのKPIを理解し、それぞれの数値を上げることで、売上目標の確実な達成を目指しましょう！"
                  },
                  "unit": {
                    "en": "¥",
                    "ja": "円"
                  },
                  "videoUrl": {
                    "en": "https://www.youtube.com/embed/uIz0XhWmyJ4?rel=0",
                    "ja": "https://www.youtube.com/embed/Y1A4-JiXjwY"
                  },
                  "metricKey": "sales"
                },
                "traffic": {
                  "order": 2,
                  "visibility": 1,
                  "title": {
                    "en": "Traffic",
                    "ja": "来店客数"
                  },
                  "explanation": {
                    "en": "This is the number of customers that visited the store. \nDrivers include marketing, merchanding and location.  \nThe 'power hour' indicates the time we observe a peak in visitors.  \nAs with online stores Traffic is the essential measurement for improvement of all aspects of store operations. From inventory to staff planning and marketing effectiveness. ",
                    "ja": "来店客数は入店したお客様の人数を表します。この数値は一般的に、天気やマーケティング、経済効果など様々な影響を受けやすい特徴があります。\n過去の来店客数の傾向からパワーアワーを予測し、営業に必要な在庫数やスタッフ人数を決定しましょう。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "videoUrl": {
                    "en": "https://www.youtube.com/embed/woUlk5Cv1Yw?rel=0",
                    "ja": "https://www.youtube.com/embed/R9-SnvtLsCM"
                  },
                  "metricKey": "traffic"
                },
                "transactions": {
                  "order": 3,
                  "visibility": 1,
                  "title": {
                    "en": "Transactions",
                    "ja": "購入件数"
                  },
                  "explanation": {
                    "en": "This is the total number of customer transactions. \nThis KPI allows us to understand purchasing patterns within any location ro group of locations. By thoroughly understanding this important KPI, we can assess the success of any sales focused strategies.  ",
                    "ja": "お客様との取引数を表します。\n購入件数を増やす際に大切なのが、来店客のうちの何人を購入客へと変えられたのか（＝購買率の数値！）を理解することです。\nお客様の来店をより多くの購買に繋げましょう。"
                  },
                  "unit": {
                    "ja": "件"
                  },
                  "videoUrl": {
                    "en": "https://www.youtube.com/embed/Ki9A2NEzY08?rel=0",
                    "ja": "https://www.youtube.com/embed/Ki9A2NEzY08"
                  },
                  "metricKey": "transactions"
                },
                "conversion": {
                  "order": 4,
                  "visibility": 1,
                  "title": {
                    "en": "Conversion",
                    "ja": "購買率"
                  },
                  "explanation": {
                    "en": "Conversion rate (%)= (Transactions/Traffic)*100.  \nThe King of Retail KPI.\nThis essential KPI is the key area of focus for all store level staff and head office users. Conversion indicates the number of visitors that can potentially be converted to purchasers and how well our in-store efforts are converting shoppers to buyers. ",
                    "ja": "購買率(%)＝購入件数÷来店客数x100\n購買率は、買上率・コンバージョン・転換率とも呼ばれています。\n来店客をどれだけ購入客へと転換できたかを表す、店内分析の重要指標です。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "videoUrl": {
                    "en": "https://www.youtube.com/embed/Mn9Lwa37zRw?rel=0",
                    "ja": "https://www.youtube.com/embed/2htU59DSP_c"
                  },
                  "metricKey": "conversion"
                },
                "avgpurchase": {
                  "order": 5,
                  "visibility": 1,
                  "title": {
                    "en": "Average Purchase",
                    "ja": "平均客単価"
                  },
                  "explanation": {
                    "en": "Average Purchase = Sales/Transactions.  \nIllustrates what each of the store's customers spent on average.  \nBy setting sales targets for popular and high priced items this value can be increased.",
                    "ja": "平均客単価＝売上高÷購入件数\nこの数値はお客様一人当たりの購入額を表します。\n高単価商品のおすすめやセット販売で、客単価UPを狙えます。"
                  },
                  "unit": {
                    "en": "¥",
                    "ja": "円"
                  },
                  "videoUrl": {
                    "en": "https://www.youtube.com/embed/95aGMxZrzxU?rel=0",
                    "ja": "https://www.youtube.com/embed/xzNHQhn_Fho"
                  },
                  "metricKey": "avgpurchase"
                },
                "upt": {
                  "order": 6,
                  "visibility": 1,
                  "title": {
                    "en": "Unit Per Transaction",
                    "ja": "平均買上点数"
                  },
                  "explanation": {
                    "en": "Units Per Transaction (UPT) = Total number of items purchased/Transactions. \nThis value represents the average number of items your customers purchase per transaction. \nThis is key for understanding whether related items are being sold together, and if more focus on 'upselling' is needed.  \nFocus here for increasing your Average Purchase value.",
                    "ja": "平均買上点数(UPT)＝総買上商品点数÷購入件数\nこの指標はお客様一人当たりの購入点数を表し、販売の現場ではセット率とも呼ばれています。\nセット販売を出来ているかを知るのに最適な指標で、客単価アップのための重要指標です。"
                  },
                  "unit": {
                    "ja": "点"
                  },
                  "metricKey": "upt"
                },
                "rov": {
                  "order": 7,
                  "visibility": 1,
                  "title": {
                    "en": "Return on Visit",
                    "ja": "売上高/客数"
                  },
                  "explanation": {
                    "en": "How much do marketing activities affect the buying intent of shoppers?  \nSales/Traffic is a useful KPI for measuring the effects of marketing, promotions and events.  ",
                    "ja": "売上高/客数は来店客一人当たりの売上高を表します。\nマーケティング効果測定やイベント分析に有用な指標です。"
                  },
                  "unit": {
                    "en": "¥",
                    "ja": "円"
                  },
                  "metricKey": "rov"
                },
                "avgitemprice": {
                  "order": 8,
                  "visibility": 1,
                  "title": {
                    "en": "Average Item Price",
                    "ja": "平均商品単価"
                  },
                  "explanation": {
                    "en": "Average Item Price= Sales/total number of items purchased.  \nThis value represents the average price at which your customers are buying items. \nWith this indicator, we can determine if higher priced items are being effectively sold.",
                    "ja": "平均商品単価＝売上高÷総買上商品点数\nお客様が購入した商品1点当たりの価格を表します。\nこの指標で、高単価商品を販売することができているかをチェックしましょう。"
                  },
                  "unit": {
                    "en": "¥",
                    "ja": "円"
                  },
                  "metricKey": "avgitemprice"
                },
                "salespersqm": {
                  "order": 9,
                  "visibility": 1,
                  "title": {
                    "en": "Sales Per Tsubo",
                    "ja": "売上高/坪"
                  },
                  "explanation": {
                    "en": "Sales/㎡= Sales/ sales floor area (㎡). \nThis represents the sales per square meter and is a useful for indexing and benchmarking the efficiency of how stores optimize the use their floor area.",
                    "ja": "売上高/㎡＝売上高÷売り場面積(㎡)\n１㎡当たりの売上高を表し、店舗の収益性の比較をすることに役立つ指標です。\nロケーション毎の収益性のベンチマークとしても利用することができます。"
                  },
                  "unit": {
                    "en": "¥",
                    "ja": "円"
                  },
                  "metricKey": "salespersqm"
                },
                "trafficpersqm": {
                  "order": 10,
                  "visibility": 1,
                  "title": {
                    "en": "Traffic Per Tsubo",
                    "ja": "来店客数/坪"
                  },
                  "explanation": {
                    "en": "Traffic/㎡ = Traffic/sales floor area (㎡).  \nThis represents the drawing power of stores and is an effective index of the congestion of a certain store.",
                    "ja": "入店客数/坪＝入店客数÷売り場面積(坪)\n１坪当たりの入店客数を表します。立地の良し悪しを測ったり、店舗の混み具合を知るのに有効な指標です。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "trafficpersqm"
                },
                "stafflevel": {
                  "order": 11,
                  "visibility": 1,
                  "title": {
                    "en": "Staff Level",
                    "ja": "スタッフレベル"
                  },
                  "explanation": {
                    "en": "Traffic/ Staff per hour. \nThis represents the number of visitors per staff member available per hour. \nThis indicator helps in determining the optimal staff level to convert your store traffic into transactions. ",
                    "ja": "来店客数÷1時間当たりスタッフ\n1時間のスタッフ1人あたりの来店客数を表します。\nこの指標はスタッフ1人が接客すべき客数の目安になります。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "stafflevel"
                },
                "staffhours": {
                  "order": 12,
                  "visibility": 1,
                  "title": {
                    "en": "Staff Hours",
                    "ja": "スタッフ/時間"
                  },
                  "explanation": {
                    "en": "This value represents the average number of staff per hour.",
                    "ja": "1時間あたりのスタッフ人数の平均を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "staffhours"
                },
                "closingstrength": {
                  "order": 13,
                  "visibility": 1,
                  "title": {
                    "en": "Closing Strength",
                    "ja": "購入転換力"
                  },
                  "explanation": {
                    "en": "Transactions/ Staff per hour. \nThis value represents the number of purchasing customers per staff per hour. \nThis helps in determining if staff member-customer engagement contributes to increased transactions.",
                    "ja": "購入客数÷1時間あたりスタッフ\n1時間のスタッフ一人あたりの購入客数を表します。\nスタッフの接客が購入につながっているかをチェックできる指標です。"
                  },
                  "unit": {
                    "ja": "件"
                  },
                  "metricKey": "closingstrength"
                },
                "staffefficiency": {
                  "order": 15,
                  "visibility": 1,
                  "title": {
                    "en": "Staff Efficiency",
                    "ja": "スタッフ効率"
                  },
                  "explanation": {
                    "en": "Sales/staff per hour.  \nThis KPI identifies the amount of sales that a staff member is able to create\nThis helps with understanding and improving staff performance.",
                    "ja": "売上÷1時間あたりスタッフ\n1時間のスタッフ一人あたりの売上を表します。\nスタッフ一人あたりの成果を知ることができます。"
                  },
                  "unit": {
                    "ja": "円"
                  },
                  "metricKey": "staffefficiency"
                },
                "passersby": {
                  "order": 15,
                  "visibility": 1,
                  "title": {
                    "en": "Passersby",
                    "ja": "通行人数"
                  },
                  "explanation": {
                    "en": "This is the number of individuals passing by the store. \nThis number indicates the potential traffic to the store.",
                    "ja": "店舗前の通行人数を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "passersby"
                },
                "capturerate": {
                  "order": 16,
                  "visibility": 1,
                  "title": {
                    "en": "Capture Rate",
                    "ja": "来店率"
                  },
                  "explanation": {
                    "en": "Capture Rate= Traffic/Passersby.  \nRepresents the number of shoppers passing infront of the store who choose to enter. ",
                    "ja": "来店率＝来店客数÷通行人数\n店舗前の通行人のうち何割が来店したかを表します。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "capturerate"
                },
                "unitssold": {
                  "order": 14,
                  "visibility": 1,
                  "title": {
                    "en": "Total Item Sold",
                    "ja": "販売点数"
                  },
                  "explanation": {
                    "en": "This is the total number of items sold. \nThis KPI allows us to understand purchasing patterns within any location ro group of locations. By thoroughly understanding this important KPI, we can assess the success of any sales focused strategies.  ",
                    "ja": "商品販売点数を表します。\n販売点数を増やすことで客単価の改善、売上改善につながります。\n声かけ、カゴ渡しは、販売点数の改善に効果的です。"
                  },
                  "unit": {
                    "en": "-",
                    "ja": "点"
                  },
                  "metricKey": "unitssold"
                },
                "instoreconversion%": {
                  "title": {
                    "en": "In-store Conversion%",
                    "ja": "店内回遊率"
                  },
                  "explanation": {
                    "en": "In-store Conversion% = Specific In-store Area Traffic / Store Traffic\n",
                    "ja": "店内回遊率＝エリア来店客数÷来店客数\n来店客の店舗内での回遊率を把握するのに有効な指標です。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "instoreconversion%"
                },
                "specificareatraffic": {
                  "title": {
                    "en": "Repeat Rate",
                    "ja": "ゾーン来訪人数"
                  },
                  "explanation": {
                    "en": "Traffic of a Specific In-store Area  = Number of visitor's who entered  the specific area ",
                    "ja": "店内の特定エリアの来訪人数を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "specificareatraffic"
                },
                "facadestops": {
                  "title": {
                    "en": "Facade Stops",
                    "ja": "立ち寄り客"
                  },
                  "explanation": {
                    "en": "The number of customers who stopped by the facade (within a 1.5 m range) for more than 5 seconds. ",
                    "ja": "ファサードに立ち寄った客数を表します。\nファサードから1.5m以内の範囲に、5秒以上滞在したお客様の人数です。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "facadestops"
                },
                "storedwelltime": {
                  "title": {
                    "en": "Store Dwell Time",
                    "ja": "平均滞在時間"
                  },
                  "explanation": {
                    "en": "The average amount of time a customer stays in the store.",
                    "ja": "お客様の店舗での滞在時間の平均を表します。"
                  },
                  "unit": {
                    "en": "XXh:XXm:XXs",
                    "ja": "x 時間 y分 z秒"
                  },
                  "metricKey": "storedwelltime"
                },
                "repeatrate": {
                  "title": {
                    "en": "Repeat Rate",
                    "ja": "再来店率"
                  },
                  "explanation": {
                    "en": "The percentage of traffic who are derived from repeat customers. ",
                    "ja": "来店客のうち、過去に来店したことがある人の割合を表します。"
                  },
                  "unit": {
                    "ja": "%"
                  },
                  "metricKey": "repeatrate"
                },
                "engagementrate": {
                  "title": {
                    "en": "Engagement Rate",
                    "ja": "エンゲージメント率"
                  },
                  "explanation": {
                    "en": "The proportion of customers that stayed in the store longer than the predefined threshold that is defined for visitors. \nEngagement rates give insight into the level of interest and enjoyment of the overall shopping experience.",
                    "ja": "来店客数のうち、一定時間以上店舗に滞在していた人の割合です。長く滞在するお客様が多いほど、お買い物を楽しんでいるお客様が多いと評価することができます。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "engagementrate"
                },
                "bouncerate": {
                  "title": {
                    "en": "Bounce Rate",
                    "ja": "直帰率"
                  },
                  "explanation": {
                    "en": "The proportion of customers that left the store sooner than the predefined threshold that constitutes a visitor.  \nBounce rates give us insight to the effectiveness of the store layout and VMD to encourage visitors to stay longer and engage with the products.",
                    "ja": "来店客数のうち、一定時間内に店舗から出て行ってしまった人の割合です。商品に触れる機会のなかったと思われる冷やかし客などの割合を知ることができます。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "bouncerate"
                },
                "gendercomparison": {
                  "title": {
                    "en": "Gender Comparison",
                    "ja": "男女比率"
                  },
                  "explanation": {
                    "en": "This value represents the ratio of male to female visitors",
                    "ja": "来店者の男女比率を表します。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "gendercomparison"
                },
                "age": {
                  "title": {
                    "en": "Age",
                    "ja": "年齢別比率"
                  },
                  "explanation": {
                    "en": "It represents the ratio of visitors by age according to predefined age groupings.",
                    "ja": "来店者の年齢別比率を表します。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "age"
                },
                "frvisitis": {
                  "title": {
                    "en": "Fitting Room Visits",
                    "ja": "試着室訪問客数"
                  },
                  "explanation": {
                    "en": "This value represents the total number of visitors using the fitting rooms.",
                    "ja": "試着室への総訪問客数を表します。"
                  },
                  "unit": {
                    "en": "人",
                    "ja": "人"
                  },
                  "metricKey": "frvisitis"
                },
                "frpercentage": {
                  "title": {
                    "en": "Fitting Room %",
                    "ja": "試着室訪問率"
                  },
                  "explanation": {
                    "en": "Fiiting Room% = Total Fitting Room Visits/Traffic\nAn essential KPI for all fashion retailers.  Insight into the number of shoppers who have accesssed the fitting rooms.\nIncreasing this Fitting Room % directly leads to increases Conversion and Sales.",
                    "ja": "試着室訪問率＝来店客数÷試着室訪問客数。来店客をどれだけ試着室へ誘導できるかがわかる指標です。"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "frpercentage"
                },
                "frconversion": {
                  "title": {
                    "en": "Fitting Room Conversion %",
                    "ja": "試着室購買率"
                  },
                  "unit": {
                    "en": "％",
                    "ja": "％"
                  },
                  "metricKey": "frconversion"
                },
                "frdwelltime": {
                  "title": {
                    "en": "Fitting Room Dwell Time",
                    "ja": "試着室滞在時間"
                  },
                  "explanation": {
                    "en": "This value represents the average time spent in the fitting rooms.",
                    "ja": "試着室の滞在時間の平均を表します。"
                  },
                  "unit": {
                    "en": "XXh:XXm:XXs",
                    "ja": "x 時間 y分 z秒"
                  },
                  "metricKey": "frdwelltime"
                },
                "avgoccupancy": {
                  "title": {
                    "en": "Avg Occupancy",
                    "ja": "平均滞在人数"
                  },
                  "explanation": {
                    "en": "This value represents the average number of visitors in location during in the selected period.",
                    "ja": "選択期間中の選択店舗内滞在人数を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "avgoccupancy"
                },
                "avgoccupancy%": {
                  "title": {
                    "en": "Avg Occupancy Percent",
                    "ja": "平均混雑率"
                  },
                  "explanation": {
                    "en": "The average % of maximum capacity reached during the selected period.",
                    "ja": "店舗滞在人数上限人数を基に計算された選択期間中の選択店舗内混雑率を表します。"
                  },
                  "unit": {
                    "en": "%",
                    "ja": "%"
                  },
                  "metricKey": "avgoccupancy%"
                },
                "maxoccupancy": {
                  "title": {
                    "en": "Max Occupancy",
                    "ja": "最大滞在人数"
                  },
                  "explanation": {
                    "en": "The maximum number of visitors during the selected period ",
                    "ja": "選択期間中の選択店舗内最大滞在人数を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "maxoccupancy"
                },
                "minoccupancy": {
                  "title": {
                    "en": "Min Occupancy",
                    "ja": "最小滞在人数"
                  },
                  "explanation": {
                    "en": "The minimum number of visitors during the selected period ",
                    "ja": "選択期間中の選択店舗内最小滞在人数を表します。"
                  },
                  "unit": {
                    "ja": "人"
                  },
                  "metricKey": "minoccupancy"
                },
                "numberofalerts": {
                  "title": {
                    "en": "Alerts",
                    "ja": "アラート送信回数"
                  },
                  "explanation": {
                    "en": "Total Alerts sent",
                    "ja": "FlowBotからチャットアプリケーションへ送信されたアラートメッセージ件数を表します。"
                  },
                  "unit": {
                    "en": "-",
                    "ja": "回"
                  },
                  "metricKey": "numberofalerts"
                },
                "numberofrequests": {
                  "title": {
                    "en": "Responses",
                    "ja": "アクション回数"
                  },
                  "explanation": {
                    "en": "Total Responses to alertss",
                    "ja": "アラートメッセージに添付されたアクションメッセージボタンが押された回数を表します。"
                  },
                  "unit": {
                    "en": "-",
                    "ja": "回"
                  },
                  "metricKey": "numberofrequests"
                },
                "requesttakenperalert": {
                  "title": {
                    "en": "Action/Alerts",
                    "ja": "アクション実行率"
                  },
                  "explanation": {
                    "en": "Actions taken per Alert",
                    "ja": "アクション回数÷アラート送信回数<br/>\n全アラートメッセージに対して、何割の反応があったかを表します。"
                  },
                  "unit": {
                    "en": "%",
                    "ja": "％"
                  },
                  "metricKey": "requesttakenperalert"
                },
                "timebeforetakingrequest": {
                  "title": {
                    "en": "Avg Time/Request",
                    "ja": "アラート反応平均時間"
                  },
                  "explanation": {
                    "en": "Time per Alert Response",
                    "ja": "FlowBotからチャットアプリケーションへメッセージが送信されてからアクションボタンが押されるまでの平均反応時間を表します。"
                  },
                  "unit": {
                    "en": "xxh:xxmin:xxs",
                    "ja": "xx時間xx分xx秒"
                  },
                  "metricKey": "timebeforetakingrequest"
                },
                "trafficinline": {
                  "title": {
                    "en": "Queue Length",
                    "ja": "待機人数"
                  },
                  "explanation": {
                    "en": "The number of shoppers in the Queue",
                    "ja": "選択期間中に列で待機していた人数を表します。"
                  },
                  "unit": {
                    "en": "-",
                    "ja": "人"
                  },
                  "metricKey": "trafficinline"
                },
                "avgusersinline": {
                  "title": {
                    "en": "Avg Queue Length",
                    "ja": "平均待機人数"
                  },
                  "explanation": {
                    "en": "The average number of shoppers in the Queue",
                    "ja": "平均待機人数＝選択期間中の待機人数合計 ÷（選択期間日数＊店舗の営業時間）<br/>\n営業時間中１時間あたりの列で待機している人の平均人数を表します。\n"
                  },
                  "unit": {
                    "en": "-",
                    "ja": "人"
                  },
                  "metricKey": "avgusersinline"
                },
                "avgwaitingtime": {
                  "title": {
                    "en": "Avg Waiting Time",
                    "ja": "平均待機時間"
                  },
                  "explanation": {
                    "en": "Avg Waiting Time in the Queue",
                    "ja": "列で待機している人の一人あたりの平均待機時間を表します。"
                  },
                  "unit": {
                    "en": "xxh:xxmin:xxs",
                    "ja": "xx時間xx分xx秒"
                  },
                  "metricKey": "avgwaitingtime"
                },
                "dwelltime": {
                  "title": {
                    "en": "Dwell Time"
                  },
                  "metricKey": "dwelltime"
                },
                "engagedcount": {
                  "title": {
                    "en": "Engaged Count"
                  },
                  "metricKey": "engagedcount"
                },
                "bouncedcount": {
                  "title": {
                    "en": "Bounced Count"
                  },
                  "metricKey": "bouncedcount"
                },
                "frvisits": {
                  "title": {
                    "en": "FR Visits"
                  },
                  "metricKey": "frvisits"
                },
                "avgfrdwelltime": {
                  "title": {
                    "en": "Avg FR Dwell Time"
                  },
                  "metricKey": "avgfrdwelltime"
                }
              }
            },
            "callName": "getKPIPreferences"
          }

          const kpis = data.data.response;
          const locale = store.state.locale;
          let kpiList = Object.values(kpis).map((kpi) => {
            kpi.title =
              locale === 'ja'
                ? kpi.title?.ja ?? kpi.title?.en ?? ''
                : kpi.title?.en ?? '';
            kpi.explanation =
              locale === 'ja'
                ? kpi.explanation?.ja ?? kpi.explanation?.en ?? ''
                : kpi.explanation?.en ?? '';
            kpi.unit =
              locale === 'ja'
                ? kpi.unit?.ja ?? kpi.unit?.en ?? ''
                : kpi.unit?.en ?? '';
            kpi.videoUrl =
              locale === 'ja'
                ? kpi.videoUrl?.ja ?? kpi.videoUrl?.en ?? ''
                : kpi.videoUrl?.en ?? '';
            return kpi;
          });
          // const occupancyKpiOptions = [
          //   'avgoccupancy',
          //   'maxoccupancy',
          //   'minoccupancy',
          //   'avgoccupancy%'
          // ];
          // const occupancyKpis = kpiList
          //   .filter(k => occupancyKpiOptions.includes(k.metricKey))
          //   .map(k => {
          //     switch (k.metricKey) {
          //       case 'avgoccupancy':
          //         k.metricKey = 'avgOccupancy';
          //         break;

          //       case 'maxoccupancy':
          //         k.metricKey = 'maxOccupancy';
          //         break;

          //       case 'minoccupancy':
          //         k.metricKey = 'minOccupancy';
          //         break;

          //       case 'avgoccupancy%':
          //         k.metricKey = 'avgOccupancyPercentage';
          //         break;
          //     }
          //     return k;
          //   });
          kpiList = kpiList
            .filter(kpi => {
              return kpi.visibility === 1;
            })
            .sort((a, b) => {
              return a.order - b.order;
            });
          kpiList.map(singleKpi => {
            if (singleKpi.title == "Sales Per Sqm") {
              singleKpi.title = "Sales Per Tsubo";
            }
            if (singleKpi.title == "Traffic Per Sqm") {
              singleKpi.title = "Traffic Per Tsubo";
            }
            return singleKpi;
          });

          // commit('setOccupancyKpis', occupancyKpis);
          store.commit('setKpiPreferences', kpiList);
          // commit('setKpiPreferencesNew', kpiList);
          // commit('setKpis', kpis);
        } catch (error) {
          // console.log(error);
        }
      },
    }


