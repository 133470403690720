import { PostRequest } from '@/util/http';

export function APIFetchKPI(params: any) {
  return PostRequest('queue/kpis', params);
} // APIFetchKPI

export function APIFetchPowerHourByFloor(params: any, type) {
  return PostRequest('queue/powerHour/byFloor/' + type, params);
} // APIFetchPowerHourByFloor

export function APIFetchPowerHourByPos(params: any, type) {
  return PostRequest('queue/powerHour/byPos/' + type, params);
} // APIFetchPowerHourByPos

export function APIFetchQueueNotifierTrend(params: any, queueMetricKey) {
  return PostRequest(`queue/kpi/trend/${queueMetricKey}/daily`, params);
} // APIFetchQueueNotifierTrend

export function APIFetchQueueNotifierTrendHourly(params: any, queueMetricKey) {
  return PostRequest(`queue/kpi/trend/${queueMetricKey}/hourly`, params);
} // APIFetchQueueNotifierTrend

export function APIFetchQueueNotifierDate(params: any, queueMetricKey) {
  return PostRequest(`queue/kpi/distribution/byDate`, params);
} // APIFetchQueueNotifierTrend

export function APIFetchStoresList(params: any) {
  // return PostRequest('allStores', params);
  return PostRequest('queueEnabledStores', params);
} // APIFetchPowerHourByPos

export function APIFetchFloorsListByStore(params: any, storeID) {
  return PostRequest(`storeFloor/${storeID}`, params);
} // APIFetchFloorsListByStore

export function APIFetchPOSListByFloor(params: any, floorID) {
  return PostRequest(`storePos/${floorID}`, params);
} // APIFetchFloorsListByStore
