
  import Vue from "vue";
  import { mapActions, mapGetters, mapState } from "vuex";
  // import { addKpiCustomization } from "@/util/kpis";
  
  export default {
    props: {
      kpisListBase: { type: Array, default: () => [] },
      allTrends: { type: Object, default: () => {} },
      loading: { type: Boolean, default: false },
      modifiedDate: { type: String, default: '' },
    },
    data() {
      return {
        trendOrder: 4,
        currentTrend: "",
        metricKeyShort: "",
        cardHover: "",
        kpisList: []
      };
    }, // data
    computed: {
      ...mapState("overview", { kpis: "kpis" }),
      ...mapState("trend", ["targetSelectedPeriod", "targetComparedPeriod"]),
      ...mapState("user", ["kpiPreferences"]),
      ...mapGetters("filter", [
        "getStoreCodesList",
        "getDaysOfWeekList",
        "getStartingPeriod",
        "getEndingPeriod",
        "getStartingCompared",
        "getEndingCompared",
        "getCustomCalenderState",
        "getEndingCompared",
      ]),
    }, // computed
    watch: {
      // targetSelectedPeriod(newValue) {
      //   this.addTargetAchivment();
      // },
      // targetComparedPeriod(newValue) {
      //   this.addTargetAchivment();
      // },
      // getStartingPeriod(newValue) {
      //   this.getOverviewData();
      // },
      kpisListBase(newValue) {
        this.kpisList = JSON.parse(JSON.stringify(newValue))
        // this.getOverviewData()
      }
    },
    created() {
      this.kpiUpdated = JSON.parse(JSON.stringify(this.kpisList))
      // this.getOverviewData();
    },
    methods: {
      // ...mapActions("trend", ["fetchTargetAchive"]),
      // async getOverviewData() {
      //   try {
      //   await this.fetchTargetAchive({
      //     startDate: this.getStartingPeriod,
      //     endDate: this.getEndingPeriod,
      //     storeCodes: this.getStoreCodesList.split(","),
      //     target: true,
      //   });
      //   // this.loading = false;
      // } catch (error) {
      //   console.log(error);
      // } finally {
      //   // this.loading = false;
      // }
      // try {
      //   await this.fetchTargetAchive({
      //     startDate: this.getStartingCompared,
      //     endDate: this.getEndingCompared,
      //     storeCodes: this.getStoreCodesList.split(","),
      //     target: false,
      //   });
      //   // this.loading = false;
      // } catch (error) {
      //   console.log(error);
      // } finally {
      //   // this.loading = false;
      // }
      //  this.addTargetAchivment()
        // try {
        //   await this.fetchTargetAchive({
        //     startDate: this.getStartingPeriod,
        //     endDate: this.getEndingPeriod,
        //     storeCodes: this.getStoreCodesList.split(","),
        //     target: true,
        //   });
        // } catch (error) {
        //   console.log(error);
        // }
        // try {
        //   await this.fetchTargetAchive({
        //     startDate: this.getStartingCompared,
        //     endDate: this.getEndingCompared,
        //     storeCodes: this.getStoreCodesList.split(","),
        //     target: false,
        //   });
        //   // this.loading = false;
        // } catch (error) {
        //   console.log(error);
        // }
        // this.addTargetAchivment()
      // },
      // addTargetAchivment(){
      //    this.kpisList = this.kpisList.filter(v => v.metricKey != this.$store.state.user.translate.target_achievement_rate)
      //    //
      //    var sales = this.kpisList.find(v => v.metricKey);
      //   // console.log(this.targetSelectedPeriod, 'targetSelectedPeriod')
      //   var sumAll = this.targetSelectedPeriod.reduce(
      //     (accumulator, currentValue) => accumulator + currentValue.value,
      //     0
      //   );
      //   var sumAllC = this.targetComparedPeriod.reduce(
      //     (accumulator, currentValue) => accumulator + currentValue.value,
      //     0
      //   );
      //   var isNumber = (x) => {
      //     if (Number.isNaN(x) || x === Infinity || x === -Infinity) {
      //       return 0;
      //     } else {
      //       return x;
      //     }
      //   };
      //   var formula = (value, bool) => {
      //     var salesCom = bool ? "comparedValue" : "value";
      //     var num = sales?.[salesCom]?.replaceAll("¥", "") || "0";
      //     var remCma = num.replaceAll(",", "");
      //     return isNumber((parseInt(remCma) / value) * 100);
      //   };
      //   this.kpisList.splice(1, 0, {
      //     metricKey: this.$store.state.user.translate.target_achievement_rate,
      //     metricKeyShort: this.$store.state.user.translate.target_achievement_rate,
      //     value: formula(sumAll, false).toFixed(2) + "%",
      //     comparedValue: formula(sumAllC, true).toFixed(2) + "%",
      //     variation: "0 ",
      //     variationPercentage: "1.00%",
      //     growthIndicator: formula(sumAll, false) >= formula(sumAllC, true) ? 1 : -1,
      //     variance: (formula(sumAll, false) - formula(sumAllC, true)).toFixed(2) + "%",
      //     metricID: "TargetAchivement",
      //     title: this.$store.state.user.translate.target_achievement_rate,
      //     explanation: this.$store.state.user.translate.target_achievement_rate_explanation,
      //     order: 2,
      //     visibility: 1,
      //   });
      // },
      getTooltip(kpiName: any): String {
        // if (this.$te(`tooltip_${kpiName}`, 'locale')) {
        // if (!['facadestops'].includes(kpiName)) {
        return this.$t(`HomeTooltips.tooltip_${kpiName}`);
        // } else {
        // return '';
        // }
        // }
  
        // return '';
      },
      getVideo(kpiName: any): String {
        // if (this.$te(`video_${kpiName}`, true)) {
        if (["sales", "conversion", "traffic", "avgpurchase"].includes(kpiName)) {
          return this.$t(`HomeTooltips.video_${kpiName}`);
        } else {
          return "";
        }
  
        // }
  
        // return '';
      },
      hoverCard(cardName) {
        this.cardHover = cardName;
      },
      leaveCard() {
        this.cardHover = "";
      },
      cardClick(cardName, order,metricKeyShort, event) {
      const wrapper = document.querySelectorAll('.control-wrapper');
       if (wrapper[order-1]?.contains(event.target)) return;
       //
        Vue.prototype?.$mixpanel?.track(metricKeyShort, {
          referrer: document.referrer,
        });
  
        if (this.currentTrend === cardName) {
          this.currentTrend = "";
          this.metricKeyShort = "";
        } else {
          let d = this.$q.screen.md ? 3 :
          this.$q.screen.lg || this.$q.screen.xl ? 4 :
          this.$q.screen.sm ? 2 : 1;
          this.trendOrder = Math.ceil(order/d) * d;
          this.currentTrend = cardName;
          this.metricKeyShort = metricKeyShort;
        }
      },
    },
  };
  