
import CommonMixin from '@/mixins/CommonMixin';
import DrawerMixin from '@/components/Common/Mixins/DrawerMixin';
import Store from '@/models/Store';
import { StoreOrder } from '@/models/Order';
import each from 'lodash/each';
import clone from 'lodash/clone';

interface SelectedStores {
  [key: string]: StoreOrder;
}

interface CheckedStores {
  [key: string]: boolean;
}

export default {
  mixins: [CommonMixin, DrawerMixin],
  props: {
    stores: { type: Array, required: true },
    order: { type: Object, required: true },
    index: { type: Number, required: true }
  },
  data() {
    return {
      selectedStores: {},
      checkedStores: {},
      innerStores: []
    };
  },
  computed: {
    storeMap() {
      const result = new Map<string, Store>();
      each(this.innerStores, store => {
        result.set(store.id, store);
      });
      return result;
    }
  },
  mounted() {
    const checkedStores: CheckedStores = {};
    const selectedStores: SelectedStores = {};
    this.innerStores = this.stores;
    each(this.innerStores, store => {
      checkedStores[store.id] = false;
    });
    each(this.order.storeOrders, storeOrder => {
      const { store } = storeOrder;
      checkedStores[store.id] = true;
      selectedStores[store.id] = storeOrder;
    });
    this.checkedStores = checkedStores;
    this.selectedStores = selectedStores;
  },
  methods: {
    handleFilter(value) {
      this.innerStores = this.stores.filter(store =>
        store.name.includes(value)
      );
    },
    backDrawer() {
      this.$emit('back');
    },
    save() {
      const { storeMap, index } = this;
      const storeOrders: StoreOrder[] = [];
      each(this.checkedStores, (checked, storeId) => {
        if (!checked) return;
        const store = storeMap.get(storeId);
        storeOrders.push(
          this.selectedStores[storeId] || {
            store,
            quantity: 1
          }
        );
      });
      const order = clone(this.order);
      order.storeOrders = storeOrders;
      this.$emit('update-store-order', { order, index });
    }
  }
};
