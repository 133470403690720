import axios from "axios"
import store from '@/store';
import { SSOPutRequest2, SSOPostRequest2, SSOPutRequest, SSODeleteRequest2, SSOGetRequest2 } from '@/util/kpis';
export default {
  // getPeerGroups() {
  //   const paramsData = {
  //       client_id: store.state?.clientId,
  //       client_secret: store.state?.clientSecret,
  //     };
  //   return axios.get(store.state?.baseKpiUrl+'/api/getPeerGroups',{
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // createPeerGroup(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "title": data.title,
  //     "description": data.description,
  //     "storeCodes": data.storeIds,
  //     "type": data.type
  //   };
  //   return axios.post(store.state?.baseKpiUrl+'/api/createPeerGroup', contactData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  // updatePeerGroup(data) {
  //   let contactData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "title": data.title,
  //     "description": data.description,
  //     "storeCodes": data.storeCodes,
  //     "peerGroupId": data.peerGroupId
  //   };
  //   return axios.put(store.state?.baseKpiUrl+'/api/updatePeerGroup',contactData,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getPeerGroups() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,

    }
    const apiResponse = await SSOGetRequest2(
      '/api/getPeerGroups',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async createPeerGroup(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "title": data.title,
      "description": data.description,
      "storeCodes": data.storeIds,
      "order": data.order,
      "type": data.type
    };
    const apiResponse = await SSOPostRequest2(
      '/api/createPeerGroup',paramsData,
      {
        // params: paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },

  async updatePeerGroup(data) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "title": data.title,
      "description": data.description,
      "storeCodes": data.storeCodes,
      "peerGroupId": data.peerGroupId,
      "order": data.order,
      "type": data.type
    };
    const apiResponse = await SSOPutRequest2(
      '/api/updatePeerGroup',paramsData,
      {
        // params: paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async  deletePeerGroup(peerGroupId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "peerGroupId": peerGroupId,
    };
    const apiResponse = await SSODeleteRequest2(
      '/api/deletePeerGroup',
      {
        data:paramsData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // deletePeerGroup(peerGroupId) {
  //   let userData = {
  //     "client_id": store.state?.clientId,
  //     "client_secret": store.state?.clientSecret,
  //     "peerGroupId": peerGroupId,
  //   };
  //   return axios.delete(store.state?.baseKpiUrl + '/api/deletePeerGroup', {
  //     data: userData,
  //     headers: {
  //       Authorization:
  //         "Bearer" + store.state?.token
  //     },
  //   })
  // },
}