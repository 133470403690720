
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['dataAccessibleRows', 'dataStores'],
  data() {
    return {
      alert: false,
      peerGroupsAccessibleColumns: [
        {
          name: 'peerGroupType',
          align: 'center',
          label: this.$store.state.user.translate.name,
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'peerGroupName',
          align: 'left',
          label: this.$store.state.user.translate.description,
          field: 'peerGroupName',
          sortable: true
        },
        {
          name: 'totalStore',
          align: 'center',
          label: this.$store.state.user.translate.no_of_stores,
          field: 'totalStore'
        },
        {
          name: 'exportOrder',
          align: 'center',
          label: this.$store.state.user.translate.export_order_no,
          field: 'exportOrder'
        },
        {
          name: 'action',
          label: this.$store.state.user.translate.actions,
          field: 'action'
        }
      ],
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      selected: [],
      filter: '',
      visible: false
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(
        this.dataAccessibleRows.length / this.pagination.rowsPerPage
      );
    }
  },
  methods: {
    showModel() {
      this.alert = true;
    },
    getStoreCount(stores) {
      var count = [];
      stores?.forEach(element => {
        var store = this.dataStores.find(v => element === v.storeNumber);
        if (store) {
          count.push(store);
        }
      });
      return count.length;
    },
    deletePeerGroup(peerGroup) {
      this.$emit('deletePeerGroup', peerGroup);
    },
    editPeerGroupDialog(peerGroup) {
      this.$emit('editPeerGroupDialog', peerGroup);
    },
    openPeerGroupModel() {
      this.alert = false;
      this.$emit('openPeerGroupModel');
    }
  }
};
