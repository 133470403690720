
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import { UpdateUser } from '@/repositories/UserRepository';
import { validateMandatoryFields } from '@/util/validation';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      user: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
 //       phone: {
 //         countryCode: '',
  //        phoneNumber: ''
   //     },
        phoneCountryCode:'',
        phoneNumber: '',
        stores: '',
        roleId: ''
      },
      removedStores: [],
      loading: true,
      disable: true
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
    canSubmit() {
      return validateMandatoryFields(this.user, [
        'firstName',
        'lastName',
        'username',
        '',
        'roleId',
        'stores'
      ]);
    }
  },
  async created() {
    this.loading = true;
    try {
      const user = await this.$repo.user.getUser(this.userId);
      const store = await this.$repo.store.getUserStore(this.userId);
      const data = user.response.userDetails;
      this.user = {
        username: data.username,
        ...data.profile,
        email: data.email,
        phone: {
          phoneCountryCode: data.profile.phoneCountryCode,
          phoneNumber: data.profile.phoneNumber || ''
        },
        phoneCountryCode: data.profile.phoneCountryCode,
        phoneNumber: data.profile.phoneNumber,
        stores: store.map(v => v.store),
        roleId: data.roleDetails._id
      };
    } catch (err) {
      handleErrorUI(err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    removeStores(val) {
      this.removedStores = [...this.removedStores, val];
    }
  }
};
