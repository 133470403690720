
export default {
  props: {
    target: {
      type: String,
      required: false,
      default: 'test'
    },
    achieved: {
      type: String,
      required: false,
      default: 'test'
    },
    remaining: {
      type: String,
      required: false,
      default: 'test'
    }
  },

  data() {
    return {
      test: ''
    };
  }
};
