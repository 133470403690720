
import { colors, dims } from '@/styles/management';

export default {
  props: {
    label: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    badgeStyle() {
      return {
        backgroundColor: colors.green,
        color: colors.white,
        fontWeight: dims.font.bold,
        borderRadius: '12px',
        marginRight: '4px'
      };
    }
  }
};
