import { AxiosInstance } from 'axios';
import { createBackendError } from '@/util/error';
import { FTPConfigDTO } from '@/models/FTPConfig';

export default class FTPSetupController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getConfig() {
    try {
      const { data } = await this.client.get('/sftp');
      return data as FTPConfigDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createConfig(config: FTPConfigDTO) {
    try {
      const { data } = await this.client.post('/sftp', config);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateConfig(config: FTPConfigDTO) {
    try {
      const { data } = await this.client.post('/sftp', config);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async checkConnection(config: FTPConfigDTO) {
    try {
      const { data } = await this.client.post('/sftp/check-connection', config);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
