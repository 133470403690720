
export default {
  props: {
    value: { default: false, type: Boolean },
    store: { default: () => ({}), type: Object }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    }
  }
};
