
import domains from "@/services/domains";
export default {
  props: {},
  data() {
    return {
      configuration: true,
      domain: "",
      description: "",
      loadingBtn: false,
    };
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
  },
  methods: {
    isValidDomain(str) {
      //    let regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
    // if (str == null) {
    //     this.$q.notify({
    //       message: "Enter Valid Domain Name",
    //       color: "red",
    //     });
    //     return "false";
    // }
    // if (regex.test(str.substring(str.indexOf("@") + 1)) == true) {
    //     return "true";
    // }
    // else {
    //      this.$q.notify({
    //       message: "Enter Valid Domain Name",
    //       color: "red",
    //     });
    //     return "false";
    // }
      // const regex =  ;
      const slug = str.substring(str.indexOf("@") + 1);
      if (slug == "gmail.com") { 
       this.$q.notify({
          message: this.$store.state.user.translate.use_domain,
          color: "red",
        });
        return "false";
      } 
    },
    createDomain() {
      if(this.isValidDomain(this.domain)){
         this.isValidDomain(this.domain);
         return
      }
       this.loadingBtn = true;
      domains
        .createWhiteListDomain(this.domain)
        .then((response) => {
          this.$q.notify({
            message: this.$store.state.user.translate.domain_created_successfully,
            color: "green",
          });
           this.loadingBtn = false;
          this.$emit("updateWhitelistDomains");
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
           this.loadingBtn = false;
          this.$emit("closeModel", false);
        });
    },
  },
};
