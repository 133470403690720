import RoleController from '@/services/management/RoleController';
import { map } from 'lodash';
import Role from '@/models/Role';
import Authority from '@/models/Authority';
import { Pagination, PaginationResponse } from '@/util/pagination';

export interface GetRolesResult {
  data: Role[];
  pagination: PaginationResponse;
}

export default class RoleRepository {
  private readonly roleController: RoleController;

  constructor(roleController: RoleController) {
    this.roleController = roleController;
  }

  public async getRolesSSO() {
    const res = await this.roleController.getRolesSSO();
    return res;
  }

  public async getRoles(
    pagination: Pagination = new Pagination()
  ): Promise<GetRolesResult> {
    const { content, ...meta } = await this.roleController.getRoles(
      pagination.toRequest()
    );
    return {
      data: map(content, dto => Role.fromDTO(dto)),
      pagination: meta
    };
  }

  public async createRole(request, permissions) {
    const dto = await this.roleController.createRole(request, permissions);
    return Role.fromDTO(dto);
  }

  public async updateRole(id: string, request) {
    const dto = await this.roleController.updateRole(id, request);
    return Role.fromDTO(dto);
  }

  public async deleteRole(id: string) {
    await this.roleController.deleteRole(id);
  }

  public async getAuthorities() {
    const content = await this.roleController.getAuthorities();
    return map(content, dto => Authority.fromDTO(dto));
  }

  public async getAuthoritiesByRole(roleId) {
    const { content } = await this.roleController.getAuthoritiesByRole(roleId);
    return map(content, dto => Authority.fromDTO(dto));
  }

  public async updateRoleAuthorities(title, description, roleId, authorities: any, selectedPeriod, comparePeriod, locationFilter) {
    const response = await this.roleController.updateRoleAuthorities(
      title,
      description,
      roleId,
      authorities,
      selectedPeriod,
      comparePeriod,
      locationFilter
    );
    return response;
  }
}
