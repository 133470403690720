import HttpController from '../HttpController';
import { PaginatedResponse } from '@/models/Pagination';
import { SaleDTO } from '@/models/Sale';
import { createBackendError } from '@/util/error';

export type GetSalesResponse = PaginatedResponse<SaleDTO>;

export default class SalesController extends HttpController {
  public async getSales() {
    try {
      const { data } = await this.client.get('/sales', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          unpaged: true
        }
      });
      return data as GetSalesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getSalesTemplate(kpi) {
    try {
      const { data } = await this.client.get(
        '/' + kpi + '/import-' + kpi + '/template'
      );
      return data as string;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async importReportData(
    file: File,
    salesMappingTemplateId: string,
    kpi: string
  ) {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.client.post(
      `/${kpi}/import-${kpi}/${salesMappingTemplateId}`,
      formData
    );
    return data;
  }
}
