import { AxiosInstance } from 'axios';
import { DefaultSettingsDTO } from '@/models/onboarding/DefaultSettings';
import { PaginatedResponse } from '@/models/Pagination';
import {
  APIUpdateTenantSettingsSSO,
  APIGetDefaultSettingsSSO
} from '@/api/authSSO';

export type GetDefaultSettingsResponse = PaginatedResponse<DefaultSettingsDTO>;
export type CreateDefaultSettingsRequest = DefaultSettingsDTO;
export type UpdateDefaultSettingsRequest = DefaultSettingsDTO;
export type CreateDefaultSettingsResponse = DefaultSettingsDTO;

export default class DefaultSettingController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getDefaultSettings() {

    // const { data } = await this.client.get('/default-settings', {
    //   params: {
    //     offset: 0,
    //     pageNumber: 1,
    //     pageSize: 10,
    //     paged: false
    //   }
    // });
    // const { response } = await APIGetDefaultSettingsSSO({});
    // data.content[0].locationFilter = response.config.allowLocationFilter;
    // data.content[0].storeComparePeriods = response.config.comparePeriod;
    // data.content[0].storeSelectPeriods = response.config.selectedPeriod;
    // data.content[0].allowAdvertiseItems = response.config.allowAdvertiseItems
    // return data as GetDefaultSettingsResponse;

    const { response } = await APIGetDefaultSettingsSSO({});
    //APIGetDefaultSettingsSSO
    localStorage.setItem("WeekStartDay", response.config.weekStartDay)

    const transformedResponse = {
      content: [
        {
          id: "0000000-0000-0000-0000-000000000001",
          createdBy: "system",
          createdDate: 1590123504517,
          updatedBy: "demo@flow-solutions.com",
          updatedDate: 1681415210293,
          storeFloorAreaUnit: response.config.storeFloorAreaUnit,
          headquartersSelectPeriods: null,
          headquartersComparePeriods: null,
          storeSelectPeriods: response.config.selectedPeriod,
          storeComparePeriods: response.config.comparePeriod,
          locationFilter: response.config.allowLocationFilter,
          engagementRateThreshold: "SECONDS",
          bounceRateThreshold: "SECONDS",
          weekStartDay: "SUNDAY",
          fiscalCalendarStart: response.config.fiscalStartMonth,
          weeklyEmailReportSendDay: response.config.weeklyReportDay,
          weeklyEmailReportSendTime: response.config.weeklyReportTime,
          monthlyEmailReportSendDay: response.config.monthlyReportDay,
          monthlyEmailReportSendTime: response.config.monthlyReportTime,
          allowAdvertiseItems: response.config.allowAdvertiseItems
        }
      ],


      first: true,
      last: true,
      number: 0,
      numberOfElements: 1,
      size: 2147483647,
      sort: {
        empty: false,
        sorted: true,
        unsorted: false
      },
      totalElements: 1,
      totalPages: 1,
    };
    return transformedResponse as unknown as GetDefaultSettingsResponse;
  }

  // public async getSchema(email: string, password: string) {
  //   await this.client.get('/default-settings/onboarding', {
  //     params: { email, password }
  //   });
  //   return true;
  // }

  public async createDefaultSettings(req: CreateDefaultSettingsRequest) {
    const { data } = await this.client.post('/default-settings', req);
    return data as CreateDefaultSettingsResponse;
  }

  public async updateDefaultSettings(
    id: string,
    req: UpdateDefaultSettingsRequest
  ) {
    let settings: any = {};
    // settings.locationFilter = req.locationFilter;
    // settings.storeComparePeriods = req.storeComparePeriods;
    // settings.storeSelectPeriods = req.storeSelectPeriods;
    // settings.allowLocationFilter = req.locationFilter;
    // settings.comparePeriod = req.storeComparePeriods;
    // settings.selectedPeriod = req.storeSelectPeriods;


    settings.selectedPeriod = req.storeSelectPeriods,
      settings.comparePeriod = req.storeComparePeriods,
      settings.fiscalStartMonth = req.fiscalCalendarStart,
      settings.weeklyReportDay = req.weeklyEmailReportSendDay,//weeklyReportDay,//weekStartDay
      settings.weeklyReportTime = req.weeklyEmailReportSendTime,//weeklyReportTime,
      settings.monthlyReportDay = req.monthlyEmailReportSendDay,//monthlyReportDay,
      settings.monthlyReportTime = req.monthlyEmailReportSendTime,//monthlyReportTime,
      settings.storeFloorAreaUnit = req.storeFloorAreaUnit,
      settings.allowLocationFilter = req.locationFilter,
      settings.allowAdvertiseItems = req.allowAdvertiseItems


    const { response } = await APIUpdateTenantSettingsSSO({ settings });
    if (req.storeComparePeriods == "PREVIOUS_YEAR_SAME_DAY_OF_WEEK") {
      //delete req.storeComparePeriods;
      req.storeComparePeriods = "PREVIOUS_YEAR_SAME_PERIOD";//there is an issue with sohaib logic
    }
    const { data } = await this.client.post(`/default-settings/${id}`, req);
    if (settings.comparePeriod == "PREVIOUS_YEAR_SAME_DAY_OF_WEEK") {
      data.storeComparePeriods = "PREVIOUS_YEAR_SAME_DAY_OF_WEEK";
    }
    // updated response (data) didn't have allowAdvertiseItems
    data.allowAdvertiseItems = req.allowAdvertiseItems;
    return data as CreateDefaultSettingsResponse;
  }
}
