import { AxiosInstance } from 'axios';
import Entity from '@/models/Entity';
import { PaginatedResponse } from '@/models/Pagination';

export type GetBusinessTypesResponse = PaginatedResponse<Entity>;

export default class BusinessTypeController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getBusinessTypes() {
    const { data } = await this.client.get('/business-types', {
      params: {
        offset: 0,
        pageNumber: 1,
        pageSize: 10,
        paged: false
      }
    });
    return data as GetBusinessTypesResponse;
  }
}
