
import { required } from 'vuelidate/lib/validators';
import clone from 'lodash/clone';
import map from 'lodash/map';
import countryCodes from 'country-codes-list';
import AuthMixin from '../../../../mixins/AuthMixin';
import CommonMixin from '../../../../mixins/CommonMixin';
import { handleErrorUI } from '../../../../util/error';
import { mapGetters } from 'vuex';

export default {
  mixins: [AuthMixin, CommonMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }, // props
  data() {
    return {
      isHide: false,
      selectedIndustryIndex: null,
      industries: []
    };
  }, // data
  computed: {
    ...mapGetters('user', ['getLocale']),
    industryOptions() {
      return map(this.industries, (industry, index) => {
        return {
          label:
            this.getLocale === 'ja' ? industry.nameJapanese : industry.name,
          value: index
        };
      });
    },
    countryCodesList() {
      return Object.keys(
        countryCodes.customList('countryCallingCode', '[{countryCallingCode}]')
      ).map(v => '+' + v);
    }
  },
  watch: {
    selectedIndustryIndex: function(index) {
      const industry = this.industries[index];
      this.value.industryId = industry.id;
      this.value.industryName = industry.name;
      this.value.industryNameJp = industry.nameJapanese;
    }
  }, // watcher
  async created() {
    try {
      this.industries = await this.$repo.core.getIndustries();
    } catch (err) {
      handleErrorUI(err);
    }
  },
  methods: {
    checkIndustry(v) {
      return v !== null;
    },
    validate() {
      return this.$v.value.$invalid;
    },
    sync() {
      this.loading = true;
      this.disable = true;
      this.$v.value.$touch();
      try {
        if (!this.$v.value.$invalid) {
          this.$emit('input', clone(this.value));
        }
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.value.$reset();
      } // finally
    }
  },
  validations: {
    value: {
      companyName: {
        required
      },
      phone: {
        required
      }
    },
    selectedIndustryIndex: {
      required
    }
  }
};
