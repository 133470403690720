
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
import { createColumn, formatAsCurrency } from '@/util/table';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import { PaymentConfirmation } from '@/models/RequestOrder';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import { Bank } from '@/models/Bank';
import AddNewPaymentMethodDialog from '@/components/Tools/Billing/PaymentMethod/AddNewPaymentMethodDialog.vue';
import PaginationMixin from '@/mixins/PaginationMixin';

enum PaymentMethod {
  BANK,
  CREDIT_CARD
}

export default {
  components: { AddNewPaymentMethodDialog },
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: { default: false, type: Boolean },
    order: { default: () => ({}), type: Object },
    item: { default: () => ({}), type: Object },
    store: { default: () => ({}), type: Object }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      paymentMethodOption: [
        {
          label: 'Bank',
          value: PaymentMethod.BANK
        },
        {
          label: 'Credit Card',
          value: PaymentMethod.CREDIT_CARD
        }
      ],
      activePaymentMethod: PaymentMethod.BANK,
      banks: [],
      creditCards: [],
      selectedPaymentMethod: null,
      paymentMethods: [],
      PaymentMethod: PaymentMethod,
      addPaymentMethodDialogShown: false,
      tableColumns: [
        createColumn('productName', 'Name'),
        createColumn('quantity', 'Quantity'),
        createColumn('price', 'Price', formatAsCurrency()),
        createColumn('subtotal', 'Subtotal', formatAsCurrency())
      ]
    };
  },
  computed: {
    ...mapState('user', ['profile', 'sessionClient']),
    ...mapGetters('user', ['getToken']),
    ...mapGetters('tenant', ['hasResetAccount']),
    isDowngrade() {
      if (this.order == null) return false;
      return this.order.downgradePlan;
    },
    formattedSubTotal() {
      if (this.order == null) return 0;
      return formatCurrencyWithSymbol(
        this.order.totalOrder,
        this.order.currency.code
      );
    },
    formattedTax() {
      if (this.order == null) return 0;
      return formatCurrencyWithSymbol(
        this.order.totalTax,
        this.order.currency.code
      );
    },
    formattedGrandTotal() {
      if (this.order == null) return 0;
      return formatCurrencyWithSymbol(
        this.order.grandTotal,
        this.order.currency.code
      );
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.formatTableData();
      }
    },
    async activePaymentMethod(method) {
      await this.retrievePaymentMethods(method);
      this.selectedPaymentMethod = null;
    }
  },
  async created() {
    await this.retrievePaymentMethods(this.activePaymentMethod);
  },
  methods: {
    ...mapMutations('user', [
      'setLocale',
      'setUserPermissions',
      'setSession',
      'setBearerToken'
    ]),
    ...mapMutations('distribution', ['getLocalStoreKPI']),
    ...mapActions('user', [
      'authenticate',
      'fetchProfile',
      'fetchClientProfile'
    ]),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapMutations('marketplace', [
      'setLibraryStartingPeriod',
      'setLibraryEndingPeriod',
      'setLibraryStartingCompared',
      'setLibraryEndingCompared'
    ]),
    ...mapActions('tenant', ['resetAccount']),
    setUsersParams() {
      const storedUserId = localStorage.getItem('userID');
      if (storedUserId !== this.profile?.userID) {
        localStorage.removeItem('storeCodes');
      }
      localStorage.setItem('userID', this.profile?.userID);
      let stores = localStorage.getItem('storeCodes');
      if (stores !== null) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      } else {
        this.setStoreCodes({ storeCodes: this.profile?.stores.split(',') });
        localStorage.setItem('storeCodes', this.profile?.stores);
      }
      this.setStartingPeriod({
        startingPeriod: this.profile?.defaultDateFilter?.startDate
      });
      this.setEndingPeriod({
        endingPeriod: this.profile?.defaultDateFilter?.endDate
      });
      this.setStartingCompared({
        startingCompared: this.profile?.defaultDateFilter?.compareStartDate
      });
      this.setEndingCompared({
        endingCompared: this.profile?.defaultDateFilter?.compareEndDate
      });
      this.setRetailCalendar({
        dates: this.profile?.custom445Calendar
          ? this.profile?.custom445Calendar
          : {}
      });
      this.setCustomCalenderFlag({
        value: this.profile?.defaultDateFilter?.useCustomCalender
      });
      localStorage.setItem(
        'startingPeriod',
        this.profile?.defaultDateFilter?.startDate
      );
      localStorage.setItem(
        'endingPeriod',
        this.profile?.defaultDateFilter?.endDate
      );
      localStorage.setItem(
        'startingCompared',
        this.profile?.defaultDateFilter?.compareStartDate
      );
      localStorage.setItem(
        'endingCompared',
        this.profile?.defaultDateFilter?.compareEndDate
      );
      localStorage.setItem(
        'useCustomCalender',
        this.profile?.defaultDateFilter?.useCustomCalendar || false
      );
    },
    formatWithCurrency(value) {
      return formatCurrencyWithSymbol(value, this.order.currency.code);
    },
    formatTableData() {
      if (this.order == null) return [];
      const orderList = this.order.orders || [];
      this.tableData = orderList.map(item => ({
        id: item.productId,
        productName: item.productName,
        quantity: item.quantity,
        currency: this.order.currency.code,
        price: item.price,
        subtotal: item.subtotal
      }));
    },
    closeDialog() {
      this.$emit('input', false);
    },
    async retrievePaymentMethods(method: PaymentMethod) {
      this.selectedPaymentMethod = null;

      if (method === PaymentMethod.BANK) {
        if (this.banks.length === 0) await this.retrieveBankList();
        this.paymentMethods = this.banks.map(bank => ({
          label: bank.name,
          value: bank.code
        }));
      } else {
        if (this.creditCards.length === 0) await this.retrieveCreditCardList();
        this.paymentMethods = this.creditCards.map(card => ({
          label: card.cardNumber,
          value: card.id
        }));
      }
    },
    async retrieveBankList() {
      const { data } = await this.$repo.order.getBank();
      this.banks = data;
    },
    async retrieveCreditCardList() {
      const { tenant } = await this.$repo.session.fetch();
      this.creditCards = await this.$repo.order.getCreditCards(tenant);
    },
    async confirmPayment() {
      try {
        this.loading = true;
        if (this.selectedPaymentMethod == null) return;
        const confirmation: PaymentConfirmation = {
          tenantCardId: null,
          paymentMethod: null
        };
        if (this.activePaymentMethod === PaymentMethod.BANK) {
          const selectedBank = this.banks.find(
            bank => bank.code === this.selectedPaymentMethod
          );
          confirmation.paymentMethod = selectedBank.toDTO();
        } else {
          confirmation.tenantCardId = this.selectedPaymentMethod;
        }
        const { tenant } = await this.$repo.session.fetch();
        await this.$repo.planOrder.createOrder(
          tenant,
          this.item,
          this.store,
          confirmation
        );
        if (this.activePaymentMethod === PaymentMethod.BANK) {
          handleSuccessUI(
            'Order Plan has been successfully created. please check your email and follow the payment instruction.'
          );
        } else {
          handleSuccessUI('Payment confirmed successfully');
        }

        if (!this.hasResetAccount) {
          try {
            await this.resetAccount();
            this.setSession({ session: this.sessionClient });
            this.setBearerToken({ token: this.getToken });
            this.fetchProfile();
            this.fetchClientProfile();
            this.setUsersParams();
            this.$router.push({ name: 'management:store' });
          } catch (e) {
            handleErrorUI(e);
          }
        } else {
          this.success();
          this.loading = false;
        }
        this.success();
      } catch (err) {
        handleErrorUI(err);
        this.fail();
        this.loading = false;
      }
    },
    addPaymentMethod() {
      this.addPaymentMethodDialogShown = true;
    },
    async closeAddPaymentMethodDialog() {
      this.addPaymentMethodDialogShown = false;
      this.creditCards = [];
      await this.retrievePaymentMethods(PaymentMethod.CREDIT_CARD);
    },
    success() {
      this.$emit('success', this.order);
    },
    fail() {
      this.$emit('fail');
    }
  }
};
