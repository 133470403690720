
import AuthMixin from '@/mixins/AuthMixin';
import Modal from '@/components/LandingPage/Modal.vue';
import ModalTitle from '@/components/LandingPage/Modal/Title.vue';
import ModalBody from '@/components/LandingPage/Modal/Body.vue';
import ModalButtons from '@/components/LandingPage/Modal/Buttons.vue';

export default {
  components: {
    Modal,
    ModalTitle,
    ModalBody,
    ModalButtons
  },
  mixins: [AuthMixin, Modal],
  props: {
    size: {
      type: String,
      default: ''
    }
  }
};
