
import roles from "@/services/roles";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedRole"],
  // props: {
  //   selectedRole: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
      configuration: true,
      roleName: "",
      description: "",
      title: "",
      loadingBtn: false,
      selectedPeriod: null,
      comparePeriod: [],
      // pagination: {
      //   rowsPerPage: 5,
      // },
      defaultSetting: [],
      loading: false,
      locationFilterVisibility: [],
      selected: {},
      permissionValues: [],

      // selectPeriodOptions: [
      //   { label: "Today", value: "TODAY" },
      //   { label: "Yesterday", value: "YESTERDAY" },
      //   { label: "This Week", value: "THIS_WEEK" },
      //   { label: "Last Week", value: "LAST_WEEK" },
      //   { label: "This Month", value: "THIS_MONTH" },
      //   { label: "Last Month", value: "LAST_MONTH" },
      //   { label: "This Year", value: "THIS_YEAR" },
      //   { label: "Last Year", value: "LAST_YEAR" },
      //   { label: "Previous Period", value: "PREVIOUS_PERIOD" },
      //   { label: "Previous Year Same Period", value: "PREVIOUS_YEAR_SAME_PERIOD" },
      // ],
      // comparePeriodOptions: [
      //   { label: "Previous Period", value: "PREVIOUS_PERIOD" },
      //   { label: "Previous Year Same Period", value: "PREVIOUS_YEAR_SAME_PERIOD" },
      //   { label: "Previous Year Same Week", value: "PREVIOUS_YEAR_SAME_WEEK" },
      // ],
       selectPeriodOptions: [
        { label: this.$store.state.user.translate.today, value: 'TODAY' },
        { label: this.$store.state.user.translate.yesterday, value: 'YESTERDAY' },
        { label: this.$store.state.user.translate.this_week, value: 'THIS_WEEK' },
        { label: this.$store.state.user.translate.last_week, value: 'LAST_WEEK' },
        { label: this.$store.state.user.translate.this_month, value: 'THIS_MONTH' },
        { label: this.$store.state.user.translate.last_month, value: 'LAST_MONTH' },
        { label: this.$store.state.user.translate.this_year, value: 'THIS_YEAR' },
        { label: this.$store.state.user.translate.last_year, value: 'LAST_YEAR' },
        { label: this.$store.state.user.translate.previous_period, value: 'PREVIOUS_PERIOD' }
      ],
      comparePeriodOptions: [
        { label: this.$store.state.user.translate.previous_period, value: 'PREVIOUS_PERIOD' },
        { label: this.$store.state.user.translate.previous_year_same_period, value: 'PREVIOUS_YEAR_SAME_PERIOD' },
        { label: this.$store.state.user.translate.previous_year_same_day_of_week, value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK' }
      ],

      locationFilterVisibilityOptions: [
        { label: this.$store.state.user.translate.enable, value: true },
        { label: this.$store.state.user.translate.disable, value: false },
      ],

      reportColumns: [
        {
          name: "pagename",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "pagename",
          sortable: true,
        },

        {
          name: "Create",
          label: this.$store.state.user.translate.create,
          field: "Create",
        },
        {
          name: "View",
          label: this.$store.state.user.translate.View,
          field: "View",
        },
        {
          name: "Update",
          label: this.$store.state.user.translate.update,
          field: "Update",
        },
        {
          name: "Delete",
          label: this.$store.state.user.translate.delete,
          field: "Delete",
        },
        {
          name: "Import",
          label: this.$store.state.user.translate.import,
          field: "Import",
        },
        {
          name: "Download",
          label: this.$store.state.user.translate.download,
          field: "Download",
        },
        {
          name: "Edit",
          label: this.$store.state.user.translate.edit,
          field: "Edit",
        },
      ],
      reportRows: [],
      val: [],
      settingColumns: [],
      toolColumns: [],

      permissionMap: [],
      rolePermissions: [],

      toolRows: [],
      settingRows: [],
      assistantRows: [],
      chatRows: [],
      pulseRows: [],
      eventRows: [],
      flowForMobile: [],
    };
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
  },
  async mounted() {
    await this.getPermissions();
    if (this.selectedRole) {
      this.title =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.role;
      this.roleName = this.selectedRole.title;
      this.description = this.selectedRole.description;
      if (this.selectedRole.config) {
        this.selectedPeriod = this.selectedRole.config.selectedPeriod;
        this.comparePeriod = this.selectedRole.config.comparePeriod;
        this.locationFilterVisibility = this.selectedRole.config.allowLocationFilter;
      } else {
        this.selectedPeriod = "LAST_WEEK";
        this.comparePeriod = "PREVIOUS_PERIOD";
        this.locationFilterVisibility = true;
      }
    } else {
      this.title =
        this.$store.state.user.translate.new +
        " " +
        this.$store.state.user.translate.role;
    }
  },
  methods: {
    getPermissions() {
      this.loading = true;
      roles
        .getPermissions()
        .then((response) => {
          this.defaultSetting = response.data.response.defaultSettings;
          if (!this.selectedRole) {
            this.locationFilterVisibility = this.defaultSetting.allowLocationFilter;
            this.comparePeriod = this.defaultSetting.comparePeriod;
            this.selectedPeriod = this.defaultSetting.selectedPeriod;
          }
          this.permissionMap = response.data.response.permissions;
          this.loading = false;
          this.mapPermissons(this.permissionMap);
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
          this.$q.notify({
            message:
              this.$store.state.user.translate.fetching_permissions_error,
            color: "red",
          });
        });
    },

    mapPermissons(permissions) {
      var categories = {};
      permissions.forEach(function (e) {
        if (e.category) {
          if (!categories[e.category]) {
            categories[e.category] = [];
          }
          categories[e.category].push(e);
        }
      });
      var sections = {};
      for (let [key, value] of Object.entries(categories)) {
        if (!sections[key]) {
          sections[key] = {};
        }
        value.forEach(function (e) {
          if (e.section) {
            sections[key][e.section] = e;
          }
        });
      }
      var operations = {};
      var totalOperations = new Set();
      for (let [key, value] of Object.entries(categories)) {
        // eslint-disable-next-line no-unused-vars
        let key2 = sections[key];
        if (!operations[key]) {
          operations[key] = {};
        }
        value.forEach(function (e) {
          if (!operations[key][e.section]) {
            operations[key][e.section] = {};
          }
          if (e.operation && !operations[key][e.section][e.operation]) {
            operations[key][e.section][e.operation] = e;
            totalOperations.add(e.operation);
          }
        });
      }
      // console.log('operations', operations)

      var objs = [];
      for (let [key1, value1] of Object.entries(operations)) {
        for (let [key2, value2] of Object.entries(value1)) {
          var obj = {};
          obj.pagename = key2;
          for (let [key3, value3] of Object.entries(value2)) {
            obj[key3] = value3._id;
            if (this.selectedRole) {
              this.selectedRole.permissions.forEach((element) => {
                if (element._id === value3._id) {
                  this.permissionValues.push(value3._id);
                }
              });
            }
          }
          if (key1 === "Reports") {
            this.reportRows.push(obj);
          } else if (key1 === "Tool") {
            this.toolRows.push(obj);
          } else if (key1 === "Settings") {
            this.settingRows.push(obj);
          } else if (key1 === "Assistant") {
            this.assistantRows.push(obj);
          } else if (key1 === "Chat") {
            this.chatRows.push(obj);
          } else if (key1 === "Pulse") {
            this.pulseRows.push(obj);
          }
          else if (key1 === "Event") {
            this.eventRows.push(obj);
          } 
           else if (key1 === "Flow for mobile") {
            this.flowForMobile.push(obj);
          }
          objs.push(obj);
        }
      }
      this.reportRows = [
        ...this.reportRows,
        ...this.toolRows,
        ...this.settingRows,
        ...this.assistantRows,
        ...this.chatRows,
        ...this.pulseRows,
        ...this.eventRows,
        ...this.flowForMobile,
      ];
    },
    saveRoleDetails() {
      if (this.selectedRole) {
        let rolePermission = [];
        this.selectedRole.permissions.forEach((element) => {
          rolePermission.push(element._id);
        });
        let updateRoleData = {
          title: this.roleName,
          description: this.description,
          permissionsArray: this.permissionValues,
          roleId: this.selectedRole._id,
          selectedPeriod: this.selectedPeriod,
          comparePeriod: this.comparePeriod,
          locationFilterVisibility: this.locationFilterVisibility,
        };
        this.loadingBtn = true;
        roles
          .updateUserRole(updateRoleData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.loadingBtn = false;
            this.$emit("updateUserRole");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.loadingBtn = false;
            this.$emit("closeModel", false);
          });
      } else {
        let newRoleData = {
          title: this.roleName,
          description: this.description,
          permissionsArray: this.permissionValues,
          selectedPeriod: this.selectedPeriod,
          comparePeriod: this.comparePeriod,
          locationFilterVisibility: this.locationFilterVisibility,
        };
        this.loadingBtn = true;
        roles
          .createRole(newRoleData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.role_created_successfully,
              color: "green",
            });
            this.loadingBtn = false;
            this.$emit("updateUserRole", false);
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.loadingBtn = false;
            this.$emit("closeModel", false);
          });
      }
    },
  },
};
