import { APIExport } from '@/api/export';

interface DetailedReportState {
  kpiLineChart: {
    selectedKPI: string;
    compareKPI: string;
  };
}

const initialState: DetailedReportState = {
  kpiLineChart: {
    selectedKPI: '',
    compareKPI: ''
  }
};

export default {
  state: initialState,
  mutations: {
    setSelectedKPI(state: DetailedReportState, { kpi }: { kpi: string }) {
      localStorage.setItem('selectedKPIdetailedReport', kpi);
      state.kpiLineChart.selectedKPI = kpi;
    },
    setCompareKPI(state: DetailedReportState, { kpi }: { kpi: string }) {
      localStorage.setItem('compareKPIdetailedReport', kpi);
      state.kpiLineChart.compareKPI = kpi;
    }
  },
  actions: {}
};
