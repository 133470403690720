
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import {
    addMonths,
    addWeeks,
    startOfMonth,
    endOfMonth,
    addYears,
    startOfWeek,
    endOfWeek,
    subYears,
    format,
    differenceInDays
  } from 'date-fns';
  import moment from 'moment';
  import { addSign } from '@/util/replaceFidaKpi';
  import {
  GetReportingSumDataByLocationFidaV2LocationRep,convertApiResponseForLocationReport
} from '@/api/fida';

  export default {
    data() {
      return {
        previousYearSameMonthsSales:0,
        previousYearSameMonthsTarget:0,
        trendOrder: 4,
        currentTrend: '',
        loading: false
      };
    },
    computed: {
      ...mapGetters('filter', [
        'getStoreCodesList',
        'getDaysOfWeekList',
        'getPulseStartingPeriod',
        'getPulseEndingCompared',
        'getPulseStartingCompared',
        'getPulseEndingPeriod'
      ]),
      ...mapState('target', ['targetsPulse', 'callTracker','targetsPulseCompared', 'preYearData']),
      ...mapState('periodicReport', [
        'selectedStoreCode',
        'weeklyTarget',
        'monthPoints',
        'yearPoints'
      ])
    },
    watch: {
      selectedStoreCode() {
        this.loadTargetData();
      }
    },
    created() {
      if (this.selectedStoreCode) {
        this.loadTargetData();
      }
    },
    methods: {
      ...mapActions('overview', ['fetchOverview']),
      ...mapActions('periodicReport', ['getTargetTableValueWeekly']),
      ...mapMutations('periodicReport', ['setWeeklyLoading']),
      ...mapActions('target', ['fetchTargetsPulse','fetchTargetsPulseCompare']),
      addSigns(kpi,val){
        return addSign(kpi,val);
      },
      async loadTargetData() {
        // * updates weeklyTarget
        try {
   
          let startDate = startOfMonth(new Date());
          let endDate = endOfMonth(new Date());
          let compareStartDate = addYears(startDate, -1);
          let compareEndDate =  addYears(endDate, -1);

      
          var response = await GetReportingSumDataByLocationFidaV2LocationRep({

          startDate: format(compareStartDate, 'yyyy-MM-dd'),
          endDate: format(compareEndDate, 'yyyy-MM-dd'),
          "storeCodes": this.selectedStoreCode.split(','),
          kpiTypes: ["sls", "st"],
          "frequency": "all"
          });
          if(response)
          {
            this.previousYearSameMonthsSales =response?.kpis[0]?.sls?response.kpis[0].sls:0;
            this.previousYearSameMonthsTarget =response?.kpis[0]?.st?response.kpis[0].st:0;
            this.setWeeklyLoading({ component: 'weeklyTarget', value: true });
              let params = {
                startDate: this.getPulseStartingPeriod,
                endDate: this.getPulseEndingPeriod,
                kpiTypes: ["sls", "st"],
                storeCodes: this.selectedStoreCode.split(","),
              };
              let str = JSON.stringify(params)
              params.trackLatest = { callerId: "home", value: str }
          
              await this.fetchTargetsPulse(params);
              let paramsComp = {
                startDate: this.getPulseStartingCompared,
                endDate: this.getPulseEndingCompared,
                kpiTypes: ["sls", "st"],
                storeCodes: this.selectedStoreCode.split(","),
                isPrevWeek:false
              };
              const dataFunctions = (nDate) => {
                const d = subYears(new Date(nDate), 1);
                const formattedDate = format(d, 'yyyy-MM-dd');
                return formattedDate;
              }
              let prevYear = {
                ...paramsComp,
                // startDate: dataFunctions(this.getPulseStartingCompared),
                startDate: dataFunctions(new Date()),
                endDate: dataFunctions(this.getPulseEndingCompared),
                isPrevWeek:true,
              }
              await this.fetchTargetsPulseCompare(paramsComp)
              await this.fetchTargetsPulseCompare(prevYear);  
          }
        } catch (e) {
         console.log('exceptions')
        }
        this.setWeeklyLoading({ component: 'weeklyTarget', value: false });
      },
    }
  };
  