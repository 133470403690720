
import Card from './Card';

export default {
  components: {},
  props: {
    pageName: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    indexRouteName: {
      type: String,
      default: null
    },
    noCenter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backLabel() {
      return `${this.$t('back_to')} ${this.pageName}`;
    }
  },
  methods: {
    backToIndex() {
      this.$router.push({ name: this.indexRouteName });
    }
  }
};
