
import { createColumn } from '@/util/table';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import Product from '@/models/Product';
import { handleErrorUI } from '@/util/error';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'ProductListTable',
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      loading: true,
      columns: [
        createColumn('id', this.$t('ProductManagement.ProductID') as string),
        createColumn(
          'name',
          this.$t('ProductManagement.ProductName') as string
        ),
        createColumn(
          'type',
          this.$t('ProductManagement.ProductType') as string
        ),
        createColumn('price', this.$t('ProductManagement.Price') as string),
        createColumn(
          'description',
          this.$t('ProductManagement.Description') as string
        ),
        createColumn('active', this.$t('ProductManagement.Shown') as string, {
          format: (active: boolean) => (active ? 'Yes' : 'No')
        }),
        createColumn('actions', this.$t('ProductManagement.Action') as string)
      ],
      data: []
    };
  },
  mounted() {
    this.loadProducts();
  },
  methods: {
    formatPrice(product: Product) {
      const { price, currency } = product;
      return formatCurrencyWithSymbol(price, currency.code);
    },
    async loadProducts() {
      this.loading = true;
      try {
        const { products } = await this.$repo.product.getProducts();
        this.data = products;
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
