
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      groupName: '',
      usersList: [],
      loading: true,
      usersOption: [],
      searchKeyword: '',
      externalID: '',
      allowSubmit: false
    };
  },
  computed: {
    ...mapState('usermgmt', ['users', 'userGroup', 'settingsMode'])
  },
  watch: {
    users() {
      this.usersOption = this.users.map((dat, key) => {
        return {
          label: dat.firstName,
          value: dat.userID
        };
      });
    },
    searchKeyword() {
      this.usersOption = this.users
        .filter(
          v =>
            v.firstName.includes(this.searchKeyword) ||
            v.lastName.includes(this.searchKeyword) ||
            v.userName.includes(this.searchKeyword)
        )
        .map((dat, key) => {
          return {
            label: dat.firstName,
            value: dat.id
          };
        });
    }
  },
  async created() {
    this.loading = true;
    await this.fetchUsers();
    if (this.settingsMode === 'edit') {
      this.groupName = this.userGroup.groupName;
      this.externalID = this.userGroup.externalID;
      this.usersList = this.userGroup.users.map(v => v.userID);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('usermgmt', [
      'addUserGroup',
      'fetchUserGroup',
      'updateUserGroup',
      'fetchUsers'
    ]),
    ...mapMutations('usermgmt', ['setSettingsMode', 'setDialog']),
    async addUserGroupData(e) {
      e.preventDefault();
      let group = {};
      if (this.usersList.length > 0) {
        group = {
          groupName: this.groupName,
          userIDs: this.usersList.join(',')
        };
      } else {
        group = {
          groupName: this.groupName
        };
      }
      if (this.externalID !== '') {
        group = {
          ...group,
          externalID: this.externalID
        };
      }
      if (this.settingsMode === 'add') {
        await this.addUserGroup(group);
        await this.fetchUserGroup();
        this.setDialog(false);
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('successMessage')
        });
      } else {
        await this.updateUserGroup({
          userGroup: group,
          id: this.userGroup.groupID
        });
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('updateMessage')
        });
      }
      await this.fetchUserGroup();
      this.setDialog(false);
      this.setSettingsMode('add');
    }
  }
};
