
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';

export default {
  props: {
    order: { default: () => ({}), type: Object }
  },
  computed: {
    formattedSubTotal() {
      return formatCurrencyWithSymbol(
        this.order.total,
        this.order.currency.code
      );
    },
    formattedTax() {
      return formatCurrencyWithSymbol(this.order.tax, this.order.currency.code);
    },
    formattedGrandTotal() {
      return formatCurrencyWithSymbol(
        this.order.grandTotal,
        this.order.currency.code
      );
    }
  }
};
