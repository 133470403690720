import Sale from '@/models/Sale';
import SalesController from '@/services/core/SalesController';
import { convertFromSaleDTO } from '@/models/formatters/SaleFormatter';
import map from 'lodash/map';

export interface GetSalesResult {
  sales: Sale[];
}

export default class SalesRepository {
  private readonly salesController: SalesController;

  constructor(salesController: SalesController) {
    this.salesController = salesController;
  }

  public async getSales() {
    const res = await this.salesController.getSales();
    return {
      sales: map(res.content, dto => convertFromSaleDTO(dto))
    };
  }

  public getSalesTemplate(kpi: string) {
    return this.salesController.getSalesTemplate(kpi);
  }

  public importReportData(
    file: File,
    salesMappingTemplateId: string,
    kpi: string
  ) {
    return this.salesController.importReportData(
      file,
      salesMappingTemplateId,
      kpi
    );
  }
}
