
import users from "@/services/users";
import tenants from "@/services/tenants";

export default {
  props: {
    openModel: Boolean,
  },
  data() {
    return {
      fiscalCalenderStart: null,
      storeFloortUnit: null,
      weekStartDay: "SUN",
      loading: false,
      visible: false,
      monthReportTime: "",
      toggleModel: true,
      userDefaultSettings: "",
      monthReportDay: "",
      weekReportTime: "",
      weekReportDay: "",
      comparePeriod: "",
      selectPeriod: "",
      weekdays: [
        { label: this.$store.state.user.translate.monday, value: 'MON' },
        // { label: this.$store.state.user.translate.tuesday, value: 'TUE' },
        // { label: this.$store.state.user.translate.wednesday, value: 'WED' },
        // { label: this.$store.state.user.translate.thursday, value: 'THUR' },
        // { label: this.$store.state.user.translate.friday, value: 'FRI' },
        // { label: this.$store.state.user.translate.saturday, value: 'SAT' },
        { label: this.$store.state.user.translate.sunday, value: 'SUN' }
      ],
      selectPeriodOptions: [
        { label: this.$store.state.user.translate.today, value: 'TODAY' },
        { label: this.$store.state.user.translate.yesterday, value: 'YESTERDAY' },
        { label: this.$store.state.user.translate.this_week, value: 'THIS_WEEK' },
        { label: this.$store.state.user.translate.last_week, value: 'LAST_WEEK' },
        { label: this.$store.state.user.translate.this_month, value: 'THIS_MONTH' },
        { label: this.$store.state.user.translate.last_month, value: 'LAST_MONTH' },
        { label: this.$store.state.user.translate.this_year, value: 'THIS_YEAR' },
        { label: this.$store.state.user.translate.last_year, value: 'LAST_YEAR' },
        { label: this.$store.state.user.translate.previous_period, value: 'PREVIOUS_PERIOD' }
      ],
      comparePeriodOptions: [
        { label: this.$store.state.user.translate.previous_period, value: 'PREVIOUS_PERIOD' },
        { label: this.$store.state.user.translate.previous_year_same_period, value: 'PREVIOUS_YEAR_SAME_PERIOD' },
        { label: this.$store.state.user.translate.previous_year_same_day_of_week, value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK' }
      ],
      weekReportdays: [
        { label: this.$store.state.user.translate.monday, value: 'MON' },
        { label: this.$store.state.user.translate.tuesday, value: 'TUE' },
        { label: this.$store.state.user.translate.wednesday, value: 'WED' },
        { label: this.$store.state.user.translate.thursday, value: 'THUR' },
        { label: this.$store.state.user.translate.friday, value: 'FRI' },
        { label: this.$store.state.user.translate.saturday, value: 'SAT' },
        { label: this.$store.state.user.translate.sunday, value: 'SUN' }
      ],
      monthReportdays: [
        { label: this.$store.state.user.translate.monday, value: 'MON' },
        { label: this.$store.state.user.translate.tuesday, value: 'TUE' },
        { label: this.$store.state.user.translate.wednesday, value: 'WED' },
        { label: this.$store.state.user.translate.thursday, value: 'THUR' },
        { label: this.$store.state.user.translate.friday, value: 'FRI' },
        { label: this.$store.state.user.translate.saturday, value: 'SAT' },
        { label: this.$store.state.user.translate.sunday, value: 'SUN' }
      ],
      monthdays: [
        { label: this.$store.state.user.translate.january, value: 'JAN' },
        { label: this.$store.state.user.translate.february, value: 'FEB' },
        { label: this.$store.state.user.translate.march, value: 'MAR' },
        { label: this.$store.state.user.translate.april, value: 'APR' },
        { label: this.$store.state.user.translate.may, value: 'MAY' },
        { label: this.$store.state.user.translate.june, value: 'JUN' },
        { label: this.$store.state.user.translate.july, value: 'JUL' },
        { label: this.$store.state.user.translate.august, value: 'AUG' },
        { label: this.$store.state.user.translate.september, value: 'SEP' },
        { label: this.$store.state.user.translate.october, value: 'OCT' },
        { label: this.$store.state.user.translate.november, value: 'NOV' },
        { label: this.$store.state.user.translate.december, value: 'DEC' }
      ],
      storeUnit: [
        { label: this.$store.state.user.translate.tsubo, value: 'tsubo' },
        { label: this.$store.state.user.translate.sqm, value: 'sqm' },
      ],
    }
  },
  mounted() {
    this.visible = true
    this.getUserDefaultSettings()
  },
  methods: {
    updateToggle(value) {
      this.toggleModel = value
    },
    getUserDefaultSettings() {
      users.getDefaultSettings().then((response) => {
        this.userDefaultSettings = response.data.response
        this.toggleModel = this.userDefaultSettings.config.allowLocationFilter
        this.comparePeriod = this.userDefaultSettings.config.comparePeriod
        this.selectPeriod = this.userDefaultSettings.config.selectedPeriod
        this.weekReportDay = this.userDefaultSettings.config.weeklyReportDay
        this.weekReportTime = this.userDefaultSettings.config.weeklyReportTime
        this.monthReportDay = this.userDefaultSettings.config.monthlyReportDay
        this.monthReportTime = this.userDefaultSettings.config.monthlyReportTime
        this.storeFloortUnit = this.userDefaultSettings.config.storeFloorAreaUnit
        this.fiscalCalenderStart = this.userDefaultSettings.config.fiscalStartMonth
        this.weekStartDay = this.userDefaultSettings.config.weekStartDay || 'SUN'
        this.visible = false 
      })
    },
    updateDefaultSettings() {
      this.visible = true
      let contactData = {
        "selectedPeriod": this.selectPeriod,
        "comparePeriod": this.comparePeriod,
        "allowLocationFilter": this.toggleModel,
        "fiscalStartMonth": this.fiscalCalenderStart,
        "weeklyReportDay": this.weekReportDay,
        "weeklyReportTime": this.weekReportTime,
        "monthlyReportDay": this.monthReportDay,
        "monthlyReportTime": this.monthReportTime,
        "storeFloorAreaUnit": this.storeFloortUnit?.value,
        "weekStartDay": this.weekStartDay,
      }
      tenants.updateTenantDefaultSettings(contactData).then(() => {
        this.visible = false
        this.$q.notify({
          message: this.$store.state.user.translate.updated_successfully,
          color: 'green'
        })
      }).catch((error) => {
        // console.log(error)
        this.visible = false
        if (error.response.data.message) {
          this.$q.notify({
            message: error.response.data.message,
            color: 'red'
          })
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.something_went_wrong,
            color: 'red'
          })
        }
      })
    }
  }
};
