import {
  APIFetchTrafficByWeek,
  APIFetchTrafficByEntrance,
  APIFetchTrafficPowerHour
} from '@/api/traffic';
import {
  APIFetchFloorsListByStore,
  APIFetchKPI,
  APIFetchPOSListByFloor,
  APIFetchPowerHourByFloor,
  APIFetchPowerHourByPos,
  APIFetchQueueNotifierDate,
  APIFetchQueueNotifierTrend,
  APIFetchQueueNotifierTrendHourly,
  APIFetchStoresList
} from '@/api/queue';

const initialState = {
  storeID: '1',
  storeCodes: '33261',
  floorID: '1',
  posID: null,
  kpiData: [],
  stores: [],
  floors: [],
  pos: [],
  powerHourFloor: [],
  powerHourLine: [],
  trendData: [],
  tableData: [],
  floorData: [],
  floorsListByStore: [],
  posListByStore: [],
  NumberOfAlerts: {},
  NumberOfRequestsTaken: {},
  RequestTakenPerAlert: {},
  TimeBeforeTaking: {},
  TrafficInLine: {},
  AverageUsersInLine: {},
  AverageWaitingTime: {}
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setKPINumberOfAlerts(state, kpi) {
      state.NumberOfAlerts = kpi;
    },
    setKPINumberOfRequestsTaken(state, kpi) {
      state.NumberOfRequestsTaken = kpi;
    },
    setKPIRequestTakenPerAlert(state, kpi) {
      state.RequestTakenPerAlert = kpi;
    },
    setKPITimeBeforeTaking(state, kpi) {
      state.TimeBeforeTaking = kpi;
    },
    setKPITrafficInLine(state, kpi) {
      state.TrafficInLine = kpi;
    },
    setKPIAverageUsersInLine(state, kpi) {
      state.AverageUsersInLine = kpi;
    },
    setKPIAverageWaitingTime(state, kpi) {
      state.AverageWaitingTime = kpi;
    },
    setFloorData(state, payload) {
      state.floorData = payload;
    },
    setPowerHourByFloor(state, payload) {
      state.powerHourFloor = payload;
    },
    setPowerHourByPos(state, payload) {
      state.powerHourLine = payload;
    },
    setTrendData(state, payload) {
      state.trendData = payload;
    },
    setTableData(state, payload) {
      state.tableData = payload;
    },
    setStoreData(state, payload) {
      state.stores = payload;
    },
    setFloorList(state, payload) {
      state.floorData = payload;
    },
    setPosList(state, payload) {
      state.posListByStore = payload;
    },
    setStoreID(state, payload) {
      state.storeID = payload;
    },
    setFloorID(state, payload) {
      state.floorID = payload;
    },
    setPosID(state, payload) {
      state.posID = payload;
    }
  }, // mutations
  actions: {
    async fetchKpiData(
      { commit },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        kpis,
        daysOfWeek,
        posID,
        floorID,
        storeCodes
      }
    ) {
      const res = await APIFetchKPI({
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        kpis,
        posID,
        floorID,
        daysOfWeek,
        storeCodes
      });
      commit('setKPINumberOfAlerts', res.data[0].kpis['numberofalerts']);
      commit(
        'setKPINumberOfRequestsTaken',
        res.data[0].kpis['numberofrequests']
      );
      commit(
        'setKPIRequestTakenPerAlert',
        res.data[0].kpis['requesttakenperalert']
      );
      commit(
        'setKPITimeBeforeTaking',
        res.data[0].kpis['timebeforetakingrequest']
      );
      commit('setKPITrafficInLine', res.data[0].kpis['trafficinline']);
      commit('setKPIAverageUsersInLine', res.data[0].kpis['avgusersinline']);
      commit('setKPIAverageWaitingTime', res.data[0].kpis['avgwaitingtime']);
      return res;
    },
    // fetchTrafficByWeek
    async fetchStoresList({ commit }) {
      let res = await APIFetchStoresList({});
      commit('setStoreData', Object.values(res.data));
      let val = Object.values(res.data)[0];
      commit('setStoreID', val['storeID']);
    },
    async fetchPowerHourDataFloor(
      { commit },
      { startDate, endDate, kpis, posID, floorID, storeCodes, type, daysOfWeek }
    ) {
      let res = await APIFetchPowerHourByFloor(
        { startDate, endDate, kpis, posID, floorID, storeCodes, daysOfWeek },
        type
      );
      commit('setPowerHourByFloor', res.data);
    },
    async fetchPowerHourDataPos(
      { commit },
      { startDate, endDate, kpis, posID, floorID, storeCodes, daysOfWeek, type }
    ) {
      let res = await APIFetchPowerHourByPos(
        { startDate, endDate, kpis, posID, floorID, daysOfWeek, storeCodes },
        type
      );
      commit('setPowerHourByPos', res.data);
    },
    async fetchQueueNotifierTrend(
      { commit },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        kpis,
        posID,
        floorID,
        storeCodes,
        daysOfWeek,
        type
      }
    ) {
      let res = await APIFetchQueueNotifierTrend(
        {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          kpis,
          posID,
          daysOfWeek,
          floorID,
          storeCodes
        },
        type
      );
      commit('setTrendData', res.data);
    },
    async fetchQueueNotifierTrendHourly(
      { commit },
      {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        kpis,
        posID,
        floorID,
        daysOfWeek,
        storeCodes,
        type
      }
    ) {
      let res = await APIFetchQueueNotifierTrendHourly(
        {
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          kpis,
          posID,
          floorID,
          daysOfWeek,
          storeCodes
        },
        type
      );
      commit('setTrendData', res.data);
    },
    async fetchQueueNotifierDate(
      { commit },
      { startDate, endDate, kpis, posID, floorID, storeCodes, daysOfWeek, type }
    ) {
      let res = await APIFetchQueueNotifierDate(
        { startDate, endDate, kpis, posID, floorID, storeCodes, daysOfWeek },
        type
      );
      commit('setTableData', res.data);
    },
    async fetchFloorListByStore({ commit }, storeID) {
      let res = await APIFetchFloorsListByStore({}, storeID);
      commit('setFloorList', res.data);
    },
    async fetchPosListByStore({ commit }, floorID) {
      let res = await APIFetchPOSListByFloor({}, floorID);
      commit('setPosList', res.data);
    }
  } // actions
}; // export default
