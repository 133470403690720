import { PostRequest, GetRequest } from '@/util/http';
import { APIGetKpisPlainDataFida, APIGetReportingSumByLocationDataFidaV2 ,APIGetKpisPlainDataFidaV2} from '@/api/fidaApi';

export async function APIFetchTrafficByWeek(params: any, mode) {
  return PostRequest(`kpi/traffic/byWeek/${mode}`, params);
} // APIFetchTrafficByWeek

export function APIFetchTrafficByEntrance(params: any, mode) {
  return PostRequest(`kpi/traffic/byEntrance/${mode}`, params);
} // APIFetchTrafficByEntrance

export function APIFetchTrafficPowerHour(params: any, mode: string) {
  return PostRequest(`kpi/powerHour/traffic/${mode}`, params);
} //APIFetchTrafficPowerHour

export function APIDownloadTrafficReport(filterParams: any) {
  return PostRequest(`data/export/traffic`, filterParams);
}


// New Api

export async function APIFetchTrafficDataByStore(params: any) {
  const  postData = {
      kpiTypes: ['trf'],
     startDate: params.startDate,
     endDate: params.endDate,
     storeCodes: params.storeCodes.split(','),
     compareStartDate:params.compareStartDate,
     compareEndDate: params.compareEndDate,
     frequency: params.frequency
   }

  var response: any;
  response = await APIGetReportingSumByLocationDataFidaV2(postData);

  return response.data.response;
}

export async function APIFetchTrafficPowerHourV2(params: any) {
  const  postData = {
      kpiTypes: ['trf'],
     startDate: params.startDate,
     endDate: params.endDate,
     storeCodes: params.storeCodes.split(','),
     frequency: params.frequency
   }

  var response: any;
  response = await APIGetKpisPlainDataFidaV2(postData);
  return response.data.response.kpis;
}
