
import config from '@/config';
import { downloadFromUrl } from '@/util/file';
import { mapGetters } from 'vuex';
import { createColumn, formatAsDateTime } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import BillingRecord from '@/models/BillingRecord';
import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      appends: [
        createColumn('paymentDate', 'Payment Date', formatAsDateTime),
        createColumn('paymentAmount', 'Payment Amount'),
        createColumn('balance', 'Balance')
      ],
      data: [],
      loading: true
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount'])
  },
  async mounted() {
    await this.fetchBillings();
  },
  methods: {
    async fetchBillings() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.billing.getBillingHistory(
          tenant,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchBillings();
    },
    async downloadInvoice(billing: BillingRecord) {
      downloadFromUrl(
        `${config.apiGatewayURL}/console/payment/billing-plan/${billing.id}/pdf`
      );
    }
  }
};
