import { GetRequest, PostRequest, PostRequestSSO } from '@/util/http';
import axios from 'axios';

export function APIAuthenticate(params: any) {
  return axios.post(
    'rest/oauth/token',
    {},
    {
      params: {
        grant_type: 'password',
        client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
        client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
        username: params.username,
        password: params.password
      },
      headers: null
    }
  );
} // APIAuthenticate

export function APIReAuthenticate(params: any) {
  return PostRequest(
    'oauth/token',
    `refresh_token=${params.refresh_token}&grant_type=refresh_token`,
    {
      auth: {
        username: 'flowinsight',
        password: 'flowinsight'
      }
    }
  );
} // APIReAuthenticate

export function AccessTokenValidation(params: any) {
  return PostRequestSSO(
    'api/validateToken', {},  {
      params: {
        client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
        client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization:'Bearer '+ params.token
      }
    }
  );
} // AccessTokenValidation

export function APIGetFloorMapToken() {
  return PostRequest('', {});
}

export function APIFetchProfile(params: any, config: any = {}) {
  return PostRequest('userprofile', {}, config);
} // APIFetchProfile
