
import { createColumn, formatAsDateTime, TableOptions } from '@/util/table';
import { zeropad } from '@/util/string';
import { TableView } from './TableModels';
import map from 'lodash/map';
import PaginationMixin from '@/mixins/PaginationMixin';

const dateFormat = (date: Date) => {
  const parts = [
    date.getFullYear(),
    zeropad(date.getMonth()),
    zeropad(date.getDate())
  ];
  return parts.join('-');
};

const dateTimeOptions: TableOptions = {
  format: (date: Date) => {
    const parts = [zeropad(date.getHours()), zeropad(date.getMinutes())];
    return dateFormat(date) + ' ' + parts.join(':');
  }
};

const createColumns = (view: TableView) =>
  [
    createColumn('id', 'PurchaseOrders.OrderID'),
    createColumn('company', 'PurchaseOrders.Company'),
    createColumn('store', 'PurchaseOrders.Store'),
    createColumn('requestDate', 'PurchaseOrders.RequestDate', formatAsDateTime),
    view >= TableView.WaitingFinalOrder
      ? createColumn(
          'reviewDate',
          'PurchaseOrders.ReviewedDate',
          formatAsDateTime
        )
      : false,
    view >= TableView.WaitingPayment
      ? createColumn(
          'finalizedDate',
          'PurchaseOrders.FinalizedDate',
          formatAsDateTime
        )
      : false,
    createColumn('requestTotal', 'PurchaseOrders.RequestGrandTotal'),
    view >= TableView.WaitingPayment
      ? createColumn('paymentMethod', 'PurchaseOrders.PaymentMethod')
      : false,
    view >= TableView.Finished
      ? createColumn(
          'installationSchedule',
          'Installation Schedule',
          dateTimeOptions
        )
      : false,
    createColumn('action', 'PurchaseOrders.Action')
  ].filter(Boolean);

export default {
  mixins: [PaginationMixin],
  props: {
    data: { type: Array, required: true },
    view: { type: Number, required: true }
  },
  data() {
    return {
      columns: createColumns(this.view)
    };
  },
  computed: {
    tableData() {
      return map(this.data, order => ({ ...order, action: '' }));
    }
  },
  watch: {
    view(value: TableView) {
      this.columns = createColumns(value);
    }
  }
};
