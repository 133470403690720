export default class {
  constructor(baseUrl, token) {
    this.baseUrl = baseUrl || "https://fida-stg.flow-insight.com";
    this.baseUrl = this.baseUrl + "/api/";
    this.token = token
    this.sensorData = [];
    this.sensors = {}
    this.data = {};
    this.callback = function () {};


  }

  formatDate(date, format, utc) {
    var MMMM = [
      "\x00",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var MMM = [
      "\x01",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dddd = [
      "\x02",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    function ii(i, len) {
      var s = i + "";
      len = len || 2;
      while (s.length < len) s = "0" + s;
      return s;
    }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H === 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
      tz = Math.abs(tz);
      var tzHrs = Math.floor(tz / 60);
      var tzMin = tz % 60;
      K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
  }

  _currentHour() {
    var date = new Date();
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return this.formatDate(date, "yyyy-MM-dd HH:mm:ss");
  }

  async fetchStores() {

    var url = new URL(this.baseUrl + "getKpiStores");
    url.searchParams.append("kpiType", "occupancy");
    url.searchParams.append("client_id", "aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688");
    url.searchParams.append("client_secret", "fp329-polk80s-ye04p1yy-45hx874z06");

    const rawResponse = await fetch(url.href, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": this.token
      },
    });
    return rawResponse.json();
  }


  async _fetchHourLink(sensors) {
    if (!sensors.length) {
      return;
    }
    const rawResponse = await fetch(this.baseUrl + "createSensorDataUrl", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sensors, type: "direct"}),
    });
    return rawResponse.json();
  }



  async _fetchHourData(link) {
    const rawResponse = await fetch(link, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (rawResponse.status == 200) {
      return rawResponse.json();
    }
    return {enters: 0, exits: 0, minOccupancy: 0, maxOccupancy: 0}
  }

  async _fetchLinks() {
    let _currentHour = this._currentHour();

    this.stores.forEach((e) => {
      if (!this.sensors[_currentHour]) {
        this.sensors[_currentHour] = e.sensorIds.map((e) => { return {_id: e, dateTime: _currentHour} })
      }
      delete e.data
    });
var check =0;
    if (this.sensors[_currentHour]) {
      var links = await this._fetchHourLink(this.sensors[_currentHour])
      links = links.response.data
      for(const i of links) {
        let data = await this._fetchHourData(i.url);
        this.sensorData[i._id] = data
        if(this.sensorData[i._id] === null)
        {
          check=1;
        }
      }
if(check == 0)
{
      this.stores.forEach((e) => {
        e.sensorIds.map((f) => {
          if (this.sensorData[f]) {
            if (!e.data) {
              e.data = []
            }
            e.data.push(this.sensorData[f])
          }
        })
      });


      this.stores.forEach((e) => {
        var enters = 0
        var exits = 0
        var minOccupancy = 0
        var maxOccupancy = 0
        e?.data?.forEach((f) => {
          enters += f.enters || 0
          exits += f.exits || 0
          minOccupancy += f.minOccupancy || 0
          maxOccupancy += f.maxOccupancy || 0
        })
        let entries = enters - exits;
        if(entries<0)
        {
          entries = 0
        }
        let occupancy = entries;
        let _maxOccupancy = e.occupancyConfig.maxOccupancy
        let occupancyRate = occupancy / _maxOccupancy
        let occupantPercentage = parseFloat((occupancy / _maxOccupancy) * 100).toFixed(2);
        var object = {enters, exits, occupancy, occupancyRate, isWarning: false, isUnsafe: false, occupantPercentage}

        let config = e.occupancyConfig.defaultWidget
        object.isWarning = occupantPercentage >= 0.7
        object.isUnsafe = occupantPercentage >= 0.9

        e.data = object

      })
      this.callback();
    }
  }
  }


  // start auto after history data
  async startUpdating() {
    if (!this.stores) {
      var stores = await this.fetchStores();
      this.stores = stores.response;
    }
    this.callback()
    await this._fetchLinks(); // DEBUG

    await new Promise((resolve) =>
      setInterval(() => resolve(this._fetchLinks()), 70000)
    ); // 70 secs
  }
}
