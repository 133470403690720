
import { mapState, mapActions, mapMutations } from 'vuex';
import { APICreatePreferences } from '@/api/kpi';

export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    } // elevated
  }, // props

  data() {
    return {
      search: '',
      markedOptions: [],
      listState: null,
       flag: false,
    };
  },
  computed: {
    ...mapState('store', ['stores']),
    ...mapState('filter', ['filter']),
    ...mapState('user', ['profile']),

    /**
     * List of options. It gets updated if the user operates an search.
     */
    searchResult() {
      if (this.search) {
        return this.options?.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.options;
      }
    }, // searchResult
    options() {
      if (this.stores) {
        let stores = this.stores.stores ?? [];
        // sort store by storeCode
        // stores.sort((a, b) => {
        //   return a.storeCode - b.storeCode;
        // });

        if(this.flag === false)
        {
         stores.sort((a, b) => {
            return a.storeCode - b.storeCode;
          });
          // this.flag= false;
        }
        else
        {
         stores.sort((a, b) => {
            return b.storeCode - a.storeCode;
          });
          // this.flag= false;
        }
        // else
        // {
        //   debugger;
        //   stores.sort((a, b) => {
        //     return b.storeCode - a.storeCode;
        //   });
        //   this.flag= true;
        // }


        return (
          stores?.map(store => {
              return {
                label: store.storeCode + ' -  ' + store.name,
                value: store.storeCode
              };
            })
          // filter store as per authorization
          // .filter(v => this.profile.stores.split(',').includes(v.value))
        );
      } // if (this.stores.regions)

      return [];
    }, // options
    loading() {
      if (this.options.length) {
        return false;
      } else {
        return true;
      }
    }
  }, // computed
  watch: {
    'filter.storeCodes': {
      handler: 'setStoreCodesFromHierarchy'
    }
  },
  async created() {
    // Set default selection
    await this.fetchStores();
    // this.markedOptions =
    //   this.selectedOptions.filter(v => this.profile?.stores?.includes(v)) || [];
    // this.markedOptions = this.selectedOptions;
    var allStoresList = this.stores?.stores?.map(s => s.storeCode);
   // this.selectedOptions =  allStoresList.filter(v => this.filter.storeCodes.includes(v)) || [];
      this.markedOptions  =  allStoresList?.filter(v => this.filter?.storeCodes?.includes(v)) || [];;//this.selectedOptions;
  }, // created

  methods: {
    ...mapActions('store', ['fetchStores']),
    ...mapMutations('filter', ['setStoreCodes']),

    /**
     * Mark all options as selected|
     */
    setStoreCodesFromHierarchy() {
      var allStoresList = this.stores?.stores?.map(s => s.storeCode);
      this.markedOptions  =  allStoresList?.filter(v => this.filter?.storeCodes?.includes(v)) || [];
      // this.markedOptions = this.filter.storeCodes;
    },
    markAllOptions() {
      this.markedOptions = this.options?.map(option => option.value);
    },
    /**
     * Fired when user click on 'All' checkbox
     */
    listStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markAllOptions();
      } else {
        // Unmark all checkboxes
        this.markedOptions = [];
      }
    }, // listStateInput
    /**
     * Fired when user click on any checkbox apart from 'All'
     */
    markedOptionsInput(newValue) {
      if (newValue.length === this.options.length) {
        // 'All' checkbox totally state
        this.listState = true;
      } else if (newValue.length === 0) {
        // 'All' checkbox empty state
        this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        this.listState = null;
      }
    }, // markedOptionsInput
    /**
     * Update selected options before closing the filter
     */
    submit() {
      var urlPage = window.location.pathname;
      // Check if minimum of 1 store is selected
      if (this.markedOptions.length === 0) {
        return false;
      }
      // Save preferences in localStorage
      // APICreatePreferences(urlPage, this.markedOptions.toString());
      APICreatePreferences(urlPage, this.markedOptions);
      // Updates the parent
      this.$emit('update:selectedOptions', this.markedOptions);
      this.setStoreCodes({ storeCodes: this.markedOptions });
      // Hide the filter
      this.$refs.filterStore.hide();
    }, // submit
    /**
     * Closes the filter without saving selected options
     */
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterStore.hide();
    }, // hide
    sortOptions() {
      if (this.stores) {
        let stores = this.stores.stores ?? [];
        if(this.flag === false)
        {
           this.flag= true;
        }
        else
        {
          this.flag= false;
        }
      }
    }
  }
};
