
import CommonMixin from '@/mixins/CommonMixin';
import { StoreOrder } from '@/models/Order';
import clone from 'lodash/clone';
import { generateImageDataUrl } from '@/util/file';

export default {
  mixins: [CommonMixin],
  props: {
    order: { type: Object, required: true }
  },
  methods: {
    async fetcher() {
      return await this.$repo.sensor.getSensorImageAsBase64(this.order.sensor);
    },
    formatter(input, url) {
      return generateImageDataUrl(input, url);
    },
    modifyQuantity(quantity: number, storeOrder: StoreOrder) {
      const newQuantity = storeOrder.quantity + quantity;
      if (newQuantity <= 0) return;
      storeOrder.quantity = newQuantity;
      this.$emit('update-order', clone(this.order));
    },
    removeStore(removed: StoreOrder) {
      const order = clone(this.order);
      const index = order.storeOrders.findIndex(
        storeOrder => storeOrder.store.id === removed.store.id
      );
      order.storeOrders.splice(index, 1);
      this.$emit('update-order', order);
    }
  }
};
