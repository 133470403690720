
import { mapActions, mapGetters } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import StoreForm from './StoreForm.vue';
import { handleErrorUI } from '@/util/error';

export default {
  components: {
    StoreForm
  },
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      store: {
        cityId: '',
        area: '0',
        areaUnit: 'SQM',
        floorLevel: 0,
        selectedDistrict: '',
        storeBusinessHours: [],
        storeFloorPlan: {
          ceilingHeight: '0',
          dimension: 0,
          numberOfEntrances: '0'
        },
        storeFloorPlanImages: [],
        status: ''
      },
      planModalShown: false,
      selectedStore: {},
      loading: false,
      isEdit: true
    };
  },
  computed: {
    ...mapGetters('tenant', ['hasResetAccount']),
    canSubmit: function() {
      return this.$refs.storeForm.validate();
    }
  },
  methods: {
    ...mapActions('tenant', ['markPlanSubscribed']),
    async submitStore() {
      this.loading = true;
      this.$refs.storeForm.dirty();
      try {
        if (this.canSubmit) {
          this.selectedStore = await this.$repo.store.createStore(this.store);
          // if (!this.hasResetAccount) {
          //   await this.markPlanSubscribed();
          //   this.planModalShown = true;
          // } else {
          await this.$router.push({ name: 'management:store' }).catch(() => {});
          this.$q.notify({
            type: 'positive',
            message: this.$t('Form.add_store_success_message'),
            position: 'top'
          });
          // }
        } else {
          this.$q.notify({
            type: 'negative',
            message: this.$t('Form.resolve_errors'),
            position: 'top'
          });
        }
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      } // finally
    }
  }
};
