import { AxiosInstance } from 'axios';
import { Country } from '@/models/Location';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';

export type GetCountriesResponse = PaginatedResponse<Country>;

export default class CountryController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getCountries() {
    try {
      const { data } = await this.client.get('/country', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 100,
          paged: false
        }
      });
      return data as GetCountriesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
