import { PaginatedResponse } from '@/models/Pagination';
import { AreaManagerDTO } from '@/models/AreaManager';
import { StoreDTO } from '@/models/Store';
import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { AxiosInstance } from 'axios';

export type GetAreaManagersResponse = PaginatedResponse<AreaManagerDTO>;
export type GetAreaManagerResponse = AreaManagerDTO;

export interface CreateAreaManagerRequest {
  name: string;
  parent?: string;
  ids: string[];
}

export interface CreateAreaManagerResponse {
  id: string;
  name: string;
  parentId: string;
  stores: StoreDTO[];
}

export default class AreaManagerController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getAreaManagers() {
    try {
      const { data } = await this.client.get('/storeareamanager');
      return data as GetAreaManagersResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getAreaManager(id: string) {
    try {
      const { data } = await this.client.get(`/storeareamanager/${id}`);
      return data as GetAreaManagerResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createAreaManager(req: any) {
    try {
      const { data } = await this.client.post('/storeareamanager', req);
      return data as any;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateAreaManager(id: string, req: any) {
    try {
      const { data } = await this.client.put(`/storeareamanager/${id}`, req);
      return data as CreateAreaManagerResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteAreaManager(id: string, msg: string) {
    return this.deleteAreaManagers([id], msg);
  }

  public async deleteAreaManagers(ids: string[], msg: string) {
    try {
      const data = await this.client.delete('/storeareamanager', {
        data: ids
      });
      data.statusText = msg;
      createBulkDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
