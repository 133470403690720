import axios from "axios";
import store from '@/store';
const { importFileValidations, readFile } = require('./fileValidator_temp.js');

export default {
 async importReportData(file, kpi,storeCodesList,lang) {
  const isValidRes = await importFileValidations(file, kpi, storeCodesList,lang);

  var isValid = isValidRes.message;
  const storeState = store.state;


  const token = storeState.user.sessionClient.access_token;
    if (isValid === 'Success' || isValid == 'success' || isValid === '成功')
    {

        const fileData = await readFile(file);

        var data = {};
        if(kpi == "sls")//sales
        {
          data = {
            kpi: "sls",
            // db: "mysql",
            data: fileData.slice(1).map(row => ({
              storeCode: row[0],
              date: row[1],
              time: row[2],
              salesAmount: row[5],
              totalTransactions: row[3],
              itemsSold: row[4]
            }))
          };
        }
        else if(kpi == "st")//salesTarget
        {
          data = {
            kpi: "st",
          //  db: "mysql",
            data: fileData.slice(1).map(row => ({
              storeCode: row[0],
              date: row[1],
              salesTarget: row[2],//?
            }))
          };
        }
        else if(kpi == "trf")//traffic, set param values
        {
          data = {
            kpi: "trf",
            // db: "mysql",
            data: fileData.slice(1).map(row => ({
              storeCode: row[0],
              entrance:row[3],
              date: row[1],
              time: row[2],
              inout: row[4],
              traffic: row[5]
            }))
          };
        }
        else if(kpi == "sh")//staffHOur set param values
        {

          data = {
            kpi: "sh",
            // db: "mysql",
            data: fileData.slice(1).map(row => ({
              storeCode: row[0],
              date: row[1],
              time: row[2],
              staffCount: row[3],//?
            }))
          };
        }
        else{
          return {
            message: 'Please contact support!'
          };
        }
        // return
        data.filename = file.name
        var url;
        const status1 = localStorage.getItem('status');

        if (status1 == 'staging')
          url = `https://import.flow-insight.com/api/importDataStg`;
        else
          url = `https://import.flow-insight.com/api/importData`;

        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response == "")
        {
          return isValidRes;
        }
        else
        {
          // isValidRes.message = response
          // isValidRes.successCount =0;
       //   const  successActualCount= Number(isValidRes.successCount) - Number(response.result.failedCount);
          if(response?.result?.failedReasons?.length>0) {
            isValidRes.message = response.result.failedReasons.join(', ');
          } else if (response?.message) {
            isValidRes.message = response.message
          } else {
            isValidRes.message= 'success';
          }


          isValidRes.successCount = response?.result?.successCount;
          isValidRes.failedCount = response.result?.failedCount;
          isValidRes.failedList = isValidRes.message;

          return isValidRes;
        }


        //console.log(response);
    }
    else {
      return isValidRes
    }
  },
};

