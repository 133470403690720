
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import DefaultSettingsMixin from '@/mixins/DefaultSettingsMixin';
import AreaFormMixin from '@/mixins/AreaFormMixin';
import DefaultSettings, {
  initialDefaultSettings
} from '@/models/onboarding/DefaultSettings';

export default {
  mixins: [CommonMixin, FormMixin, DefaultSettingsMixin, AreaFormMixin],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      data: initialDefaultSettings(),
      storeAreaUnits: [
        { label: this.$t('SettingsManagement.sqm'), value: 'SQM' },
        { label: this.$t('SettingsManagement.tsubo'), value: 'TSUBO' }
      ]
    };
  },
  watch: {
    value(val) {
      this.copyData(val);
    }
  },
  async created() {
    if (this.value) {
      this.copyData(this.value);
    }
  },
  methods: {
    copyData(value: DefaultSettings) {
      this.data = new DefaultSettings({ ...value });
    }
  }
};
