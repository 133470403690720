import { ClientManager } from '@/util/client';
import LogController from '@/services/tools/LogController';
import FTPSetupController from '@/services/tools/FTPSetupController';
import SalesTemplateController from '@/services/tools//SalesTemplateController';

export interface ToolsControllers {
  log: LogController;
  ftp: FTPSetupController;
  salesTemplate: SalesTemplateController;
}

export default (clientManager: ClientManager): ToolsControllers => ({
  log: new LogController(clientManager.coreClient),
  ftp: new FTPSetupController(clientManager.coreClient),
  salesTemplate: new SalesTemplateController(clientManager.coreClient)
});
