
import { BillingModel } from '@/models/Plan';
import PlanCard from '@/components/Management/Plan/PlanCard.vue';
import ConfirmDeleteDialog from '@/components/Common/Dialog/ConfirmDeleteDialog.vue';
import BillingModelToggle from '@/components/Management/Plan/BillingModelToggle.vue';

export default {
  components: {
    PlanCard,
    BillingModelToggle,
    ConfirmDeleteDialog
  },
  props: {
    loading: { type: Boolean, default: false },
    plans: { type: Array, default: () => [] },
    activePlanId: { type: String, default: null },
    disablePriceToggle: { type: Boolean, default: false },
    billingModel: { type: Number, default: BillingModel.MONTHLY }
  },
  data() {
    return {
      BillingModel: BillingModel,
      confirmDeletion: false
    };
  },
  methods: {
    isActive(planId) {
      if (this.activePlanId == null) return false;
      return this.activePlanId === planId;
    },
    onDelete(event) {
      this.$emit('cancelPlan', event);
    }
  }
};
