
import FormMixin from '../Mixins/FormMixin';

export default {
  mixins: [FormMixin],
  data: () => ({
    data: []
  }),
  async mounted() {
    const { users } = await this.$repo.user.getUsers();
    this.data = users;
  }
};
