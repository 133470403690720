
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getKpiTitle } from '@/util/utilFidaFunctions';
import {
  addMonths,
  addWeeks,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInDays
} from 'date-fns';
import { fetchOverview } from '../../fetchData';
import moment from 'moment';
import { addKpiCustomization } from '@/util/kpis';
import { getDateDifference } from '@/util/utilFidaFunctions';
import { addSign } from '@/util/replaceFidaKpi';

export default {
  data() {
    return {
      trendOrder: 4,
      currentTrend: '',
      loading: false,
      customTitles: [
        this.$t('KPI.sales'),
        this.$t('KPI.targetachievementrate'),
        this.$t('KPI.avgitemprice'),
        this.$t('KPI.upt'),
        this.$t('KPI.avgpurchase'),
        this.$t('KPI.conversion'),
        this.$t('KPI.transactions'),
        this.$t('KPI.traffic'),
        this.$t('KPI.passersby'),
        this.$t('KPI.salespersqm'),
        this.$t('KPI.trafficpersqm'),
        this.$t('KPI.capturerate'),
        this.$t('KPI.storedwelltime'),
        this.$t('KPI.engagementrate'),
        this.$t('KPI.bouncerate'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.stafflevel'),
        this.$t('KPI.staffhours'),
        this.$t('KPI.closingstrength'),
        this.$t('KPI.staffefficiency'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      kpiOverview: []
    };
  },
  computed: {
    ...mapState('trend', ['targetSelectedPeriod', 'targetComparedPeriod']),
    ...mapState('periodicReport', ['selectedStoreCode', 'weeklyTarget']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState',
      'getPulseEndingCompared'
    ]),
    ...mapState('user', ['kpiPreferences']),
    datesList() {
      return {
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared
      };
    }
  },
  watch: {
    datesList: {
      handler: 'loadKpiOverview',
      immediate: true
    },
    selectedStoreCode() {
      this.loadKpiOverview();
    },
    selectedKPIOptions() {
      this.loadKpiOverview();
    }
  },
  async created() {
    const dateDiff = getDateDifference(this.getPulseStartingPeriod,this.getPulseEndingPeriod);

    if(dateDiff <= 7)
    {
      if (this.selectedStoreCode) 
        this.loadKpiOverview();
      try {
        await this.fetchTargetAchive({
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseEndingPeriod,
          storeCodes: this.getStoreCodesList.split(','),
          target: true
        });
        // this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        // this.loading = false;
      }
      try {
        await this.fetchTargetAchive({
          startDate: this.getPulseStartingCompared,
          endDate: this.getPulseEndingCompared,
          storeCodes: this.getStoreCodesList.split(','),
          target: false
        });
        // this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        // this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('trend', ['fetchTargetAchive']),
    ...mapActions('overview', ['fetchOverview']),
    ...mapActions('periodicReport', ['getTargetTableValueWeekly']),
    ...mapMutations('periodicReport', ['setWeeklyLoading', 'setWeeklyRadar']),
    addSigns(kpi,val){
        return addSign(kpi,val);
      },
  
   kpiSorter(x, y) {
      return (
        this.kpiPreferences.findIndex(k => k.metricKey === x.metricID) -
        this.kpiPreferences.findIndex(k => k.metricKey === y.metricID)
      );
    },
    addTargetAchivment(sales, target) {
      var isNumber = x => {
        if (Number.isNaN(x) || x === Infinity || x === -Infinity) {
          return 0;
        } else {
          return x;
        }
      };
      //
      var formula = value => {
        var num = sales?.replaceAll('¥', '');
        var remCma = num?.replaceAll(',', '');
        return isNumber((parseInt(remCma) / value) * 100);
      };
      return formula(target).toFixed(2);
    },

    loadKpiOverview: async function() {
      const dateDiff = getDateDifference(this.getPulseStartingPeriod,this.getPulseEndingPeriod);
      if(dateDiff <= 7)
      {
      this.loading = true;

      this.setWeeklyLoading({ component: 'kpiAchievement', value: false });
      const kpiOverview = await fetchOverview({
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared,
        storeCodes: this.selectedStoreCode,
        kpis: this.selectedKPIOptions,
        daysOfWeek: this.getDaysOfWeekList,
        groupByMetricKey: true
      });

      //this.$t 
      let addTranslation = kpiOverview.map(kpi => {
        let title;
        try {
          title = getKpiTitle(kpi.metricID);
        } catch (error) {
          // Handle the exception here, you can log the error or provide a default title
          console.error("Error finding title:", error);
          title = "Default Title"; // Or any other fallback value
        }
        return { ...kpi, title };
      });

      this.kpiOverview = [...addTranslation]
        .sort(this.kpiSorter)
        .filter(el => this.selectedKPIOptions.includes(el.metricID));

      this.loading = false;
      this.setWeeklyLoading({ component: 'kpiAchievement', value: false });
      }
    }
  }
};
