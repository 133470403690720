import { PaginatedResponse } from '@/models/Pagination';
import Reference from '@/models/Reference';
import { RoleDTO } from '@/models/Role';
import { UserDTO } from '@/models/User';
import {
  createBackendError,
  createBulkDeleteBackendError,
  getErrorCodes
} from '@/util/error';
import { PaginationRequest } from '@/util/pagination';
import { AxiosInstance } from 'axios';
import store from '@/store';
import router from '@/router';
import { Notify } from 'quasar';
import i18n from '@/plugins/i18n';
import { fetchClass } from '@/plugins/fetch';
import {
  APIGetUserDetailsSSO,
  APIGetUsersSSO,
  APIStatusUpdateSSO,
  APIUserUpdateSSO,
  APIUserRoleUpdateSSO,
  APIInviteUserRequestSSO,
  APIReInviteUserRequestSSO,
  APIGetProfilePictureSSO,
  APIUpdateProfilePictureSSO
} from '@/api/authSSO';
import Vue from 'vue';

export type GetUserResponse = UserDTO;
export type GetUsersResponse = PaginatedResponse<UserDTO>;
export type GetRolesResponse = PaginatedResponse<RoleDTO>;

export interface InviteUserRequest {
  approvalPrivilege: boolean;
  email: string;
  firstname: string;
  lastname: string;
  tenant: string;
  username?: string;
  phone: string;
  role: {
    id: string;
  };
  stores: Reference[];
}

export interface UpdateUserRequest {
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  phone: string;
  role: {
    id: string;
  };
  stores: Reference[];
}

export default class UserController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getUsers(pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/users', {
        params: {
          ...pagination
        }
      });
      return data as GetUsersResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
  public async getUsersSSO() {
    try {
      const response = await APIGetUsersSSO({});
      return response.response;
    } catch (err) {
      throw createBackendError(err);
    }
  }
  public async getDefaultSettings() {
    try {
      const { data } = await this.client.get(`core/default-settings`);
      return data;
    } catch (err) {
      if (err) {
        await store.dispatch('user/clearSession');
        await store.dispatch('tenant/reset');
        await router.push({ name: 'auth:login' }).catch(_ => { });
        localStorage.clear();
        Notify.create({
          type: 'negative',
          position: 'top',
          message: err
        });
      }
      throw createBackendError(err);
    }
  }

  public async getUserSSO(_id: string) {
    try {
      const res = await APIGetUserDetailsSSO(
        _id !== 'me' ? { body: { _id } } : {}
      );
      const {weekStartDay} = res?.response?.userDetails?.tenant?.config;
      store.commit('user/setStartWeek', weekStartDay)
      return res;
    } catch (err) {
      if (err) {
        store.dispatch('user/clearSession');
        store.dispatch('tenant/reset');
        router.push({ name: 'auth:login' }).catch(_ => { });
        localStorage.clear();
        Notify.create({
          type: 'negative',
          position: 'top',
          message: `Username or password is incorrect`
        });
      }
      throw createBackendError(err);
    }
  }

  public async getUser(id: string) {
    try {
      const { data } = await this.client.get(`/users/${id}`);
      return data as UserDTO;
    } catch (err) {
      if (err) {
        store.dispatch('user/clearSession');
        store.dispatch('tenant/reset');
        router.push({ name: 'auth:login' }).catch(_ => { });
        localStorage.clear();
        Notify.create({
          type: 'negative',
          position: 'top',
          message: `Username or password is incorrect`
        });
      }
      throw createBackendError(err);
    }
  }

  public async getRoles() {
    try {
      const { data } = await this.client.get('/users-roles', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetRolesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getRolesSSO() {
    try {
      const data = await fetchClass(
        '/getRoles',
        {
          params: {
            offset: 0,
            pageNumber: 1,
            pageSize: 10,
            paged: false
          }
        },
        {}
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async inviteUser(req: any) {
    try {
      const response = await APIInviteUserRequestSSO({ body: req });
      return response;
    } catch (err) {
      throw err;
    }
  }

  public async reInvite(req: any) {
    try {
      const { response } = await APIReInviteUserRequestSSO({ body: req });
      return response;
    } catch (err) {
      throw err;
    }
  }

  public async getProfilePictureSSO() {
    try {
      const response = await APIGetProfilePictureSSO({});
      return response;
    } catch (err) {
      throw err;
    }
  }

  public async updateUserInvitation(id: string, req: InviteUserRequest) {
    try {
      const { data } = await this.client.put(`/users/invite/${id}`, req);
      return data as UserDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateUser(id: string, req) {
    try {
      const { response } = await APIUserUpdateSSO({ id, body: req });
      return response;
      // return data as UpdateUser;
    } catch (err) {
      throw createBackendError(err);
    }
  }
  public async updateProfilePicture(file: any, formate: string) {
    try {
      const { response } = await APIUpdateProfilePictureSSO({ file, body: formate });
      return response;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateUserRole(_id: string, roleId: string) {
    try {
      const { response } = await APIUserRoleUpdateSSO({
        body: { _id, roleId }
      });
      return response;
      // return data as UpdateUser;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteUser(ids: string[]) {
    try {
      await this.client.delete('/users', {
        data: ids
      });
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteUserSSO({ id }) {
    try {
      const response = await APIStatusUpdateSSO({ id });
      return response.response.users;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async restoreUser(id) {
    try {
      await this.client.put('/users/restore/' + id);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getImportTemplate() {
    try {
      const { data } = await this.client.get('/users/import/template');
      return data as BlobPart;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async importUsers(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.client.post('/users/import', formData);
    return data;
  }

  public async userIsDisabled(email: string) {
    try {
      const { data } = await this.client.post('/users/is-disable', {
        username: email
      });
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
