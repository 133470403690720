
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('user', ['locale']),
    faqLink() {
      return `https://intercom.help/flow-solutions/${
        this.locale === 'en-us' ? 'en' : 'ja'
      }/`;
    }
  }
};
