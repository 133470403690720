
const columns = [
  { name: "updatedOn", align: "center", label: "Updated On", field: "updatedOn", sortable: true },
  {
    name: "fileName",
    align: "left",
    label: "File Name/ Application Name",
    field: "fileName",
    sortable: true,
  },
  { name: "status", label: "Status", field: "status" },
  { name: "detail", label: "Detail", field: "detail" },
  { name: "importedMethod", label: "Imported Method", field: "importedMethod" },
  { name: "filePath", label: "File Path", field: "filePath" },
  { name: "name", label: "Name", field: "name" },
  { name: "uploadedBy", label: "Uploaded By", field: "uploadedBy" },
];

const rows = [
  {
    updatedOn: "erer",
    fileName: 1,
    status: "Store",
    detail: "Store",
    importedMethod: "Store",
    filePath: "Store",
    name: "Store",
    uploadedBy: "Store",
  },
  {
    updatedOn: "erer",
    fileName: 1,
    status: "Store",
    detail: "Store",
    importedMethod: "Store",
    filePath: "Store",
    name: "Store1",
    uploadedBy: "Store",
  },
];

export default {
  data () {
    return {
      openModel: false,
      rows: rows,
      columns: columns,
      pagination: {
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
    }
  },
  computed: {
  },
  methods: {
    closeModel() {
      this.openModel = false;
    }
  }
};
