
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      selected: {},
      recommended: [],
      integrations: [],
      integrationSelected: {}
    };
  },
  computed: {
    ...mapState('marketplace', [
      'integrationRecommended',
      'integration',
      'pricing',
      'selectedTag'
    ]),
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    pricing() {
      // this.hardwares= this.iot.map(v=>)
      if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() === 'free'
      ) {
        this.integrations = this.thirdParty.filter(v => {
          return v.rate.toLowerCase() === 'free';
        });
      } else if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() !== 'free'
      ) {
        this.integrations = this.thirdParty.filter(
          v => v.rate.toLowerCase() !== 'free'
        );
      } else {
        this.integrations = this.thirdParty;
      }
    },
    selectedTag() {
      this.integrations = this.thirdParty.filter(v =>
        [v.label.toLowerCase(), ...v.tags].includes(this.selectedTag)
      );
    }
  },
  created() {
    this.fetchIntegrationRecommended();
    this.fetchIntegration();
    this.integrations = this.integration;
    this.recommended = this.integrationRecommended;
  },
  methods: {
    ...mapActions('marketplace', [
      'fetchIntegrationRecommended',
      'fetchIntegration'
    ]),
    openDialog(int) {
      this.selected = int;
      this.show = true;
    },
    getImgUrl(pic) {
      return require('../../../assets/img/icons/' + pic + '.png');
    }
  }
};
