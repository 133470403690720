
import moment from 'moment';
import stores from '@/services/stores';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedSensor', 'sensors'],
  data() {
    return {
      features: [],
      confirm: false,
      option: 'view data',
      startDate: null,
      endDate: null,
      details: {
        startHour: null,
        endHour: null,
        macAddress: null,
        serialNumber: null,
        estimate: true,
        viewData: false,
        entranceId: null
      },
      //
      configuration: false,
      loadingBtn: false,
      loadedSensor: {}
    };
  },
  computed: {
    getSensors() {
      const sensors = this.sensors.map(el => el.serialNumber);
      return sensors || [];
    },
    getMacAddress() {
      const sensors = this.sensors.map(el => el.macAddress);
      return sensors || [];
    },
    getEntrance() {
      const sensors = this.sensors.map(el => el.entranceId);
      return sensors || [];
    },
    title() {
      return this.$store.state.user.translate.add_sensor_details;
    },
    getHours() {
      const hoursD = [];
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? '0' + i : i;
        hoursD.push(hour.toString());
      }
      return hoursD;
    }
  },
  watch: {
    'details.serialNumber': {
      handler(value) {
        this.features = this.sensors?.find(
          el => el.serialNumber === value
        )?.features;
        this.details.macAddress = this.sensors?.find(
          el => el.serialNumber === value
        )?.macAddress;
      },
      deep: true
    },
    'details.macAddress': {
      handler(value) {
        this.features = this.sensors?.find(
          el => el.macAddress === value
        )?.features;
        this.details.serialNumber = this.sensors?.find(
          el => el.macAddress === value
        )?.serialNumber;
      },
      deep: true
    },
    // assign sensor
    configuration(newValue) {
      if (newValue) {
        this.features = this.selectedSensor?.features;
        this.details.serialNumber = this.selectedSensor.serialNumber;
        this.details.macAddress = this.selectedSensor.macAddress;
        this.details.entranceId = this.selectedSensor.entranceId;
      }
    }
  },

  methods: {
    resetState() {
      this.startDate = null;
      this.endDate = null;
      this.details = {
        startHour: null,
        endHour: null,
        macAddress: null,
        serialNumber: null,
        estimate: true,
        viewData: false
      };
      this.$parent.selectedStore = [];
    },
    verifyData() {
      const f = 'YYYY-MM-DD';
      const openDate = new Date(this.startDate);
      const closeDate = new Date(this.endDate);
      if (openDate > closeDate) {
        this.$q.notify({
          message: 'invalid End Date',
          color: 'red'
        });
        return;
      }
      if (!this.option) {
        this.$q.notify({
          message: 'Please Select Missing feilds',
          color: 'red'
        });
        return;
      }
      if (parseInt(this.details.startHour) > parseInt(this.details.endHour)) {
        this.$q.notify({
          message: 'Invalid End Hour',
          color: 'red'
        });
        return;
      }
      if (this.option === 'view data') {
        this.details.viewData = true;
        this.details.estimate = true;
      } else if (this.option === 'Reload Data') {
        this.details.viewData = false;
        this.details.estimate = false;
      } else if (this.option === 'Raw Data') {
        this.details.viewData = true;
        this.details.estimate = false;
      } else {
        this.details.viewData = false;
        this.details.estimate = true;
      }
      this.details.startDate = moment(this.startDate).format(f);
      this.details.endDate = moment(this.endDate).format(f);
      this.confirm = true;
    },
    async downloadFile(url, fileName) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        //
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    async callApi() {
      this.loadingBtn = true;
      try {
        const resp = await stores.updateDeviceData(this.details);
        let data =
          resp?.data?.response?.response?.response ??
          resp?.data?.response?.response;
        if (data && this.details.viewData) {
          const res = data;
          if (res && res.length) {
            res.forEach(el => {
              let url = '';
              url = el && el[0] && el[0].data ? el[0].data : el.data;
              if (!url) return;
              let fileName = url.substring(url.lastIndexOf('/') + 1);
              this.downloadFile(url, fileName);
            });
          }
        }
        this.$q.notify({
          message: this.$store.state.user.translate.updated_successfully,
          color: 'green'
        });
        // this.configuration = false;
        // this.resetState();
        this.loadingBtn = false;
      } catch (error) {
        this.loadingBtn = false;
      }
    },
    async getUrl(url) {
      const w = await fetch(url);
      return w;
    },
    preventSpace(event) {
      if (event.keyCode === 32) {
        event.preventDefault();
        setTimeout(() => {
          this.$refs.mac.applyResult({
            errors: 'space is not allowed', // array of string errors
            valid: true, // boolean state
            failedRules: {} // should be empty since this is a manual error.
          });
        }, 500);
      }
    },
    validateMac() {
      if (!this.isValidMACAddress(this.details.macAddress)) {
        setTimeout(() => {
          this.$refs.mac.applyResult({
            errors: 'Invalid Mac Address', // array of string errors
            valid: true, // boolean state
            failedRules: {} // should be empty since this is a manual error.
          });
        }, 500);
      }
    },

    isValidMACAddress(str) {
      // Regex to check valid
      // MAC_Address
      let regex = new RegExp(
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/
      );

      // if str
      // is empty return false
      if (str == null) {
        return false;
      }

      // Return true if the str
      // matched the ReGex
      if (regex.test(str) == true) {
        return true;
      } else {
        return false;
      }
    }
  }
};
