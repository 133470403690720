
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("periodicReport", ["filterFormState"]),
  },
  methods: {},
};
