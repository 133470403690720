
import { mapState } from 'vuex';
export default {
  props: {
    charttitle: {
      type: String,
      default: ''
    },
    categories: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {},
      categoryData: []
    };
  },
  computed: {
    ...mapState('user', ['locale'])
  },
  watch: {
    locale(newValue) {
      this.setChartOptions();
    },
    categories() {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.categories
        }
      };
    }
  },
  created() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      this.chartOptions = {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        title: {
          // text: this.charttitle,
          align: 'left',
          offsetX: 110
        },
        colors: ['#008FFB', '#898989', '#feb019'],
        xaxis: {
          categories: this.categoryData
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB'
              }
            },
            title: {
              style: {
                color: '#008FFB'
              }
            },
            tooltip: {
              enabled: true
            }
          },
          {
            seriesName: `${this.$i18n.t('In')}`,
            opposite: true,
            show: false,
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: true,
              color: '#5f5f5f'
            },
            labels: {
              show: false,
              style: {
                colors: '#5f5f5f'
              }
            },
            title: {
              style: {
                color: '#5f5f5f'
              }
            }
          },
          {
            seriesName: `${this.$i18n.t('Occupancy')}`,
            opposite: true,
            show: false,
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: true,
              color: '#FEB019'
            },
            labels: {
              show: false,
              style: {
                colors: '#FEB019'
              }
            },
            title: {
              style: {
                color: '#FEB019'
              }
            },
            yaxis: {
              labels: {
                show: false
              }
            }
          },
          {
            seriesName: `${this.$i18n.t('Out')}`,
            show: false
          }
        ],
        tooltip: {
          enabled: true,
          y: {
            title: {
              formatter: seriesName => {
                if (seriesName === 'In') {
                  return `${this.$i18n.t('In')}`;
                } else if (seriesName === 'Out') {
                  return `${this.$i18n.t('Out')}`;
                } else if (seriesName === 'Occupancy') {
                  return `${this.$i18n.t('Occupancy')}`;
                }
              }
            }
          }
          // fixed: {
          //   enabled: true,
          //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          //   offsetY: 30,
          //   offsetX: 60
          // }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: 40,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          },
          formatter: (seriesName, opts) => {
            if (seriesName === 'In') {
              return `${this.$i18n.t('In')}`;
            } else if (seriesName === 'Out') {
              return `${this.$i18n.t('Out')}`;
            } else if (seriesName === 'Occupancy') {
              return `${this.$i18n.t('Occupancy')}`;
            }
            // return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
          }
        }
      };
    }
  }
};
