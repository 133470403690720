import { convertFromMenuDTO } from '@/models/formatters/MenuFormatter';
import { convertFromRoleDTO } from '@/models/formatters/RoleFormatter';
import { convertFromUserDTO } from '@/models/formatters/UserFormatter';
import Menu from '@/models/Menu';
import Role from '@/models/Role';
import Store from '@/models/Store';
import User from '@/models/User';
import localForage from 'localforage';
import MenuController from '@/services/management/MenuController';
import UserController from '@/services/management/UserController';
import { Pagination, PaginationResponse } from '@/util/pagination';
import map from 'lodash/map';
import { debug } from 'webpack';
const STORED_UPLOAD_RESULT_PREFIX = 'user';

export interface GetUsersResult {
  data: User[];
  pagination: PaginationResponse;
}

export interface GetRolesResult {
  roles: Role[];
}

export interface GetMenusResult {
  menus: Menu[];
}

export interface UserInvitation {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  tenant: string;
  phone: {
    countryCode: string;
    phoneNumber: string;
  };
  roleId: string;
  stores: Store[];
}

export interface UpdateUser {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  phone: string;
  roleId: string;
  stores: Store[];
}

export default class UserRepository {
  private readonly userController: UserController;
  private readonly menuController: MenuController;

  constructor(userController: UserController, menuController: MenuController) {
    this.userController = userController;
    this.menuController = menuController;
  }

  public async getUsers(pagination: Pagination = new Pagination()) {
    const { content, ...meta } = await this.userController.getUsers(
      pagination.toRequest()
    );
    return {
      data: map(content, dto => convertFromUserDTO(dto)),
      pagination: meta
    } as GetUsersResult;
  }

  public async getDefaultSettingsDetails() {
    const val = await this.userController.getDefaultSettings();
    return val.content[0];
  }

  public async getMenus() {
    const res = await this.menuController.getMenus();
    return {
      menus: map(res.content, dto => convertFromMenuDTO(dto))
    } as GetMenusResult;
  }

  public async inviteUser(invitation: any) {
    return await this.userController.inviteUser({
      email: invitation.email,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      username: invitation.username,
      phoneCountryCode: invitation.phone.phoneCountryCode,
      phoneNumber: invitation.phone.phoneNumber,
      roleId: invitation.roleId
    });
  }

  public async reInvite(invitation: any) {
    await this.userController.reInvite({
      email: invitation.email
    });
  }

  public async updateUserInvitation(id: string, invitation: UserInvitation) {
    await this.userController.updateUserInvitation(id, {
      approvalPrivilege: true,
      email: invitation.email,
      firstname: invitation.firstName,
      lastname: invitation.lastName,
      tenant: invitation.tenant,
      phone: `${invitation.phone.countryCode} ${invitation.phone}`,
      role: {
        id: invitation.roleId
      },
      stores: invitation.stores.map(store => ({
        ref: store.id
      }))
    });
  }

  public async deleteUser(id: string) {
    await this.userController.deleteUser([id]);
  }

  public async restoreUser(id: string) {
    await this.userController.restoreUser(id);
  }

  public async getImportTemplate() {
    return await this.userController.getImportTemplate();
  }

  public async importUsers(file: File) {
    return await this.userController.importUsers(file);
  }

  public async saveImportResult(id: string, result: any) {
    await localForage.setItem(`${STORED_UPLOAD_RESULT_PREFIX}::${id}`, result);
  }

  public async getImportResult(id: string) {
    return await localForage.getItem(`${STORED_UPLOAD_RESULT_PREFIX}::${id}`);
  }

  public async deleteUserSSO(params: any) {
    await this.userController.deleteUserSSO(params);
  }

  public async getUsersSSO() {
    const res = await this.userController.getUsersSSO();
    return res;
  }

  public async getUser(id: string) {
    const res = await this.userController.getUserSSO(id);
    return res;
  }

  public async getProfilePicture() {
    const res = await this.userController.getProfilePictureSSO();
    var resImg = '';
    if (res != null && res != '{}') {
      resImg = res.response.profileImage;
    }
    return resImg;
  }

  public async updateProfilePicture(file: any, formate: string) {
    const dto = await this.userController.updateProfilePicture(file, formate);
    return dto;
  }

  public async getRoles() {
    const res = await this.userController.getRolesSSO();
    /* return {
      roles: map(res.content, dto => convertFromRoleDTO(dto))
    } as GetRolesResult; */
  }
  public async updateUser(id: string, user: any) {
    const dto = await this.userController.updateUser(id, user);
    return dto;
  }

  public async updateUserRole(id: string, roleId: string) {
    const dto = await this.userController.updateUserRole(id, roleId);
    return dto;
  }
}
