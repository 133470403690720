
import { Pagination } from '../../../util/pagination';

export default {
  name: 'StoresCheckbox',
  props: {
    selectedStores: { default: () => [], type: Array }
  },
  data() {
    return {
      cachedStoreList: [],
      search: '',
      markedOptions: [],
      listState: null,
      storesList: [],
      selectableStores: []
    };
  },
  computed: {
    searchResult() {
      if (this.search) {
        return this.selectableStores.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.selectableStores;
      }
    }, // searchResult
    label() {
      if (this.hideLabel) return null;
      return this.$t('SettingsManagement.store');
    },
    valueShown() {
      if (!this.showValue || this.value == null || this.value.length === 0)
        return null;
      const names = this.value.map(store => store.name);
      return names.join(', ');
    },
    placeholderShown() {
      if (this.value != null && this.value.length > 0) return null;
      return this.$t('type_store');
    }
  },
  watch: {
    selectedStores() {
      if (this.storesList.length > 0) {
        this.markedOptions = this.selectedStores;
        const selectedStoreIds = this.selectedStores.map(store => store.id);
        // this.storesList.sort((a, b) => {
        //   return a.storecode - b.storecode;
        // });
        this.selectableStores = this.storesList
          .filter(store => selectedStoreIds.indexOf(store.id) < 0)
          .map(v => ({
            value: v,
            label: `${v.storecode} - ${v.name}`
          }));
        this.cachedStoreList = this.selectableStores.map(v => ({
          value: v,
          label: `${v.storecode} - ${v.name}`
        }));
      }
    }
  },
  async created() {
    await this.loadStores();
  },
  methods: {
    hide() {
      this.markedOptions = [];
      this.$refs.filterStore.hide();
    },
    listStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markAllOptions();
      } else {
        // Unmark all checkboxes
        this.markedOptions = [];
      }
    }, // listStateInput
    async loadStores() {
      const request = new Pagination();
      const store = await this.$repo.store.getStores(request);
      this.storesList = store['data'].filter(store => {
        return store.status !== 'DISABLE';
      });
      if (this.selectedStores.length >= 0) {
        const selectedStoreIds = this.selectedStores.map(store => store.id);

        // this.$emit('selectStores', []);
        this.markedOptions = this.storesList
          .filter(store => selectedStoreIds.indexOf(store.id) >= 0)
          .map(v => ({
            value: v,
            label: `${v.storecode} - ${v.name}`
          }));
        this.$emit(
          'selectStores',
          this.markedOptions.map(v => v.value)
        );
        this.selectableStores = this.storesList
          .filter(store => selectedStoreIds.indexOf(store.id) < 0)
          .map(v => {
            return {
              value: v,
              label: `${v.storecode} - ${v.name}`
            };
          });
        this.cachedStoreList = this.selectableStores.map(v => ({
          value: v,
          label: `${v.storecode} - ${v.name}`
        }));
      }
    },
    markAllOptions() {
      this.markedOptions = this.storesList;
    },
    submit() {
      // Check if minimum of 1 store is selected
      // if (this.markedOptions.length === 0) {
      //   return false;
      // }
      // Updates the parent
      // this.value = this.markedOptions.map(v => v.id);
      // Hide the filter
      this.$emit('selectStores', this.markedOptions);
      this.$refs.filterStore.hide();
    }, // submit
    markedOptionsInput(newValue) {
      if (newValue?.length === this.options?.length) {
        // 'All' checkbox totally state
        this.listState = true;
      } else if (newValue?.length === 0) {
        // 'All' checkbox empty state
        this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        this.listState = null;
      }
    } // markedOptionsInput
  }
};
