
import { QPagination } from 'quasar';

export default {
  functional: true,
  render: (h, context) => {
    const { $style, props } = context;
    const pagination = h(QPagination, { props });
    const options = {
      attrs: {
        class: $style.wrapper
      }
    };
    return h('div', options, [pagination]);
  }
};
