
import Button from '@/components/Common/Button';
import EmployeeTable from './EmployeeTable';
import { mapState } from 'vuex';

export default {
  components: {
    'm-btn': Button,
    'employee-table': EmployeeTable
  },
  props: {
    selectedRoleIndex: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    data: []
  }),
  async mounted() {
    const { users } = await this.$repo.user.getUsers();
    this.data = users;
  },
  methods: {}
};
