
import Vue from 'vue';
import { colors, granularities, kpis } from '@/util';
import { APIFetchKPIAnalysis } from '@/api/compare';
import { formatYAxis } from '@/util/legend';
import { addKpiCustomization, getKpiKeyByName } from '@/util/kpis';
import moment from 'moment'
//import { addSign } from '@/util/replaceFidaKpi';
export default Vue.extend({
  props: {
    stores: {
      type: Array,
      required: true
    },
    kpi: {
      type: String,
      required: true
    },
    period: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      analysisType: 'daily',
      loading: false,
      overviewSelection: 'region',
      series: [],
      titles:[],
      chartOptions: {
        tooltip:{
          x:{
            formatter: (value,{ series, seriesIndex, dataPointIndex, w }) => {
                  return `${this.titles[dataPointIndex]}`
              }
          }
        },
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: colors.secondary,
        stroke: {
          width: 2,
          curve: 'straight',
          dashArray: 0
        },
        title: {
          // text: 'Product Trends by Month',
          // align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [],
           tickAmount: 50,
        },
        legend: {
          position: 'top',
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        }
      }
    };
  },
  computed: {
    granularityOptions() {
      return granularities.map(granularity => ({
        label: this.$t(`Date.${granularity}`),
        value: granularity
      }));
    },
    kpis() {
      return kpis.map(kpi => ({
        label: this.$t(`KPI.${kpi}`),
        value: kpi
      }));
    }
  },
  watch: {
    async analysisType() {
      this.loading = true;
      await this.loadKpiMetrics();
      this.loading = false;
    }
  },
  async created() {
    this.loading = true;
    await this.loadKpiMetrics();
    this.loading = false;
  },
  methods: {
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('Date.Sun');
      }
      if (dayVal === 1) {
        return this.$t('Date.Mon');
      }
      if (dayVal === 2) {
        return this.$t('Date.Tue');
      }
      if (dayVal === 3) {
        return this.$t('Date.Wed');
      }
      if (dayVal === 4) {
        return this.$t('Date.Thu');
      }
      if (dayVal === 5) {
        return this.$t('Date.Fri');
      }
      if (dayVal === 6) {
        return this.$t('Date.Sat');
      }
    },

    whichKPI(kpiLabel) {

      return getKpiKeyByName(kpiLabel);

      // return this.kpis.find(element => {
      //   debugger;
      //   if (
      //     this.$t(`KPI.${kpiLabel.toLowerCase()}`) ===
      //     `KPI.${kpiLabel.toLowerCase()}`
      //   ) {
      //     return element.label === kpiLabel;
      //   }
      //   return element.label === this.$t(`KPI.${kpiLabel.toLowerCase()}`);
      // });
    },
    async loadKpiMetrics() {
      let lang = this.$store.state.user.locale;
      this.titles = []
      let _series = await Promise.all(
        this.stores.map(async store => {
          const { data } = await APIFetchKPIAnalysis(
            {
              startDate: this.period.start,
              endDate: this.period.end,
              compareStartDate: this.period.start,
              compareEndDate: this.period.end,
              selectedKPI: this.whichKPI(this.kpi).value,//this.kpi,
              compareKPI: this.whichKPI(this.kpi).value,//this.kpi,
              groupByMetricKey: false,
              storeCodes: store.storeCode,
              type: this.analysisType
            },
            this.analysisType
          );

          if (data && data.selectedKPITrend) {
            return { ...store, series: data.selectedKPITrend.selectedPeriod };
          }
        })
      );
      let columns = [];
      let values = [];
      let formatedString = (val, frmt="MMM DD") => {
        let findStart = val.indexOf('(');
        let findEnd = val.indexOf(')');
        let weekString = val.slice(0,findStart)
        let dateString = val.slice(findStart+1, findEnd);
        let datesWeek = dateString.split(' - ');
        let dateStartW = moment(datesWeek[0]).locale(lang).format(frmt)
        let dateEndW = moment(datesWeek[1]).locale(lang).format(frmt)
        let stringWeek = `${weekString} (${dateStartW} - ${dateEndW})`
        return stringWeek
      }
      let formatLabel = label => {
        if (this.analysisType === 'daily') {
          // label = label + `(${this.getFullDay(label)})`;
          label = moment(label).locale(lang).format(lang == 'ja' ?'M月DD日': 'MMM DD');
        } else if (this.analysisType === 'monthly') {
          label = this.$t(`FilterBar.${label}`) ;
        } else if (this.analysisType === 'weekly') {
          label = formatedString(label,lang == 'ja' ?'M月DD日': 'MMM DD')
            .replace('wk-', this.$t('FilterBar.week') + ' ')
            .replace('week', this.$t('FilterBar.week'));
        }
        return label;
      };
      let formatLabel2 = label => {
        if (this.analysisType === 'daily') {
          label = moment(label).locale(lang).format('ll') + ` (${this.getFullDay(label)})`;;
        } else if (this.analysisType === 'monthly') {
          label = this.$t(`FilterBar.${label}`) ;
        } else if (this.analysisType === 'weekly') {
          label = formatedString(label,'ll')
            .replace('wk-', this.$t('FilterBar.week') + ' ')
            .replace('week', this.$t('FilterBar.week'));
        }
        return label;
      };

      _series[0]?.series?.forEach(s => {
        columns.push(formatLabel(s.timelineLabel));
        this.titles.push(formatLabel2(s.timelineLabel));
      });

      _series.forEach(s => {
        // TODO: Needs to mount Average
        values.push({
          name: s.storeName,
          data: s.series.map(({ formattedMetricValue }) =>
            formattedMetricValue.replace(/[^\d.-]/g, '')
          ),
          
        });
      });

      this.$refs.chart.updateOptions({
        title: {
          text: `${this.$t('KpiAnalysis.current_kpi')}: ${
            this.whichKPI(this.kpi).label
          }`,
          align: 'center'
        },
        xaxis: {
          categories: columns,
          tickAmount: 50,
        },
        yaxis: {
          labels: {
            formatter: value => {
              return (
                value.toLocaleString() //+ this.$t(`unit.${this.whichKPI(this.kpi).value}Unit`)
              );
            }
          }
        }
      });
      this.$refs.chart.updateSeries(values);
    }
  }
});
