import { APIFetchExpectations } from '@/api/expectation';

const initialState = {
  expectations: {}
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setExpectations(state, { expectations }) {
      state.expectations = expectations;
    } // setExpectations
  }, // mutations
  actions: {
    async fetchExpectations({ commit }) {
      const res = await APIFetchExpectations({});

      commit('setExpectations', { expectations: res.data.wte });

      return res;
    } // fetchExpectations
  } // actions
}; // export default
