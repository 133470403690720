import { TenantConfig } from '@/models/Tenant';
import { CoreService, OnboardingService } from '@/services';
import TenantController from '@/services/account/TenantController';

import GeneralController from '@/services/core/GeneralController';
import ProgressController from '@/services/onboarding/ProgressController';
import SectionController from '@/services/onboarding/SectionController';
import SubtaskController from '@/services/onboarding/SubtaskController';
import { convertFromProgressDTO } from '@/models/onboarding/formatters/ProgressFormatter';
import { convertFromSectionDTO } from '@/models/onboarding/formatters/SectionFormatter';
import map from 'lodash/map';

export default class OnboardingRepository {
  private generalController: GeneralController;
  private subtaskController: SubtaskController;
  private sectionController: SectionController;
  private progressController: ProgressController;
  private readonly tenantController: TenantController;

  constructor(
    coreService: CoreService,
    onboardingService: OnboardingService,
    tenantController: TenantController
  ) {
    this.generalController = coreService.general;
    this.subtaskController = onboardingService.subtask;
    this.sectionController = onboardingService.section;
    this.progressController = onboardingService.progress;
    this.tenantController = tenantController;
  }
  public async getTenantConfig(tenantName: string) {
    const tenant = await this.tenantController.getTenant(tenantName);
    return tenant.tenantConfig;
  }

  public cleanData() {
    return this.generalController.cleanData();
  }

  public async updateTenantConfig(
    tenantConfig: TenantConfig,
    updatedTenantConfig: TenantConfig
  ) {
    return this.tenantController.updateTenantConfig(
      tenantConfig,
      updatedTenantConfig
    );
  }

  public async getSections() {
    const res = await this.sectionController.getSections();
    return map(res.content, dto => convertFromSectionDTO(dto));
  }

  public getCount() {
    return this.subtaskController.getCount();
  }

  public async getCurrentOnboardingProgess() {
    const { content } = await this.progressController.getProgress();
    return map(content, dto => convertFromProgressDTO(dto));
  }

  public async markAsCompleted(onboardingSubtaskId: string) {
    return await this.progressController.markAsCompleted(onboardingSubtaskId);
  }
}
