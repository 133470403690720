import { fetchClass } from '@/plugins/fetch';
import config from '@/config';

var sso = config.sso;
var coreURL = config.coreURL;
export function APIAuthenticateSSO(params: any) {
  return fetchClass(
    `${sso}/api/authenticate`,
    {
      method: 'POST',
      body: {
        username: params.username,
        password: params.password
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIAuthenticateSSO

export function APIRegisterEmailVerificationSSO(params: any) {
  return fetchClass(
    `${sso}/api/registerTenantAdminEmailVerification`,
    {
      method: 'POST',
      body: {
        email: params.email
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterEmailVerificationSSO

export function APIRegisterCodeVerificationSSO(params: any) {
  return fetchClass(
    `${sso}/api/verifyEmailRegistration`,
    {
      method: 'POST',
      body: {
        code: params.code
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterCodeVerificationSSO

export function APIInviteCodeVerificationSSO(params: any) {
  return fetchClass(
    `${sso}/api/verifyEmailInvitationCode`,
    {
      method: 'POST',
      body: {
        code: params.code
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIInviteCodeVerificationSSO

export function APICodeVerificationSSO(params: any) {
  return fetchClass(
    `${sso}/api/verifyResetPasswordCode`,
    {
      method: 'POST',
      body: {
        code: params.code
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterCodeVerificationSSO

export function APIResetPasswordSSO(params: any) {
  return fetchClass(
    `${sso}/api/resetPassword`,
    {
      method: 'POST',
      body: {
        code: params.code,
        password: params.password,
        confirmPassword: params.confirmPassword
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterCodeVerificationSSO

export function APIRegisterInvited(params: any) {
  return fetchClass(
    `${sso}/api/registerEmailInvitationCode`,
    //`http://3.112.234.243:8090/console/customersV2/signupV2`,
    {
      method: 'POST',
      body: { ...params.data }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterInvited

export function APIRegisterInviteUserSSO(params: any) {
  return fetchClass(
    `${sso}/api/registerEmailInvitationCodeV2`,
    //`http://3.112.234.243:8090/console/customersV2/signupV2`,
    {
      method: 'POST',
      body: { ...params }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterInviteUserSSO

export function APIRegisterNormal(params: any) {
  return fetchClass(
    `${sso}/api/registerTenantAdmin`,
    //`http://3.112.234.243:8090/console/customersV2/signupV2`,
    {
      method: 'POST',
      body: { ...params.data }
    },
    {
      ...params.headers
    },
    true
  );
} // APIRegisterNormal

export function APIStores(params: any) {
  return fetchClass(
    `${coreURL}/core/store`,
    {
      method: 'GET',
      params: { ...params.params }
    },
    {
      ...params.headers
    }
  );
} // APIRegisterNormal

export function APIGetUserDetailsSSO(params: any) {
  return fetchClass(
    `${sso}${params?.body?._id
      ? '/api/getUserDetailsPublicFlow'
      : '/api/getUserDetails'
    }`,
    {
      method: 'GET',
      body: { ...params.body }
    },
    {
      ...params.headers
    }
  );
} // APIRegisterNormal

export function APIGetRolesSSO() {
  return fetchClass(
    `${sso}/api/getRoles`,
    {
      method: 'GET'
    },
    {}
  );
} // APIGetRolesSSO

export function APIDeleteRoleSSO(params: any) {
  return fetchClass(
    `${sso}/api/deleteRole`,
    {
      method: 'DELETE',
      body: { ...params.data }
    },
    {
      ...params.headers
    }
  );
} // APIDeleteRoleSSO

export function APIUpdateProfileSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateProfile`,
    {
      method: 'PUT',
      body: { ...params.data }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateProfileSSO

export function APIChangePasswordSSO(params: any) {
  return fetchClass(
    `${sso}/api/updatePassword`,
    {
      method: 'PUT',
      body: { ...params.data }
    },
    {
      ...params.headers
    }
  );
} // APIChangePasswordSSO
//APIUpdatePasswordSSO
export function APIUpdatePasswordSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateUserPassword`,
    {
      method: 'PUT',
      body: { ...params.data }
    },
    {
      ...params.headers
    }
  );
}
export function APIGetUsersSSO(params: any) {
  return fetchClass(
    `${sso}/api/getTenantUsers`,
    {
      method: 'GET'
    },
    {
      ...params.headers
    }
  );
} // APIGetUsersSSO

export function APIStatusUpdateSSO(params: any) {
  return fetchClass(
    `${sso}/api/deleteUser`,
    {
      method: 'DELETE',
      body: {
        _id: params.id
        //enabled: params.status == 'ENABLED' ? true : false
      }
    },
    {
      ...params.headers
    }
  );
} // APIStatusUpdateSSO

export function APIUserUpdateSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateUserProfile`,
    {
      method: 'PUT',
      body: {
        _id: params.id,
        ...params.body,
      }
    },
    {
      ...params.headers
    }
  );
} // APIStatusUpdateSSO

export function APIInviteUserRequestSSO(params: any) {
  return fetchClass(
    `${sso}/api/inviteUserEmail`,
    {
      method: 'POST',
      body: {
        ...params.body
      }
    },
    {
      ...params.headers
    }
  );
} // APIInviteUserRequestSSO

export function APIReInviteUserRequestSSO(params: any) {
  return fetchClass(
    `${sso}/api/resendInviteUserEmail`,
    {
      method: 'POST',
      body: {
        ...params.body
      }
    },
    {
      ...params.headers
    }
  );
} // APIInviteUserRequestSSO

export function APIUserRoleUpdateSSO(params: any) {
  return fetchClass(
    `${sso}/api/assignUserRole`,
    {
      method: 'POST',
      body: {
        ...params.body
      }
    },
    {
      ...params.headers
    }
  );
} // APIStatusUpdateSSO

export function APIForgotPasword(params: any) {
  return fetchClass(
    `${sso}/api/forgotPassword`,
    {
      method: 'POST',
      body: {
        ...params.body
      }
    },
    {
      ...params.headers
    },
    true
  );
} // APIForgotPasword

export function APIGetPermissionsSSO(params: any) {
  return fetchClass(
    `${sso}/api/getTenantModulePermissions`,
    {
      method: 'GET',
      params: {
        ...params.params
      }
    },
    {
      ...params.headers
    }
  );
} // APIGetPermissionsSSO

export function APIGetProfilePictureSSO(params: any) {
  return fetchClass(
    `${sso}/api/getProfilePicture`,
    {
      method: 'GET',
      params: {
        ...params.params
      }
    },
    {
      ...params.headers
    }
  );
}

export function APIUpdateProfilePictureSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateProfilePicture`,
    {
      method: 'PUT',
      body: {
        photoBucket: params.file,
        formate: params.body
      }
    },
    {
      ...params.headers
    }
  );
}
export function APIUpdateRoleSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateRole`,
    {
      method: 'PUT',
      body: {
        title: params.title,
        description: params.description,
        permissionsArray: params.authorities,
        _id: params.roleId,
        selectedPeriod: params.selectedPeriod,
        comparePeriod: params.comparePeriod,
        allowLocationFilter: params.locationFilter
      }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateRoleSSO

export function APIUpdateLangSSO(params: any) {
  // if(params.lang == 'en-us')
  // {
  //   params.lang = 'en'
  // }
  return fetchClass(
    `${sso}/api/updateUserLang`,
    {
      method: 'PUT',
      body: {
        userLang: params.lang
      }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateRoleSSO

export function APICreateRoleSSO(params: any) {
  return fetchClass(
    `${sso}/api/createRole`,
    {
      method: 'POST',
      body: {
        permissionsArray: params.authorities,
        title: params.title,
        description: params.description,
        comparePeriod: params.comparePeriod,
        selectedPeriod: params.selectedPeriod,
        allowLocationFilter: params.locationFilter
      }
    },
    {
      ...params.headers
    }
  );
} // APICreateRoleSSO

export function APIUpdateTenantSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateTenant`,
    //`${sso}/api/updateTenantDefaultSettings`,
    {
      method: 'PUT',
      body: {
        ...params.data
      }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateTenantSSO
export function APIUpdateTenantSettingsSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateTenantDefaultSettings`,
    {
      method: 'PUT',
      body: {
        ...params.settings
      }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateTenantSSO
export function APIGetDefaultSettingsSSO(params: any) {
  return fetchClass(
    `${sso}/api/getDefaultSettings`,
    {
      method: 'GET',
      body: {
        ...params.settings
      }
    },
    {
      ...params.headers
    }
  );
} // getDefaultSettings
//
export function APIGetTenantContactsSSO(params: any) {
  return fetchClass(
    `${sso}/api/getTenantContacts`,
    {
      method: 'GET',
      params: {
        tenantId: params.tenantId
      }
    },
    {
      ...params.headers
    }
  );
} // APIGetTenantContactsSSO

export function APIUpdateTenantContactSSO(params: any) {
  return fetchClass(
    `${sso}/api/updateTenantContact`,
    {
      method: 'PUT',
      body: { ...params.data, contactId: params.data.id }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateTenantContactSSO

export function APICreateTenantContactSSO(params: any) {
  return fetchClass(
    `${sso}/api/createTenantContact`,
    {
      method: 'POST',
      body: { ...params.data }
    },
    {
      ...params.headers
    }
  );
} // APIUpdateTenantContactSSO

export function APISignoutSSO(params: any) {
  return fetchClass(
    `${sso}/api/logoutUser`,
    {
      method: 'POST',
      body: {}
    },
    {
      ...params.headers
    }
  );
} // APISignoutSSO
