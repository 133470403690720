import * as marketData from '@/util/mockData/marketPlace';
import { PostRequest } from '@/util/http';

export function APIFetchThirdPartyRecommended() {
  return marketData.recommendedThirdParty;
}
export function APIFetchListOfPackages() {
  return marketData.listOfPackages;
}
export function APIFeaturedListOfPackages() {
  return marketData.featuredListOfPackages;
}
export function APIFetchIntegration() {
  return marketData.packageIntegration;
}
export function APIFeaturedIntegration() {
  return marketData.packageIntegrationRecommended;
}

export function APIFetchThirdParty() {
  return marketData.thirdParty;
}
export function APIFetchPartnersRecommended() {
  return marketData.partnersRecommended;
}

export function APIFetchPartners() {
  return marketData.partners;
}
export function APIFetchIot() {
  return marketData.iot;
}

export function APIFetchIotRecommended() {
  return marketData.iotRecommended;
}

export function APIFetchFlowService() {
  return marketData.flowService;
}

export function APISendEmail(details) {
  return PostRequest('marketplace/notification/email', details);
}
