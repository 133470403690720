
import users from "@/services/users";
export default {
  name: "StoreModel",
   // eslint-disable-next-line vue/require-prop-types
  props: ['selectedStores','selectedUser', 'userDetails', 'userLocation', 'storeData'],
  // props: {
  //   selectedUser: {
  //     type: Object,
  //     default: null,
  //   },
  //   storeData: {
  //     type: Array,
  //     default: () => [],
  //   },
  //   userDetails: {
  //     type: Object,
  //     default: null,
  //   },
  //   userLocation: {
  //     type: Object,
  //     default: null,
  //   }
  // },
  data () {
    return {
       filter: "",
      openStoreModel: true,
      data: [],
      selected: [],
      storeAccessibleColumns: [
        {
          name: 'title',
          required: true,
          label: this.$store.state.user.translate.name,
          align: 'left',
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true
        },
        {
          name: "storeNumber",
          align: "left",
          label: this.$store.state.user.translate.store_number,
          field: "storeNumber",
          sortable: true,
        },
        // { name: "brand", label: "Brand", field: "brand" },
        // { name: "myStore", label: "My Store", field: "myStore" },
      ],
    }
  },
  mounted() {
    // this.data = this.storeData?.filter(v => v.status === 0);
    //  this.data = this.storeData
    // console.log(this.data, 'data')
    this.selected = this.selectedStores;
  
  },
  // mounted() {
    // console.log('#',this.selectedStores)
    // this.selected = this.selectedUser;
  // },
  methods: {
    getSelectedString() {
      return this.selected.length === 0 ? '' : `${this.selected.length} record${this.selected.length > 1 ? 's' : ''} selected of ${this.storeData.length}`
    },
    assignUserStore() {
      // if (this.selectedUser ) {
      //   let userStores = []
      //   let userPeerGroups = []
      //   this.userLocation.myPeerGroups.forEach((element) => {
      //     userPeerGroups.push(element._id)
      //   })
      //   this.selected.forEach((element) => {
      //     userStores.push(element.storeNumber)
      //   })
      //   let UsrDetails = {
      //     userId: this.selectedUser._id,
      //     locations: [ {
      //         "type": "mystores",
      //         "storeCodes": userStores
      //       },
      //       {
      //         "type": "peergroups",
      //         "peerGroupIds": userPeerGroups
      //       }
      //     ]
      //   }
      //   users.setUserLocations(UsrDetails).then(() => {
      //     this.$q.notify({
      //       message: 'Store assigned successfully',
      //       color: 'green'
      //     })
      //     this.$emit("updateStore")
      //   }).catch((error) => {
      //     // console.log(error)
      //     if (error.response.data.message) {
      //       this.$q.notify({
      //         message: error.response.data.message,
      //         color: 'red'
      //       })
      //     } else {
      //       this.$q.notify({
      //         message: this.$store.state.user.translate.something_went_wrong,
      //         color: 'red'
      //       })
      //     }
      //   })
      // } else {
        this.$emit("updateStoreValue", this.selected)
      // }
    },
  }
}
