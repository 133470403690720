
import { mapState, mapActions, mapMutations } from 'vuex';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [PaginationMixin],
  data() {
    return {
      parameter: '',
      searchKeyword: ['', '', ''],
      columns: [
        {
          name: 'id',
          align: 'center',
          label: 'ID',
          field: 'id',
          sortable: true,
          required: true,
          format: val => `${val}`
        },
        {
          name: 'groupName',
          align: 'center',
          label: this.$t('userGroup'),
          field: 'groupName',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'actions',
          align: 'center',
          label: this.$t('actions'),
          field: 'actions',
          headerClasses: 'entrance-col'
        }
      ],
      row: [],
      loading: true
    };
  },
  computed: {
    ...mapState('usermgmt', ['userGroupsList', 'userGroup'])
  },
  watch: {
    searchKeyword() {
      let obj = {
        id: this.searchKeyword[0],
        groupName: this.searchKeyword[1]
      };
      let rowData = this.userGroupsList
        .map(val => {
          return {
            id: val.groupID,
            groupName: val.groupName
          };
        })
        .reverse();
      this.row = rowData.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    },
    userGroupsList() {
      this.row = this.userGroupsList
        .map(val => {
          return {
            id: val.groupID,
            groupName: val.groupName
          };
        })
        .reverse();
    }
  },
  async created() {
    this.loading = true;
    await this.fetchUserGroup();
    this.loading = false;
  },
  methods: {
    ...mapActions('usermgmt', [
      'fetchUsers',
      'fetchUserGroupByID',
      'fetchUserGroup',
      'deleteUserGroupByID'
    ]),
    ...mapMutations('usermgmt', [
      'setDialog',
      'setSettingsMode',
      'setUserGroup'
    ]),
    async fetchTableData() {
      await this.fetchUserGroup();
    },
    deleteUser(val) {
      this.$q
        .dialog({
          title: this.$t('confirmDelete'),
          message: this.$t('promptDelete'),
          cancel: true,
          persistent: false
        })
        .onOk(async () => {
          await this.deleteUserGroupByID(val);
          await this.fetchUserGroup();
          this.$q.notify({
            type: 'positive',
            position: 'top',
            message: this.$t('deleteMessage')
          });
        })
        .onCancel(() => {})
        .onDismiss(() => {});
    },
    async editMode(val) {
      await this.fetchUserGroupByID(val);
      await this.setSettingsMode('edit');
      this.setDialog(true);
    },
    openAddDialog() {
      this.setSettingsMode('add');
      this.setDialog(true);
      this.setUserGroup(null);
    }
  }
};
