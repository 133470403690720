// import { APIFetchStores } from '@/api/store';
import { APIFetchStoresFida } from '@/api/storeFida';
import { de } from 'date-fns/locale';

const initialState = {
  stores: {
    areaManagers: [],
    peerGroups: [],
    regions: [],
    translation:null,
    storesCodeNameList: null,
    dataAccessStores: [],
    myStores: []
  }
};

export default {
  state: initialState,
  mutations: {
    setStores(state, { stores }) {
      state.stores = stores;
    },
    setTranslation (state, payload) {
      state.translation = payload
    },
    setStoresCodeNameList(state, payload) {
      state.storesCodeNameList = payload;
    },
  },
  actions: {
    // async fetchStores({ commit }) {
    //   const res = await APIFetchStores();
    //   commit('setStores', { stores: res.data });
    //   return res;
    // }
    async fetchStores({ commit, state }) {

      const pathname = window.location.pathname;
      if (state?.stores?.stores?.length > 0 && pathname!="/" && state?.stores?.stores[0]?.storeTimings  && state?.stores?.stores[0]?.entrances) {
        return state.stores; // Return existing data
      }
      const res:any = await APIFetchStoresFida('all');
      commit('setStores', { stores: res.data });
      commit('setStoresCodeNameList', res.data.stores);
      return res;
    },
    async fetchStoresForTraffic({ commit, state }) {
      const res:any = await APIFetchStoresFida('all');
    
      return res.data.stores;
    },
  },
  getters: {
    getStoreNameList: state => {
      const stores = state.stores.stores;
      return stores?.map((store) => { return store.name })
    },
    getStoreCodeList: state => {
      const stores = state.stores.stores;
      return stores?.map((store) => { return store.storeCode })
    },
    getStoresCodeNameList: state =>{
      return state.storesCodeNameList;
    },
    getAllStoreInfo: state => {
      const stores = state.stores;
      return JSON.parse(JSON.stringify(state.stores))
    },
  }
};
