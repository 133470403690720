import { ClientManager } from '@/util/client';
import SectionController from './SectionController';
import SubtaskController from './SubtaskController';
import ProgressController from './ProgressController';

export interface OnboardingControllers {
  subtask: SubtaskController;
  section: SectionController;
  progress: ProgressController;
}

export default (clientManager: ClientManager): OnboardingControllers => ({
  subtask: new SubtaskController(clientManager.consoleClient),
  section: new SectionController(clientManager.consoleClient),
  progress: new ProgressController(clientManager.apiGatewayClient)
});
