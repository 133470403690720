import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@/models/Pagination';
import { Prefecture } from '@/models/Location';
import { createBackendError } from '@/util/error';

export type GetPrefecturesResponse = PaginatedResponse<Prefecture>;

export default class PrefectureController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getPrefectures() {
    try {
      const { data } = await this.client.get('/prefecture', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 100,
          paged: false
        }
      });
      return data as GetPrefecturesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getPrefecturesByDistrict(districtId) {
    try {
      const { data } = await this.client.get('/prefecture', {
        params: {
          query: `districtId:${districtId}`
        }
      });
      return data as GetPrefecturesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
