var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-card',{attrs:{"flat":""}},[_c('q-card-section',{staticClass:"q-py-sm"},[_c('h6',{},[_vm._v(" "+_vm._s(_vm.$t('ranking'))+" "),_c('q-icon',{staticClass:"info-icon",attrs:{"name":"fas fa-info-circle"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-class":"w-300","offset":[10, 10]}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('tooltip'))+" ")])])],1),_c('div',{staticClass:"float-right"},[_c('div',{staticStyle:{"width":"200px"}},[_c('q-select',{attrs:{"borderless":"","dense":"","options":_vm.options,"option-value":"value","label":_vm.$t('BoxOccupancyPowerHour.selectKPI')},model:{value:(_vm.kpi),callback:function ($$v) {_vm.kpi=$$v},expression:"kpi"}})],1)])],1)]),_c('q-separator'),_c('q-card-section',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 row justify-center align-center",staticStyle:{"height":"fit-content","overflow":"hidden"}},[_c('div',{staticStyle:{"width":"90%","margin":"20px 0px"}},[_c('q-btn-toggle',{attrs:{"toggle-color":"warning","color":"white","toggle-text-color":"black","text-color":"black","unelevated":"","options":[
            { label: _vm.$t('top10'), value: 10 },
            { label: _vm.$t('top20'), value: 20 },
            { label: _vm.$t('all'), value: 10009 }
          ]},model:{value:(_vm.modelL),callback:function ($$v) {_vm.modelL=$$v},expression:"modelL"}})],1),_c('q-table',{key:_vm.modelL,staticClass:"sticky-column-table box-rank-table",staticStyle:{"width":"90%"},attrs:{"hide-pagination":"","flat":"","data":_vm.data,"columns":_vm.columns,"pagination":{ rowsPerPage: _vm.modelL },"row-key":"name"},scopedSlots:_vm._u([{key:"body-cell-rank",fn:function(scope){return [_c('q-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(scope.pageIndex + 1)+" ")])]}}])})],1),_c('div',{staticClass:"col-md-6 row justify-center align-center",staticStyle:{"height":"fit-content","overflow":"hidden"}},[_c('div',{staticStyle:{"width":"90%","margin":"20px 0px"}},[_c('q-btn-toggle',{attrs:{"toggle-color":"grey","color":"white","text-color":"black","unelevated":"","options":[
            { label: _vm.$t('bottom10'), value: 10 },
            { label: _vm.$t('bottom20'), value: 20 },
            { label: _vm.$t('all'), value: 10009 }
          ]},model:{value:(_vm.modelR),callback:function ($$v) {_vm.modelR=$$v},expression:"modelR"}})],1),_c('q-table',{key:_vm.modelR,staticClass:"sticky-column-table box-rank-table",staticStyle:{"width":"90%"},attrs:{"hide-pagination":"","flat":"","data":Array.from(_vm.data).reverse(),"columns":_vm.columns,"pagination":{ rowsPerPage: _vm.modelR },"row-key":"name"},scopedSlots:_vm._u([{key:"body-cell-rank",fn:function(scope){return [_c('q-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(scope.pageIndex + 1)+" ")])]}}])})],1),_c('LoadingSpinner',{attrs:{"loading":_vm.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }