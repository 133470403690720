import DefaultSettings, {
  DefaultSettingsDTO
} from '@/models/onboarding/DefaultSettings';
import {
  convertFromCompanyDTO,
  convertFromCompanyDTOSSO,
  convertToCompanyDTO
} from '@/models/formatters/CompanyFormatter';

import Company from '@/models/Company';
import CompanyController from '@/services/core/CompanyController';
import DefaultSettingController from '@/services/core/DefaultSettingController';
import {
  APICreateTenantContactSSO,
  APIUpdateTenantContactSSO
} from '@/api/authSSO';
import { convertToContactDTO } from '@/models/formatters/ContactFormatter';
import map from 'lodash/map';

export type UpsertDefaultSettings = DefaultSettings;
export type UpsertCompanyInfo = Company;

export default class SettingsRepository {
  private companyController: CompanyController;
  private defaultSettingController: DefaultSettingController;

  constructor(
    companyController: CompanyController,
    defaultSettingController: DefaultSettingController
  ) {
    this.companyController = companyController;
    this.defaultSettingController = defaultSettingController;
  }

  public async getDefaultSettings() {
    const {
      content
    } = await this.defaultSettingController.getDefaultSettings();
    if (content.length === 0) return null;
    return DefaultSettings.fromDTO(content.pop());
  }

  // public async getSchema(email: string, password: string) {
  //   await this.defaultSettingController.getSchema(email, password);
  //   return true;
  // }

  public async upsertDefaultSettings(defaultSettings: UpsertDefaultSettings) {
    let dto: DefaultSettingsDTO;
    if (defaultSettings.id) {
      dto = await this.defaultSettingController.updateDefaultSettings(
        defaultSettings.id,
        defaultSettings.toDTO()
      );
    } else {
      dto = await this.defaultSettingController.createDefaultSettings(
        defaultSettings.toDTO()
      );
    }
    return DefaultSettings.fromDTO(dto);
  }

  public async getCompanyInfo() {
    const dto = await this.companyController.getCompany();
    return convertFromCompanyDTOSSO(dto);
  }

  public async upsertCompanyInfo({
    businessContacts,
    operationContacts,
    companyInfo
  }) {
    map(businessContacts, contact => convertToContactDTO(contact)).map(data => {
      if (data.id) {
        APIUpdateTenantContactSSO({
          data: {
            ...data,
            phoneNumber: data.phone,
            contactType: 'business',
            tenantId: companyInfo.id
          }
        });
      } else {
        APICreateTenantContactSSO({
          data: {
            ...data,
            phoneNumber: data.phone,
            contactType: 'business',
            tenantId: companyInfo.id
          }
        });
      }
    });

    map(operationContacts, contact => convertToContactDTO(contact)).map(
      data => {
        if (data.id) {
          APIUpdateTenantContactSSO({
            data: {
              ...data,
              phoneNumber: data.phone,
              contactType: 'operation',
              tenantId: companyInfo.id
            }
          });
        } else {
          APICreateTenantContactSSO({
            data: {
              ...data,
              phoneNumber: data.phone,
              contactType: 'operation',
              tenantId: companyInfo.id
            }
          });
        }
      }
    );
    const dto = await (companyInfo.id
      ? this.companyController.updateCompany(companyInfo)
      : this.companyController.createCompany(companyInfo));
    return convertFromCompanyDTO(dto);
  }
}
