
import PersonalDataForm from './Forms/PersonalData.vue';
import CompanyDataForm from './Forms/CompanyData.vue';
import AccountReviewModal from './Modals/AccountReviewModal.vue';
import PersonalInfoBody from './Modals/PersonalInfoBody.vue';
import CompanyInfoBody from './Modals/CompanyInfoBody.vue';
import * as CompanyDataModels from './Forms/CompanyDataModels';
import * as PersonalDataModels from './Forms/PersonalDataModels';
import LoginTabs from '@/components/Auth/Tabs.vue';
import AuthMixin from '@/mixins/AuthMixin';
import { handleErrorUI } from '@/util/error';
import { loginDashboard } from '@/util/navigation';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Vue from 'vue';
import store from '../../../store';
import moment from 'moment';
import { getAuthoritiesObj } from '@/util/authoritiesCheck';
import { TERMS_AND_CONDITIONS } from '@/consts/pages';


import { required } from 'vuelidate/lib/validators';
import CommonMixin from '../../../mixins/CommonMixin';
import { UPDATE_COMPANIES, VIEW_COMPANIES } from '@/consts/permissions';
import { setUsersParamsValues } from '@/util/userParams';
import { Notify } from 'quasar';
import config from '@/config';


const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export default {
  components: {
    LoginTabs,
    PersonalDataForm,
    CompanyDataForm,
    AccountReviewModal,
    PersonalInfoBody,
    CompanyInfoBody
  },
  mixins: [AuthMixin,CommonMixin],
  props: {
    email: { type: String, default: '' },
    code: { type: String, default: '' }
  },
  data() {
    return {
      username: null,
      password: null,
      userlang:null,
      disable: false,
      isHide: true,
      agreed: false,
      step: 0,
      reviewShown: false,
      admin: null,
      tenant: null,
      user: {
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        phone: {
          countryCode: '+81',
          phoneNumber: ''
        }
      },
      company: {
        name: '',
        contacts: [{ phone: '' }]
      },
      loading: false
    };
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['isAuthenticated', 'getLocale']),
    ...mapGetters('tenant', ['hasResetAccount']),
    ...mapGetters('user', ['getLocale']),
    stepLabels: function() {
      return [
        this.$t('AuthPage.personal_data'),
        this.$t('AuthPage.company_data')
      ];
    },
    personalForm: function() {
      return this.$refs.personalDataForm;
    },
    companyForm: function() {
      return this.$refs.companyDataForm;
    }
  },
  created() {
    this.admin = PersonalDataModels.createData(this.email);
    this.tenant = CompanyDataModels.createData(this.email);
  },
  methods: {
     ...mapMutations('user', [
      'setLocale',
      'setUserPermissions',
      'setDefaultSettings'
    ]),

    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),

    ...mapActions('tenant', ['reset']),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    ...mapActions('user', [
      'authenticateSSO',
      'fetchKpiPreferences',
      // 'fetchKpiPreferences2',
      'fetchStores',
      'clearSession',
      'authenticate',
      'fetchProfile',
      'authenticateDemo',
      'fetchClientProfile'
    ]),

    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    ...mapActions('user', ['registerNormal']),
    setupLocale(locale) {
      this.setLocale({ locale });
    }, // setupLocale
    stepClass(index) {
      const styles = this.$style;
      return {
        [styles.step]: true,
        [styles.done]: index <= this.step,
        [styles.active]: index === this.step
      };
    },
    prevStep() {
      this.step--;
    },
    nextStep() {
      this.step++;
    },
    toCompanyForm() {
      this.personalForm.sync();
      this.user = {
        email: this.admin.email,
        username: this.admin.username,
        firstName: this.admin.firstName,
        lastName: this.admin.lastName,
        phone: this.admin.phone
      };

      this.nextStep();
    },
    showReview() {
      this.companyForm.sync();

      if (!this.companyForm.validate()) {
        const { phone } = this.tenant;
        this.company = {
          name: this.tenant.companyName,
          industryName: this.tenant.industryName,
          industryNameJp: this.tenant.industryNameJp,
          contacts: [{ phone: `${phone.countryCode} ${phone.phoneNumber}` }]
        };

        this.reviewShown = true;
      }
    },
    setUsersParams() {
      const storedUserId = localStorage.getItem('userID');
      if (storedUserId !== this.profile?.userID) {
        localStorage.removeItem('storeCodes');
      }
      localStorage.setItem('userID', this.profile?.userID);
      let stores = localStorage.getItem('storeCodes');
      if (stores !== null) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      } else {
        this.setStoreCodes({ storeCodes: this.profile?.stores?.split(',') });
        localStorage.setItem('storeCodes', this.profile?.stores);
      }
      this.setStartingPeriod({
        startingPeriod: this.profile?.defaultDateFilter?.startDate ?? new Date()
      });
      this.setEndingPeriod({
        endingPeriod: this.profile?.defaultDateFilter?.endDate ?? new Date()
      });
      this.setStartingCompared({
        startingCompared:
          this.profile?.defaultDateFilter?.compareStartDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      });
      this.setEndingCompared({
        endingCompared:
          this.profile?.defaultDateFilter?.compareEndDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      });
      this.setRetailCalendar({
        dates: this.profile?.custom445Calendar
          ? this.profile?.custom445Calendar
          : {}
      });
      this.setCustomCalenderFlag({
        value: this.profile?.defaultDateFilter?.useCustomCalender
      });
      localStorage.setItem(
        'startingPeriod',
        this.profile?.defaultDateFilter?.startDate ?? new Date()
      );
      localStorage.setItem(
        'endingPeriod',
        this.profile?.defaultDateFilter?.endDate ?? new Date()
      );
      localStorage.setItem(
        'startingCompared',
        this.profile?.defaultDateFilter?.compareStartDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      );
      localStorage.setItem(
        'endingCompared',
        this.profile?.defaultDateFilter?.compareEndDate ??
          moment(new Date())
            .add(-1, 'days')
            .toDate()
      );
      localStorage.setItem(
        'useCustomCalender',
        this.profile?.defaultDateFilter?.useCustomCalendar || false
      );
    },
    async loginOld() {
      this.loading = true;
      this.disable = true;

      this.username = this.admin.username === null ? '' : this.admin.username;
      this.password = this.admin.password === null ? '' : this.admin.password;
      try {
        await this.authenticate({
          username: this.username,
          password: this.password
        });

        const { tenant } = await this.$repo.auth.login(
          this.username,
          this.password
        );

        await this.authenticateDemo({
          hasResetAccount: this.hasResetAccount
        });

        const session = await this.$repo.session.put(tenant);
        this.$client.setSession(session);
        await this.initialize();
        await this.flowLogin();
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
        this.disable = false;
        await this.$router.push({ name: 'home' }).catch(_ => {});
      } // finally
    },
    async flowLogin() {
      try {
        const user = await this.$repo.user.getUser('me');
        const userDetails = this.defaultSettings;
        this.setDefaultSettings({ ...userDetails, user });
        let permissions = getAuthoritiesObj(user?.roles);
        this.setUserPermissions(permissions);
        await this.fetchProfile();
        await this.fetchClientProfile();
        this.setUsersParams();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: 'Username or Password is incorrect. Please try again.',
          position: 'top',
          timeout: 3000
        });
      } finally {
        this.loading = false;
        this.disable = false;
      }
    },
    async submitTenant() {
      this.loading = true;

      const data = {
        email: this.user.email,
        username: this.user.username,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: this.admin.password,
        passwordConfirm: this.admin.confirmPassword,
        phoneNumber: `${this.admin.phone.countryCode} ${this.admin.phone.phoneNumber}`,
        code: this.code,
        terms: TERMS_AND_CONDITIONS,
        tenant: {
          companyName: this.tenant.companyName,
          email: this.user.email,
          companyPhone: `${this.tenant.phone.countryCode} ${this.tenant.phone.phoneNumber}`,
          domain: this.user.email.split('@')[1].toString(),
          industryId: this.tenant.industryId,
          industry: this.tenant.industryName
        }
      };
      try {
        var invited= null;
        if (window.location.href.indexOf("invited=true") > -1) {
           invited =true;
        }

        const tenant = await this.registerNormal({ data,invited });
        if(tenant != null)
        {
			const core =  localStorage.getItem('core');
			const flowgo = localStorage.getItem('flowgo');
      const flowgo2 = localStorage.getItem('flowgo2');

      const report = localStorage.getItem('reporting');


			const eddyUrl = localStorage.getItem('eddyUrl');
			const sso = localStorage.getItem('sso');
      const chatIP = localStorage.getItem('chatIP');
      const chatIsProd = localStorage.getItem('chatIsProd');

			const status = localStorage.getItem('status');
			this.clearSession();
			this.reset();
			localStorage.setItem('core',core);
			localStorage.setItem('flowgo',flowgo);
    	localStorage.setItem('flowgo2',flowgo2);

			localStorage.setItem('reporting',report);

			localStorage.setItem('sso', sso);
			localStorage.setItem('eddyUrl', eddyUrl);

			localStorage.setItem('status',status);
      localStorage.setItem('chatIP', chatIP);
      localStorage.setItem('chatIsProd', chatIsProd);
			await this.login();
        }
      this.$q.notify({
          type: 'positive',
          message: this.$t('AuthPage.company_success'),
          position: 'top',
          timeout: 3000
        });

    // await this.$router.push({ name: 'auth:login' }).catch(_ => {});
     await this.$router.push({ name: 'auth:home' }).catch(_ => {});
      } catch (err) {
		this.$q.notify({
          type: 'negative',
          message: this.$t('AuthPage.EmaiOrUserExist'),
          position: 'top',
          timeout: 3000
        });
    //    handleErrorUI(err?.response?.data || err?.response);
      } finally {
        this.loading = false;
        //  console.log('finally');
      } // finally

      /* try {
        const tenant = await this.$repo.auth.createTenant(
          this.admin,
          this.tenant
        );
        const session = await Vue.repo.session.put(tenant);
        Vue.client.setSession(session);
        await store.dispatch('tenant/initialize');


        await this.login();
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
        // await this.$router.push({ name: 'home' }).catch(_ => {});
      } // finally */
    },
     async login() {
      try {
          const tenant = await this.authenticateSSO({
            username: this.user.username,
            password: this.admin.password,
            userlang: this.getLocale
          });
         // this.$repo.settings.getSchema(this.user.username, this.admin.password);
          const user = await this.$repo.user.getUser('me');
          await this.fetchStores();
          const userDetails = await this.$repo.settings.getDefaultSettings();
          const usrRoleSetting = user.response.userDetails.role.config;
          const usrTenentSetting = user.response.userDetails.tenant.config;
          if(usrRoleSetting != null && usrRoleSetting != 'undefined') {
            userDetails.locationFilter = usrRoleSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrRoleSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrRoleSetting.selectedPeriod;
          } else
          {
            userDetails.locationFilter = usrTenentSetting.allowLocationFilter;
            userDetails.headquartersComparePeriods = usrTenentSetting.comparePeriod;
            userDetails.headquartersSelectPeriods = usrTenentSetting.selectedPeriod;
          }
          this.setDefaultSettings({ ...userDetails, user });

          this.$client.setSession({
            tenant: tenant.intercom.companyName
          });
          await this.$repo.session.put(tenant.intercom.companyName);
          //,tenant.intercom.companyId,tenant.intercom.addonsIds,tenant.intercom.plansIds
          localStorage.setItem('userID', this.profile?._id);
          const accountType = user.response.userDetails.plans[0].code
            .toString()
            .includes('Demo');
          localStorage.setItem('accountType', accountType);
          localStorage.setItem('isDemoAccount', this.profile?._id);

          await this.setUsersParamsLogin();
          await this.fetchKpiPreferences();
          // await this.fetchKpiPreferences2();
          const userData = user.response.userDetails;

          Vue.prototype?.$mixpanel?.people.set({
            $distinct_id: userData._id,
            $created: userData.cDate.timeStamp,
            $username: userData.username,
            $role: userData.role.title,
            $email_verification: userData.emailVerification.verified,
            $tenant_id: userData.tenant._id,
            $tenant_name: userData.tenant.domain,
            $SSO_ID: userData._id
          });
          Vue.prototype?.$mixpanel?.identify(userData._id);
          await this.$router.push({ name: 'home' }).catch(_ => {});
      } catch (err) {
        this.$q.notify({
          message: this.$t('AuthPage.login_error_message'),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
        localStorage.clear();
        this.$v.$reset();
        this.signout();
      } finally {
        this.loading = false;
        this.disable = false;
      }
    },
    async setUsersParamsLogin() {
      try {
        await setUsersParamsValues();
      } catch (e) {
        Notify.create({
          message: e,
          position: 'top',
          type: 'negative'
        });
      }
    },
  }
};
