import merge from 'lodash/merge';
import Vue from 'vue';

import {
  getCurrencySymbol,
  formatCurrency
} from '@/models/formatters/CurrencyFormatter';

export interface TableOptions {
  format?: (val: any, row: any) => string;
  sortable?: boolean;
}

export const createColumn = (
  field: string,
  label: string,
  options: TableOptions = {}
) =>
  merge(
    {
      name: field,
      align: 'left',
      label,
      field,
      required: true,
      sortable: true,
      style: 'max-width: 13%',
      headerStyle: 'max-width: 13%'
    },
    options
  );

export const formatAsDate: TableOptions = {
  format: (val: Date) => {
    return val.toLocaleString();
  }
};

export const formatAsTime: TableOptions = {
  format: (val: Date) => {
    return val.toLocaleTimeString();
  }
};

export const formatAsDateTime: TableOptions = {
  format: (val: Date) => {
    const date = new Date(val);
    return `${date.toLocaleString()} ${date.toLocaleString()}`;
  }
};

export function formatAsCurrency(
  currencyFn: Function = row => row['currency'],
  valueFn: Function = val => val
): TableOptions {
  return {
    format: (val: number, row) => {
      const currency = currencyFn(row, val);
      const v = valueFn(val);
      return `${getCurrencySymbol(currency)} ${formatCurrency(v, currency)}`;
    }
  };
}
