import { map } from 'lodash';
import PeerGroup, { PeerGroupDTO } from '../PeerGroup';
import { convertFromStoreDTO } from './StoreFormatter';

export const convertFromPeerGroupDTO = (dto: PeerGroupDTO): PeerGroup => ({
  id: dto.id,
  title: dto.title,
  peergroupid: dto.peergroupid,
  stores: map(dto.stores, ({ store }) => convertFromStoreDTO(store, null))
});
