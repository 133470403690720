import { render, staticRenderFns } from "./ImportMappingDialog.vue?vue&type=template&id=27d3528a&scoped=true&"
import script from "./ImportMappingDialog.vue?vue&type=script&lang=ts&"
export * from "./ImportMappingDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ImportMappingDialog.vue?vue&type=style&index=0&id=27d3528a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d3528a",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QBtn,QTable,QTd,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QBtn,QTable,QTd,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
