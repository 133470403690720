import { parseEmailDomain } from './parser';

export const validateEmail = (email: string) => {
  if (!email) return false;
  if (!parseEmailDomain(email)) return false;
  return true;
};

export function validateMandatoryFields(request: object, keys: string[]) {
  if (request == null) {
    return false;
  }

  for (const key of keys) {
    const parts = key.split('.');
    let part = parts.shift();
    let value: any = request;
    while (part != null) {
      //  In case the search path is deeper than the actual
      //  object structure, the field doesn't exist, therefore
      //  return false
      if (value == null) {
        return false;
      }

      value = value[part];
      part = parts.shift();
    }

    if (empty(value)) {
      return false;
    }
  }
  return true;
}

export function empty(value: any): boolean {
  if (value == null) return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'string' && value.trim().length === 0) return true;
  if (typeof value === 'object' && Object.keys(value).length === 0) return true;
  return false;
}
