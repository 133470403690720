import { SSOPostReq, SSOPostRequest2,SSOGetRequest2 } from '@/util/kpis';
import store from '@/store';
import { convertResponse } from './kpiApiResponceConversion';
import axios from "axios"

let urlObj = {
  '/': "storeCodesDashboardF",
  '/export': "storeCodesExportF",
  '/export/kpi': "kpiListStF",
  '/detailed-report': "storeCodesDetailedReportF",
  '/detailed-report/kpi': "selectedDetailKPIF",
  '/traffic-report': "traffic-reportF",
  '/location-report': "location-reportF",
  '/demographics-report': "demographics-reportF",
  '/location-comparison-report': "location-comparison-reportF",
  '/queue-report': "queue-reportF",
};

export const APIGetSavedPreferences = (urlPage): Promise<any[]> => {
  {
  return new Promise(async (resolve, reject) => {
    try {
      let url = urlObj[urlPage];
      let resObj = null;
      if(url) {
        let v = localStorage.getItem(url)
        if(!(v == 'undefined' || !v)) {
          resObj = v;
        } 
      }
      if(!resObj) {
        const storeState: any = store.state;

        const userID= storeState.user.profile._id;
        const tenentID= storeState.user.profile.tenant._id;
        const baseUrl = 'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/user/'+''+tenentID+'/'+userID
        const timestamp = new Date().getTime(); // Current timestamp
        const urlWithTimestamp = `${baseUrl}?timestamp=${timestamp}`;
  
        const apiResponse = await getApiPrefrencses(urlWithTimestamp);

        Object.keys(apiResponse).forEach((k) => {
          if(urlObj[k]) {
            let v = apiResponse[k].toString();
            if(!(v == 'undefined' || !v)) {
              localStorage.removeItem(k)
              localStorage.setItem(k, v)
            } 
          }
        })
        
        resObj = apiResponse[urlPage] || apiResponse['/'];
      }
      resolve(resObj)
    } catch (error) {
      reject(error);
    }
  })
}
}
export const APISavePreferences = (urlPage, selectedStores) => {
  if(urlObj[urlPage]) {
    localStorage.removeItem(urlObj[urlPage])
    localStorage.setItem(urlObj[urlPage], selectedStores)
  }
}
const config = {
  method: "GET",
  priority: 'low',
  headers: {
      Authorization: "",
  },
};
var getApiPrefrencses = async (url) =>{
  const response = await fetch(url,config);
    return response.json();
}
export const APIGetSavedPreferencesForAll = (): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;

      const userID= storeState.user.profile._id;
      const tenentID= storeState.user.profile.tenant._id;

      const baseUrl = 'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/user/'+''+tenentID+'/'+userID
      const timestamp = new Date().getTime(); // Current timestamp
      const urlWithTimestamp = `${baseUrl}?timestamp=${timestamp}`;

      const apiResponse = await getApiPrefrencses(urlWithTimestamp);
        var res = [];

        for (const key of Object.keys(apiResponse)) {
          const context = key;
          const values = apiResponse[key];

          const result = {
            context: context,
            values: values
          };

          res.push(result);
        }

      res.filter(pref => pref !== undefined);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const APICreatePreferences = (urlPage,actualStores): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {

      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      const filteredObj = actualStores.filter(item => item !== "undefined");

      // const apiResponse: any = await SSOPostRequest2(
      //   '/api/createPreferences',
      const apiResponse: any = await SSOPostReq(
        '/api/setUserPreferences',
        {
          client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
          client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
          context: urlPage,
          values: filteredObj
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + token
          }
        }
      );
      resolve(apiResponse);

      APISavePreferences(urlPage, filteredObj);
     // resolve(kpiList);//nabeel change this from kpiLists to kpiList
    } catch (error) {
      reject(error);
    }
  });
};
export const APICreatePreferencesPulse = (reportID,obj): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;
      const token = storeState.user.sessionClient.access_token;
      // const apiResponse: any = await SSOPostRequest2(
      //   '/api/createPreferences',
      const apiResponse: any = await SSOPostReq(
        '/api/setUserPreferences',
        {
          client_id: 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688',
          client_secret: 'fp329-polk80s-ye04p1yy-45hx874z06',
          context: reportID,
          values: obj
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + token
          }
        }
      );
      resolve(apiResponse);

    } catch (error) {
      reject(error);
    }
  });
};
export const APIGetSavedPreferencesForPulse = (params): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;

      const userID= storeState.user.profile._id;
      const tenentID= storeState.user.profile.tenant._id;

      const baseUrl = 'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/user/'+''+tenentID+'/'+userID
      const timestamp = new Date().getTime(); // Current timestamp
      const urlWithTimestamp = `${baseUrl}?timestamp=${timestamp}`;

      const apiResponse = await getApiPrefrencses(urlWithTimestamp);
        var reportType = params.reportType.toUpperCase();

        if(reportType == 'ALL')
          reportType = "";
        else
         reportType = "-"+reportType;

        var result;
        if(apiResponse)
          result = apiResponseToPulseAll(apiResponse, reportType);
        else
          result = [];
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
export const APIGetSavedPreferencesForPulseById = (params): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const storeState: any = store.state;

      const userID= storeState.user.profile._id;
      const tenentID= storeState.user.profile.tenant._id;

      const baseUrl = 'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/user/'+''+tenentID+'/'+userID
      const timestamp = new Date().getTime(); // Current timestamp
      const urlWithTimestamp = `${baseUrl}?timestamp=${timestamp}`;

      const apiResponse = await getApiPrefrencses(urlWithTimestamp);
      var result;
      if(apiResponse)
        result = apiResponseToPulseAllByID(apiResponse,params.id);
      else
        result = [];
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

function apiResponseToPulseAllByID(apiResponse, id) {
  const transformedObjects = [];
  for (const key in apiResponse) {
    if (key == id) {
      const {
        storeCodes,
        kpis,
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        reportTitle,
        reportType,
        marketReport,
        lastWeekActionPlan,
        nextActionPlan,
        reviewActionPlan,
        reportID,
        downloadPath,
        numberOfEmployees,
        createdOn,
        updatedOn,
        fileName
      } = apiResponse[key];

      if(storeCodes)
      {
        var reportTypeTitle = key?key.split('-'):'';
        const transformedObject = {
          storeCodes,
          kpis,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          useCustomCalendar: false,
          numberOfEmployees: numberOfEmployees,
          reportType: reportType?reportType : reportTypeTitle[1],
          attendanceStaff: null,
          reportTitle: reportTitle,
          marketReport,
          lastWeekActionPlan,
          nextActionPlan: nextActionPlan || null,
          reviewActionPlan: reviewActionPlan || null,
          updatedBy: null,
          reportID,
          fileName,
          downloadPath: fileName,
          createdOn,
          updatedOn,
          createdBy: null
        };

        transformedObjects.push(transformedObject);
      }
    }
  }

  return transformedObjects;
}
function apiResponseToPulseAll(apiResponse,kpiType) {
  const transformedObjects = [];
  for (const key in apiResponse) {
    if (key.startsWith("PeriodicReport"+kpiType)) {
      const {
        storeCodes,
        kpis,
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        reportTitle,
        reportType,
        marketReport,
        lastWeekActionPlan,
        nextActionPlan,
        reviewActionPlan,
        reportID,
        downloadPath,
        numberOfEmployees,
        createdOn,
        updatedOn,
        fileName
      } = apiResponse[key];

      if(storeCodes)
      {
        var reportTypeTitle = key?key.split('-'):'';
        const transformedObject = {
          storeCodes,
          kpis,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          useCustomCalendar: false,
          numberOfEmployees: numberOfEmployees,
          reportType: reportType?reportType : reportTypeTitle[1],
          attendanceStaff: null,
          reportTitle: key,
          reportLabel: reportTitle,
          marketReport,
          lastWeekActionPlan,
          nextActionPlan: nextActionPlan || null,
          reviewActionPlan: reviewActionPlan || null,
          updatedBy: null,
          reportID,
          fileName,
          downloadPath: fileName,
          createdOn,
          updatedOn,
          createdBy: null
        };

        transformedObjects.push(transformedObject);
      }
    }
  }

  return transformedObjects;
}
