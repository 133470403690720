// import { APIGetAllStores } from '@/api/store';
import { APIFetchStoresFida } from '@/api/storeFida';


export const useJBStoreOption = async () => {
  // const storeList = await APIGetAllStores();
  const storeList: any = await APIFetchStoresFida('storesOnly');

  const storeOption = storeList.map(singleStore => {
    return {
      label: singleStore.storeCode + ' -  ' + singleStore.name,
      value: singleStore.storeCode,
      ...singleStore
    };
  });
  // const additionalTestData = {
  //   floorPlanImagePath: '/images/layout/JBHiFiOfficeLayout.png',
  //   id: '1',
  //   label: '002 -  Tech Office Level 20',
  //   latitude: '-37.90017430',
  //   longitude: '144.100010310',
  //   name: 'Tech Office Level 29',
  //   storeCode: '002',
  //   value: '002'
  // };
  return storeOption;
};
