
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays
} from 'date-fns';
import moment from 'moment';
import {getDayDiffFromMonday, getStartDayOfWeek} from '@/util/calendar.js'
export default {
  data() {
    return {
      attrs: [
        {
          key: 'Holidays',
          highlight: true,
          popover: {
            label: ''
          },
          dates: []
        }
      ],
      retailCalendarMode: false,
      retailMonth: null,
      retailMonths: [],
      retailWeek: '',
      filterRetailWeeks: [],
      retailYear: null,
      retailMonthsOptions: [
        { label: this.$t('FilterBar.January'), value: 'Jan' },
        { label: this.$t('FilterBar.February'), value: 'Feb' },
        { label: this.$t('FilterBar.March'), value: 'Mar' },
        { label: this.$t('FilterBar.April'), value: 'Apr' },
        { label: this.$t('FilterBar.May'), value: 'May' },
        { label: this.$t('FilterBar.June'), value: 'Jun' },
        { label: this.$t('FilterBar.July'), value: 'Jul' },
        { label: this.$t('FilterBar.August'), value: 'Aug' },
        { label: this.$t('FilterBar.September'), value: 'Sep' },
        { label: this.$t('FilterBar.October'), value: 'Oct' },
        { label: this.$t('FilterBar.November'), value: 'Nov' },
        { label: this.$t('FilterBar.December'), value: 'Dec' }
      ],
      retailMonthSelection: '',
      retailWeeks: [],
      retailYears: [],
      demographicReport: false,
      queueReport: false,
      occ: false,
      trafficReport: false,
      scrolled: false,
      calenderMode: 'range',
      specialCalenderMode: false,
      dragValue: null,
      selectedPeriodState: false,
      selectedDateRange: [],
      dateOptionsSelectedModel: {
        label: this.$t("FilterBar.this_week"),
        value: "THIS_WEEK",
        disable: false
      },
      dateOptionsComparedModel: {
        label: this.$t('FilterBar.previous_period'),
        value: 'PREVIOUS_PERIOD',
        disable: false
      },
      marketingCampaignMode: false,
      marketingCampaignOption: null,
      marketingCampaignOptionList: [],
      dateOptionsSelected: [
        {
          label: this.$t('FilterBar.today'),
          value: 'TODAY'
        },
        {
          label: this.$t('FilterBar.yesterday'),
          value: 'YESTERDAY'
        },

        {
          label: this.$t('FilterBar.this_week'),
          value: 'THIS_WEEK'
        },
        {
          label: this.$t('FilterBar.last_week'),
          value: 'LAST_WEEK'
        },
        {
          label: this.$t('FilterBar.this_month'),
          value: 'THIS_MONTH'
        },
        {
          label: this.$t('FilterBar.last_month'),
          value: 'LAST_MONTH'
        },
        {
          label: this.$t('FilterBar.this_year'),
          value: 'THIS_YEAR'
        },

        {
          label: this.$t('FilterBar.last_year'),
          value: 'LAST_YEAR'
        }
      ],

      dateOptionsCompared: [
        {
          label: this.$t('FilterBar.previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        {
          label: this.$t('FilterBar.previous_year_same_period'),
          value: 'PREVIOUS_YEAR_SAME_PERIOD'
        },
        {
          label: this.$t('FilterBar.previous_year_same_day_of_week'),
          value: 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
        },

        //
      ],
      selectedPeriod: {
        start: new Date(),
        end: new Date()
      },
      comparedPeriod: {
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1)
      },
      lastTimeStamp: Date.now(),
      startDayFromMonday: 0,
      startDayOfWeek: 1,
    };
  },
  computed: {
    ...mapState('user', ['profile', 'startOfWeek', "defaultSettings"]),
    ...mapGetters('user', ['getLocale']),
    ...mapState('filter', ['filter', 'holidays', 'retailCalendar']),
    ...mapState('store', ['stores']),
    ...mapState('marketplace', ['filterLibrary']),
    ...mapState('comparison', ['marketingCampaign', 'compareDates']),
    comparedPeriodText() {
      return `${this.getFormattedDate(
        this.comparedPeriod.start
      )}(${this.getFullDay(
        this.comparedPeriod.start
      )}) ~ ${this.getFormattedDate(this.comparedPeriod.end)}(${this.getFullDay(
        this.comparedPeriod.end
      )}) (${this.getDifferenceDays(
        this.comparedPeriod.start,
        this.comparedPeriod.end
      ) + 1})`;
    },
    tutuannaMode() {
      return this.dateOptionsComparedModel.value === 'tutuanna_calender';
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // Defaults to true when using slot
        }
      };
    },
    dragComparedValue: function() {
      let value = '';
      if (this.dragValue && this.compareDates) {
        value = this.getComparedDate(this.formatDate(this.dragValue));
        return this.formatDate(value);
      } else {
        return value;
      }
    },
    numberfRetailWeeks() {
      let year = this.retailYear
        ? this.retailYear.value
        : Number(moment(new Date(this.selectedPeriod.start)).format('YYYY'));
      return this.retailCalendar.find(
        v => v.label.toString() === year.toString()
      )?.numberOfWeeks;
    },
    startDateOfRetailYear() {
      let year = this.retailYear
        ? this.retailYear.value
        : Number(moment(new Date(this.selectedPeriod.start)).format('YYYY'));
      return this.retailCalendar.find(
        v => v.label.toString() === year.toString()
      )?.startDate;
    }
  }, // computed
  watch: {
    retailYear() {
      let num = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.numberOfWeeks;
      let startDateRetail = this.retailCalendar.find(
        v => v.label.toString() === this.retailYear.value.toString()
      )?.startDate;
      this.retailMonthSelection = {
        label: this.$t('FilterBar.January'),
        value: 'Jan'
      };
      this.getRetailWeeks(startDateRetail, 12, num);
    },
    retailWeek() {
      if (
        new Date(this.retailWeek?.value?.startingPeriod) < new Date() &&
        new Date(this.retailWeek?.value?.endingPeriod) < new Date()
      ) {
        this.selectedPeriod = {}
        this.$set(this.selectedPeriod, "start", new Date(this.retailWeek?.value?.startingPeriod))
        this.$set(this.selectedPeriod, "end", new Date(this.retailWeek?.value?.endingPeriod))
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    retailMonth() {
      if (
        new Date(this.retailMonth?.value?.startingPeriod) < new Date() &&
        new Date(this.retailMonth?.value?.endingPeriod) < new Date()
      ) {
        this.selectedPeriod = {}
        this.$set(this.selectedPeriod, "start", new Date(this.retailMonth?.value?.startingPeriod))
        this.$set(this.selectedPeriod, "end", new Date(this.retailMonth?.value?.endingPeriod))
      } else {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `Please select range lower than present day`
        });
      }
    },
    retailMonthSelection() {
      this.filterRetailWeeks = this.retailWeeks.filter(v =>
        v.label.includes(this.getSelectedMonth(this.retailMonthSelection.value))
      );
      this.retailWeek = this.filterRetailWeeks[0];
    },
    marketingCampaign() {
      if (this.marketingCampaign && this.marketingCampaign.length > 0) {
        this.dateOptionsSelected = [
          ...this.dateOptionsSelected,
          {
            label: this.$t('FilterBar.marketing_campaigns'),
            value: 'marketing_campaigns'
          }
        ];
      }
    },
    compareDates() {
      if (this.compareDates && this.compareDates.length > 0) {
        this.dateOptionsCompared = [
          ...this.dateOptionsCompared,
          {
            label: this.$t('FilterBar.special_calendar'),
            value: 'tutuanna_calender'
          }
        ];
      }
    },
    holidays() {
      this.attrs = [
        ...this.holidays.map(v => ({
          key: 'Holidays',
          // highlight: true,
          popover: {
            label: v.value,
            labelClass: 'white-text',
            labelStyle: { color: 'white' },
            class: 'white-text'
          },
          dates: v.date,
          bar: {
            color: 'red',
            class: 'my-dot-class'
          }
        }))
      ];
    },
    'filter.daysOfWeek': {
      handler: 'refreshPage'
    },
    'filter.storeCodes': {
      handler: 'refreshPage'
    },
    $router(to, from) {
      this.occ = this.$router.history.current.name === 'occupancy_report';
      this.trafficReport =
        this.$router.history.current.name === 'traffic_report';
      this.queueReport = this.$router.history.current.name === 'queue_report';
      this.demographicReport =
        this.$router.history.current.name === 'demographics-reportF';
    },
    selectedPeriod: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        try {
          if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
            const diffDays = differenceInDays(
              this.selectedPeriod.start,
              this.selectedPeriod.end
            );
            const diffDaysPositive =
              diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
            const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
            this.comparedPeriod.start = addWeeks(
              this.selectedPeriod.start,
              -weeks
            );
            this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
          } else if (
            this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
          ) {
            this.comparedPeriod.start = addYears(
              new Date(this.selectedPeriod.start),
              -1
            );
            this.comparedPeriod.end = addYears(
              new Date(this.selectedPeriod.end),
              -1
            );
          }
        } catch (e) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: `${e}`
          });
        }
      }
    },
    dateOptionsSelectedModel: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    },
    marketingCampaignOption: function() {
      if (!this.tutuannaMode && this.marketingCampaignMode) {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    }
  },
  async created() {
    this.startDayFromMonday = getDayDiffFromMonday();
    this.startDayOfWeek = getStartDayOfWeek();
    if (this.retailCalendar.length > 0) {
      this.dateOptionsSelected = [
        ...this.dateOptionsSelected,
        {
          label: this.$t('FilterBar.retail_calendar_monthly'),
          value: 'retail_calendar_monthly'
        },
        {
          label: this.$t('FilterBar.retail_calendar'),
          value: 'retail_calendar'
        }
      ];

      this.retailYears = this.retailCalendar.map(v => ({
        label: v.label,
        value: v.label
      }));
      this.getRetailWeeks(
        this.startDateOfRetailYear,
        12,
        this.numberfRetailWeeks
      );
    }
    this.selectedPeriod.start = new Date(this.filterLibrary.startingPeriod);
    this.selectedPeriod.end = new Date(this.filterLibrary.endingPeriod);
    this.comparedPeriod.start = new Date(this.filterLibrary.startingCompared);
    this.comparedPeriod.end = new Date(this.filterLibrary.endingCompared);
    this.occ = this.$router.history.current.name === 'occupancy_report';
    this.trafficReport = this.$router.history.current.name === 'traffic_report';
    this.queueReport = this.$router.history.current.name === 'queue_report';
    this.demographicReport =
      this.$router.history.current.name === 'demographics-reportF';
    try {
      await this.fetchHolidays();
      await this.fetchCompareDates();
      await this.fetchMarketingCampaign();
      // await this.fetchKPIMetrics();
      this.getSelectedDateRange();
    } catch (e) {
      handleErrorUI(e);
    }
    this.marketingCampaignOptionList = this.marketingCampaign
      ? this.marketingCampaign.map(mc => {
          return {
            label: mc.campaignName,
            value: {
              startDate: mc.startDate,
              endDate: mc.endDate
            }
          };
        })
      : [];
    // await this.fetchKPIMetrics();
    this.getSelectedDateRange();
    this.submitDate();
  }, // created
  mounted(){
    this.dateOptionsSelectedModel =  {
          label: this.$t('FilterBar.this_year'),
          value: 'THIS_YEAR'
        }

    this.dateOptionsSelectedModelChanged();
  },
  methods: {
    getSelectedMonth(val) {
      if (val.includes('Jan')) return '/01/';
      else if (val.includes('Feb')) return '/02/';
      else if (val.includes('Mar')) return '/03/';
      else if (val.includes('Apr')) return '/04/';
      else if (val.includes('May')) return '/05/';
      else if (val.includes('Jun')) return '/06/';
      else if (val.includes('Jul')) return '/07/';
      else if (val.includes('Aug')) return '/08/';
      else if (val.includes('Sep')) return '/09/';
      else if (val.includes('Oct')) return '/10/';
      else if (val.includes('Nov')) return '/11/';
      else if (val.includes('Dec')) return '/12/';
    },
    onDayClick() {
      this.dateOptionsSelectedModel = {
        label: this.$t("custom_range"),
        value: "CUSTOM",
        disable: true,
      };
    },
    getRetailWeeks(startingDateVal, noOfMonths, noOfWeeks) {
      let startingDate = new Date(startingDateVal);
      let startingMonth = new Date(startingDateVal);
      let monthsVal = [];
      this.retailWeeks = [];
      this.retailMonths = [];
      for (let i = 0; i < noOfMonths; i++) {
        let monthStart = moment(startingMonth).format('YYYY-MM-DD');
        let monthEnd = moment(
          addDays(addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4), -1)
        ).format('YYYY-MM-DD');
        startingMonth = addWeeks(startingMonth, (i + 1) % 3 === 0 ? 5 : 4);
        monthsVal.push({
          label:
            moment(monthStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(monthEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: monthStart,
            endingPeriod: monthEnd
          }
        });
      }
      this.retailMonths = monthsVal;
      for (let i = 0; i < noOfWeeks; i++) {
        let weekStart = moment(startingDate).format('YYYY-MM-DD');
        let weekEnd = moment(addDays(addWeeks(startingDate, 1), -1)).format(
          'YYYY-MM-DD'
        );
        const weekVal = {
          label:
            moment(weekStart).format('YYYY/MM/DD') +
            ' - ' +
            moment(weekEnd).format('YYYY/MM/DD'),
          value: {
            startingPeriod: weekStart,
            endingPeriod: weekEnd
          }
        };
        startingDate = addWeeks(startingDate, 1);
        this.retailWeeks.push(weekVal);
      }
    },
    getSelectedDateRange() {
      let dates = [];
      if (this.selectedPeriod?.start && this.selectedPeriod?.end) {
        let startDate = this.selectedPeriod.start;
        while (startDate <= this.selectedPeriod.end) {
          dates.push(startDate);
          startDate = addDays(startDate, 1);
        }
        this.selectedDateRange = dates.map(dat => this.formatDate(dat));
      }
    },
    dateOptionsSelectedModelChanged() {
      const selectedOption = this.dateOptionsSelectedModel.value;
      this.marketingCampaignMode = false;
      this.retailCalendarMode = false;
      if (selectedOption === 'TODAY') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = new Date();
        this.selectedPeriod.end = new Date();
      } else if (selectedOption === 'YESTERDAY') {
        this.selectedPeriod = {};
        const yesterday = startOfYesterday();
        this.selectedPeriod.start = yesterday;
        this.selectedPeriod.end = yesterday;
      } else if (selectedOption === 'THIS_WEEK') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfWeek(new Date(), { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfWeek(new Date());
      } else if (selectedOption === "LAST_WEEK") {
        this.selectedPeriod = {};
        const d = addWeeks(new Date(), -1);
        this.selectedPeriod.start = startOfWeek(d, { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = addDays(this.selectedPeriod.start, 6)
      } else if (selectedOption === 'THIS_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfMonth(new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(addMonths(new Date(), -1));
        this.selectedPeriod.end = endOfMonth(addMonths(new Date(), -1));
      } else if (selectedOption === 'THIS_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfYear(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfYear(new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = addYears(startOfYear(new Date()), -1);
        this.selectedPeriod.end = addYears(endOfYear(new Date()), -1);
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      } else if (selectedOption === 'retail_calendar') {
        this.retailCalendarMode = true;
      } else if (selectedOption === 'retail_calendar_monthly') {
        this.retailCalendarMode = true;
      }
      this.dateOptionsComparedModelChanged();
    },
    dateOptionsComparedModelChanged() {
      this.specialCalenderMode = false;
      // const selectedOption = this.dateOptionsSelectedModel.value;
      const comparedOption = this.dateOptionsComparedModel.value;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        this.comparedPeriod = {};

        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
        this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        this.comparedPeriod = {};
        this.comparedPeriod.start = addYears(new Date(), -1);
        this.comparedPeriod.end = addYears(new Date(), -1);
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
        this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
    },
    getComparedDatesArray() {
      this.comparedPeriod = {};
      this.comparedPeriod = this.selectedDateRange.map(
        dat => new Date(this.getComparedDate(dat))
      );
    },
    marketingCampaignOptionChange() {
      let val = this.marketingCampaignOption.value;
      this.selectedPeriod = {};
      this.selectedPeriod = {
        start: new Date(val.startDate),
        end: new Date(val.endDate)
      };
    },
    ...mapMutations('filter', [
      'setCalendarDatesToStore',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag'
    ]), // mapMutations
    ...mapActions('comparison', [
      'fetchCompareDates',
      'fetchMarketingCampaign',
      'fetchHolidays',
      'fetchKPIMetrics'
    ]),
    ...mapMutations('marketplace', [
      'setLibraryStartingPeriod',
      'setLibraryEndingPeriod',
      'setLibraryStartingCompared',
      'setLibraryEndingCompared'
    ]),
    ...mapActions('filter', ['fetchHolidays']),
    startingPeriodChanged(val) {
      // this.selectedPeriod.start = val;
      // this.selectedPeriod.start = ({ startingPeriod: val });
    }, // startingPeriodChanged
    endingPeriodChanged(val) {
      // this.setEndingPeriod({ endingPeriod: val });
      // this.selectedPeriod.end = val;
    }, // endingPeriodChanged
    startingComparedChanged(val) {
      // this.comparedPeriod.start = val;
      // this.setStartingCompared({ startingCompared: val });
    }, // startingComparedChanged
    endingComparedChanged(val) {
      // this.comparedPeriod.end = val;
      // this.setEndingCompared({ endingCompared: val });
    }, // endingComparedChanged
    refreshPage() {
      this.$emit('refreshPage');
    }, // refreshPage
    submitDate() {

      this.setLibraryStartingPeriod({
        startingPeriod: format(this.selectedPeriod.start, 'yyyy-MM-dd')
      });
      this.setLibraryEndingPeriod({
        endingPeriod: format(this.selectedPeriod.end, 'yyyy-MM-dd')
      });
      this.setLibraryStartingCompared({
        startingCompared: format(this.comparedPeriod.start, 'yyyy-MM-dd')
      });
      this.setLibraryEndingCompared({
        endingCompared: format(this.comparedPeriod.end, 'yyyy-MM-dd')
      });
      this.$emit('changeDate', {
        startDate: this.selectedPeriod.start,
        endDate: this.selectedPeriod.end,
        compareDateStart: this.comparedPeriod.start,
        compareDateEnd: this.comparedPeriod.end
      });
      // if (!this.tutuannaMode) {
      //   this.setStartingCompared({
      //     startingCompared: format(this.comparedPeriod.start, 'yyyy-MM-dd')
      //   });
      //   this.setEndingCompared({
      //     endingCompared: format(this.comparedPeriod.end, 'yyyy-MM-dd')
      //   });
      // }
      // this.setCustomCalenderFlag({ value: this.tutuannaMode });

      this.hide();
      // this.refreshPage();
    }, // submitDate
    hide() {
      this.$refs.periodFilter.hide();
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = date.toISOString().substr(0, 10);
      return newDate;
    },
    getComparedDate(date) {
      if (date && this.compareDates) {
        let dateObj = this.compareDates.filter(dat => dat.date === date);
        if (dateObj.length > 0) {
          return new Date(dateObj[0].comparedDate);
        } else {
          return '';
        }
      }
    }, // hide
    handleScroll(evt, el) {
      if (window.scrollY > 10) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('FilterBar.Sun');
      }
      if (dayVal === 1) {
        return this.$t('FilterBar.Mon');
      }
      if (dayVal === 2) {
        return this.$t('FilterBar.Tue');
      }
      if (dayVal === 3) {
        return this.$t('FilterBar.Wed');
      }
      if (dayVal === 4) {
        return this.$t('FilterBar.Thu');
      }
      if (dayVal === 5) {
        return this.$t('FilterBar.Fri');
      }
      if (dayVal === 6) {
        return this.$t('FilterBar.Sat');
      }
    },
    getDifferenceDays(a, b) {
      let val = differenceInDays(new Date(a), new Date(b));
      return val < 0 ? -val : val;
    },
    getFormattedDate(date) {
      return moment(new Date(date)).format('YYYY/MM/DD');
    },
    dropDownToggled() {
      // setTimeout(() => {
      //   document.querySelector(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      // }, 1000);
    },
    getDayDiffFromMonday() {
      const weekday = ["friday","saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"];
      let i = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
      this.startDayFromMonday = i - 3;
      const indexes_of_weekday = [0,1,2,3,4,5,6];
    },
    getStartDayOfWeek() {
      const weekday = ["sunday","monday", "tuesday", "wednesday", "thursday","friday","saturday"];
      this.startDayOfWeek = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
    },
  } // methods
}; // export default
