import {getStoreTimingsForDatesAndStores, formatIntDate} from '@/util/utilFidaFunctions';
import store from '@/store/';
import { getStoreCodesByLocations } from '../../util/utilFunctions';
import {
  addDays,
  addWeeks,
  differenceInDays
} from "date-fns";
import moment from "moment";
import { getKpiTitle } from '@/util/utilFidaFunctions';
import {
  GetReportingSumDataFidaV2,
  GetKpisPlainDataFidaForDemographics,
  GetReportingSumDataByLocationFidaV2OnlyExport,
  getStoreAreaByStoeCodes
} from '@/api/fida';

import * as XLSX from 'xlsx';
import { getAllSigns } from '@/util/replaceFidaKpi';

// All Stores Daily New
function formatResponseAllStoresDailyReport(apiResponse, targetKpiName, lang) {
 // apiResponse = sortingByOrder(apiResponse, 'StoreNumber', 'ASC','alldaily');
  const formattedData = {};

  for (const kpi of apiResponse) {
    const storeCode = kpi._id.split('_')[1];
    const date = formatIntDate(kpi._id.split('_')[0]);
    const sales = kpi[targetKpiName];

    if (!formattedData[storeCode]) {
      const storeName = getStoreNameByCode(storeCode);
      formattedData[storeCode] = {
        storeName: storeName,
      };
    }

    formattedData[storeCode][date] = sales;
  }

  // Sort dates in ascending order
  for (const storeCode of Object.keys(formattedData)) {
    const storeData = formattedData[storeCode];
    const sortedDates = Object.keys(storeData).filter(key => key !== "storeName").sort();
    storeData.sortedDates = sortedDates;
  }

  const result = [];
  var headerRow = [];

  // Create the header row
  if(!Object.keys(formattedData)[0])
  {
    if (lang == 'en') {
      headerRow = ["Store Code", "Store Name"];
    } else {
      headerRow = ["店コード", "店名"];
    }
  }
  else
  {
    if (lang == 'en') {
      headerRow = ["Store Code", "Store Name", ...formattedData[Object.keys(formattedData)[0]].sortedDates];
    } else {
      headerRow = ["店コード", "店名", ...formattedData[Object.keys(formattedData)[0]].sortedDates];
    }
  }

  result.push(headerRow.join(', '));

  for (const storeCode of Object.keys(formattedData)) {
    const storeData = formattedData[storeCode];
    const row = [storeCode, storeData.storeName];
    for (const date of storeData.sortedDates) {
      row.push(storeData[date]);
    }
    result.push(row.join(', '));
  }
  return result.join("\n");
}

function sortByDate(data) {
  return data.sort((a, b) => {
      // Extract dates from _id field
      const dateA = a._id.split('_')[0];
      const dateB = b._id.split('_')[0];


      // Compare dates
      return dateA- dateB;
  });
}
//Daily Report New
function formatResponseDailyReport(apiResponse: any, lang: string, orderKey, order) {
  //order = "desc";
  apiResponse = sortByDate(apiResponse);//sortingByOrder(apiResponse, orderKey, order, 'daily');
  const formattedData = {};

  // Process the API response data

  apiResponse.forEach(item => {
    const storeCode = item._id.split('_')[1];
    const date = formatIntDate(item._id.split('_')[0]);
    const time = date;//date.toString(); // Format hour as "HH:00"

    // Create a key for the storeCode if it doesn't exist in the formatted data
    if (!formattedData[storeCode]) {
      formattedData[storeCode] = [];
    }

    // Extract and format the KPIs dynamically
    const kpis = Object.keys(item)
      .filter(key => key !== "_id") // Exclude "_id" field
      .map(key => item[key]?item[key].toLocaleString():0); // Format KPI values as strings with commas

    formattedData[storeCode].push([time, ...kpis]);
  });
  // Extract the list of unique KPIs dynamically
  const allKPIs = [...new Set(apiResponse.flatMap(item => Object.keys(item)).filter(key => key !== "_id"))];

  // Find the KPI with the most data entries
  const maxKpiName = allKPIs.reduce((maxKpi: any, kpiName: any) => {
    const kpiDataArr = apiResponse.map(item => item[kpiName]);
    const nonUndefinedDataCount = kpiDataArr.filter(data => data !== undefined).length;
    const maxKpiDataArr = apiResponse.map(item => item[maxKpi]);
    const maxKpiDataCount = maxKpiDataArr.filter(data => data !== undefined).length;

    return nonUndefinedDataCount > maxKpiDataCount ? kpiName : maxKpi;
  }, allKPIs[0]);

  const result: string[] = [];

  if (lang === 'en') {
    result.push(`Date,Store Name,Store Code, ${allKPIs.join(", ")}`);//Date	Store Name	Store Code	Sales	Traffic

  } else {
    result.push(`Date,店名,店コード, ${allKPIs.join(", ")}`);
  }



  apiResponse.forEach(item => {
    const storeCode = item._id.split('_')[1];
    const date = formatIntDate(item._id.split('_')[0]);
    const storeName = getStoreNameByCode(storeCode);
    const kpiValues = allKPIs.map((kpi: any) => (item[kpi] ? item[kpi] : 0));

//    const kpiValues = allKPIs.map(kpi => (item[kpi] ? item[kpi] : 0));
    result.push(` ${date},${storeName},${storeCode},${kpiValues.join(", ")}`);
  });


  var kpiTotals = {};
  allKPIs.forEach((kpi: any) => {
    const total = apiResponse.reduce((sum, item) => sum + (item[kpi] ? Number(item[kpi]) : 0), 0);
    kpiTotals[kpi] = total;
  });
  const count = apiResponse.length; // Replace with your desired count
  kpiTotals = applyCount(kpiTotals, count);
  // Add the totals row to the result
  const totalsRow = ['', '','', ...allKPIs.map((kpi: any) => kpiTotals[kpi])];
  result.push(totalsRow.join(', '));

  return result.join("\n");
}

//Hourly Report New
function formatResponseHourlyKpiReport(apiResponse: any, lang: string, orderKey, order) {
  apiResponse = sortingByOrder(apiResponse, orderKey, order,'hourly');


  const formattedData = {};

  // Process the API response data
  apiResponse.forEach(item => {
    const storeCode = item._id.split('_')[1];
    const hour = item._id.split('_')[0];
    const time = `${hour.toString().padStart(2, '0')}:00`; // Format hour as "HH:00"

    // Create a key for the storeCode if it doesn't exist in the formatted data
    if (!formattedData[storeCode]) {
      formattedData[storeCode] = [];
    }
    // Extract and format the KPIs dynamically
    const kpis = Object.keys(item)
      .filter(key => key !== "_id") // Exclude "_id" field
      .map(key => item[key]?item[key].toLocaleString():0); // Format KPI values as strings with commas

    formattedData[storeCode].push([time, ...kpis]);
  });

  // Extract the list of unique KPIs dynamically
  const allKPIs = [...new Set(apiResponse.flatMap(item => Object.keys(item)).filter(key => key !== "_id"))];

  // Find the KPI with the most data entries
  const maxKpiName = allKPIs.reduce((maxKpi: any, kpiName: any) => {
    const kpiDataArr = apiResponse.map(item => item[kpiName]);
    const nonUndefinedDataCount = kpiDataArr.filter(data => data !== undefined).length;
    const maxKpiDataArr = apiResponse.map(item => item[maxKpi]);
    const maxKpiDataCount = maxKpiDataArr.filter(data => data !== undefined).length;

    return nonUndefinedDataCount > maxKpiDataCount ? kpiName : maxKpi;
  }, allKPIs[0]);

  const result: string[] = [];

  if (lang === 'en') {
    result.push(`Store Code, Hour, ${allKPIs.join(", ")}`);
  } else {
    result.push(`店コード, 時間, ${allKPIs.join(", ")}`);
  }

  apiResponse.forEach(item => {
    const storeCode = item._id.split('_')[1];
    const hour = item._id.split('_')[0];
    const kpiValues = allKPIs.map((kpi: any) => (item[kpi] ? item[kpi] : 0));
    result.push(` ${storeCode},${hour.toString().padStart(2, '0')}:00,  ${kpiValues.join(", ")}`);
  });


  var kpiTotals = {};
  allKPIs.forEach((kpi: any) => {
    const total = apiResponse.reduce((sum, item) => sum + (item[kpi] ? Number(item[kpi]) : 0), 0);
    kpiTotals[kpi] = total;
  });

  const count = apiResponse.length; // Replace with your desired count
  kpiTotals = applyCount(kpiTotals, count);
  // Add the totals row to the result
  const totalsRow = ['', '', ...allKPIs.map((kpi: any) => kpiTotals[kpi])];
  result.push(totalsRow.join(', '));

  return result.join("\n");
}
function applyCount(response, count) {
  const keysToSum = ["cn", "ap", "upt", "rov", "aip", "spt", "tpt", "sl", "sh", "cs", "se", "cr", "dw", "st", "tra"];

  const updatedResponse = { ...response };

  keysToSum.forEach(key => {
    if (response[key] !== undefined && typeof response[key] === "number") {
      if(key == "cn" || key == "upt" || key == "tpt" || key == "cr")
        updatedResponse[key] = (response[key] / count).toFixed(2);
      else
        updatedResponse[key] = Math.round(response[key] / count);
    }
  });

  return updatedResponse;
}


//Detailed Report New
function formatResponseDetailed(apiResponse, lang: string, orderKey, order) {
  if (orderKey && order) {
    if (orderKey.toUpperCase() != 'REGION' && orderKey.toUpperCase() != 'DISTRICT'
    || orderKey.toUpperCase() != 'PEERGROUPS1' && orderKey.toUpperCase() != 'PEERGROUPS2'
    || orderKey.toUpperCase() != 'STORE' && orderKey.toUpperCase() != 'SV') {
      if (orderKey.toUpperCase() == 'STORENUMBER') {
        apiResponse.kpis.sort((a, b) => {
          const aValue = a._id.split('_')[0];//.storeCode;
          const bValue = b._id.split('_')[0];//.storeCode;
          if(aValue || bValue)
            if (order.toUpperCase() === 'ASC') {
              return aValue - bValue;
            } else {
              return bValue - aValue;
            }
        });
      }
      else
        apiResponse.kpis.sort((a, b) => {
          const aValue = a[orderKey];
          const bValue = b[orderKey];
          if(aValue || bValue)
            if (order.toUpperCase() === 'ASC') {
              return aValue - bValue;
            } else {
              return bValue - aValue;
            }
        });
      }
  }

  const storeSummaries = [];
  const kpiKeys = Object.keys(apiResponse.kpis[0]).filter((key) => key !== "_id");

  const storeCodes = new Set();

  apiResponse.kpis.forEach((data) => {
    storeCodes.add(data._id.split('_')[0]);
  });

  storeCodes.forEach((storeCode) => {
    const storeSummary = {
      storeCode,
      storeName: '', // Add store name retrieval logic here
    };

    kpiKeys.forEach((kpiKey) => {
      const storeKpiData = apiResponse.kpis.filter(
        (data) => data._id.split('_')[0] === storeCode
      );
      const storeLastWeekKpiData = apiResponse.kpisLastWeek.filter(
        (data) => data._id.split('_')[0] === storeCode
      );
      const storeCompareKpiData = apiResponse.kpisCompare.filter(
        (data) => data._id.split('_')[0] === storeCode
      );

      var actualValue = storeKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );
      var lastWeekValue = storeLastWeekKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );


      var prevYearValue = storeCompareKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );
      var yoyPercentage = (actualValue / lastWeekValue) ;
      var yoyPercentagePrevYear = (actualValue / prevYearValue) ;

      // if(kpiKey == 'cn' || kpiKey == 'cr' || kpiKey == 'dw')
      // {

      //   actualValue = actualValue/100;
      //   lastWeekValue = lastWeekValue/100;
      //   prevYearValue = prevYearValue/100;

      //   actualValue = Math.floor(actualValue *1000)/1000;
      //   lastWeekValue =Math.floor(lastWeekValue *1000)/1000;
      //   prevYearValue = Math.floor(prevYearValue *1000)/1000;
      // }

      if (!isFinite(yoyPercentage) === true) {
        yoyPercentage = 0;
      }
      if (!isFinite(yoyPercentagePrevYear) === true) {
        yoyPercentagePrevYear = 0;
      }
      //
      yoyPercentage = Math.floor(yoyPercentage *1000)/1000;
      yoyPercentagePrevYear = Math.floor(yoyPercentagePrevYear *1000)/1000;
      //yoyPercentagePrevYear = Math.floor(yoyPercentagePrevYear *1000)/1000;
      // Update the storeSummary object properties as follows:



      storeSummary[`actual${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(actualValue) || actualValue == null ? 0 : actualValue;
      storeSummary[`lastWeek${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(lastWeekValue) || lastWeekValue == null ? 0 : lastWeekValue;
      storeSummary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}Percentage`] = isNaN(yoyPercentage) || yoyPercentage == null ? 0 : yoyPercentage;
      storeSummary[`previousYear${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(prevYearValue) || prevYearValue == null ? 0 : prevYearValue;
      storeSummary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}PercentagePrevYear`] = isNaN(yoyPercentagePrevYear) || yoyPercentagePrevYear == null ? 0 : yoyPercentagePrevYear;
    });
    storeSummaries.push(storeSummary);
  });
  // Define the header row based on your language preference
  var result = [];
  if (lang == 'en') {
    result.push(generateAdditionalHeaderRow(kpiKeys));
    result.push(
      'Store Code,Store Name,District,Region,Area Manager,Peer Group 1,Peer Group 2,'
      + generateKPIHeadersDetailed(kpiKeys,lang)
    );

  } else {
    result.push(generateAdditionalHeaderRow(kpiKeys));
    result.push(
      '店コード,店名,東西,地域,エリアマネージャー,共通グループ 1,共通グループ 2,'
      +generateKPIHeadersDetailed(kpiKeys,lang)
    );
  }

  storeSummaries.forEach((summary) => {
const storeGroup: any = getStoreGroups(summary.storeCode);
var peerGroup1 = storeGroup.peerGroups[0]?storeGroup.peerGroups[0].name:'';
var peerGroup2 = storeGroup.peerGroups[1]?storeGroup.peerGroups[1].name:'';

if(storeGroup.peerGroups[0]?.order == 2)
{
  peerGroup2 = peerGroup1;
  peerGroup1 = '';
}
    const row = [
      summary.storeCode,
      getStoreNameByCode(summary.storeCode),
      storeGroup.districtName?storeGroup.districtName:'',//'東西', // Add logic for 東西, 地域, エリアマネージャー, 共通グループ 1, 共通グループ 2
      storeGroup.regionName?storeGroup.regionName:'',
      storeGroup.areaManagerName?storeGroup.areaManagerName:'',
      peerGroup1,
      peerGroup2
      // storeGroup.peerGroups[0]?storeGroup.peerGroups[0]:'',
      // storeGroup.peerGroups[1]?storeGroup.peerGroups[1]:''
    ];

    kpiKeys.forEach((kpiKey) => {
      row.push(summary[`actual${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(summary[`lastWeek${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(Number(summary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}Percentage`]).toFixed(3));
      row.push(summary[`previousYear${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(Number(summary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}PercentagePrevYear`]).toFixed(3));
    });

    result.push(row.join(','));
  });
  return result.join('\n');
}

function formatResponseSummaryByGroup(apiResponse:any, kpis)
{

  var storeGroupsByStoreCodeList = getStoreGroupsByStoreCodeList();
  var results = {};

  for (var groupType in storeGroupsByStoreCodeList) {
    var names = storeGroupsByStoreCodeList[groupType].split(',').map(name => name.trim());

    for (var i = 0; i < names.length; i++) {
      var name = names[i];
      var storeCodesByGroupNames = getStoreCodesByLocations(groupType, name);
      if(storeCodesByGroupNames != "")
      {
        if (!results[groupType]) {
          results[groupType] = {};
        }

        if (!results[groupType][name]) {
          results[groupType][name] = storeCodesByGroupNames;
        }
      }
    }
  }

  const combinedResult = {};

  for (const key in results) {
      Object.assign(combinedResult, results[key]);
  }

const finalResult = {};
const allValues = Object.values(combinedResult).flat();

// Use a Set to get unique values
const uniqueStoreCodes = [...new Set(allValues)];


for (const key in combinedResult) {

  const storeCodes = combinedResult[key];
  const kpiSums = {};
  const kpiAverages = {};

  for (const code of storeCodes) {
    const storeData = apiResponse.find(item => item._id.split('_')[0] === code);

    if (storeData) {
      for (const kpi in storeData) {
        if (kpi !== "_id") {
          if (kpiSums[kpi] === undefined) {
            kpiSums[kpi] = 0;
         //   kpiAverages[kpi] = 0;
          }
          if(storeData[kpi] && storeData[kpi] != undefined && storeData[kpi] != null)
          {
            kpiSums[kpi] += storeData[kpi];
          }
          else
            kpiSums[kpi] += 0;
            // kpiAverages[kpi] += storeData[kpi];
        }
      }
    }


  }


  // if(kpiSums['conversion'])
  // {
  //   var v1 = Number(kpiSums['transactions']?kpiSums['transactions']:0);
  //   var v2 = Number(kpiSums['traffic']?kpiSums['traffic']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['conversion'] = Math.floor((v1/v2) *1000)/1000;
  // }

  // if(kpiSums['avgpurchase'])
  // {
  //   v1 = Number(kpiSums['sales']?kpiSums['sales']:0);
  //   v2 = Number(kpiSums['transactions']?kpiSums['transactions']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['avgpurchase'] = Math.round(v1/v2);
  // }

  // if(kpiSums['avgitemprice'])
  // {
  //   v1 = Number(kpiSums['sales']?kpiSums['sales']:0);
  //   v2 = Number(kpiSums['unitssold']?kpiSums['unitssold']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['avgitemprice'] = Math.round(v1/v2);
  // }

  // if(kpiSums['upt'])
  // {
  //   v1 = Number(kpiSums['unitssold']?kpiSums['unitssold']:0);
  //   v2 = Number(kpiSums['transactions']?kpiSums['transactions']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['upt'] = (v1/v2).toFixed(3);
  // }

  // if(kpiSums['rov'])
  // {
  //   v1 = Number(kpiSums['sales']?kpiSums['sales']:0);
  //   v2 = Number(kpiSums['traffic']?kpiSums['traffic']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['rov'] = Math.round(v1/v2);
  // }

  // if(kpiSums['stafflevel'])
  // {
  //   v1 = Number(kpiSums['traffic']?kpiSums['traffic']:0);
  //   v2 = Number(kpiSums['staffhours']?kpiSums['staffhours']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['stafflevel'] = Math.round(v1/v2);
  // }

  // if(kpiSums['closingstrength'])
  // {
  //   v1 = Number(kpiSums['traffic']?kpiSums['traffic']:0);
  //   v2 = Number(kpiSums['staffhours']?kpiSums['staffhours']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['closingstrength'] = Math.round(v1/v2);
  // }

  // if(kpiSums['staffefficiency'])
  // {
  //   v1 = Number(kpiSums['sales']?kpiSums['sales']:0);
  //   v2 = Number(kpiSums['staffhours']?kpiSums['staffhours']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['staffefficiency'] = Math.round(v1/v2);
  // }

  // if(kpiSums['capturerate'])
  // {
  //   v1 = Number(kpiSums['traffic']?kpiSums['traffic']:0);
  //   v2 = Number(kpiSums['passersby']?kpiSums['passersby']:-1);
  //   if(v2 == -1)
  //     v1 = 0;

  //   kpiSums['capturerate'] = ((v1/v2)).toFixed(3);
  // }

  if(kpiSums['spt']>=0)
  {
    var v1 = Number(kpiSums['sls']?kpiSums['sls']:0);
    var v2 = Number(getStoreAreaByStoeCodes(uniqueStoreCodes));

    if(v2 == -1)
      v1 = 0;

    kpiSums['spt'] = Math.round(v1/v2);
  }

  if(kpiSums['tpt']>=0)
  {
    v1 = Number(kpiSums['trf']?kpiSums['trf']:0);
    v2 = Number(getStoreAreaByStoeCodes(uniqueStoreCodes));
    if(v2 == -1)
      v1 = 0;

    kpiSums['tpt'] = (v1/v2).toFixed(2);
  }

  if(kpiSums['upt'])
  {
    v1 = Number(kpiSums['is']?kpiSums['is']:0);
    v2 = Number(kpiSums['trn']?kpiSums['trn']:-1);
    if(v2 == -1)
      v1 = 0;

    kpiSums['upt'] = (v1/v2).toFixed(2);
  }

  // have to remove extra kpis
  finalResult[key] = {
    _id: { storeCode: key },
    ...kpiSums
  };
}


//


const finalOutput = Object.values(finalResult).map((item:any) => ({
    _id: item._id,
    ...item
}));
//kpis
removeKeys(finalOutput, kpis.split(','));

  return finalOutput;
}

function removeKeys(dataArray, keysToKeep) {
  dataArray.forEach(item => {
    for (let key in item) {
        if (!keysToKeep.includes(key) && key != '_id') {
            delete item[key];
        }
    }
});
}
// summary report
function formatResponseSummary(apiResponse, lang: string, orderKey, order,kpis) {
  const storeSummaries = [];

  apiResponse.kpis = formatResponseSummaryByGroup(apiResponse.kpis,kpis);
  apiResponse.kpisLastWeek = formatResponseSummaryByGroup(apiResponse.kpisLastWeek,kpis);
  apiResponse.kpisCompare = formatResponseSummaryByGroup(apiResponse.kpisCompare,kpis);

  //add funciton to change response
  const kpiKeys = Object.keys(apiResponse.kpis[0]).filter((key) => key !== "_id");

  const storeCodes = new Set();

  apiResponse.kpis.forEach((data) => {
    storeCodes.add(data._id.storeCode);
  });

  storeCodes.forEach((storeCode) => {
    const storeSummary = {
      storeCode
    };

    kpiKeys.forEach((kpiKey) => {
      const storeKpiData = apiResponse.kpis.filter(
        (data) => data._id.storeCode === storeCode
      );
      const storeLastWeekKpiData = apiResponse.kpisLastWeek.filter(
        (data) => data._id.storeCode === storeCode
      );

      const storeCompareKpiData = apiResponse.kpisCompare.filter(
        (data) => data._id.storeCode === storeCode
      );

      var actualValue = storeKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );
      var lastWeekValue = storeLastWeekKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );
      // var yoyPercentage = (actualValue / lastWeekValue) ;
      var prevYearValue = storeCompareKpiData.reduce(
        (sum, data) => sum + data[kpiKey],
        0
      );
      // var yoyPercentagePrevYear = (actualValue / prevYearValue);

      var yoyPercentage = (actualValue / lastWeekValue) ;
      var yoyPercentagePrevYear = (actualValue / prevYearValue) ;

      // if(kpiKey == 'conversion' || kpiKey == 'capturerate')
      // {

      //   actualValue = actualValue/100;
      //   lastWeekValue = lastWeekValue/100;
      //   prevYearValue = prevYearValue/100;

      //   actualValue = Math.floor(actualValue *1000)/1000;
      //   lastWeekValue =Math.floor(lastWeekValue *1000)/1000;
      //   prevYearValue = Math.floor(prevYearValue *1000)/1000;
      // }

      if (!isFinite(yoyPercentage) === true) {
        yoyPercentage = 0;
      }
      if (!isFinite(yoyPercentagePrevYear) === true) {
        yoyPercentagePrevYear = 0;
      }

      yoyPercentage = Math.floor(yoyPercentage *1000)/1000;
      yoyPercentagePrevYear = Math.floor(yoyPercentagePrevYear *1000)/1000;
      // Update the storeSummary object properties as follows:
      storeSummary[`actual${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(actualValue) || actualValue == null ? 0 : actualValue;
      storeSummary[`lastWeek${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(lastWeekValue) || lastWeekValue == null ? 0 : lastWeekValue;
      storeSummary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}Percentage`] = isNaN(yoyPercentage) || yoyPercentage == null ? 0 : yoyPercentage;;
      storeSummary[`previousYear${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`] = isNaN(prevYearValue) || prevYearValue == null ? 0 : prevYearValue;
      storeSummary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}PercentagePrevYear`] = isNaN(yoyPercentagePrevYear) || yoyPercentagePrevYear == null ? 0 : yoyPercentagePrevYear;
    });

    storeSummaries.push(storeSummary);
  });
  // Define the header row based on your language preference
  var result = [];
  if (lang == 'en') {
    result.push(generateAdditionalHeaderRowSummary(kpiKeys));
    result.push(
      ','
      + generateKPIHeaders(kpiKeys,lang)
    );

  } else {
    result.push(generateAdditionalHeaderRowSummary(kpiKeys));
    result.push(
      ','
      +generateKPIHeaders(kpiKeys,lang)
    );
  }

  storeSummaries.forEach((summary) => {
const storeGroup: any = getStoreGroups(summary.storeCode);
    const row = [
      summary.storeCode
    ];

    kpiKeys.forEach((kpiKey) => {
      row.push(summary[`actual${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(summary[`lastWeek${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(Number(summary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}Percentage`]).toFixed(3));
      row.push(summary[`previousYear${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}`]);
      row.push(Number(summary[`yoy${kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1)}PercentagePrevYear`]).toFixed(3));
    });

    result.push(row.join(','));
  });

  return result.join('\n');
}

function generateKPIHeadersDetailed(kpiKeys,lang) {
  const headers = [];

  const storeState: any = store.state;
  const translate = storeState.user.translate;

  console.log(translate.previous_period_2)
  kpiKeys.forEach((kpiKey) => {
    headers.push(
      translate.this_period_results+getAllSigns(kpiKey)+`, ${translate.previous_period_2} `+getAllSigns(kpiKey)+`,${translate.export_yoy} (%),${translate.export_previous_year_same_day_of_week} `+getAllSigns(kpiKey)+`,${translate.export_yoy_2} (%)`
    );
  });
  
  return headers.join(',');
}
/////////////////////
function generateKPIHeaders(kpiKeys,lang) {
  const headers = [];

  const storeState: any = store.state;
  const translate = storeState.user.translate;

  kpiKeys.forEach((kpiKey) => {
    headers.push(
      translate.this_period_results+getAllSigns(kpiKey)+`, ${translate.export_last_week} `+getAllSigns(kpiKey)+`,${translate.export_yoy_3} (%),${translate.export_compared_results} `+getAllSigns(kpiKey)+`,${translate.export_yoy_3} (%)`
    );
  });

  return headers.join(',');
}

function generateAdditionalHeaderRowSummary(kpiKeys) {
  const headers = [];

  // Add KPI names followed by 4 empty columns for each KPI
  kpiKeys.forEach((kpiKey) => {
    headers.push('');
    headers.push(kpiKey);
    for (let i = 0; i < 3; i++) {
      headers.push('');
    }
  });

  return headers.join(',');
}
function generateAdditionalHeaderRow(kpiKeys) {
  const headers = [];

  // Add empty columns (7 in this case)
  for (let i = 0; i < 6; i++) {
    headers.push('');
  }

  // Add KPI names followed by 4 empty columns for each KPI
  kpiKeys.forEach((kpiKey) => {
    headers.push('');
    headers.push(kpiKey);
    for (let i = 0; i < 3; i++) {
      headers.push('');
    }
  });

  return headers.join(',');
}

//Demographic report final
function generateTimestamps(date, storeCode, openingTime, closingTime) {

  const timestamps = [];
  for (let i = openingTime; i <= closingTime; i++) {
      const timestamp = new Date(`${date}T${i.toString().padStart(2, '0')}:00:00.000Z`);
      timestamps.push({
          "kpiType": "demographics",
          "storeCode": storeCode,
          "demographics": {
            "ageGroup": {
            '1': 0,
            '2': 0,
            '3': 0,
            '4': 0,
            '5': 0,
            '6': 0,
            '7': 0,
            '8': 0,
          },
        },
          "startTimestamp": timestamp.toISOString()
      });
  }
  return timestamps;
}
function formatResponseDemographics(apiResponse: any, lang): string {
  apiResponse = apiResponse.map(item => {
    const timestampParts = item._id.split('_');
    const storeCode = timestampParts[3];
    const date = timestampParts[0].substring(0, 4) + '-' + timestampParts[0].substring(4, 6) + '-' + timestampParts[0].substring(6, 8);
    const hour = timestampParts[1];
    const startTimestamp = date + 'T' + hour + ':00:00.000Z';
  
    const demographics:any = {};
    demographics.ageGroup = { ...item.dm };

    return {
        kpiType: "demographics",
        storeCode,
        demographics,
        startTimestamp
    };
});

  const result: string[] = [];

  const storeState: any = store.state;
  const translate = storeState.user.translate;
  const id = storeState.user.profile.tenant._id;



  var ageGroups = ["1", "2", "3", "4", "5", "6", "7", "8"];
  var ageGroupLabels = [];
  // if(lang == 'en')
  // {
    if(id == '4TxrckSM8PrcEvfx9')//taito
    {
      ageGroups = ["1", "2", "3", "4", "5", "6"];
      ageGroupLabels = [
        translate[`male_child_report_${id}`]?translate[`male_child_report_${id}`]:translate['male_child_report'],
        translate[`male_young_report_${id}`]?translate[`male_young_report_${id}`]:translate['male_young_report'],
        translate[`male_adult_report_${id}`]?translate[`male_adult_report_${id}`]:translate['male_adult_report'],
      //  translate[`male_senior_report_${id}`]?translate[`male_senior_report_${id}`]:translate['male_senior_report'],

        translate[`female_child_report_${id}`]?translate[`female_child_report_${id}`]:translate['female_child_report'],
        translate[`female_young_report_${id}`]?translate[`female_young_report_${id}`]:translate['female_young_report'],
        translate[`female_adult_report_${id}`]?translate[`female_adult_report_${id}`]:translate['female_adult_report'],

        //donot change
        translate[`male_senior_report_${id}`]?translate[`male_senior_report_${id}`]:translate['male_senior_report'],
        translate[`female_senior_report_${id}`]?translate[`female_senior_report_${id}`]:translate['female_senior_report'],
      ];
    }
    else
    {
      ageGroupLabels = [
        translate[`male_child_report_${id}`]?translate[`male_child_report_${id}`]:translate['male_child_report'],
        translate[`male_young_report_${id}`]?translate[`male_young_report_${id}`]:translate['male_young_report'],
        translate[`male_adult_report_${id}`]?translate[`male_adult_report_${id}`]:translate['male_adult_report'],
        translate[`male_senior_report_${id}`]?translate[`male_senior_report_${id}`]:translate['male_senior_report'],

        translate[`female_child_report_${id}`]?translate[`female_child_report_${id}`]:translate['female_child_report'],
        translate[`female_young_report_${id}`]?translate[`female_young_report_${id}`]:translate['female_young_report'],
        translate[`female_adult_report_${id}`]?translate[`female_adult_report_${id}`]:translate['female_adult_report'],
        translate[`female_senior_report_${id}`]?translate[`female_senior_report_${id}`]:translate['female_senior_report'],
      ];
    }
  // }
  // else
  // {
  //   ageGroupLabels = [
  //     "男性-10代", "男性-20代", "男性-30/40代", "男性-50代以上",
  //     "女性-10代", "女性-20代", "女性-30/40代", "女性-50代以上"
  //   ];
  // }

  const groupedData: Record<string, Record<string, number[]>> = {};

  //const uniqueStoreCodes = [...new Set(apiResponse.map(entry => entry.storeCode))];
  const uniqueDates = [...new Set(apiResponse.map(entry => entry.startTimestamp.split('T')[0]))];

  let storeCodes = storeState.store.storesCodeNameList.map(store => store.storeCode);
  const res:any = getStoreTimingsForDatesAndStores(uniqueDates, storeCodes);

  for (const [date, storeData] of Object.entries(res)) {

    for (const [storeCode, timings] of Object.entries(storeData)) {

        const existingTimestamps = apiResponse.filter(item => item.storeCode === storeCode && item.startTimestamp.startsWith(date));
        if (existingTimestamps.length < timings.closingTime - timings.openingTime + 1) {
            const missingHours = [];
            for (let hour = timings.openingTime; hour <= timings.closingTime; hour++) {
                const timestampExists = existingTimestamps.some(item => parseInt(item.startTimestamp.slice(-23, -21)) === hour);
                if (!timestampExists) {
                    missingHours.push(hour);
                }
            }

            const missingTimestamps = generateTimestamps(date, storeCode, missingHours[0], missingHours[missingHours.length - 1]);
            apiResponse = apiResponse.concat(missingTimestamps);
        }
    }
}

  apiResponse.forEach(entry => {
    const startTimestamp = new Date(entry.startTimestamp);
    const date = startTimestamp.toISOString().substring(0, 10);
    const hour = startTimestamp.getUTCHours().toString().padStart(2, "0") + ":00";

    if (!groupedData[date]) {
      groupedData[date] = {};
    }

    if (!groupedData[date][hour]) {
      groupedData[date][hour] = new Array(ageGroups.length).fill(0);
    }

    ageGroups.forEach((ageGroup, index) => {
      if (entry.demographics.ageGroup[ageGroup]) {
        groupedData[date][hour][index] += entry.demographics.ageGroup[ageGroup];
      }
    });
  });
  // Create an array of date-hour combinations and sort them
  const dateHourCombinations = Object.keys(groupedData).flatMap(date =>
    Object.keys(groupedData[date]).map(hour => ({ date, hour }))
  );
  dateHourCombinations.sort((a, b) => {
    const dateComparison = a.date.localeCompare(b.date);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return a.hour.localeCompare(b.hour);
  });

  // Generate header based on available age groups
  var header = [];
  if(lang == 'en')
  {
    header = ["Date", "Hour", ...ageGroupLabels.slice(0, ageGroups.length)];
  }
  else
  {
    header = ["日付", "時間", ...ageGroupLabels.slice(0, ageGroups.length)];
  }
  result.push(header.join(","));

  for (const { date, hour } of dateHourCombinations) {
    const total = groupedData[date][hour].reduce((sum, value) => sum + value, 0);
    // const percentages = groupedData[date][hour].map(value => ((value / total)).toFixed(3));
    // const percentages:any = groupedData[date][hour].map(
    //   value =>
    //   ((value / total)*100).toFixed(2)
    //   );
    const percentages = groupedData[date][hour].map(value => {
      const percentage = (total !== 0 || value !== 0) ? ((value / total) * 100).toFixed(2) : 0;
      return Number(percentage); // Convert back to number
  });

    const percentagesAfter:any = percentages.map(
      v => (Math.floor(((v?v:0) / 100)*1000)/1000).toString()
    );
    //(Math.floor(((percentages) / 100)*1000)/1000).toString();


    result.push(`${date},${hour},${percentagesAfter.join(",")}`);
  }

  return result.join("\n");
}
// Houlry Report By Store
function formatResponseHourofDayKpiReport(apiResponse: any): string {
  const processedData = [];

  apiResponse.forEach(item => {
    const { _id, ...kpiData } = item;
    const _id2 = item._id.split('_');
    const dateDt = formatIntDate(_id2[0]);
    const hour = _id2[1];
    const storeCode = _id2[3];
  //delete apiResponse[item._id]._id;

    if(kpiData['trf'])
    {
      //trf =
      var trafficSum =0;
      for(const trff in kpiData.trf)
      {
       // trafficSum += kpiData.trf[trff].trf;
       trafficSum += kpiData.trf[trff].trf?kpiData.trf[trff].trf:0;
      }
      kpiData.trf = trafficSum;
    }

    const obj = {
      storeCode:storeCode,
      date: dateDt,
      hour: hour+':00',
      ...kpiData
    }
    processedData.push(obj);
  });


    //const kpiValue = Object.keys(kpiData).length > 0 ? kpiData[kpiType].countValue : undefined;

  // Sort the processed data by Store Code, Date, and Hour
  processedData.sort((a, b) => {
    if (a.storeCode !== b.storeCode) {
      return a.storeCode.localeCompare(b.storeCode);
    }
    if (a.date !== b.date) {
      return a.date.localeCompare(b.date);
    }
    return a.hour.localeCompare(b.hour);
  });

  const obj2 = {...apiResponse[0]};
  delete obj2._id;

  const kpiTypes = Object.keys(obj2);
  var headerRow = [];
  if(lang == 'en')
  {
    headerRow = ['Store Code', 'Date', 'Hour', ...kpiTypes];
  }
  else
  {
    headerRow = ['店コード', '日付', '時間', ...kpiTypes];
  }
  const result = processedData.map(data => {
    const rowData = [data.storeCode, data.date, data.hour];
    // kpiTypes.forEach((kpiType: any) => {
    //   rowData.push(data[kpiType] !== undefined ? data[kpiType] : '0');
    // });

    kpiTypes.forEach((kpiType: any) => {
      rowData.push(data[kpiType] !== undefined ? String(data[kpiType]) : '0');
    });


    return rowData;
  });

  result.unshift(headerRow);

  return result.map(row => row.join(', ')).join('\n');
}

// common functions
var lang;
export async function APIExport(exportRequestBody: any) {

  lang = exportRequestBody.lang;
  var reportType = '';
  var reportType2 = '';
  var api1=0;

  if(exportRequestBody.reportType == 'daily')
  {
    reportType = 'daily';
    reportType2 = 'daily';
    api1 = 1;
  }
  else if(exportRequestBody.reportType == 'AllStoresDaily')
  {
    reportType = 'daily';
    reportType2 = 'AllStoresDaily';
    api1 = 1;
  }
  else if(exportRequestBody.reportType == 'hourofdaykpi'){
    reportType = 'hourly';
    api1 = 0;
    reportType2 = 'hourofdaykpi';
  }
  else if(exportRequestBody.reportType == 'hourly'){
    reportType = 'hourly';
    api1 = 1;
    reportType2 = 'hourly';
  }
  else if(exportRequestBody.reportType == 'demographics'){
    reportType = 'demographics';
    reportType2 = 'demographics';
    api1 = 0;
  }
  else if(exportRequestBody.reportType == 'detailed')
  {
    reportType = 'all';///from all to daily
    reportType2 = 'detailed';
    api1 = 2;
  }
  else if(exportRequestBody.reportType == 'summary')
  {
    reportType = 'all';//from all to daily
    reportType2 = 'summary';
    api1 = 2;

  var storeGroupsByStoreCodeList = getStoreGroupsByStoreCodeList();
  var results = {};
  var storeCodesByGroupNamesAll = '';

  for (var groupType in storeGroupsByStoreCodeList) {
    var names = storeGroupsByStoreCodeList[groupType].split(',').map(name => name.trim());
    for (var i = 0; i < names.length; i++) {
      var name = names[i];
      var storeCodesByGroupNames = getStoreCodesByLocations(groupType, name);
      if(storeCodesByGroupNames != "")
      {
        if (!results[groupType]) {
          results[groupType] = {};
        }

        if (!results[groupType][name]) {
          results[groupType][name] = storeCodesByGroupNames;
          storeCodesByGroupNamesAll = storeCodesByGroupNamesAll +','+ storeCodesByGroupNames;
        }
      }
    }
  }
  var uniqueStoreCodes = [...new Set(storeCodesByGroupNamesAll.split(','))].join(',');

  exportRequestBody.storeCodes = uniqueStoreCodes;
  }
  else {
    alert('Report Type not defined');
    return;
  }

  let cleanedStoreCodes4 = exportRequestBody.storeCodes.split(',').filter(code => code.trim() !== '').join(',');

  const requestBody = {
    "startDate": exportRequestBody.startDate,
    "endDate": exportRequestBody.endDate,
    "frequency": reportType,//daily
    "storeCodes": cleanedStoreCodes4.split(","),
    "kpiTypes": exportRequestBody.kpis.split(","),
    "reportType": exportRequestBody.reportType
  }
  var requestBody2:any = {
    "startDate": exportRequestBody.startDate,
    "endDate": exportRequestBody.endDate,
    "frequency": reportType,//daily
    "storeCodes": cleanedStoreCodes4.split(","),
    "kpiTypes": exportRequestBody.kpis.split(","),
    "compareStartDate":exportRequestBody.compareStartDate,
    "compareEndDate": exportRequestBody.compareEndDate,
    "reportType": exportRequestBody.reportType
  }

  const dateForName = exportRequestBody.startDate+'_'+exportRequestBody.endDate;

  if(api1 == 1)
  {
    const response = await GetReportingSumDataByLocationFidaV2OnlyExport(requestBody);
    await exportToExcel(response.kpis,reportType2,dateForName, exportRequestBody);
    return response;
  }
  else if(api1 == 2 )
  {
    requestBody2.isReport = 1;

    if(exportRequestBody.reportType == 'summary')
    {

      var startDate3 = new Date(exportRequestBody.startDate);
      var endDate3 = new Date(exportRequestBody.endDate);

      // Subtract 7 days from the startDate
      startDate3.setDate(startDate3.getDate() - 7);
      endDate3.setDate(endDate3.getDate() - 7);
      //endDate3.toISOString().slice(0, 10),
      if(requestBody2.compareStartDate != requestBody2.compareEndDate)
      {
        requestBody2.compareStartDate = startDate3.toISOString().slice(0, 10);
        requestBody2.compareEndDate = endDate3.toISOString().slice(0, 10);
      }
    }
    var response1:any;
    var startDate = new Date(requestBody2.startDate);
    var endDate = new Date(requestBody2.endDate);
    var timeDifference = endDate.getTime() - startDate.getTime();
    var daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const compareStartDate2 = requestBody2.compareStartDate;
    const compareEndDate2 = requestBody2.compareEndDate;


    if(exportRequestBody.reportType == 'summary')
    {
      const previousPeriod = getPreviousPeriod(startDate, endDate);

      requestBody2.compareStartDate = previousPeriod.previousStartDate;
      requestBody2.compareEndDate = previousPeriod.previousEndDate;

      response1 = await GetReportingSumDataByLocationFidaV2OnlyExport(requestBody2);
    }
    else
    {
      const previousPeriod = getPreviousPeriod(startDate, endDate);

      requestBody2.compareStartDate = previousPeriod.previousStartDate;
      requestBody2.compareEndDate = previousPeriod.previousEndDate;
      response1 = await GetReportingSumDataByLocationFidaV2OnlyExport(requestBody2);
    }

    var response2: any;

 if(exportRequestBody.reportType == 'detailed' ) {

      const previousYearDates = getPreviousYearSameWeekDates(exportRequestBody.startDate,exportRequestBody.endDate);
      let cleanedStoreCodes2 = exportRequestBody.storeCodes.split(',').filter(code => code.trim() !== '').join(',');

      const requestBody3 = {
        "startDate": previousYearDates.prevYearStart,
        "endDate": previousYearDates.prevYearEnd,
        "frequency": reportType,//daily
        "storeCodes": cleanedStoreCodes2.split(","),
        "kpiTypes": exportRequestBody.kpis.split(",")
      }

      response2 = await GetReportingSumDataByLocationFidaV2OnlyExport(requestBody3);
      if(response1 && response2)
      {
        if(response2.kpis)
        {
          response1.kpisLastWeek = response1.kpisCompare
          response1.kpisCompare =[...response2.kpis];
        }
      }
    }
    else if(exportRequestBody.reportType == 'summary')
    {
      const previousYearDates = getPreviousYearSameWeekDates(exportRequestBody.startDate,exportRequestBody.endDate);
      let cleanedStoreCodes3 = exportRequestBody.storeCodes.split(',').filter(code => code.trim() !== '').join(',');


      const requestBody3 = {
        "startDate": previousYearDates.prevYearStart,
        "endDate": previousYearDates.prevYearEnd,
        "frequency": reportType,//daily
        "storeCodes": cleanedStoreCodes3.split(","),
        "kpiTypes": exportRequestBody.kpis.split(","),
        "reportType": exportRequestBody.reportType
      }

      response2 = await GetReportingSumDataByLocationFidaV2OnlyExport(requestBody3);


      if(response1)
      {
        if(response1.kpisCompare)
        {
          response1.kpisLastWeek = [...response1.kpisCompare];
          delete response1.kpisCompare;
          response1.kpisCompare = [...response2.kpis];//new resposne
        }
      }
    }
    else
    {
      if(response1)
      {
        if(response1.kpisCompare)
        {
          response1.kpisLastWeek = [...response1.kpisCompare]
        }
      }

    }

    await exportToExcel(response1,reportType2,dateForName, exportRequestBody);

    return response2;
  }
  else//for demographic
  {
    var response: any;
    if(exportRequestBody.reportType == 'demographics')
    {
      delete requestBody.frequency;
      requestBody.kpiTypes = 'dm'.split(",");
      response = await GetKpisPlainDataFidaForDemographics(requestBody);

    }
    else
    {

      response = await GetReportingSumDataFidaV2(requestBody);
    }

    await exportToExcel(response.kpis,reportType2,dateForName, exportRequestBody);
    return response;
  }
}

function getPreviousYearSameWeekDates(startDate, endDate) {

  let st = addDays(
    new Date(startDate),
    -364
  );
  let et = addDays(new Date(endDate), -364);
  //this.setComparedPeriod(st, et)

  return { prevYearStart: formatDate(st), prevYearEnd: formatDate(et) };
}
// function getPreviousYearSameWeekDates(startDate, endDate) {


//   const start:any = new Date(startDate);
//   var end:any = new Date(endDate);
//   // Calculate the day of the week for the start date (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
//   const inputDayOfWeek = start.getDay();

//   // Subtract a year from both start and end dates
//   start.setFullYear(start.getFullYear() - 1);
//   end.setFullYear(end.getFullYear() - 1);

//   // Find the first date in the previous year with the same day of the week
//   while (start.getDay() !== inputDayOfWeek) {
//     start.setDate(start.getDate() + 1);
//   }

//   // Calculate the end date based on the start date and the duration of the input period

//   let diffInDays = (end - start) / (1000 * 60 * 60 * 24);
//   diffInDays = diffInDays+1;
//   var endStartDate:any = new Date(start);

//   endStartDate.setDate(endStartDate.getDate() + diffInDays);
//   end = endStartDate;

//   // Format the resulting dates as "yyyy-MM-dd"
//   const prevYearStart = start.toISOString().slice(0, 10);
//   const prevYearEnd = end.toISOString().slice(0, 10);

//   return { prevYearStart, prevYearEnd };
// }
export async function exportToExcel(apiResponse: any, reportType: string, dateForName: string, exportRequestBody): Promise<void> {
  var kpis = exportRequestBody.kpis;
  var orderKey = exportRequestBody.orderKey;//orderKey: "sales", order:"ASC"
  var order = exportRequestBody.order;
  var formattedResult;
  var fileName;

  if (reportType === 'detailed') {
    if(lang == 'en')
      fileName = 'Detailed_'+dateForName;
    else
      fileName = '全店レポート_'+dateForName;

    formattedResult = formatResponseDetailed(apiResponse,lang, orderKey, order);
  }


  else if (reportType === 'summary') {
    if(lang == 'en')
      fileName = 'Summary_'+dateForName;
    else
      fileName = 'サマリーレポート_'+dateForName;
      formattedResult = formatResponseSummary(apiResponse,lang, orderKey, order,kpis);
  //  formattedResult = [];//formatResponseSummary(apiResponse);
  }

  else if (reportType === 'hourly') {
    if(lang == 'en')
      fileName = 'Hourly Total_'+dateForName;
    else
      fileName = '時間毎レポート_'+dateForName;
    formattedResult = formatResponseHourlyKpiReport(apiResponse,lang, orderKey, order);
  }

  else if (reportType === 'daily') {
    if(lang == 'en')
      fileName = 'Daily_'+dateForName;
    else
      fileName = '日毎レポート_'+dateForName;

    formattedResult = formatResponseDailyReport(apiResponse,lang, orderKey, order);
  }

  // else if (reportType === 'AllStoresDaily') {
  //   if(lang == 'en')
  //     fileName = 'All Stores Daily_Sales_'+dateForName;
  //   else
  //     fileName = '全店日毎レポート_売上_'+dateForName;

  //   formattedResult = formatResponseAllStoresDailyReport(apiResponse, kpis, lang);
  // }
  else if (reportType === 'AllStoresDaily') {
    const storeState: any = store.state;
    const translate = storeState.user.translate;

    const kpiTitle = getKpiTitle(kpis);
    if(lang == 'en')//kpis
      fileName = 'All Stores Daily_'+kpiTitle+'_'+dateForName;//'All Stores Daily_Sales_'+dateForName;
    else
      fileName = '全店日毎レポート_'+kpiTitle+'_'+dateForName;

    formattedResult = formatResponseAllStoresDailyReport(apiResponse, kpis, lang);
  }

  else if (reportType === 'demographics') {

    if(lang == 'en')
      fileName = 'Demographics_'+dateForName;//lang
    else
      fileName = '来店者性別分析_'+dateForName;//lang
    formattedResult = formatResponseDemographics(apiResponse, lang);
  }

  else if (reportType === 'hourofdaykpi') {
    if(lang == 'en')
      fileName = 'Hourly By Store & day_'+dateForName;
    else
      fileName = '店舗別時間毎レポート_'+dateForName;

    formattedResult = formatResponseHourofDayKpiReport(apiResponse);

  }

  else {
    alert('Invalid Report Type');
  }


  //   if (reportType === 'detailed') {
  // //Other sorts
  //   //StoreNumber,Region,District,PeerGroups1,PeerGroups2,store,SV--orderKey,order
  //   if (orderKey.toUpperCase() == 'REGION' || orderKey.toUpperCase() == 'DISTRICT'
  //   || orderKey.toUpperCase() == 'PEERGROUPS1' || orderKey.toUpperCase() == 'PEERGROUPS2'
  //   || orderKey.toUpperCase() == 'STORE' || orderKey.toUpperCase() == 'SV') {

  //     console.log('storeSummaries');
  //     console.log(storeSummaries);

  //   }
  // }

  const rows = formattedResult.split('\n').map((row) => row.split(','));
  const newHeaderNames = rows[0].map((cell) => {
    var newKpiName = getKpiNameByKey(cell)
    return newKpiName;
  });

  var updatedRows;
  if (reportType === 'detailed' && (orderKey.toUpperCase() == 'REGION' || orderKey.toUpperCase() == 'DISTRICT'
     || orderKey.toUpperCase() == 'PEERGROUPS1' || orderKey.toUpperCase() == 'PEERGROUPS2' || orderKey.toUpperCase() == 'AREAMANAGERS'
     || orderKey.toUpperCase() == 'STORE' || orderKey.toUpperCase() == 'SV')) {

    var columnToSortIndex = 0;
    if(orderKey.toUpperCase() == 'STORE') {columnToSortIndex = 1;}
    else if(orderKey.toUpperCase() == 'DISTRICT') {columnToSortIndex = 2;}
    else if(orderKey.toUpperCase() == 'REGION') {columnToSortIndex = 3;}
    else if(orderKey.toUpperCase() == 'AREAMANAGERS') {columnToSortIndex = 4;}
    else if(orderKey.toUpperCase() == 'SV') {columnToSortIndex = 4;}//BOTH IDS WILL REMAIN SAME
    else if(orderKey.toUpperCase() == 'PEERGROUPS1') {columnToSortIndex = 5;}
    else if(orderKey.toUpperCase() == 'PEERGROUPS2') {columnToSortIndex = 6;}

    // const columnNameToSort = 'Store Name'; // or 'Area Manager'
    //const columnToSortIndex = rows[1].indexOf(columnNameToSort);

    //  updatedRows = [newHeaderNames, ...rows.slice(1)];

updatedRows = [...rows];
    updatedRows.sort((a, b) => {
      if (a === rows[0]) return -1;
      if (b === rows[0]) return 1;
      if (a === rows[1]) return -1;
      if (b === rows[1]) return 1;

      const valueA = a[columnToSortIndex].trim();
      const valueB = b[columnToSortIndex].trim();

      const comparison = valueA.localeCompare(valueB); // or numA - numB for numeric sorting

      return order.toUpperCase() === 'ASC' ? comparison : -comparison;

    });
 //   updatedRows.splice(1, 0, rows[1]);
 updatedRows = [newHeaderNames, ...updatedRows.slice(1)];
  }
  else
  updatedRows = [newHeaderNames, ...rows.slice(1)];





  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
    updatedRows.map((row, rowIndex) =>
      row.map((cell, columnIndex) => {

        const trimmedCell = cell.trim();
        var columnIndexVal = 1;
        if (reportType === 'summary')
        {
          columnIndexVal = 0;
        }
//
if(reportType === 'daily')
{
  columnIndexVal = 2;
}
        if(columnIndex > columnIndexVal && !isNaN(Number(trimmedCell)))
        {
          if(Number(trimmedCell).toString().includes('.'))
          {
            if(reportType === 'daily' || reportType === 'hourly' || reportType === 'AllStoresDaily' ){
              return {
                t: 'n',
                v: trimmedCell,
                w: trimmedCell.toLocaleString('en-US', { maximumFractionDigits: 3 }),
                z: '#,##0.00'
              };
            }
            else
            {
              return {
                t: 'n',
                v: trimmedCell,
                w: trimmedCell.toLocaleString('en-US', { maximumFractionDigits: 3 }),
                z: '#,##0.000'
              };
            }
          }
          else
          return {
            t: 'n',
            v: trimmedCell,
            w: trimmedCell.toLocaleString('en-US', { maximumFractionDigits: 0 }),
            z: '#,##0'
          };
        }
        else
        {
          return {
            t: 's', // Treat all cells as strings
            v: trimmedCell,
          };
        }

      })
    )
  );

  const totalColumns = rows.length > 0 ? rows[0].length : 0;
  const columnWidths = Array.from({ length: totalColumns }, () => ({ wch: 13 }));
  worksheet['!cols'] = columnWidths;

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');


  const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  const excelData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const excelUrl = URL.createObjectURL(excelData);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = excelUrl;
  link.download = fileName+'.xlsx';
  link.click();
}

export function getStoreNameByCode(storeCode:string)
{
  const storesCodeName = store.getters['user/getStoresCodeName'];
  const storeList = storesCodeName.find(store => store.storeCode === storeCode);
  return storeList ? storeList.name : null;
}

function getStoreGroups(storeCode:string)
{
  const data = store.getters['user/getStoreGroups'];
    const result: any = {};

    // Find area managers that have the given storeCode
    for (const areaManager of data.areaManagers) {
        for (const store of areaManager.stores) {
            if (store.storeCode === storeCode) {
                result.areaManagerName = areaManager.name;
                break;
            }
        }
        if (result.areaManagerName) break;
    }

    // Find regions and districts that have the given storeCode
    for (const region of data.regions) {
        for (const district of region.districts) {
            for (const store of district.stores) {
                if (store.storeCode === storeCode) {
                    result.regionName = region.name;
                    result.districtName = district.name;
                    break;
                }
            }
            if (result.regionName) break;
        }
        if (result.regionName) break;
    }

    // Find peer groups that have the given storeCode
    result.peerGroups = [];


    for (const peerGroup of data.peerGroups) {
      for (const store of peerGroup.stores) {
          if (store.storeCode === storeCode) {
              result.peerGroups.push(peerGroup);
              break;
          }
      }
  }
  result.peerGroups = result.peerGroups.map(peerGroup => ({
    order: peerGroup.order?peerGroup.order:5,
    name: peerGroup.name
  }));

  const extractedData = result.peerGroups
  .map(peerGroup => ({ order: peerGroup.order, name: peerGroup.name }))
  .sort((a, b) => a.order - b.order);

// const orders = extractedData.map(peerGroup => peerGroup.order);
// const names = extractedData.map(peerGroup => peerGroup.name);

// result.sort((a, b) => (a.order || 0) - (b.order || 0));
result.peerGroups.sort((a, b) => (a.order || 0) - (b.order || 0));

// result.peerGroups = sortedData;
    return result;
}

export function getStoreGroupsByStoreCodeList() {
  const storeCodesList = store.getters['filter/getStoreCodesList'];

  const storeCodesListArray = storeCodesList.split(',');

  const data = store.getters['user/getStoreGroups'];
  const uniqueResults = new Set(); // Use a Set to store unique results

  for (const storeCode of storeCodesListArray) {
    const result:any = {};

    // Find area managers that have the given storeCode
    for (const areaManager of data.areaManagers) {
      for (const store of areaManager.stores) {
        if (store.storeCode === storeCode) {
          result.areaManagerName = areaManager.name;
          break;
        }
      }
      if (result.areaManagerName) break;
    }

    // Find regions and districts that have the given storeCode
    for (const region of data.regions) {
      for (const district of region.districts) {
        for (const store of district.stores) {
          if (store.storeCode === storeCode) {
            result.regionName = region.name;
            result.districtName = district.name;
            break;
          }
        }
        if (result.regionName) break;
      }
      if (result.regionName) break;
    }

    // Find peer groups that have the given storeCode
    result.peerGroups = [];
    for (const peerGroup of data.peerGroups) {
      for (const store of peerGroup.stores) {
        if (store.storeCode === storeCode) {
          result.peerGroups.push(peerGroup.name);
          break;
        }
      }
    }

    // Convert the result to a string to check for uniqueness
    const resultString = JSON.stringify(result);

    if (!uniqueResults.has(resultString)) {
      uniqueResults.add(resultString);
    }
  }

  // Convert the Set back to an array of unique results
  const uniqueResultsArray = Array.from(uniqueResults).map((resultString:any) =>
    JSON.parse(resultString)
  );

  // Create an object with comma-separated unique values
  const uniqueResultsObj = {
    areaManagers: [...new Set(uniqueResultsArray.map((result) => result.areaManagerName))].join(','),
    regions: [...new Set(uniqueResultsArray.map((result) => result.regionName))].join(','),
    districts: [...new Set(uniqueResultsArray.map((result) => result.districtName))].join(','),
    peerGroups: [...new Set(uniqueResultsArray.flatMap((result) => result.peerGroups))].join(','),
  };
  return uniqueResultsObj;
}

export function getKpiNameByKey(metricKey:string)
{
  const kpiNameAndKey = store.getters['user/getKpisLabelValue'];
  const kpiObj = kpiNameAndKey.find(k => k.value === metricKey.trim());
  return kpiObj ? kpiObj.label : metricKey;
}

function getPreviousPeriod(startDate, endDate) {
// if (this.dateOptionsComparedModel.value === "PREVIOUS_PERIOD")
  const diffDays = differenceInDays(
    startDate,
    endDate
  );
  const diffDaysPositive = diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
  // const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
  const weeks = Math.ceil(Number((diffDaysPositive / 7).toFixed(2)));
 var previousStartDate = addWeeks(startDate, -weeks);
  var previousEndDate = addWeeks(endDate, -weeks);
  return {
        previousStartDate: formatDate(previousStartDate),
        previousEndDate: formatDate(previousEndDate)
      };
}
// function getPreviousPeriod(startDate, endDate) {
//
//   const millisecondsInADay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

//   // Calculate the total number of days in the period
//   var totalDays = Math.floor((endDate.getTime() - startDate.getTime()) / millisecondsInADay) + 1;
//   var previousStartDate;
//   var previousEndDate;
//
//   if(totalDays == 7)
//   {
//   // Calculate the previous period
//      previousStartDate = new Date(startDate.getTime() - (totalDays * millisecondsInADay));
//      previousEndDate = new Date(endDate.getTime() - (totalDays * millisecondsInADay));
//   }
//   else if (totalDays < 7)
//   {
//   // Calculate the previous period
//      previousStartDate = new Date(startDate.getTime() - (totalDays * millisecondsInADay));
//      previousEndDate = new Date(endDate.getTime() - (totalDays * millisecondsInADay));
//   }
//   else //if (totalDays > 7)
//   {
//   // Calculate the previous period
//      totalDays = totalDays+1;
//      previousStartDate = new Date(startDate.getTime() - (totalDays * millisecondsInADay));
//      previousEndDate = new Date(endDate.getTime() - (totalDays * millisecondsInADay));
//   }
//
//   return {
//     previousStartDate: formatDate(previousStartDate),
//     previousEndDate: formatDate(previousEndDate)
//   };
// }

// Helper function to format date as "YYYY-MM-DD"
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function sortingByOrder(apiResponse, orderKey, order, page)
{
  if (orderKey == 'StoreNumber')
    orderKey = 'storeCode';

  if (orderKey && order) {
    if (!apiResponse[0] || !apiResponse[0][orderKey]) {
      orderKey = 'storeCode';
    }

    if (orderKey === 'storeCode') {
      apiResponse.sort((a, b) => {

        var aStoreCode;
        var bStoreCode;

        var aOtherCode;
        var bOtherCode;
        if(page == 'hourly')
        {
          aStoreCode = Number(a._id.split('_')[1]);
          bStoreCode = Number(b._id.split('_')[1]);

          aOtherCode = Number(a._id.split('_')[0]);
          bOtherCode = Number(b._id.split('_')[0]);

          if (order === 'ASC') {
            // First, sort by the second part of the _id
            if (aStoreCode !== bStoreCode) {
              return aStoreCode - bStoreCode;
            }
            // If the second part is the same, then sort by the first part of the _id
            return aOtherCode - bOtherCode;
          } else {
            // Reverse order: First, sort by the second part of the _id
            if (aStoreCode !== bStoreCode) {
              return bStoreCode - aStoreCode;
            }
            // If the second part is the same, then sort by the first part of the _id
            return bOtherCode - aOtherCode;
          }
        }
        else if(page == 'alldaily')
        {
          aStoreCode = Number(a._id.split('_')[1]);
          bStoreCode = Number(b._id.split('_')[1]);

            if (order === 'ASC') {
              return aStoreCode - bStoreCode;
            } else {
              return bStoreCode - aStoreCode;
            }
        }
        else
        {
          aOtherCode = Number(a._id.split('_')[0]);
          bOtherCode = Number(b._id.split('_')[0]);

          aStoreCode = Number(a._id.split('_')[1]);
          bStoreCode = Number(b._id.split('_')[1]);

          if (order === 'ASC') {
            // First, sort by the second part of the _id
            // if (aStoreCode !== bStoreCode) {
            //   return aStoreCode - bStoreCode;
            // }

            if (aOtherCode !== aOtherCode) {
              return aOtherCode - aOtherCode;
            }
            // If the second part is the same, then sort by the first part of the _id
            return aStoreCode - bStoreCode;//aOtherCode - aOtherCode;
          } else {
            // Reverse order: First, sort by the second part of the _id
            if (aOtherCode !== bOtherCode) {
              return bOtherCode - aOtherCode;
            }
            // If the second part is the same, then sort by the first part of the _id
            return bStoreCode - aStoreCode;//bOtherCode - aOtherCode;
          }
        }


      });

      // if(page == 'hourly')
      // {

      // }
    }

else
    apiResponse.sort((a, b) => {
      if (order === "ASC") {
        if (a[orderKey] < b[orderKey]) return -1;
        if (a[orderKey] > b[orderKey]) return 1;
        return 0;
      } else {
        if (a[orderKey] > b[orderKey]) return -1;
        if (a[orderKey] < b[orderKey]) return 1;
        return 0;
      }
    });
  };

  return apiResponse;
}
