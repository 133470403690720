import { AxiosInstance } from 'axios';
import { District } from '@/models/Location';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';

export type GetDistrictsResponse = PaginatedResponse<District>;

export default class DistrictController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getDistricts() {
    try {
      const { data } = await this.client.get('/district', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 100,
          paged: false
        }
      });
      return data as GetDistrictsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getDistrictsByRegion(regionId) {
    try {
      const { data } = await this.client.get('/district', {
        params: {
          query: `regionId:${regionId}`
        }
      });
      return data as GetDistrictsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
