
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  props: {
    submitreport: {
      type: Function,
      default: () => {},
      required: true,
    },
    togglemodal: {
      type: Function,
      default: () => {},
      required: true,
    },
    isReportSaving: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...mapGetters("filter", [
      "getStoreCodesList",
      "getDaysOfWeekList",
      "getPulseStartingPeriod",
      "getPulseEndingPeriod",
      "getPulseStartingCompared",
      "getPulseEndingCompared",
      "getCustomCalenderState",
    ]),
    ...mapState("periodicReport", [
      "selectedStoreCode",
      "dailyReportList",
      "selectedStore",
    ]),
    ...mapState("user", ["profile"]),
    getButtonLabel() {
      if (this.$route.name === 'periodic_report_list' || this.$route.name === 'periodic_report_list_demo') {
        return this.$store.state.user.translate.download;
      } else {
        return this.$t('Library.OK');
      }
    }
  },
};
