import each from 'lodash/each';

export const mergeToAttrs = (...props: any[]): string => {
  return props.filter(Boolean).join(' ');
};

export const normalizeProps = (props: any): any => {
  const resultProps = {};
  each(props, (value: any, key: string) => {
    if (value === '') {
      resultProps[key] = true;
    } else {
      resultProps[key] = value;
    }
  });
  return resultProps;
};

export function deepObjectAssign(key: string, value: any) {
  return key
    .split('.')
    .reduceRight(
      (acc, val, index, data) =>
        data.length === index + 1
          ? Object.assign({}, acc, { [val]: value })
          : { [val]: acc },
      {}
    );
}
