
import { useJBStoreOption } from './getJBStore';
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
  props: {
    additionaloption: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      markedOptions: [],
      selectedOptions: null,
      searchResult: [],
      storeOption: [],
      selectAllStore: false,
      selectedStore: null
    };
  },
  computed: {
    ...mapState('periodicReport', ['currentReport', 'selectedStoreCode'])
  },
  watch: {
    selectedStoreCode() {
      this.selectedStore = this.selectedStoreCode;
    },
    search(keyword) {
      const searchedStore = keyword
        ? this.storeOption.filter(
            option =>
              option.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
          )
        : this.storeOption;
      this.searchResult = [...searchedStore];
    },
    selectedOptions(newValue) {
      if (
        newValue === 'All' ||
        newValue.value === 'All' ||
        newValue === 'すべて' ||
        newValue.value === 'すべて'
      ) {
        this.setStore('all');
      } else {
        this.setSelectedStore(this.selectedOptions);
        this.setStore(newValue.value);
      }
    }
  },
  async beforeCreate() {
    const storeOptionList = await useJBStoreOption();
    if (this.additionaloption !== '') {
      this.storeOption = [
        { label: this.additionaloption, value: this.additionaloption },
        ...storeOptionList
      ];
    } else {
      this.storeOption = [...storeOptionList];
    }
    this.searchResult = [...storeOptionList];

    if (this.additionaloption !== '') {
      this.selectedOptions = this.additionaloption;
    } else {
      this.selectedOptions =
        this.selectedStoreCode && this.selectedStoreCode !== 'all'
          ? storeOptionList.filter(
              store => store.value === this.selectedStoreCode
            )[0]
          : storeOptionList[0];
    }
    this.setSelectedStore(this.selectedOptions);
    this.setStore(this.selectedOptions.value);
  },
  methods: {
    ...mapMutations('periodicReport', [
      'setCurrentReport',
      'setStore',
      'setSelectedStore'
    ]),
    filterFn(val, update) {
      val
        ? update(
            () =>
              (this.searchResult = this.storeOption.filter(
                v => v.label.toLowerCase().indexOf(val.toLowerCase()) > -1
              ))
          )
        : update(() => (this.searchResult = [...this.storeOption]));
    }
  }
};
