import HttpController from '../HttpController';
import { MenuDTO } from '@/models/Menu';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';

export type GetMenusResponse = PaginatedResponse<MenuDTO>;

export default class MenuController extends HttpController {
  public async getMenus() {
    try {
      const { data } = await this.client.get('/menus', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          unpaged: true
        }
      });
      return data as GetMenusResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
