import { splitPhoneNumber } from '@/util/string';
import { Contact, ContactDTO } from '../Contact';

export const convertFromContactDTO = (dto: ContactDTO): Contact => ({
  ...dto,
  phone: {
    countryCode: dto.phone ? splitPhoneNumber(dto.phone).callingCode : '',
    phoneNumber: dto.phone ? splitPhoneNumber(dto.phone).phoneNumber : ''
  }
});

export const convertToContactDTO = (contact: Contact): ContactDTO => ({
  id: contact.id,
  email: contact.email,
  department: contact.department,
  phone: `${contact.phone.countryCode} ${contact.phone.phoneNumber}`,
  title: contact.title,
  name: contact.name
});
