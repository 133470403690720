
import { mapActions } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin],
  props: {
    value: { type: Boolean, required: true }
  },
  methods: {
    ...mapActions({
      markStoreCreated: 'tenant'
    }),
    async startOnboarding() {
      await this.markStoreCreated();
      window.open('https://juk1i3mvd2k.typeform.com/to/Y21dgww6', '_self');
    }
  }
};
