
import Vue from 'vue';
import { props, render } from './ButtonDefs';

export default Vue.extend({
  name: 'Button',
  functional: true,
  props,
  render
});
