
export default {
  props: {
    data: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    dateOptions() {
      const dateDict = new Map<string, Date>();
      this.data.forEach(log => {
        const date = new Date(log.timestamp);
        date.setHours(0, 0, 0, 0);
        const dateKey = date.toString();
        if (!dateDict.has(dateKey)) {
          dateDict.set(dateKey, date);
        }
      });
      return [...dateDict.values()].map(date => ({
        label: date.toLocaleDateString('ja-JP'),
        value: date
      }));
    },
    usernameOptions() {
      const usernameDict = new Map<string, string>();
      this.data.forEach(log => {
        if (!usernameDict.has(log.username)) {
          usernameDict.set(log.username, log.username);
        }
      });
      return [...usernameDict.values()].map(username => ({
        label: username,
        value: username
      }));
    },
    menuOptions() {
      const menuDict = new Map<string, string>();
      this.data.forEach(log => {
        if (!menuDict.has(log.menu)) {
          menuDict.set(log.menu, log.menu);
        }
      });
      return [...menuDict.values()].map(menu => ({
        label: menu,
        value: menu
      }));
    }
  }
};
