import { Industry } from '@/models/Industry';
import { PaginatedResponse } from '@/models/Pagination';
import { PaginationRequest } from '@/util/pagination';
import { AxiosInstance } from 'axios';

export type GetIndustriesResponse = PaginatedResponse<Industry>;

export default class IndustryController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getIndustries(pagination: PaginationRequest) {
    const data = {
      content: [
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fu',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '017',
          name: 'Automobile Showroom',
          nameJapanese: '自動車販売店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58ft',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '016',
          name: 'Other Industry',
          nameJapanese: 'その他業種'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fs',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '015',
          name: 'Other Retail',
          nameJapanese: 'その他小売店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fr',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '014',
          name: 'Other Restaurant',
          nameJapanese: 'その他飲食店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fq',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '013',
          name: 'Supermarket',
          nameJapanese: 'スーパーマーケット'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fp',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '012',
          name: 'Sporting Goods',
          nameJapanese: 'スポーツ用品店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fn',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '010',
          name: 'Quick Service Restaurant',
          nameJapanese: 'ファストフード店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fm',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '009',
          name: 'Mall Operator/Developer',
          nameJapanese: '商業施設'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fl',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '008',
          name: 'Luxury Fashion',
          nameJapanese: 'ラグジュアリーファッション'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fk',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '007',
          name: 'Hospitality',
          nameJapanese: '売店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fj',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '006',
          name: 'Fashion',
          nameJapanese: '衣料品店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fi',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '005',
          name: 'Electronics',
          nameJapanese: '家電量販店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fh',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '004',
          name: 'Drug Store',
          nameJapanese: 'ドラックストア'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fg',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '003',
          name: 'Department Store',
          nameJapanese: '百貨店'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58ff',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '002',
          name: 'Convenience Store',
          nameJapanese: 'コンビニエンスストア'
        },
        {
          id: '0d3a179e-a248-4c37-be27-f000391f58fe',
          createdBy: 'system',
          createdDate: 1496250000000,
          updatedBy: 'system',
          updatedDate: 1496250000000,
          code: '001',
          name: 'Information & Technology',
          nameJapanese: ''
        }
      ],
      totalPages: 1,
      last: true,
      totalElements: 16,
      numberOfElements: 16,
      sort: {
        sorted: true,
        unsorted: false,
        empty: false
      },
      first: true,
      size: 30,
      number: 0
    };
    return data;
  }
}
