
import { initialDefaultSettings } from '@/models/onboarding/DefaultSettings';
import CommonMixin from '@/mixins/CommonMixin';
import { mapState } from 'vuex';
import ReviewTab from '@/components/Settings/DefaultSettings/ReviewTab.vue';
import EditTab from '@/components/Settings/DefaultSettings/EditTab.vue';
import DetailCard from '@/components/Common/DetailCard.vue';
import { checkPermission } from '@/api/utils';

const flags = {
  TAB_REVIEW: 0,
  TAB_EDIT: 1
};

export default {
  components: { DetailCard, EditTab, ReviewTab },
  mixins: [CommonMixin],
  data() {
    return {
      activeTab: flags.TAB_REVIEW,
      flags: flags,
      data: initialDefaultSettings()
    };
  },
  watch: {
    data() {
      this.activeTab = flags.TAB_REVIEW;
    }
  },
  async created() {
    const defaultSettings = await this.$repo.settings.getDefaultSettings();
    if (defaultSettings) this.data = defaultSettings;
  },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    }
  }
};
