
import AuthMixin from '@/mixins/AuthMixin';
import BaseModal from '@/components/LandingPage/BaseModal';
import VModal from '@/components/LandingPage/Modal.vue';
import ModalTitle from '@/components/LandingPage/Modal/Title.vue';
import ModalBody from '@/components/LandingPage/Modal/Body.vue';
import ModalButtons from '@/components/LandingPage/Modal/Buttons.vue';

export default {
  components: {
    VModal,
    ModalTitle,
    ModalBody,
    ModalButtons
  },
  extends: BaseModal,
  mixins: [AuthMixin],
  data() {
    return {
      i18n: {
        header: 'Terms of Service',
        title: 'Section 1.10.32 of "de Finibus Bonorum et Malorum"',
        content: `
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
        sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
        adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam,
        quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?
        Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
      `,
        close: 'Close'
      }
    };
  }
};
