import { PostRequestFida } from '@/util/http';


export function APIFetchTargetsSales(params: any) {
  return PostRequestFida('/api/getSalesTargetData', params);
} // APIFetchTargets
// export function APIFetchTargets(params: any) {
//   return PostRequest('kpi/target/sales', params);
// } // APIFetchTargets

// api/Target.ts