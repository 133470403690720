
import Field from './Field';

export default {
  components: {
    Field
  },
  props: {
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hideDropdownIcon: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Object],
      default: null
    },
    useInput: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    displayValue: {
      type: [Number, String],
      default: undefined
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    haserror: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menuShown: false
  }),
  computed: {
    isValueEmpty() {
      if (this.value == null || this.value.length === 0) {
        return true;
      }
      return false;
    },
    canClear() {
      if (!this.clearable) return false;
      if (this.isValueEmpty) return false;
      return true;
    },
    canShowPlaceholder() {
      if (this.placeholder == null) return false;
      return this.isValueEmpty;
    },
    getValueOrPlaceholder() {
      if (this.useInput) return this.value;
      if (this.canShowPlaceholder) {
        return this.placeholder;
      }
      return this.value;
    }
  },
  methods: {}
};
