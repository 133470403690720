
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      timeDuration: this.$t('AlertSettings.seconds'),
      text: '',
      options: [],
      alertName: '',
      userGroups: [],
      users: [],
      userKeyword: '',
      userGroupKeyword: '',
      storeCode: null,
      floorID: null,
      personCount: 0,
      posID: null,
      storeList: [],
      floorList: [],
      posList: [],
      threshold: 0,
      timeThreshold: 0,
      message: '',
      sticker: [{ packageId: 0, stickerId: 0 }],
      userOptions: [],
      userGroupsOptions: [],
      userHierarchyList: [],
      stickerState: false,
      loading: false,
      time: [
        this.$t('AlertSettings.seconds'),
        this.$t('AlertSettings.minutes'),
        this.$t('AlertSettings.hours')
      ]
    };
  },
  computed: {
    ...mapState('alert', [
      'alertSelected',
      'usersList',
      'userGroupsList',
      'userHierarchy',
      'alertSelected',
      'settingsMode',
      'alertRetrieved'
    ]),
    ...mapState('queue', ['stores', 'floorData', 'posListByStore']),
    validated() {
      let val = [
        this.alertName,
        this.userGroups,
        this.users,
        this.storeCode,
        this.floorID,
        this.posID,
        this.threshold,
        this.timeThreshold,
        this.personCount,
        this.message
      ];
      return (
        (val.filter(v => v === null).length === 0 && this.users.length > 0) ||
        this.userGroups.length > 0
      );
    }
  },
  watch: {
    stickerState() {
      if (!this.stickerState) {
        this.sticker[0].packageId = null;
        this.sticker[0].stickerId = null;
      }
    },
    usersList() {
      this.userOptions = this.usersList.map((dat, key) => {
        return JSON.parse(
          JSON.stringify({
            label: dat.firstName,
            value: dat.firstName
          })
        );
      });
    },
    userGroupsList() {
      this.userGroupsOptions = Object.values(this.userGroupsList).map(val => {
        return {
          label: val.groupName,
          value: val.groupName
        };
      });
    },
    userHierarchy() {
      this.userHierarchyList = Object.values(this.userHierarchy).map(
        (val, key) => ({
          label: Object.keys(this.userHierarchy)[key].slice(
            2,
            Object.keys(this.userHierarchy)[key].length
          ),
          value: Object.values(val).map(v => v.firstName)
        })
      );
    },
    userGroups() {
      let userGroupNames = this.userGroupsList.map(val => val.groupName);
      let userGroupSelectedFromHierarchy = this.userHierarchyList
        .filter(val => {
          return userGroupNames.includes(val.label);
        })
        .map(v => v.value)
        .flat();
      this.users = this.userOptions
        .filter(val => {
          return userGroupSelectedFromHierarchy.includes(val.value);
        })
        .map(v => JSON.parse(JSON.stringify(v.value)));
    },
    userKeyword() {
      this.userOptions = this.usersList
        .filter(
          v =>
            v.firstName
              .toLowerCase()
              .includes(this.userKeyword.toLowerCase()) ||
            v.lastName.toLowerCase().includes(this.userKeyword.toLowerCase()) ||
            v.userName.toLowerCase().includes(this.userKeyword.toLowerCase())
        )
        .map(val => {
          return {
            label: val.firstName,
            value: val.firstName
          };
        });
    },
    userGroupKeyword() {
      this.userGroupsOptions = this.userGroupsList
        .filter(v =>
          v.groupName
            .toLowerCase()
            .includes(this.userGroupKeyword.toLowerCase())
        )
        .map(val => {
          return {
            label: val.groupName,
            value: val.groupName
          };
        });
    },
    stores() {
      if (this.stores && this.stores.length > 0) {
        this.storeList = this.stores.map(val => {
          return {
            label: val.storeName,
            value: val.storeID,
            description: val.storeCode
          };
        });
      }
    },
    floorData() {
      if (this.floorData && Object.values(this.floorData).length > 0) {
        this.floorList = Object.values(this.floorData).map(val => {
          return {
            label: val.floorName,
            value: val.floorID,
            description: val.floorLevel
          };
        });
        this.floorsList.sort((x, y) => {
          return x.description - y.description;
        });
      }
    },
    posListByStore() {
      if (
        this.posListByStore &&
        Object.values(this.posListByStore).length > 0
      ) {
        this.posList = Object.values(this.posListByStore).map(val => {
          return {
            label: val.posName,
            value: val.posID
          };
        });
      }
    },
    async storeID() {
      await this.fetchFloorListByStore(this.storeID.value);
    },
    async floorID() {
      await this.fetchPosListByStore(this.floorID.value);
    },
    posID() {
      this.$store.commit('queue/setPosID', this.posID);
    }
  },
  async created() {
    this.loading = true;
    await this.loadData();
    await this.loadStoreData();
    if (this.settingsMode === 'edit') {
      // let users=
      await this.populateEdit();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('alert', [
      'addAlertData',
      'updateAlert',
      'fetchUsers',
      'fetchUserGroup',
      'fetchUserGroupHierarchy',
      'fetchAllAlertsOverview',
      'updateAlertByID'
    ]),
    ...mapActions('queue', [
      'fetchStoresList',
      'fetchFloorListByStore',
      'fetchPosListByStore'
    ]),
    ...mapMutations('alert', ['setMode', 'setAlert', 'setDialog']),
    async loadData() {
      await this.fetchUsers();
      await this.fetchUserGroup();
      await this.fetchUserGroupHierarchy();
    },
    async loadStoreData() {
      await this.fetchStoresList();
      if (this.storeID) {
        await this.fetchFloorListByStore(this.storeID);
      } else {
        await this.fetchFloorListByStore('1');
      }
      if (this.floorID) {
        await this.fetchPosListByStore(this.floorID);
      } else {
        await this.fetchPosListByStore('1');
      }
    },
    async addAlert() {
      this.loading = true;
      let alert = {
        alertName: this.alertName,
        userGroups: this.userGroupsList
          .filter(v => {
            return this.userGroups.join(',').includes(v.groupName);
          })
          .map(v => v.groupID)
          .join(','),
        users: this.usersList
          .filter(v => {
            return this.users.join(',').includes(v.firstName);
          })
          .map(v => v.id)
          .join(','),
        storeCode: this.storeCode.description || '',
        floorID: this.floorID.value || '',
        posID: this.posID.value || '',
        threshold: this.threshold || '',
        timeThreshold: this.getThresholdSeconds(this.timeThreshold) || '',
        personCount: this.personCount,
        message: this.message || '',
        sticker: JSON.stringify(this.sticker)
      };
      if (this.settingsMode === 'add') {
        await this.addAlertData(alert);
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('AlertSettings.successMessage')
        });
      } else if (this.settingsMode === 'edit') {
        await this.updateAlertByID({
          data: alert,
          id: this.alertRetrieved.alertID
        });
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('AlertSettings.updateMessage')
        });
      }
      await this.fetchAllAlertsOverview();
      this.loading = false;
      this.setDialog(false);
    },
    async populateEdit() {
      this.alertName = this.alertRetrieved.alertName;
      this.userGroups = await this.alertRetrieved.groups.map(v => v.groupName);
      this.users = await this.alertRetrieved.users.map(
        v => v.userName.split(' ')[0]
      );
      this.storeCode = this.storeList.filter(
        val => val.description === this.alertRetrieved.store.storeCode
      )[0];
      this.floorID = this.floorList.filter(
        val => val.value === this.alertRetrieved.floor.floorID
      )[0];
      this.posID = this.posList.filter(
        val => val.label === this.alertRetrieved.pos.posName
      )[0];
      this.threshold = this.alertRetrieved.threshold;
      this.timeThreshold = this.alertRetrieved.timeThreshold;
      this.message = this.alertRetrieved.message;
      this.personCount = this.alertRetrieved.personCount || 0;
      let parsedSticker = [...JSON.parse(this.alertRetrieved.sticker)];
      if (
        parsedSticker[0].packageId !== null ||
        parsedSticker[0].stickerId !== null
      ) {
        this.stickerState = true;
        this.sticker = parsedSticker;
      }
    },
    getThresholdSeconds(time) {
      if (this.timeDuration === 'Seconds') {
        return time;
      } else if (this.timeDuration === 'Hours') {
        return time * 60 * 60;
      } else if (this.timeDuration === 'Minutes') {
        return time * 60;
      }
    },
    goToSticker() {
      window.location.href =
        'https://static.worksmobile.net/static/wm/media/message-bot-api/line_works_sticker_list_new.pdf';
    }
  }
};
