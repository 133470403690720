import { render, staticRenderFns } from "./AddNewRegion.vue?vue&type=template&id=6a81c78f&"
import script from "./AddNewRegion.vue?vue&type=script&lang=js&"
export * from "./AddNewRegion.vue?vue&type=script&lang=js&"
import style0 from "./AddNewRegion.vue?vue&type=style&index=0&id=6a81c78f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QForm,QItemLabel,QInput,QSelect,QTable,QBtn,QSpace,QTd,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QForm,QItemLabel,QInput,QSelect,QTable,QBtn,QSpace,QTd,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
