import store from '@/store';
interface Response1Item {
  _id: string;
  tenantId: string;
  kpiId: string;
  title: {
    en: string;
    ja: string;
  };
  explanation: {
    en: string;
    ja: string;
  };
  unit: {
    en: string;
    ja: string;
  };
  videoUrl: {
    en: string;
    ja: string;
  };
  visibility: number;
  order: number;
}

interface Response2Item {
  order: number;
  visibility: number;
  title: string;
  explanation: string;
  unit: string;
  videoUrl:string;
  metricKey: string;
}

export function convertResponse(response1: Response1Item[], lang: 'en' | 'ja'): { [key: string]: Response2Item } {
  const storeState: any = store.state;
  const trs: any = storeState.user.translateKpi;

  const response2: { [key: string]: Response2Item } = {};

  response1.forEach(item => {
    const {
      kpiId,
      order,
      visibility,
    } = item;

    const metricKey = kpiId;

    const titleObj = trs[metricKey].title[lang]?trs[metricKey].title[lang]:"";
    const descriptionObj = trs[metricKey].description[lang]?trs[metricKey].description[lang]:"";

    const unitObj = trs[metricKey].unit[lang]?trs[metricKey].unit[lang]:"-";
    const videoUrlObj = trs[metricKey].videoUrl[lang]?trs[metricKey].videoUrl[lang]:"";

    const newItem: Response2Item = {
      order,
      visibility,
      title: titleObj,//lang === 'en' ? (title?.en?title.en:"") : (title?.ja?title.ja:""),
      explanation: descriptionObj,//lang === 'en' ? (explanation?.en?explanation.en:"") : (explanation?.ja?explanation.ja:""),
      unit: unitObj,//lang === 'en' ? (unit?.en?unit.en:"") : (unit?.ja?unit.ja:""),
      videoUrl: videoUrlObj,//lang === 'en' ? (videoUrl?.en?videoUrl.en:""): (videoUrl?.ja?videoUrl.ja:""),
      metricKey
    };

    response2[metricKey] = newItem;
  });
  return response2;
}
