import {
  APIFetchTrafficByWeek,
  APIFetchTrafficByEntrance,
  APIFetchTrafficPowerHour,
  APIDownloadTrafficReport,
  APIFetchTrafficPowerHourV2,
  APIFetchTrafficDataByStore
} from '@/api/traffic';
import { base64ToArrayBuffer, saveByteArray, getNameByStoreCode } from '@/util/utilFunctions';
import {getStoreTimings,getInOutByStores,formatDate,
  getStoreMinAndMaxHourForDatesAndStores, getEntranceTitle,
  fillMissingHours,getListOfDates,getDayName, getEntrancesWithStores, addMissingObjects, missinZeroDataByHour} from '@/util/utilFidaFunctions';
import { debug } from 'webpack';

const initialState = {
  byWeek: [],
  byEntrance: [],
  powerHourData: [],
  powerHourDataActualValue: [],
  powerHourDataPercentageOfDay: [],
  powerHourDataPercentageOfWeek: [],
  entranceAverage: true,
  entranceInout: 'OUT',
  entranceMode: 'weekly',
  powerHourMode: 'actualValue',
  startDate: null,
  endDate: null,
  daysOfWeek: '',
  storeCodes: '',
  totalTrafficCount: 0,
  totalComparedTrafficCount: 0,
  varianceTotal: 0,
  selectedPeriod: [],
  comparedPeriod: [],
  sales:{},//temp save api data
  apiCall2:false,
  allTrafficData: [],
  trafficInList: [],
  trafficOutList: [],
  trafficList: [],
  loading: false,
  loadingPower: false
}; // initialState


const getDayOfWeek = (dateString) => {
  const date = new Date(dateString);
  const dayOfWeek = date.getUTCDay();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[dayOfWeek];
};



export default {
  state: initialState, // state
  getters: {
    getSelectedPeriod: (state) => state.selectedPeriod,
    getComparedPeriod: (state) => state.comparedPeriod,
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setLoadingPower(state, value) {
      state.loadingPower = value;
    },
    setTrafficInList(state,payload) {
      state.trafficInList =payload;
    },
    setTrafficOutList(state,payload) {
      state.trafficOutList =payload;
    },
    setTrafficList(state,payload) {
      state.trafficList =payload;
    },
    setEntranceAverage(state, { average }) {
      state.entranceAverage = average;
    },
    setentranceInout(state, { trafficCountingFlow }) {
      state.entranceInout = trafficCountingFlow;
    },
    //inout
    setEntranceMode(state, { mode }) {
      state.entranceMode = mode;
    },
    setTrafficByWeek(state, { byWeek }) {
      state.byWeek = byWeek;
    }, // setTrafficByWeek
    setTrafficByEntrance(state, { byEntrance }) {
      state.byEntrance = byEntrance;
    }, // setTrafficByEntrance
    setPowerHourMode(state, { mode }) {
      state.powerHourMode = mode;
    },
    //actualValue','percentageOfDay','percentageOfWeek
 //   powerHourDataActualValue,powerHourDataPercentageOfDay,powerHourDataPercentageOfWeek
    setPowerHourData(state, { powerHourData }) {
      state.powerHourData = powerHourData;
    },

    setPowerHourDataActualValue(state, { powerHourDataActualValue }) {
      state.powerHourDataActualValue = powerHourDataActualValue;
    },
    setPowerHourDataPercentageOfDay(state, { powerHourDataPercentageOfDay }) {
      state.powerHourDataPercentageOfDay = powerHourDataPercentageOfDay;
    },
    setPowerHourDataPercentageOfWeek(state, { powerHourDataPercentageOfWeek }) {
      state.powerHourDataPercentageOfWeek = powerHourDataPercentageOfWeek;
    },
    setStartDate(state,{startDate}){
      state.startDate = startDate;
    },
    setEndDate(state,{endDate}){
      state.endDate = endDate;
    },
    setDaysOfWeek(state,{daysOfWeek}){
      state.daysOfWeek = daysOfWeek;
    },
    setStoreCodes(state,{storeCodes}){
      state.storeCodes = storeCodes;
    },
    setTotalTrafficCount(state,{totalTrafficCount}){
      state.totalTrafficCount = totalTrafficCount;
    },
    setTotalComparedTrafficCount(state,{totalComparedTrafficCount}){
      state.totalComparedTrafficCount = totalComparedTrafficCount;
    },
    setVariance(state,{varianceTotal}){
      state.varianceTotal = varianceTotal;
    },

    setSelectedPeriodTrend(state, { selectedPeriod }) {
      state.selectedPeriod = selectedPeriod;
    }, // setSelectedPeriod
    setComparedPeriodTrend(state, { comparedPeriod }) {
      state.comparedPeriod = comparedPeriod;
    }, // setComparedPeriod
    setSales(state, payload) {
      state.sales = payload;
    }, // setComparedPeriod
    setLoaded(state, payload){
      state.apiCall2 = payload
    },
    setAllTrafficData(state,payload) {
      state.allTrafficData =payload;
    },
  }, // mutations
  actions: {
    async fetchTrafficOverViewAndTrend(
      { commit, state , rootState},
      { startDate, endDate, daysOfWeek, storeCodes, mode, startComparedDate, endComparedDate }
    ){

      const currentUrl: string = window.location.href;
      const isTrafficReportUrl: boolean = currentUrl.endsWith("traffic-report");
      if(isTrafficReportUrl != true)
      {
        return;
      }

      var res:any = [];
      var resComapred:any = [];

      var frequency = 'daily';
      if(startDate == endDate)
      {
        frequency = 'hourly';
      }
        res = await APIFetchTrafficDataByStore(
          {
            startDate,
            endDate,
            daysOfWeek,
            storeCodes,
            frequency: frequency,
            compareStartDate: startComparedDate,
            compareEndDate: endComparedDate
          }
        );

        var kpisObj = [];
        var kpisCompareObj = [];
        res.kpis = getCombinedDataOfStores(res.kpis,frequency);
        res.kpisCompare = getCombinedDataOfStores(res.kpisCompare,frequency);

        if(startDate != endDate)
        {
          kpisObj = filterDataByWeekDays(res.kpis,daysOfWeek);
          kpisCompareObj = filterDataByWeekDays(res.kpisCompare,daysOfWeek);
        }
        else
        {
          // if(res.kpis?.length ==0)
          {
            const datesList = getListOfDates(startDate, endDate);

            const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodes.split(','));
            const { minOpening, maxClosing } = getStoreTimmings;
            kpisObj = missinZeroDataByHour(res.kpis,['trf'],minOpening,maxClosing,storeCodes.split(','));
            kpisCompareObj = missinZeroDataByHour(res.kpisCompare,['trf'],minOpening,maxClosing,storeCodes.split(','));
          }
          // else
          // {
          //   kpisObj = res.kpis;
          //   kpisCompareObj = res.kpisCompare;
          // }

        }

        //Start Traffic Over view
        const totalTrafficCount = calculateTotalTraffic(kpisObj);
        const totalComparedTrafficCounts = calculateTotalTraffic(kpisCompareObj);
        var variance = 0;

        if(totalComparedTrafficCounts== 0)
          variance = 0.00;
        else
          variance = totalTrafficCount/totalComparedTrafficCounts*100;


        commit('setTotalTrafficCount', { totalTrafficCount:totalTrafficCount });
        commit('setVariance', { varianceTotal:variance });
        commit('setTotalComparedTrafficCount', { totalComparedTrafficCount:totalComparedTrafficCounts });
        //End Traffic Over view

        //Start Traffic Trend

        const selectedPeriodTrend = generateTrafficTrend(kpisObj,frequency,startDate, endDate,storeCodes);
        const comparedPeriod = generateTrafficTrend(kpisCompareObj,frequency,startComparedDate, endComparedDate, storeCodes);

        var toggle;

        if(startDate == endDate)
        {
          toggle = !rootState.traffic.apiCall2; // for watch
          commit('setLoaded', toggle)
          commit('setSelectedPeriodTrend', { selectedPeriod: selectedPeriodTrend });
          commit('setComparedPeriodTrend', { comparedPeriod: comparedPeriod });
        }
        else
        {
          toggle = !rootState.traffic.apiCall2;// for watch
          commit('setLoaded', toggle)
          commit('setSelectedPeriodTrend', { selectedPeriod: selectedPeriodTrend });
          commit('setComparedPeriodTrend', { comparedPeriod: comparedPeriod });
        }
    },
    async fetchAllTrafficFidaV2(
      { commit, state , rootState},
      { startDate, endDate, daysOfWeek, storeCodes, mode }
    )
    {
      commit('setLoading', true);
      commit('setLoadingPower', true);
      var res = await APIFetchTrafficPowerHourV2(
        {
          startDate,
          endDate,
          daysOfWeek,
          storeCodes
        }
      );

      // if(daysOfWeek != 56)
      //   input = filterDataByWeekdaysGetPlain(input,daysOfWeek);

      const datesList = getListOfDates(startDate, endDate);
      const storeCodesList = storeCodes.split(',');

      const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
      const { minOpening, maxClosing } = getStoreTimmings;

      const storesWithEntrances = getEntrancesWithStores(storeCodes);
      for (const key in storesWithEntrances) {
        if (storesWithEntrances.hasOwnProperty(key) && storesWithEntrances[key].entrances?.length === 0) {
            delete storesWithEntrances[key];
        }
    }

    if(res.length<=25000)
      res = addMissingObjects(res,startDate,endDate,minOpening,maxClosing,storesWithEntrances);
      const trafficData = getTrafficInOutTotal(res);
      //trafficInData, trafficOutData,  trafficActual};
      commit('setTrafficInList', { trafficInList: trafficData.trafficInData });
      commit('setTrafficOutList', { trafficOutList: trafficData.trafficOutData });
      commit('setLoading', false);
      commit('setLoadingPower', false);
      commit('setTrafficList', {trafficList: trafficData.trafficActual} );

    },

    fetchWeeklyInOut({ commit , state},
      { trafficInOut,weeklyOrHourly, modeInOut,startDate,endDate,storeCodes,entranceaverage })
    {
      if(trafficInOut)
      {
        var trafficWeeklyOrHourly;
        var trafficType = 'trafficOut';

        if(modeInOut == 'IN')
          trafficType = 'trafficIn';

        const datesList = getListOfDates(startDate, endDate);

        if(weeklyOrHourly == 'hourly')
        {
          trafficWeeklyOrHourly = convertResponseTrafficByHour(trafficInOut,trafficType,datesList,storeCodes,entranceaverage);
        }
        else
        {

          trafficWeeklyOrHourly = convertResponseTrafficByWeek(trafficInOut,trafficType,entranceaverage,datesList);
        }


        trafficWeeklyOrHourly.forEach(store => {
          Object.keys(store.weeklyTraffic).forEach(day => {
              store.weeklyTraffic[day] = store.weeklyTraffic[day]?.toLocaleString();
          });
        });

        // trafficWeeklyOrHourly.sort((a, b) => {
        //   const storeA = a.storeName.toUpperCase();
        //   const storeB = b.storeName.toUpperCase();

        //   if (storeA < storeB) {
        //       return -1;
        //   }
        //   if (storeA > storeB) {
        //       return 1;
        //   }
        //   return 0;
        // });
        //
       commit('setTrafficByWeek', { byWeek: trafficWeeklyOrHourly });
      }
    },

    fetchEntranceInOut({ commit , state},
      { trafficInOut,weeklyOrHourly, modeInOut, storeCodes,startDate, endDate,entranceaverage })
    {
        var trafficModeInOut;
        var trafficType = 'trafficOut';

        if(modeInOut == 'IN')
          trafficType = 'trafficIn';

          const datesList = getListOfDates(startDate, endDate);

        if(weeklyOrHourly == 'hourly')
        {
          trafficModeInOut = convertResponseTrafficByEntranceHourly(trafficInOut,trafficType, storeCodes,datesList,entranceaverage,false);
        }
        else
        {
          trafficModeInOut = convertResponseTrafficByEntrance(trafficInOut,trafficType,datesList,entranceaverage);
        }
      //  const storesWithEntrances = getEntrancesWithStores(storeCodes);
      //  trafficModeInOut = addMissingData(trafficModeInOut,storesWithEntrances);

        trafficModeInOut?.forEach(store => {
          // Get the keys of the traffic object and sort them in ascending order
          const sortedTraffic = Object.keys(store.traffic)
              .sort((a, b) => parseInt(a) - parseInt(b))
              .reduce((sorted, key) => {
                  sorted[key] = store.traffic[key];
                  return sorted;
              }, {});

          // Replace the traffic object with the sortedTraffic object
          store.traffic = sortedTraffic;

        });

       const res = changeTrafficKeys(trafficModeInOut);
       commit('setTrafficByEntrance', { byEntrance: res });
       return 1;
    },
// Power Hour response convertion
    fetchPowerHourFidaV2({ commit, state }, { trafficInOut, mode, daysOfWeek,storeCodes ,startDate, endDate }) {

      const weekdayCounts = {};
      let totalTrafficCount = 0;
      var shortObj = {};
      const actualValue = {};

      if(trafficInOut)
      {

          trafficInOut.forEach(item => {
            const tDate = item._id.split('_')[0];
            const hour = item._id.split('_')[1];
            if(shortObj[tDate+'_'+hour])
            {
              shortObj[tDate+'_'+hour] = shortObj[tDate+'_'+hour]+ item.trf;
            }
            else
              shortObj[tDate+'_'+hour] = item.trf;

              if (mode =="percentageOfWeek")
                totalTrafficCount += item.trf;
              else if(mode =="percentageOfDay")
              {
                const date = item._id.slice(0, 8); // Extracting the date part
                const dayName = getDayName(date);
                weekdayCounts[dayName] = (weekdayCounts[dayName] || 0) + item.trf;
              }
          });


        //  if(mode == "actualValue")////actualValue','percentageOfDay','percentageOfWeek
          {
              for (const key in shortObj) {
                const date = key.slice(0, 8); // Extracting the date part
                const hour = key.slice(-2); // Extracting the hour part
                const dayName = getDayName(date);

                if (!actualValue[dayName]) {
                    actualValue[dayName] = [];
                }

                const existingEntry = actualValue[dayName].find(entry => entry.hour === `${hour}:00`);
                if (existingEntry) {
                    // If entry exists, add the value to the existing one
                    existingEntry.value += shortObj[key];
                    existingEntry.formattedValue = existingEntry.value.toLocaleString();
                } else {
                    actualValue[dayName].push({
                        hour: `${hour}:00`,
                        value: shortObj[key],
                        formattedValue: shortObj[key].toLocaleString()
                    });
                  }
              }
            }
            if(mode == "percentageOfDay")////actualValue','percentageOfDay','percentageOfWeek
            {
              for (const day in actualValue) {
                actualValue[day].forEach(entry => {
                 // const percentageDayCount = ((entry.value / weekdayCounts[day]) * 100).toFixed(0);
                  const percentageDayCount = isNaN((entry.value / weekdayCounts[day]) * 100) ? 0 : ((entry.value / weekdayCounts[day]) * 100).toFixed(0);

                  entry.value = Number(percentageDayCount);
                  entry.formattedValue = entry.value + '%';
                });
              }
            }
            else if(mode == "percentageOfWeek")
            {
              for (const day in actualValue) {
                actualValue[day].forEach(entry => {
             //     const percentageOfWeekCount = ((entry.value / totalTrafficCount) * 100).toFixed(0);
                  const percentageOfWeekCount = isNaN((entry.value / totalTrafficCount) * 100) ? 0 : ((entry.value / totalTrafficCount) * 100).toFixed(0);

                  entry.value = Number(percentageOfWeekCount);
                  entry.formattedValue = entry.value + '%';

                });
              }
            }
          }
            var data = actualValue;

            // const allZero = Object.values(data).every((dayData:any) =>
            //     dayData.every(hourData => hourData.value === 0)
            //   );
            //   if (allZero) {
            //     data =  {};
            //   }
            for (const day in data) {
              data[day].sort((a, b) => {
                const timeA:any = new Date(`1970-01-01T${a.hour}`);
                const timeB:any = new Date(`1970-01-01T${b.hour}`);
                return timeA - timeB;
              });
            }

            commit('setPowerHourData', { powerHourData: data });
          },
    fetchPowerHourFida({ commit, state }, { trafficInOut, mode, daysOfWeek,storeCodes ,startDate, endDate }) {

      var result = {};
      const weekdayCounts = {};
      let totalTrafficCount = 0;

      trafficInOut.forEach(item => {
        const startTimestamp = subtractHours(item.startTimestamp.split('T')[0]);
        const dayOfWeek = startTimestamp.toLocaleDateString('en-US', { weekday: 'long' });
        var allHHour = item.startTimestamp.split('T')[1];//10:00:00...
        var actualHour = allHHour.split(':')[0];
        if(Number(allHHour.split(':')[1]!=0))
        {
          actualHour = Number(actualHour)+1;
        }

        const hour = actualHour.toString().padStart(2, '0') + ':00';
        const countValue = item.trafficOut.countValue;

        result[dayOfWeek] = result[dayOfWeek] || {};
        result[dayOfWeek][hour] = (result[dayOfWeek][hour] || 0) + countValue;

        weekdayCounts[dayOfWeek] = (weekdayCounts[dayOfWeek] || 0) + countValue;
        totalTrafficCount += countValue;
      });


      const datesList = getListOfDates(startDate, endDate);
    const storeCodesList = storeCodes.split(',');

    const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
    const { minOpening, maxClosing } = getStoreTimmings;


    result = fillMissingHours(result, minOpening, maxClosing);

      const actualValue = {};
      const percentageOfDay = {};
      const percentageOfWeek = {};

      const uniqueHours = new Set();

      Object.keys(result).forEach(dayOfWeek => {
        Object.keys(result[dayOfWeek]).forEach(hour => {
          uniqueHours.add(hour);
        });
      });

      const uniqueHoursArray = [...uniqueHours].sort();

      const filteredDaysOfWeeks = daysOfWeek
        .split(',')
        .map(day => day.charAt(0).toUpperCase() + day.slice(1));

      filteredDaysOfWeeks.forEach(day => {
        if (result[day]) {
          actualValue[day] = [];
          percentageOfDay[day] = [];
          percentageOfWeek[day] = [];

          uniqueHoursArray.forEach(hour => {
            const count = result[day][hour] || 0;
            const percentageDay = ((count / weekdayCounts[day]) * 100).toFixed(0);
            const percentageWeek = ((count / totalTrafficCount) * 100).toFixed(0);

            actualValue[day].push({
              hour,
              value: Number(count) || 0,
              formattedValue: count.toString(),
            });

            percentageOfDay[day].push({
              hour,
              value: Number(percentageDay) || 0,
              formattedValue: percentageDay + '%',
            });

            percentageOfWeek[day].push({
              hour,
              value: Number(percentageWeek) || 0,
              formattedValue: percentageWeek + '%',
            });
          });
        }
      });
      console.log(percentageOfDay);
      console.log(percentageOfWeek);
      console.log(actualValue);
      const data = mode === 'percentageOfDay' ? percentageOfDay :
                   mode === 'percentageOfWeek' ? percentageOfWeek : actualValue;

      commit('setPowerHourData', { powerHourData: data });
    }
,

fetchTrafficByWeek: async function(
  { commit },
  {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    storeCodes,
    daysOfWeek,
    mode,
    average,
    trafficCountingFlow
    // daysOfWeek
  }
) {
  const res = await APIFetchTrafficByWeek(
    {
      startDate,
      endDate,
      compareStartDate,
      compareEndDate,
      storeCodes,
      daysOfWeek,
      averageFlag: average,
      trafficCountingFlow: trafficCountingFlow
    },
    mode
  );
  commit('setTrafficByWeek', { byWeek: res.data });
  return res;
},
// fetchTrafficByWeek
fetchTrafficByEntrance: async function(
  { commit },
  {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    storeCodes,
    daysOfWeek,
    mode,
    average,
    trafficCountingFlow
  }
) {
  const res = await APIFetchTrafficByEntrance(
    {
      startDate,
      endDate,
      compareStartDate,
      compareEndDate,
      storeCodes,
      daysOfWeek,
      averageFlag: average,
      trafficCountingFlow: trafficCountingFlow
    },
    mode
  );
  commit('setTrafficByEntrance', { byEntrance: res.data });
  return res;
},
async fetchPowerHourData(
  { commit },
  { startDate, endDate, daysOfWeek, storeCodes, mode }
) {
  const res = await APIFetchTrafficPowerHour(
    {
      startDate,
      endDate,
      daysOfWeek,
      storeCodes
    },
    mode
  );
  commit('setPowerHourData', { powerHourData: res.data });
},

    downloadTrafficReport({ commit }, filterParams) {
      return new Promise(async (resolve, reject) => {
        try {
          const res: any = await APIDownloadTrafficReport(filterParams);
          const sampleArr = base64ToArrayBuffer(res.data.encodedData);
          saveByteArray(`${res.data.fileName}`, sampleArr);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

  }, // actions

}; // export default
// Response conversion of Traffic In and Traffic Out
function convertResponseTrafficByWeek(data,trafficType,entranceaverage,datesList) {
  const stores = {};

data.forEach(item => {

  const dayOfWeek = getDayName(item._id.slice(0, 8));
  const storeCode = item._id.split('_')[3];

  if (!stores[storeCode]) {

    stores[storeCode] = {
      storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
      storeCode: storeCode,
      weeklyTraffic: {
        Monday: 0 ,
        Tuesday: 0 ,
        Wednesday: 0 ,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0
      },
    };
  }

  let sumOfValues = 0;

  // Loop through each key in the trf object
  for (const key in item.trf) {
    // Check if the current key has the "out" property
    if (item.trf[key].hasOwnProperty('out')) {
      // Add the out value to the sum
      sumOfValues += item.trf[key].out;
    }
    else if (item.trf[key].hasOwnProperty('in')) {
      // Add the out value to the sum
      sumOfValues += item.trf[key].in;
    }
  }


    if (dayOfWeek in stores[storeCode].weeklyTraffic) {
      stores[storeCode].weeklyTraffic[dayOfWeek] = (stores[storeCode].weeklyTraffic[dayOfWeek])+(sumOfValues);
    }
});

if(entranceaverage)
  {

    const res:any = convertResponseTrafficByEntrance(data,trafficType,datesList,entranceaverage);;


  let obj2 = res.map(item => {
    let weeklyTraffic = {
        "Monday": 0,
        "Tuesday": 0,
        "Wednesday": 0,
        "Thursday": 0,
        "Friday": 0,
        "Saturday": 0,
        "Sunday": 0
    };

    Object.values(item.traffic).forEach(trafficData => {
        Object.keys(trafficData).forEach(day => {
            weeklyTraffic[day] += parseInt(trafficData[day]);
        });
    });

    return {
        "storeName": item.storeName,
        "storeCode": item.storeCode,
        "weeklyTraffic": weeklyTraffic
    };
  });

  return obj2;

  }
  const finalResponse = Object.values(stores);

  return finalResponse;
}

function convertResponseTrafficByEntrance(data,trafficType,datesList,entranceaverage) {
  if(data) {

    const stores = {};
    data.forEach(item => {

    const dayOfWeek = getDayName(item._id.slice(0, 8));
    const storeCode = item._id.split('_')[3];

    if (!stores[storeCode]) {

      stores[storeCode] = {
        storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
        storeCode: storeCode,
        traffic: {},
      };
    }

      // Loop through each key in the trf object
      for (const key in item.trf) {
        const entranceId = key;
        var countValue = 0;

        if(trafficType == "trafficOut")
          countValue = item.trf[key].out;
        else
          countValue = item.trf[key].in;

        if (!stores[storeCode].traffic[entranceId]) {
          stores[storeCode].traffic[entranceId] = {
            Monday: "0 ",
            Tuesday: "0 ",
            Wednesday: "0 " ,
            Thursday: "0 ",
            Friday: "0 ",
            Saturday: "0 ",
            Sunday: "0 "
          };
        }

        if (dayOfWeek in stores[storeCode].traffic[entranceId]) {
          stores[storeCode].traffic[entranceId][dayOfWeek] = Number(stores[storeCode].traffic[entranceId][dayOfWeek]) + Number(countValue) + ' ';
        }
      }
    });


  if(entranceaverage)
  {
    const divider = datesList.length;

    Object.keys(stores).forEach(storeCode => {
      const store = stores[storeCode];
      Object.keys(store.traffic).forEach(entrance => {
        const trafficData = store.traffic[entrance];
        Object.keys(trafficData).forEach(day => {
          const value = parseInt(trafficData[day], 10);
          if (!isNaN(value)) {
            trafficData[day] = Math.floor(value / divider).toString();
          }
        });
      });
    });
  }

  const finalResponse = Object.values(stores);

  return finalResponse;
}
else
return null;
}

// function convertResponseTrafficByEntrance(data,trafficType,datesList,entranceaverage) {
//   const stores = {};
//   if(data) {
//   data.forEach((item) => {

//     const storeCode = item.storeCode;
//     const dayOfWeek = new Date(item.startTimestamp.split('T')[0]).toLocaleDateString("en-US", { weekday: "long" });

//     if (!stores[storeCode]) {
//       stores[storeCode] = {
//         storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
//         storeCode: storeCode,
//         traffic: {},
//       };
//     }

//     const entranceId = item[trafficType].entranceId;
//     const countValue = item[trafficType].countValue;

//     if (!stores[storeCode].traffic[entranceId]) {
//       stores[storeCode].traffic[entranceId] = {
//         Monday: "0 ",
//         Tuesday: "0 ",
//         Wednesday: "0 " ,
//         Thursday: "0 ",
//         Friday: "0 ",
//         Saturday: "0 ",
//         Sunday: "0 "
//       };
//     }

//     if (dayOfWeek in stores[storeCode].traffic[entranceId]) {

//       stores[storeCode].traffic[entranceId][dayOfWeek] = Number(stores[storeCode].traffic[entranceId][dayOfWeek]) + Number(countValue) + ' ';
//     }
//   });

//   // Convert stores object to an array of values

//   if(entranceaverage)
//   {
//     const divider = datesList.length;

//     Object.keys(stores).forEach(storeCode => {
//       const store = stores[storeCode];
//       Object.keys(store.traffic).forEach(entrance => {
//         const trafficData = store.traffic[entrance];
//         Object.keys(trafficData).forEach(day => {
//           const value = parseInt(trafficData[day], 10);
//           if (!isNaN(value)) {
//             trafficData[day] = Math.floor(value / divider).toString();
//           }
//         });
//       });
//     });
//   }

//   const finalResponse = Object.values(stores);

//   return finalResponse;
// }
// else
// return null;
// }

// function convertResponseTrafficByHour(data, trafficType,datesList,storeCodes,entranceaverage) {
//   const stores = [];
//   const storeCodesList = storeCodes.split(',');
//   const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
//   const { minOpening, maxClosing } = getStoreTimmings;

//   if(!entranceaverage)
//   {
//     data.forEach((item) => {
//       const storeCode = item.storeCode;

//       if (!stores[storeCode]) {
//         stores[storeCode] = {
//           storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
//           storeCode: storeCode,
//           weeklyTraffic: {},
//         };
//         for (let i = minOpening; i <= maxClosing; i++) {
//           const formattedHour = `${i.toString().padStart(2, '0')}:00`;
//           stores[storeCode].weeklyTraffic[formattedHour] = 0;
//         }
//       }

//       const hour = new Date(item.startTimestamp).getUTCHours();
//       const countValue = item[trafficType].countValue;

//       const formattedHour = `${hour.toString().padStart(2, '0')}:00`;

//       if (formattedHour in stores[storeCode].weeklyTraffic) {
//         stores[storeCode].weeklyTraffic[formattedHour] += countValue;
//         //stores[storeCode].weeklyTraffic[formattedHour] = countValue + ' ';
//       }
//     });
//     const finalResponse = Object.values(stores);
//     return finalResponse;
//   }
//   else {
//     const res = convertResponseTrafficByEntranceHourly(data,trafficType, storeCodes,datesList,entranceaverage, true);

//       let obj2 = [];

//       res.forEach(store => {
//           let weeklyTraffic = {
//           };


//           Object.keys(store.traffic).forEach(day => {
//             Object.keys(store.traffic[day]).forEach(time => {
//                 weeklyTraffic[time] = (weeklyTraffic[time] ? weeklyTraffic[time] : 0) + store.traffic[day][time];
//             });
//         });


//           obj2.push({
//               storeName: store.storeName,
//               storeCode: store.storeCode,
//               weeklyTraffic: weeklyTraffic
//           });
//       });

//       return obj2;
//     }

// //     data.forEach((item) => {
// //       const storeCode = item.storeCode;

// //       if (!stores[storeCode]) {
// //         stores[storeCode] = {
// //           storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
// //           storeCode: storeCode,
// //           weeklyTraffic: {},
// //         };
// //         for (let i = minOpening; i <= maxClosing; i++) {
// //           const formattedHour = `${i.toString().padStart(2, '0')}:00`;
// //           stores[storeCode].weeklyTraffic[formattedHour] = 0;
// //         }
// //       }

// //       const hour = new Date(item.startTimestamp).getUTCHours();
// //       const countValue = item[trafficType].countValue;

// //       const formattedHour = `${hour.toString().padStart(2, '0')}:00`;

// //       if (formattedHour in stores[storeCode].weeklyTraffic) {
// //         stores[storeCode].weeklyTraffic[formattedHour] += countValue;
// //         //stores[storeCode].weeklyTraffic[formattedHour] = countValue + ' ';
// //       }
// //     });
// //     const divider =datesList.length;
// //     for (const key in stores) {
// //       const weeklyTraffic = stores[key].weeklyTraffic;
// //       for (const day in weeklyTraffic) {
// //           const value = weeklyTraffic[day] / divider;
// //           weeklyTraffic[day] = Math.floor(value);
// //       }
// //   }
// // }
// //  // Convert stores object to an array of values
// //   const finalResponse = Object.values(stores);

//    //return finalResponse;

// }

function convertResponseTrafficByHour(data, trafficType,datesList,storeCodes,entranceaverage) {

  const storeCodesList = storeCodes.split(',');
  const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
  const { minOpening, maxClosing } = getStoreTimmings;

  if(!entranceaverage)
  {
    const stores = {};

    data.forEach(item => {

    //  const dayOfWeek = getDayName(item._id.slice(0, 8));
      const storeCode = item._id.split('_')[3];

      if (!stores[storeCode]) {


        stores[storeCode] = {
          storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
          storeCode: storeCode,
          weeklyTraffic: {},
        };
        for (let i = minOpening; i <= maxClosing; i++) {
          const formattedHour = `${i.toString().padStart(2, '0')}:00`;
          stores[storeCode].weeklyTraffic[formattedHour] = 0;
        }
      }

      let sumOfValues = 0;
      // Loop through each key in the trf object
      for (const key in item.trf) {
        // Check if the current key has the "out" property
        if (item.trf[key].hasOwnProperty('out')) {
          // Add the out value to the sum
          sumOfValues += item.trf[key].out;
        }
        else if (item.trf[key].hasOwnProperty('in')) {
          // Add the out value to the sum
          sumOfValues += item.trf[key].in;
        }
      }



      const hour = item._id.split('_')[1];
      const countValue = sumOfValues;

      const formattedHour = `${hour.toString().padStart(2, '0')}:00`;

      if(!stores[storeCode].weeklyTraffic[formattedHour])
          stores[storeCode].weeklyTraffic[formattedHour] = 0;


      if (formattedHour in stores[storeCode].weeklyTraffic) {

        stores[storeCode].weeklyTraffic[formattedHour] += countValue;
        //stores[storeCode].weeklyTraffic[formattedHour] = countValue + ' ';
      }

        // if (dayOfWeek in stores[storeCode].weeklyTraffic) {
        //   stores[storeCode].weeklyTraffic[dayOfWeek] = (stores[storeCode].weeklyTraffic[dayOfWeek])+(sumOfValues);
        // }
    });


    const finalResponse = Object.values(stores);
    return finalResponse;
  }
    else {
    const res = convertResponseTrafficByEntranceHourly(data,trafficType, storeCodes,datesList,entranceaverage, true);

      let obj2 = [];

      res.forEach(store => {
          let weeklyTraffic = {
          };

          Object.keys(store.traffic).forEach(day => {
            Object.keys(store.traffic[day]).forEach(time => {
                weeklyTraffic[time] = (weeklyTraffic[time] ? weeklyTraffic[time] : 0) + store.traffic[day][time];
            });
        });


          obj2.push({
              storeName: store.storeName,
              storeCode: store.storeCode,
              weeklyTraffic: weeklyTraffic
          });
      });

      return obj2;
    }

}
function convertResponseTrafficByEntranceHourly(data, trafficType, storeCodes,datesList,entranceaverage, outtside) {

  var finalResponse;

  // const stores ={};
  const storeCodesList = storeCodes.split(',');

  const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
  const { minOpening, maxClosing } = getStoreTimmings;
  const stores = {};
  data.forEach((item) => {
    const storeCode = item._id.split('_')[3];
    const hour = item._id.split('_')[1];

    if (!stores[storeCode]) {
      stores[storeCode] = {
        storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
        storeCode: storeCode,
        traffic: {},
      };
    }


    // Loop through each key in the trf object
    for (const entranceId in item.trf) {
      let sumOfValues = 0;
      // // Check if the current key has the "out" property
      if (item.trf[entranceId].hasOwnProperty('out')) {
        // Add the out value to the sum
        sumOfValues = item.trf[entranceId].out;
      }
      else if (item.trf[entranceId].hasOwnProperty('in')) {
        // Add the out value to the sum
        sumOfValues = item.trf[entranceId].in;
      }

      if (!stores[storeCode].traffic[entranceId])
      {
        stores[storeCode].traffic[entranceId] = {};

        for (let i = minOpening; i <= maxClosing; i++) {
          const formattedHour = `${i.toString().padStart(2, '0')}:00`;
          stores[storeCode].traffic[entranceId][formattedHour] = 0;
        }
      }
      const formattedHour = `${hour.toString().padStart(2, '0')}:00`;

      if(!stores[storeCode].traffic[entranceId][formattedHour])
        stores[storeCode].traffic[entranceId][formattedHour] = 0;

      if (formattedHour in stores[storeCode].traffic[entranceId]) {
        stores[storeCode].traffic[entranceId][formattedHour] += sumOfValues;// + ' ';
      }
    }
  });
  //

  if(entranceaverage)
  {
    finalResponse = Object.values(stores);

    const divider =datesList.length;
    finalResponse.forEach(store => {
      Object.keys(store.traffic).forEach(entrance => {
        Object.keys(store.traffic[entrance]).forEach(time =>
          {
            store.traffic[entrance][time] = Math.floor(store.traffic[entrance][time] / divider);
        });
      });
    });
    return finalResponse;
  }


  finalResponse = Object.values(stores);

  return finalResponse;
}
//   function convertResponseTrafficByEntranceHourly(data, trafficType, storeCodes,datesList,entranceaverage, outtside) {

//     const stores ={};
//     const storeCodesList = storeCodes.split(',');

//     const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
//     const { minOpening, maxClosing } = getStoreTimmings;

//     data.forEach((item) => {
//       const storeCode = item.storeCode;
//       const entranceId = item[trafficType].entranceId;
//       const hour1 = Number(item.startTimestamp.split('T')[1].split(':')[0]);
//       const hour2 = Number(item.startTimestamp.split('T')[1].split(':')[1]);
//       var finalhour =0;
//       if(hour2>0)
//         finalhour = hour1+hour2;
//       else
//         finalhour = hour1;

//       const countValue = item[trafficType].countValue;

//       if (!stores[storeCode]) {
//         stores[storeCode] = {
//           storeName: getNameByStoreCode(storeCode), // Replace this with the actual store name
//           storeCode: storeCode,
//           traffic: {},
//         };
//       }

//       if (!stores[storeCode].traffic[entranceId]) {
//         stores[storeCode].traffic[entranceId] = {};
//         for (let i = minOpening; i <= maxClosing; i++) {
//           const formattedHour = `${i.toString().padStart(2, '0')}:00`;
//           stores[storeCode].traffic[entranceId][formattedHour] = 0;
//         }
//       }

//       const formattedHour = `${finalhour.toString().padStart(2, '0')}:00`;

//       if (formattedHour in stores[storeCode].traffic[entranceId]) {
//         stores[storeCode].traffic[entranceId][formattedHour] += countValue;// + ' ';
//       }
//     });
//     var finalResponse;

//     if(entranceaverage)
//     {
//       finalResponse = Object.values(stores);

//       const divider =datesList.length;
//       finalResponse.forEach(store => {
//         Object.keys(store.traffic).forEach(entrance => {
//           Object.keys(store.traffic[entrance]).forEach(time =>
//             {
//               store.traffic[entrance][time] = Math.floor(store.traffic[entrance][time] / divider);
//           });
//         });
//       });
//       return finalResponse;
//     }

//     // if(entranceaverage && !outtside)
//     // {
//     //   finalResponse = Object.values(stores);
//     //   const divider =datesList.length;
//     //   finalResponse.forEach(store => {
//     //     Object.keys(store.traffic).forEach(entrance => {
//     //       Object.keys(store.traffic[entrance]).forEach(time =>
//     //         {
//     //           store.traffic[entrance][time] = Math.floor(store.traffic[entrance][time] / divider);
//     //       });
//     //     });
//     //   });
//     //   return finalResponse;
//     // }

//     finalResponse = Object.values(stores);

//     return finalResponse;
// }
//Entrance End
function subtractHours(date) {

  const newDate = new Date(date);
  // newDate.setHours(newDate.getHours() - 5);//have to fix this issue
  return newDate;
};

function convertToDate(dateString) {
  // Extract year, month, and day from the string
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  // Create a Date object
  const date = `${year}-${month}-${day}`;

  return date.toString();
}
function getCombinedDataOfStores(obj,frequency){
  if (!obj)
    return [];

    const data = [];

    for (const kpi of obj) {
      var dateObj;
      if(frequency == 'daily')
        dateObj = convertToDate(kpi._id.split('_')[0]);
      else
        dateObj = kpi._id.split('_')[0];
      if(data[dateObj])
        data[dateObj]  += kpi.trf?kpi.trf:0;
      else
        data[dateObj] = kpi.trf?kpi.trf:0;
    }

    const extractedData = Object.keys(data).map(date => {
      return {
        date: date,
        traffic: data[date]
      };
    });
return extractedData;
}
function filterDataByWeekDays(obj,daysOfWeek){
  if (!daysOfWeek || daysOfWeek.trim() === '' || !obj)
    return [];

    let weekdaysArray = daysOfWeek.split(',');

    let daysToFilter = weekdaysArray.map(day => {
      return day.charAt(0).toUpperCase() + day.slice(1);
    });
    const filteredKPIs = obj.filter(kpi => {
      const date = new Date(kpi.date);
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
      return daysToFilter.includes(dayOfWeek);
    });

  let extractedData = filteredKPIs.map(item => {
    return {
        date: item.date,
        traffic: item.traffic
    };
});

return extractedData;
}
function calculateTotalTraffic(obj){
  let total = 0;
  if(!obj)
  return 0;

  obj.forEach(item => {
      if (item.traffic) {
        total  += item.traffic?item.traffic:0;
      }
  });

return total;
}

function generateTrafficTrend(obj,frequency,startDate, endDate, storeCodes)
{

  const datesList = getListOfDates(startDate, endDate);

  let selectedPeriodTrend = [];
  if(frequency == 'daily') {

    let dailyMap = new Map();

    for (var item3 of obj) {
      const dailyDate = item3.date;
      if (dailyMap.has(dailyDate)) {
        dailyMap.set(dailyDate, dailyMap.get(dailyDate) + item3.traffic);
      } else {
        dailyMap.set(dailyDate, item3.traffic);
      }
    }

    const apiResponseDates = new Set(dailyMap.keys());

  // Loop through dateList and add missing dates with values set to 0
  datesList.forEach(date => {
      if (!apiResponseDates.has(date)) {
        dailyMap.set(date, 0);
      }
  });

  // Sort the Map by date
  const sortedArray = new Map([...dailyMap.entries()].sort());



    // Convert the Map entries to an array and sort it based on keys
  //const sortedArray = Array.from(dailyMap).sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());

  // Convert the sorted array back to a Map
  const sortedMap = new Map(sortedArray);

  sortedMap.forEach((trafficSum, dailyDate) => {

        selectedPeriodTrend.push({
            timeLabel: dailyDate,
            value: trafficSum,
            temperature: 0,
            weather: "",
            maxTemp: 0,
            minTemp: 0,
            weatherIcon: "",
            formattedValue: trafficSum.toLocaleString()+' ',
            traffic: trafficSum.toLocaleString()+' '
        });
    });
  }
  else//hourly
  {
    let hourMap = new Map();

    //datesList

    // const storeCodesList = storeCodes.split(',');

    // const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
    // const { minOpening, maxClosing } = getStoreTimmings;
    // for (let i = minOpening; i <= maxClosing; i++) {
    //   if (!hourMap.has(i)) {
    //       // If the hour is missing in the Map, add it with a placeholder value
    //       hourMap.set(i, 0); // You can set any default value here
    //   }
    // }

    for (var itemw of obj) {
      const hour = itemw.date;
      if (hourMap.has(hour)) {
        hourMap.set(hour, hourMap.get(hour) + itemw.traffic);
      } else {
        hourMap.set(hour, itemw.traffic);
      }
    }

    // Convert the Map entries to an array and sort it based on keys
  const sortedArray = Array.from(hourMap).sort((a, b) => a[0] - b[0]);

  // Convert the sorted array back to a Map
  const sortedMap = new Map(sortedArray);

  sortedMap.forEach((trafficSum, hour) => {
      selectedPeriodTrend.push({
        timeLabel: hour,
        value: trafficSum,
        temperature: 0,
        weather: "",
        maxTemp: 0,
        minTemp: 0,
        weatherIcon: "",
        formattedValue: trafficSum.toLocaleString() + ' ',
        traffic: trafficSum.toLocaleString() + ' '
      });
    });
  }
  return selectedPeriodTrend;
}

function filterDataByWeekdaysGetPlain(data,daysOfWeek) {
  const filteredDaysOfWeeks = daysOfWeek.split(',').map(day => day.charAt(0).toUpperCase() + day.slice(1));
  const filteredData = data.filter(entry => {
      const timestamp = new Date(entry.startTimestamp.split('T')[0]);//may be we have to get only dates by 'T'
      const dayName = timestamp.toLocaleString('en-US', { weekday: 'long' });
      return filteredDaysOfWeeks.includes(dayName);
  });
  return filteredData;
}


// Function to calculate total trafficIn and trafficOut for each store
function getTrafficInOut(apiData) {
  const trafficInData = [];
  const trafficOutData = [];
  // Loop through each entry in the API response
  apiData.forEach(entry => {
    const { kpiType} = entry;

    // If kpiType is trafficIn, store the data accordingly
    if (kpiType === 'trafficIn')
      trafficInData.push(entry);
    else
      trafficOutData.push(entry);
  });

  return { trafficIn: trafficInData, trafficOut: trafficOutData };
}
function getEntrancesWithFlowIn(entrances, apiresponse, storeCodesWithFlowIn) {
  const entrancesWithFlowIn = [];

  storeCodesWithFlowIn.forEach(storeCode => {
    const relevantData = apiresponse.filter(data => {
      return data.storeCode === storeCode;
    });

    if (relevantData.length > 0) {
      const modifiedData = relevantData.map(entry => {
        const { trafficIn, ...rest } = entry;
        return {
          ...rest,
          kpiType: entry.kpiType.replace('trafficIn', 'trafficOut'),
          trafficOut: entry.trafficIn,
        };
      });

      entrancesWithFlowIn.push(...modifiedData);
    }
  });

  return entrancesWithFlowIn;
}


function removeEntrancesFlowInFromTrafficOut(apiresponse, storeCodesWithFlowIn) {
  if (!apiresponse || storeCodesWithFlowIn.length === 0) {
    console.log('No data to process');
    return apiresponse;
  }
  const result = apiresponse.filter(data => !storeCodesWithFlowIn.includes(data.storeCode));
  return result;
}

function changeTrafficKeys(storesData) {
  // Loop through each store
  storesData?.forEach(store => {
      const trafficKeys = Object.keys(store.traffic);
      // Create a new object to store updated traffic data
      const newTraffic = {};

      // Iterate through traffic keys and update them
      trafficKeys.forEach((key, index) => {
          const newKey = getEntranceTitle(store.storeCode, key);
          newTraffic[newKey] = store.traffic[key];
      });

      // Replace the old traffic object with the updated one
      store.traffic = newTraffic;
  });

  return storesData;
}

//V2
function getTrafficInOutTotal(apiData) {
  const trafficInData = [];
  const trafficOutData = [];
  const trafficActual = [];

    apiData.forEach(item => {
        const { _id, trf } = item;
        // if(!trf)
        //   trf= { trf:0, out:0}

        let trfValue  = trf || { trf: 0, out: 0 };

        // Check if trf exists and is an object
    if (trfValue && typeof trfValue === 'object') {
      const newTrfWithoutOut = {};
      const newTrfWithoutIn = {};
      let sumOfTrfValues = 0;

      Object.keys(trfValue).forEach(key => {
          const currentTrf = trfValue[key];

          // Check if currentTrf is an object
          if (currentTrf && typeof currentTrf === 'object') {
              const { trf: trfValue=0, out=-1, in: inVal=-1, ...rest } = currentTrf;

              // Assigning trf to out if out is missing
              const outValue = out !== -1 ? out : trfValue;
              const inValue = inVal !== -1 ? inVal : trfValue;

              newTrfWithoutIn[key] = { in: inValue, ...rest };
              newTrfWithoutOut[key] = { out: outValue, ...rest };
              sumOfTrfValues += trfValue;
          }
      });

      // Push your processed data into respective arrays
      trafficInData.push({ _id, trf: newTrfWithoutIn });
      trafficOutData.push({ _id, trf: newTrfWithoutOut });
      trafficActual.push({ _id, trf:  sumOfTrfValues  });
  }
});

    return { trafficInData, trafficOutData,  trafficActual};
}

//const { withoutOut, withoutTrf } = removeOutAndTrfKeys(kpisData);

function removeOutKeys(kpis) {
  const kpisWithoutOut = kpis.map(item => {
      const { _id, trf } = item;
      const newTrf = {};

      Object.keys(trf).forEach(key => {
          const { trf: trfValue } = trf[key];
          newTrf[key] = { trf: trfValue };
      });

      return { _id, trf: newTrf };
  });

  return kpisWithoutOut;
}


// Please write a function getTrafficInOutTotal in javascript which will return 3 arrays
// const trafficInData = [];
//   const trafficOutData = [];
//   const trafficActual = [];
