import store from '@/store/modules/store';
import { APIFetchUserGroup, APIFetchUsers } from '@/api/alert';
import {
  APIAddUser,
  APIAddUserGroup,
  APIDeleteUserByID,
  APIDeleteUserGroupByID,
  APIFetchRoles,
  APIFetchUserByID,
  APIFetchUserGroupByID,
  APIUpdateUser,
  APIUpdateUserGroup
} from '@/api/usermgmt';
import { Notify } from 'quasar';

const initialState = {
  userGroup: {},
  user: {},
  userGroupsList: [],
  users: [],
  showDialog: false,
  settingsMode: 'add',
  roles: [],
  userID: ''
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    setUsersGroup(state, payload) {
      state.userGroupsList = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserGroup(state, payload) {
      state.userGroup = payload;
    },
    setDialog(state, payload) {
      state.showDialog = payload;
    },
    setSettingsMode(state, payload) {
      state.settingsMode = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setUserID(state, payload) {
      state.userID = payload;
    }
  }, // mutations
  actions: {
    async fetchUsers({ commit }) {
      let res = await APIFetchUsers({});
      commit('setUsers', res.data);
    },
    async fetchUserGroup({ commit }) {
      let res = await APIFetchUserGroup({});
      commit('setUsersGroup', res.data);
    },
    async addUser({ commit }, user) {
      let res: any = await APIAddUser(user);
      Notify.create({
        type: res.status === 'ERROR' ? 'negative' : 'positive',
        position: 'top',
        message: res.description
      });
    },
    async addUserGroup({ commit }, userGroup) {
      let res = await APIAddUserGroup(userGroup);
    },
    async updateUser({ commit }, { user, id }) {
      let res = await APIUpdateUser(user, id);
    },
    async updateUserGroup({ commit }, { userGroup, id }) {
      let res = await APIUpdateUserGroup(userGroup, id);
    },
    async fetchUsersByID({ commit }, id) {
      let res = await APIFetchUserByID({}, id);
      commit('setUserID', id);
      commit('setUser', res.data);
    },
    async fetchUserGroupByID({ commit }, id) {
      let res = await APIFetchUserGroupByID({}, id);
      commit('setUserGroup', res.data);
    },
    async deleteUsersByID({ commit }, id) {
      let res = await APIDeleteUserByID({}, id);
    },
    async fetchRoles({ commit }) {
      let res = await APIFetchRoles({});
      commit('setRoles', res.data);
    },
    async deleteUserGroupByID({ commit }, id) {
      let res = await APIDeleteUserGroupByID({}, id);
    }
  } // actions
}; // export default
