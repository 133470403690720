import { PostRequest } from '@/util/http';
import { PostRequestFida } from '@/util/http';
import { groupNamesList } from '@/util/mockData/groupName';
import { genRawData } from './apiDataManagers/fakegen'
import store from '@/store/';
import demographics from '@/store/modules/demographics';
import moment from 'moment';

export function APIFetchDemographicsByPeriod(type, params: any) {
  return PostRequest('demographics/byPeriod/' + type, params);
} // APIFetchDemographicsByPeriod

//Seems like no use
export function APIFetchDemographicsByWeek(params: any) {
  return PostRequest('demographics/byPeriod/weekly', params);
} // APIFetchDemographicsByWeek

//Seems like no use
export function APIFetchDemographicsByHour(params: any) {
  return PostRequest('demographics/byPeriod/hourly', params);
}

export function APIFetchDemographicsDistributionByGroup(params: any) {
  return PostRequest('demographics/distribution/byGroup', params);
} // APIFetchDemographicsDistributionByGroup

// export function APIFetchDemographicsByGender(params: any) {
//   return PostRequest('demographics/distribution/byGender', params);
// }

export function APIFetchDemographicsByStore(params: any) {
  return PostRequest('demographics/distribution/byStore', params);
}


///////////////////////////Fida////////////////////////////////
const apiCache: { [key: string]: any } = {};

export async function APIFetchDemographicsFidaAll(params: any) {
  params.storeCodes = params.storeCodes.split(',');
  // const callType = params.typeD;//:'group'
  delete params.typeD;

  // if(Object.keys(apiCache).length >= 2) {
  //   for (const key of Object.keys(apiCache)) {
  //     delete apiCache[key];
  //   }
  // }

  // const cacheKey = JSON.stringify(params);
  // if (apiCache[cacheKey]) {
  //   return apiCache[cacheKey];
  // }
  var result = null
  // if (userCheck()) {
  //   result = getFakeData(params)
  // } else {
    result = await PostRequestFida('/api/getDemographicsDataV2', params);
  // }
  const response = result.data.response.kpis;
  // apiCache[cacheKey] = response;

  return response;
}

function getFakeData(newParam) {
  let { startDate, endDate, compareStartDate, compareEndDate, storeCodes, frequency  } = newParam;
  let payloadSelected = { dateS: startDate, dateE: endDate, storeCodes: storeCodes.toString(), frequency };
  let payloadCompare = { dateS: compareStartDate, dateE: compareEndDate, storeCodes: storeCodes.toString() , frequency };
  let isCompDates = compareStartDate && compareEndDate;
  let fakeData = genRawData(payloadSelected);
  let fakeDataComp = isCompDates && genRawData(payloadCompare);
  if(frequency === 'hourly'){
    fakeData = fakeData['hourlyResp']
    fakeDataComp = fakeData['hourlyResp']
  }
  // only for demographic code
  let getDate = (dateString) => {
     let dateSplit = dateString.split('_')
     return frequency === 'hourly'? parseInt(dateSplit[0]): moment(dateSplit[0]).format('dddd').toLocaleLowerCase()
  }
  let alterfakeData = fakeData.map((el) => {
    let name = frequency === 'hourly' ? 'hour':'dayName'
    return {
      "_id": { [name]: getDate(el._id)},
      ...el.demographics
    }
  })
  let data = {
    data: {
      "status": 200,
      "message": "Success",
      "transKey": "SUCCESS",
      "response": {
        "kpis": alterfakeData,
        ...(isCompDates && { "kpisCompare": fakeDataComp })
      },
      "callName": ""
    }
  }
  return data
}
function userCheck() {
  let { user } = store.state['user']['defaultSettings'];
  let isDemo = user?.response['userDetails']['tenant']['isDemo'];
  return isDemo;
}
