import { APIFetchHolidays } from '@/api/utils';
import { APIFetchKPI } from '@/api/compare';
import { debug } from 'webpack';
import { PostRequest } from '@/util/http';
import { format } from "date-fns";
import moment from "moment";
import store from '..';
import {
  startOfWeek,
  endOfWeek,
  startOfTomorrow,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  getDay
} from 'date-fns';

const today = new Date();
const yesterday = new Date(today);

yesterday.setDate(yesterday.getDate() - 1);

const retailCalendar = {
      "2013": {
          "startDate": "2012-05-20",
          "numberOfWeeks": "53"
      },
      "2014": {
          "startDate": "2013-05-26",
          "numberOfWeeks": "53"
      },
      "2015": {
          "startDate": "2014-06-01",
          "numberOfWeeks": "52"
      },
      "2016": {
          "startDate": "2015-05-31",
          "numberOfWeeks": "52"
      },
      "2017": {
          "startDate": "2016-05-29",
          "numberOfWeeks": "53"
      },
      "2018": {
          "startDate": "2017-06-04",
          "numberOfWeeks": "52"
      },
      "2019": {
          "startDate": "2018-06-03",
          "numberOfWeeks": "52"
      },
      "2020": {
          "startDate": "2019-06-02",
          "numberOfWeeks": "52"
      },
      "2021": {
          "startDate": "2020-05-31",
          "numberOfWeeks": "52"
      },
      "2022": {
          "startDate": "2021-05-30",
          "numberOfWeeks": "52"
      },
      "2023": {
          "startDate": "2022-05-29",
          "numberOfWeeks": "53"
      },
      "2024": {startDate: "2023-05-28", numberOfWeeks: "53"},
      "2025": {startDate: "2024-06-02", numberOfWeeks: "52"},
      "2026": {startDate: "2025-06-01", numberOfWeeks: "52"},
      "2027": {startDate: "2026-05-31", numberOfWeeks: "52"},
      "2028": {startDate: "2027-05-30", numberOfWeeks: "52"},
      "2029": {startDate: "2028-05-28", numberOfWeeks: "53"},
      "2030": {startDate: "2029-06-03", numberOfWeeks: "52"},
  }

  const formatDate2 = date => {
    if (!date) return null;
    let newDate = format(date, 'yyyy-MM-dd');
    const [year, month, day] = newDate.split('-');
    return `${year}-${month}-${day}`;
  };

const formatDate = date => {
  if (!date) return null;
  let newDate = date.toISOString().substr(0, 10);
  const [year, month, day] = newDate.split('-');
  return `${year}-${month}-${day}`;
};

const initialState = {
  filter: {
    storeCodes: [],
    storeCodesCal: [],
    daysOfWeek: [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ],
    kpiMetrics: [],
    startingPeriod: formatDate(new Date()),
    endingPeriod: formatDate(new Date()),
    startingCompared: formatDate(yesterday),
    endingCompared: formatDate(yesterday),

    startingPeriodCal:formatDate2(startOfMonth(addMonths(new Date(), 1))),
    endingPeriodCal: formatDate2(endOfMonth(addMonths(new Date(), 1))),
    startingComparedCal: formatDate2(addYears(startOfMonth(addMonths(new Date(), 1)), -1)),
    endingComparedCal: formatDate2(addYears(endOfMonth(addMonths(new Date(), 1)), -1)),//formatDate2(endOfMonth(addMonths(new Date(), 1))),
    
    
    selectionPeriodOption: null,
    selectionPeriodOptionCal: null,
    
    custom445Dates: null,
    selectedOption: JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods,
    comparedOption: JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods,
    hasSessionListned: false,

    pulseSelectedStart: formatDate(new Date()),
    pulseSelectedEnd: formatDate(new Date()),
    pulseComparedStart: formatDate(yesterday),
    pulseComparedEnd: formatDate(yesterday)
  },
  retailCalendar: [],
  useCustomCalender: false,
  radarChartKpiOptions: [
    'sls',
    'tra',
    'trf',
    'trn',
    'cn',
    'ap',
    'upt',
    'rov',
    'aip',
    'spt',
    'tpt',
    'sl',
    'sh',
    'cs',
    'se',
    'pb',
    'cr',
    'dw',
    'is',
    'st'
  ],
  tabluarDataKpiOptions: ['sls', 'trf', 'cn', 'rov'],
  holidays: []
};

export default {
  state: initialState,
  getters: {
    getStoreCodesList: state => {
      localStorage.getItem('firstList')
      if(state?.filter?.storeCodes?.length > 0) {
        return state.filter.storeCodes?.join(',')
      } else if(localStorage.getItem('firstList')) {
        return localStorage.getItem('firstList');
      } else {
        return 'all'
      }
    },
    getStoreCodesCalList: state => {
      localStorage.getItem('firstList')
      if(state?.filter?.storeCodesCal?.length > 0) {
        return state.filter.storeCodesCal?.join(',')
      } else if(localStorage.getItem('firstList')) {
        return localStorage.getItem('firstList');
      } else {
        return 'all'
      }
    },
    //storeCodesCal
    getDaysOfWeekList: state => {
      return state.filter.daysOfWeek.length > 0
        ? state.filter.daysOfWeek.join(',')
        : 'all';
    },

    getStartingPeriodCal: state => {
      return state.filter.startingPeriodCal;
    },
    getEndingPeriodCal: state => {
      return state.filter.endingPeriodCal;
    },
    getStartingComparedCal: state => {
      return state.filter.startingComparedCal;
    },
    getEndingComparedCal: state => {
      return state.filter.endingComparedCal;
    },

    getStartingPeriod: state => {
      return state.filter.startingPeriod;
    },
    getEndingPeriod: state => {
      return state.filter.endingPeriod;
    },
    getStartingCompared: state => {
      return state.filter.startingCompared;
    },
    getEndingCompared: state => {
      return state.filter.endingCompared;
    },

    getPulseStartingPeriod: state => {
      return state.filter.pulseSelectedStart;
    },
    getPulseEndingPeriod: state => {
      return state.filter.pulseSelectedEnd;
    },
    getPulseStartingCompared: state => {
      return state.filter.pulseComparedStart;
    },
    getPulseEndingCompared: state => {
      return state.filter.pulseComparedEnd;
    },
    getCustomCalenderState: state => {
      return state.useCustomCalender;
    },
    getCustom445DateRange: state => {
      return state.filter.custom445Dates;
    },
    getHasSessionListnend: state => {
      return state.filter.hasSessionListned;
    },
  },
  mutations: {
    async setStoreCodes(state, { storeCodes }) {
      var firstStoreList = localStorage.getItem('firstList');

      console.log(firstStoreList, "********", storeCodes)
      if(Array.isArray(storeCodes)) {
        if (!firstStoreList) {
          localStorage.removeItem('firstList');
          localStorage.setItem('firstList', storeCodes?.join(',') ?? '');
          state.filter.storeCodes = storeCodes;
        }
        localStorage.removeItem('storeCodes');
        localStorage.setItem('storeCodes', storeCodes?.join(',') ?? '');
        state.filter.storeCodes = storeCodes;
      }
    }, // setStoreCodes
    async setStoreCodesCal(state, { storeCodesCal }) {
      state.filter.storeCodesCal = storeCodesCal;
    },
    
    setDaysOfWeek(state, { daysOfWeek }) {
      localStorage.removeItem('daysOfWeek');
      localStorage.setItem('daysOfWeek', daysOfWeek);
      state.filter.daysOfWeek = daysOfWeek;
    }, // setDaysOfWeek

    setSelectedOptionsValue(state, { val }) {
      localStorage.removeItem('setSelectedOptionsValue');
      localStorage.setItem('setSelectedOptionsValue', val);
      state.filter.setSelectedOptionsValue = val;
    }, // setStartingCompared
    setStartingPeriod(state, { startingPeriod }) {
      // console.log('setStartingPeriod')
      localStorage.removeItem('startingPeriod');
      localStorage.setItem('startingPeriod', startingPeriod);
      state.filter.startingPeriod = startingPeriod;
    },
    setStartingPeriodCal(state, { startingPeriodCal }) {
      // console.log('setStartingPeriod')
      localStorage.removeItem('startingPeriodCal');
      localStorage.setItem('startingPeriodCal', startingPeriodCal);
      state.filter.startingPeriodCal = startingPeriodCal;
    },
    setEndingPeriodCal(state, { endingPeriodCal }) {
      localStorage.removeItem('endingPeriodCal');
      localStorage.setItem('endingPeriodCal', endingPeriodCal);
      state.filter.endingPeriodCal = endingPeriodCal;
    }, 
    
    setStartingComparedCal(state, { startingComparedCal }) {
      localStorage.removeItem('startingComparedCal');
      localStorage.setItem('startingComparedCal', startingComparedCal);
      state.filter.startingComparedCal = startingComparedCal;
    }, // setStartingCompared
    setEndingComparedCal(state, { endingComparedCal }) {
      localStorage.removeItem('endingComparedCal');
      localStorage.setItem('endingComparedCal', endingComparedCal);
      state.filter.endingComparedCal = endingComparedCal;
    }, // setEndingCompared
    // setStartingPeriod
    setEndingPeriod(state, { endingPeriod }) {
      localStorage.removeItem('endingPeriod');
      localStorage.setItem('endingPeriod', endingPeriod);
      state.filter.endingPeriod = endingPeriod;
    }, // setEndingPeriod

    setComparedOptionsValue(state, { val }) {
      localStorage.removeItem('setComparedOptionsValue');
      localStorage.setItem('setComparedOptionsValue', val);
      state.filter.setComparedOptionsValue = val;
    }, // setStartingCompared
    setStartingCompared(state, { startingCompared }) {
      localStorage.removeItem('startingCompared');
      localStorage.setItem('startingCompared', startingCompared);
      state.filter.startingCompared = startingCompared;
    }, // setStartingCompared
    setEndingCompared(state, { endingCompared }) {
      localStorage.removeItem('endingCompared');
      localStorage.setItem('endingCompared', endingCompared);
      state.filter.endingCompared = endingCompared;
    }, // setEndingCompared

    setPulseStartingPeriod(state, val) {
      localStorage.removeItem('pulseSelectedStart');
      localStorage.setItem('pulseSelectedStart', val);
      state.filter.pulseSelectedStart = val;
    },
    setPulseEndingPeriod(state, val) {
      localStorage.removeItem('pulseSelectedEnd');
      localStorage.setItem('pulseSelectedEnd', val);
      state.filter.pulseSelectedEnd = val;
    },
    setPulseStartingCompared(state, val) {
      localStorage.removeItem('pulseComparedStart');
      localStorage.setItem('pulseComparedStart', val);
      state.filter.pulseComparedStart = val;
    },
    setPulseEndingCompared(state, val) {
      localStorage.removeItem('pulseComparedEnd');
      localStorage.setItem('pulseComparedEnd', val);
      state.filter.pulseComparedEnd = val;
    },
    setCalendarDatesToStore(state, {selectedOption, comparedOption, selectedPeriod, comparedPeriod}) {
      if(selectedPeriod?.start?._isValid && selectedPeriod?.start?._d) {
        selectedPeriod.start = selectedPeriod?.start?._d
      }
      if(selectedPeriod?.end?._isValid && selectedPeriod?.end?._d) {
        selectedPeriod.end = selectedPeriod?.end?._d
      }
      if(comparedPeriod?.start?._isValid && comparedPeriod?.start?._d) {
        comparedPeriod.start = comparedPeriod?.start?._d
      }
      if(comparedPeriod?.end?._isValid && comparedPeriod?.end?._d) {
        comparedPeriod.end = comparedPeriod?.end?._d
      }
      if(selectedPeriod) {
        localStorage.removeItem('startingPeriod');
        let s = format(selectedPeriod.start, "yyyy-MM-dd")
        localStorage.setItem('startingPeriod', s);
        state.filter.startingPeriod = s;
        let e = format(selectedPeriod.end, "yyyy-MM-dd")
        localStorage.removeItem('endingPeriod');
        localStorage.setItem('endingPeriod', e);
        state.filter.endingPeriod = e
      }
      if(comparedPeriod) {
        localStorage.removeItem('startingCompared');
        localStorage.setItem('startingCompared', format(comparedPeriod.start, "yyyy-MM-dd"));
        state.filter.startingCompared = format(comparedPeriod.start, "yyyy-MM-dd");
        localStorage.removeItem('endingCompared');
        localStorage.setItem('endingCompared', format(comparedPeriod.end, "yyyy-MM-dd"));
        state.filter.endingCompared = format(comparedPeriod.end, "yyyy-MM-dd");
      }
      if(comparedOption) {
        localStorage.removeItem('comparedOption');
        localStorage.setItem('comparedOption', comparedOption);
        state.filter.comparedOption = comparedOption;
      }
      if(selectedOption) {
        localStorage.removeItem('selectedOption');
        localStorage.setItem('selectedOption', selectedOption);
        state.filter.selectedOption = selectedOption;
      }
    },
    setCalendarDatesToStoreCal(state, {selectedOption, comparedOption, selectedPeriod, comparedPeriod}) {
      if(selectedPeriod?.start?._isValid && selectedPeriod?.start?._d) {
        selectedPeriod.start = selectedPeriod?.start?._d
      }
      if(selectedPeriod?.end?._isValid && selectedPeriod?.end?._d) {
        selectedPeriod.end = selectedPeriod?.end?._d
      }
      if(comparedPeriod?.start?._isValid && comparedPeriod?.start?._d) {
        comparedPeriod.start = comparedPeriod?.start?._d
      }
      if(comparedPeriod?.end?._isValid && comparedPeriod?.end?._d) {
        comparedPeriod.end = comparedPeriod?.end?._d
      }
      if(selectedPeriod) {
        localStorage.removeItem('startingPeriodCal');
        let s = format(selectedPeriod.start, "yyyy-MM-dd")
        localStorage.setItem('startingPeriodCal', s);
        state.filter.startingPeriodCal = s;
        let e = format(selectedPeriod.end, "yyyy-MM-dd")
        localStorage.removeItem('endingPeriodCal');
        localStorage.setItem('endingPeriodCal', e);
        state.filter.endingPeriodCal = e
      }
      if(comparedPeriod) {
        localStorage.removeItem('startingComparedCal');
        localStorage.setItem('startingComparedCal', format(comparedPeriod.start, "yyyy-MM-dd"));
        state.filter.startingComparedCal = format(comparedPeriod.start, "yyyy-MM-dd");
        localStorage.removeItem('endingComparedCal');
        localStorage.setItem('endingComparedCal', format(comparedPeriod.end, "yyyy-MM-dd"));
        state.filter.endingComparedCal = format(comparedPeriod.end, "yyyy-MM-dd");
      }
      if(comparedOption) {
        localStorage.removeItem('comparedOptionCal');
        localStorage.setItem('comparedOptionCal', comparedOption);
        state.filter.comparedOptionCal = comparedOption;
      }
      if(selectedOption) {
        localStorage.removeItem('selectedOptionCal');
        localStorage.setItem('selectedOptionCal', selectedOption);
        state.filter.selectedOptionCal = selectedOption;
      }
    },
    setSelectedPeriodCal(state, { selectedPeriod }) {
      localStorage.removeItem('selectedOptionCal');
      localStorage.setItem('selectedOptionCal', JSON.stringify(selectedPeriod));
      state.filter.selectionPeriodOptionCal = selectedPeriod;
    },
    setSelectedPeriod(state, { selectedPeriod }) {
      localStorage.removeItem('selectedOption');
      localStorage.setItem('selectedOption', JSON.stringify(selectedPeriod));
      state.filter.selectionPeriodOption = selectedPeriod;
    },
    setCustom445DateRange(state, { custom445Dates }) {
      state.filter.custom445Dates = custom445Dates;
    }, // setCustomDateRange445
    setCustomCalenderFlag(state, { value }) {
      localStorage.removeItem('useCustomCalender');
      localStorage.setItem('useCustomCalender', value);
      state.useCustomCalender = value;
    },
    setRadarChartKpiOptions(state, { kpiOptions }) {
      state.radarChartKpiOptions = kpiOptions;
    },
    setTabluarDataKpiOptions(state, kpiOptions) {
      state.tabluarDataKpiOptions = kpiOptions;
    },
    setHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setKPIMapping(state, payload) {
      state.kpiMetrics = payload;
    },
    getLocalStorageTabularKPI(state) {
      const storedKPI = localStorage.getItem('selectedTabularKPI');
      if (storedKPI && storedKPI.length) {
        state.tabluarDataKpiOptions = storedKPI.split(',');
      }
    },
    setRetailCalendar(state, { dates }) {
      localStorage.removeItem('retailCalendar');
      localStorage.setItem('retailCalendar', JSON.stringify(dates));
      if (dates && Object.keys(dates).length) {
        const formattedArr = Object.values(dates).map((v: any, i: number) => ({
          label: Object.keys(dates)[i],
          ...v
        }));
        state.retailCalendar = formattedArr;
      }
    },
    setHasSessionListnened(state, val) {
      localStorage.removeItem('hasSessionListned');
      localStorage.setItem('hasSessionListned', val);
      state.filter.hasSessionListned = val;
    },
    firstTimeCheck(state) {

      let session = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.session || {};
      if(Object.keys(session).length && !state.filter.hasSessionListned) {


        let compOpt = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods
        let selOpt = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods
        if(compOpt) {
          localStorage.removeItem('comparedOption');
          localStorage.setItem('comparedOption', compOpt);
          state.filter.comparedOption = compOpt;
        }
        if(selOpt) {
          localStorage.removeItem('selectedOption');
          localStorage.setItem('selectedOption', selOpt);
          state.filter.selectedOption = selOpt;
        }
        localStorage.removeItem('hasSessionListned');
        localStorage.setItem('hasSessionListned', "1");
        state.filter.hasSessionListned = "1";
        setTimeout(() => {

        localStorage.removeItem('selectedOption');
        localStorage.setItem('selectedOption', JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods);
        state.filter.selectedOption = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods;
        localStorage.removeItem('comparedOption');
        localStorage.setItem('comparedOption', JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods);
        state.filter.comparedOption = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods;
        }, 1000)
      }
      if(Object.keys(session).length < 1) {
        localStorage.removeItem('hasSessionListned');
        localStorage.setItem('hasSessionListned', "");
        state.filter.hasSessionListned = undefined;
      }
    }
  },
  actions: {
    // async fetchRetailCalendar({ getters }) {

    //   try {
    //     let retVal = {}
    //     let date = new Date()
    //     let dateY = date.getFullYear()
    //     Object.keys(retailCalendar).forEach((k) => {
    //         if(Number(k) <= Number(dateY)) {
    //             retVal[k] = retailCalendar[k]
    //         }
    //     })
    //     return retVal
    //   } catch (error) {
    //     console.error('fetch445Calendar(fail): ', error);
    //   }
    // },
    async fetchRetailCalendar({ getters }) {

      try {
        let retVal = {}
        let date = new Date()
        // let dateY = date.getFullYear()
        Object.keys(retailCalendar).forEach((k) => {
            if(new Date(retailCalendar[k].startDate) <= date) {
                retVal[k] = retailCalendar[k]
            }
        })
        return retVal
      } catch (error) {
        console.error('fetch445Calendar(fail): ', error);
      }
    },


    async fetchHolidays({ commit }) {
      let res = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${JSON.parse(localStorage.getItem('vuex')).user.locale}.japanese%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAoX78xQN-i5ZNAdfwI6Q8hUonXlztezG0`)
      .then(resp => resp.json())
      commit(
        'setHolidays',
        res.items.map((v, key) => ({
          date: v.start.date,
          value: v.summary
        }))
      );
    },
    setPersistentTabularKPI({ commit }, selectedKPI) {
      localStorage.removeItem('selectedTabularKPI');
      localStorage.setItem('selectedTabularKPI', selectedKPI.join(','));
    }
  }
}; // export default
