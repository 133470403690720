import { convertFromCurrencyDTO } from '@/models/formatters/CurrencyFormatter';
import { CoreService } from '@/services';
import BusinessTypeController from '@/services/core/BusinessTypeController';
import CurrencyController from '@/services/core/CurrencyController';
import IndustryController from '@/services/core/IndustryController';
import OperatingSystemController from '@/services/core/OperatingSystemController';
import { Pagination } from '@/util/pagination';
import { map } from 'lodash';

export default class CoreRepository {
  private readonly businessTypeController: BusinessTypeController;
  private readonly operatingSystemController: OperatingSystemController;
  private readonly industryController: IndustryController;
  private readonly currencyController: CurrencyController;

  constructor(service: CoreService) {
    this.businessTypeController = service.businessType;
    this.operatingSystemController = service.operatingSystem;
    this.industryController = service.industry;
    this.currencyController = service.currency;
  }

  public async getBusinessTypes() {
    const res = await this.businessTypeController.getBusinessTypes();
    return res.content;
  }

  public async getOperatingSystems() {
    return [
      {
        id: 'd1ea18d8-a691-11e6-80f5-76304dec7001',
        createdBy: 'system',
        createdDate: 1478586884810,
        updatedBy: 'system',
        updatedDate: 1478586884810,
        code: '001',
        name: 'IT'
      }
    ];
  }

  public async getIndustries(pagination: Pagination = new Pagination(0, 30)) {
    const { content } = await this.industryController.getIndustries(
      pagination.toRequest()
    );
    return content;
  }

  public async getCurrencies() {
    const { content } = await this.currencyController.getCurrencies();
    return map(content, dto => convertFromCurrencyDTO(dto));
  }
}
