
import { SubTaskDTO } from '@/models/onboarding/SubTask';
import { handleErrorUI } from '../../util/error';

export default {
  data() {
    return {
      activeModule: null,
      activeTask: {
        onboardingSubTasks: [],
        icon: null
      },
      onboardingProgress: [],
      completedSubTaskIds: [],
      loading: true
    };
  },
  computed: {
    subtasksCompletedInPercent() {
      return (
        (this.subTasksCompletedCount / this.subTasksToCompleteCount) * 100.0
      );
    },
    subTasksToCompleteCount() {
      return this.activeTask.onboardingSubTasks.length ?? 0;
    },
    subTasksCompletedCount() {
      return this.subTasksToCompleteCount - this.subTasksToComplete.length;
    },
    subTasksToComplete() {
      return this.activeTask.onboardingSubTasks.filter(
        subTask => !this.completedSubTaskIds.includes(subTask.id)
      );
    },
    activeSubTask(): SubTaskDTO {
      if (this.subTasksToComplete.length === 0) return null;
      return this.subTasksToComplete[0];
    }
  },
  async created() {
    const session = await this.$repo.session.fetch();
    if (!session) return;
    this.loading = true;

    try {
      const onboardingModules = await this.$repo.onboarding.getSections();
      this.onboardingProgress = await this.$repo.onboarding.getCurrentOnboardingProgess();
      this.completedSubTaskIds = this.onboardingProgress.map(
        progress => progress.onboardingSubtaskId
      );

      for (const module of onboardingModules) {
        const tasks = module.onboardingTasks;
        for (const task of tasks) {
          const subTasksToComplete = task.onboardingSubTasks.filter(
            subTask => !this.completedSubTaskIds.includes(subTask.id)
          );
          if (subTasksToComplete.length !== 0) {
            this.activeTask = task;
            break;
          }
        }

        if (this.activeTask != null) {
          this.activeModule = module;
          break;
        }
      }
    } catch (err) {
      handleErrorUI(err);
    }

    this.loading = false;
  },
  methods: {
    updateOnboardingStatus() {
      if (this.activeSubTask == null) return;
      this.$repo.onboarding.markAsCompleted(this.activeSubTask.id);
    }
  }
};
