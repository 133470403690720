
import Vue from "vue";

import { mapState, mapActions, mapGetters } from "vuex";
import { DUO_TONES, MONO_TONE } from "../../util/colors";
import { formatYAxis } from "../../util/legend";
import moment from "moment";
import { weatherObj } from "@/util/weatherIcons";
import axios from "axios";

import {resetTrendValue} from '@/api/apiDataManagers/fidaToOldConvertor';
export default {
  props: {
    modifiedDate: { type: String, default: '' },
    allTrends: { type: Object, default: () => {} },
    storecode: {
      type: String,
      default: "",
      required: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    }, // options
  }, // props
  data() {
    return {
      tooltipTarget: true,
      showTooltip: false,
      lastTimStamp: "",
      loading: false,
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          type: "line",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: '<i class="fas fa-download"></i>',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              ...(this.options.metricKey == "trf" && {
                 customIcons: [
                  {
                    icon:
                      `<span style="display: inline-block;margin-top: -27px; margin-left: -149px;"><svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg> ${this.$store.state.user.translate.view_traffic_report}</span>`,
                    title: "View traffic report",
                    class: "custom-tool-icon2 icon-w",
                    click:  () => {
                      this.$router.push('traffic-report')
                    },
                  },
                ],
                
              }),
              ...(this.options.metricKey == "sls" && {
                customIcons: [
                  {
                     icon:
                      `<span><svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg> <span class="view-report">${this.$store.state.user.translate.view_detailed_report}</span></span>`,
                    title: "View detailed report",
                    class: "custom-tool-icon2 icon-w",
                    click:  () => {
                      this.$router.push('detailed-report')
                    },
                  },
                ],
               
              }),
            },
          },
        },
        // title: {
        //   text: '',
        //   align: 'left',
        //   style: {
        //     fontSize: '16px',
        //     fontWeight: 'bold',
        //     color: '#333',
        //   },
        // },
        colors: DUO_TONES,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
          dashArray: [0, 6, 0, 6],
        },
        legend: {
          show: true,
          position: "top",
          markers: {
            radius: 0,
            width: 16,
            height: 4,
            customHTML: function () {
              return `<style>
  .apexcharts-legend-series:nth-child(1) .custom-marker,.apexcharts-legend-series:nth-child(3) .custom-marker{
      display:none;
  }
  </style>
                            <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:0;top:-2px;background:white"></div>
                          <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white"></div>`;
            },
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          forceNiceScale: true,
          tickAmount: 4,
          labels: {
            formatter: (val) => formatYAxis(val),
          },
        },
        xaxis: {
          categories: [],
          tooltip: { enabled: false },
          labels: {
            formatter: (val) => {
              let lang = this.$store.state.user.locale;
              return val?.length < 6 ?val:moment(val).locale(lang).format(lang == 'ja' ?'M月DD日': 'MMM DD')
            },
          },
          tickAmount: 50,
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const dataPointSelected =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[0] &&
              w.config.series[0].data[dataPointIndex]
                ? w.config.series[0].data[dataPointIndex]
                : "";
            const dataPointCompared =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[1] &&
              w?.config.series[1].data[dataPointIndex]
                ? w.config.series[1].data[dataPointIndex]
                : "";
            //
            const dataPointSelectedSales =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[2] &&
              w.config.series[2].data[dataPointIndex]
                ? w.config.series[2].data[dataPointIndex]
                : "";
            const dataPointSelectedSalesC =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[3] &&
              w.config.series[3].data[dataPointIndex]
                ? w.config.series[3].data[dataPointIndex]
                : "";
            return `
                <q-card class="flex column q-pa-md tooltip-container">
                  <div class="flex justify-between">
                       <label>${dataPointSelected.z?.popUpdate ?? "-"}</label>
                          <div class="flex">
                          ${
                            (this.getStoreCodesList.split(",").length &&
                              dataPointSelected?.z?.weather) ||
                            (this.storecode && dataPointSelected?.z?.weather)
                              ? `<div class="flex items-center justify-between weather-tooltip">
                                  ${weatherObj[dataPointSelected.z.weatherIcon]}
                                  </div>
                                  <div class="text-bold q-ml-sm">
                                    ${dataPointSelected.z.maxTemp + "°C"}
                                  </div>
                                  <div class="q-ml-sm">
                                    ${dataPointSelected.z.minTemp + "°C"}
                                  </div>`
                              : ""
                          }
                     </div>
                    </div>
                    <div class="flex tooltip-title items-center">
                      <div class="tooltip-title-color" style="background:${
                        w.config.colors[0]
                      }"></div>
                        <label>${this.$t("selected_period")}:</label>
                        <div class="q-pl-md">${
                          dataPointSelected?.formattedValue ?? "0"
                        }</div>
                    </div>
                    ${
                      this.options.metricKey == 'sls' && this.tooltipTarget
                        ? `<div class="flex tooltip-title items-center">
                      <div class="tooltip-title-color" style="background:${
                        w.config.colors[2]
                      }"></div>
                        <label>${
                          this.$store.state.user.translate.sales_target_selected_period
                        }:</label>
                        <div class="q-pl-md">${
                          dataPointSelectedSales?.formattedValue ?? "0"
                        }</div>
                    </div>`
                        : ``
                    }
                  <div class="flex justify-between"><label for="">
                  ${dataPointCompared.z?.popUpdate ?? "-"}
                  </label>
                  <div class="flex">
                          ${
                            this.getStoreCodesList.split(",").length &&
                            dataPointCompared?.z?.weather
                              ? `<div class="flex items-center justify-between weather-tooltip">
                                  ${weatherObj[dataPointCompared.z.weatherIcon]}
                                  </div>
                                  <div class="text-bold q-ml-sm">
                                    ${dataPointCompared.z.maxTemp + "°C"}
                                  </div>
                                  <div class="q-ml-sm">
                                    ${dataPointCompared.z.minTemp + "°C"}
                                  </div>`
                              : ""
                          }
                     </div>
                  </div>
                    <div class="flex tooltip-title items-center">
                    <div class="tooltip-title-color" style="background:${
                      w.config.colors[1]
                    }"></div>
                      <label>${this.$t("compared_period")}:</label>
                      <div class="q-pl-md">${
                        dataPointCompared.formattedValue ?? "0"
                      }</div>
                    </div>
                    ${
                      this.options.metricKey == 'sls' && this.tooltipTarget
                        ? `<div class="flex tooltip-title items-center">
                    <div class="tooltip-title-color" style="background:${
                      w.config.colors[3]
                    }"></div>
                      <label>${
                        this.$store.state.user.translate.sales_target_compared_period
                      }:</label>
                      <div class="q-pl-md">${
                        dataPointSelectedSalesC?.formattedValue ?? "0"
                      }</div>
                    </div>`
                        : ``
                    }
                </q-card>
              `;
          },
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
      getAvgHourTargertAchive(weatherData, bool) {
        var obj = weatherData[0] || {};
        const tempObj = { temp: 0, tempMin: 0, tempMax: 0 };
        const myObj = {};
        const iconCounts = {};
        const descriptionCounts = {};
        //
        for (const key in obj) {
          tempObj.temp += obj[key].temp;
          tempObj.tempMax += obj[key].tempMax;
          tempObj.tempMin += obj[key].tempMin;
          // Count occurrences of icons and descriptions
          iconCounts[obj[key].icon] = (iconCounts[obj[key].icon] || 0) + 1;
          descriptionCounts[obj[key].description] =
            (descriptionCounts[obj[key].description] || 0) + 1;
        }
        // // Find the most common icon and description
        const mostCommonIcon = Object.keys(iconCounts).reduce((a, b) =>
          iconCounts[a] > iconCounts[b] ? a : b
        );
        const mostCommonDescription = Object.keys(descriptionCounts).reduce((a, b) =>
          descriptionCounts[a] > descriptionCounts[b] ? a : b
        );
        // Calculate average temperature values
        tempObj.temp = tempObj.temp / 24;
        tempObj.tempMax = tempObj.tempMax / 24;
        tempObj.tempMin = tempObj.tempMin / 24;
        // icons
        tempObj.icon = mostCommonIcon;
        tempObj.description = mostCommonDescription;
        var dates = bool ? this.getPulseEndingPeriod : this.getPulseEndingCompared;
        myObj[dates] = tempObj;
        return [myObj];
      },
      addKeyWeather(apiDataArray, bool) {
        var wOrC = bool ? "weather" : "weatherCompare";
        // var target =
        //   this.options.metricKey ==
        //   this.$store.state.user.translate.target_achievement_rate;
        // var isSingleDates = this.getPulseStartingPeriod === this.getPulseEndingPeriod || this.getPulseStartingCompared === this.getPulseEndingCompared && target
        // var weatherObj =
        //  isSingleDates
        //     ? this.getAvgHourTargertAchive(this.$store.state.weather[wOrC], bool)
        //     : this.$store.state.weather[wOrC];
        var weatherObj = this.$store.state.weather[wOrC]
        if(this.options.metricKey == "tra" && this.getPulseStartingPeriod === this.getPulseEndingPeriod) {
          let minT = 0, maxT =0, avgT = 0, c =0, sum =0, icon = {};
          Object.keys(weatherObj["0"]).forEach((k) => {
            let w = weatherObj["0"][k]
            c++;
            sum += w.temp;
            if(minT > w.tempMin) { minT = w.tempMin; }
            if(maxT < w.tempMax) { maxT = w.tempMax; }
            if(icon[w.icon]) {
              icon[w.icon].count += 1;
            } else {
              icon[w.icon] = {icon: w.icon, description: w.description, count: 1}
            }
          })
          avgT = c < 1 ? 0 : sum/c;

          let maxIcon = "";
          c = 0;
          Object.keys(icon).forEach((k) => {
            if(icon[k].count > c) {
              maxIcon = icon[k];
              c = icon[k].count;
            }
          })

          return apiDataArray?.map(obj => {
            return {
              ...obj,
              maxTemp: maxT,
              minTemp: minT,
              weather: maxIcon?.description,
              weatherIcon: maxIcon?.icon,
              temperature: avgT
            };
          });

        }
        return apiDataArray?.map(obj => {
          var wO = weatherObj?.find(ele => ele.hasOwnProperty(obj.timeLabel))?.[
            obj.timeLabel
          ];
          return {
            ...obj,
            maxTemp: wO?.tempMax,
            minTemp: wO?.tempMin,
            weather: wO?.description,
            weatherIcon: wO?.icon,
            temperature: wO?.temp,
          };
        });
      },
      addPercentage(selected, target) {
        var copyTarget = JSON.parse(JSON.stringify(target));
        var formula = (actual, tar) => {
          var countPer = (actual / tar) * 100;
          if (isNaN(countPer) || !Number.isFinite(countPer)) return 0;
          return countPer;
        };
        var per = copyTarget?.map((element) => {
          var find = selected?.find((ele) => ele.timeLabel == element.timeLabel);
          var clc = formula(find?.value, element?.value);
          element.value = clc;
          element.formattedValue = clc.toFixed(2) + "%";
          return element;
        });
        return per;
      },
      addWeather() {
        var selectedPeriod = this.addKeyWeather(this.selectedPeriod, true);
        var targetSelectedPeriod = this.addKeyWeather(this.targetSelectedPeriod, true);
        var comparedPeriod = this.addKeyWeather(this.comparedPeriod, false);
        var targetComparedPeriod = this.addKeyWeather(this.targetComparedPeriod, false);
        if (
          this.options.metricKey !=
          this.$store.state.user.translate.target_achievement_rate
        ) {
          this.$store.commit("trend/setSelectedPeriod", { selectedPeriod });
          this.$store.commit("trend/setTargetSelectedPeriod", {
            targetSelectedPeriod,
          });
          // compare
          this.$store.commit("trend/setComparedPeriod", { comparedPeriod });
          this.$store.commit("trend/setTargetComparedPeriod", {
            targetComparedPeriod,
          });
        } else {
          // for target sales
          var data = {
            selectedPeriod: this.sales?.selectedPeriod,
            comparedPeriod: this.sales?.comparedPeriod,
          };
          if (this.getPulseStartingPeriod === this.getPulseEndingPeriod) {
            var sum = this.sales?.selectedPeriod?.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.value;
            }, 0);
            //
            var sumC = this.sales?.comparedPeriod?.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.value;
            }, 0);
            data.selectedPeriod = [{ timeLabel: this.getPulseEndingPeriod, value: sum }];
            data.comparedPeriod = [{ timeLabel: this.getPulseEndingCompared, value: sumC }];
          }
          //  target achievedment parcentage graph
          var selectedPeriodActual = this.addKeyWeather(data.selectedPeriod, true);
          var comparedPeriodActual = this.addKeyWeather(data?.comparedPeriod, false);

          var selectedPer = this.addPercentage(
            selectedPeriodActual,
            targetSelectedPeriod
          );
          var selectedComPer = this.addPercentage(
            comparedPeriodActual,
            targetComparedPeriod
          );
          this.$store.commit("trend/setSelectedPeriod", {
            selectedPeriod: selectedPer,
          });
          this.$store.commit("trend/setComparedPeriod", {
            comparedPeriod: selectedComPer,
          });
        }
        this.furtherMethod();
      },
    };
  }, // data
  computed: {
    ...mapGetters("user", ["getLocale"]),
    ...mapGetters("filter", [
      "getStoreCodesList",
      "getDaysOfWeekList",
      "getCustomCalenderState",
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
    ]), // mapGetters
    ...mapState("trend", [
      "selectedPeriod",
      "targetSelectedPeriod",
      "targetComparedPeriod",
      "comparedPeriod",
      "apiCall",
      "sales",
    ]),
    ...mapState("weather", ["weather", "weatherCompare"]),
  }, // created
  watch: {
    getPulseStartingPeriod(newValue) {
      this.getTrendData();
    },
    apiCall: {
      handler(newValue, oldValue) {
        this.addWeather();
      },
    },
    weather: {
      handler(newValue, oldValue) {
        if (!this.selectedPeriod?.length) {
          const myInterval = setInterval(() => {
            if (this.selectedPeriod?.length) {
              this.addWeather();
              clearInterval(myInterval);
            }
          }, 3000);
        } else {
          this.addWeather();
        }
      },
      immediate: true,
    },
    weatherCompare: {
      handler(newValue, oldValue) {
        if (!this.comparedPeriod.length) {
          const myInterval = setInterval(() => {
            if (this.comparedPeriod.length) {
              this.addWeather();
              clearInterval(myInterval);
            }
          }, 3000);
        } else {
          this.addWeather();
        }
      },
      immediate: true,
    },
    storecode() {
      this.loadData();
    },
  }, // computed

  created() {
    this.getTrendData();
    // if(this.options.metricKey == this.$store.state.user.translate.target_achievement_rate){
    //    this.addWeather()
    // }else{
    //   this.loadData()
    // }
  },
  methods: {
    ...mapActions("trend", ["fetchTrend", "fetchTrendHour"]),
    async getTrendData() {
      this.chartOptions.chart.toolbar = {
        ...this.chartOptions.chart.toolbar,
        export: {
          svg: {
            filename: `${this.$t(`${this.options.metricKeyShort}`)} ${this.$t(
              "KpiAnalysis.selected"
            )}: ${this.getPulseStartingPeriod} ${this.getPulseEndingPeriod}  ${this.$t(
              "KpiAnalysis.compared"
            )}: ${this.getPulseStartingCompared} ${this.getPulseEndingCompared}`,
          },
          png: {
            filename: `${this.$t(`${this.options.metricKeyShort}`)} ${this.$t(
              "KpiAnalysis.selected"
            )}: ${this.getPulseStartingPeriod} ${this.getPulseEndingPeriod}  ${this.$t(
              "KpiAnalysis.compared"
            )}: ${this.getPulseStartingCompared} ${this.getPulseEndingCompared}`,
          },
          csv: {
            filename: `${this.$t(`${this.options.metricKeyShort}`)} ${this.$t(
              "KpiAnalysis.selected"
            )}: ${this.getPulseStartingPeriod} ${this.getPulseEndingPeriod}  ${this.$t(
              "KpiAnalysis.compared"
            )}: ${this.getPulseStartingCompared} ${this.getPulseEndingCompared}`,
          },
        }
      }
    this.loadData();
    this.addWeather();
    },
    furtherMethod() {
      let lang = this.$store.state.user.locale;
      let data = [
        {
          name: this.$t("selected_period"),
          data: [],
        },
        {
          name: this.$t("compared_period"),
          data: [],
        },
      ];

    let selectedPeriod = this.selectedPeriod;
      let comparedPeriod = this.comparedPeriod;
      if(this.selectedPeriod.length < this.comparedPeriod.length && this.getPulseStartingPeriod == this.getPulseEndingPeriod){
        let startIndex = this.selectedPeriod.length;
        let newArr = this.comparedPeriod.slice(startIndex, this.comparedPeriod.length);
        let newKeys = newArr.map(el => {return {timeLabel:el.timeLabel,value:0}})
        selectedPeriod = [...this.selectedPeriod, ...newKeys]
      }
      if(this.selectedPeriod.length > this.comparedPeriod.length &&  this.getPulseStartingCompared == this.getPulseEndingCompared){
        let startIndex = this.comparedPeriod.length;
        let newArr = this.selectedPeriod.slice(startIndex, this.selectedPeriod.length);
        let newKeys = newArr.map(el => {return {timeLabel:el.timeLabel,value:0}})
        comparedPeriod = [...this.comparedPeriod, ...newKeys]
      }
  
      const selectedData = selectedPeriod.map((singlePoint) => {
        return {
          x:
            this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
                : this.options.metricKey === "tra"
                ? singlePoint.timeLabel
                : singlePoint.timeLabel + ":00"
              : singlePoint.timeLabel,
          y: singlePoint.value ? singlePoint.value?.toFixed(2) : 0,
          z: {
            weather: singlePoint && singlePoint.weather,
            weatherIcon: singlePoint && singlePoint.weatherIcon,
            maxTemp: singlePoint && singlePoint.maxTemp?.toFixed(2),
            minTemp: singlePoint && singlePoint.minTemp?.toFixed(2),
            popUpdate:this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
               :this.options.metricKey === "tra" ?moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`: singlePoint.timeLabel + ":00"
              :  moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`,
          },
          formattedValue: singlePoint && singlePoint.formattedValue,
        };
      });

      const comparedData = comparedPeriod.map((singlePoint) => {
        return {
          x:
            this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
                : this.options.metricKey === "tra"
                ? singlePoint.timeLabel
                : singlePoint.timeLabel + ":00"
              : singlePoint.timeLabel,
          y: singlePoint.value ? singlePoint.value?.toFixed(2) : 0,
          z: {
            weather: singlePoint && singlePoint.weather,
            weatherIcon: singlePoint && singlePoint.weatherIcon,
            maxTemp: singlePoint && singlePoint.maxTemp?.toFixed(2),
            minTemp: singlePoint && singlePoint.minTemp?.toFixed(2),
            popUpdate:this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
                :this.options.metricKey === "tra" ?moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`: singlePoint.timeLabel + ":00"
              :  moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`,
          },
          formattedValue: singlePoint && singlePoint.formattedValue,
        };
      });
      data[0].data = [...selectedData];
      data[1].data = [...comparedData];
      // new target sales
      let targetEnabledKpi = ["sls"]
      if (targetEnabledKpi.indexOf(this.options.metricKey) > -1) {
        data = [
          ...data,
          {
            name: this.$store.state.user.translate.sales_target_selected_period,
            data: [],
          },
          {
            name: this.$store.state.user.translate.sales_target_compared_period,
            data: [],
          },
        ];
        const targetPData = selectedPeriod.map((singlePoint) => {
          return {
            x:
              this.getPulseStartingPeriod === this.getPulseEndingPeriod
                ? singlePoint.date
                  ? singlePoint.timeLabel
                  : this.options.metricKey ===  "tra"
                  ? singlePoint.timeLabel
                  : singlePoint.timeLabel + ":00"
                : singlePoint.timeLabel,
            y: singlePoint.st ? parseInt(singlePoint.st) : 0,
            z: {
              weather: singlePoint && singlePoint.weather,
              weatherIcon: singlePoint && singlePoint.weatherIcon,
              maxTemp: singlePoint && singlePoint.maxTemp?.toFixed(2),
              minTemp: singlePoint && singlePoint.minTemp?.toFixed(2),
              popUpdate:this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
                :this.options.metricKey === "tra" ?moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`: singlePoint.timeLabel + ":00"
              :  moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`,
            },
            formattedValue: singlePoint && '¥'+parseInt(singlePoint.st).toLocaleString(),
          };
        });

        const targetCData = comparedPeriod.map((singlePoint) => {
          return {
            x:
              this.getPulseStartingPeriod === this.getPulseEndingPeriod
                ? singlePoint.date
                  ? singlePoint.timeLabel
                  : this.options.metricKey ===
                    this.$store.state.user.translate.target_achievement_rate
                  ? singlePoint.timeLabel
                  : singlePoint.timeLabel + ":00"
                :singlePoint.timeLabel,
            y: singlePoint.st ? parseInt(singlePoint.st)?.toFixed(2) : 0,
            z: {
              weather: singlePoint && singlePoint.weather,
              weatherIcon: singlePoint && singlePoint.weatherIcon,
              maxTemp: singlePoint && singlePoint.maxTemp?.toFixed(2),
              minTemp: singlePoint && singlePoint.minTemp?.toFixed(2),
              popUpdate:this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? singlePoint.date
                ? singlePoint.timeLabel
                :this.options.metricKey === "tra" ?moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`: singlePoint.timeLabel + ":00"
              :  moment(singlePoint.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(singlePoint.timeLabel)})`,
            },
            formattedValue: singlePoint && '¥'+parseInt(singlePoint.st).toLocaleString(),
          };
        });
        data[2].data = [...targetPData];
        data[3].data = [...targetCData];
      }

      this.series = [...data];
      setTimeout(() => {
        this.$refs.apex?.hideSeries(
          this.$store.state.user?.translate.sales_target_selected_period
        );
        this.$refs.apex?.hideSeries(
          this.$store.state.user?.translate.sales_target_compared_period
        );
        if (this.getPulseStartingPeriod === this.getPulseEndingPeriod) {
          this.tooltipTarget = false;
          const collection = document.getElementsByClassName("apexcharts-legend");
          const nestedObj = collection[0].childNodes[2];
          const nestedObj1 = collection[0].childNodes[3];
          nestedObj.style.pointerEvents = "none";
          nestedObj1.style.pointerEvents = "none";
        }
      }, 100);
      let dateLocale = () => {
        this.series[0].data.forEach(d => {
          d.x = moment(d.x).locale(lang).format('MMM DD');
        });
      }
      if (this.getPulseStartingPeriod !== this.getPulseEndingPeriod) {
        const days = this.getDaysOfWeekList.split(",");
        this.series[0].data = this.series[0].data.filter((d) => {
          return days.some((day) => {
            return day.includes(moment(d.x.slice(0, 10)).format("dddd").toLowerCase());
          });
        });
        dateLocale()
      }
      if (this.getPulseStartingPeriod === this.getPulseEndingPeriod && this.options.metricKey === 'tra') {
        dateLocale()
      }
    },

    recalcModifiedDate() {

      const loginTenentID = localStorage.getItem('tenentID');

      const selectedstorecode =
        this.storecode !== '' ? this.storecode : this.getStoreCodesList;
      return this.getDateByTenetAndStore(
        JSON.parse(this.modifiedDate),
        loginTenentID,
        selectedstorecode,
        this.getLocale,
        this.options.metricKey
      );
    },
    getDateByTenetAndStore(
      jsonData,
      targetTenetID,
      storeCodeList,
      lang,
      key
    ) {
      let selectedDate = null;

      if (jsonData[key] && jsonData[key][targetTenetID]) {
        for (const code in jsonData[key][targetTenetID]) {
          if (storeCodeList.includes(code)) {
            const currentDate = new Date(jsonData[key][targetTenetID][code]);
            if (!selectedDate || currentDate > selectedDate) {
              selectedDate = currentDate;
            }
          }
        }
      }
      if (selectedDate) {
        const formattedDate = selectedDate.toISOString().slice(0, 19);
        const date = new Date(formattedDate);
        var language = lang;

        if (language == 'ja') {
          language = 'ja-JP';
        } else {
          language = 'en-US';
        }

        const options = {
          month: 'numeric',
          day: 'numeric',
          weekday: 'short',
          hour: '2-digit',
          minute: 'numeric',
          hour12: false,
          locale: language // Japanese locale
        };

        const formatter = new Intl.DateTimeFormat(language, options);
        const formattedDate2 = formatter.format(date);

        return formattedDate2;
      }

      return null;
    },
    async loadData() {
        let savedTrend = null;
        let currkey = this.options.metricKey === this.$store.state.user.translate.target_achievement_rate ? 'tra' : this.options.metricKey;
        let findK = currkey;
        Object.keys(this.allTrends).forEach((k) => {
          if(k.toLowerCase() == findK) {
            savedTrend = JSON.parse(JSON.stringify(this.allTrends[k]))
          }
        })
        this.lastTimStamp = this.recalcModifiedDate()
        console.log(currkey, JSON.parse(JSON.stringify(this.allTrends)))
        console.log(currkey, JSON.parse(JSON.stringify(savedTrend)))
        if(savedTrend) {
          resetTrendValue(savedTrend.data, currkey)
          this.$store.commit('trend/setComparedPeriod', { comparedPeriod: savedTrend.data.comparedPeriod });
          this.$store.commit('trend/setSelectedPeriod', { selectedPeriod: savedTrend.data.selectedPeriod });
          this.$store.commit('trend/setSales', {selectedPeriod: savedTrend.data.selectedPeriod,comparedPeriod: savedTrend.data.comparedPeriod})
        }
        else {
          if (this.options.metricKey) {
          try {
            this.loading = true;
            let params = {
              startDate: this.getPulseStartingPeriod,
              endDate: this.getPulseEndingPeriod,
              compareStartDate: this.getPulseStartingCompared,
              compareEndDate: this.getPulseEndingCompared,
              storeCodes:
                this.storecode !== '' ? this.storecode : this.getStoreCodesList,
              daysOfWeek: this.getDaysOfWeekList,
              metricKey:
                this.options.metricKey === this.$store.state.user.translate.target_achievement_rate
                  ? 'sls'
                  : this.options.metricKey,
              useCustomCalender: this.getCustomCalenderState
            };
            this.getPulseStartingPeriod === this.getPulseEndingPeriod
              ? await this.fetchTrendHour(params)
              : await this.fetchTrend(params);
            //
          } catch (error) {
            this.$q.notify({
              type: 'negative',
              position: 'top',
              message: `${error}`
            });
          } finally {
            this.loading = false;
          } // finally
        }
        }

        setTimeout(this.furtherMethod, 50);

    },
    getUnits() {
      if (val === this.$t('sls')) {
        return '円';
      }
      if (val === this.$t('trf')) {
        return '人';
      }
      if (val === this.$t('trn')) {
        return '人';
      }
      if (val === this.$t('cn')) {
        return '%';
      }
      if (val === this.$t("averagesale")) {
        return "円";
      }
      if (val === this.$t('ap')) {
        return '円';
      }
      if (val === this.$t("upt")) {
        return "点";
      }
      if (val === this.$t("rov")) {
        return "円";
      }
      if (val === this.$t('aip')) {
        return '円';
      }
      if (val === this.$t('spt')) {
        return '円';
      }
      if (val === this.$t('tpt')) {
        return '人';
      }
      if (val === this.$t('sl')) {
        return '人';
      }
      if (val === this.$t('sh')) {
        return '人';
      }
      if (val === this.$t('cs')) {
        return '人';
      }
      if (val === this.$t('se')) {
        return '円';
      }
      if (val === this.$t('pb')) {
        return '人';
      }
      if (val === this.$t('cr')) {
        return '%';
      }
      // if (val === this.$t('facadestops')) {
      //   return '人';
      // }
      if (val === this.$t('dw')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t("repeatrate")) {
        return "-";
      }
      if (val === this.$t("engagementrate")) {
        return "％";
      }
      if (val === this.$t("bouncerate")) {
        return "％";
      }
      if (val === this.$t("gendercomparison")) {
        return "％";
      }
      if (val === this.$t("age")) {
        return "％";
      }
      if (val === this.$t("fittingroomvisits")) {
        return "人";
      }
      if (val === this.$t("fittingroom %")) {
        return "％";
      }
      if (val === this.$t("fittingroomconversion %")) {
        return "％";
      }
      if (val === this.$t("fittingroomdwelltime")) {
        return "x 時間 y分 z秒";
      }
      if (val === this.$t("areapassersby")) {
        return "人";
      }
      if (val === this.$t("areastops")) {
        return "人";
      }
    },
    fromateMethod(dateString) {
      var date = dateString.split("-");
      return `${date[1]}/${date[2]}/${date[0]}`;
    },
    isToday(date) {
      const today = new Date();
      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        return true;
      }

      return false;
    },

    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t("Sun");
      }
      if (dayVal === 1) {
        return this.$t("Mon");
      }
      if (dayVal === 2) {
        return this.$t("Tue");
      }
      if (dayVal === 3) {
        return this.$t("Wed");
      }
      if (dayVal === 4) {
        return this.$t("Thu");
      }
      if (dayVal === 5) {
        return this.$t("Fri");
      }
      if (dayVal === 6) {
        return this.$t("Sat");
      }
    },
  }, // methods
}; // export default
function getDateByTenetAndStore(jsonData, targetTenetID, storeCodeList, lang, key) {
  let selectedDate = null;

  if (jsonData[key] && jsonData[key][targetTenetID]) {
    for (const code in jsonData[key][targetTenetID]) {
      if (storeCodeList.includes(code)) {
        const currentDate = new Date(jsonData[key][targetTenetID][code]);
        if (!selectedDate || currentDate > selectedDate) {
          selectedDate = currentDate;
        }
      }
    }
  }

  if (selectedDate) {
    const formattedDate = selectedDate.toISOString().slice(0, 19);
    const date = new Date(formattedDate);
    var language = lang;

    if (language == "ja") {
      language = "ja-JP";
    } else {
      language = "en-US";
    }

    const options = {
      month: "numeric",
      day: "numeric",
      weekday: "short",
      hour: "2-digit",
      minute: "numeric",
      hour12: false,
      locale: language, // Japanese locale
    };

    const formatter = new Intl.DateTimeFormat(language, options);
    const formattedDate2 = formatter.format(date);

    return formattedDate2;
  }

  return null;
}
