import { PostRequest } from '@/util/http';
import DashboardManager from '@/api/apiDataManagers/dashboardManager.js';
export interface OverviewParams {
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  storeCodes: string;
  daysOfWeek: string;
  kpis: string;
  groupByMetricKey: boolean;
  comparisonYKPIMetricKey ?: string;
  status ?: string;
}

export function APIFetchOverview(params: OverviewParams) {
  if(params.status && params.status == 'kpitree')
    return DashboardManager.fetchAllDetailForKpiTree(params)
  else
  return DashboardManager.fetchAllDetail(params)

  // return PostRequest('kpis', params);
}


export function APIFetchDetailOverview(params: OverviewParams) {
  return PostRequest('details/kpis', params);
}
