import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@/models/Pagination';
import { Region } from '@/models/Location';
import { createBackendError } from '@/util/error';

export type GetRegionsResponse = PaginatedResponse<Region>;

export default class RegionController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getRegions() {
    try {
      const { data } = await this.client.get('/region', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 100,
          paged: false
        }
      });
      return data as GetRegionsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getRegionsByCountry(countryId) {
    try {
      const { data } = await this.client.get('/region', {
        params: {
          query: `countryId:${countryId}`
        }
      });
      return data as GetRegionsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
