
import { ref, reactive } from '@vue/composition-api';

export default {
  data() {
    return {
      numberOfEmployee: '',
      attendanceStaff: '',
      productTrend: '',
      storeStatus: '',
      inhousesituation: '',
      currentUser: ''
    };
  },
  setup() {
    let test = ref('this is test');
    let numberOfEmployee = ref(0);
    let attendanceStaff = ref(0);
    const changeTestVar = val => {
      test.value = val;
    };
    return { test, changeTestVar };
  }
};
