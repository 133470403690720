
import { createColumn } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    data: {
      type: Array,
      required: true
    },
    noAction: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: []
    };
  },
  created() {
    const columns = [
      createColumn('name', this.$t('UploadFloorPlan.StoreName')),
      createColumn(
        'storeFloorPlan.dimension',
        this.$t('UploadFloorPlan.Dimensions'),
        {
          field: row => row.storeFloorPlan.dimension
        }
      ),
      createColumn(
        'storeFloorPlan.ceilingHeight',
        this.$t('UploadFloorPlan.CeilingHeight'),
        {
          field: row => row.storeFloorPlan.ceilingHeight
        }
      ),
      createColumn(
        'storeFloorPlan.numberOfEntrances',
        this.$t('UploadFloorPlan.NumberOfEntrances'),
        {
          field: row => row.storeFloorPlan.numberOfEntrances
        }
      ),
      createColumn(
        'storeFloorPlanImages',
        this.$t('UploadFloorPlan.FloorPlan'),
        {
          format: images => {
            const length = (images || []).length;
            if (length === 0) return 'No image';
            return `${length} image${length > 1 ? 's' : ''}`;
          }
        }
      ),
      createColumn('action', this.$t('UploadFloorPlan.Action'))
    ];
    this.columns = columns;
  }
};
