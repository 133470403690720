
import store from '@/store'
import { mapGetters, mapActions, mapState } from 'vuex';
import users from "@/services/users.js";
import stores2 from '@/services/stores';
export default {
  data() {
    return {
      model: true,
      step: 1,
      selected: null,
      isStoreMulti: true,
      survey: [],
      stores: [],
      stringOptions: [
      ],
      options: [],
      firstName: "",
      storeInfo: []
    };
  },
  
  computed: {
    ...mapGetters('store', ['getAllStoreInfo']),
    ...mapState('store', { storeStores: 'stores' }),
    ...mapGetters('user', ['getLocale']),
    getSurvey() {
      return [
        { label: this.$store.state.user.translateWhole.survey_option1[this?.getLocale], value: "Cashier, Sales, VMD etc", needStore: true },
        {
          label: this.$store.state.user.translateWhole.survey_option2[this?.getLocale],
          value: "Store Manager or Assistant Store Manager",
          needStore: true
        },
        { label: this.$store.state.user.translateWhole.survey_option3[this?.getLocale], value: "Area Manager", needStore: true },
        { label: this.$store.state.user.translateWhole.survey_option4[this?.getLocale], value: "Head Office - Marketing" , needStore: false},
        { label: this.$store.state.user.translateWhole.survey_option5[this?.getLocale], value: "Head Office - Sales" , needStore: false },
        {
          label: this.$store.state.user.translateWhole.survey_option6[this?.getLocale],
          value: "Head Office - IT or Operations (Admin)" , needStore: false
        },
        { label: this.$store.state.user.translateWhole.survey_option7[this?.getLocale], value: "Head Office - Other" , needStore: false },
      ]
    }
  },
  watch: {
    async storeStores() {
      console.log('getAllStoreInfo', this.storeStores)
    },
    getLocale() {
      console.log("mounted", this.$store.state.user.translateWhole.survey_option1[this?.getLocale])
      
    }
  },
  async mounted () {
    
    this.firstName = store.getters['user/getProfile'].firstName
    
  },
  methods: {
    selectedProfile(o) {
      console.log(o)
      this.selected = o.value;
      this.needStore = o.needStore;
      this.isStoreMulti = o.value == "Area Manager"
    },
    goBack() {
      if(this.step == 3) {
        this.goTo(this.needStore ? 2 : 1)
      } else {
        this.goTo(1)
      }
    },
    goTo(val) {
      console.log(this.storeStores)
      if( this.needStore) {
        if(val == 2) {
          this.stringOptions = store.getters['user/getStoresCodeNameList'].map((a) => {
            return {
              label: `${a.storeCode} ${a.name}`,
              value: a.storeCode,
              storeName: a.name,
              storeCode: a.storeCode,
            }
          })
          if(this.isStoreMulti) {
            this.stores = [];
            this.stringOptions.forEach((st) => {
              let cSt = this.storeStores.myStores.filter((a) => {
                console.log(a.storeNumber, st.storeCode)
                return a.storeNumber == st.storeCode;
              })
              if(cSt.length) {
                cSt = cSt[0]
                let cStItem = {
                    label: `${cSt.storeNumber} ${cSt.title}`,
                    value: cSt.storeNumber,
                    storeName: cSt.title,
                    storeCode: cSt.storeNumber,
                  };
                this.stores.push(cStItem)
              }
            })
          } else {
            this.stores = [];
            this.stringOptions.forEach((st) => {
              let cSt = this.storeStores.myStores.filter((a) => {
                let f = this.stringOptions.filter((b) => {
                  return a.storeNumber == b.storeCode
                })
                return f.length > 0
              })
              if(cSt.length) {
                cSt = cSt[0]
                let cStItem = {
                    label: `${cSt.storeNumber} ${cSt.title}`,
                    value: cSt.storeNumber,
                    storeName: cSt.title,
                    storeCode: cSt.storeNumber,
                  };
                this.stores = cStItem
              }
            })
          }
        }
        if(val == 3) {
          let { profile } = store.state["user"];
          let locations = [];
          if(this.isStoreMulti) {
            locations = [{
              type: "dataaccess",
              storeCodes: this.storeStores.dataAccessStores.map((st) => st.storeNumber)
            }]
          } else {
            locations = [{
              type: "mystores",
              storeCodes: [this.stores.storeCode],
            }]
          }
        let UsrDetails = {
          userId: profile._id,
          locations
        };
        users
          .setUserLocations(UsrDetails)
          .then((response) => {
          })
          .catch((error) => {
            // console.log(error);
            this.$q.notify({
              message: error,
              color: "red",
            });
          });
        }
        if(this.isStoreMulti) {
          if(!Array.isArray(this.stores)) {
            this.stores = [this.stores]
          }
        } else {

          if(Array.isArray(this.stores)) {
            this.stores = this.stores[0]
          }
        }
      } else {
        if(val == 2) {
          val = 3
        }
      }
      this.step = val;
    },
    createValue(val, done) {
      if (val.length > 0) {
        if (!this.stringOptions.includes(val)) {
          this.stringOptions.push(val);
        }
        console.log(val);
        done(val, "toggle");
      }
    },
    // filterFn(val, update, abort) {
    //   update(() => {
    //     const needle = val.toLowerCase();
    //     this.options.value = this.stringOptions.filter((v) => v.toLowerCase().indexOf(needle) > -1);
    //   });
    // },
    filterFn(val, update) {
      update(() => {
        if (val === "") {
          this.options = this.stringOptions;
        } else {
          const needle = val.toLowerCase();
          this.options = this.stringOptions.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        }
      });
    },
  },
};
