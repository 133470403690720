
import moment from 'moment';

export default {
  props: {
    staffInfo: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  methods: {
    getTime(val) {
      return moment(new Date(val)).format('LT');
    }
  }
};
