
import CommonMixin from '@/mixins/CommonMixin';
import DrawerMixin from '@/components/Common/Mixins/DrawerMixin';
import Order from '@/models/Order';
import each from 'lodash/each';

export default {
  mixins: [CommonMixin, DrawerMixin],
  props: {
    orders: { type: Array, required: true }
  },
  computed: {
    storeAdded(): boolean {
      let valid = false;
      each(this.orders, order => {
        each(order.storeOrders, store => {
          if (store.quantity > 0) {
            valid = true;
            return false;
          }
        });
        return !valid;
      });
      return valid;
    }
  },
  methods: {
    addStore(order: Order, index: number) {
      this.$emit('add-store', { order, index });
    },
    updateOrder(order: Order, index: number) {
      this.$emit('update-order', { order, index });
    },
    removeOrder(order: Order, index: number) {
      this.$emit('remove-order', { order, index });
    }
  }
};
