
import SensorConfigurationModal from './SensorConfigurationModal.vue';
import hiddenModel from './hiddenModel.vue';
import stores from '@/services/stores';
import Vue from 'vue';
import store from '@/store/';

export default {
  name: 'Sensor',
  components: { SensorConfigurationModal, hiddenModel },
  // eslint-disable-next-line
  props: ['allStores'],
  data() {
    return {
      // hidden
      hiddenCount: 0,
      slave: 0,
      hiddenShow: false,
      // hidden
      exportBtn: false,
      allExport: false,
      selectedSensor: [],
      openModel: false,
      allStoresData: [],
      breadCrumb: '',
      visible: false,
      loading: false,
      columns: [
        {
          name: 'entranceName',
          align: 'left',
          label: this.$store.state.user.translate.entrance_name,
          field: 'Entrance Name',
          format: val => `${val}`,
          sortable: true
        },
        {
          name: 'installPosition',
          align: 'left',
          label: this.$store.state.user.translate.install_position,
          field: 'Install Position',
//        format: val => `${val}`,
          format: val => val == null ? '' : `${val}`, // Check if val is null or undefined
          sortable: true
        },
        
        {
          name: 'macAddress',
          label: this.$store.state.user.translate.MAC_address,
          field: 'MAC Address'
        },
        {
          name: 'sensorNumber',
          align: 'left',
          label: this.$store.state.user.translate.device_sn,
          field: 'Sensor No.',
          sortable: true
        },
        {
          name: 'type',
          label: this.$store.state.user.translate.type,
          field: 'Type'
        },
        {
          name: 'brand',
          label: this.$store.state.user.translate.brand,
          field: 'Brand'
        },
        {
          name: 'status',
          label: this.$store.state.user.translate.device_status,
          field: 'Status',
          align: 'center'
        },

        {
          name: 'actions',
          label: this.$store.state.user.translate.actions,
          field: 'action'
        }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10
      },
      selected: [],
      filter: '',
      confirm: false,
      selectedStore: [],
      countryOptions: [],
      allRegions: [],
      allCities: [],
      timer: null
    };
  },
  computed: {
    adminCheck() {
      const { botAdmin, email } = this.$store.state.user.profile;
      return email == 'demo@flow-solutions.com' || email.startsWith('botadmin@') ? true : botAdmin;
    },
    pagesNumber() {
      return Math.ceil(this.allStoresData.length / this.pagination.rowsPerPage);
    }
  },
  watch: {
    allStores(newValue) {
      this.loading = true;
      this.allStoresData = newValue?.find(
        item => item._id == this.$route.query.id
      )?.devices;
      this.loading = false;
      this.breadCrumb = newValue?.find(
        item => item._id == this.$route.query.id
      )?.title;
    },
    allExport(newValue) {
      if (newValue) {
        this.selectedSensor = this.allStoresData.map(item => item.id);
      } else {
        this.selectedSensor = [];
      }
    }
  },
  methods: {
    estimateModel(store) {
      this.$refs.model.resetState();
      this.$refs.model.configuration = true;
      this.selectedStore = store;
    },
   hiddenMethod() {
      this.hiddenCount++;
      if (this.hiddenCount > 6) {
        this.hiddenShow = true;
        this.hiddenCount = 0;
        this.$q.notify({
          message: 'Enabled Successfully',
          color: 'green'
        });
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.hiddenCount = 0
      }, 2000); 
    },
    sendTrackingEvent(type) {
      let { profile } = store.state['user'];
      data.email = profile?.email;
      Vue.prototype?.$mixpanel?.track(
        'webapp_sensor_' + type,
        this.selectedSensor
      );
    },
    exportMethod() {
      if (this.columns[0].name !== 'export') {
        this.columns.unshift({
          name: 'export',
          align: 'left',
          label: 'Export',
          field: 'Export',
          format: val => `${val}`,
          sortable: true
        });
      }
      this.selectedSensor = [];
      this.exportBtn = !this.exportBtn;
      this.allExport = false;
      if (!this.exportBtn) {
        this.columns = this.columns.filter(el => el.name !== 'export');
      }
    },
    downloadCSV() {
      const filterStore = this.allStoresData.filter(item =>
        this.selectedSensor.includes(item.id)
      );
      const csv = this.convertToCSV(filterStore);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.$store.state.user.translate.sensor + '.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      if (this.columns[0].name === 'export') {
        this.columns = this.columns.filter(el => el.name !== 'export');
      }
      this.exportBtn = false;
      this.allExport = false;
      this.selectedSensor = [];
    },
    convertToCSV(objArray) {
      const translate = this.$store.state.user.translate;
      const csvFileObj = [
        { title: translate.store_name, key: 'storeName' },
        { title: translate.store_number, key: 'storeCode' },
        { title: translate.device_brand, key: 'brand' },
        { title: translate.entrance_name, key: 'entranceName' },
        { title: translate.type, key: 'isConnected' },
        { title: translate.sensor_feature, key: 'features' },
        { title: translate.default_traffic_flow, key: 'defaultTrafficFlow' },
        { title: translate.device_status, key: 'isEnabled' },
        { title: translate.part_number, key: 'partNumber' },
        { title: translate.MAC_address, key: 'macAddress' },
        { title: translate.device_sn, key: 'serialNumber' },
        { title: translate.firmware, key: 'firmwareVersion' },
        { title: translate.IP_address, key: 'ipAddress' },
        { title: translate.subnet_mask, key: 'subnetMask' },
        { title: translate.default_gateway, key: 'defaultGateway' },
        { title: translate.PoE_port, key: 'poePort' },
        { title: translate.dns, key: 'dns' },
        { title: translate.install_position, key: 'installPosition' },
        { title: translate.pathway_depth, key: 'pathwayDepth' },
        { title: translate.detection_length, key: 'detectionLength' },
        { title: translate.zone_location, key: 'location' },
        { title: translate.entrance_number, key: 'entranceId' }
      ];

      const array = JSON.parse(JSON.stringify([...objArray]));
      let csv = '';
      // Header
      csv += csvFileObj.map(v => v.title) + '\n';
      // Rows
      let store = this.allStores?.find(
        item => item._id == this.$route.query.id
      );
      array.forEach(obj => {
        let addfullNames = obj['features']?.map(item =>
          item === 'pb'
            ? 'Passersby'
            : item === 'dm'
            ? 'Demographics'
            : item === 'dw'
            ? 'Dwelltime'
            : item.charAt(0).toUpperCase() + item.slice(1)
        );
        // add store name and code
        obj['storeName'] = store?.title || '';
        obj['storeCode'] = store?.storeNumber || '';
        obj['features'] = `"${addfullNames || ''}"`;
        obj['brand'] = `"${obj['brand']?.charAt(0).toUpperCase() +
          obj['brand']?.slice(1) || ''}"`;
        obj['defaultTrafficFlow'] = `"${obj['defaultTrafficFlow']
          ?.charAt(0)
          .toUpperCase() + obj['defaultTrafficFlow']?.slice(1) || ''}"`;
        // add 0,1 base titles
        obj['isConnected'] =
          obj['isConnected'] === undefined
            ? ''
            : obj['isConnected'] === 0
            ? translate.slave
            : translate.master;
        obj['isEnabled'] =
          obj['isEnabled'] === undefined
            ? ''
            : obj['isEnabled'] === 0
            ? translate.disable
            : translate.enable;
        obj['entranceName'] = obj['entranceName']
          ? '="' + obj['entranceName'] + '"'
          : ''; // for csv string
        obj['serialNumber'] = obj['serialNumber']
          ? '="' + obj['serialNumber'] + '"'
          : ''; // for csv string
        obj['entranceId'] = obj['entranceId']
          ? '="' + obj['entranceId'] + '"'
          : ''; // for csv string
        obj['installPosition'] = obj['installPosition']
          ? '="' + obj['installPosition'] + '"'
          : ''; // for csv string
        obj['location'] = obj['location'] ? '="' + obj['location'] + '"' : ''; // for csv string
        //
        if (!obj['features']?.includes('Traffic')) {
          obj['entranceId'] = '';
        }
        csv += csvFileObj.map(v => obj[v.key] || ' ') + '\n';
      });
      return csv;
    },
    goBack() {
      this.$router.go(-1);
    },
    closeModel() {
      this.openModel = false;
    },
    saveUserDetails() {
      let findOtherSensors = this.allStoresData?.filter(
        item => item.id != this.selectedStore.id
      );
      let device = {
        storeId: this.$route.query.id,
        deviceInfo: [...findOtherSensors]
      };
      // this.loading = true;
      stores
        .updateSensor(device)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.removed_successfully,
            color: 'green'
          });
          this.getAllStores();
          // this.loading = false;
          this.sendTrackingEvent('Update');
          this.$emit('updateStore');
        })
        .catch(error => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
          // this.loading = false;
          this.$emit('closeModel', false);
        });
      // }
    },
    getAllStores() {
      this.visible = true;
      this.loading = true;
      stores
        .getStores()
        .then(response => {
          this.allStoresData = response.data.response.stores.reverse();
          this.allStoresData = this.allStoresData?.find(
            item => item._id == this.$route.query.id
          )?.devices;
          
          // this.breadCrumb = this.allStoresData?.find(
          //   (item) => item._id == this.$route.query.id
          // )?.title;
          this.visible = false;
          this.loading = false;
        })
        .catch(error => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: 'red'
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: 'red'
            });
          }
        });
    },
    addNewStoreDialog() {
      this.openModel = true;
    },

    editStoreDialog(store, editBtns) {
      store.btnsShow = editBtns ? 'edit' : 'view';
      this.selectedStore = store;
      this.openModel = true;
    },
    async updateStore() {
      this.getAllStores();
      this.openModel = false;
    },

    openStoreModel() {
      this.selectedStore = { btnsShow: '' };
      this.openModel = true;
    },

    deleteStore(store) {
      this.selectedStore = store;
      this.confirm = true;
    },
    confirmDeleteStore() {
      this.saveUserDetails();
      this.confirm = false;
    }
  }
};
