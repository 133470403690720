import moment from 'moment';
import store from '../store';
import { getDefaultDate, getStartOfWeek } from '@/util/defaultDate';
import { initialDefaultSettings } from '@/models/onboarding/DefaultSettings';
import retailcalendar from 'retail-calendar';

export const setUsersParamsValues = async () => {
  const storedUserId = localStorage.getItem('userID');
  // if (storedUserId !== store.state['user']?._ID) {
  // localStorage.removeItem('storeCodes');
  // localStorage.removeItem('selectedTrafficKPI');
  // localStorage.removeItem('selectedTabularKPI');
  // localStorage.removeItem('selectedComparisonKPI');
  // localStorage.removeItem('daysOfWeek');
  // }
  localStorage.setItem('userID', store.state['user']?._ID);
  let stores = localStorage.getItem('storeCodes');
  if (stores !== null) {
    store.commit('filter/setStoreCodes', {
      storeCodes: localStorage.getItem('storeCodes').split(',')
    });
  } else {
    store.commit('filter/setStoreCodes', {
      storeCodes: store.state['user']?.profile?.stores?.split(',')
    });
    if (storedUserId !== store.state['user']?._ID) {
      localStorage.setItem('storeCodes', store.state['user']?.stores);
      //localStorage.setItem('storeCodes', 'all');
    } else {
      localStorage.setItem('storeCodes', store.state['user']?.stores.split(','));
      // localStorage.setItem('storeCodes', store.state['user']?.profile?.stores);
    }
  }
  let daysOfWeek = localStorage.getItem('daysOfWeek');
  if (daysOfWeek !== null) {
    store.commit('filter/setDaysOfWeek', {
      daysOfWeek: localStorage.getItem('daysOfWeek').split(',')
    });
  }
  const defaultSettings = store.state['user']?.defaultSettings;

  const defaultDates = getDefaultDate(
    defaultSettings.storeSelectPeriods,
    defaultSettings.storeComparePeriods
  );
  
  store.commit(
    'user/setStartOfWeek',
    getStartOfWeek(defaultSettings.weekStartDay)
  );

  store.commit('filter/setStartingPeriod', {
    startingPeriod:
      defaultDates.startingPeriod ?? moment(new Date()).format('YYYY-MM-DD')
  });
  store.commit('filter/setEndingPeriod', {
    endingPeriod:
      defaultDates.endingPeriod ?? moment(new Date()).format('YYYY-MM-DD')
  });
  store.commit('filter/setStartingCompared', {
    startingCompared:
      defaultDates.startingCompared ?? moment(new Date()).format('YYYY-MM-DD')
  });
  store.commit('filter/setEndingCompared', {
    endingCompared:
      defaultDates.endingCompared ?? moment(new Date()).format('YYYY-MM-DD')
  });
  const retailCalendar = await store.dispatch('filter/fetchRetailCalendar');
  store.commit('filter/setRetailCalendar', {
    dates: retailCalendar
    // dates: store.state['user']?.profile?.custom445Calendar
    // ? store.state['user']?.profile?.custom445Calendar
    // : {}
  });
  store.commit('filter/setCustomCalenderFlag', {
    value: store.state['user']?.defaultDateFilter?.useCustomCalender
  });
  localStorage.setItem(
    'startingPeriod',
    defaultDates.startingPeriod ?? moment(new Date()).format('YYYY-MM-DD')
  );
  localStorage.setItem(
    'endingPeriod',
    defaultDates.endingPeriod ?? moment(new Date()).format('YYYY-MM-DD')
  );
  localStorage.setItem(
    'startingCompared',
    defaultDates.startingCompared ??
    moment(new Date())
      .add(-1, 'days')
      .format('YYYY-MM-DD')
  );
  localStorage.setItem(
    'endingCompared',
    defaultDates.endingCompared ??
    moment(new Date())
      .add(-1, 'days')
      .format('YYYY-MM-DD')
  );
  localStorage.setItem(
    'useCustomCalender',
    store.state['user']?.defaultDateFilter?.useCustomCalendar || false
  );
};
