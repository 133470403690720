export const partners = [
  {
    id: '1',
    name: 'Quividi',
    nameJP: 'Quividi',
    hwMakerJP: '',
    tags: ['属性', 'アプリケーション'],
    swMaker: 'Quividi',
    link: 'https://quividi.com/',
    support: '',
    swMakerJP: '',
    icon: 'quividi',
    catchCopy: 'Quividi',
    catchCopyJP: 'Quividi',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['quividiScrn1'],
    overviewJP:
      'Quividiで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: [
      {
        title: '',
        description:
          '性別、年代データを取得することが可能です。お店づくりのコンセプトデザイン、VMDデザイン、売場づくりの改善に役立てることが可能です。'
      }
    ],
    descriptionJP: ''
  },
  {
    id: '2',
    name: 'Think/vmd 3D simulator',
    nameJP: 'Think/vmd 3D simulator',
    hwMakerJP: '',
    tags: ['VMD', 'アプリケーション'],
    swMaker: 'I APARTMENT LLC',
    link: 'https://think-vmd.com/contact',
    support: '',
    swMakerJP: '',
    icon: 'think',
    catchCopy: '小売向け売場空間 3Dシミュレーションシステム',
    catchCopyJP: '小売向け売場空間 3Dシミュレーションシステム',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['thinkScrn1'],
    overviewJP:
      'ink!vmd は、VMD・空間演出のためのプランニング・コミュニケーションツールです。' +
      'Think!vmd 3D simulator を使えば、3Dシミュレーションのカンタン作成や3D データ共有機能でVMDのプレゼンテーションの効果を上げ 円滑なコミュニケーションを可能にして日々のVMD業務をちょっと便利にします。',
    description: [
      {
        title: '直感的に空間情報を伝達',
        description: `
        平面図や展開図などの２次元情報だけではわかりにくい空間情報を、カンタン操作の 3D simulator で表現できます。
        また、URL・QR共有機能を使えば、共有した相手も3Dデータを閲覧でき、今までにない円滑なコミュニケーションを可能にします。`
      },
      {
        title: 'カンタン レイアウトシミュレーション',
        description: `平面図等の面図の挿入機能で、平面図上に什器などをバーチャルにレイアウトシミュレーションができ、レイアウト検証をカンタンに行なえます。
           3D⇄2Dへの変換は、ボタン１つでカンタンに切り替えられ、さまざまな視点でのレイアウト検証・可視化ができます。`
      },
      {
        title: 'オリジナル製品のシステム登録',
        description: `什器やマネキンなどオリジナル製品を所有の企業様向けに、オリジナル3Dオブジェクトとして製品をシステム内に登録することができます。
            登録した製品はシステム内で3Dカタログ化も可能にし、顧客へのアピールツールとしても作成することができます。
            また、3D simulator 内では、見積り自動生成機能で業務の効率化をサポートします。`
      }
    ],
    descriptionJP: ''
  },
  {
    id: '3',
    name: 'Safie',
    nameJP: 'Safie',
    hwMakerJP: '',
    tags: ['防犯', 'WEB カメラ'],
    swMaker: 'Safie',
    link: 'https://safie.link/',
    support: '',
    swMakerJP: '',
    icon: 'safie',
    catchCopy: 'クラウド録画カメラ シェアNo.1のSafie（セーフィー）',
    catchCopyJP: 'クラウド録画カメラ シェアNo.1のSafie（セーフィー）',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['safieScrn1', 'safieScrn2'],
    overviewJP:
      'Safieはクラウド録画型映像プラットフォームです。\n' +
      'Safie対応カメラを導入していただくことで、クラウドを通じて、高画質なリアルタイムの\n' +
      '映像と録画された映像を手軽に見ることができ、店舗の防犯・管理に活用いただけます。',
    description: [
      {
        title: `直感的に空間情報を伝達`,
        description: `平面図や展開図などの２次元情報だけではわかりにくい空間情報を、カンタン操作の 3D simulator で表現できます。 また、URL・QR共有機能を使えば、共有した相手も3Dデータを閲覧でき、今までにない円滑なコミュニケーションを可能にします。`
      },
      {
        title: `カンタン レイアウトシミュレーション`,
        description: `平面図等の面図の挿入機能で、平面図上に什器などをバーチャルにレイアウトシミュレーションができ、レイアウト検証をカンタンに行なえます。 3D⇄2Dへの変換は、ボタン１つでカンタンに切り替えられ、さまざまな視点でのレイアウト検証・可視化ができます。
`
      },
      {
        title: `オリジナル製品のシステム登録`,
        description:
          '什器やマネキンなどオリジナル製品を所有の企業様向けに、オリジナル3Dオブジェクトとして製品をシステム内に登録することができます。　登録した製品はシステム内で3Dカタログ化も可能にし、顧客へのアピールツールとしても作成することができます。 また、3D simulator 内では、見積り自動生成機能で業務の効率化をサポートします。'
      }
    ],
    descriptionJP: ''
  },
  {
    id: '4',
    name: '"GMOタウンWiFi',
    nameJP: '"GMOタウンWiFi',
    hwMakerJP: '',
    tags: ['公衆Wifi', '通信'],
    swMaker: '"GMOタウンWiFi',
    link: 'https://townwifi.jp/company/',
    support: '',
    swMakerJP: '',
    icon: 'gmo',
    catchCopy:
      'リピーターWiFi（顧客の会員化・再来店）\n' +
      'お店のWiFiをマーケティングに活用できます\n',
    catchCopyJP:
      'リピーターWiFi（顧客の会員化・再来店）\n' +
      'お店のWiFiをマーケティングに活用できます\n',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['gmoScrn1', 'gmoScrn2'],
    overviewJP:
      '①お店に来店した人を会員化（アプリ・LINE）する\n' +
      '②過去に来店した人に対して再来店を促す\n' +
      '③当社開発したWEBダッシュボードで①②の効果を可視化する\n',
    description: [
      { title: 'その他、以下の施策も可能', description: '' },
      {
        title: '',
        description:
          '新規顧客を対象とした集客キャンペーン（小売店や商業施設の実績有り）'
      },
      { title: '', description: 'Wi-Fiルーター機器のご提供' },
      {
        title: '',
        description:
          '弊社アプリインストール促進物設置による店舗様への協力金お支払いモデル'
      },
      { title: '', description: '弊社のWi-Fi自動接続機能（SDK）を無償提供可能' }
    ],
    descriptionJP: ''
  },
  {
    id: '5',
    name: 'Eagle Eye',
    nameJP: 'Eagle Eye',
    hwMakerJP: '',
    tags: ['防犯', 'WEB カメラ'],
    swMaker: 'Eagle Eye',
    link: 'https://www.een.com/ja/',
    support: '',
    swMakerJP: 'Eagle Eye',
    icon: 'eagleeye',
    catchCopy: `「かんたん＆スマートな監視カメラシステム
        ワールドワイドでNo1の実績」`,
    catchCopyJP: `「かんたん＆スマートな監視カメラシステム
        ワールドワイドでNo1の実績」`,
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['eagleScrn1', 'eagleScrn2', 'eagleScrn3'],
    overviewJP: `「かんたん＆スマートな監視カメラシステム
 ワールドワイドでNo1の実績」`,
    description: [
      {
        title:
          'かんたん＆スマートな監視カメラシステム  ワールドワイドでNo1の実績',
        description: ''
      },
      {
        title: '',
        description: '従業員やお客様にとってより安全な環境作りに欠かせません'
      },
      { title: '', description: '自動的に最新機能にアップグレードされます' },
      {
        title: '',
        description: 'サイバーセキュリティ対策もばっちり対応しています'
      },
      { title: '', description: '既存カメラをそのままご活用いただけます' },
      { title: '', description: 'ネットに繋がりづらい環境もしっかり見えます' },
      {
        title: '',
        description:
          'Eagle Eye Cloud VMSは、離れた場所の店舗・施設・オフィスを遠隔で管理できるクラウドシステムです。現場に大掛かりな録画機を設置しなくていいので、運用も簡単で、頻繁な故障の心配もございません。お手持ちのパソコンやモバイル端末（Android/iPhone）でいつでもどこでも監視カメラにアクセスできます。また、3000機種を超えるネットワークカメラやアナログカメラに対応しています。'
      }
    ],
    descriptionJP: ''
  },
  {
    id: '6',
    name: '国内人口分布統計',
    nameJP: '国内人口分布統計',
    hwMakerJP: '',
    tags: ['統計データ'],
    swMaker: '株式会社ドコモ・インサイトマーケティング',
    link: 'https://mobaku.jp/contact/',
    support: '',
    swMakerJP: '株式会社ドコモ・インサイトマーケティング',
    icon: 'mobaku',
    catchCopy: 'モバイル空間統計',
    catchCopyJP: 'モバイル空間統計',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['mubokuAScrn1'],
    overviewJP: `国内人口分布は、日本全国の1時間ごとの平均人口をご覧いただけます。
    性・年代別、居住地エリア別に把握することが可能です。`,
    description: [
      {
        title: '',
        description:
          'エリア：500mメッシュ～・市区町村\n' +
          '時間：最小1時間ごと\n' +
          '期間：平日休日別月平均、特定日、年平均など\n' +
          '属性：総数、性年代別、居住地別(都道府県・市区町村・大字)\n' +
          '\n' +
          '詳細属性オプションを付けた分析も可能です。\n' +
          '＜詳細分析オプション＞\n' +
          '勤務地、結婚有無、居住形態、世帯年収、興味関心、趣味、保有自動車メーカー\n' +
          '新商品に対する購買行動、ネットショッピング利用頻度、来訪頻度、交通手段など\n' +
          ''
      }
    ],
    descriptionJP: ''
  },
  {
    id: '7',
    name: '国内人口分布統計 リアルタイム版',
    nameJP: '国内人口分布統計 リアルタイム版',
    hwMakerJP: '',
    tags: ['統計データ'],
    swMaker: '株式会社ドコモ・インサイトマーケティング',
    link: 'https://mobaku.jp/contact/',
    support: '',
    swMakerJP: '株式会社ドコモ・インサイトマーケティング',
    icon: 'mobaku',
    catchCopy: 'モバイル空間統計',
    catchCopyJP: 'モバイル空間統計',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['mobukuBScrn1'],
    overviewJP: `国内人口分布統計 リアルタイム版は、日本全国の平均人口を約1時間後にご覧いただけます。
    性・年代別、居住地エリア別に分析可能で、ほぼリアルタイムにどこにどのような人がいるかが把握できます。`,
    description: [
      {
        title: '',
        description: `エリア：500mメッシュ
          時間：10分ごと・1時間ごと
          期間：特定日
          属性：総数、性年代別、居住地別(都道府県、市区町村)`
      }
    ],
    descriptionJP: ''
  },
  {
    id: '8',
    name: '国内観光客動態統計',
    nameJP: '国内観光客動態統計',
    hwMakerJP: '',
    tags: ['統計データ'],
    swMaker: '株式会社ドコモ・インサイトマーケティング',
    link: 'https://mobaku.jp/contact/',
    support: '',
    swMakerJP: '株式会社ドコモ・インサイトマーケティング',
    icon: 'mobaku',
    catchCopy: 'モバイル空間統計',
    catchCopyJP: 'モバイル空間統計',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['mubokuCScrn1'],
    overviewJP:
      '観光客に特化した宿泊・日帰りの調査や観光ルートの把握が可能です。',
    description: [
      {
        title: '',
        description: `観光が想定される移動のみを観光客とみなし、エリアの期間内の累計滞在人数を調査します。
        分析内容：滞在日数調査、他都市訪問調査、前後滞在場所調査、滞在日数調査など
        エリア：1kmメッシュ～・市区町村
        時間：全日、10～18時など
        期間：1ヵ月累計、3ヵ月累計など
        属性：総数、性年代別、居住地別など`
      }
    ],
    descriptionJP: ''
  },
  {
    id: '9',
    name: '訪日外国人分布統計',
    nameJP: '訪日外国人分布統計',
    hwMakerJP: '',
    tags: ['統計データ'],
    swMaker: '株式会社ドコモ・インサイトマーケティング',
    link: 'https://mobaku.jp/contact/',
    support: '',
    swMakerJP: '株式会社ドコモ・インサイトマーケティング',
    icon: 'mobaku',
    catchCopy: 'モバイル空間統計',
    catchCopyJP: 'モバイル空間統計',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['mubokuCScrn1'],
    overviewJP:
      'エリアに滞在する訪日外国人の人口分布を国や地域別に把握することが可能です。',
    description: [
      {
        title: '',
        description: `エリア：1kmメッシュ～・都道府県、市区町村
          時間：最小1時間ごと
          期間：特定日
          属性：総数・国籍別`
      }
    ],
    descriptionJP: ''
  },
  {
    id: '10',
    name: '訪日外国人動態統計',
    nameJP: '訪日外国人動態統計',
    hwMakerJP: '',
    tags: ['統計データ'],
    swMaker: '株式会社ドコモ・インサイトマーケティング',
    link: 'https://mobaku.jp/contact/',
    support: '',
    swMakerJP: '株式会社ドコモ・インサイトマーケティング',
    icon: 'mobaku',
    catchCopy: 'モバイル空間統計',
    catchCopyJP: 'モバイル空間統計',
    publishedDate: '',
    rate: '',
    overview: '',
    screenshots: ['mobukuEScrn1'],
    overviewJP:
      '訪日外国人の入国から出国までのルート分析や旅行日数ごとの把握が可能です。',
    description: [
      {
        title: '',
        description: `エリアの期間内の累計滞在人数を調査します。
        分析内容：滞在人数調査、他都市訪問調査、前後滞在場所調査、滞在日数調査、入出国空港調査など
        エリア：1kmメッシュ～
        時間：全日、10時～18時など
        期間：1ヵ月累計、3ヵ月累計、12か月累計など
        属性： 総数、国籍別`
      }
    ],
    descriptionJP: ''
  }
];

export const recommendedThirdParty = [
  {
    icon: 'lineworks',
    label: 'Line Works ',
    description: 'lineworksContent',
    tags: ['Communication', 'BI'],
    installs: '>1,000',
    rate: 'lineworksRate',
    enabled: true
  },
  {
    icon: 'zapier',
    label: 'Zapier ',
    description: 'zapierContent',
    tags: ['Communication', 'BI'],
    installs: '>1,000',
    rate: 'zapierRate',
    enabled: false
  }
];
export const thirdParty = [
  {
    icon: 'lineworks',
    label: 'Line Works ',
    description: 'lineworksContent',
    tags: ['Communication', 'BI'],
    installs: '>1,000',
    rate: 'lineworksRate',
    enabled: true
  },
  {
    icon: 'openWeather',
    label: 'Open Weather',
    description: 'openWeatherContent',
    rate: 'openWeatherRate',
    enabled: true
  },
  {
    icon: 'ncr',
    label: 'NCR ',
    description: 'ncrContent',
    tags: ['Communication', 'BI'],
    installs: '>100',
    rate: 'ncrRate',
    enabled: true
  },
  {
    icon: 'meraki',
    label: 'Cisco Meraki',
    description: 'merakiContent',
    tags: ['Communication', 'BI'],
    installs: '<100',
    rate: 'merakiRate',
    enabled: true
  },
  {
    icon: 'zapier',
    label: 'Zapier ',
    description: 'zapierContent',
    tags: ['Communication', 'BI'],
    installs: '>1,000',
    rate: 'zapierRate',
    enabled: false
  },
  {
    icon: 'aerohive',
    label: 'Aerohive',
    description: 'aerohiveContent',
    tags: ['Communication', 'BI'],
    installs: '<100',
    rate: 'aerohiveRate',
    enabled: false
  },
  {
    icon: 'outlook',
    label: 'Outlook',
    description: 'outlookContent',
    tags: ['Communication', 'BI'],
    installs: '>100',
    rate: 'outlookRate',
    enabled: false
  },
  {
    icon: 'tec',
    label: 'TEC ',
    description: 'tecContent',
    tags: ['Communication', 'BI'],
    installs: '>1,000',
    rate: 'tecRate',
    enabled: false
  },
  {
    icon: 'aruba',
    label: 'Aruba',
    description: 'arubaContent',
    tags: ['Communication', 'BI'],
    installs: '>10',
    rate: 'arubaRate',
    enabled: false
  },
  {
    icon: 'fortinet',
    label: 'Fortinet ',
    description: 'fortinetContent',
    tags: ['Communication', 'BI'],
    installs: '<10',
    rate: 'fortinetRate',
    enabled: false
  },
  {
    icon: 'googleCalender',
    label: 'Google Calender',
    description: 'googleCalenderContent',
    tags: ['Communication', 'BI'],
    installs: '>1,900',
    rate: 'googleCalenderRate',
    enabled: false
  }
];
export const iot = [
  {
    icon: 'hikVision',
    tags: ['People Counting'],
    label: 'HikVision',
    description: '',
    productImg: 'hikProduct',
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'ncr',
    tags: ['People Counting'],
    label: 'NCR',
    description: '',
    productImg: 'ncrProduct',
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'flir',
    tags: ['POS', 'People Counting'],
    label: 'FLIR',
    description: '',
    productImg: 'flirProduct',
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'aruba',
    tags: ['POS', 'People Counting'],
    label: 'Aruba',
    description: 'arubaProduct',
    productImg: null,
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'brickStream',
    tags: ['POS', 'People Counting'],
    label: 'Brickstream',
    description: 'brickStreamContent',
    productImg: null,
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'irisSystem',
    tags: ['POS'],
    label: 'Irisys',
    description: 'irisSystemContent',
    productImg: null,
    installs: '>1,000',
    rate: 'irisSystemRate',
    enabled: false
  },
  {
    icon: 'atcast',
    tags: ['POS'],
    label: 'Actcast',
    description: 'atcastContent',
    productImg: null,
    installs: '<100',
    rate: 'atcastRate',
    enabled: false
  },
  {
    icon: 'quividi',
    tags: ['POS'],
    label: 'Quividi',
    description: 'quividiContent',
    productImg: null,
    installs: '<100',
    rate: 'quividiRate',
    enabled: false
  },
  {
    icon: 'meraki',
    tags: ['POS'],
    label: 'Cisco Meraki',
    description: 'merakiContent',
    productImg: null,
    installs: '>1,500',
    rate: 'merakiRate',
    enabled: false
  },
  {
    icon: 'datto',
    tags: ['POS'],
    label: 'Datto',
    description: 'dattoContent',
    productImg: null,
    installs: '>500',
    rate: 'dattoRate',
    enabled: false
  }
];
export const iotRecommended = [
  {
    icon: 'brickStream',
    tags: ['POS'],
    label: 'Brickstream',
    description: 'brickStreamContent',
    installs: '>1,500',
    rate: 'brickStreamRate',
    enabled: false
  },
  {
    icon: 'irisSystem',
    tags: ['POS'],
    label: 'Irisys',
    description: 'irisSystemContent',
    installs: '>1,000',
    rate: 'irisSystemRate',
    enabled: false
  },
  {
    icon: 'atcast',
    tags: ['POS'],
    label: 'Actcast',
    description: 'atcastContent',
    installs: '<100',
    rate: 'atcastRate',
    enabled: false
  },
  {
    icon: 'quividi',
    tags: ['POS'],
    label: 'Quividi',
    description: 'quividiContent',
    installs: '<100',
    rate: 'quividiRate',
    enabled: false
  }
];
export const flowService = [
  {
    icon: 'conversion',
    label: 'conversionOptimization',
    price: '¥6,900',
    serviceFeature: [
      {
        label: 'kpiBot',
        features: []
      },
      {
        label: 'trafficAnalysis',
        features: ['traffic', 'trafficPerSQM', 'returnOnVisit', 'conversion']
      },
      {
        label: 'staffOptimization',
        features: []
      }
    ],
    enabled: true
  },
  {
    icon: 'datadriven',
    label: 'dataDrivenRetailing',
    price: '¥3,900',
    serviceFeature: [
      {
        label: 'taskBot',
        features: []
      },
      {
        label: 'wifiAnalysis',
        features: ['engagementRate', 'bounceRate']
      },
      {
        label: 'passerbyPrediction',
        features: [
          'passersBy',
          'captureRate',
          'storeDwellTime'
          // 'facadeStops'
        ]
      }
    ],
    enabled: false
  },
  {
    icon: 'optimization',
    label: 'staffOptimization',
    price: '¥4,900',
    serviceFeature: [
      {
        label: 'advancedStaffReporting',
        features: []
      },
      {
        label: 'storeOccupancy',
        features: [
          'staffPathmaps',
          'salesPerStaffMember',
          'staffEfficiencyRating'
        ]
      },
      {
        label: 'staffPrediction',
        features: ['comingSoon']
      }
    ],
    enabled: false
  }
];
export const featuredListOfPackages = [];
export const packageIntegrationRecommended = [];
export const listOfPackages = [
  {
    id: 'PHW_1',
    nameJP: '高精度　熱感知来店客数計測センサーセット',
    name: 'High Accuracy Thermal Traffic Counting',
    link: 'https://share.hsforms.com/1aqVnFiFeRYKUmA48ZvHx8g12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Irisys Gazelle 2',
        hardwareCode: 'HAR_8',
        name: 'Irisys Gazelle 2',
        nameJP: 'Irisys Gazelle 2',
        icon: 'default',
        description:
          'Gazelle2 Infrared Thermal people counter with 97%+ Accuracy. Ideal people counting and fitting room analysis.  The thermal lens provieds totally anonymous counting.',
        descriptionJP:
          'Gazelle2赤外線サーマルのピープルカウンターは97％以上の精度で測定します。 理想的な人の数え方と試着室の分析。 サーマルレンズは完全に匿名のカウントを提供します。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥10,750',
    overviewJP:
      '高精度のトラフィックカウンターで店舗の来店客数をカウントします。熱感知センサーは画像データを使用しない為、顧客のプライバシーが気になるケースでも安心してご利用いただけます',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'InfraRed Integrated Systems',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      '95%+ Accuracy People counting on Irisys Themal Infrared sensors.',
    descriptionJP:
      '95%以上の精度をもつ、Irisys Thermal赤外線センサートラフィックカウントパッケージ。 Irisys Gazalle 2センサー、トラフィック処理機能、IoT管理、PoE機器が含まれています。',
    catchCopy: '',
    catchCopyJP: '高精度サーマルトラフィックカウント'
  },
  {
    id: 'PHW_2',
    nameJP: '高精度　3D来店客数計測センサーセット',
    name: 'High Accuracy 3D Traffic Counting',
    link: 'https://share.hsforms.com/1oc3oP9bKRCuGRB3vc1TcNg12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Brickstream 2510',
        hardwareCode: 'HAR_3',
        name: 'Brickstream 2510',
        nameJP: 'Brickstream 2510',
        icon: 'brickstreamdevice',
        description:
          'Dual lens 3D People counter with 97%+ accuracy. Ideal for shops and spaces where accuracy is the top priority.  Where conversion rates or occupancy is a mission critical metric this is the right hardware.',
        descriptionJP:
          'デュアルレンズ3Dトラフィックカウントは97％以上の精度をもち、 購買率または占有率が重要な指標である場合、適切なハードウェアです。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥10,750',
    overviewJP:
      '2つのレンズを用いた3D映像により来店客数をカウントします。来店客の身長を識別するので大人と子供を認識することが可能です',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'FLIR Systems',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      '95%+ Accuracy People counting on Flir Brickstream 3D sensors.',
    descriptionJP:
      '95%以上の精度 をもつ、Flir Brickstream 3Dセンサートラフィックカウント。 Brickstream 2510センサーハードウェア、トラフィック処理、IoT管理とPoEが含まれています。',
    catchCopy: '',
    catchCopyJP: '高精度 3D トラフィック カウント'
  },
  {
    id: 'PHW_3',
    nameJP: '来店客数計測　ベーシックハードウェアセット',
    name: 'Simple Traffic Counting Hardware Set',
    link: 'https://share.hsforms.com/1tKWJz0z7SH67gLfKvCOPog12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Panasonic IP Camera',
        hardwareCode: '#N/A',
        name: 'Panasonic IP Camera',
        nameJP: 'Panasonic IP Camera',
        icon: 'panasonicdevice',
        description: 'Ultra-compact fixed dome IP Camera. ',
        descriptionJP: 'コンパクトな筐体で広画角を実現'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥10,250',
    overviewJP: '基本的なトラフィックカントのハードウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      '95%+ Accuracy People counting on Flir Brickstream 3D sensors.',
    descriptionJP:
      'FlowのAdvanced People Countingソフトウェアでトラフィックカウントをはじめましょう。IPカメラ、トラフィックソフトウェア、Flow Cloud Processing、およびPoEが含まれています。',
    catchCopy: '',
    catchCopyJP: 'トラフィックカウント基本パッケージ'
  },
  {
    id: 'PHW_4',
    nameJP: '来店客数計測　アドバンスハードウェアドセット',
    name: 'Advanced Flow Counting Hardware Set',
    link: 'https://share.hsforms.com/19Fh_9blQS5-ArCqdUnFdPg12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Flow Demographics',
        hardwareCode: 'ADD_2',
        name: 'Flow Demographics',
        nameJP: '属性分析',
        icon: 'default',
        description:
          "Demographic Analysis by Flow.  Monitor the gender and age trends of your visitors.  Flow's powerful machine Learning software give you greater insight into your target customer and what they are doing in your shops.    Deploy quickly on your existing IP Cameras!",
        descriptionJP:
          '人口統計学的分析。 来店者の性別や年齢の傾向分析します。 Flowの強力な機械学習ソフトウェアは、あなたのターゲット顧客と彼らがあなたの店で何をしているかについてのより大きな洞察力を与えます。   既存のIPカメラですぐに導入できます'
      },
      {
        item: 'Repeats Analysis',
        hardwareCode: 'ADD_6',
        name: 'Repeats Analysis',
        nameJP: 'リピーター客計測',
        icon: 'default',
        description:
          'What ratio are visitors returning to your shop?  Do you need to filter out staff from your traffic numbers?  Deploy ',
        descriptionJP:
          'あなたのお店に戻ってくる訪問者の割合は？ トラフィック数からスタッフをフィルタリングする必要がありますか？ '
      },
      {
        item: 'Panasonic IP Camera',
        hardwareCode: '#N/A',
        name: 'Panasonic IP Camera',
        nameJP: 'Panasonic IP Camera',
        icon: 'panasonicdevice',
        description: 'Ultra-compact fixed dome IP Camera. ',
        descriptionJP: 'コンパクトな筐体で広画角を実現'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥22,750',
    overviewJP: 'さらに高度な分析のためのトラフィックカウントパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      "Best Value! Leverage Flow's advanced Machine Learning technology to get all the tools you need to know about your customers and how your shop is performing.",
    descriptionJP:
      'ベストバリュー Flowの高度な機械学習技術を活用して、顧客やお店の状況を知るために必要なすべてのツールを手に入れましょう。  IPカメラ、Flowクラウド処理、IoT管理、PoE、トラフィック、リピート率、人口統計が含まれます。',
    catchCopy: '',
    catchCopyJP: 'Flow 顧客導線カウント'
  },
  {
    id: 'PHW_5',
    nameJP: '高精度　スタッフ除外機能付き　3D来店客数計測センサーセット',
    name: 'High Accuracy 3D Traffic Counting with Staff Filtering',
    link: 'https://share.hsforms.com/1wAss-58GQV6zByBJclqmZw12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Brickstream 2510',
        hardwareCode: 'HAR_3',
        name: 'Brickstream 2510',
        nameJP: 'Brickstream 2510',
        icon: 'brickstreamdevice',
        description:
          'Dual lens 3D People counter with 97%+ accuracy. Ideal for shops and spaces where accuracy is the top priority.  Where conversion rates or occupancy is a mission critical metric this is the right hardware.',
        descriptionJP:
          'デュアルレンズ3Dトラフィックカウントは97％以上の精度をもち、 購買率または占有率が重要な指標である場合、適切なハードウェアです。'
      },
      {
        item: 'Brickstream BLE Card',
        hardwareCode: 'HAR_6',
        name: 'Brickstream BLE Card',
        nameJP: 'Brickstream BLE タグ',
        icon: 'brickstreamcarddevice',
        description:
          'BLE tag for use with Brickstream 2510SF to filter staff from in and out counts',
        descriptionJP:
          'Brickstream 2510SFで使用するBLEタグを使用して、スタッフのインとアウトのカウントをフィルタリングします'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'BrickStream Employee Filtering Key',
        hardwareCode: 'HAR_25',
        name: 'BrickStream Employee Filtering Key',
        nameJP: 'Brickstream用スタッフ除外タグ',
        icon: 'default',
        description: '',
        descriptionJP: ''
      },
      {
        item: 'BrickStream Employee Filtering USB Dongle for BLE',
        hardwareCode: 'HAR_26',
        name: 'BrickStream Employee Filtering USB Dongle for BLE',
        nameJP: 'Brickstream用スタッフ除外BLE　USBドングル',
        icon: 'default',
        description: '',
        descriptionJP: ''
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥14,750',
    overviewJP:
      'スタッフ及び関係者は小型の電子タグを着用し、顧客とスタッフを判別。正確な来店客数をカウントします。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'FLIR Systems',
    overview: '',
    softwareMaker: 'FLIR Systems',
    description:
      'Dual lens 3D People counter with 97%+ accuracy and the added bonus of being able to filter staff from counts.  Using Bluetooth cards this sensor is able to filter out staff and report only traffic of potential buyers.',
    descriptionJP:
      'デュアルレンズ3D人は、97%以上の精度とカウントからスタッフをフィルタリングすることができることの追加カウントを含みます。 Bluetoothカードを使用して、センサーがスタッフをフィルタリングし、潜在的な顧客のトラフィックのみをカウントすることができます。 ブリックストリーム2510センサーハードウェア、トラフィック処理、PoE、IoT管理、Bluetoothモジュール、スタッフフィルタリングファームウェアと5つのBluetoothカードが含まれています。',
    catchCopy: '',
    catchCopyJP: 'スタッフのフィルタリングと高精度な 3D トラフィック カウント'
  },
  {
    id: 'PHW_6',
    nameJP: 'ゾーン分析ハードウェア　アドバンスセット',
    name: 'Advanced Zone Analysis Hardware Set',
    link: 'https://share.hsforms.com/1fnOh9QS7RwOW-JPAfS7ZHw12tnr',
    icon: 'zone',
    hardwares: [
      {
        item: 'Flow Zones',
        hardwareCode: 'ADD_3',
        name: 'Flow Zones',
        nameJP: 'ゾーン分析',
        icon: 'default',
        description:
          'Deploy quickly on your existing IP Cameras to monitor how your customers are interacting with your displays.  Where are they interested in? Where are they not?  How can you improve?  We can help you find the answers.',
        descriptionJP:
          '既存のIPカメラに素早く導入して、顧客がどのようにディスプレイと対話しているかを監視します。 顧客はどこに興味を持っているのか？どこに興味を持っていないのか？ どうすれば改善できるのか？ 私たちはその答えを見つけるお手伝いをします'
      },
      {
        item: 'Brickstream 2510',
        hardwareCode: 'HAR_3',
        name: 'Brickstream 2510',
        nameJP: 'Brickstream 2510',
        icon: 'brickstreamdevice',
        description:
          'Dual lens 3D People counter with 97%+ accuracy. Ideal for shops and spaces where accuracy is the top priority.  Where conversion rates or occupancy is a mission critical metric this is the right hardware.',
        descriptionJP:
          'デュアルレンズ3Dトラフィックカウントは97％以上の精度をもち、 購買率または占有率が重要な指標である場合、適切なハードウェアです。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Brickstream Advanced Firmware Key',
        hardwareCode: 'HAR_27',
        name: 'Brickstream Advanced Firmware Key',
        nameJP:
          'Brickstream用 アドバンスファームウェア(Tracking & Queue)（カメラ台数分追加）',
        icon: 'default',
        description: '',
        descriptionJP: ''
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥15,550',
    overviewJP:
      '特定の棚・什器前の滞在人数、滞在時間を分析しヒートマップに表示。売り場レイアウトのパフォーマンス向上に活用できます',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'FLIR Systems',
    overview: '',
    softwareMaker: 'FLIR Systems',
    description:
      'Dual lens 3D People counter with pathmapping functionality. Measure interaction at the fixture level with high accuracy Brickstream hardware.',
    descriptionJP:
      'パスマッピング機能を備えたデュアルレンズ3Dピープルカウンター。高精度のBrickstreamハードウェアでフィクスチャレベルでのインタラクションを測定します。 Brickstream 2510センサーハードウェア、トラック処理、詳細トラッキングファームウェア IoT管理とPoEハードウェアが含まれています。',
    catchCopy: '',
    catchCopyJP: 'Flow アドバンス ゾーン分析'
  },
  {
    id: 'PHW_7',
    nameJP: '高精度　レジ待ちモニタリングセット',
    name: 'High Accuracy Queue Monitoring',
    link: 'https://share.hsforms.com/1O6NCU1w7Te6_IhqLO_aKYg12tnr',
    icon: 'queue',
    hardwares: [
      {
        item: 'Queue Monitoring',
        hardwareCode: 'ADD_11',
        name: 'Queue Monitoring',
        nameJP: 'レジ待ちモニタリング',
        icon: 'default',
        description:
          'For Supermarkets, drug stores and electronics retailers, improve customer satisfaction by monitoring queue conditions in real-time. Deploy on your existing IP cameras or package with a high-accuracy people counter.  Add the service alerts add-on to recieve real-time alerts and recommendations when the queues or waiting times are longer than acceptable.',
        descriptionJP:
          'スーパーマーケット、ドラッグストア、家電量販店向けに、リアルタイムで列の状況を監視して顧客満足度を向上させます。既存のIPカメラに導入するか、高精度のピープルカウンターをパッケージ化します。 サービスアラートアドオンを追加することで、行列や待ち時間が許容範囲を超えている場合にリアルタイムでアラートや推奨事項を受け取ることができます。'
      },
      {
        item: 'Brickstream 2510',
        hardwareCode: 'HAR_3',
        name: 'Brickstream 2510',
        nameJP: 'Brickstream 2510',
        icon: 'brickstreamdevice',
        description:
          'Dual lens 3D People counter with 97%+ accuracy. Ideal for shops and spaces where accuracy is the top priority.  Where conversion rates or occupancy is a mission critical metric this is the right hardware.',
        descriptionJP:
          'デュアルレンズ3Dトラフィックカウントは97％以上の精度をもち、 購買率または占有率が重要な指標である場合、適切なハードウェアです。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Brickstream Advanced Firmware Key',
        hardwareCode: 'HAR_27',
        name: 'Brickstream Advanced Firmware Key',
        nameJP:
          'Brickstream用 アドバンスファームウェア(Tracking & Queue)（カメラ台数分追加）',
        icon: 'default',
        description: '',
        descriptionJP: ''
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥14,050',
    overviewJP:
      'レジに並んでいる人数を把握し、一定の人数を超えた場合スタッフにアラートを送信します。またどのレジがどの時間帯に一番混んでいるかをリアルタイムレポートで把握できます。レジ待ち時間の短縮や混雑対策に効果的です。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'FLIR Systems',
    overview:
      'It keeps track of the number of people standing in line at the cash register and sends alerts to your staff if the number exceeds a certain number. You can also get a real-time report on which cash registers are the busiest at which time of day. Shorten your checkout time and reduce congestion',
    softwareMaker: 'FLIR Systems',
    description:
      'Dual lens 3D People counter with 97%+ accuracy. For Supermarkets, drug stores and electronics retailers this sensor helps improve customer satisfaction by monitoring queue conditions in real-time.  Add the Service Alerts add-on to recieve real-time alerts and recommendations when the queues or waiting times are longer than acceptable.',
    descriptionJP:
      '97%+の精度のデュアルレンズ3Dトラフィックカウンター。スーパーマーケット、ドラッグストア、家電量販店向けのこのセンサーは、リアルタイムでレジ待ち(キュー)の状態を監視することで、顧客満足度の向上に役立ちます。   Brickstream 2510 センサーハードウェア、キュー処理、PoE、IOt 管理、キュー監視ファームウェアが含まれています。 サービスアラートアドオンを追加して、待ち行列や待ち時間が許容範囲を超えている場合にリアルタイムのアラートで必要項目を通知します。',
    catchCopy: '',
    catchCopyJP: 'Flow レジ待ち分析'
  },
  {
    id: 'PHW_8',
    nameJP: 'WiFiトレンド分析　ハードウェアセット',
    name: 'Flow WiFi Trend Analysis Hardware Set',
    link: 'https://share.hsforms.com/1CYOUIXkpS_ihjH7FAC_V7Q12tnr',
    icon: 'wifi',
    hardwares: [
      {
        item: 'WiFi Analysis',
        hardwareCode: 'ADD_9',
        name: 'WiFi Analysis',
        nameJP: 'WiFiトレンド分析',
        icon: 'default',
        description:
          'WiFi analysis is a extremely versatile tool for offices and shops.  If your space already has wifi hardware we can likely help you leverage it for clear vizualization of your usage trends.  Using the automatic probe requests of wifi enable devices Flow is able to deliver actionable insights into how your spaces are used.  With the addition of high accuracy people counting Flow can calibrate the WiFi data for greater accuracy and more stable wifi trends.',
        descriptionJP:
          'WiFi 解析は、オフィスや店舗のための非常に汎用性の高いツールです。 もしあなたのスペースに既にwifiハードウェアがある場合は、使用傾向を明確に可視化するためにそれを活用するお手伝いをすることができます。 FlowはWiFi対応デバイスの自動プローブリクエストを使用して、スペースがどのように使用されているかについて実用的な洞察を提供することができます。 高精度のピープルカウントを追加することで、FlowはWiFiデータを校正し、より精度の高い、より安定したWiFiトレンドを提供することができます。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥6,750',
    overviewJP:
      'WiFiによる顧客動向分析、滞在時間、リピート訪問者の分析などが含まれます',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Datto',
    overview:
      'WiFi analysis of customer trends, time spent, repeat visitors, etc.',
    softwareMaker: 'Flow Solutions',
    description:
      "Understand your instore trends with WiFi Analysis. Smart devices constantly are searching for wifi conections.  Flow leverages those 'pings' to analyze the trends of your stores.  Available KPIs include Passersby, Capture rates, dwell time, engagement rate, and bounce rate.    *1 set per 75sqm recommended.  For increased accuracy integrate Traffic.",
    descriptionJP:
      'WiFi分析でインストアのトレンドを把握しましょう。スマートフォンなどのデバイスは常にWiFiの接続先を探しています。 Flowは、それらの信号を活用して、店舗の傾向を分析します。  Flow WiFiハードウェア、WiFiデータ処理、IoT管理、PoEが含まれています。 利用可能なKPIには、通行人、キャプチャ率、滞留時間、エンゲージメント率、バウンス率などがあります。   *1 75sqmあたりのセットを推奨します。 精度を高めるには、トラフィックデータを統合する必要があります。',
    catchCopy: '',
    catchCopyJP: 'Flow WiFiトレンド'
  },
  {
    id: 'PHW_10',
    nameJP: '顧客属性分析　ハードウェアセット',
    name: 'Flow Demographics Hardware Set',
    link: 'https://share.hsforms.com/1-m58muTqTQ-Af-MagoUtNg12tnr',
    icon: 'demographics',
    hardwares: [
      {
        item: 'Flow DAP42 WiFi Access Point',
        hardwareCode: 'HAR_11',
        name: 'Flow DAP42 WiFi Access Point',
        nameJP: 'Flow DAP42 WiFi アクセスポイント',
        icon: 'default',
        description:
          'Network managed WiFi access point - ideal for in-store analytics. Includes hardware and licences.',
        descriptionJP:
          'ネットワーク管理のWiFiアクセスポイント-店内分析に最適です。 ハードウェアとライセンスが含まれています。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Datto Monthly Management',
        hardwareCode: 'HAR_28',
        name: 'Datto Monthly Management',
        nameJP: 'Datto 月額管理費用',
        icon: 'default',
        description: '',
        descriptionJP: ''
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥11,750',
    overviewJP:
      '顔の特徴からデモグラフィック情報をデータとして取得します。\n' +
      '画像情報は保持されない為プライバシー対策も万全です。データはローカル処理され、メタデータのみをクラウドに送信します。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '属性分析は、顧客の店舗に入る顧客の年齢層、性別、表情などのデータを取得するユニークなソリューションです。広告、店舗内ディスプレイ、製品コンテンツ、既存戦略を、事実データに基づいて改善することができます。 IPカメラハードウェア、プロファイルデータ処理、Flowクラウド、IoT管理、PoEを含むパッケージ',
    catchCopy: '',
    catchCopyJP: 'Flow 属性分析'
  },
  {
    id: 'PHW_11',
    nameJP: '店前通行人数計測　ハードウェアセット',
    name: 'Flow Passers Hardware Set',
    link: 'https://share.hsforms.com/1hUkboKPxS82iQC4L62VdKA12tnr',
    icon: 'passersby',
    hardwares: [
      {
        item: 'Flow Passersby',
        hardwareCode: 'ADD_4',
        name: 'Flow Passersby',
        nameJP: '店前通行人数計測',
        icon: 'default',
        description:
          'Highly accurate Passerby monitoring on IP cameras or people counters.  Is your location in a highly trafficed area?  Are your campaigns and storefront improving over time?  We can show you?',
        descriptionJP:
          'IPカメラやピープルカウンターでの高精度な通行人監視。 あなたの場所は交通量の多いエリアにありますか？ キャンペーンや店頭は時間の経過とともに改善されていますか？ 私たちはあなたを示すことができますか？'
      },
      {
        item: 'Panasonic IP Camera',
        hardwareCode: '#N/A',
        name: 'Panasonic IP Camera',
        nameJP: 'Panasonic IP Camera',
        icon: 'panasonicdevice',
        description: 'Ultra-compact fixed dome IP Camera. ',
        descriptionJP: 'コンパクトな筐体で広画角を実現'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥11,250',
    overviewJP: '店舗前の通行人数をWi-Fi信号を使い測定します。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '"全ての店舗前通行人が、お客様になる可能性を秘めた「潜在客」です。\n' +
      '\n' +
      '店舗売上向上の第一歩は、商品を売る以前に、潜在客に入店を促し、来店客を増やすことです。店前通行人数の分析によって、どれだけの通行人を来店客に転換できたのかが明らかになります。IPカメラのハードウェア、通行人のデータ処理、Flowクラウド、IoT管理、PoEを含むパッケージ',
    catchCopy: '',
    catchCopyJP: 'Flow 店前通行人数分析'
  },
  {
    id: 'PHW_12',
    nameJP: '高精度　滞在人数計測３Dセンサーセット',
    name: 'High Accuracy 3D Occupancy Monitoring',
    link: 'https://share.hsforms.com/1eei99kXCSQu02YUYTP3Qgg12tnr',
    icon: 'occupancy',
    hardwares: [
      {
        item: 'Occupancy Monitoring',
        hardwareCode: 'ADD_10',
        name: 'Occupancy Monitoring',
        nameJP: '滞在人数計測',
        icon: 'default',
        description:
          'Keep the density of your shops and spaces safe and track usage trends.',
        descriptionJP:
          'お店やスペースの密度を安全に保ち、利用傾向を把握しましょう。'
      },
      {
        item: 'Brickstream 2510',
        hardwareCode: 'HAR_3',
        name: 'Brickstream 2510',
        nameJP: 'Brickstream 2510',
        icon: 'brickstreamdevice',
        description:
          'Dual lens 3D People counter with 97%+ accuracy. Ideal for shops and spaces where accuracy is the top priority.  Where conversion rates or occupancy is a mission critical metric this is the right hardware.',
        descriptionJP:
          'デュアルレンズ3Dトラフィックカウントは97％以上の精度をもち、 購買率または占有率が重要な指標である場合、適切なハードウェアです。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥10,750',
    overviewJP: 'Wi-Fi信号や防犯カメラでオフィスや店舗の混雑状況を把握します。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '「今どれぐらいお店が混んでいるのか？」本部、店舗から確認できます。一時的に入場制限をしたり、どの店舗でどの時間帯に混雑するのかを把握し、お客さまに事前に来店時間の変更を促すなどの施策がすぐに立案できます。IPカメラのハードウェア、混雑率データ処理、IoT管理、PoEを含むパッケージ',
    catchCopy: '',
    catchCopyJP: 'Flow 3D 占有率モニタリング'
  },
  {
    id: 'PHW_13',
    nameJP: '試着室分析　ハードウェアセット',
    name: 'Fitting Room Analysis Hardware Set',
    link: 'https://share.hsforms.com/1Y8GqPxSGRResLaezQHKngg12tnr',
    icon: 'fittingroom',
    hardwares: [
      {
        item: 'Fitting Room Analysis',
        hardwareCode: 'ADD_8',
        name: 'Fitting Room Analysis',
        nameJP: '試着室計測',
        icon: 'default',
        description:
          'Monitor fitting room usage.  This is a key metric for all fashion retailers.  An improvement in your fitting room rate. equals an improvement conversion and overall sales.  For privacy, Irisys thermal sensors are recommended.',
        descriptionJP:
          'フィッティングルームの使用状況をモニターします。 これはすべてのファッション小売業者にとって重要な指標です。 試着室の利用率が向上すれば、コンバージョン率が向上し、全体の売上が向上します。 プライバシーを守るためには、アイリシスの赤外線センサーをお勧めします。'
      },
      {
        item: 'Irisys Gazelle 2',
        hardwareCode: 'HAR_8',
        name: 'Irisys Gazelle 2',
        nameJP: 'Irisys Gazelle 2',
        icon: 'default',
        description:
          'Gazelle2 Infrared Thermal people counter with 97%+ Accuracy. Ideal people counting and fitting room analysis.  The thermal lens provieds totally anonymous counting.',
        descriptionJP:
          'Gazelle2赤外線サーマルのピープルカウンターは97％以上の精度で測定します。 理想的な人の数え方と試着室の分析。 サーマルレンズは完全に匿名のカウントを提供します。'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'PoE Switch 1-port',
        hardwareCode: 'HAR_12',
        name: 'PoE Switch 1-port',
        nameJP: '1 ポートPoE',
        icon: 'default',
        description: 'Single port PoE Hub for small deployments.',
        descriptionJP: '小規模な展開向けのシングルポートPoEハブ'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥15,750',
    overviewJP:
      '試着室での買い物客行動を分析することで、アップセリングやクロスセリングをより効果的に。',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: 'Panasonic',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '"利用者の67％以上が購入に至る、店内の”スイートスポット”である試着室。\n' +
      'データに基づいて買い物客を試着室へ効果的に誘導します。\n' +
      '専用サーモセンサーを使用することで、プライバシー保護への配慮も万全です。"',
    catchCopy: '',
    catchCopyJP:
      'ファッションチェーンの売上を牽引しましょう  フィッティングルームは理想的な中間KPIです。'
  },
  {
    id: 'PSW_1',
    nameJP: '属性分析　クラウドソフトウェアセット',
    name: 'Flow Demographics - Cloud Software Set',
    link: 'https://share.hsforms.com/1AsavOVP1S56BCbdDwlM2iQ12tnr',
    icon: 'demographics',
    hardwares: [
      {
        item: 'Flow Demographics',
        hardwareCode: 'ADD_2',
        name: 'Flow Demographics',
        nameJP: '属性分析',
        icon: 'default',
        description:
          "Demographic Analysis by Flow.  Monitor the gender and age trends of your visitors.  Flow's powerful machine Learning software give you greater insight into your target customer and what they are doing in your shops.    Deploy quickly on your existing IP Cameras!",
        descriptionJP:
          '人口統計学的分析。 来店者の性別や年齢の傾向分析します。 Flowの強力な機械学習ソフトウェアは、あなたのターゲット顧客と彼らがあなたの店で何をしているかについてのより大きな洞察力を与えます。   既存のIPカメラですぐに導入できます'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: '',
    rate: '¥7,250',
    overviewJP: '属性分析のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '高度な技術で買い物客の属性を分析。デジタルサイネージとの連動で、視聴者属性に合わせたコンテンツ配信も可能です。\n' +
      '個人を特定しない顔認識システムのため、安心してご利用いただけます。',
    catchCopy: '',
    catchCopyJP: '属性分析に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_2',
    nameJP: '来店客数分析　クラウドソフトウェアセット',
    name: 'Traffic Analytics - Cloud Software Set',
    link: 'https://share.hsforms.com/1Ad6abe6hQmSHq9H3Y8t88g12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥5,750',
    overviewJP: 'トラフィックカウントのためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      '利用者の67％以上が購入に至る、店内の”スイートスポット”である試着室。',
    catchCopy: '',
    catchCopyJP:
      '来店客数計測によって、店舗分析に必要な多くのKPIを割り出すことができます。\n' +
      '購買率をはじめとした様々なKPI計測が可能になり、お店のパフォーマンスを高める近道になります。 '
  },
  {
    id: 'PSW_4',
    nameJP: 'リピーター客数　クラウドソフトウェアセット',
    name: 'Repeats Analysis - Cloud Software Set',
    link: 'https://share.hsforms.com/1-eNr572OSAqebF6MEMLhkg12tnr',
    icon: 'repeats',
    hardwares: [
      {
        item: 'Repeats Analysis',
        hardwareCode: 'ADD_6',
        name: 'Repeats Analysis',
        nameJP: 'リピーター客計測',
        icon: 'default',
        description:
          'What ratio are visitors returning to your shop?  Do you need to filter out staff from your traffic numbers?  Deploy ',
        descriptionJP:
          'あなたのお店に戻ってくる訪問者の割合は？ トラフィック数からスタッフをフィルタリングする必要がありますか？ '
      },
      {
        item: 'Cross Shopping Analysis',
        hardwareCode: 'ADD_7',
        name: 'Cross Shopping Analysis',
        nameJP: 'クロスショッピング分析',
        icon: 'default',
        description:
          'A deeper layer of visibility to repeat analysis. What are the usage patterns between your brands or shops?  Which brands have the closest affinity?  Monitor your cross shopping patterns.  Available for all IP cameras.  Include same store repeat analysis.',
        descriptionJP:
          'リピート分析への可視化の深層化。ブランド間や店舗間の利用パターンは？ どのブランドが最も親和性が高いのか？ クロスショッピングのパターンを監視します。 すべてのIPカメラで利用可能。 同一店舗のリピート分析を含む。'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥10,250',
    overviewJP: 'リピーター客計測のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP:
      'リピート・クロスショッピング分析、IoTマネジメント、クラウド処理を含む',
    catchCopy: '',
    catchCopyJP:
      'リピーター客計測に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_5',
    nameJP: 'ゾーン分析　クラウドソフトウェアセット',
    name: 'Zones Analysis - Cloud Software Set',
    link: 'https://share.hsforms.com/1HPxbIcntTVm15huxFrapnA12tnr',
    icon: 'zone',
    hardwares: [
      {
        item: 'Flow Zones',
        hardwareCode: 'ADD_3',
        name: 'Flow Zones',
        nameJP: 'ゾーン分析',
        icon: 'default',
        description:
          'Deploy quickly on your existing IP Cameras to monitor how your customers are interacting with your displays.  Where are they interested in? Where are they not?  How can you improve?  We can help you find the answers.',
        descriptionJP:
          '既存のIPカメラに素早く導入して、顧客がどのようにディスプレイと対話しているかを監視します。 顧客はどこに興味を持っているのか？どこに興味を持っていないのか？ どうすれば改善できるのか？ 私たちはその答えを見つけるお手伝いをします'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: '',
    rate: '¥8,750',
    overviewJP: 'ゾーン分析のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP: 'ゾーン分析、IoT管理、クラウド処理を含む',
    catchCopy: '',
    catchCopyJP: 'ゾーン分析に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_6',
    nameJP: 'WiFi分析　クラウドソフトウェアセット',
    name: 'WiFi Analysis - Cloud Software Set',
    link: 'https://share.hsforms.com/12CaBrp8aR0GIR_eNO7AYcg12tnr',
    icon: 'wifi',
    hardwares: [
      {
        item: 'WiFi Analysis',
        hardwareCode: 'ADD_9',
        name: 'WiFi Analysis',
        nameJP: 'WiFiトレンド分析',
        icon: 'default',
        description:
          'WiFi analysis is a extremely versatile tool for offices and shops.  If your space already has wifi hardware we can likely help you leverage it for clear vizualization of your usage trends.  Using the automatic probe requests of wifi enable devices Flow is able to deliver actionable insights into how your spaces are used.  With the addition of high accuracy people counting Flow can calibrate the WiFi data for greater accuracy and more stable wifi trends.',
        descriptionJP:
          'WiFi 解析は、オフィスや店舗のための非常に汎用性の高いツールです。 もしあなたのスペースに既にwifiハードウェアがある場合は、使用傾向を明確に可視化するためにそれを活用するお手伝いをすることができます。 FlowはWiFi対応デバイスの自動プローブリクエストを使用して、スペースがどのように使用されているかについて実用的な洞察を提供することができます。 高精度のピープルカウントを追加することで、FlowはWiFiデータを校正し、より精度の高い、より安定したWiFiトレンドを提供することができます。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥2,250',
    overviewJP: 'Wi-Fi分析のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP: 'WiFi分析とIoT管理を含む',
    catchCopy: '',
    catchCopyJP: 'WiFi分析に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_7',
    nameJP: '混雑率分析　クラウドソフトウェアセット',
    name: 'Occupancy Monitoring - Cloud Software Set',
    link: 'https://share.hsforms.com/1nUfzalMJThCkZMz32HZqmA12tnr',
    icon: 'occupancy',
    hardwares: [
      {
        item: 'Occupancy Monitoring',
        hardwareCode: 'ADD_10',
        name: 'Occupancy Monitoring',
        nameJP: '滞在人数計測',
        icon: 'default',
        description:
          'Keep the density of your shops and spaces safe and track usage trends.',
        descriptionJP:
          'お店やスペースの密度を安全に保ち、利用傾向を把握しましょう。'
      },
      {
        item: 'Flow Cloud Processing',
        hardwareCode: 'SRV_5',
        name: 'Flow Cloud Processing',
        nameJP: 'クラウド処理サービス',
        icon: 'default',
        description:
          'Deploying additonal hardware to your locations can be challenging. Flow Cloud Processing simplfies this and lowers your total cost of ownership by doing the AI video processing on our cloud.  All you need is the camera.  For IP cameras only.  Brickstream, Irisys & WiFi sensors process data locally by default.',
        descriptionJP:
          '追加のハードウェアを展開するのが難しい場合、Flow Cloud Processingが最適です。クラウド上でAIビデオ処理を行うことで、簡素化と総所有コスト削減を実現します。 必要なのは IPカメラのみになります。 Brickstream、Irisys、WiFiセンサーは、デフォルトでデータをローカル処理します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥5,750',
    overviewJP: '混雑率分析のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP: '混雑率監視、IoT管理、クラウド処理を含む',
    catchCopy: '',
    catchCopyJP: '混雑率分析に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_8',
    nameJP: 'レジ待ち分析　クラウドソフトウェアセット',
    name: 'Queue Monitoring - Cloud Software Set',
    link: 'https://share.hsforms.com/1Pyl0xT2QRWWV5Qr-FArILw12tnr',
    icon: 'queue',
    hardwares: [],
    publishedDate: 'November 3',
    rate: '¥7,250',
    overviewJP: 'レジ待ち分析のためのクラウドソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description: '',
    descriptionJP: 'レジ待ち分析、IoT管理、クラウド処理を含む',
    catchCopy: '',
    catchCopyJP:
      'レジ待ち分析に必要なクラウドソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_9',
    nameJP: '属性分析　ローカルソフトウェアセット',
    name: 'Flow Demographics - Local Software Set',
    link: 'https://share.hsforms.com/1pSOm2FUlQZ-t3CbvXOPVwQ12tnr',
    icon: 'demographics',
    hardwares: [
      {
        item: 'Flow Demographics',
        hardwareCode: 'ADD_2',
        name: 'Flow Demographics',
        nameJP: '属性分析',
        icon: 'default',
        description:
          "Demographic Analysis by Flow.  Monitor the gender and age trends of your visitors.  Flow's powerful machine Learning software give you greater insight into your target customer and what they are doing in your shops.    Deploy quickly on your existing IP Cameras!",
        descriptionJP:
          '人口統計学的分析。 来店者の性別や年齢の傾向分析します。 Flowの強力な機械学習ソフトウェアは、あなたのターゲット顧客と彼らがあなたの店で何をしているかについてのより大きな洞察力を与えます。   既存のIPカメラですぐに導入できます'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥3,750',
    overviewJP: '属性分析のためのローカルソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      'Requires Local Processor. Recommended for stores with multiple IP Cameras and measurement points.',
    descriptionJP:
      '属性分析、IoT管理を含む。ローカルプロセッサが必要です。複数のIPカメラや計測ポイントを持つ店舗におすすめです。',
    catchCopy: '',
    catchCopyJP: '属性分析に必要なローカルソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_10',
    nameJP: '来店客数計測　ローカルソフトウェアセット',
    name: 'Traffic Analytics - Local Software Set',
    link: 'https://share.hsforms.com/106r37MN_QI-eaJaE0_-lRg12tnr',
    icon: 'traffic',
    hardwares: [
      {
        item: 'Traffic Analysis',
        hardwareCode: 'ADD_1',
        name: 'Traffic Analysis',
        nameJP: '来店客数計測',
        icon: 'default',
        description:
          'Track the ins and outs of your shops and spaces with industry leading accuracy. Deploy quickly on your existing IP Cameras or ultra-high accuracy people counting sensors.  ',
        descriptionJP:
          '業界をリードする高精度トラフィックカウントセンサーで店舗やスペースの内外を追跡します。既存のIPカメラまたはトラフィックカウントセンサーによるデータを統合します。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥2,250',
    overviewJP: 'トラフィックカウントのためのローカルソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      'Requires Local Processor. Recommended for stores with multiple IP Cameras and measurement points.',
    descriptionJP:
      'トラフィック分析、IoT管理を含む。ローカルプロセッサが必要です。複数のIPカメラや計測ポイントを持つ店舗におすすめです。',
    catchCopy: '',
    catchCopyJP:
      '来店客数分析に必要なローカルソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_12',
    nameJP: 'リピーター客計測　ローカルソフトウェアセット',
    name: 'Repeats Analysis - Local Software Set',
    link: 'https://share.hsforms.com/1oVasEjWXSHCK0vWoaZXSag12tnr',
    icon: 'repeats',
    hardwares: [
      {
        item: 'Repeats Analysis',
        hardwareCode: 'ADD_6',
        name: 'Repeats Analysis',
        nameJP: 'リピーター客計測',
        icon: 'default',
        description:
          'What ratio are visitors returning to your shop?  Do you need to filter out staff from your traffic numbers?  Deploy ',
        descriptionJP:
          'あなたのお店に戻ってくる訪問者の割合は？ トラフィック数からスタッフをフィルタリングする必要がありますか？ '
      },
      {
        item: 'Cross Shopping Analysis',
        hardwareCode: 'ADD_7',
        name: 'Cross Shopping Analysis',
        nameJP: 'クロスショッピング分析',
        icon: 'default',
        description:
          'A deeper layer of visibility to repeat analysis. What are the usage patterns between your brands or shops?  Which brands have the closest affinity?  Monitor your cross shopping patterns.  Available for all IP cameras.  Include same store repeat analysis.',
        descriptionJP:
          'リピート分析への可視化の深層化。ブランド間や店舗間の利用パターンは？ どのブランドが最も親和性が高いのか？ クロスショッピングのパターンを監視します。 すべてのIPカメラで利用可能。 同一店舗のリピート分析を含む。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥6,750',
    overviewJP: 'リピーター客計測のためのローカルソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      'Requires Local Processor. Recommended for stores with multiple IP Cameras and measurement points.',
    descriptionJP:
      'リピートおよびクロスショッピング分析、IoT管理を含む。ローカルプロセッサが必要です。複数のIPカメラや計測ポイントを持つ店舗におすすめです。',
    catchCopy: '',
    catchCopyJP:
      'リピーター客計測に必要なローカルソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_13',
    nameJP: 'ゾーン分析　ローカルソフトウェアセット',
    name: 'Zones Analysis - Local Software Set',
    link: 'https://share.hsforms.com/1yQ5SWhFGQGukpxVRP4tt9g12tnr',
    icon: 'zone',
    hardwares: [
      {
        item: 'Flow Zones',
        hardwareCode: 'ADD_3',
        name: 'Flow Zones',
        nameJP: 'ゾーン分析',
        icon: 'default',
        description:
          'Deploy quickly on your existing IP Cameras to monitor how your customers are interacting with your displays.  Where are they interested in? Where are they not?  How can you improve?  We can help you find the answers.',
        descriptionJP:
          '既存のIPカメラに素早く導入して、顧客がどのようにディスプレイと対話しているかを監視します。 顧客はどこに興味を持っているのか？どこに興味を持っていないのか？ どうすれば改善できるのか？ 私たちはその答えを見つけるお手伝いをします'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥5,250',
    overviewJP: 'ゾーン分析のためのローカルソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      'Requires Local Processor. Recommended for stores with multiple IP Cameras and measurement points.',
    descriptionJP:
      'ゾーン分析、IoT管理を含む、ローカルプロセッサが必要。複数のIPカメラや計測ポイントを持つ店舗におすすめです',
    catchCopy: '',
    catchCopyJP: 'ゾーン分析に必要なローカルソフトウェアをパッケージにしました'
  },
  {
    id: 'PSW_14',
    nameJP: '滞在人数計測　ローカルソフトウェアセット',
    name: 'Occupancy Monitoring - Local Software Set',
    link: 'https://share.hsforms.com/1kqnrIzShRM2WPCjt_Hem8w12tnr',
    icon: 'occupancy',
    hardwares: [
      {
        item: 'Occupancy Monitoring',
        hardwareCode: 'ADD_10',
        name: 'Occupancy Monitoring',
        nameJP: '滞在人数計測',
        icon: 'default',
        description:
          'Keep the density of your shops and spaces safe and track usage trends.',
        descriptionJP:
          'お店やスペースの密度を安全に保ち、利用傾向を把握しましょう。'
      },
      {
        item: 'Professional IoT Management',
        hardwareCode: 'SRV_4',
        name: 'Professional IoT Management',
        nameJP: 'IoT管理サービス',
        icon: 'default',
        description:
          'Flow deploys detailed monitoring alerts and AI data imputation algorithms to keep your devices online and delivering your critical data.',
        descriptionJP:
          '詳細についての監視アラートとAIデータ代入アルゴリズムを展開して、デバイスをオンラインに保ち、重要なデータを配信します。'
      }
    ],
    publishedDate: 'November 3',
    rate: '¥2,250',
    overviewJP: '混雑率分析ためのローカルソフトウェアパッケージ',
    support: 'https://www.flow-solutions.com/support',
    hardwareMaker: '',
    overview: '',
    softwareMaker: 'Flow Solutions',
    description:
      'Requires Local Processor. Recommended for stores with multiple IP Cameras and measurement points.',
    descriptionJP: '混雑率監視、IoT管理、クラウド処理を含む',
    catchCopy: '',
    catchCopyJP:
      '占有監視、IoT管理を含む。ローカルプロセッサが必要です。複数のIPカメラや計測ポイントを持つ店舗におすすめです'
  }
];

export const partnersRecommended = [];
export const packageIntegration = [
  {
    id: '1',
    name: 'TEC',
    nameJP: 'TEC ',
    hwMaker: '',
    swMaker: 'TEC',
    link: 'https://share.hsforms.com/1bRM6wzhSRg-5PR1IJHYHLA12tnr',
    catchCopyJP: '東芝テックPOSシステム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'tec',
    overviewEN: '',
    overviewJP:
      '東芝テックPOSシステムで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      '東芝テックPOSシステムで取得した売上、買上点数、取引件数データをデータプラットフォームFlowへ統合することをサポートします。'
  },
  {
    id: '2',
    name: 'NCR',
    nameJP: 'NCR ',
    hwMaker: '',
    swMaker: 'NCR',
    link: 'https://share.hsforms.com/1zpE1ruyATDeYePbJsKIilg12tnr',
    catchCopyJP: 'NCR POSシステム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'ncr',
    overviewEN: '',
    overviewJP:
      'NCR POSシステムで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'NCR POSシステムで取得した売上、買上点数、取引件数データをデータプラットフォームFlowへ統合することをサポートします。'
  },
  {
    id: '3',
    name: 'POS+ ',
    nameJP: 'POS+ ',
    hwMaker: '',
    swMaker: 'POS+',
    link: 'https://share.hsforms.com/1ukkdtq2KQLC_b9t_04RqkA12tnr',
    catchCopyJP: 'POS+ POSシステム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'POS+で取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'POS+ で取得した売上、買上点数、取引件数データをデータプラットフォームFlowへ統合することをサポートします。\n' +
      '＊開発中'
  },
  {
    id: '4',
    name: 'King of Time',
    nameJP: 'King of Time',
    hwMaker: '',
    swMaker: 'King of Time',
    link: 'https://share.hsforms.com/1h8GbQG4ORTWsqpWse17nAA12tnr',
    catchCopyJP: 'King of Time システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'King of Timeで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'King of Time で取得した勤怠データをデータプラットフォームFlowへ統合することをサポートします。\n' +
      'POSデータと連携することでスタッフのパーフォーマンスを分析することが可能になります。\n' +
      '＊開発中'
  },
  {
    id: '5',
    name: '勤怠＠WEB',
    nameJP: '勤怠＠WEB',
    hwMaker: '',
    swMaker: '勤怠＠WEB',
    link: 'https://share.hsforms.com/1_ftMGQnfRmSKoBZG5Rv6ZA12tnr',
    catchCopyJP: '勤怠＠WEB システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      '勤怠@WEBで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      '勤怠＠WEBで取得した勤怠データをデータプラットフォームFlowへ統合することをサポートします。\n' +
      'POSデータと連携することでスタッフのパーフォーマンスを分析することが可能になります。'
  },
  {
    id: '6',
    name: 'Brickstream',
    nameJP: 'Brickstream',
    hwMaker: '',
    swMaker: 'Brickstream',
    link: 'https://share.hsforms.com/1o19R6HiUQfyZ68QMYW1Xsg12tnr',
    catchCopyJP: 'Brickstream  システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'brickStream',
    overviewEN: '',
    overviewJP:
      'Brickstreamで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Brickstreamで取得した来店客数、レジ待ち人数などのデータをデータプラットフォームFlowへ統合することをサポートします。POSデータと組み合わせることで購買率を把握することが可能になります。'
  },
  {
    id: '7',
    name: 'Irisys',
    nameJP: 'Irisys',
    hwMaker: '',
    swMaker: 'Irisys',
    link: 'https://share.hsforms.com/11ucmDz46S8WEczW8k3PXRg12tnr',
    catchCopyJP: 'Irisys  システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'irisSystem',
    overviewEN: '',
    overviewJP:
      'Irisysで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Irisysで取得した来店客数データをデータプラットフォームFlowへ統合することをサポートします。POSデータと組み合わせることで購買率を把握することが可能になります。'
  },
  {
    id: '8',
    name: 'Datto',
    nameJP: 'Datto',
    hwMaker: '',
    swMaker: 'Datto',
    link: 'https://share.hsforms.com/1cyWGUHgPQlKhl7KU22tzNw12tnr',
    catchCopyJP: 'Datto システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'datto',
    overviewEN: '',
    overviewJP:
      'Dattoで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Datt で取得したスマートフォンなどのデバイスのアクティビティデータをデータプラットフォームFlowへ統合することをサポートします。統合することにより、店前通行客数、店内滞在人数、滞在時間を把握することが可能になります。'
  },
  {
    id: '9',
    name: 'Cisco Meraki',
    nameJP: 'Cisco Meraki',
    hwMaker: '',
    swMaker: 'Cisco Meraki',
    link: 'https://share.hsforms.com/1TH_yb0i-TUi8j35VsY9iZA12tnr',
    catchCopyJP: 'Cisco Meraki システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'Cisco Merakiで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Cisco Meraki で取得したスマートフォンなどのデバイスのアクティビティデータをデータプラットフォームFlowへ統合することをサポートします。統合することにより、店前通行客数、店内滞在人数、滞在時間を把握することが可能になります。'
  },
  {
    id: '10',
    name: 'Line Works',
    nameJP: 'Line Works',
    hwMaker: '',
    swMaker: 'Line Works',
    link: 'https://share.hsforms.com/1nIOZwqJRQR2xRr0Ho4FJqg12tnr',
    catchCopyJP: 'Line Works システム連携',
    publishedDate: 'November 3',
    price: '¥100',
    icon: 'default',
    overviewEN: '',
    overviewJP: 'Line WorksとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Line Works をデータプラットフォームFlowへ統合することをサポートします。統合することにより、Flowで設定した設定条件に応じてアラートをLine Works へ送信することが可能になります。'
  },
  {
    id: '11',
    name: 'Cisco DNA Spaces',
    nameJP: 'Cisco DNA Spaces',
    hwMaker: '',
    swMaker: 'Cisco DNA Spaces',
    link: 'https://share.hsforms.com/1rZzNFsmASMySKbSaUlt4fQ12tnr',
    catchCopyJP: 'Cisco DNA Spaces システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP: 'Cisco DNA SpacesとプラットフォームFlowの連携をサポートします',
    description: '',
    descriptionJP:
      'Cisco DNA Spaces上でFlowに統合したデータを表示することが可能になります。\n' +
      '＊開発中'
  },
  {
    id: '12',
    name: 'Fortinet',
    nameJP: 'Fortinet',
    hwMaker: '',
    swMaker: 'Fortinet',
    link: 'https://share.hsforms.com/10f3tDStsTlOvB5U9Ne2Jwg12tnr',
    catchCopyJP: 'Fortinet システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'fortinet',
    overviewEN: '',
    overviewJP:
      'FortinetのデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Fortinet で取得したスマートフォンなどのデバイスのアクティビティデータをデータプラットフォームFlowへ統合することをサポートします。\n' +
      '＊開発中'
  },
  {
    id: '13',
    name: 'Aruba',
    nameJP: 'Aruba',
    hwMaker: '',
    swMaker: 'Aruba',
    link: 'https://share.hsforms.com/1bC_27kIcRSCwyKWAGMSBnw12tnr',
    catchCopyJP: 'Aruba システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'aruba',
    overviewEN: '',
    overviewJP:
      'Arubaで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      "      'Cisco Meraki で取得したスマートフォンなどのデバイスのアクティビティデータをデータプラットフォームFlowへ統合することをサポートします。統合することにより、店前通行客数、店内滞在人数、滞在時間を把握することが可能になります。'\n"
  },
  {
    id: '14',
    name: 'Google Adwords',
    nameJP: 'Google Adwords',
    hwMaker: '',
    swMaker: 'Google Adwords',
    link: 'https://share.hsforms.com/1gnlW2ZCMRjCO1UI5X4wegw12tnr',
    catchCopyJP: 'Google Adwords システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'Google Adwordsで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Google Adwordsで取得したデータとプラットフォームFlowとの連携をサポートします。\n' +
      '＊開発中'
  },
  {
    id: '15',
    name: 'Google Analytics',
    nameJP: 'Google Analytics',
    hwMaker: '',
    swMaker: 'Google Analytics',
    link: 'https://share.hsforms.com/1CU8dLBUTSRal572df1L34g12tnr',
    catchCopyJP: 'Google Analytics システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'Google Analyticsで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Google Analyticsで取得したデータとプラットフォームFlowとの連携をサポートします\n' +
      '＊開発中'
  },
  {
    id: '16',
    name: 'Facebook Campaigns',
    nameJP: 'Facebook Campaigns',
    hwMaker: '',
    swMaker: 'Facebook Campaigns',
    link: 'https://share.hsforms.com/1umeSQ6iLTjKsGtCgDZBDVw12tnr',
    catchCopyJP: 'Facebook システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'Facebookで取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Facebookで取得したデータとプラットフォームFlowとの連携をサポートします\n' +
      '＊開発中'
  },
  {
    id: '17',
    name: 'SalesForce',
    nameJP: 'SalesForce',
    hwMaker: '',
    swMaker: 'SalesForce',
    link: 'https://share.hsforms.com/1uJjkegdHTIWTjDBXVLGXcQ12tnr',
    catchCopyJP: 'Sales Force システム連携',
    publishedDate: 'November 3',
    price: '¥0',
    icon: 'default',
    overviewEN: '',
    overviewJP:
      'SalesForce で取得したデータとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'SalesForce で取得したデータとプラットフォームFlowとの連携をサポートします\n' +
      '＊開発中'
  },
  {
    id: '18',
    name: 'Google Chat',
    nameJP: 'Google Chat',
    hwMaker: '',
    swMaker: 'Google Chat',
    link: 'https://share.hsforms.com/1tNFYLLnwTgGQHPO_5DhDJg12tnr',
    catchCopyJP: 'Google chat システム連携',
    publishedDate: 'November 3',
    price: '¥100',
    icon: 'default',
    overviewEN: '',
    overviewJP: 'Google chatとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'Google chatとプラットフォームFlowとの連携をサポートします。\n' +
      '統合することにより、Flowで設定した設定条件に応じてアラートをLine Works へ送信することが可能になります。\n' +
      '＊開発中'
  },
  {
    id: '19',
    name: 'WebEx Chat Integration',
    nameJP: 'WebEx Chat',
    hwMaker: '',
    swMaker: '',
    link: 'https://share.hsforms.com/12DvNjGaBT6medwQjjc74ug12tnr',
    catchCopyJP: 'WebEx Chat システム連携',
    publishedDate: 'November 3',
    price: '¥100',
    icon: 'default',
    overviewEN: '',
    overviewJP: 'WebExとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'WebExとプラットフォームFlowとの連携をサポートします。\n' + '＊開発中'
  },
  {
    id: '20',
    name: 'Slack Integration',
    nameJP: 'Slack',
    hwMaker: '',
    swMaker: '',
    link: 'https://share.hsforms.com/1RJHeg3z-R5akoad0QsU1_Q12tnr',
    catchCopyJP: 'Slack システム連携',
    publishedDate: 'November 3',
    price: '¥100',
    icon: 'default',
    overviewEN: '',
    overviewJP: 'SlackとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'SlackとプラットフォームFlowとの連携をサポートします。\n' + '＊開発中'
  },
  {
    id: '21',
    name: 'OpenWeather',
    nameJP: 'OpenWeather',
    hwMaker: '',
    swMaker: 'OpenWeather',
    link: 'https://share.hsforms.com/1dYDOBJGjTNOkQSaUtIQXBA12tnr',
    catchCopyJP: 'Open Weather システム連携',
    publishedDate: 'November 3',
    price: '',
    icon: 'openWeather',
    overviewEN: '',
    overviewJP: 'OpenWeatherとプラットフォームFlowとの連携をサポートします',
    description: '',
    descriptionJP:
      'OpenWeatherとプラットフォームFlowとの連携をサポートします。統合することにより、天気データをFlow上に表示することが可能です。'
  }
];
