import Sensor, { SensorDTO } from '../Sensor';

export const convertFromSensorDTO = (dto: SensorDTO): Sensor => ({
  id: dto.id,
  name: dto.name,
  price: dto.price,
  currency: 'JPY',
  image: dto.image,
  description: dto.description
});
