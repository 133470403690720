import clientManager from '@/util/client';
import { createBackendError } from '@/util/error';
import localForage from 'localforage';
import Vue from 'vue';
import store from '@/store';

function coalesce(value: any, ifNull: any) {
  return value != null ? value : ifNull;
}

const client = clientManager.consoleClient;

const initialState = () => ({
  id: null,
  newAccount: null,
  createStoreCompletion: null,
  resetAccountCompletion: null,
  subscribePlanCompletion: null,
  companyName: ''
});

const state = initialState();

const getters = {
  tenantId: state => state.id,
  isNewAccount: state => coalesce(state.newAccount, true),
  hasResetAccount: state => coalesce(state.resetAccountCompletion, false),
  hasCreatedPlan: state => coalesce(state.createStoreCompetion, false),
  hasSubscribedPlan: state => coalesce(state.subscribePlanCompletion, false),
  config: state => ({ ...state })
};

const actions = {
  initialize: async ({ commit }) => {
    try {
      const { tenant } = await localForage.getItem('session-tenant');
      const {
        data: { tenantConfig, companyName }
      } = await client.get(`/console/tenants/name=${tenant}`);
      commit('updateConfig', { ...tenantConfig, companyName });
    } catch (err) {
      createBackendError(err);
    }
  },
  reset: ({ commit }) => {
    commit('updateConfig', initialState());
  },
  resetAccount: async ({ commit, getters }) => {
    try {
      const change = {
        resetAccountCompletion: true
      };
      await client.put(`/console/tenant-config/${getters.tenantId}`, {
        ...getters.config,
        ...change
      });

      const gateway = clientManager.apiGatewayClient;
      await gateway.get('/general/clean-data');

      commit('updateConfig', change);
      store.commit('user/setSession', store.getters['user/getClientSession']);
    } catch (err) {
      throw createBackendError(err);
    }
  },
  markPlanSubscribed: async ({ commit, getters }) => {
    try {
      const change = {
        subscribePlanCompletion: true
      };
      await client.put(`/console/tenant-config/${getters.tenantId}`, {
        ...getters.config,
        ...change
      });

      commit('updateConfig', change);
    } catch (err) {
      throw createBackendError(err);
    }
  },
  markStoreCreated: async ({ commit, getters }) => {
    try {
      const change = {
        createStoreCompletion: true
      };
      await client.put(`/console/tenant-config/${getters.tenantId}`, {
        ...getters.config,
        ...change
      });

      commit('updateConfig', change);
    } catch (err) {
      throw createBackendError(err);
    }
  }
};

const mutations = {
  updateConfig(state, payload) {
    Object.keys(payload).forEach(key => {
      if (!Object.keys(state).includes(key)) return;
      Vue.set(state, key, payload[key]);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
