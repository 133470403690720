import { APIFetchStoresFidaApi } from '@/api/store';
export async function APIFetchStoresFida(input) {

  if(input == 'storesOnly')
  {
    const responseNew =  await APIFetchStoresFidaApi();
    const response = convertApiResponseToFidaStoresOnly(responseNew.data);
    return response;
  }
  else
  {
    const responseNew =  await APIFetchStoresFidaApi();
    const response = convertApiResponseToFida(responseNew.data);
    return response;
  }
}

interface Store {
  id: string;
  name: string;
  storeCode: string;
  longitude: string | null;
  latitude: string | null;
  area: string | null;
  storeTimings: []|null;
  entrances: [] | null;
}

interface AreaManager {
  id: string;
  name: string;
  stores: Store[];
  store: any[]; // Placeholder for missing "store" property in the provided example
}
interface StoreList {
  stores: Store[];
}
interface District {
  id: string;
  name: string;
  stores: Store[]; // Placeholder for missing "stores" property in the provided example
  store: any[]; // Placeholder for missing "store" property in the provided example
}

interface Region {
  id: string;
  name: string;
  districts: District[];
}

interface PeerGroup {
  id: string;
  name: string;
  stores: Store[];
  store: any[]; // Placeholder for missing "store" property in the provided example
}

interface ApiResponse {
  data: {
      areaManagers: AreaManager[];
      regions: Region[];
      stores: Store[];
      peerGroups: PeerGroup[];
      dataAccessStores ?: Store[]
      myStores ?: Store[]
      // myStores": [],
      //   "dataAccessStores": [],
      //   "myPeerGroups": [],
      //   "myAreaManagers": []
  };
  description: string;
  status: string;
}

function convertApiResponseToFida(apiResponse: any): ApiResponse {

const combinedStores = apiResponse.response.myStores.filter(t1=>t1.status === 0).concat(apiResponse.response.dataAccessStores.filter(t=>t.status === 0));
// console.log(combinedStores, 'co')
  // Use a Set to store distinct values based on storeNumber
const distinctStores = Array.from(new Set(combinedStores.map(store => store.storeNumber)));

// Create a new array with distinct stores
const finalStoreList = distinctStores.map(storeNumber => {
  return combinedStores.find(store => store.storeNumber === storeNumber);
});

  const stores: Store[] = finalStoreList.map((store: any) => ({
    id: store._id,
    name: store.title,
    storeCode: store.storeNumber,
    cityId: store.cityId,
    longitude: null,
    latitude: null,
    area: store.area,
    storeTimings: store.storeTimings,
    entrances: store.entrances
}));

let areaManagers: any;
if(apiResponse.response.myAreaManagers.length == 0)
{
  areaManagers = [];
}
else
{
  areaManagers = apiResponse.response.myAreaManagers.map((manager: any) => ({
      id: manager._id,
      name: manager.title,
      stores: manager.storeCodes.map((storeCode: string) => {
          const store = finalStoreList.find((store: any) => store.storeNumber === storeCode);


          if (store) {
          return {
              id: store._id,
              name: store.title,
              storeCode: store.storeNumber,
              longitude: null,
              latitude: null,
              area:store.area,
              storeTimings: store.storeTimings,
              entrances: store.entrances
          };
        }
        else {
          return null;
        }
      }).filter(store => store !== null),
      store: []
  }));
}

  let peerGroups: any;
  if(!apiResponse.response.myPeerGroups.find(x=>x.type =="peerGroup"))
  {
    peerGroups = [];
  }
  else
  {
    peerGroups = apiResponse.response.myPeerGroups
    .filter((peerGroup: any) => peerGroup.type === 'peerGroup')
    .map((peerGroup: any) => ({
        id: peerGroup._id,
        name: peerGroup.title,
        order: peerGroup.order,
        stores: peerGroup.storeCodes.map((storeCode: string) => {
              const store = finalStoreList.find((store: any) => store.storeNumber === storeCode);
              if(store)
              return {
                  id: store._id,
                  name: store.title,
                  storeCode: store.storeNumber,
                  longitude: null,
                  latitude: null,
                  area:store.area,
                 // order: store.order,
                  storeTimings: store.storeTimings,
                  entrances: store.entrances
              };
              else
              return null;
          }).filter(store => store !== null),
          store: []
      }));
  }

  const regions = getRegions(finalStoreList);

  return {
      data: {
          areaManagers,
          regions,
          stores,
          peerGroups,
          dataAccessStores: apiResponse?.response?.dataAccessStores || [],
          myStores: apiResponse?.response?.myStores || []
      },
      description: apiResponse.message,
      status: apiResponse.transKey
  };

}

function convertApiResponseToFidaStoresOnly(apiResponse: any): StoreList {
  let combinedStores
  if (apiResponse && apiResponse.response && apiResponse.response.myStores && apiResponse.response.dataAccessStores)
  {
    combinedStores = apiResponse.response.myStores.filter(t1=>t1.status === 0).concat(apiResponse.response.dataAccessStores.filter(t1=>t1.status === 0));
  }
  else if (apiResponse.response.myStores)
  {
    combinedStores = apiResponse.response.myStores.filter(t1=>t1.status === 0);
  }
  else if(apiResponse.response.dataAccessStores)
  {
    combinedStores = apiResponse.response.dataAccessStores.filter(t1=>t1.status === 0);
  }
  else
  combinedStores = [];
  const stores: Store[] = combinedStores.map((store: any) => ({
    id: store._id,
    cityId: store.cityId,
    name: store.title,
    storeCode: store.storeNumber,
    longitude: null,
    latitude: null,
    area:store.area,
    storeTimings: store.storeTimings,
    entrances: store.entrances
}));

  return {stores};

}

function getRegions(apiResponse) {
  const result = {};
  apiResponse.forEach((store) => {
    if(store.locationDetails)
    {
    const { locationDetails } = store;
    const { region, district, prefecture } = locationDetails;

    if (!result[region]) {
      result[region] = {};
    }
    if (!result[region][district]) {
      result[region][district] = [];
    }

    const mappedStore = {
      id: store._id,
      name: store.title,
      storeCode: store.storeNumber,
      longitude: null,
      latitude: null,
      area:store.area,
      storeTimings: store.storeTimings,
      entrances: store.entrances
    };

    result[region][district].push(mappedStore);
  }
  });

  const finalResult = [];

  for (const region in result) {
    const districts = [];
    for (const district in result[region]) {
      districts.push({
        id: '1', // You can set this id as needed
        name: district,
        stores: result[region][district],
        store: [],
      });
    }
    finalResult.push({
      id: '1', // You can set this id as needed
      name: region,
      districts,
    });
  }

  return finalResult;
}

