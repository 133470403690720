
import { mapState } from 'vuex';

export default {
  name: 'KPITrafficTarget',
  props: {
    flat: {
      type: Boolean
    }
  },
  computed: {
    ...mapState('overview', {
      data: 'traffic'
    })
  } // computed
}; // export default
