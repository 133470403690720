
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['getLocale'])
  }, // computed
  methods: {
    ...mapMutations('user', ['setLocale']),
    ...mapActions('user', ['clearSession']),
    signout() {
      this.clearSession();
      this.$router.push({ name: 'auth:login' }).catch(_ => {});
    }, // signout
    setupLocale(locale) {
      this.setLocale({ locale });
    } // setupLocale
  } // methods
}; // export default
