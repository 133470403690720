const groupNames = [
  {
    "DGGroupID": 1,
    "DGGroup": "Male-under 10's",
    "DGGroup_JP": "男性-10代",
    "DGGroup_EN": "Male-under 10's",
    "DGGroupDisplayColor": "#43C1E3"
  },
  {
    "DGGroupID": 2,
    "DGGroup": "Male-20's",
    "DGGroup_JP": "男性-20代",
    "DGGroup_EN": "Male-20's",
    "DGGroupDisplayColor": "#3897AD"
  },
  {
    "DGGroupID": 3,
    "DGGroup": "Male-30/40's",
    "DGGroup_JP": "男性-30/40代",
    "DGGroup_EN": "Male-30/40's",
    "DGGroupDisplayColor": "#234A63"
  },
  {
    "DGGroupID": 4,
    "DGGroup": "Male-over 50's",
    "DGGroup_JP": "男性-50代以上",
    "DGGroup_EN": "Male-over 50's",
    "DGGroupDisplayColor": "#272949"
  },
  {
    "DGGroupID": 5,
    "DGGroup": "Female-under 10's",
    "DGGroup_JP": "女性-10代",
    "DGGroup_EN": "Female-under 10's",
    "DGGroupDisplayColor": "#F280B3"
  },
  {
    "DGGroupID": 6,
    "DGGroup": "Female-20's",
    "DGGroup_JP": "女性-20代",
    "DGGroup_EN": "Female-20's",
    "DGGroupDisplayColor": "#CD5A93"
  },
  {
    "DGGroupID": 7,
    "DGGroup": "Female-30/40's",
    "DGGroup_JP": "女性-30/40代",
    "DGGroup_EN": "Female-30/40's",
    "DGGroupDisplayColor": "#AA4B80"
  },
  {
    "DGGroupID": 8,
    "DGGroup": "Female-over 50's",
    "DGGroup_JP": "女性-50代以上",
    "DGGroup_EN": "Female-over 50's",
    "DGGroupDisplayColor": "#6D3152"
  }
];

export const groupNamesList = groupNames;
