import TaxController from '@/services/management/TaxController';
import { ClientManager } from '@/util/client';
import CustomerController from './CustomerController';
import MenuController from './MenuController';
import PaymentController from './PaymentController';
import PlanController from './PlanController';
import ProductController from './ProductController';
import RoleController from './RoleController';
import SensorController from './SensorController';
import StoreController from './StoreController';
import UserController from './UserController';

export interface ManagementControllers {
  store: StoreController;
  user: UserController;
  role: RoleController;
  plan: PlanController;
  sensor: SensorController;
  payment: PaymentController;
  menu: MenuController;
  tax: TaxController;
  customer: CustomerController;
  product: ProductController;
}

export default (clientManager: ClientManager): ManagementControllers => ({
  store: new StoreController(clientManager.coreClient),
  user: new UserController(clientManager.apiGatewayClient),
  role: new RoleController(clientManager.apiGatewayClient),
  plan: new PlanController(clientManager.consoleClient),
  sensor: new SensorController(clientManager.consoleClient),
  payment: new PaymentController(clientManager.consoleClient),
  menu: new MenuController(clientManager.apiGatewayClient),
  tax: new TaxController(clientManager.consoleClient),
  customer: new CustomerController(clientManager.consoleClient),
  product: new ProductController(clientManager.consoleClient)
});
