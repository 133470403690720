
import stores from "@/services/stores";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedStore"],
  // props: {
  //   selectedStore: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
      configuration: true,
      storeName: "",
      description: "",
      title: "",
    };
  },
 watch: {
    configuration(newValue){
      if(!newValue){
        this.$emit("closeModel", false);
      }
    }
  },
  mounted() {
    if (this.selectedStore) {
      this.title =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.store;
      this.storeName = this.selectedStore.title;
      this.description = this.selectedStore.description;
    } else {
      this.title =
        this.$store.state.user.translate.add +
        " " +
        this.$store.state.user.translate.store;
    }
  },
  methods: {
    createStore() {
      if (this.selectedStore) {
        let storeData = {
          title: this.storeName,
          description: this.description,
          typeId: this.selectedStore._id,
        };
        stores
          .updateStoreType(storeData)
          .then(() => {
            this.$q.notify({
              message: this.$store.state.user.translate.updated_successfully,
              color: "green",
            });
            this.$emit("updateStores");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.$emit("closeModel", false);
          });
      } else {
        let storeType = [];
        let storeData = {
          title: this.storeName,
          description: this.description,
        };
        storeType.push(storeData);
        stores
          .createStoreType(storeType)
          .then(() => {
            this.$q.notify({
              message: "Store Created Successfully",
              color: "green",
            });
            this.$emit("updateStores");
          })
          .catch((error) => {
            // console.log(error);
            if (error.response.data.message) {
              this.$q.notify({
                message: error.response.data.message,
                color: "red",
              });
            } else {
              this.$q.notify({
                message: this.$store.state.user.translate.something_went_wrong,
                color: "red",
              });
            }
            this.$emit("closeModel", false);
          });
      }
    },
  },
};
