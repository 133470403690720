import TaxRepository from './TaxRepository';
import services from '@/services';
import AuthRepository from './AuthRepository';
import BrandRepository from './BrandRepository';
import CoreRepository from './CoreRepository';
import CustomerRepository from './CustomerRepository';
import ExtensionRepository from './ExtensionRepository';
import LocationRepository from './LocationRepository';
import LogRepository from './LogRepository';
import OnboardingRepository from './OnboardingRepository';
import OrderRepository from './OrderRepository';
import PaymentRepository from './PaymentRepository';
import PeerGroupRepository from './PeerGroupRepository';
import AreaManagerRepository from './AreaManagerRepository';
import PlanRepository from './PlanRepository';
import ProductRepository from './ProductRepository';
import PlanOrderRepository from './PlanOrderRepository';
import RoleRepository from './RoleRepository';
import SalesRepository from './SalesRepository';
import SensorRepository from './SensorRepository';
import SessionRepository from './SessionRepository';
import SettingsRepository from './SettingsRepository';
import StoreRepository from './StoreRepository';
import UserRepository from './UserRepository';
import BillingRepository from './BillingRepository';

export interface StaticRepositories {
  session: SessionRepository;
  location: LocationRepository;
  auth: AuthRepository;

  // Core
  core: CoreRepository;
  onboarding: OnboardingRepository;
  sales: SalesRepository;
  brand: BrandRepository;
  tax: TaxRepository;

  // Management
  plan: PlanRepository;
  payment: PaymentRepository;
  store: StoreRepository;
  sensor: SensorRepository;
  user: UserRepository;
  role: RoleRepository;
  product: ProductRepository;
  customer: CustomerRepository;

  // Tools
  log: LogRepository;
  billing: BillingRepository;
  extension: ExtensionRepository;

  // Settings
  settings: SettingsRepository;
  peerGroup: PeerGroupRepository;
  areaManager: AreaManagerRepository;

  // Transactional
  order: OrderRepository;
  planOrder: PlanOrderRepository;
}

export type Repositories = StaticRepositories;

const userRepository = new UserRepository(
  services.management.user,
  services.management.menu
);

const sessionRepository = new SessionRepository(userRepository);

export default {
  session: sessionRepository,
  location: new LocationRepository(services.location),
  auth: new AuthRepository(
    services.account.auth,
    services.account.login,
    services.account.tenant,
    services.management.customer,
    services.management.user
  ),

  core: new CoreRepository(services.core),
  onboarding: new OnboardingRepository(
    services.core,
    services.onboarding,
    services.account.tenant
  ),
  sales: new SalesRepository(services.core.sales),
  brand: new BrandRepository(services.core.brand),
  tax: new TaxRepository(services.management.tax),

  plan: new PlanRepository(
    services.management.plan,
    services.account.tenant,
    services.account.billing
  ),
  payment: new PaymentRepository(
    services.management.payment,
    services.account.tenant
  ),
  store: new StoreRepository(
    services.management.store,
    services.core,
    services.management.plan
  ),
  sensor: new SensorRepository(services.management.sensor),
  user: userRepository,
  role: new RoleRepository(services.management.role),
  product: new ProductRepository(services.management.product),
  customer: new CustomerRepository(services.management.customer),

  log: new LogRepository(services.tools.log),
  billing: new BillingRepository(
    services.account.billing,
    services.account.tenant
  ),
  extension: new ExtensionRepository(
    services.tools.ftp,
    services.tools.salesTemplate
  ),

  settings: new SettingsRepository(
    services.core.company,
    services.core.defaultSettings
  ),
  peerGroup: new PeerGroupRepository(services.settings.peerGroup),
  areaManager: new AreaManagerRepository(services.settings.areaManager),

  order: new OrderRepository(
    services.transactional.order,
    services.account.tenant,
    sessionRepository
  ),
  planOrder: new PlanOrderRepository(
    services.transactional.planOrder,
    services.account.tenant
  )
} as Repositories;
