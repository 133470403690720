
import CommonMixin from '@/mixins/CommonMixin';
import DrawerMixin from '@/components/Common/Mixins/DrawerMixin';
import LocationFormMixin from '@/mixins/LocationFormMixin';

const initialFilter = () => ({
  name: null,
  storeNumber: null,
  regionId: null,
  districtId: null,
  prefectureId: null,
  cityId: null,
  status: null
});

export default {
  name: 'FilterStoreDrawer',
  mixins: [CommonMixin, DrawerMixin, LocationFormMixin],
  props: {
    value: {
      type: Object,
      default: () => initialFilter()
    }
  },
  data() {
    return {
      filter: this.value,
      provideNullSelection: true,
      statusOptions: [
        {
          label: '(None)',
          value: null
        },
        {
          label: 'Operating',
          value: 'OPERATING'
        },
        {
          label: 'Inactive',
          value: 'INACTIVE'
        }
      ]
    };
  },
  methods: {
    applyFilter() {
      this.$emit('input', this.filter);
      this.closeDrawer();
    },
    updateFilter(update = {}) {
      this.filter = {
        ...this.filter,
        ...update
      };
    },
    getUpdatedLocationFilter() {
      return {
        regionId: this.selectedRegion || null,
        districtId: this.selectedDistrict || null,
        prefectureId: this.selectedPrefecture || null,
        cityId: this.selectedCity || null
      };
    },
    resetData() {
      this.updateFilter(initialFilter());
      this.applyFilter();
    }
  }
};
