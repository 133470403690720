import { render, staticRenderFns } from "./AddForm.vue?vue&type=template&id=2b9571c4&"
import script from "./AddForm.vue?vue&type=script&lang=js&"
export * from "./AddForm.vue?vue&type=script&lang=js&"
import style0 from "./AddForm.vue?vue&type=style&index=0&id=2b9571c4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QForm,QInnerLoading,QSpinnerTail,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QInnerLoading,QSpinnerTail,QField})
