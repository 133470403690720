import axios from "axios";
import { SSOPostRequest, SSOPostRequest2, SSOPutRequest, SSOGetRequest, SSOGetRequest2, SSOPostReq, SSODeleteRequest } from '@/util/kpis';
import store from '@/store';

export default {

  authenticate(email, password) {
    let client_id = store.state?.clientId
    let client_secret = store.state?.clientSecret
    let userData = {
      "client_id": client_id,
      "client_secret": client_secret,
      "email": email,
      "password": password,
    };
    return axios.post('http://153.156.163.115:9000/api/authenticate', userData, {})
  },
  async getCounter() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
    }
    const apiResponse = await SSOGetRequest(
      '/api/getUsersCount',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
    // let client_id =  store.state?.clientId
    // let client_secret = store.state?.clientSecret
    // return axios.get('https://sso-stg.flow-insight.com/api/getUsersCount?client_id=aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',{
    //   headers: {
    //     Authorization:
    //       "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg"
    //   },
    // })
  },
  async getUsers(params='') {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      // skip,
    }
    if(Object.keys(params).length){
      paramsData['pageNumber'] = params.pageNumber
      paramsData['perPage'] = params.perPage
      paramsData['searchText'] = params.searchText
      paramsData['sortDesc'] = 'createdAt'
    }
    const apiResponse = await SSOGetRequest(
      '/api/getUsers',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },

  // getUserDetails() {
  //   return axios.get('https://sso-stg.flow-insight.com/api/getUserDetails?client_id=aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',{
  //     headers: {
  //       Authorization:
  //         "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg"
  //     },
  //   })
  // },
  async getUserDetails() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
    }
    const apiResponse = await SSOGetRequest(
      '/api/getUserDetails',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async deleteUser(userId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let roleData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "_id": userId
    };
    const apiResponse = await SSODeleteRequest(
      '/api/deleteUser', 
      {
        data:roleData,
        // params: roleData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async getIndustries() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,

    }
    const apiResponse = await SSOGetRequest(
      '/api/getIndustries',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // getIndustries() {
  //   return axios.get('https://sso-stg.flow-insight.com/api/getIndustries?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg"
  //     },
  //   })
  // },
  async getDefaultSettings() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,

    }
    const apiResponse = await SSOGetRequest(
      '/api/getDefaultSettings',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // getDefaultSettings() {
  //   return axios.get('https://sso-stg.flow-insight.com/api/getDefaultSettings?client_id='+store.state?.clientId+'&client_secret='+store.state?.clientSecret,{
  //     headers: {
  //       Authorization:
  //         "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg"
  //     },
  //   })
  // },

  async updateUserProfile(userDetails) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "firstName": userDetails.firstName,
      "lastName": userDetails.lastName,
      "username": userDetails.username,
      "mobile": userDetails.mobile,
      "emailInfo": userDetails.emailInfo,
      "roleId": userDetails.roleId,
      "_id": userDetails.id
    };
    const apiResponse = await SSOPutRequest(
      '/api/updateUserProfile', userData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
    // return axios.put('https://sso-stg.flow-insight.com/api/updateUserProfile', userData, {
    //   headers: {
    //     Authorization:
    //       "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg",
    //   },
    // })
  },
  async updateUserPassword(userDetails) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "newPassword":userDetails.password,
      "newConfirmPassword":userDetails.newConfirmPassword,
      "_id": userDetails.id
    };
    const apiResponse = await SSOPutRequest(
      '/api/updateUserPassword', userData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
    // return axios.put('https://sso-stg.flow-insight.com/api/updateUserProfile', userData, {
    //   headers: {
    //     Authorization:
    //       "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg",
    //   },
    // })
  },

  // setUserLocations(details) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let userData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "_id": details.userId,
  //     "locations": details.locations,
  //   };
  //   return axios.post('https://fida-stg.flow-insight.com/api/setUserLocations', userData, {
  //     headers: {
  //       Authorization:
  //         "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg",
  //     },
  //   })
  // },
  async setUserLocations(details) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "_id": details.userId,
      "locations": details.locations,
    };
    const apiResponse = await SSOPostRequest2(
      '/api/setUserLocations', userData,
      {
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // getUserLocations(userId) {
  //   const paramsData = {
  //     client_id: store.state?.clientId,
  //     client_secret: store.state?.clientSecret,
  //     _id: userId
  //   };
  //   return axios.get('https://fida-stg.flow-insight.com/api/getUserLocations', {
  //     params: paramsData,
  //     headers: {
  //       Authorization:
  //         "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg",
  //     },
  //   })
  // },
  async getUserLocations(userId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      _id: userId
    }
    const apiResponse = await SSOGetRequest2(
      '/api/getUserLocations',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // addUser(userDetails) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   var userData = {
  //     "usersArray": userDetails,
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "platform": "flowgo"
  //   };
  //   return axios.post('https://sso-stg.flow-insight.com/api/importUsers', userData, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization:
  //       "BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ0ekpFTlNxcDJXeXh4YlNGbSIsImFjY2Vzc1Rva2VuRXhwIjp7ImRhdGUiOiIyMDIzLTA2LTAzIiwidGltZSI6IjAzOjMzOjIxIiwidGltZVN0YW1wIjoxNjg1NzMwODAxMzcxLCJnbXQiOiIrMDkwMCJ9LCJyZWZyZXNoVG9rZW5FeHAiOnsiZGF0ZSI6IjIwMjMtMDYtMDMiLCJ0aW1lIjoiMDM6MzM6MjEiLCJ0aW1lU3RhbXAiOjE2ODU3MzA4MDEzNzEsImdtdCI6IiswOTAwIn0sImlhdCI6MTY4MzEzODgwMSwiZXhwIjoxNjgzMTM4ODYxfQ.Ux0l4F22oZQEhLNjpZjngbRqHgkUsjFz6C0Or7NGBXg"
  //     }
  //   })
  // }
  async addUser(userDetails) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "usersArray": userDetails,
      "platform": "flowgo"

    };
    const apiResponse = await SSOPostReq(
      '/api/importUsers',
      userData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
}