
import { handleErrorUI } from '@/util/error';
import config from '@/config';
import CommonMixin from '@/mixins/CommonMixin';
import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeElementClasses
} from '@stripe/stripe-js';

export default {
  mixins: [CommonMixin],
  props: {
    value: { type: Boolean, default: false }
  },
  data() {
    return {
      stripe: null,
      cardField: null,
      cvcField: null,
      expiryField: null,
      clientSecret: '',
      cardholderName: '',
      postalCode: '',
      billingAddress: '',
      ready: false,
      submitting: false
    };
  },
  watch: {
    value(value) {
      if (value) {
        this.ready = false;
        this.mountStripeElements();
      }
    }
  },
  async mounted() {
    this.ready = false;
    this.submitting = false;
    this.stripe = await loadStripe(config.stripePublishableKey);
  },
  methods: {
    mountStripeElements() {
      const elementStyles = {
        invalid: {
          color: this.$style.negative
        }
      };
      const elementClasses = {
        focus: this.$style['field-focus'],
        invalid: this.$style['field-invalid']
      } as StripeElementClasses;

      const elements = this.stripe.elements();
      this.cardField = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses
      });
      this.cvcField = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses
      });
      this.expiryField = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses
      });

      this.$nextTick(() => {
        this.cardField.mount(this.$refs['card-number'] as HTMLElement);
        this.cvcField.mount(this.$refs['card-cvc'] as HTMLElement);
        this.expiryField.mount(this.$refs['card-expiry'] as HTMLElement);
      });

      this.cardField.on('ready', () => {
        this.ready = true;
      });
    },
    async addPaymentMethod() {
      if (!this.ready || this.submitting) return;

      try {
        const { tenant } = await this.$repo.session.fetch();
        this.clientSecret = await this.$repo.payment.createSetupIntent(tenant);
      } catch (err) {
        handleErrorUI(err);
      }

      this.submitting = true;
      const result = await this.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardField,
          billing_details: {
            name: this.cardholderName,
            address: {
              line1: this.billingAddress,
              postal_code: this.postalCode
            }
          }
        }
      });
      this.submitting = false;

      if (result.error != null) {
        const errorMessage = result.error.message;
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: errorMessage
        });
        return;
      }

      if (result.setupIntent != null) {
        this.$q.notify({
          icon: 'fas fa-check',
          type: 'positive',
          position: 'top',
          message: this.$t('PaymentMethod.PaymentMethodSaveSuccess')
        });
        this.$emit('close');
      }
    }
  }
};
