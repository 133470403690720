
export default {
  data() {
    return {
      series: [
        {
          name: 'Inflation',
          data: [25, 22, 20]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function(val) {
            return '$' + val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },

        xaxis: {
          categories: ['Hats', 'Purse', 'Socks'],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          tickAmount: 3,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function(val) {
              return '$' + val;
            }
          }
        }
      }
    };
  }
};
