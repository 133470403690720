

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    successMessage: { type: String, default: 'Data imported successfully' }
  },
  data() {
    return {
      columns: [
        { label: "Row", name: "row" },
        { label: "Description", name: "description"},
      ],
      pagination: {
        rowsPerPage: 10,
      },
    };
  },
  mounted() {
    // console.log(this.data)
  }
};
