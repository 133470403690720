
import { mapState } from "vuex";
export default {
  props: {
    weeklyParam: Boolean
  },
  data() {
    return {
      filterFormStateData: {},
    };
  },
  computed: {
    ...mapState("periodicReport", ["filterFormState"]),
  },
  watch: {
    filterFormState(newValue) {
      this.filterFormStateData = newValue;
    },
  },
  created() {
    this.filterFormStateData = this.filterFormState;
  },
  methods: {},
};
