
import Vue from "vue";
import { mapGetters, mapActions } from 'vuex';
import en from 'apexcharts/dist/locales/en.json';
import ja from 'apexcharts/dist/locales/ja.json';
// import resources from '@/services/resources.js'
import {getLocaleMehod, getLocaleMethodKpi} from '@/api/locale';
import store from '@/store/';
import ProfileSurvey from "@/components/modals/SurveyModel.vue"
export default {
  components: { ProfileSurvey },
  data() {
    return {
      forcedAPIVersion: 1,
      canShowSurvey: false
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale', 'isAuthenticated'])
  },
  async created(){
    const version = "3.49.92";
    localStorage.setItem("App_Version", version)

    console.log("App_Version: ", version)

    let { profile } = store.state["user"];
    Vue.prototype?.$mixpanel?.track('webapp_version', {
      version,
      email: profile?.email
    });


    let notify = this.$q.notify;
    this.$q.notify = (arg) => {
      console.error(arg)
      if(arg.color == "red") {
        delete arg.color;
        arg.type = "negative";
        arg.position = "top"
      } else if(arg.color == "green") {
        delete arg.color;
        arg.type = "positive";
        arg.position = "top"
      }
      let { profile } = store.state["user"];
      if(arg.type === 'negative') {
        Vue.prototype?.$mixpanel?.track('error', {
        referrer: document.referrer,
        version,
        url: window.location.href,
        arg,
        email: profile?.email
      });
      }
      arg.classes = ["full-width notification_top notification_"+arg.type];
      arg.actions = [{
              color: 'black',
              icon: 'close',
              'aria-label': 'Dismiss'
            }
          ];
      if(arg.message && arg.message != "undefined") {
        notify(arg)
      }
    }

    window.showNotification = (type, message) => {
      this.$q.notify({
          message: message || "A simple Message",
          type: type,
          position: 'top',
          timeout: 30000,
          classes: ["full-width notification_top notification_"+type],
          actions: [
            {
              color: 'black',
              icon: 'close',
              'aria-label': 'Dismiss'
            }
          ]
        });
    }
    let s = 0
    const startObserving = (domNode, classToLookFor) => {
        const observer = new MutationObserver(mutations => {
          mutations.forEach(function (mutation) {

            const elementAdded = Array.from(mutation.addedNodes).some(
              element => {
                if (element.classList) {
                  return element.classList.contains(classToLookFor);
                } else {
                  return false;
                }
              },
            );
            const focusHighlighted = () => {
              const allH = document.querySelectorAll(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600");
              let found = -1;
              for(let i=0; i< allH.length; i++) {
                if(allH[i].offsetParent && !allH[i].closest(".vc-opacity-0")) {
                  found=i;
                  const thisEle = allH[i];
                  thisEle?.closest(".vc-pane").scrollIntoView(true);
                  break;
                }
              }
              if(found == -1) {
                triggerHighlight();
              }
            }
            const triggerHighlight = () => {
              clearTimeout(s);
              s = setTimeout(focusHighlighted, 400)
            }
            if (elementAdded) {
              triggerHighlight();
            }
          });
        });

        observer.observe(domNode, {
          childList: true,
          attributes: true,
          characterData: true,
          subtree: true,
        });

        return observer;
      };

      startObserving(document.body, 'q-position-engine');
    getLocaleMehod();
    // getLocaleMethodKpi();
  },
  async mounted() {

    let surveyEmailAllowed = ["testinguser@flow-solutions.com", "shuhei@flow-insight.com"]

    console.log(this.canShowSurvey , this.isAuthenticated, store.state.user.profile.email)
    this.canShowSurvey = surveyEmailAllowed.indexOf(store.state.user.profile.email) > -1;
    // this.canShowSurvey = true; //store.state.user.profile.email == "shuhei@flow-insight.com"
    let cVer = Number(localStorage.getItem('forcedAPIVersion'))
    if(cVer !== this.forcedAPIVersion) {
      await this.fetchKpiPreferences()
      localStorage.setItem('forcedAPIVersion', this.forcedAPIVersion)
    }

    // this.getTranslations();

    const wa = document.createElement('script');
    window.__lo_site_id = 132500;
    window.Apex.chart = {
      locales: [en, ja],
      defaultLocale: this.getLocale
    };
    wa.type = 'text/javascript';
    wa.async = true;
    wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wa, s);
    document.head.appendChild(wa);
  },
  methods:{

    ...mapActions('user', ['fetchKpiPreferences']),
  //   allAppWeather(){
  //   const moment = require('moment');
  //   const startDate = moment('2023-04-04', 'YYYY-MM-DD');
  //   const endDate = moment('2023-04-04', 'YYYY-MM-DD');
  //   const cityName = 'つくば市';
  //   weather(startDate, endDate, cityName)
  //   .then((data) => {
  //       console.log(data, '=-->'); // Object containing average and most common values
  //   })
  //   .catch((err) => {
  //       console.error(`Error: ${err}`);
  //   });
  // },
    // async getTranslations() {
    //   var translation = await resources.setLanguage(this.$store.state.user.locale);
    //   this.$store.commit("store/setTranslation", translation);
    // }
  }
}; // export default
