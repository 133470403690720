import { BaseTenantDTO, TenantConfig, TenantDTO } from '@/models/Tenant';

import { BaseUserDTO } from '@/models/User';
import HttpController from '../HttpController';
import { createBackendError } from '@/util/error';

export interface CreateTenantRequest {
  tenant: BaseTenantDTO;
  user: {
    approvalPrivilege: boolean;
    firstname: string;
    lastname: string;
    password: string;
    passwordConfirm: string;
  } & BaseUserDTO;
}

export interface CreateTenantResponse {
  tenant: string;
}

export default class TenantController extends HttpController {
  public async getTenant(tenant: string) {
    try {
      const { data } = await this.client.get(`/console/tenants/name=${tenant}`);
      return data as TenantDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createTenant(req: CreateTenantRequest) {
    try {
      const { data } = await this.client.post('/console/tenants', req);
      return data as CreateTenantResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateTenantConfig(
    tenantConfig: TenantConfig,
    updatedTenantConfig: TenantConfig
  ) {
    try {
      await this.client.put(
        `/console/tenant-config/${tenantConfig.id}`,
        updatedTenantConfig
      );
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
