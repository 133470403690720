
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
