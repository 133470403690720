import { AxiosInstance } from 'axios';
import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { PaginationRequest } from '@/util/pagination';

export interface SaveMappingRequest {
  fileName: string;
  filePath: string;
  mappingName: string;
  mapping: { [key: string]: string };
}

export interface UploadedSalesTemplateData {
  fileName: string;
  filePath: string;
  attributes: string[];
}

export default class SalesTemplateController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getSalesTemplates(kpi: string, pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/' + kpi + '-mapping', {
        params: {
          ...pagination
        }
      });
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getSalesTemplateMaster() {
    try {
      const { data } = await this.client.get('/sales-mapping/master');
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async uploadSalesTemplate(
    file: File
  ): Promise<UploadedSalesTemplateData> {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await this.client.post(
        '/sales-mapping/upload',
        formData
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async saveMapping(req: SaveMappingRequest) {
    try {
      const { data } = await this.client.post('/sales-mapping/save', req);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateMapping(id: string, req: SaveMappingRequest) {
    try {
      const { data } = await this.client.post(`/sales-mapping/${id}`, {
        ...req,
        mapping: JSON.stringify(req.mapping)
      });
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async downloadSalesMappingTemplate(
    id: string,
    kpi: string
  ): Promise<BlobPart> {
    try {
      const { data } = await this.client.get(`/${kpi}-mapping/download/${id}`);
      return data as BlobPart;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteSalesMappingTemplate(ids: string[], kpi: string) {
    try {
      const data = await this.client.delete('/' + kpi + '-mapping', {
        data: ids
      });

      createBulkDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
