
export default {
  data() {
    return {
      activeGroup: 0,
      loading: false,

      groupNumber: [
        {
          name: 'Young Female'
        },
        {
          name: 'Group 1'
        },
        {
          name: 'Group 2'
        },
        {
          name: 'Group 3'
        },
        {
          name: 'Group 4'
        },
        {
          name: 'Group 5'
        },
        {
          name: 'Group 6'
        },
        {
          name: 'Group 7'
        },
        {
          name: 'Group 8'
        }
      ],
      metrics: [
        {
          metricKey: 'Metric 1',
          value: '985215',
          growthIndicator: 1,
          variance: 2
        },
        {
          metricKey: 'Metric 2',
          value: '3025',
          growthIndicator: -1,
          variance: 2
        },
        {
          metricKey: 'Metric 3',
          value: '65555',
          growthIndicator: -1,
          variance: 56
        },
        {
          metricKey: 'Metric 4',
          value: '3025',
          growthIndicator: -1,
          variance: 3
        },
        {
          metricKey: 'Metric 5',
          value: '3025',
          growthIndicator: 1,
          variance: 2
        },
        {
          metricKey: 'Metric 6',
          value: '3025',
          growthIndicator: -1,
          variance: 2
        }
      ]
    };
  }
};
