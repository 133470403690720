
import { mapState } from 'vuex';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';

export default {
props: {
  flat: {
    type: Boolean
  }
},
data(){
  return {
    fida: 'true'
  }
},
computed: {
  ...mapState('traffic', {
    totalComparedTrafficCount: 'totalComparedTrafficCount'
  }),
  getFormated(){
    const res = this.totalComparedTrafficCount?this.totalComparedTrafficCount.toLocaleString():0;
    return getSignsLeftSide('trf') + res + getSignsRightSide('trf')
  },
  ...mapState('overview', {
    data: 'kpis'
  })
} // computed
}; // export default
