import {
  APIFetchDistributionByPeriod,
  APIFetchDistributionByDate,
  APIFetchKpiByModule,
  APIPostKpiByModule
} from '@/api/distribution';
import DashboardManager from '@/api/apiDataManagers/dashboardManager.js';
import { getKpiNameByKey } from '@/store/modules/exportFida';
import {formatIntDate} from '@/util/utilFidaFunctions';
import {
  GetReportingSumDataByLocationFidaCompDetailV2
} from '@/api/fida';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import { filterAvailableKpis } from '@/util/kpis';

import store from '..';
import traffic from './traffic';

const initialState = {
  byPeriod: {
    selectedKPIValues: [],
    comparedKPIValues: []
  }, // byPeriod
  byDate: [],
  selectedKPIOptions: []
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setDistributionsByPeriod(state, { byPeriod }) {
      state.byPeriod = byPeriod;
    }, // setDistributionsByPeriod
    setDistributionsByDate(state, { byDate }) {
      state.byDate = byDate;
    }, // setDistributionsByDate
    setPersistentKPI(state, selectedKPI) {
      localStorage.removeItem('selectedTrafficKPI');
      localStorage.setItem('selectedTrafficKPI', selectedKPI.join(','));
      state.selectedKPIOptions = selectedKPI;
      //APIPostKpiByModule

      // var userId = localStorage.getItem('isDemoAccount');//there should be user id
      // var module = 'detailed-report';
      // var kpiMatrixKey = selectedKPI.join(',');
      // const res = APIPostKpiByModule({
      //   module: 'Dashboard',
      //   userId: 'Ry9qzFXyRff6ADPi4',
      //   kpiMatrixKey: 'sales,traffic,trafficSales'
      // });
      //Post
    },
    setPersistentComparisonKPI(state, selectedKPI) {
      localStorage.removeItem('selectedComparisonKPI');
      localStorage.setItem('selectedComparisonKPI', selectedKPI.join(','));
      state.selectedKPIOptions = selectedKPI;
    },
    getLocalStoreKPI(state, { selectedKPI, req }) {
      //get url here
      //detailed-report
      //  const res = APIFetchKpiByModule({});
      if (selectedKPI) {
        state.selectedKPIOptions = selectedKPI.split(',');
      } else {
        //call api
        const kpiOptions = [
          'sls',
          'tra',
          'trf',
          'trn',
          'cn',
          'ap',
          'upt',
          'rov',
          'aip',
          'spt',
          'tpt',
          'sl',
          'sh',
          'cs',
          'se',
          'pb',
          'cr',
          'dw',
          'is',
          'st'
        ];
        state.selectedKPIOptions = filterAvailableKpis(kpiOptions);
      }
    }
  }, // mutations
  actions: {// for redar chart
    fetchDistributionByPeriod({ commit },payload){
      commit('setDistributionsByPeriod', { byPeriod: payload });
    },
//     async fetchDistributionByPeriod(
//       { commit },
//       {
//         startDate,
//         endDate,
//         compareStartDate,
//         compareEndDate,
//         storeCodes,
//         daysOfWeek,
//         kpis
//       }
//     ) {

//       if(localStorage.getItem('fidaMigrationCheck') == 'true')// true for fida
//       {
//         if(kpis == "")
//         return;

//       const res = await GetReportingSumDataByLocationFidaCompDetailV2({
//         startDate,
//         endDate,
//         compareStartDate,
//         compareEndDate,
//         "storeCodes": storeCodes.split(','),
//         "kpiTypes": kpis,
//         "frequency": "daily",
//         daysOfWeek
//       });
//       debugger;
// const finalResult = convertApiResponse(res);
// debugger;
//       // const finalResult = await DashboardManager.fetchDistribution({
//       //   startDate,
//       //   endDate,
//       //   compareStartDate,
//       //   compareEndDate,
//       //   "storeCodes": storeCodes.split(','),
//       //   "kpiTypes": kpis,
//       //   "frequency": "daily",
//       //   daysOfWeek
//       // })
//       // console.log("fetchDistribution", JSON.parse(JSON.stringify(finalResult)))
//       if (
//         finalResult?.data?.selectedKPIValues.length !== 0 &&
//         finalResult?.data?.comparedKPIValues.length !== 0
//       ) {
//         commit('setDistributionsByPeriod', { byPeriod: finalResult.data });
//       }
//       debugger;
//     }
//     else
//     {
//       const res = await APIFetchDistributionByPeriod({
//         startDate,
//         endDate,
//         compareStartDate,
//         compareEndDate,
//         storeCodes,
//         daysOfWeek,
//         kpis
//       });

//       if (
//         res?.data?.selectedKPIValues.length !== 0 &&
//         res?.data?.comparedKPIValues.length !== 0
//       ) {
//         commit('setDistributionsByPeriod', { byPeriod: res.data });
//       }
//     }
//     },
    async fetchDistributionByDate(
      { commit, rootState },
      { startDate, endDate, storeCodes, daysOfWeek, kpis }
    ) {
        if(kpis == "")
        return;
        var check = 0;
        if(kpis.includes('sls2'))
        {
          check = 1;
          kpis = kpis.replace('sls2','sls')
        }
       
        const res = await GetReportingSumDataByLocationFidaCompDetailV2({
          startDate,
          endDate,
          "storeCodes": storeCodes.split(','),
          "kpiTypes": kpis,
          "frequency": "daily"
        });

       if(res.kpis[0].tra)
        {
          if(res.kpis[0].sls && res.kpis[0].st)
          res.kpis = res.kpis.map(obj => {
            // Calculate tra and assign it to the object
            const traValue = (obj.sls / obj.st * 100).toFixed(2);
            
            // Create a new object without the .st property
            const newObj = {...obj, tra: traValue};
            delete newObj.st;
            if(check == 1)
              {
                delete newObj.sls;
              }
            return newObj;
        });
        }

        const finalRes = convertToFetchDistributionByDate(res);

        commit('setDistributionsByDate', { byDate: finalRes });
      // return res;
    }
  }
};
function convertToFetchDistributionByDate(apiResponse: any): any {
  const formattedData = {};

apiResponse.kpis.sort((a,b) => a._id.split('_')[0].localeCompare(b._id.split('_')[0]))
// apiResponse.kpis?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
  apiResponse.kpis.forEach(item => {
    // Check if "_id" property exists and has a valid format
    if (item["_id"]) {
        var formattedDate = formatIntDate(item["_id"].split('_')[0]);
        const dateObject = new Date(formattedDate);
        const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayIndex = dateObject.getDay();
        const dayName = dayNames[dayIndex];
        formattedDate = formattedDate+'('+dayName+')';


        // Check if formattedData[formattedDate] exists
        if (!formattedData[formattedDate]) {
            formattedData[formattedDate] = {};
        }

        Object.keys(item).forEach(key => {
            // Skip processing if key is "_id"
            if (key !== "_id") {
              const valueKpi = item[key]?item[key].toLocaleString():'0';
              formattedData[formattedDate][key]  = `${getSignsLeftSide(key)}${valueKpi}${getSignsRightSide(key)}`;
               // formattedData[formattedDate][key] = `${getSignsLeftSide()+''+(item[key] === "number" ? item[key] : 0)+''+getSignsRightSide();
            }
        });
    }
});

  return formattedData;
}

interface ConvertedResponse {
  data: {
      selectedKPIValues: {
          metricKey: string;
          value: number;
          formattedValue: string;
          metricID: string;
      }[];
      comparedKPIValues: {
          metricKey: string;
          value: number;
          formattedValue: string;
          metricID: string;
      }[];
  };
}

// function convertApiResponse(apiResponse: any): ConvertedResponse {
//   const sumByKey = {};

//   apiResponse.kpis.forEach(item => {
//     Object.keys(item).forEach(key => {
//         if (key !== "_id") {
//             sumByKey[key] = (sumByKey[key] || 0) + (item[key] || 0);
//         }
//     });
//   });


//   const selectedKPIValues = Object.keys(sumByKey).map(key => ({
//     metricKey: getKpiNameByKey(key),
//     value: sumByKey[key],
//     formattedValue: sumByKey[key].toLocaleString() + ' ',
//     metricID: key,
//   }));

//   // const selectedKPIValues = Object.entries(apiResponse.kpis).map(([metricID, metrics]) => ({

//   //   metricKey: capitalizeFirstLetter(metricID),
//   //     value: parseFloat(metrics[0].totalCount),
//   //     formattedValue: metrics[0].totalCount.toLocaleString()+' ',
//   //     metricID: metricID,
//   // }));

//   apiResponse.kpisCompare.forEach(item => {
//     Object.keys(item).forEach(key => {
//         if (key !== "_id") {
//             sumByKey[key] = (sumByKey[key] || 0) + (item[key] || 0);
//         }
//     });
//   });

//   const comparedKPIValues = Object.keys(sumByKey).map(key => ({
//     metricKey: getKpiNameByKey(key),
//     value: sumByKey[key],
//     formattedValue: sumByKey[key].toLocaleString() + ' ',
//     metricID: key,
//   }));


//   // const comparedKPIValues = Object.entries(apiResponse.compareKpis).map(([metricID, metrics]) => ({
//   //     metricKey: capitalizeFirstLetter(metricID),
//   //     value: parseFloat(metrics[0].totalCount),
//   //     formattedValue: metrics[0].totalCount.toLocaleString()+' ',
//   //     metricID: metricID,
//   // }));

//   return {
//       data: {
//           selectedKPIValues,
//           comparedKPIValues,
//       },
//   };
// }

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
