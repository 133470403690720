import { PostRequest } from '@/util/http';

export function APIAddUser(params: any) {
  return PostRequest('user/add', params);
}

export function APIFetchRoles(params: any) {
  return PostRequest('roles', params);
}

export function APIUpdateUser(params: any, userID) {
  return PostRequest(`user/update/${userID}`, params);
}

export function APIFetchUserByID(params: any, userID) {
  return PostRequest(`user/details/${userID}`, params);
}

export function APIDeleteUserByID(params: any, userID) {
  return PostRequest(`user/delete/${userID}`, params);
}

export function APIAddUserGroup(params: any) {
  return PostRequest('userGroup/add', params);
}

export function APIUpdateUserGroup(params: any, userGroupID) {
  return PostRequest(`userGroup/update/${userGroupID}`, params);
}

export function APIFetchUserGroupByID(params: any, userGroupID) {
  return PostRequest(`userGroup/details/${userGroupID}`, params);
}

export function APIDeleteUserGroupByID(params: any, userGroupID) {
  return PostRequest(`userGroup/delete/${userGroupID}`, params);
}
