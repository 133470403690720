
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    radarChartSelected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      markedOptions: []
    };
  },
  computed: {
    ...mapState('filter', ['tabluarDataKpiOptions']),
    ...mapState('user', ['kpiPreferences']),
    searchResult() {
      if (this.search) {
        return this.options.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.options;
      }
    },
    options() {
      return [...this.kpiPreferences].map(kpi => {
        return { label: kpi.title, value: kpi.metricKey };
      });
    }
  },
  watch: {
    markedOptions(newValue) {
      this.setRadarChartKpiOptions({ kpiOptions: newValue });
    }
  },
  created() {
    this.markedOptions = this.selectedOptions;
  },
  methods: {
    ...mapMutations('distribution', ['setPersistentKPI']),
    ...mapMutations('filter', ['setTabluarDataKpiOptions']),
    ...mapActions('filter', ['setPersistentTabularKPI']),
    submit() {
      if (this.markedOptions.length < 4 && this.kpiPreferences>4) {
        return false;
      }
      this.setTabluarDataKpiOptions(this.markedOptions);
      this.selectedOptions = this.markedOptions;
      this.setPersistentTabularKPI(this.markedOptions);
      if (this.radarChartSelected) {
        this.setPersistentKPI(this.markedOptions);
      }
      this.$emit('update:selectedOptions', this.markedOptions);
      this.hide();
    },
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterKPI.hide();
    },
    ...mapMutations('filter', ['setRadarChartKpiOptions'])
  }
};
