import HttpController from '../HttpController';
import { createBackendError } from '@/util/error';

export default class GeneralController extends HttpController {
  public async cleanData() {
    try {
      await this.client.get('/general/clean-data');
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
