
import peergroups from "@/services/peergroups";
import users from "@/services/users.js";
import stores from "@/services/stores.js";
import StoreModel from "@/components/modals/StoreModel";

export default {
  components: {
    StoreModel,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["selectedPeerGroup", "storeData"],
  // props: {
  //   selectedPeerGroup: {
  //     type: Object,
  //     default: null,
  //   },
  //   storeData: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {
      configuration: true,
      title: "",
      loadingBtn:false,
      // storeData: [],
      openStoreModel: false,
      selectedStores: [],
      dataAccessibleColumns: [
        {
          name: "name",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "name",
          sortable: true,
        },
        {
          name: "storeNumber",
          align: "left",
          label: this.$store.state.user.translate.store_number,
          field: "storeNumber",
          sortable: true,
        },
        // { name: "brand", label: "Brand", field: "brand" },
        {
          name: "myStore",
          label: this.$store.state.user.translate.my_store,
          field: "myStore",
        },
        {
          name: "availability",
          label: this.$store.state.user.translate.actions,
          field: "availability",
        },
      ],
      dataAccessibleRows: [],
      name: "",
      description: "",
      selectedStore: "",
      type: [],
      order: [],
      orderOptions: [
        {
          label: this.$store.state.user.translate.export_order_option1,
          value: "1",
        },
        {
          label: this.$store.state.user.translate.export_order_option2,
          value: "2",
        },
        {
          label: this.$store.state.user.translate.export_order_option3,
          value: '',
        },
        
      ],
      options: [
        {
          label: "Region",
          value: "region",
        },
        {
          label: "District",
          value: "district",
        },
        {
          label: "Area Manager",
          value: "areaManager",
        },
        {
          label: "Peer Group",
          value: "peerGroup",
        },
      ],
    };
  },
  watch: {
    configuration(newValue) {
      if (!newValue) {
        this.$emit("closeModel", false);
      }
    },
  },
  mounted() {
    this.requestUserDetails();
    if (this.selectedPeerGroup) {
      this.title =
        this.$store.state.user.translate.edit +
        " " +
        this.$store.state.user.translate.peer_group;
      this.name = this.selectedPeerGroup.title;
      this.dataAccessibleRows = this.selectedPeerGroup.stores;
      this.selectedStores = this.selectedPeerGroup.stores;
      
      this.description = this.selectedPeerGroup.description;
      this.order = this.orderOptions.find(obj => obj.value == this.selectedPeerGroup.order);
      if(!this.order){
        this.order = {
          label: this.$store.state.user.translate.export_order_option3,
          value: '',
        }
      }
      // this.type = this.options.find(
      //     (c) => c.value == this.selectedPeerGroup.type
      //   ).value
    } else {
      this.title =
        this.$store.state.user.translate.add +
        " " +
        this.$store.state.user.translate.peer_group;
    }
  },
  methods: {
    createPeerGroup() {
      if (this.selectedPeerGroup) {
        let storeIds = [];
        this.dataAccessibleRows.forEach((element) => {
          storeIds.push(element.storeNumber);
        });
        let peerGroupData = {
          title: this.name,
          description: this.description,
          storeCodes: storeIds,
          order:this.order.value,
          peerGroupId: this.selectedPeerGroup._id,
          type: "peerGroup",
          
          // type: this.type.value,
          // type: this.selectedPeerGroup.type,
        };
        if (this.dataAccessibleRows.length > 0) {
          this.loadingBtn = true
          peergroups
            .updatePeerGroup(peerGroupData)
            .then(() => {
              this.$q.notify({
                message: this.$store.state.user.translate.updated_successfully,
                color: "green",
              });
              this.loadingBtn = false
              this.$emit("updatePeerGroup");
            })
            .catch((error) => {
              if (error.response.data.message) {
                this.$q.notify({
                  message: error.response.data.message,
                  color: "red",
                });
              } else {
                this.$q.notify({
                  message: this.$store.state.user.translate
                    .something_went_wrong,
                  color: "red",
                });
              }
               this.loadingBtn = false
              this.$emit("closeModel", false);
            });
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.add_stores,
            color: "red",
          });
          
        }
      } else {
        let storeIds = [];
        this.dataAccessibleRows.forEach((element) => {
          storeIds.push(element.storeNumber);
        });
        let peerGroupData = {
          title: this.name,
          description: this.description,
          order:this.order.value,
          storeIds: storeIds,
          type: "peerGroup",
        };
        
        if (this.dataAccessibleRows.length > 0) {
           this.loadingBtn = true
          peergroups
            .createPeerGroup(peerGroupData)
            .then(() => {
              this.$q.notify({
                message: this.$store.state.user.translate.peergroup_created_successfully,
                color: "green",
              });
               this.loadingBtn = false
              this.$emit("updatePeerGroup");
            })
            .catch((error) => {
              // console.log(error);
              if (error.response.data.message) {
                this.$q.notify({
                  message: error.response.data.message,
                  color: "red",
                });
              } else {
                this.$q.notify({
                  message: this.$store.state.user.translate
                    .something_went_wrong,
                  color: "red",
                });
              }
              this.loadingBtn = false
              this.$emit("closeModel", false);
            });
        } else {
          this.$q.notify({
            message: this.$store.state.user.translate.add_stores,
            color: "red",
          });
        }
      }
    },
    unassignUserStore(data) {
      let storeData = {
        storeId: data.row._id,
        userId: this.userDetails._id,
      };
      stores
        .unassignUserStore(storeData)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.store_unassigned_successfully,
            color: "green",
          });
          var copy = JSON.parse(JSON.stringify(this.dataAccessibleRows));
          this.dataAccessibleRows = copy.filter((item) => item._id != data.row._id);
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    requestUserDetails() {
      users.getUserDetails().then((response) => {
        this.userDetails = response.data.response.userDetails;
        // this.dataAccessibleRows
      });
    },
    closeStoreModel() {
      this.openStoreModel = false;
    },
    async updateStoreValue(value) {
      this.dataAccessibleRows = value;
      this.selectedStores = this.dataAccessibleRows;
      this.openStoreModel = false;
    },
    // getAllStores() {
    //   stores.getStores().then((response) => {
    //     this.storeData = response.data.response.stores
    //   })
    // },
  },
};
