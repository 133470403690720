
import BoxHeader from '@/components/Box/Common/Header/BoxHeader.vue';
import LoadingSpinner from '@/components/Common/Loading/LoadingSpinner.vue';

import { mapState, mapActions, mapGetters } from 'vuex';
import { differenceInCalendarDays, lastDayOfMonth } from 'date-fns';

export default {
  components: { BoxHeader, LoadingSpinner },
  data() {
    return {
      loading: false,
      getMonthlyTargetRate: 0,
      getDailyTargetRate: 0
    };
  },
  computed: {
    ...mapGetters('filter', ['getStoreCodesList', 'getDaysOfWeekList']),
    ...mapState('target', ['targets']),
    datesUntilEndOfMonth() {
      return differenceInCalendarDays(lastDayOfMonth(new Date()), new Date());
    }
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchTargets({
        storeCodes: this.getStoreCodesList,
        daysOfWeek: this.getDaysOfWeekList
      });
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: `${error}`
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getTargetPercent(achieved, total) {
      if (
        (typeof achieved !== 'number' && typeof total !== 'number') ||
        total === 0
      ) {
        return 0;
      } else {
        return ((achieved / total) * 100).toFixed(2);
      }
    },
    ...mapActions('target', ['fetchTargets'])
  }
};
