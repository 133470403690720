
import AddNewBrand from "@/components/modals/AddNewBrand.vue";
import brands from "@/services/brands";

export default {
  components: {
    AddNewBrand,
  },
  props: {},
  data() {
    return {
      openModel: false,
      loading: false,
      columns: [
        {
          name: "title",
          align: "left",
          label: this.$store.state.user.translate.name,
          field: "title",
          sortable: true,
        },
        {
          name: "description",
          label: this.$store.state.user.translate.description,
          field: "description",
        },
        {
          name: "action",
          label: this.$store.state.user.translate.actions,
          field: "action",
        },
      ],
      brandData: [],
      selectedBrand: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      visible: false,
      confirm: false,
       filter: "",
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.brandData.length / this.pagination.rowsPerPage);
    },
  },
  mounted() {
    this.visible = true;
    this.getBrands();
  },
  methods: {
    closeModel() {
      this.openModel = false;
    },
    addNewBrand() {
      this.selectedBrand = null;
      this.openModel = true;
    },
    editBrandDialog(brand) {
      this.selectedBrand = brand;
      this.openModel = true;
    },
    getBrands() {
      this.visible = true;
      this.loading = true;
      brands
        .getBrands()
        .then((response) => {
          this.brandData = response.data.response.brands.reverse();
          this.visible = false;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    deleteBrand(brand) {
      this.selectedBrand = brand;
      this.confirm = true;
    },
    confirmDeleteBrand() {
      brands
        .deleteBrand(this.selectedBrand._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.deleted_successfully,
            color: "green",
          });
          this.updateBrands();
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
      this.confirm = false;
    },
    async updateBrands() {
      this.openModel = false;
      this.getBrands();
    },
  },
};
