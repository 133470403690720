
import Badge from '@/components/Management/Badge.vue';
import { createColumn } from '@/util/table';
import { colors } from '@/styles/management';

const columns = [
  createColumn('name', 'Name'),
  createColumn('cardNumber', 'Card Number'),
  createColumn('address', 'Billing Address'),
  createColumn('actions', 'Action')
];

export default {
  components: {
    'm-badge': Badge
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    columns
  }),
  methods: {
    badgeClass() {
      return this.$style.badge;
    },
    badgeStyle(active) {
      if (active) return {};
      return {
        backgroundColor: colors.red
      };
    }
  }
};
