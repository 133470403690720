import Session from '@/models/Session';
import User from '@/models/User';
import UserRepository from './UserRepository';
import lazy from '@/util/lazy';
import localForage from 'localforage';

const TENANT_KEY = 'session-tenant';

export default class SessionRepository {
  private lazyUser: () => Promise<User>;
  private userRepository: UserRepository;
  constructor(userRepository: UserRepository) {
    this.lazyUser = lazy(() => {
      return userRepository.getUser('me');
    });
  }

  public getUser(): Promise<User> {
    return this.lazyUser();
  }

  public put(tenant: string): Promise<Session> {
    return localForage.setItem(TENANT_KEY, { tenant });
  }

  public fetch(): Promise<Session> {
    return localForage.getItem(TENANT_KEY);
  }

  public clear(): Promise<void> {
    return localForage.removeItem(TENANT_KEY);
  }
}
