// var kpiListCall = [
//   'sales',
//   'avgitemprice',
//   'upt',
//   'avgpurchase',
//   'conversion',
//   'transactions',
//   'traffic',
//   'passersby',
//   'salespersqm',
//   'trafficpersqm',
//   'capturerate',
//   'storedwelltime',
//   'engagementrate',
//   'bouncerate',
//   // 'facadestops',
//   'stafflevel',
//   'staffhours',
//   'closingstrength',
//   'staffefficiency',
//   'rov',
//   'maxoccupancy',
//   'minoccupancy',
//   'avgoccupancy',
//   'avgoccupancy%',
//   'numberofalerts',
//   'numberofrequests',
//   'requesttakenperalert',
//   'timebeforetakingrequest',
//   'trafficinline',
//   'avgusersinline',
//   'avgwaitingtime'
// ];

var kpiListCall = [
  'sls',
  'aip',
  'upt',
  'tra',
  'ap',
  'cn',
  'trn',
  'trf',

  'pb',
  'spt',
  'tpt',
  'cr',
  'dw',

  'is',
  'sh',

  'rov',
  'sl',
  'cs',
  'se'
];

const kpiListOccupancyCall = [
 // 'averageOccupancy', //'avgOccupancy', value and lable is changed only in BoxOccupancyPowerHour.vue occupancy report
  'maxOccupancy',
  'minOccupancy',
 // 'avgOccupancyPercent'
];
const kpiListExtensionCall = [
  'sls',
  'st',//sales Target //value sales-target
  'trf',
  'sh' //value staff-hours'
];
const kpiListExtensionTemplateCall = ['sls', 'trf'];
const kpiListQueseCall = [
  'NumberOfAlerts',
  'NumberOfRequestsTaken',
  'TimeBeforeTaking',
  'AverageUsersInLine',
  'AverageWaitingTime',
  'TrafficInLine',
  'RequestTakenPerAlert'
];

//No need of api call
export const kpiMapping = {
  weather: "Weather",
  trf:	"Traffic",
  tra:	"Target Achievement Rate",
  pb:	"Passersby",
  dw:	"Store Dwell Time",
  sls:	"Sales",
  trn:	"Transactions",
  is:	"Total Item Sold",
  sh:	"Staff Hours",
  cn:	"Conversion",
  cr:	"Capture Rate",
  ap:	"Average Purchase",
  aip:	"Average Item Price",
  spt:	"Sales Per Tsubo",
  tpt:	"Traffic Per Tsubo",
  sl:	"Staff Level",
  cs:	"Closing Strength",
  se:	"Staff Efficiency",
  sales: 'Sales',
  avgitemprice: 'Average Item Price',
  upt: 'UPT',
  avgpurchase: 'Avg Purchase',
  conversion: 'Conversion',
  transactions: 'Transactions',
  capturerate: 'Capture Rate',
  traffic: 'Traffic',
  passersby: 'Passers By',
  salespersqm: 'Sales Per Tsubo',
  trafficpersqm: 'Traffic Per Tsubo',
  storedwelltime: 'Store Dwell Time',
  engagementrate: 'Engagement Rate',
  bouncerate: 'Bounce Rate',
  // facadestops: 'Facade Stops',
  stafflevel: 'Staff Level',
  staffhours: 'Staff Hours',
  closingstrength: 'Closing Strength',
  staffefficiency: 'Staff Efficiency',
  rov: 'ROV',
  maxoccupancy: 'Max Occupancy',
  minoccupancy: 'Min Occupancy',
  avgoccupancy: 'Avg Occupancy',
  'avgoccupancy%': 'Avg Occupancy %',
  numberofalerts: 'Number of Alerts',
  numberofrequests: 'Number of Requests',
  requesttakenperalert: 'Request Taken Per Alert',
  timebeforetakingrequest: 'Time Before Taking Request',
  trafficinline: 'Traffic In Line',
  avgusersinline: 'Avg Users In Line',
  avgwaitingtime: 'Avg Waiting Time'
};
// export const kpiList = kpiListCall;
export const kpiListOccupancy = kpiListOccupancyCall;
export const kpiListExtension = kpiListExtensionCall;
export const kpiListExtensionTemplate = kpiListExtensionTemplateCall;
export const kpiListQuese = kpiListQueseCall;
export const kpiList = kpiListCall;

// import { APIGetAllKpis } from '@/api/kpi';

// export const kpiListGet = async () => {
//   const kpiList = await (await APIGetAllKpis())
//     .filter(kpi => {
//       return kpi.visibility === 1;
//     })
//     .sort((a, b) => {
//       return a.order - b.order;
//     });
//   const kpiOption = kpiList.map(singleKpi => {
//     return {
//       label: singleKpi.title,
//       value: singleKpi.metricKey
//     };
//   });
//   return kpiOption;
// };

// export const kpiListGet2 = async () => {
//   const kpiList = await (await APIGetAllKpis())
//     .filter(kpi => {
//       return kpi.visibility === 1;
//     })
//     .sort((a, b) => {
//       return a.order - b.order;
//     });
//   const kpiOption = kpiList;
//   return kpiOption;
// };
