import PaymentCard, { PaymentCardDTO } from '../Payment';

import { zeropad } from '@/util/string';

export const convertFromPaymentCardDTO = (dto: PaymentCardDTO): PaymentCard => {
  const expiryMonth = zeropad(dto.card.expMonth, 2);
  const expiryYear = zeropad(dto.card.expYear, 2);
  const expired = `${expiryMonth}/${expiryYear}`;
  return {
    id: dto.id,
    name: dto.billingDetails.name,
    cardNumber: `(${dto.card.brand}) ${dto.card.last4}`,
    address: dto.billingDetails.address.line1,
    expired: expired,
    customerId: dto.customer
  };
};
