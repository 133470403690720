import BrandController from '@/services/core/BrandController';
import Brand from '@/models/Brand';
import { Pagination, PaginationResponse } from '@/util/pagination';

export interface GetBrandsResult {
  data: Brand[];
  pagination: PaginationResponse;
}

export default class BrandRepository {
  private readonly brandController: BrandController;

  constructor(brandController: BrandController) {
    this.brandController = brandController;
  }

  public async getBrands(pagination: Pagination = new Pagination()) {
    const { content, ...meta } = await this.brandController.getBrands(
      pagination.toRequest()
    );
    return {
      data: content,
      pagination: meta
    } as GetBrandsResult;
  }

  public async createBrand(brand: Brand) {
    const res = await this.brandController.createBrand({
      code: brand.code,
      brand: brand.brand
    });
    return res;
  }

  public async updateBrand(id: string, brand: Brand) {
    const res = await this.brandController.updateBrand(id, {
      brandid: brand.brandid,
      code: brand.code,
      brand: brand.brand
    });
    return res;
  }

  public async deleteBrand(id: string) {
    await this.brandController.deleteBrand([id]);
  }
}
