import { CurrencyDTO } from '@/models/Currency';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';
import HttpController from '../HttpController';

const basePath = '/console/currencies';

export type GetCurrenciesResponse = PaginatedResponse<CurrencyDTO>;

export default class CurrencyController extends HttpController {
  public async getCurrencies() {
    try {
      const { data } = await this.client.get(basePath, {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          unpaged: true,
          sorted: true
        }
      });
      return data as GetCurrenciesResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
