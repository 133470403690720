import { render, staticRenderFns } from "./CompanyInfo.vue?vue&type=template&id=adb128ba&"
import script from "./CompanyInfo.vue?vue&type=script&lang=js&"
export * from "./CompanyInfo.vue?vue&type=script&lang=js&"
import style0 from "./CompanyInfo.vue?vue&type=style&index=0&id=adb128ba&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QForm,QItemLabel,QInput,QSelect,QBtn,QInnerLoading,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QItemLabel,QInput,QSelect,QBtn,QInnerLoading,QField})
