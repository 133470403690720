import { render, staticRenderFns } from "./BoxStoresOverview.vue?vue&type=template&id=5db453dd&scoped=true&"
import script from "./BoxStoresOverview.vue?vue&type=script&lang=js&"
export * from "./BoxStoresOverview.vue?vue&type=script&lang=js&"
import style0 from "./BoxStoresOverview.vue?vue&type=style&index=0&id=5db453dd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db453dd",
  null
  
)

export default component.exports
import {QInnerLoading,QSpinnerTail} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInnerLoading,QSpinnerTail})
