
import { required, minValue } from 'vuelidate/lib/validators';
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import LocationFormMixin from '@/mixins/LocationFormMixin';
import AreaFormMixin from '@/mixins/AreaFormMixin';
import {
  entityOptions,
  brandEntityOptions,
  storeTypeEntityOptions
} from '@/util/select';
import { handleErrorUI } from '@/util/error';
import { generateImageDataUrl } from '@/util/file';
import { downloadAsBlob, getMimeFromFilename } from '../../../util/file';
import store from '@/store/modules/store';
import { mapState } from 'vuex';

export default {
  mixins: [AreaFormMixin, CommonMixin, FormMixin, LocationFormMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isedit: Boolean
  },
  data() {
    return {
      currentUrl: false,
      hasEdit: false,
      brands: [],
      storeTypes: [],
      statusOptions: [
        {
          label: this.$t('StoreManagement.operating'),
          value: 'OPERATING'
        },
        {
          label: this.$t('StoreManagement.inactive'),
          value: 'INACTIVE'
        }
      ],
      imageExtension: 'image/*,application/pdf'
    };
  },

  computed: {
    ...mapState('user', ['profile', 'defaultSettings']),
    brandOptions: function() {
      return brandEntityOptions(this.brands);
    },
    storeTypeOptions: function() {
      return storeTypeEntityOptions(this.storeTypes);
    }
  },
  mounted() {
    this.currentUrl = window.location.href;
    this.hasEdit = this.currentUrl.includes('edit');

  },
  async created() {
    try {
      const { data: brands } = await this.$repo.brand.getBrands();
      this.brands = brands;
      const storeTypes = await this.$repo.store.getStoreTypes();
      this.storeTypes = storeTypes;
      this.value.areaUnit = this.defaultSettings.storeFloorAreaUnit;
    } catch (err) {
      handleErrorUI(err);
    }
    await this.selectLocations();
  },
  methods: {

    updateField(value) {
      const copy = { ...this.value, ...value };
      this.$emit('input', copy);
    },
    updateSubField(subfield, value) {
      const copy = {
        ...this.value
      };
      copy[subfield] = {
        ...copy[subfield],
        ...value
      };
      this.$emit('input', copy);
    },
    toNumber(value) {
      const number = Number(value);
      return isNaN(number) ? null : number;
    },
    storeImageUploadHandler(file) {
      return this.$repo.store.uploadStoreImage(file);
    },
    fetcher(url) {
      return this.$repo.store.getStoreImageAsBase64(url);
    },
    formatter(input, url) {
      return generateImageDataUrl(input, url);
    },
    validate() {
      return !this.$v.$invalid;
    },
    selectedDisplayValue(options, valueId) {
      const selectedOption = options.filter(opt => opt.value.id === valueId);
      if (selectedOption.length) {
        return selectedOption[0].label;
      } else {
        return;
      }
    },
    dirty() {
      this.$v.$touch();
    },
    async handleDownload(file) {
      const data = await this.$repo.store.downloadFile(file.id);
      downloadAsBlob(data, getMimeFromFilename(file.fileName), file.fileName);
    },
    async selectLocations() {
      const cityId = this.value.selectedCity;
      try {
        if (this.value.selectedCountry != null) {
          await this.selectCountry(this.value.selectedCountry);
        }
        if (this.value.selectedRegion != null) {
          await this.selectRegion(this.value.selectedRegion);
        }
        if (this.value.selectedDistrict != null) {
          await this.selectDistrict(this.value.selectedDistrict);
        }
        if (this.value.selectedPrefecture != null) {
          await this.selectPrefecture(this.value.selectedPrefecture);
        }
        await this.selectCity(cityId);
      } catch (err) {
        handleErrorUI(err);
      }
    }
  },
  validations: {
    selectedCountry: {
      required
    },
    selectedRegion: {
      required
    },
    selectedDistrict: {
      required
    },
    selectedPrefecture: {
      required
    },
    selectedCity: {
      required
    },
    value: {
      name: {
        required
      },
      brandId: {
        required
      },
      storeTypeId: {
        required
      },
      status: {
        required
      },
      storeFloorPlan: {
        dimension: {

        },
        numberOfEntrances: {

          minValue: minValue(0)
        },
        ceilingHeight: {

          minValue: minValue(0)
        }
      },
      area: {
        required,
        minValue: minValue(0)
      },
      storeNumber: {
        required
      },
      openingDate: {
        required
      },
      address: {
        required
      },
      cityId: {
        required
      }
    }
  }
};
