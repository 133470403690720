
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false
    };
  }, // data
  computed: {
    ...mapState('comparison', ['stores']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared',
      'getCustomCalenderState'
    ])
  }, // computed
  async created() {
    try {
      this.loading = true;

      await this.fetchStores({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        storeCodes: this.getStoreCodesList,
        daysOfWeek: this.getDaysOfWeekList,
        compareStartDate: this.getStartingCompared,
        compareEndDate: this.getEndingCompared,
        useCustomCalender: this.getCustomCalenderState
      });
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: `${error}`
      });
    } finally {
      this.loading = false;
    }
  }, // created
  methods: {
    ...mapActions('comparison', ['fetchStores'])
  } // methods
}; // export default
