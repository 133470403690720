export const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (reportName: any, byte: any) => {
  const blob = new Blob([byte], {
    type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

export const saveByteArrayPdf = (reportName: any, byte: any) => {
  const blob = new Blob([byte], {
    type: 'application/pdf'
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

import { debug } from 'console';
import * as XLSX from 'xlsx';
import store from '../store';//'@/store'; // Replace with the actual path to your store

export const saveByteArrayExport = (reportName, byte, reportType, dataForDetailReport) => {
  try {
    var rptCol =1,rptRow=1;
    if(reportType == "detailed") { rptCol =4,rptRow=1;}
    else if(reportType == "summary"){rptCol =1,rptRow=2;}
    else if(reportType == "hourly"){rptCol =2,rptRow=1;}
    else if(reportType == "daily"){rptCol =3,rptRow=1;}
    else if(reportType == "AllStoresDaily"){rptCol =2,rptRow=1;}
    else if(reportType == "demographics"){rptCol =2,rptRow=1;}
    else if(reportType == "hourofdaykpi"){rptCol =3,rptRow=1;}

    const binaryData = Buffer.from(byte, 'base64');
    // Parse the binary data into a workbook
    const workbook = XLSX.read(binaryData, { type: 'buffer' });

    // Iterate through each sheet in the workbook
    workbook.SheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      // Get the range of cells in the worksheet


      const range = XLSX.utils.decode_range(worksheet['!ref']);

       // Initialize the column widths array if it doesn't exist
       if (!worksheet['!cols']) {
        worksheet['!cols'] = [];
      }


      // Iterate through each column
      if ( reportType == "detailed" && dataForDetailReport != null) {
        const acceptedLanguage = store.getters['user/getLocale'] === 'en' ? 'en' : 'ja';
        for (let col = rptCol; col <= range.e.c; col++) {
          // Set the width of each column to 20 characters
          worksheet['!cols'][col] = { wch: 14 };
        }
        for (let row = 2; row <= range.e.r; row++) {
          //row 2 col 10
          let mc = 7;
          for (let cl = 10; cl <= range.e.c; cl+=5) {
            const storeCodeAddress = XLSX.utils.encode_cell({ r: row, c: 0 }); // Assuming "店コード" is in the first column
            const storeCode = worksheet[storeCodeAddress].v;

            const kpiAddress = XLSX.utils.encode_cell({ r: 0, c: mc }); // Assuming "店コード" is in the first column
            const kpi = worksheet[kpiAddress].v;

            mc= mc+5

            let newValue = getValueByStoreAndMetric(storeCode, kpi,dataForDetailReport);

            const newValueAddress = XLSX.utils.encode_cell({ r: row, c: cl });

            const kpiAddress2 = XLSX.utils.encode_cell({  r: row, c: cl-3  }); // Assuming "店コード" is in the first column
            var val1 = parseFloat(worksheet[kpiAddress2].v.replace(/,/g, ''));
            //parseFloat(worksheet[kpiAddress2].v.replace(/,|\s/g, ''));

            const val2 = parseFloat(newValue.replace(/,/g, '')); //Number(newValue);
            var finalVal = "0";
            if(val2!=0)
              finalVal = ((Number(val1)/val2)*100).toFixed(2);

            if(acceptedLanguage == 'ja' && row == range.e.r)
            {
              const jaKpi = getJapeniesKpi(storeCode, kpi,dataForDetailReport);
              worksheet[kpiAddress] = {
                t: 's',
                v: jaKpi,
                // w: finalVal
                w: jaKpi,
                z: '#,##0.00'
              };
            }
            const newValuePerAddress = XLSX.utils.encode_cell({ r: row, c: cl+1 });

            if(finalVal.includes('.'))
            {
              worksheet[newValuePerAddress] = {
                t: 's',
                v: Number(finalVal).toLocaleString(),
                // w: finalVal
                w: Number(finalVal).toLocaleString(),
                z: '#,##0.00'
              };
            }
            else
            {
              worksheet[newValuePerAddress] = {
                t: 's',
                v: Number(finalVal).toLocaleString(),
               // w: finalVal
               w: Number(finalVal).toLocaleString(),
                z: '#,##0'
              };
            }

            if(newValue.includes('.'))
            {
              worksheet[newValueAddress] = {
                t: 's',
                v: Number(newValue).toLocaleString(),
                w: Number(newValue).toLocaleString(),
                z: '#,##0.00'

              };
            }
            else
            {
              worksheet[newValueAddress] = {
              t: 's',
              v: Number(newValue).toLocaleString(),
              w: Number(newValue).toLocaleString(),
              z: '#,##0'

            };}

          }
        }
      }
      // New Code
      if ( reportType == "detailed"){
        for (let col = rptCol; col <= range.e.c; col++) {
          const storeCodeAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Assuming "店コード" is in the first column
          const colValue = worksheet[storeCodeAddress].v;
          if(colValue.includes('%'))
          {
            for (let row = 2; row <= range.e.r; row++) {
              const storeCodeAddress2 = XLSX.utils.encode_cell({ r: row, c: col }); // Assuming "店コード" is in the first column
              const colValue2 = parseFloat(worksheet[storeCodeAddress2].v);
              if(colValue2)
              {

                const finalVal2 = (Math.floor(((colValue2) / 100)*1000)/1000).toString();//(Math.floor(colValue2) / 100).toString() + '2';

                worksheet[storeCodeAddress2] = {
                  t: 's',
                  v: finalVal2,
                  // w: finalVal
                  w: finalVal2,
                  z: '#,##0.00'
                };
              }
            }
          }
        }
      }
      if ( reportType == "summary")
      {
      for (let col = rptCol; col <= range.e.c; col++) {
        const storeCodeAddress = XLSX.utils.encode_cell({ r: 1, c: col });
        const colValue = worksheet[storeCodeAddress].v;
        if(colValue.includes('%'))
        {
          for (let row = 2; row <= range.e.r; row++) {
            const storeCodeAddress2 = XLSX.utils.encode_cell({ r: row, c: col });
            const colValue2 = parseFloat(worksheet[storeCodeAddress2].v);
            if(colValue2)
            {
              const finalVal2 = (Math.floor(((colValue2) / 100)*1000)/1000).toString();//(Math.floor(colValue2) / 100).toString();

              worksheet[storeCodeAddress2] = {
                t: 's',
                v: finalVal2,
                // w: finalVal
                w: finalVal2,
                z: '#,##0.00'
              };
            }
          }
        }
      }
      }
      if ( reportType == "demographics")
      {
      for (let col = rptCol; col <= range.e.c; col++) {
          for (let row = 1; row <= range.e.r; row++) {
            const storeCodeAddress2 = XLSX.utils.encode_cell({ r: row, c: col });
            const colValue2 = parseFloat(worksheet[storeCodeAddress2].v);
            if(colValue2)
            {
              const finalVal2 = (Math.floor(((colValue2) / 100)*1000)/1000).toString();//(Math.floor(colValue2) / 100).toString();
              worksheet[storeCodeAddress2] = {
                t: 's',
                v: finalVal2,
                // w: finalVal
                w: finalVal2,
                z: '#,##0.00'
              };
            }
          }
        }
      }
      // New Code
      for (let col = rptCol; col <= range.e.c; col++) {
        // Start iterating from the second row (skip the header row)
        for (let row = rptRow; row <= range.e.r; row++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cell = worksheet[cellAddress];

          // Check if the cell contains a value
          if (cell && cell.v !== undefined) {
            // Trim the cell value (remove leading and trailing whitespace)
            if (typeof cell.v === 'string') {
              cell.v = cell.v.trim();
            }

            if(cell.v == 'SV' )
              {
                worksheet[cellAddress] = {
                  v: 'エリアマネージャー',
                };
              }
            // Check if the cell value contains commas and convert it to a number
            if (cell.v.includes(',')) {
              const numericValue = parseFloat(cell.v.replace(/,/g, ''));
              if (!isNaN(numericValue)) {
                // Set the cell type to 'n' (number) and apply number formatting
              if(cell.v.includes('.'))
                worksheet[cellAddress] = {
                  t: 'n',
                  v: numericValue,
                  w: numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 }),
                  z: '#,##0.00'

                };
              else
                  worksheet[cellAddress] = {
                    t: 'n',
                    v: numericValue,
                    w: numericValue.toLocaleString('en-US', { maximumFractionDigits: 0 }),
                    z: '#,##0'
                  };
              }
            }
            else {

              // If the cell value is a numeric string, convert it to a number
              const numericValue = parseFloat(cell.v);
              if (!isNaN(numericValue)) {
                worksheet[cellAddress] = {
                  t: 'n',
                  v: numericValue,
                };
              }
            }
          }
        }
      }

    });


    // Create a new binary representation of the updated workbook as base64
    const updatedBinaryData = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    // Convert the base64 data to Blob
    const blob = new Blob([Uint8Array.from(atob(updatedBinaryData), c => c.charCodeAt(0))], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Create a download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;

    // Trigger the download
    link.click();
  } catch (error) {
    //console.error('Error processing the Excel file:', error);
    // Handle the error (e.g., show a message to the user)
  }
};


// Function to get a value by store code and metric ID
function getValueByStoreAndMetric(storeCode, metricKey, data) {
  // Check if the store code exists in the data

  if (data.hasOwnProperty(storeCode)) {
      const storeData = data[storeCode];
      if(metricKey.replace(" ", "").toUpperCase()  == "UnitPerTransaction".toUpperCase() )
        metricKey = "Units Per Transaction";
      else if(metricKey.replace(" ", "").toUpperCase()  == "SalesPerTsubo".toUpperCase() )
      metricKey = "Sales Per Sqm";
      else if(metricKey.replace(" ", "").toUpperCase()  == "TrafficPerTsubo".toUpperCase() )
      metricKey = "Traffic Per Sqm";
      else if(metricKey.replace(" ", "").toUpperCase()  == "TotalItemSold".toUpperCase() )
        metricKey = "Units Sold";
      //
      // else if(metricKey.replace(" ", "").toUpperCase()  == "売上金額".toUpperCase() )
      // metricKey = "売上";



      let metricKeyN=metricKey.replace(" ", "").replace(" ", "").toUpperCase();

      // Find the data entry with the matching metricKey
      const metricData = storeData.find(item => item.metricKey.replace(" ", "").replace(" ", "").replace(" ", "").toUpperCase() == metricKeyN);

      // If found, return the value
      if (metricData) {
          return metricData.value.replace(".00", ""). replace(/[^0-9.]/g, "");
      }
  }


  return "0 ";
}
function getJapeniesKpi(storeCode, enKpi, dataForDetailReport)
{
  if(enKpi.replace(" ", "").toUpperCase()  == "UnitPerTransaction".toUpperCase() )
    enKpi = "Units Per Transaction";
  else if(enKpi.replace(" ", "").toUpperCase()  == "SalesPerTsubo".toUpperCase() )
    enKpi = "Sales Per Sqm";
  else if(enKpi.replace(" ", "").toUpperCase()  == "TrafficPerTsubo".toUpperCase() )
    enKpi = "Traffic Per Sqm";
    else if(enKpi.replace(" ", "").toUpperCase()  == "TotalItemSold".toUpperCase() )
    enKpi = "Units Sold";

  const comingKpiId = getMetricID(storeCode, enKpi, dataForDetailReport);
  if(comingKpiId == "MissingName")
  {return enKpi;}
  const storeState: any = store.state;
  const kpi = storeState.user.kpiListja.find(
    k => k.metricKey === comingKpiId
  );

  return kpi?.title?kpi?.title:"MissingName";
}
function getMetricID(storeCode, metricKey, data) {

      if (data.hasOwnProperty(storeCode)) {
          const metrics = data[storeCode];
          const metricKeyNew = metricKey.replace(" ", "").replace(" ", "").toUpperCase();
          for (const metric of metrics) {
              if (metric.metricKey.replace(" ", "").replace(" ", "").replace(" ", "").toUpperCase() === metricKeyNew) {
                  return metric.metricID;
              }
          }
      }

  return "MissingName"; // Metric key not found
}
export function getNameByStoreCode(storeCode) {

  const stores = store.getters['user/getStoresCodeNameList'];

    if(stores && storeCode){
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].storeCode === storeCode) {
        return stores[i].name;
      }
    }
  }
  return "Unknown Store Name";
}
export function getStoreCodesByLocations(groupType, name) {
  const data = store.getters['user/getStoreGroups'];

  if (groupType === 'areaManagers') {
    const areaManager = data.areaManagers.find(manager => manager.name === name);
    if (areaManager) {
      return areaManager.stores.map(store => store.storeCode);
    }
  } else if (groupType === 'regions') {
    const region = data.regions.find(region => region.name === name);
    if (region) {
      const storeCodes = [];
      region.districts.forEach(district => {
        storeCodes.push(...district.stores.map(store => store.storeCode));
      });
      return storeCodes;
    }
  } else if (groupType === 'peerGroups') {
    const peerGroup = data.peerGroups.find(group => group.name === name);
    if (peerGroup) {
      return peerGroup.stores.map(store => store.storeCode);
    }
  } else if (groupType === 'districts') {
    const district = data.regions.flatMap(region => region.districts).find(district => district.name === name);
    if (district) {
      return district.stores.map(store => store.storeCode);
    }
  }

  return [];
}
