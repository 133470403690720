import { ClientManager } from '@/util/client';
import AuthController from './AuthController';
import BillingController from './BillingController';
import LoginController from './LoginController';
import TenantController from './TenantController';

export interface AccountControllers {
  auth: AuthController;
  login: LoginController;
  tenant: TenantController;
  billing: BillingController;
}

export default (clientManager: ClientManager): AccountControllers => ({
  auth: new AuthController(clientManager.apiGatewayClient),
  login: new LoginController(clientManager.consoleClient),
  tenant: new TenantController(clientManager.consoleClient),
  billing: new BillingController(clientManager.consoleClient)
});
