import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '../store';
import router from '@/router';
import config from '@/config';

axios.defaults.baseURL = config.reportingURL;
axios.defaults.headers.common.Authorization = store.getters['user/getToken']
  ? `bearer ${store.getters['user/getToken']}`
  : '';

axios.defaults.headers['Accept-Language'] = store.getters['user/getLocale']
  ? store.getters['user/getLocale']
  : 'en-us';

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  }, // function(config)
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  } // function(error)
); // axios.interceptors.request.use

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  }, // function(response)
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // temporary commit to avoid authentication
    // console.log('Here is the error: axios.ts ->  axios.interceptors.response.use' );
    // console.log(error);
    if (error.response && error.response.status === 401) {
      // console.log('Here is the error2: axios.ts ->  axios.interceptors.response.use' );
      // console.log(error.response);

      store.dispatch('user/cleanupAfterLogout');
    }

    return Promise.reject(error?.response?.data?.message);
  } // function(error)
); // axios.interceptors.response.use

Vue.use(VueAxios, axios);
