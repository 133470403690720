import {
  APIImportUpload,
  APIDownloadSample,
  APIDownloadManualFile
} from '@/api/import';
import { base64ToArrayBuffer, saveByteArray } from '../../util/utilFunctions';

const initialState = {}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setStores(state, { stores }) {
      state.stores = stores;
    } // setStores
  }, // mutations
  actions: {
    async importUpload({ commit }, uploadData) {
      return new Promise(async (resolve, reject) => {
        try {
          const res: any = await APIImportUpload(
            uploadData.formData,
            uploadData.importRequestBody
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    }, // fetchStores
    async downloadSample({ commit }, requestBody) {
      return new Promise(async (resolve, reject) => {
        try {
          const res: any = await APIDownloadSample(requestBody);
          const sampleArr = base64ToArrayBuffer(res.data.encodedData);
          saveByteArray(`${res.data.fileName}`, sampleArr);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    async downloadManualFile({ commit }, requestBody) {
      return new Promise(async (resolve, reject) => {
        try {
          const res: any = await APIDownloadManualFile(requestBody);
          const sampleArr = base64ToArrayBuffer(res.data.encodedData);
          saveByteArray(`${res.data.fileName}`, sampleArr);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    }
  } // actions
}; // export default
