
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["dataAccessibleRows", "district"],
  data() {
    return {
      peerGroupsAccessibleColumns: [
        {
          name: "peerGroupType",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "peerGroupName",
          align: "left",
          label: this.$store.state.user.translate.description,
          field: "peerGroupName",
          sortable: true,
        },
        {
          name: "action",
          label: this.$store.state.user.translate.actions,
          field: "action",
        },
      ],
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
      visible: false,
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.dataAccessibleRows.length / this.pagination.rowsPerPage);
    },
  },
  methods: {
    deletePeerGroup(peerGroup) {
      this.$emit("deletePeerGroup", peerGroup);
    },
    editPeerGroupDialog(peerGroup) {
      this.$emit("editPeerGroupDialog", peerGroup);
    },
    openPeerGroupModel() {
      this.$emit("openPeerGroupModel");
    },
  },
};
