import {
  APIAddAlert,
  APIChangeAlertStatus,
  APIDeleteAlert,
  APIFetchAlertByID,
  APIFetchAllAlertsOverview,
  APIFetchUserGroup,
  APIFetchUserGroupHierarchy,
  APIFetchUsersByLine,
  APIUpdateAlertByID
} from '@/api/alert';

const initialState = {
  alertList: [],
  alertSelected: {},
  alertRetrieved: {},
  alertID: '',
  usersList: [],
  tableData: [],
  userGroupsList: [],
  userHierarchy: [],
  showDialog: false,
  settingsMode: 'add'
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setAlertList(state, payload) {
      state.alertList = payload;
    },
    setAlertSelected(state, payload) {
      state.alertSelected = payload;
    },
    setAlertID(state, payload) {
      state.alertID = payload;
    },
    setUsers(state, payload) {
      state.usersList = payload;
    },
    setUserGroups(state, payload) {
      state.userGroupsList = payload;
    },
    setUserHierarchy(state, payload) {
      state.userHierarchy = payload;
    },
    setDialog(state, payload) {
      state.showDialog = payload;
    },
    setAlert(state, payload) {
      state.alertSelected = payload;
    },
    setMode(state, payload) {
      state.settingsMode = payload;
    },
    setTableData(state, payload) {
      state.tableData = payload;
    },
    setAlertRetrieved(state, payload) {
      state.alertRetrieved = payload;
    }
  }, // mutations
  actions: {
    async fetchUsers({ commit }) {
      let res = await APIFetchUsersByLine({});
      commit(
        'setUsers',
        res?.data?.map((v, key) => ({ ...v, id: key + 1 })) || []
      );
    },
    async fetchUserGroup({ commit }) {
      let res = await APIFetchUserGroup({});
      commit('setUserGroups', res.data);
    },
    async fetchUserGroupHierarchy({ commit }) {
      let res = await APIFetchUserGroupHierarchy({});
      commit('setUserHierarchy', res.data);
    },
    async addAlertData({ commit }, alert) {
      let res = await APIAddAlert(alert);
    },
    async fetchAllAlertsOverview({ commit }) {
      let res = await APIFetchAllAlertsOverview({});
      commit('setTableData', res.data);
    },
    async fetchAlertByID({ commit }, alertID) {
      let res = await APIFetchAlertByID({}, alertID);
      commit('setAlertRetrieved', res.data);
    },
    async updateAlertByID({ commit }, { data, id }) {
      let res = await APIUpdateAlertByID(data, id);
    },
    async changeAlertStatus({ commit }, { status, val }) {
      let res = await APIChangeAlertStatus({ status: status }, val);
    },
    async deleteAlert({ commit }, alertID) {
      let res = await APIDeleteAlert({}, alertID);
    }
  } // actions
}; // export default
