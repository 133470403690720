import { City, Country, District, Prefecture, Region } from '@/models/Location';

import Entity from '@/models/Entity';
import map from 'lodash/map';

export const entityOptions = (entities: Entity[]) => {
  return map(entities, entity => ({
    label: entity.name,
    value: entity.id
  }));
};

export const brandEntityOptions = (entities: BrandEntity[]) => {
  return map(entities, entity => ({
    label: entity.brand,
    value: entity
  }));
};
export const storeTypeEntityOptions = (entities: StoreTypeEntity[]) => {
  return map(entities, entity => ({
    label: entity.storetype,
    value: entity
  }));
};
export const regionEntittyOptions = (regions: Region[]) => {
  return map(regions, region => ({
    label: region.region,
    value: { id: region.id, region: region.region }
  }));
};
export const districtEntityOptions = (districts: District[]) => {
  return map(districts, district => ({
    label: district.district,
    value: { id: district.id, district: district.district }
  }));
};

export const prefectureEntityOptions = (prefectures: Prefecture[]) => {
  return map(prefectures, prefecture => ({
    label: prefecture.perfecture,
    value: { id: prefecture.id, prefecture: prefecture.perfecture }
  }));
};

export const cityEntityOptions = (cities: City[]) => {
  return map(cities, city => ({
    label: city.city,
    value: { ...city }
  }));
};

export const countryOptions = (countries: Country[]) => {
    return [
      {
        label: 'Japan',
        value: { id: '00000000-0000-0000-0000-000000000001', country: 'Japan' }
      }
    ];
};

export const regionOptions = (regions: Region[]) => entityOptions(regions);

export const regionOptionsList = (regions: Region[]) =>
  regionEntittyOptions(regions);

export const prefectureOptions = (prefectures: Prefecture[]) =>
  entityOptions(prefectures);

export const prefectureOptionsList = (prefectures: Prefecture[]) =>
  prefectureEntityOptions(prefectures);

export const districtOptions = (districts: District[]) =>
  entityOptions(districts);

export const districtOptionsList = (districts: District[]) =>
  districtEntityOptions(districts);

export const cityOptions = (cities: City[]) => entityOptions(cities);
export const cityOptionsList = (cities: City[]) => cityEntityOptions(cities);

export interface Option {
  label: string;
  value: string | number;
}

export interface BrandEntity extends Entity {
  brand: string;
}

export interface StoreTypeEntity extends Entity {
  storetype: string;
}
