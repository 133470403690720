
export default {
  props: {
    order: { default: () => ({}), type: Object }
  },
  methods: {
    formatAsDateTime(val: Date): string {
      if (val == null) return null;
      return val.toLocaleString();
    }
  }
};
