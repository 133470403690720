import { render, staticRenderFns } from "./RemovePaymentCard.vue?vue&type=template&id=001b1a29&"
import script from "./RemovePaymentCard.vue?vue&type=script&lang=js&"
export * from "./RemovePaymentCard.vue?vue&type=script&lang=js&"
import style0 from "./RemovePaymentCard.vue?vue&type=style&index=0&id=001b1a29&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QCardActions} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QCardActions})
