import { render, staticRenderFns } from "./UserForm.vue?vue&type=template&id=c7a0e198&"
import script from "./UserForm.vue?vue&type=script&lang=js&"
export * from "./UserForm.vue?vue&type=script&lang=js&"
import style0 from "./UserForm.vue?vue&type=style&index=0&id=c7a0e198&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UserForm.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./UserForm.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QInput,QSelect,QTable,QTd} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInput,QSelect,QTable,QTd})
