import { UserDTO } from '@/models/User';
import { createBackendError } from '@/util/error';
import HttpController from '../HttpController';

export interface LoginResponse {
  tenant: string;
  user: UserDTO;
}

export default class LoginController extends HttpController {
  public async logout() {
    await this.client.post('/auth/logout');
  }

  public async login(username: string, password: string) {
    try {
      const { data } = await this.client.post(
        `${process.env.VUE_APP_LOGIN_URL}/console/login`,
        null,
        {
          params: { username, password }
        }
      );
      return data as LoginResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
