
export default {
  name: 'SelectReportDialog',
  data() {
    return {
      dataType: 'daily'
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
};
