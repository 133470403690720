import { Currency, CurrencyDTO } from './Currency';

export class PlanOrderQuoteItem {
  productName: string;
  productId: string;
  price: number;
  quantity: number;
  subtotal: number;
}

class PlanOrderQuote {
  currency: Currency;
  discount: number;
  downgradePlan: boolean;
  grandTotal: number;
  taxAmount: number;
  taxAmountType: string;
  totalOrder: number;
  totalTax: number;
  orders: PlanOrderQuoteItem[];

  constructor(initializer: Partial<PlanOrderQuote>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: PlanOrderQuoteDTO): PlanOrderQuote {
    return new PlanOrderQuote({
      currency: dto.currency,
      discount: dto.discount,
      downgradePlan: dto.downgradePlan,
      grandTotal: dto.grandTotal,
      taxAmount: dto.taxAmount,
      taxAmountType: dto.taxAmountType,
      totalOrder: dto.totalOrder,
      totalTax: dto.totalTax,
      orders: dto.itemResponses
    });
  }
}

interface PlanOrderQuoteDTO {
  currency: CurrencyDTO;
  discount: number;
  downgradePlan: boolean;
  grandTotal: number;
  taxAmount: number;
  taxAmountType: string;
  totalOrder: number;
  totalTax: number;
  itemResponses: PlanOrderQuoteItem[];
}

export default PlanOrderQuote;
