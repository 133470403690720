import { APIForgotPasword } from '@/api/authSSO';
import { CustomerDTO } from '@/models/Customer';
import { PaginatedResponse } from '@/models/Pagination';
import { TenantDTO } from '@/models/Tenant';
import clientManager from '@/util/client';
import { createBackendError } from '@/util/error';
import { da } from 'date-fns/locale';
import qs from 'querystring';
import HttpController from '../HttpController';

export interface InviteUserRequest {
  approvalPrivilege: boolean;
  email: string;
  firstname: string;
  lastname: string;
  tenant: string;
  username: string;
}

export type InviteUserResponse = InviteUserRequest;

export interface SignupResponse {
  id: string;
  email: string;
  domain: string;
  approved: boolean;
  admin: boolean;
  invitedUser: boolean;
  requestedUser: boolean;
  verificationCode: string;
  tenant?: TenantDTO;
}

export type VerifyResponse = SignupResponse;

export interface SetPasswordRequest {
  email: string;
  password: string;
  confirmPassword: string;
}

export type GetCustomersResponse = PaginatedResponse<CustomerDTO>;

export default class CustomerController extends HttpController {
  public async inviteUser(req: InviteUserRequest) {
    try {
      const { data } = await this.client.post(
        '/console/customers/invited-user',
        req
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async signup(email: string) {
    try {
      const message = (await this.client.post(
        '/console/customers/signup',
        qs.stringify({ email })
      )) ?? { data: 'Network Error. Please try again later' };
      return message.data as SignupResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async verify(email: string, verificationCode: string) {
    try {
      const { data } = await this.client.post('/console/customers/verify', {
        email,
        verificationCode
      });
      clientManager.setSession({ tenant: data?.tenant?.name ?? '' });
      return data as VerifyResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async forgetPassword(email: string) {
    try {
      const response = await APIForgotPasword({ body: { email } });
      return response;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async setPassword(req: SetPasswordRequest) {
    try {
      await this.client.post('/console/customers/set-password', req);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async notifyAdministrator(
    domain: string,
    email: string,
    requestedUser: boolean
  ) {
    try {
      const { data } = await this.client.post(
        '/console/customers/notify-admin',
        {
          domain,
          email,
          requestedUser
        }
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getCustomers() {
    try {
      const { data } = await this.client.get('/console/customers', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetCustomersResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getCustomerByEmail(email: string) {
    try {
      return this.client.get('/console/customers/findByEmail/' + email, {});
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
