import { convertFromAreaManagerDTO } from '@/models/formatters/AreaManagerFormatter';
import AreaManager from '@/models/AreaManager';
import AreaManagerController from '@/services/settings/AreaManagerController';
import map from 'lodash/map';

export interface GetAreaManagersResult {
  areaManagers: AreaManager[];
}

export default class AreaManagerRepository {
  private areaManagerController: AreaManagerController;

  constructor(areaManagerController: AreaManagerController) {
    this.areaManagerController = areaManagerController;
  }

  public async getAreaManagers() {
    const res = await this.areaManagerController.getAreaManagers();
    return {
      areaManagers: map(res.content, dto => convertFromAreaManagerDTO(dto))
    };
  }

  public async getAreaManager(id: string) {
    const dto = await this.areaManagerController.getAreaManager(id);
    return convertFromAreaManagerDTO(dto);
  }

  public async upsertAreaManager(areaManager: any, ids: string[]) {
    if (areaManager.id) {
      await this.updateAreaManager(
        areaManager.id,
        areaManager.storeAreaManagerIdToStoreId,
        areaManager.title,
        ids
      );
    } else {
      await this.createAreaManager(areaManager.title, ids);
    }
  }

  public async deleteAreaManager(id: string, msg: string) {
    await this.areaManagerController.deleteAreaManager(id, msg);
  }

  private async createAreaManager(title: string, ids: string[]) {
    await this.areaManagerController.createAreaManager({
      title: title,
      ids: ids,
      description: ''
    });
  }

  private async updateAreaManager(
    id: string,
    storeAreaManagerIdToStoreId: any[],
    title: string,
    ids: string[]
  ) {
    await this.areaManagerController.updateAreaManager(id, {
      title: title,
      description: '',
      storeIds: ids,
      id: id
    });
  }
}
