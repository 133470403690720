import HttpController from '../HttpController';
import { createBackendError } from '@/util/error';

export default class SubtaskController extends HttpController {
  public async getCount() {
    try {
      const res = await this.client.get('/console/onboarding-subtasks/count');
      return res.data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
