import { PostRequestFidaExport,GetRequestSSO } from '@/util/http';
import { genRawData, trfIn } from './apiDataManagers/fakegen';
import store from '@/store/';
import demoHandler from './apiDataManagers/demo_handler';

const client_id = 'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688';
const client_secret = 'fp329-polk80s-ye04p1yy-45hx874z06';

async function postReq(url, params) {
  let result = await PostRequestFidaExport(url, params);
  if(result.data.response.demoStores) {
    console.log("Before demo", demoHandler, params)
    result.data.response = demoHandler.toDemo(result.data.response, params.storeCodes)
  }

  return result;
}

export async function APIGetKpisPlainDataFida(params: any) {
  params.client_id = client_id;
  params.client_secret = client_secret;
  // if (userCheck()) {
  //   return getFakeData(params, false);
  // } else {
    return postReq('/api/getKpisPlainDataV2', params);
  // }
}
//NEw Apis
//
export function APIGetTriggeredMessages(params: any) {
    return GetRequestSSO('/api/getTriggeredMessages', params );
}
export function APIGetReportingSumByLocationDataFidaV2(params: any) {
  params.client_id = client_id;
  params.client_secret = client_secret;

  // if (userCheck()) {
  //   if((params.storeCodes.length == 1 && params.storeCodes[0] == "") || params.startDate ==  null) 
  //     return []
  //   else
  //   return getFakeData(params);
  // } else {
  if((params.storeCodes.length == 1 && params.storeCodes[0] == "") || params.startDate ==  null) 
    return []
  else {
    if(params.daysOfWeek) {
      params.days = params.daysOfWeek;
      delete params.daysOfWeek;
      if(typeof params.days === 'string' ) {
        params.days = params.days.split(',')
      }
    }
    return postReq('/api/getReportingData', params );
  }
}

export async function APIGetKpisPlainDataFidaV2(params: any) {
  //Power Hour
  params.client_id = client_id;
  params.client_secret = client_secret;
  // if (userCheck()) {
  //   params.kpiTypes = [...params.kpiTypes, 'in', 'out'];
  //   return getFakeData(params, true);
  // } else {
    return postReq('/api/getKpisPlainDataV2', params);
  // }
}

function fillterKeys(kpiType, fakeRes) {
  if (!fakeRes.length) return;
  const filterKeys = fakeRes.map(kpi => {
    const availableKeys = {};
    kpiType.forEach(key => {
      if (kpi.hasOwnProperty(key)) {
        if (key === '_id') {
          availableKeys[key] = kpi[key].replaceAll('-', '');
        } else {
          availableKeys[key] = kpi[key];
        }
      }
    });
    return availableKeys;
  });
  return filterKeys;
}

function getFakeData(newParam, plainData = false) {
  let {
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    storeCodes,
    kpiTypes,
    frequency,
    days
  } = newParam;
  
  let kpiTypesWithId = kpiTypes.concat(['_id', 'date']);
  let payloadSelected = {
    dateS: startDate,
    dateE: endDate,
    storeCodes: storeCodes.toString(),
    // resp: startDate == endDate
    resp: plainData ? "hourly" : startDate == endDate,
    days
  };
  let payloadCompare = {
    dateS: compareStartDate,
    dateE: compareEndDate,
    storeCodes: storeCodes.toString(),
    // resp: compareStartDate == compareEndDate
    resp: plainData ? "hourly" : startDate == endDate,
    days
  };

  let isCompDates = compareStartDate && compareEndDate;
  let fakeData = fillterKeys(kpiTypesWithId, genRawData(payloadSelected));
  let fakeDataComp =
    isCompDates && fillterKeys(kpiTypesWithId, genRawData(payloadCompare));
  if (frequency === 'all') {
    fakeData = fakeData.map(el => {
      el._id = el._id.split('_')[1];
      return { ...el };
    });
    if(isCompDates) {
      fakeDataComp = fakeDataComp.map(el => {
        el._id = el._id.split('_')[1];
        return { ...el };
      });
    }
  }
  // fro head map traffic report
  if (plainData) {
    fakeData = trfIn(fakeData, false)
    fakeDataComp = trfIn(fakeDataComp, false)
  }
  
  let data = {
    data: {
      status: 200,
      message: 'Success',
      transKey: 'SUCCESS',
      response: {
        kpis: fakeData,
        ...(isCompDates && { kpisCompare: fakeDataComp })
      },
      callName: ''
    }
  };
  return data;
}
export function userCheck() {
  let { user } = store.state['user']['defaultSettings'];
  let isDemo = user?.response['userDetails']['tenant']['isDemo'];
  return isDemo;
}