import { CreateElement, RenderContext } from 'vue';

export const props = {
  type: {
    type: String,
    default: 'button'
  },
  label: {
    type: String,
    required: true
  },
  outline: {
    type: Boolean,
    default: false
  },
  href: {
    type: String,
    default: null
  },
  disable: {
    type: Boolean,
    default: false
  }
};

export const render = (h: CreateElement, context: RenderContext) => {
  const $style = (context as any).$style;
  const { data, props, listeners } = context;
  const baseClass = {
    'base-button': true,
    [$style.button]: true,
    [$style.outline]: props.outline
  };
  const baseProps: any = {
    class: [baseClass, data.class],
    attrs: {
      disabled: props.disable
    }
  };

  let tag = 'button';
  if (props.type === 'submit') {
    tag = 'input';
    baseProps.attrs.type = props.type;
    baseProps.attrs.value = props.label;
  } else if (props.href) {
    tag = 'a';
    baseProps.attrs.href = props.href;
  }

  let children = null;
  if (props.type !== 'submit') {
    baseProps.on = listeners;
    children = props.label;
  }

  return h(tag, baseProps, children);
};
