
import { mapActions } from 'vuex';
import { mapMutations, mapGetters, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('user', [
      'profile',
      'userPermissions',
      'defaultSettings',
      'clientProfile'
    ]),
    ...mapGetters('tenant', ['config'])
  },
  methods: {
    ...mapGetters('user', ['getLocale', 'isAuthenticated']),
    ...mapActions('tenant', ['reset']),
    ...mapActions('user', ['clearSession', 'fetchKpiPreferences', 'logout']),
    startFreePlan() {
      window.open('https://juk1i3mvd2k.typeform.com/to/Y21dgww6', '_blank');
      //windows.create({"url": 'auth/login', "incognito": true});
    },
    async signout() {
      await this.logout('');
    }
  }
};
