
export default {
  data() {
    return {
      allData: [],
      maxVal: 0,
      minVal: 0,
      median: 0,
      series: [
        {
          name: 'Shirt',
          data: this.generateData(12, {
            min: 0,
            max: 90
          })
        },
        {
          name: 'Item 2',
          data: this.generateData(12, {
            min: 0,
            max: 90
          })
        },
        {
          name: 'Item 3',
          data: this.generateData(12, {
            min: 0,
            max: 90
          })
        },
        {
          name: 'Item 4',
          data: this.generateData(12, {
            min: 0,
            max: 90
          })
        },
        {
          name: 'Item 5',
          data: this.generateData(12, {
            min: 0,
            max: 90
          })
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          categories: [
            '10:00',
            '',
            '12:00',
            '',
            '14:00',
            '',
            '16:00',
            '',
            '18:00',
            '',
            '20:00',
            ''
          ]
        },
        colors: ['#3e7cd2']
      }
    };
  },
  methods: {
    generateData(count, range) {
      let arr = [];
      for (let i = 0; i < count; i++) {
        let values =
          Math.floor(Math.random() * (range.max - range.min + 1)) + range.min;
        arr.push(values);
      }
      return arr;
    }
  }
};
