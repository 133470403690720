
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import Store from '@/models/Store';
import AreaManager from '@/models/AreaManager';
import { handleErrorUI } from '@/util/error';
import map from 'lodash/map';
import StoreTable from '@/components/Management/Store/Table.vue';
import StoresCheckbox from '@/components/Common/StoresCheckbox/StoresCheckbox.vue';

export default {
  name: 'PeerGroupForm',
  components: { StoresCheckbox, StoreTable },
  mixins: [CommonMixin, FormMixin],
  props: {
    backLocation: { type: [String, Object], required: true },
    isEdit: { type: Boolean, default: true },
    mode: { type: String, default: 'Edit' },
    areaManagerId: { type: String, default: '' }
  },
  data() {
    return {
      loading: false,
      areaManager: {},
      selectedStores: []
    };
  },
  computed: {
    areaManagerID() {
      return this.$route.query.id;
    },
    pageTitle() {
      return this.mode === 'View'
        ? this.$t('CreateAreaManager.ViewAreaManager')
        : this.isEdit
        ? this.$t('CreateAreaManager.EditAreaManager')
        : this.$t('CreateAreaManager.CreateAreaManager');
    }
  },
  mounted() {
    if (this.areaManagerID) this.loadAreaManager(this.areaManagerID);
  },
  methods: {
    setStoresList(val) {
      this.selectedStores = val;
    },
    loadAreaManager(id: string) {
      this.loading = true;
      this.$repo.areaManager.getAreaManager(id).then(areaManager => {
        this.areaManager = areaManager;
        this.selectedStores = areaManager.stores;
        this.loading = false;
      }, handleErrorUI);
    },
    removeStore(removedStore: Store) {
      this.selectedStores = this.selectedStores.filter(
        store => store.id !== removedStore.id
      );
    },
    async submitAreaManager() {
      if (this.selectedStores.length > 0) {
        try {
          await this.$repo.areaManager.upsertAreaManager(
            { ...this.areaManager, id: this.areaManagerID } as AreaManager,
            map(this.selectedStores, store => store.id)
          );

           if(this.areaManager.id != null && this.areaManager.id !=undefined && this.areaManager.id !=undefined)
          {
            this.$q.notify({
              type: 'positive',
              message: this.$t('SettingsManagement.areaMangerUpdate'),
              position: 'top'
            });
          } else {
             this.$q.notify({
              type: 'positive',
              message: this.$t('SettingsManagement.areaMangerAdded'),
              position: 'top'
            });
          }
          this.$router.push(this.backLocation).catch(_ => {});
        } catch (err) {
          handleErrorUI(err);
        }
      } else {
        this.$q.notify({
          position: 'top',
          message: this.$t('AuthPage.at_least_one_store'),
          type: 'negative'
        });
      }
    }
  }
};
