
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI } from '@/util/error';
import { Pagination } from '@/util/pagination';

export default {
  mixins: [CommonMixin],
  props: {
    placeholder: { default: null, type: String },
    value: { default: () => [], type: Array },
    hideLabel: { default: false, type: Boolean },
    noMargin: { default: false, type: Boolean },
    showValue: { default: false, type: Boolean },
    showDropdownIcon: { default: false, type: Boolean },
    clearable: { default: false, type: Boolean },
    mandatory: { default: false, type: Boolean },
    haserror: { default: false, type: Boolean }
  },
  data() {
    return {
      cachedStoreList: []
    };
  },
  computed: {
    label() {
      if (this.hideLabel) return null;
      return this.$t('SettingsManagement.store');
    },
    valueShown() {
      if (!this.showValue || this.value == null || this.value.length === 0)
        return null;
      const names = this.value.map(store => store.name);
      return names.join(', ');
    },
    placeholderShown() {
      if (this.value != null && this.value.length > 0) return null;
      return this.$t('type_store');
    }
  },
  async created() {
    try {
      const { data } = await this.$repo.store.getStores();
      this.cachedStoreList = data;
    } catch (err) {
      handleErrorUI(err);
    }
  },
  methods: {
    async searchStore(term, update) {
      const selectedStoreIds = this.value.map(store => store.id);

      const request = new Pagination();
      request.addQuery('name', term.toLowerCase());

      const { data: stores } = await this.$repo.store.getStores(request);
      const selectableStores = stores.filter(
        store => selectedStoreIds.indexOf(store.id) < 0
      );
      update(() => {
        this.cachedStoreList = selectableStores;
      });
    },
    onInput(store) {
      this.$emit('input', [...this.value, store]);
    },
    onClear() {
      this.$emit('input', []);
    }
  }
};
