
import { mapState, mapActions } from 'vuex';
import{downloadTrafficReportFida} from '@/responseConversion/trafficResponse';
export default {
  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    ...mapState('filter', ['filter'])
  },
  watch: {},

  methods: {
    async onItemClick(params) {
      this.isLoading = true;

        const filterParams = {
          fileType: params.fileType,
          fileFormat: params.fileFormat,
          startDate: this.filter.startingPeriod,
          endDate: this.filter.endingPeriod,
          storeCodes: this.filter.storeCodes.join(','),
          kpiTypes: '',
          frequency: ''
        };
          filterParams.storeCodes = this.filter.storeCodes;
          filterParams.kpiTypes = ["trafficOut","trafficIn"];
          if(params.fileType == 'Daily')
            filterParams.frequency = 'Daily';
          else
            filterParams.frequency = 'Hourly';

          await downloadTrafficReportFida(filterParams);

      this.isLoading = false;
    },
    ...mapActions('traffic', ['downloadTrafficReport'])
  }
};
