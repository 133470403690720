import store from '../store';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
//single
export function getStoreTimings(date, storeCode) {
  // Your array of store objects
  const stores =  store.getters['user/getStoresCodeNameList'];

  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  // Find the store by storeCode
  const storeList = stores.find(st => st.storeCode === storeCode);

  if (!storeList) {
      return 'Store not found';
  }

  const dayIndex = new Date(date).getDay();
  const day = daysOfWeek[dayIndex];

  // Find the timings for the specific day
  const timings = storeList?.storeTimings?.find(timing => timing.day === day);

  if (!timings) {
      return 'Timings not available for this day';
  }

  return {
      openingTime: timings.openingTime?Number(timings.openingTime.split(':')[0]):10,
      closingTime: timings.closingTime?Number(timings.closingTime.split(':')[0]):20
  };
}
//multiple
export function getStoreTimingsForDatesAndStores(dates, storeCodes) {
  // Your array of store objects

  const stores = store.getters['user/getStoresCodeNameList'];

  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const result = {};
  dates.forEach(date => {
    storeCodes.forEach(storeCode => {
      // Find the store by storeCode
      const storeList = stores.find(st => st.storeCode === storeCode);

      if (!storeList) {
        if (!result[date]) {
          result[date] = {};
        }
      //  result[date][storeCode] = 'Store not found';
        return;
      }

      const dayIndex = new Date(date).getDay();
      const day = daysOfWeek[dayIndex];

      // Find the timings for the specific day
      const timings = storeList?.storeTimings?.find(timing => timing.day === day);

      if (!timings) {
        if (!result[date]) {
          result[date] = {};
        }
        result[date][storeCode] = 'Timings not available for this day';
        return;
      }

      if (!result[date]) {
        result[date] = {};
      }


      result[date][storeCode] = {
        openingTime: timings.openingTime ? Number(timings.openingTime.split(':')[1]=='00'?timings.openingTime.split(':')[0]:Number(timings.openingTime.split(':')[0])+1) : 10,
        closingTime: timings.closingTime ? Number(timings.closingTime.split(':')[1]=='00'?timings.closingTime.split(':')[0]:Number(timings.closingTime.split(':')[0])+1): 20
      };
    });
  });

  let minOpening = Infinity;
  let maxClosing = -Infinity;

  // Iterate through the data to find min opening and max closing times
  Object.values(result).forEach((dateData) => {
    Object.values(dateData).forEach((entry) => {
      minOpening = Math.min(minOpening, entry.openingTime);
      maxClosing = Math.max(maxClosing, entry.closingTime);
    });
  });

  // Update all entries with min opening and max closing times
  Object.keys(result).forEach((date) => {
    Object.keys(result[date]).forEach((key) => {
      result[date][key].openingTime = minOpening;
      result[date][key].closingTime = maxClosing;
    });
  });

  return result;
}

export function getStoreMinAndMaxHourForDatesAndStores(dates, storeCodes) {
  // Your array of store objects

  const stores = store.getters['user/getStoresCodeNameList'];

  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const result = {};
  dates.forEach(date => {
    storeCodes.forEach(storeCode => {
      // Find the store by storeCode
      const storeList = stores.find(st => st.storeCode === storeCode);

      if (!storeList) {
        if (!result[date]) {
          result[date] = {};
        }
        result[date][storeCode] = 'Store not found';
        return;
      }

      const dayIndex = new Date(date).getDay();
      const day = daysOfWeek[dayIndex];

      // Find the timings for the specific day
      const timings = storeList?.storeTimings?.find(timing => timing.day === day);
      if (!timings) {
        if (!result[date]) {
          result[date] = {};
        }
        result[date][storeCode] = 'Timings not available for this day';
        return;
      }

      if (!result[date]) {
        result[date] = {};
      }

      result[date][storeCode] = {
        openingTime: timings.openingTime ? Number(timings.openingTime.split(':')[1]=='00'?timings.openingTime.split(':')[0]:Number(timings.openingTime.split(':')[0])+1) : 10,
        closingTime: timings.closingTime ? Number(timings.closingTime.split(':')[1]=='00'?timings.closingTime.split(':')[0]:Number(timings.closingTime.split(':')[0])+1): 20
      };
    });
  });

  let minOpening = Infinity;
  let maxClosing = -Infinity;

  // Iterate through the data to find min opening and max closing times
  Object.values(result).forEach((dateData) => {
    Object.values(dateData).forEach((entry) => {
      minOpening = Math.min(minOpening, entry.openingTime) || 10;
      maxClosing = Math.max(maxClosing, entry.closingTime) || 20;
    });
  });

  // Update all entries with min opening and max closing times
  Object.keys(result).forEach((date) => {
    Object.keys(result[date]).forEach((key) => {
      if(result[date][key].openingTime){
        result[date][key].openingTime = minOpening;
      }
      if(result[date][key].closingTime){
        result[date][key].closingTime = maxClosing;
      }
    });
  });

  const finalResult = {
    minOpening,
    maxClosing
  }

  return finalResult;
}

// function getStoresByCodes(storeCodesString, stores) {


export function getInOutByStores(storeCodes) {//list of storeCodes
  const stores =  store.getters['user/getStoresCodeNameList'];
  if(stores)
  {
  storeCodes = storeCodes.split(',').map(code => code.trim());

  const selectedStores = stores.filter(store => storeCodes.includes(store.storeCode));

  const result = {};
  selectedStores.map(store => {
      const { storeCode,entrances } = store;
      result[storeCode] = {
          entrances
      };
  });

  return result;
}
else
{
  return null;
}
}


export function getEntranceTitle(storeCode, entranceId) {
  const storesData =  store.getters['user/getStoresCodeNameList'];
  if(storesData)
  {
  const storeObj = storesData?.find(storeObj => storeObj.storeCode === storeCode);
  if (storeObj) {
      const entrance = storeObj.entrances?.find(entrance => entrance.id === entranceId);
      if (entrance) {
          return entrance.title?entrance.title:entrance.id;
      } else {
          return entranceId;
      }
  } else {
      return entranceId;
  }
  }
  else
   return entranceId;
}

export function formatDate(dateString) {
  const result = dateString.split('T')[0];
  return result;
}

export function formatTime(dateString) {
  const result = dateString.split(':')[0];
  return Number(result.split('T')[1]);
}

export function getDateRange(startDate, endDate) {
  const dateRanges = [];
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Iterate through the dates
  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    // Format the date in "YYYY-MM-DD" format
    const formattedDate = date.toISOString().split('T')[0];
    dateRanges.push(formattedDate);
  }

  return dateRanges;
}
export function fillMissingHours(data, startingHour, endingHour) {
  const result = {};

  // Loop through each day
  for (const day in data) {
      result[day] = {};
      // Loop through hours between startingHour and endingHour
      for (let hour = startingHour; hour <= endingHour; hour++) {
          const hourStr = hour.toString().padStart(2, '0') + ":00";
          // Check if the hour exists in the data, if not, assign 0
          result[day][hourStr] = data[day][hourStr] || 0;
      }
  }

  return result;
}
export function getListOfDates(startDate, endDate) {
  const dateList = [];
  const start = new Date(startDate);
  const end = new Date(endDate);

  let currentDate = new Date(start);

  while (currentDate <= end) {
    const year = currentDate.getFullYear();
    const month = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const day = `${currentDate.getDate().toString().padStart(2, '0')}`;
    const formattedDate = `${year}-${month}-${day}`;
    dateList.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateList;
}
export function getDatsFilteredData(kpis, startDate, endDate, comparedStartDate, comparedEndDate) {
  const kpisPeriod = {};
  const kpisCompared = {};

  const convertDate = (date) => date.split('-').join('');

  const formattedStartDate = convertDate(startDate);
  const formattedEndDate = convertDate(endDate);
  const formattedComparedStartDate = convertDate(comparedStartDate);
  const formattedComparedEndDate = convertDate(comparedEndDate);

  // Filter KPIs for the given date range
  const filteredKPIs = kpis.filter(kpi => {
    const kpiDate = kpi._id.split('_')[0];
    return kpiDate >= formattedStartDate && kpiDate <= formattedEndDate;
});

// Filter compared KPIs for the compared date range
const comparedFilteredKPIs = kpis.filter(kpi => {
    const kpiDate = kpi._id.split('_')[0];
    return kpiDate >= formattedComparedStartDate && kpiDate <= formattedComparedEndDate;
});


  // Populate kpisPeriod object
  filteredKPIs.forEach(kpi => {
    kpisPeriod[kpi._id] = {
          sls: kpi.sls,
          trn: kpi.trn
      };
  });

  // Populate kpisCompared object
  comparedFilteredKPIs.forEach(kpi => {
      kpisCompared[kpi._id] = {
          sls: kpi.sls,
          trn: kpi.trn
      };
  });

  return { kpisPeriod, kpisCompared };
}


export function getDayName(dateStr) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const year = parseInt(dateStr.slice(0, 4));
  const month = parseInt(dateStr.slice(4, 6)) - 1; // Months are 0-indexed
  const day = parseInt(dateStr.slice(6, 8));
  const date = new Date(year, month, day);
  const dayIndex = date.getDay();
  return days[dayIndex];
}


export function formatIntDate(dateStr) {
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6); // Months are 0-indexed
  const day = dateStr.slice(6, 8);
  //const date = new Date(year, month, day);
  return year+"-"+month+"-"+day;
}

export function getKpiTitle(kpiId) {

  const storeState: any = store.state;
  const trs: any = storeState.user.translateKpi;
  const lang = storeState.user.locale || 'en';
  const kpiTitle = trs[kpiId].title[lang]?trs[kpiId].title[lang]:"";

  return kpiTitle;
}

export function getDateDifference(startDate: string, endDate: string): number {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the time difference in milliseconds
  const timeDifference = end.getTime() - start.getTime();

  // Convert the time difference to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference+1;
}
export function getEntrancesWithStores(storeCodes) {//list of storeCodes
  const stores =  store.getters['user/getStoresCodeNameList'];
  if(stores)
  {
  storeCodes = storeCodes.split(',').map(code => code.trim());

  const selectedStores = stores.filter(store => storeCodes.includes(store.storeCode));

  const result = {};
  selectedStores.map(store => {
      const { storeCode,entrances } = store;
      result[storeCode] = {
        entrances: entrances?.map(entrance => ({ id: entrance.id }))
      };
  });
  return result;
}
else
{
  return null;
}

}

export function addMissingData(dataSet, defaultData) {
  for (const key in defaultData) {
      const storeCode = key; // Assuming storeCode is the key in defaultData
      const index = dataSet.findIndex(item => item.storeCode === storeCode);
      if (index !== -1) {
          const entrances = defaultData[key].entrances;
          for (const entrance of entrances) {
              if (!dataSet[index].traffic[entrance.id]) {
                  dataSet[index].traffic[entrance.id] = {
                      "Monday": "0",
                      "Tuesday": "0",
                      "Wednesday": "0",
                      "Thursday": "0",
                      "Friday": "0",
                      "Saturday": "0",
                      "Sunday": "0"
                  };
              }
          }
      }
      else
      {
        const newObject = {
          "storeName": "UNKNOWN", // You can set a default storeName or handle it as needed
          "storeCode": storeCode,
          "traffic": {}
      };

      // Initialize traffic with entrances with default values set to 0
      const entrances2 = defaultData[key].entrances;
      for (const entrance of entrances2) {
          newObject.traffic[entrance.id] = {
              "Monday": "0",
              "Tuesday": "0",
              "Wednesday": "0",
              "Thursday": "0",
              "Friday": "0",
              "Saturday": "0",
              "Sunday": "0"
          };
      }

    dataSet.push(newObject);
}
  }
  return dataSet;
}
///////////////////////

function convertToCustomFormat(inputDate) {
  const parts = inputDate.split('/');
  const year = parts[2];
  const month = parts[1].padStart(2, '0');
  const day = parts[0].padStart(2, '0');
  return `${year}${month}${day}`;
}

interface Entrance {
  id: string;
}

interface StoreData {
  entrances: Entrance[];
}

interface ApiResponseEntry {
  _id: string;
  trf: Record<string, { in: number; trf: number }>;
}

export function addMissingObjects(
  apiResponse: ApiResponseEntry[],
  startDate: string,
  endDate: string,
  startHour: number,
  endHour: number,
  storeCodes: Record<string, StoreData>
): ApiResponseEntry[] {

  const result: ApiResponseEntry[] = [];
  const startDateObj = new Date(startDate.replace(/-/g, '/'));
  const endDateObj = new Date(endDate.replace(/-/g, '/'));

  const tentID = localStorage.getItem("tenentID") || 'Rreqd9vt3pKGYBRQK';

  for (let currentDate = new Date(startDateObj); currentDate <= endDateObj; currentDate.setDate(currentDate.getDate() + 1)) {
    for (let hour = startHour; hour <= endHour; hour++) {
      const formattedDate = convertToCustomFormat(currentDate.toLocaleDateString('en-GB').replace(/-/g, ''));

      for (const [storeCode, storeData] of Object.entries(storeCodes)) {
        const entrances = storeData.entrances;
        const newHour = hour < 10 ? `0${hour}` : `${hour}`;
        const id = `${formattedDate}_${newHour}_${tentID}_${storeCode}`;

        // if (!apiResponse.some(existingEntry => existingEntry._id === id)) {
          if (!apiResponse.some(existingEntry => (existingEntry._id === id && Object.keys(existingEntry).length>1
          && entrances?.every(entrance => existingEntry.trf.hasOwnProperty(entrance.id))))){
          const newEntry: ApiResponseEntry = {
            "_id": id,
            "trf": entrances && Object.fromEntries(entrances.map(entrance => [entrance.id, { "in": 0, "trf": 0 }])),
          };
          result.push(newEntry);
        }
      }
    }
  }

  return [...apiResponse, ...result];
}


//specifically for traffic trend
export function missinZeroDataByHour(apiResponse, kpiTypes, start, end, storeCodes) {
  const dateStoreMap = {};
  const missingEntries = [];

    apiResponse.forEach(entry => {
        const date = entry.date;
        if (!dateStoreMap[date]) {
            dateStoreMap[date] = [];
        }
        dateStoreMap[date].push(entry);
    });

    for (let date = start; date <= end; date++) {
      if (!dateStoreMap[date]) {
          const newEntry = {
            date: `${date}`
          };
          kpiTypes.forEach(kpi => (newEntry['traffic'] = 0));
          missingEntries.push(newEntry);
      }
    }

  return [...apiResponse, ...missingEntries];
}

export function getDateRangeForPrediction() {
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  // startDate.setDate(startDate.getDate() - 7);
  startDate.setDate(startDate.getDate() - 14);

  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() - 1);

  const formatDate = date => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
  };
}

export function convertIdToDatePredictionData(data) {
  return data.map(item => {
      const datePart = item._id.split('_')[0]; // Splitting and taking the date part
      const formattedDate = datePart.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'); // Formatting to YYYY-MM-DD
      return {
        conversion: "6.62 ",
        date: formattedDate,
        maxTemp: 0,
        minTemp: 0,
        sales: "1,209,350 ",
        temperature: 0,
        value: ""+item.trf +" ",
        weather: "",
        weatherIcon: ""
      };
  });
}
export function generateRandomVariation(baseValue) {
  const variationPercentage = 15 / 100;

  const minVariation = baseValue * (1 - variationPercentage);
  const maxVariation = baseValue * (1 + variationPercentage);
  return Math.random() * (maxVariation - minVariation) + minVariation;
}


export function generateNext7DaysPrediction(last14DaysDatas){
  //sort
  const last14DaysData = last14DaysDatas.sort((a, b) => (a.date.replace(/-/g, "")) - (b.date.replace(/-/g, "")));

  console.log('last14DaysData');
  console.log(last14DaysData);

  // Extract dates and values from the provided data
  const dates = last14DaysData.map(entry => new Date(entry.date));
  const values = last14DaysData.map(entry => parseInt(entry.value.trim()));

  // Initialize an array to store the averages for each day of the week
  const dayOfWeekAverages = Array.from({ length: 7 }, () => []);

  // Calculate the average value for each day of the week
  dates.forEach((date, index) => {
      const dayOfWeek = date.getDay();
      dayOfWeekAverages[dayOfWeek].push(values[index]);
  });

  const dayAverages = dayOfWeekAverages.map(dayValues => {
      return dayValues.reduce((acc, val) => acc + val, 0) / dayValues.length;
  });

  // Predict next 7 days by using the average of the corresponding day of the week
  const prediction = Array.from({ length: 7 }, (_, i) => {
      const nextDate = new Date(dates[dates.length - 1]);
      nextDate.setDate(nextDate.getDate() + i + 1);
      const dayOfWeek = nextDate.getDay();
      return {
          date: nextDate.toISOString().split('T')[0],
          value: ""+Math.round(dayAverages[dayOfWeek])+ " ",
           temperature: 0,
           weather: "",
           weatherIcon: ""
      };
  });

  return prediction;

}
// export function generateNext7DaysPrediction(last7DaysData) {
//   const next7DaysData = JSON.parse(JSON.stringify(last7DaysData));

//   // Calculate 15% variation
//   const variationPercentage = 20 / 100;

//   // Helper function to generate random variation
//   function generateRandomVariation(baseValue) {
//       const minVariation = baseValue * (1 - variationPercentage);
//       const maxVariation = baseValue * (1 + variationPercentage);
//       return Math.random() * (maxVariation - minVariation) + minVariation;
//   }

//   // // Increment dates and modify values
//   // const currentDate = new Date(next7DaysData[next7DaysData.length - 1].date);

//   const currentDate2 = new Date();
//   const currentDate = new Date(currentDate2);

//   for (let i = 0; i < next7DaysData.length; i++) {
//       const nextDate = new Date(currentDate);
//       // nextDate.setDate(nextDate.getDate() + i + 7); // Incrementing date
//       nextDate.setDate(nextDate.getDate() + i); // Incrementing date
//       const formattedDate = nextDate.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
//       const baseValue = parseInt(next7DaysData[i].value.replace(/\s/g, ''), 10); // Removing comma and parsing to integer
//       const newValue = Math.round(generateRandomVariation(baseValue)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Rounding the new value and adding comma
//       next7DaysData[i].date = formattedDate;
//       next7DaysData[i].value = ""+ newValue + " ";

//       delete next7DaysData[i].conversion;
//       delete next7DaysData[i].maxTemp;
//       delete next7DaysData[i].minTemp;
//       delete next7DaysData[i].sales;
//       delete next7DaysData[i].sales;
//   }
//   console.log('next7DaysData');
// console.log(next7DaysData);
//   return next7DaysData;
// }
export function downloadPDF(blob, fileName) {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function transformPulseGetAll(apiResponse, params)
{
  const datesInRange = getDatesInRange(params);
  if(params.reportType == 'daily')
  {
    //apiResponse "PeriodicReport-DAILY"
    console.log('');
  }
  console.log(params);

}


function formatDatePulse(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getDatesInRange(reportConfig) {
  const startDate = new Date(reportConfig.startDate);
  const endDate = new Date(reportConfig.endDate);
  const dateArray = [];

  while (startDate <= endDate) {
    dateArray.push(formatDatePulse(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }


  return dateArray;
}
//pulse

export function convertResponsePulseSalesTrend(originalResponse) {
  
  const mapItem = (item) => {
    const date = item._id.substring(0, 8);
    return {
      timeLabel: `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`,
      value: item.sls,
      temperature: 0,
      weather: "Sunny",
      maxTemp: 0,
      minTemp: 0,
      sales: `¥${item.sls.toLocaleString()} `,
      traffic: "0",
      conversion: "0 %",
      formattedValue: `¥${item.sls.toLocaleString()} `,
      weatherIcon: "wi-day-sunny"
    };
  };

  const selectedPeriod = originalResponse.kpis.map(mapItem).sort((a, b) => a.timeLabel.localeCompare(b.timeLabel));
  const comparedPeriod = originalResponse.kpisCompare.map(mapItem).sort((a, b) => a.timeLabel.localeCompare(b.timeLabel));
  const target = originalResponse.kpisCompare.map((item) => {
    const date = item._id.substring(0, 8);
    return {
      timeLabel: `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`,
      value: item.st ? item.st : 0,
      formattedValue: `¥${item.st ? item.st : item.st.toLocaleString()} `,
    };
  }).sort((a, b) => a.timeLabel.localeCompare(b.timeLabel));
  
  return {
    data: {
      selectedPeriod,
      comparedPeriod,
      target
    }
  };
}
export function addMissingDatesData(originalResponse, kpiType, startDate, endDate) {
  // Create a map to store apiResponse objects by their date
  const responseMap = new Map();
const storeCode = originalResponse[0]._id.split('_')[1];
  // Populate the map with existing data
  originalResponse.forEach(item => {
      const date = item._id.split('_')[0];
      responseMap.set(date, item[kpiType]);
  });

  // Create an array to store the final response
  const finalResponse = [];

  // Loop through the dates from startDate to endDate
  let currentDate = new Date(startDate);
  const end = new Date(endDate);
  
  while (currentDate <= end) {
      const dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, '');

      // Check if the date exists in the responseMap
      if (responseMap.has(dateString)) {
          finalResponse.push({
              "_id": dateString + "_"+storeCode,
              [kpiType]: responseMap.get(dateString)
          });
      } else {
          // If date is missing, add it with sls value 0
          finalResponse.push({
              "_id": dateString + "_"+storeCode,
              [kpiType]: 0
          });
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
  }

  return finalResponse;
}
export function convertResponsePulseTrend(originalResponse,kpiType,startDate,endDate,compareStartDate,compareEndDate) {
  originalResponse.kpis =  addMissingDatesData(originalResponse.kpis,kpiType,startDate,endDate);
  originalResponse.kpisCompare =  addMissingDatesData(originalResponse.kpisCompare,kpiType,compareStartDate,compareEndDate);

  const mapItem = (item) => {
    const date = item._id.substring(0, 8);
    var  key = "";
    if(kpiType == 'sls')
    {
      key = "sales"
    }
    else if(kpiType == 'trf')
    {
      key = "traffic"
    }
    else if(kpiType == 'pb')
    {
      key = "passersby"
    }
    return {
      timeLabel: `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`,
      value: item[kpiType],
      temperature: 0,
      weather: "Sunny",
      maxTemp: 0,
      minTemp: 0,
      [key]: `${getSignsLeftSide(key)}${item[kpiType].toLocaleString()}${getSignsRightSide(kpiType)} `,
      // traffic: "0",
      conversion: "0 %",
      formattedValue: `${getSignsLeftSide(key)}${item[kpiType].toLocaleString()}${getSignsRightSide(kpiType)} `,
      weatherIcon: "wi-day-sunny"
    };
  };

  const selectedPeriod = originalResponse.kpis.map(mapItem).sort((a, b) => a.timeLabel.localeCompare(b.timeLabel));
  const comparedPeriod = originalResponse.kpisCompare.map(mapItem).sort((a, b) => a.timeLabel.localeCompare(b.timeLabel));

  return {
    data: {
      selectedPeriod,
      comparedPeriod

    }
  };

}
