
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays
} from 'date-fns';
import {getDayDiffFromMonday, getStartDayOfWeek} from '@/util/calendar.js'

export default {
  data() {
    return {
      attrs: [
        {
          key: 'Holidays',
          highlight: true,
          popover: {
            label: ''
          },
          dates: []
        }
      ],
      queueReport: false,
      occ: false,
      trafficReport: false,
      scrolled: false,
      calenderMode: 'range',
      specialCalenderMode: false,
      dragValue: null,
      selectedPeriodState: false,
      selectedDateRange: [],
      dateOptionsSelectedModel: null,
      dateOptionsComparedModel: {
        label: this.$t('previous_period'),
        value: 'PREVIOUS_PERIOD'
      },
      marketingCampaignMode: false,
      marketingCampaignOption: null,
      marketingCampaignOptionList: [],
      dateOptionsSelected: [
        {
          label: this.$t('today'),
          value: 'TODAY'
        },
        {
          label: this.$t('yesterday'),
          value: 'YESTERDAY'
        },
        {
          label: this.$t('this_week'),
          value: 'THIS_WEEK'
        },
        {
          label: this.$t('last_week'),
          value: 'LAST_WEEK'
        },
        {
          label: this.$t('this_month'),
          value: 'THIS_MONTH'
        },
        {
          label: this.$t('last_month'),
          value: 'LAST_MONTH'
        },
        {
          label: this.$t('this_year'),
          value: 'THIS_YEAR'
        },
        {
          label: this.$t('last_year'),
          value: 'LAST_YEAR'
        }
      ],
      dateOptionsCompared: [
        {
          label: this.$t('previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        {
          label: this.$t('previous_year_same_period'),
          value: 'PREVIOUS_YEAR_SAME_PERIOD'
        }
      ],
      selectedPeriod: {
        start: new Date(), // Jan 16th, 2018
        end: new Date() // Jan 19th, 2018
      },
      comparedPeriod: {
        start: addDays(new Date(), -1), // Jan 16th, 2018
        end: addDays(new Date(), -1) // Jan 19th, 2018
      },
      lastTimeStamp: Date.now(),
      startDayFromMonday: 0,
      startDayOfWeek: 1,
    };
  }, // data
  computed: {
    ...mapGetters('user', ['getLocale', 'profile', 'startOfWeek']),
    ...mapState("user", ["defaultSettings"]),
    ...mapState('filter', ['filter', 'holidays']),
    ...mapState('store', ['stores']),
    ...mapState('comparison', ['marketingCampaign', 'compareDates']),
    // ...mapState('comparison', []),
    comparedPeriodText() {
      return `${this.filter.startingCompared} (${this.getFullDay(
        this.filter.startingCompared
      )}
          ) ~  ${this.filter.endingCompared} (${this.getFullDay(
        this.filter.endingCompared
      )}
          ) (              ${this.getDifferenceDays(
            this.filter.startingCompared,
            this.filter.endingCompared
          ) + 1}
          )`;
    },
    tutuannaMode() {
      return this.dateOptionsComparedModel.value === 'tutuanna_calender';
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // Defaults to true when using slot
        }
      };
    },
    dragComparedValue: function() {
      let value = '';
      if (this.dragValue && this.compareDates) {
        value = this.getComparedDate(this.formatDate(this.dragValue));
        return this.formatDate(value);
      } else {
        return value;
      }
    }
  }, // computed
  watch: {
    marketingCampaign() {
      if (this.marketingCampaign && this.marketingCampaign.length > 0) {
        this.dateOptionsSelected = [
          ...this.dateOptionsSelected,
          {
            label: this.$t('marketing_campaigns'),
            value: 'marketing_campaigns'
          }
        ];
      }
    },
    compareDates() {
      if (this.compareDates.length > 0) {
        this.dateOptionsCompared = [
          ...this.dateOptionsCompared,
          {
            label: this.$t('special_calendar'),
            value: 'tutuanna_calender'
          }
        ];
      }
    },
    holidays() {
      this.attrs = [
        ...this.holidays.map(v => ({
          key: 'Holidays',
          // highlight: true,
          popover: {
            label: v.value,
            labelClass: 'white-text',
            labelStyle: { color: 'white' },
            class: 'white-text'
          },
          dates: v.date,
          bar: {
            color: 'red',
            class: 'my-dot-class'
          }
        }))
      ];
    },
    'filter.daysOfWeek': {
      handler: 'refreshPage'
    },
    'filter.storeCodes': {
      handler: 'refreshPage'
    },
    $router(to, from) {
      this.occ = this.$router.history.current.name === 'occupancy_report';
      this.trafficReport =
        this.$router.history.current.name === 'traffic_report';
      this.queueReport = this.$router.history.current.name === 'queue_report';
    },
    selectedPeriod: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    },
    dateOptionsSelectedModel: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    },
    marketingCampaignOption: function() {
      if (!this.tutuannaMode && this.marketingCampaignMode) {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    }
  },
  async created() {
    this.startDayFromMonday = getDayDiffFromMonday();
    this.startDayOfWeek = getStartDayOfWeek();
    this.selectedPeriod.start = new Date(this.filter.startingPeriod);
    this.selectedPeriod.end = new Date(this.filter.endingPeriod);
    this.comparedPeriod.start = new Date(this.filter.startingCompared);
    this.comparedPeriod.end = new Date(this.filter.endingCompared);

    this.dateOptionsSelected.forEach((data) => {
      if (data.value == this.filter.selectedOption) {
        this.$set(this.dateOptionsSelectedModel, "value", data.value)
        this.$set(this.dateOptionsSelectedModel, "label", data.label)
        this.$set(this.dateOptionsSelectedModel, "disable", data.disable || false)
        this.dateOptionsSelectedModelChangedNew(data.value);
      }
    })
    if(this.filter.selectedOption == "CUSTOM_RANGE"|| this.filter.selectedOption == "CUSTOM") {
      this.onDayClick()
    }
    this.dateOptionsCompared.forEach((data) => {
      if (data.value == this.filter.comparedOption) {
        this.$set(this.dateOptionsComparedModel, "value", data.value)
        this.$set(this.dateOptionsComparedModel, "label", data.label)
        this.$set(this.dateOptionsComparedModel, "disable", data.disable || false)
        this.dateOptionsComparedModelChangedNew(data.value);
      }
    })
    if(this.filter.comparedOption == "CUSTOM") {
      this.onDayClickCompare()
    }

    this.occ = this.$router.history.current.name === 'occupancy_report';
    this.trafficReport = this.$router.history.current.name === 'traffic_report';
    this.queueReport = this.$router.history.current.name === 'queue_report';
    await this.fetchHolidays();
    await this.fetchCompareDates();
    await this.fetchMarketingCampaign();
    this.getSelectedDateRange();
    this.marketingCampaignOptionList = this.marketingCampaign
      ? this.marketingCampaign.map(mc => {
          return {
            label: mc.campaignName,
            value: {
              startDate: mc.startDate,
              endDate: mc.endDate
            }
          };
        })
      : [];
  }, // created
  methods: {
    getFormattedDate(date) {
      return moment(new Date(date)).format('YYYY/MM/DD');
    },
    getSelectedDateRange() {
      let dates = [];
      let startDate = this.selectedPeriod.start;
      while (startDate <= this.selectedPeriod.end) {
        dates.push(startDate);
        startDate = addDays(startDate, 1);
      }
      this.selectedDateRange = dates.map(dat => this.formatDate(dat));
    },
    dateOptionsSelectedModelChanged() {
      const selectedOption = this.dateOptionsSelectedModel.value;
      this.marketingCampaignMode = false;
      if (selectedOption === 'TODAY') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = new Date();
        this.selectedPeriod.end = new Date();
      } else if (selectedOption === 'YESTERDAY') {
        this.selectedPeriod = {};
        const yesterday = startOfYesterday();
        this.selectedPeriod.start = yesterday;
        this.selectedPeriod.end = yesterday;
      } else if (selectedOption === 'THIS_WEEK') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfWeek(new Date(), { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfWeek(new Date());
      } else if (selectedOption === "LAST_WEEK") {
        this.selectedPeriod = {};
        const d = addWeeks(new Date(), -1);
        this.selectedPeriod.start = startOfWeek(d, { weekStartsOn: this.startDayOfWeek });
        this.selectedPeriod.end = addDays(this.selectedPeriod.start, 6)
      } else if (selectedOption === 'THIS_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfMonth(new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(addMonths(new Date(), -1));
        this.selectedPeriod.end = endOfMonth(addMonths(new Date(), -1));
      } else if (selectedOption === 'THIS_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfYear(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfYear(new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = addYears(startOfYear(new Date()), -1);
        this.selectedPeriod.end = addYears(endOfYear(new Date()), -1);
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      }
      this.dateOptionsComparedModelChanged();
    },
    onDayClick() {
      this.dateOptionsSelectedModel = {
        label: this.$t("custom_range"),
        value: "CUSTOM",
        disable: true,
      };
    },
    onDayClickCompare() {
      this.dateOptionsComparedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
      this.storeValues()
    },
    dateOptionsComparedModelChanged() {
      this.specialCalenderMode = false;
      // const selectedOption = this.dateOptionsSelectedModel.value;
      const comparedOption = this.dateOptionsComparedModel.value;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        this.comparedPeriod = {};

        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
        this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        this.comparedPeriod = {};
        this.comparedPeriod.start = addYears(new Date(), -1);
        this.comparedPeriod.end = addYears(new Date(), -1);
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        this.comparedPeriod.start = addYears(this.selectedPeriod.start, -1);
        this.comparedPeriod.end = addYears(this.selectedPeriod.end, -1);
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
    },
    getComparedDatesArray() {
      this.comparedPeriod = {};
      this.comparedPeriod = this.selectedDateRange.map(
        dat => new Date(this.getComparedDate(dat))
      );
    },
    marketingCampaignOptionChange() {
      let val = this.marketingCampaignOption.value;
      this.selectedPeriod = {};
      this.selectedPeriod = {
        start: new Date(val.startDate),
        end: new Date(val.endDate)
      };
    },
    ...mapMutations('filter', [
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag'
    ]), // mapMutations
    ...mapActions('comparison', [
      'fetchCompareDates',
      'fetchMarketingCampaign',
      'fetchHolidays'
    ]),
    ...mapActions('filter', ['fetchHolidays']),
    startingPeriodChanged(val) {
      this.setStartingPeriod({ startingPeriod: val });
    }, // startingPeriodChanged
    endingPeriodChanged(val) {
      this.setEndingPeriod({ endingPeriod: val });
    }, // endingPeriodChanged
    startingComparedChanged(val) {
      this.setStartingCompared({ startingCompared: val });
    }, // startingComparedChanged
    endingComparedChanged(val) {
      this.setEndingCompared({ endingCompared: val });
    }, // endingComparedChanged
    refreshPage() {
      this.$emit('refreshPage');
    }, // refreshPage
    submitDate() {
      this.setStartingPeriod({
        startingPeriod: format(this.selectedPeriod.start, 'yyyy-MM-dd')
      });
      this.setEndingPeriod({
        endingPeriod: format(this.selectedPeriod.end, 'yyyy-MM-dd')
      });
      if (!this.tutuannaMode) {
        this.setStartingCompared({
          startingCompared: format(this.comparedPeriod.start, 'yyyy-MM-dd')
        });
        this.setEndingCompared({
          endingCompared: format(this.comparedPeriod.end, 'yyyy-MM-dd')
        });
      }
      this.setCustomCalenderFlag({ value: this.tutuannaMode });

      this.hide();
      this.refreshPage();
    }, // submitDate
    hide() {
      this.$refs.periodFilter.hide();
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = date.toISOString().substr(0, 10);
      const [year, month, day] = newDate.split('-');
      return `${year}-${month}-${day}`;
    },
    getComparedDate(date) {
      if (date && this.compareDates) {
        let dateObj = this.compareDates.filter(dat => dat.date === date);
        if (dateObj.length > 0) {
          return new Date(dateObj[0].comparedDate);
        } else {
          return '';
        }
      }
    }, // hide
    handleScroll(evt, el) {
      if (window.scrollY > 10) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('Sun');
      }
      if (dayVal === 1) {
        return this.$t('Mon');
      }
      if (dayVal === 2) {
        return this.$t('Tue');
      }
      if (dayVal === 3) {
        return this.$t('Wed');
      }
      if (dayVal === 4) {
        return this.$t('Thu');
      }
      if (dayVal === 5) {
        return this.$t('Fri');
      }
      if (dayVal === 6) {
        return this.$t('Sat');
      }
    },
    getDifferenceDays(a, b) {
      let val = differenceInDays(new Date(a), new Date(b));
      return val < 0 ? -val : val;
    },
    dropDownToggled() {
      // setTimeout(() => {
      //   document.querySelector(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      // }, 1000);
    },
    getDayDiffFromMonday() {
      const weekday = ["friday","saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"];
      let i = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
      this.startDayFromMonday = i - 3;
      const indexes_of_weekday = [0,1,2,3,4,5,6];
    },
    getStartDayOfWeek() {
      const weekday = ["sunday","monday", "tuesday", "wednesday", "thursday","friday","saturday"];
      this.startDayOfWeek = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
    },
  } // methods
}; // export default
