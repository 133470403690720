
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { colors } from 'quasar';
import { addKpiCustomization } from '@/util/kpis';

export default {
  data() {
    return {
      kpiFormatted: {},
      selectOptionsKpi: [],
      loading: false,
      series: [],
      kpisList: [],
      customTitles: [
        this.$t('KPI.sls'),
        this.$t('KPI.aip'),
        this.$t('KPI.upt'),
        this.$t('KPI.ap'),
        this.$t('KPI.cn'),
        this.$t('KPI.trn'),
        this.$t('KPI.trf'),
        this.$t('KPI.pb'),
        this.$t('KPI.spt'),
        this.$t('KPI.tpt'),
        this.$t('KPI.cr'),
        this.$t('KPI.dw'),
        this.$t('KPI.er'),
        this.$t('KPI.br'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.sl'),
        this.$t('KPI.sh'),
        this.$t('KPI.cs'),
        this.$t('KPI.se'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      chartOptions: {
        chart: {
          type: 'radar',
          height: 460,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        colors: ['#3989f5', '#aac7fa'],
        stroke: {
          width: 2,
          dashArray: [0, 5]
          // curve: 'straight'
        },
        legend: {
          position: 'top',
          offsetY: 20,
          markers: { radius: 0, width: 16, height: 4 }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const selectData = series[0][dataPointIndex]; // Data from the first series
            const compareData = series[1][dataPointIndex]; // Data from the second series

            return `
      <q-card class="tooltip-container flex column q-pa-md">
        <label>${w.globals.labels[dataPointIndex]}</label>
        <div class="tooltip-title flex items-center">
          <div class="tooltip-title-color" style="background:${
            w.globals.colors[0]
          }"></div>
          <label>${w.globals.seriesNames[0]} :</label>
          <label class="q-ml-xs text-bold">${this.getRadarValue(
            selectData,
            0,
            dataPointIndex
          )}</label>
        </div>
        <div class="tooltip-title flex items-center">
          <div class="tooltip-title-color" style="background:${
            w.globals.colors[1]
          }"></div>
          <label>${w.globals.seriesNames[1]} :</label>
          <label class="q-ml-xs text-bold">${this.getRadarValue(
            compareData,
            1,
            dataPointIndex
          )}</label>
        </div>
      </q-card>
    `;
          }
        },

        fill: {
          opacity: 0.1
        },
        markers: {
          size: 4,
          colors: ['transparent'],
          strokeColors: 'transparent'
          // strokeWidth: 2,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: val => {
              return val + '%';
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared'
    ]),
    ...mapState('overview', ['byPeriod']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('overview', { kpis: 'kpis' }),
    ...mapState('filter', ['radarChartKpiOptions']),
    ...mapState('user', ['kpiPreferences']),
    ...mapState('periodicReport', [
      'selectedStoreCode',
    ]),
  },
  watch: {
    selectOptionsKpi: {
      handler: 'loadData',
      immediate: true
    },
    selectedKPIOptions() {
       if (this.selectedStoreCode !== '') {
      this.selectOptionsKpi = this.selectedKPIOptions;
       }
    },
    selectedStoreCode: {
      handler: 'loadData',
      immediate: true
    },
    kpis(newValue) {
      this.kpisList = addKpiCustomization(newValue);
    }
  },
  async created() {

    // this.kpisList = this.kpis;
    this.kpisList = addKpiCustomization(this.kpis);

    var selectKpiList = localStorage.getItem('selectedDetailKPIF')?.split(','); //saved kpis

    if (selectKpiList != null && selectKpiList != '') {
      this.selectOptionsKpi = selectKpiList;
    } else {
      this.selectOptionsKpi = this.selectedKPIOptions; //this.selectedKPIOptions;
    }
    if(!this.selectOptionsKpi || this.selectOptionsKpi == '' ||this.selectOptionsKpi.length<=3)
    {
      for(var p=0;p<this.kpiPreferences.length;p++)
      {
        if(this.kpiPreferences[p].metricKey)
        this.selectOptionsKpi.push(this.kpiPreferences[p].metricKey);
      }
    }
    this.selectOptionsKpi = [...new Set(this.selectOptionsKpi)];

    this.selectOptionsKpi = this.selectOptionsKpi.filter(item => item !== "undefined");
        this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
        },
        png: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
        },
        csv: {
          filename: `${this.$t('RadarChart.radar')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
        }
      }
    };
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByPeriod']),
    ...mapActions('overview', ['fetchDetailOverview']),
   ...mapMutations('periodicReport', ['setMonthlyLoading']),
    radarSorter(x, y) {
     /* return (
        this.selectOptionsKpi.indexOf(x.metricID) -
        this.selectOptionsKpi.indexOf(y.metricID)
      );*/
      return (
        this.kpiPreferences.findIndex(k => k.metricKey === x.metricID) -
        this.kpiPreferences.findIndex(k => k.metricKey === y.metricID)
      );
    },
    getRadarValue(value, seriesIndex, dataPointIndex) {
      switch (seriesIndex) {
        case 1:
          return (
            this.byPeriod?.comparedKPIValues[dataPointIndex]?.formattedValue ||
            0
          );
        case 0:
          return (
            this.byPeriod?.selectedKPIValues[dataPointIndex]?.formattedValue ||
            0
          );
        default:
          return value;
      }
    },
    async loadData(newValue, oldValue) {
      try {
         this.setMonthlyLoading({ component: 'monthlyRadar', value: true });
          if (this.selectedStoreCode !== '') {
        if (newValue.length) {
          this.loading = true;

          const filterParams = {
            startDate: this.getPulseStartingPeriod,
            endDate: this.getPulseEndingPeriod,
            compareStartDate: this.getPulseStartingCompared,
            compareEndDate: this.getPulseEndingCompared,
            storeCodes: this.selectedStoreCode,
            daysOfWeek: this.getDaysOfWeekList,
            kpis: this.selectedKPIOptions.join(','),
          };
          await Promise.all([
            this.fetchDetailOverview(filterParams),
           // this.fetchDistributionByPeriod(filterParams)
          ]);
         this.setMonthlyLoading({ component: 'monthlyRadar', value: false });
          this.chartOptions = {
  ...this.chartOptions,
  xaxis: {
    categories: [
      ...this.byPeriod.selectedKPIValues
        .sort(this.radarSorter)
        .map(kpi => {
          const kpiPreference = this.kpiPreferences.find(
            k => k.metricKey === kpi.metricID
          );
          return kpiPreference ? kpiPreference.title : kpi.metricID; // Use empty string if no match
        })
    ]
  }
};

          this.series = [];

          const selectedKPIValues =
            this.byPeriod?.selectedKPIValues?.sort(this.radarSorter) || [];

          const comparedKPIValues =
            this.byPeriod?.comparedKPIValues?.sort(this.radarSorter) || [];

          const selected = selectedKPIValues.map((kpi, index) => {
            const val = (kpi.value / comparedKPIValues[index].value) * 100;
            return isNaN(val) || !isFinite(val) ? 0 : val;
          });

          const compared = comparedKPIValues.map((kpi, index) => {
            const val = (kpi.value / comparedKPIValues[index].value) * 100;
            return isNaN(val) || !isFinite(val) ? 0 : val;
          });

          this.series = [
            {
              name: this.$t('KpiAnalysis.selected_period'),
              data: [...selected]
            },
            {
              name: this.$t('KpiAnalysis.compared_period'),
              data: [...compared]
            }
          ];
        }
          }
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
