import * as localForage from 'localforage';

export type LazyFetch<T> = () => Promise<T>;

export interface PendingTask<T> {
  resolve: (value: T) => void;
  reject: (error: Error) => void;
}

export class LazyValue<T> {
  private fetch: LazyFetch<T>;
  private pendingTasks: PendingTask<T>[] = [];

  private value?: T;
  private fetchScheduled = false;

  constructor(fetch: LazyFetch<T>) {
    this.fetch = fetch;
  }

  public get(): Promise<T> {
    if (this.value) {
      let ses = localForage.getItem('session-tenant');
      return Promise.resolve(this.value);
    }
    return new Promise((resolve, reject) => {
      this.pendingTasks.push({ resolve, reject });
      if (!this.fetchScheduled) {
        this.fetchScheduled = true;
        this.scheduleFetch();
      }
    });
  }

  private async scheduleFetch() {
    try {
      const value = await this.fetch();
      this.value = value;
      while (this.pendingTasks.length > 0) {
        const task = this.pendingTasks.shift();
        task.resolve(value);
      }
    } catch (err) {
      while (this.pendingTasks.length > 0) {
        const task = this.pendingTasks.shift();
        task.reject(err);
      }
    }
  }
}

export const lazy = <T>(fetch: LazyFetch<T>) => {
  const lazyValue = new LazyValue(fetch);
  return () => lazyValue.get();
};

export default lazy;
