
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { addMonths, startOfMonth } from 'date-fns';
import { addKpiCustomization } from '@/util/kpis';
import { getKpiTitle } from '@/util/utilFidaFunctions';
import { addSign } from '@/util/replaceFidaKpi';

export default {
  data() {
    return {
      currentTrend: '',
      loading: false,
      customTitles: [
        this.$t('KPI.sales'),
        this.$t('KPI.avgitemprice'),
        this.$t('KPI.upt'),
        this.$t('KPI.avgpurchase'),
        this.$t('KPI.conversion'),
        this.$t('KPI.transactions'),
        this.$t('KPI.traffic'),
        this.$t('KPI.passersby'),
        this.$t('KPI.salespersqm'),
        this.$t('KPI.trafficpersqm'),
        this.$t('KPI.capturerate'),
        this.$t('KPI.storedwelltime'),
        this.$t('KPI.engagementrate'),
        this.$t('KPI.bouncerate'),
        // this.$t('KPI.facadestops'),
        this.$t('KPI.stafflevel'),
        this.$t('KPI.staffhours'),
        this.$t('KPI.closingstrength'),
        this.$t('KPI.staffefficiency'),
        this.$t('KPI.rov'),
        this.$t('KPI.maxoccupancy'),
        this.$t('KPI.minoccupancy'),
        this.$t('KPI.avgoccupancy'),
        this.$t('KPI.avgoccupancy%'),
        this.$t('KPI.numberofalerts'),
        this.$t('KPI.numberofrequests'),
        this.$t('KPI.requesttakenperalert'),
        this.$t('KPI.timebeforetakingrequest'),
        this.$t('KPI.trafficinline'),
        this.$t('KPI.avgusersinline'),
        this.$t('KPI.avgwaitingtime')
      ],
      customSort: [
        'sales',
        'avgitemprice',
        'upt',
        'avgpurchase',
        'conversion',
        'transactions',
        'capturerate',
        'traffic',
        'passersby',
        'salespersqm',
        'trafficpersqm',
        'engagementrate',
        'storedwelltime',
        'bouncerate',
        // 'facadestops',
        'staffhours',
        'closingstrength',
        'staffefficiency',
        'rov'
      ],
      kpiOverview: []
    };
  }, // data
  computed: {
    ...mapState('periodicReport', ['selectedStoreCode', 'monthlyTarget']),

    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('overview', { kpis: 'kpis' }),
    ...mapState('target', ['targetsPulse', 'callTracker','targetsPulseCompared']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState'
    ]),
    datesList() {
      return {
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared
      };
    }
  },
  watch: {
    datesList: {
      handler: 'loadAllData',
      immediate: true
    },

    async selectedStoreCode() {
      await this.loadOverviewData();
      await this.loadTargetData();
    },
    async selectedKPIOptions() {
      await this.loadOverviewData();
    }
  },

  async created() {
    if (this.selectedStoreCode !== '') {
      await this.loadOverviewData();
      await this.loadTargetData();
    }
  },
  methods: {
    ...mapActions('overview', ['fetchOverview']),
    ...mapActions('target', ['fetchTargetsPulseMonth','fetchTargetsPulseCompareMonth']),
    ...mapMutations('periodicReport', ['setMonthlyLoading','setWeeklyRadar']),
     addSigns(kpi,val){
      return addSign(kpi,val);
    },
    kpiSorter(x, y) {
      return (
        this.customTitles.indexOf(x.metricKey) -
        this.customTitles.indexOf(y.metricKey)
      );
    },

    addTargetAchivment(sales, target) {
      var isNumber = x => {
        if (Number.isNaN(x) || x === Infinity || x === -Infinity) {
          return 0;
        } else {
          return x;
        }
      };
      //
      var formula = value => {
        var num = sales?.replaceAll('¥', '');
        var remCma = num?.replaceAll(',', '');
        return isNumber((parseInt(remCma) / value) * 100);
      };
      return formula(target).toFixed(2) ;
    },

    async loadOverviewData() {
      try {
        this.loading = true;
      //   var arr = [...this.selectedKPIOptions];
      //   var index = arr.indexOf('targetAchive');
      //   if (index !== -1) {
      //     arr[index] = 'sales';
      //   }
      // let uniqueArray = [...new Set(arr)];
        this.setMonthlyLoading({ component: 'kpiAchievement', value: true });
        await this.fetchOverview({
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseEndingPeriod,
          compareStartDate: this.getPulseStartingCompared,
          compareEndDate: this.getPulseEndingCompared,
          storeCodes: this.selectedStoreCode,
          kpis: this.selectedKPIOptions,
          daysOfWeek: this.getDaysOfWeekList,
          groupByMetricKey: true
        });
        let kpiOverview = addKpiCustomization(this.kpis);
        this.kpiOverview = [...kpiOverview].filter(el => this.selectedKPIOptions.includes(el.metricID));
        //
        this.kpiOverview = this.kpiOverview.map(kpi => {
          let title;
          try {
            title = getKpiTitle(kpi.metricID);
          } catch (error) {
            // Handle the exception here, you can log the error or provide a default title
            console.error("Error finding title:", error);
            title = "Default Title"; // Or any other fallback value
          }
          return { ...kpi, title };
        });
        
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      }
      this.loading = false;
      this.setMonthlyLoading({ component: 'kpiAchievement', value: false });
    },
    async loadTargetData() {
      let params = {
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseEndingPeriod,
          kpiTypes: ["sls", "st"],
          storeCodes: this.selectedStoreCode.split(",")
        };
        let str = JSON.stringify(params)
        params.trackLatest = { callerId: "home", value: str }

        await this.fetchTargetsPulseMonth(params);

        let paramsComp = {
          startDate: this.getPulseStartingCompared,
          endDate: this.getPulseEndingCompared,
          kpiTypes: ["sls", "st"],
          storeCodes: this.selectedStoreCode.split(",")
        };
        await this.fetchTargetsPulseCompareMonth(paramsComp);
    },
    async loadAllData() {
      await this.loadOverviewData();
      await this.loadTargetData();
    },
    getTooltip(kpiName) {
      if (this.$te(`tooltip_${kpiName}`, true)) {
        return this.$t(`tooltip_${kpiName}`);
      }
      return '';
    } // getTooltip
    /**
     * Return video url based on kpi name recieved
     */
  } // methods
}; // export default
