import LoginButton from '@/components/Auth/Button.vue';
import LoginButtonLink from '@/components/Auth/ButtonLink.vue';
import LoginButtons from '@/components/Auth/Buttons.vue';
import LoginField from '@/components/Auth/Field.vue';
import LoginForm from '@/components/Auth/Form.vue';
import LoginInput from '@/components/Auth/Input.vue';
import SelectBox from '@/components/Auth/SelectBox.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    'l-buttons': LoginButtons,
    'l-button': LoginButton,
    'l-button-link': LoginButtonLink,
    'l-form': LoginForm,
    'l-field': LoginField,
    'l-input': LoginInput,
    'select-box': SelectBox
  }
});
