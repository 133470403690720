import { PostRequest, PostRequestEN } from '@/util/http';
import store from '../store';

export function APIExport(params: any) {
  const acceptedLanguage =
    store.getters['user/getLocale'] === 'en-us' ? 'en' : 'ja';

  const config = {
    headers: {
      'Accept-Language': acceptedLanguage
    }
  };
  return PostRequest(`data/export/${params.reportType}`, params, config);
} // APIFetchExpectations
export function APIExportEN(params: any) {
  const config = {
    headers: {
      'Accept-Language': 'en'
    }
  };
  return PostRequestEN(`data/export/${params.reportType}`, params, config);
}
// api export 