
import ModalHeader from './Header';

export default {
  components: {
    ModalHeader
  },
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
