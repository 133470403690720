import { render, staticRenderFns } from "./ComparisonDistrict.vue?vue&type=template&id=0af1ab34&"
import script from "./ComparisonDistrict.vue?vue&type=script&lang=js&"
export * from "./ComparisonDistrict.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QInnerLoading,QSpinnerTail} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInnerLoading,QSpinnerTail})
