
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export function exportToPDF(id = ".report-preview", notify) {
  return new Promise(async (resolve, reject) => {
    try {
      let box = document.querySelector(".report-preview");
      let boxWidth = box.clientWidth;
      let boxHeight = box.clientHeight;
      box.scrollIntoView();
      const canvas = await html2canvas(box, {
        height: boxHeight,
        width: boxWidth,
        
      });
       const imgData = canvas.toDataURL("image/PNG");
      //  let pdfDoc = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
      let pdfDoc = new jsPDF({
        unit: 'pt',
        format: [canvas.width, canvas.height],
      });
      const imageProps = pdfDoc.getImageProperties(imgData);
      const pdfWidth = pdfDoc.internal.pageSize.getWidth();
      const pdfHeight = (imageProps.height * pdfWidth) / imageProps.width;
      pdfDoc.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height - 300, null, 'SLOW');
      const blobImage = pdfDoc.output("blob");
      resolve(blobImage);
    } catch (error) {
      notify({
        type: "negative",
        position: "top",
        message: `${error}`,
      });
      reject(error);
    }
  });
}