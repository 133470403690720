import { AxiosInstance } from 'axios';
import { LogDTO } from '@/models/Log';
import { PaginatedResponse } from '@/models/Pagination';
import { createBackendError } from '@/util/error';

export type GetLogsResponse = PaginatedResponse<LogDTO>;

export default class LogController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getLogs() {
    try {
      const { data } = await this.client.get('/logs', {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetLogsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
