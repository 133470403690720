export class SalesTemplateMap {
  id: string;
  mapping: { [key: string]: string };
  mappingName: string;
  fileName: string;
  filePath: string;

  constructor(initializer?: Partial<SalesTemplateMap>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: SalesTemplateMapDTO): SalesTemplateMap {
    let mapping = {};
    try {
      mapping = JSON.parse(dto.mapping);
    } catch (err) {
      mapping = {};
    }
    return new SalesTemplateMap({
      id: dto.id,
      mapping: mapping,
      mappingName: dto.mappingName,
      fileName: dto.fileName,
      filePath: dto.filePath
    });
  }
}

export interface SalesTemplateMapDTO {
  id: string;
  mapping: string;
  mappingName: string;
  fileName: string;
  filePath: string;
}
