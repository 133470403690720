
	import Vue from "vue";
	import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
	import { fetchOverview } from '../fetchData';
	import axios from 'axios';
	import { addKpiCustomization } from '@/util/kpis';
	import { getKpiTitle } from '@/util/utilFidaFunctions';

	export default {
	  data() {
	   
		return {
		  currentTimeStamp: '',
		  cardHover: "",
		  trendOrder: 4,
		  currentTrend: '',
		  loading: false,
		  kpiOverview: [],
		  metricKeyShort: "",
		  modifiedDate: '',
		};
	  },
	  computed: {
		...mapState('overview', ['kpis','allTrends']),
		...mapState('distribution', ['selectedKPIOptions']),
		...mapState('periodicReport', [
		  'selectedStoreCode',
		  'currentReport',
		  'lastTimeStamp'
		]),
		...mapGetters('filter', [
		  'getStoreCodesList',
		  'getDaysOfWeekList',
		  'getPulseStartingPeriod',
		  'getPulseEndingPeriod',
		  'getPulseStartingCompared',
		  'getCustomCalenderState',
		  'getPulseEndingCompared'
		])
	  },
	  watch: {
		selectedStoreCode() {
		  this.loadData();
		},
		lastTimeStamp(newValue) {
		  this.loadData();
		  this.currentTimeStamp = newValue;
		},
		selectedKPIOptions() {
		  this.loadData();
		}
	  },
	  async created() {
		if (this.selectedStoreCode) {
		  await this.loadData();
		}
	  },
	  beforeDestroy() {
		this.resetFilterForm();
	  },
	  methods: {
		hoverCard(cardName) {
		  this.cardHover = cardName;
		},
		
		async calcLastTimeStamp() {
		  const apiUrl =
			'https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/data/LastModifiedStore.json';
		  const config = {
			headers: {
			  Authorization: ''
			}
		  };

		  const timestamp = new Date().getTime(); // Current timestamp
		  const urlWithTimestamp = `${apiUrl}?timestamp=${timestamp}`;

		  return JSON.stringify(await axios.get(urlWithTimestamp, config))
		},
		leaveCard() {
		  this.cardHover = "";
		},

		cardClick(cardName, order,metricKeyShort) {
		  Vue.prototype?.$mixpanel?.track(cardName, {
			referrer: document.referrer,
		  });

		  if (this.currentTrend === cardName) {
			this.currentTrend = "";
			this.metricKeyShort = "";
		  } else {
			let d = this.$q.screen.md ? 3 :
			this.$q.screen.lg || this.$q.screen.xl ? 4 :
			this.$q.screen.sm ? 2 : 1;
			this.trendOrder = Math.ceil(order/d) * d;
			this.currentTrend = cardName;
			this.metricKeyShort = metricKeyShort;
		  }
		},

		async loadData() {
		  try {
			this.loading = true;
			this.modifiedDate = await this.calcLastTimeStamp();
			const filterParams = {
			  startDate: this.getPulseStartingPeriod,
			  endDate: this.getPulseEndingPeriod,
			  compareStartDate: this.getPulseStartingCompared,
			  compareEndDate: this.getPulseEndingCompared,
			  storeCodes: this.selectedStoreCode,
			  kpis: this.selectedKPIOptions,
			  daysOfWeek: this.getDaysOfWeekList,
			  groupByMetricKey: true
			};
		//	let kpiData = await fetchOverview(filterParams);
       await this.fetchOverview(filterParams);
		
			this.kpiOverview =  this.kpis && addKpiCustomization(this.kpis);

			this.kpiOverview = this.kpiOverview?.filter(el => {return this.selectedKPIOptions.includes(el.metricID)})
			this.kpiOverview = this.kpiOverview.map(kpi => {
				let title;
				try {
					title = getKpiTitle(kpi.metricID);
				} catch (error) {
					// Handle the exception here, you can log the error or provide a default title
					console.error("Error finding title:", error);
					title = "Default Title"; // Or any other fallback value
				}
				return { ...kpi, title };
			});
				
			this.kpiOverview.forEach(kpi => {
			  kpi.title = (kpi?.title ?? kpi.metricKeyShort).split(" ").map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(" ")
			});
		  } catch (error) {
			this.$q.notify({
			  type: 'negative',
			  position: 'top',
			  message: error
			});
		  } finally {
			this.loading = false;
		  }

		}, //
		// async loadData() {
		//   try {
		//     this.loading = true;
		//     const filterParams = {
		//       startDate: this.getPulseStartingPeriod,
		//       endDate: this.getPulseEndingPeriod,
		//       compareStartDate: this.getPulseStartingCompared,
		//       compareEndDate: this.getPulseEndingCompared,
		//       storeCodes: this.selectedStoreCode,
		//       kpis: this.selectedKPIOptions.join(','),
		//       daysOfWeek: this.getDaysOfWeekList,
		//       groupByMetricKey: true
		//     };
		//     let kpiData = await fetchOverview(filterParams);
		//     this.kpiOverview = kpiData.sort(this.kpiSorter);
		//   } catch (error) {
		//     this.$q.notify({
		//       type: 'negative',
		//       position: 'top',
		//       message: error
		//     });
		//   } finally {
		//     this.loading = false;
		//   }
		// },
		...mapActions('overview', ['fetchOverview']),
		// ...mapActions("trend", ["fetchTargetAchive"]),
		...mapMutations('periodicReport', ['resetFilterForm']),
		getTooltip(kpiName: String): String {
		  if (this.$te(`tooltip_${kpiName}`, true)) {
			return this.$t(`tooltip_${kpiName}`);
		  }
		  return '';
		}
	  }
	};
	