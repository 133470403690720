
import CommonMixin from '@/mixins/CommonMixin';
import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin],
  props: {
    role: { type: Object, required: true }
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    async onDelete() {
      this.processing = true;
      try {
        await this.$repo.role.deleteRole(this.role._id);
        this.$emit('deleted', this.role);
        this.$emit('close');
        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: this.$t('SettingsManagement.successfully_deleted')
        } as any);
      } catch (err) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: this.$t('AuthPage.data_used_by_other_items')
        } as any);
        this.$emit('close');
      } finally {
        this.processing = false;
      }
    },
    closeDialog() {
      if (this.processing) return;
      this.$emit('close');
    }
  }
};
