
export default {
  data() {
    return {
      dataTable: [
        {
          name: 'Sales',
          val1: 365,
          val2: 171
        },
        {
          name: 'Traffic',
          val1: 365,
          val2: 171
        },
        {
          name: 'Conversion',
          val1: 365,
          val2: 171
        },
        {
          name: 'UPT',
          val1: 365,
          val2: 171
        },
        {
          name: 'ROV',
          val1: 365,
          val2: 171
        }
      ],
      overviewSelection: 'region',
      series: [
        {
          name: 'Scores',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep'
          ]
        }
      }
    };
  }
};
