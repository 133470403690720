import {
  cityOptions,
  cityOptionsList,
  countryOptions,
  districtOptions,
  districtOptionsList,
  prefectureOptions,
  prefectureOptionsList,
  regionOptions,
  regionOptionsList
} from '@/util/select';

export default {
  data() {
    return {
      countries: [],
      regions: [],
      prefectures: [],
      districts: [],
      cities: [],
      provideNullSelection: false,
      selectedCountry: null,
      selectedRegion: null,
      selectedPrefecture: null,
      selectedDistrict: null,
      selectedCity: null
    };
  },
  computed: {
    countryOptions() {
      const options = countryOptions(this.countries);
      return this.appendNullSelection(options);
    },
    regionOptions() {
      const options = regionOptionsList(this.regions);
      return this.appendNullSelection(options);
    },
    prefectureOptions() {
      const options = prefectureOptionsList(this.prefectures);
      return this.appendNullSelection(options);
    },
    districtOptions() {
      const options = districtOptionsList(this.districts);
      return this.appendNullSelection(options);
    },
    cityOptions() {
      const options = cityOptionsList(this.cities);
      return this.appendNullSelection(options);
    }
  },
  methods: {
    appendNullSelection(original) {
      if (!this.provideNullSelection) return original;
      return [
        {
          label: '(None)',
          value: null
        },
        ...original
      ];
    },
    clearSelection(clearFrom: string) {
      const scopes = ['COUNTRY', 'REGION', 'DISTRICT', 'PREFECTURE', 'CITY'];
      const startIndex = scopes.indexOf(clearFrom);
      for (let i = startIndex; i < scopes.length; i++) {
        const scope = scopes[i];
        if (scope === 'COUNTRY') {
          this.selectedCountry = null;
          this.countries = [];
        }
        if (scope === 'REGION') {
          this.selectedRegion = null;
          this.regions = [];
        }
        if (scope === 'DISTRICT') {
          this.selectedDistrict = null;
          this.districts = [];
        }
        if (scope === 'PREFECTURE') {
          this.selectedPrefecture = null;
          this.prefectures = [];
        }
        if (scope === 'CITY') {
          this.selectedCity = null;
          this.cities = [];
        }
      }
    },
    async selectCountry(countryId) {
      this.selectedCountry = countryId;
      const { $repo } = this;
      this.clearSelection('REGION');
      if (countryId != null) {
        this.regions = await $repo.location.getRegionsByCountry(
          this.selectedCountry
        );
      }
    },
    async selectRegion(regionId) {
      this.selectedRegion = regionId;
      const { $repo } = this;
      this.clearSelection('DISTRICT');
      if (regionId != null) {
        this.districts = await $repo.location.getDistrictsByRegion(
          this.selectedRegion
        );
      }
    },
    async selectDistrict(districtId) {
      this.selectedDistrict = districtId;
      const { $repo } = this;
      this.clearSelection('PREFECTURE');
      if (districtId != null) {
        this.prefectures = await $repo.location.getPrefecturesByDistrict(
          this.selectedDistrict
        );
      }
    },
    async selectPrefecture(prefectureId) {
      this.selectedPrefecture = prefectureId;
      const { $repo } = this;
      this.clearSelection('CITY');
      if (prefectureId != null) {
        this.cities = await $repo.location.getCitiesByPrefecture(
          this.selectedPrefecture
        );
      }
    },
    async selectCity(cityId) {
      this.selectedCity = cityId;
    }
  },
  async created() {
    this.countries = await this.$repo.location.getCountries();
  }
};
