
import { createColumn, formatAsCurrency } from '@/util/table';
import { InitialOrderModalMode } from './OrderModalModes';
import CommonMixin from '@/mixins/CommonMixin';
import RequestOrder from '@/models/RequestOrder';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    value: { default: false, type: Boolean },
    mode: { default: InitialOrderModalMode.VIEW, type: String },
    order: { default: () => ({}), type: Object }
  },
  data() {
    return {
      InitialOrderModalMode: InitialOrderModalMode,
      tableColumns: [],
      tableData: []
    };
  },
  computed: {
    subtotalOrder() {
      if (this.mode === InitialOrderModalMode.VIEW) return this.order;
      const total = this.tableData.reduce(
        (acc, i) => acc + i.price * i.suggested,
        0
      );
      const tax = total * 0.1;
      const grandTotal = total + tax;
      return {
        ...this.order,
        total,
        tax,
        grandTotal
      };
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.formatTableData();
        this.tableColumns = [
          createColumn('sensorName', 'Product Name'),
          createColumn('quantity', 'Quantity'),
          this.mode === InitialOrderModalMode.SUGGEST
            ? createColumn('suggested', 'Suggested Quantity', {
                sortable: false
              })
            : false,
          createColumn('price', 'Price', formatAsCurrency()),
          createColumn('subtotal', 'Subtotal', formatAsCurrency())
        ].filter(Boolean);
      }
    }
  },
  methods: {
    formatTableData() {
      if (this.order == null) return [];
      const orderList = this.order.orders || [];
      const orders = orderList.map(item => ({
        id: item.id,
        sensorName: item.product.name,
        quantity: item.initialQuantity,
        currency: this.order.currency.code,
        suggested: item.initialQuantity,
        price: item.product.price,
        subtotal: item.initialSubtotal
      }));
      this.tableData = orders;
    },
    formatWithCurrency(value) {
      return formatCurrencyWithSymbol(value, this.order.currency.code);
    },
    saveDialog() {
      const order = new RequestOrder(this.order);
      this.tableData.forEach(entry => {
        const orderItem = order.orders.find(item => item.id === entry.id);
        orderItem.suggestedQuantity = entry.suggested;
      });
      this.$emit('suggest', order);
    },
    closeDialog() {
      this.$emit('input', false);
    }
  }
};
