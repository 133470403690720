
import CommonMixin from '@/mixins/CommonMixin';
import moment from 'moment';

export default {
  mixins: [CommonMixin],
  props: {
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      data: {
        date: '',
        time: ''
      },
      dateOptions: function(date) {
        return date >= moment().format('YYYY/MM/dd');
      }
    };
  },
  watch: {
    value(show) {
      if (show) {
        this.data = {
          date: '',
          time: ''
        };
      }
    }
  },
  methods: {
    saveDialog() {
      this.$emit('scheduleSet', this.data);
    },
    closeDialog() {
      this.$emit('input', false);
    }
  }
};
