
import CommonMixin from '@/mixins/CommonMixin';
import Axios, { CancelTokenSource } from 'axios';
import { generateImageDataUrl } from '@/util/file';

interface FileTransmission {
  id: string;
  progress: number;
  filename: string;
  size: number;
  cancelTokenSource: CancelTokenSource;
}

export default {
  mixins: [CommonMixin],
  props: {
    storeId: { required: true, type: String },
    sessionId: { required: true, type: String },
    value: { default: () => [], type: Array },
    showDialog: { default: false, type: Boolean }
  },
  data() {
    return {
      uploading: [],
      images: [...this.value],
      isUploading: false
    };
  },
  watch: {
    showDialog(show) {
      if (show) {
        this.images = [...this.value];
      }
    }
  },
  methods: {
    handleFileUpload(event) {
      const files = event.target.files;
      for (const file of files) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const id = Math.random()
        .toString(36)
        .substr(2, 4);
      const fileInfo = {
        filename: file.name,
        size: file.size
      };

      const cancelTokenSource = Axios.CancelToken.source();

      this.uploading.push({
        id,
        ...fileInfo,
        cancelTokenSource,
        progress: 0
      });
      this.isUploading = true;

      const uploadInfo = { id, ...fileInfo };

      this.notifyQueueLength();
      this.$emit('uploading', uploadInfo);

      try {
        const path = await this.$repo.store.uploadStoreImageForBulkUpdate(
          this.storeId,
          file,
          this.sessionId,
          cancelTokenSource.token,
          e => {
            const item = this.uploading.find(item => item.id === id);
            if (item != null) {
              item.progress = Math.round((e.loaded / e.total) * 100.0);
            }
          }
        );
        this.images = [
          ...this.images,
          {
            id,
            filename: fileInfo.filename,
            path: path
          }
        ];
        this.$emit('uploaded', uploadInfo);
      } finally {
        this.uploading = this.uploading.filter(upload => upload.id !== id);
      }

      this.notifyQueueLength();
    },
    removeImage(i) {
      this.images.splice(i, 1);
    },
    cancelUpload(id) {
      const upload = this.uploading.find(upload => upload.id === id);
      if (upload != null) {
        upload.cancelTokenSource.cancel('Upload cancelled by user');
      }
    },
    notifyQueueLength() {
      const length = this.uploading.length;
      this.$emit('uploading', length);
      if (length === 0) {
        this.isUploading = false;
        this.$emit('done');
      }
    },
    async fetch(url) {
      return await this.$repo.store.getStoreImageAsBase64(url);
    },
    formatter(input, url) {
      return generateImageDataUrl(input, url);
    },
    saveImages() {
      this.$emit('input', this.images);
      this.close();
    },
    close() {
      this.$emit('dialogEvent', false);
    }
  }
};
