import { render, staticRenderFns } from "./StoresCheckbox.vue?vue&type=template&id=5637b8d8&"
import script from "./StoresCheckbox.vue?vue&type=script&lang=js&"
export * from "./StoresCheckbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtnDropdown,QInput,QIcon,QCheckbox,QOptionGroup,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtnDropdown,QInput,QIcon,QCheckbox,QOptionGroup,QBtn})
