
import { mapActions } from 'vuex';
export default {
  props: {
    plan: {
      type: String,
      default: ''
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      department: '',
      message: '',
      loading: false
    };
  },
  methods: {
    ...mapActions('marketplace', ['sendEmailToUser']),
    async submitForm() {
      const details = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        contactNumber: this.phone,
        company: this.companyName,
        department: this.department,
        message: this.message,
        plan: this.plan
      };
      this.loading = true;
      await this.sendEmailToUser(details);
      this.loading = false;
      this.closeModal();
    }
  }
};
