
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';

import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      storeData: []
    };
  },
  async created() {
    try {
      const { data } = await this.$repo.store.getStores();
      this.storeData = data;
    } catch (err) {
      handleErrorUI(err);
    }
  }
};
