const moment = require('moment');
import axios from 'axios';
export default async function weather(startDate, endDate, cityName) {
  try {
    const baseUrl = 'https://sso.flow-insight.com/api/getWeatherData';
    const dateFormat = 'YYYY-MM-DD';
    const start = startDate.format(dateFormat);
    const end = endDate.format(dateFormat);
    const cities = cityName.map(city => city.cityId);
    const isHourly = start === end;
    var getApiWeather = async () => {
      const response = await axios.get(baseUrl, {
        params: {
          frequency: isHourly ? 'hourly' : 'daily',
          startDate: start,
          endDate: end,
          cities: cities.toString()
        }
      });
      return response.response.data;
    };
    const weather = await getApiWeather();
    // Iterate through the object
    const weatherObj = {};
    var dataHourly = [];
    for (const key in weather) {
      if (weather.hasOwnProperty(key)) {
        const tempObj = { temp: 0, tempMin: 0, tempMax: 0 };
        const iconCounts = {};
        const descriptionCounts = {};
        const hourly = {};
        for (const city in weather[key]) {
          var obj = weather[key][city];
          // hourly
          if (isHourly) {
            for (const hr in obj) {
              const temp = hourly[hr]?.temp || 0;
              const tempMax = hourly[hr]?.tempMax || 0;
              const tempMin = hourly[hr]?.tempMin || 0;
              if (!hourly[hr]) {
                hourly[hr] = {};
              }
              hourly[hr].temp = obj[hr].temp + temp;
              hourly[hr].tempMax = obj[hr].tempMax + tempMax;
              hourly[hr].tempMin = obj[hr].tempMin + tempMin;
              iconCounts[obj[hr].icon] = (iconCounts[obj[hr].icon] || 0) + 1;
              descriptionCounts[obj[hr].description] =
                (descriptionCounts[obj[hr].description] || 0) + 1;
            }
          } else {
            // daily
            tempObj.temp += obj.temp;
            tempObj.tempMax += obj.tempMax;
            tempObj.tempMin += obj.tempMin;
            // //   Count occurrences of icons and descriptions
            iconCounts[obj.icon] = (iconCounts[obj.icon] || 0) + 1;
            descriptionCounts[obj.description] =
              (descriptionCounts[obj.description] || 0) + 1;
          }
        }

        // Find the most common icon and description
        const mostCommonIcon = Object.keys(iconCounts) && Object.keys(iconCounts).length && Object.keys(iconCounts)?.reduce((a, b) =>
          iconCounts[a] > iconCounts[b] ? a : b
        );

        const mostCommonDescription = Object.keys(descriptionCounts) && Object.keys(descriptionCounts).length &&  Object.keys(descriptionCounts)?.reduce((a, b) =>
          descriptionCounts[a] > descriptionCounts[b] ? a : b
        );
        //
        var divider = Object.keys(weather[key])?.length;
        var dateKey = moment(key).format(dateFormat);
        var average = {
          temp: tempObj.temp / divider,
          tempMax: tempObj.tempMax / divider,
          tempMin: tempObj.tempMin / divider,
          icon: mostCommonIcon,
          description: mostCommonDescription
        };
        for (const hour in hourly) {
          if (hourly.hasOwnProperty(hour)) {
            hourly[hour].temp = hourly[hour].temp / divider;
            hourly[hour].tempMax = hourly[hour].tempMax / divider;
            hourly[hour].tempMin = hourly[hour].tempMin / divider;
            hourly[hour].icon = mostCommonIcon;
            hourly[hour].description = mostCommonDescription;
          }
        }
        weatherObj[dateKey] = average;
        dataHourly = [hourly];
      }
    }
    // console.log({ weather: isHourly ? dataHourly : [weatherObj] }, 'weather');
    return {
      weather: isHourly ? dataHourly : [weatherObj]
    };
  } catch (error) {
    console.log(error);
  }
}