
import { mockStores } from '@/util/mocks';
import { createColumn } from '@/util/table';
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'StoreMockTable',
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      data: mockStores(),
      columns: [
        createColumn('name', this.$t('SettingsManagement.name') as string),
        createColumn(
          'storeNumber',
          this.$t('SettingsManagement.number') as string
        ),
        createColumn('region', this.$t('SettingsManagement.region') as string),
        createColumn(
          'district',
          this.$t('SettingsManagement.district') as string
        ),
        createColumn(
          'prefecture',
          this.$t('SettingsManagement.prefecture') as string
        ),
        createColumn('city', this.$t('SettingsManagement.city') as string),
        createColumn('status', this.$t('SettingsManagement.status') as string),
        // createColumn('plan', this.$t('SettingsManagement.planName') as string),
        createColumn(
          'planType',
          this.$t('SettingsManagement.planType') as string
        ),
        createColumn('actions', this.$t('SettingsManagement.actions') as string)
      ]
    };
  }
};
