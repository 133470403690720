
import { VueFunnelGraph } from 'vue-funnel-graph-js';
// import JSCharting from 'jscharting-vue';

export default {
  name: 'SimpleLine',
  components: {
    VueFunnelGraph
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['cuwidth'],
  data () {
    return {
      // labels: ['Campiang Letter Read Rate', 'Capature Rate', 'Conversion'],
      // subLabels: [],
      // values: [12000, 4700, 930],
      // colors: ['#DBE9F5', '#6AACE2'],
      // // colors: [
      // //   ['#DBE9F5', '#6AACE2'], // color set for "Impressions" segment
      // //   ['#DBE9F5', '#6AACE2'], // color set for "Add To Cart" segment
      // //   ['#DBE9F5', '#6AACE2']  // color set for "Buy" segment
      // // ],
      // direction: 'vertical',
      // gradientDirection: 'vertical',
      // height: 400,
      // width: 600
      labels: ['Campiang Letter Read Rate', 'Capature Rate', 'Conversion', 'Return'],
      subLabels: [],
      values: [12000, 8700, 930, 260],
      colors: ['#DBE9F5', '#6AACE2'],
      direction: 'vertical',
      gradientDirection: 'vertical',
      height: 400,
      width: this.cuwidth,
      dataSetNum: 1
    };
  },
  mounted () {

  },
};
