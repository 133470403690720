var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-table',{class:_vm.$style.table,attrs:{"flat":"","columns":_vm.columns,"data":_vm.data,"pagination":_vm.quasarPagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"body-cell-action",fn:function(props){return [_c('q-td',{attrs:{"props":props}},[_c('div',{class:_vm.$style.actions},[_c('router-link',{attrs:{"to":{
              name: 'settings:peer-group:view',
              query: { id: props.row.id }
            }}},[(_vm.viewAllowed('FlowPeerGroupManagementView'))?_c('c-btn',{attrs:{"text-color":"secondary","label":_vm.$t('SettingsManagement.view')}}):_vm._e()],1),(_vm.viewAllowed('FlowPeerGroupManagementEdit'))?_c('router-link',{attrs:{"to":{
              name: 'settings:peer-group:edit',
              query: { id: props.row.id }
            }}},[_c('c-btn',{attrs:{"text-color":"secondary","label":_vm.$t('SettingsManagement.edit')}})],1):_vm._e(),(_vm.viewAllowed('FlowPeerGroupManagementDelete'))?_c('c-btn',{attrs:{"id":"row-delete","text-color":"negative","label":_vm.$t('SettingsManagement.Delete')},on:{"click":function($event){return _vm.showDeleteConfirmation(props.row)}}}):_vm._e()],1)])]}}])}),_c('delete-dialog',{attrs:{"name":(_vm.rowDataSelected || {}).name,"show":_vm.deleteConfirmationShown,"on-delete":_vm.deletePeerGroup},on:{"close":function($event){_vm.deleteConfirmationShown = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }