import weather from '../../../public/weather.js';
const initialState = {
  weather: [],
  weatherCompare: [],
  hourlyWeather: [],
  hourlyWeatherC: []
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    weather(state, payload) {
      state.weather = payload;
    },
    setComparedWeather(state, payload) {
      state.weatherCompare = payload;
    }, // setComparedPeriod
    weatherHourly(state, payload) {
      state.hourlyWeather = payload;
    },
    setComparedWeatherHourly(state, payload) {
      state.hourlyWeatherC = payload;
    }, // setComparedPeriod
    setCitiesListWeather(state, payload) {
      state.citiesList = payload;
    } // setComparedPeriod
  }, // mutations
  actions: {
    async getWeather({ commit }, { startDate, endDate, cityName }) {
      const res = await weather(startDate, endDate, cityName);
      commit('weather', res?.weather);
      // commit('weatherHourly', res?.hourlyWeather);
      // console.log('weatherHourly', res?.hourlyWeather);

      // localStorage.setItem('weather', JSON.stringify(res?.hourlyWeather) );
      // localStorage.setItem('weatherHourly', JSON.stringify(res?.hourlyWeather) );
      // if(setCompare){
      //   // localStorage.setItem('weatherCompare', JSON.stringify(res?.hourlyWeather) );
      //   // localStorage.setItem('weatherHourlyC', JSON.stringify(res?.hourlyWeather) );
      //   commit('setComparedWeather', res?.weather);
      //   // commit('setComparedWeatherHourly', res?.hourlyWeather);
      // }
      return res;
    },
    async getCompareWeather({ commit }, { startDate, endDate, cityName }) {
      const res = await weather(startDate, endDate, cityName);
      // commit('weather', res);
      // localStorage.setItem('weatherCompare', JSON.stringify(res?.hourlyWeather) )
      // localStorage.setItem('weatherHourlyC', JSON.stringify(res?.hourlyWeather) )
      // commit('setComparedWeatherHourly', res?.hourlyWeather);
      commit('setComparedWeather', res?.weather);
      return res;
    }
  }
}; // export default