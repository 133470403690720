import { render, staticRenderFns } from "./DeleteDialog.vue?vue&type=template&id=3a279644&scoped=true&"
import script from "./DeleteDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteDialog.vue?vue&type=script&lang=js&"
import style0 from "./DeleteDialog.vue?vue&type=style&index=0&id=3a279644&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a279644",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QCardActions} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QCardActions})
