import { APIFetchTrafficPrediction } from '@/api/trafficPrediction';

const initialState = {
  trafficPrediction: []
};

export default {
  state: initialState,
  mutations: {
    setTrafficPrediction(state, { data }) {
      state.trafficPrediction = data;
    }
  },
  actions: {
    async fetchTrafficPrediction({ commit }, params) {
      const res = await APIFetchTrafficPrediction(params);
      commit('setTrafficPrediction', { data: res.data });
      return res;
    }
  }
};
