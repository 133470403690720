const ASSUMED_REPORTING_HOURS_IMPROVEMENT_FACTOR = 0.5;
const INITIAL_INSTALLATION_COST = 100000;
const MONTHLY_SOFTWARE_RUNNING_COST_PER_LOCATION = 6900;
const ASSUMED_CONVERSION_IMPROVEMENT = 1.05;
const PER_HOUR_REPORTING_COST_PER_STAFF = 2000;
const MONTHLY_HARDWARE_COST_PER_UNIT = 11000;
const DAYS_PER_MONTH = 30;
const REPORTING_HOURS_PER_LOCATION_PER_DAY = 2;
const HEAD_OFFICE_STAFF_REPORTING_FACTOR = 1.25;
const ONBOARDING_COST = 300000;
const SENSORS_PER_LOCATION = 2.5;
const GENERIC_CONVERSION_RATE = {
  Fashion: 6.25,
  'Fast Fashion': 22.8,
  Electronics: 53.3,
  Luxury: 7.92
};

export const getCurrentOperatingExpense = numOfLocations =>
  REPORTING_HOURS_PER_LOCATION_PER_DAY *
  numOfLocations *
  DAYS_PER_MONTH *
  HEAD_OFFICE_STAFF_REPORTING_FACTOR *
  PER_HOUR_REPORTING_COST_PER_STAFF;

export const getGenericConversionRate = segment =>
  GENERIC_CONVERSION_RATE[segment] / 100;

export const getTotalInitialCosts = numOfLocations => {
  return numOfLocations * INITIAL_INSTALLATION_COST + ONBOARDING_COST;
};

export const getTotalMonthlyCosts = numOfLocations => {
  return (
    numOfLocations * SENSORS_PER_LOCATION * MONTHLY_HARDWARE_COST_PER_UNIT +
    numOfLocations * MONTHLY_SOFTWARE_RUNNING_COST_PER_LOCATION
  );
};

export const getTwelveMonthRunningCosts = totalMonthlyCost =>
  totalMonthlyCost * 12;

export const getSavingsFromReportOptimizationInHoursPerMonth = numOfLocations => {
  return (
    REPORTING_HOURS_PER_LOCATION_PER_DAY *
    numOfLocations *
    DAYS_PER_MONTH *
    ASSUMED_REPORTING_HOURS_IMPROVEMENT_FACTOR *
    HEAD_OFFICE_STAFF_REPORTING_FACTOR
  );
};

export const getSavingsFromReportOptimizationInYenPerMonth = savingsFromReportOptimizationInHoursPerMonth =>
  savingsFromReportOptimizationInHoursPerMonth *
  PER_HOUR_REPORTING_COST_PER_STAFF;

export const getTotal1stYearCosts = (
  totalInitialCosts,
  twelveMonthRunningCosts
) => totalInitialCosts + twelveMonthRunningCosts;

export const getROI = (
  monthlySalesImprovement,
  savingsFromReportOptimizationInYenPerMonth,
  total1stYearCosts
) =>
  (((monthlySalesImprovement + savingsFromReportOptimizationInYenPerMonth) *
    12) /
    total1stYearCosts) *
  100;

export const getCurrentEstimatedTraffic = (
  amountOfSales,
  averagePurchase,
  conversionRateBasedOnRetailCategory
) => amountOfSales / averagePurchase / conversionRateBasedOnRetailCategory;

export const getFutureConversionRate = conversionRateBasedOnRetailCategory =>
  conversionRateBasedOnRetailCategory * ASSUMED_CONVERSION_IMPROVEMENT;

export const getFutureExpectedSales = (
  currentEstimatedTraffic,
  futureConversion,
  averagePurchase
) => currentEstimatedTraffic * futureConversion * averagePurchase;

export const getMonthlySales = (
  currentEstimatedTraffic,
  conversion,
  averagePurchase
) => currentEstimatedTraffic * averagePurchase * conversion;

export const getMonthlySalesImprovement = (
  futureExpectedSales,
  amountOfSales
) => futureExpectedSales;

export const getCalculatedResults = calculatorValues => {
  const savingsFromReportOptimizationInHoursPerMonth = getSavingsFromReportOptimizationInHoursPerMonth(
    calculatorValues.locations
  );

  const totalInitialCosts = getTotalInitialCosts(calculatorValues.locations);
  const totalMonthlyCosts = getTotalMonthlyCosts(calculatorValues.locations);
  const twelveMonthRunningCost = getTwelveMonthRunningCosts(totalMonthlyCosts);

  const total1stYearCost = getTotal1stYearCosts(
    totalInitialCosts,
    twelveMonthRunningCost
  );
  const genericConversionRate = getGenericConversionRate(
    calculatorValues.segment
  );

  const currentEstimatedTraffic = getCurrentEstimatedTraffic(
    calculatorValues.monthlyRevenue,
    calculatorValues.averagePurchasePrice,
    genericConversionRate
  );

  const futureConversionRate = getFutureConversionRate(genericConversionRate);

  const futureExpectedSales = getFutureExpectedSales(
    currentEstimatedTraffic,
    futureConversionRate,
    calculatorValues.averagePurchasePrice
  );

  //monthlySalesImprovement

  const currentReportingExpenses = getCurrentOperatingExpense(
    calculatorValues.locations
  );

  const currentSales = getMonthlySales(
    currentEstimatedTraffic,
    genericConversionRate,
    calculatorValues.averagePurchasePrice
  );

  const monthlySalesImprovement = getMonthlySalesImprovement(
    futureExpectedSales,
    calculatorValues.monthlyRevenue
  );
  //savings from report
  const savingsFromReportOptimizationInYenPerMonth = getSavingsFromReportOptimizationInYenPerMonth(
    savingsFromReportOptimizationInHoursPerMonth
  );
  //  roi
  const roi = getROI(
    monthlySalesImprovement - calculatorValues.monthlyRevenue,
    savingsFromReportOptimizationInYenPerMonth,
    total1stYearCost
  );
  const calculatedValues = {
    savingsFromReportOptimizationInHoursPerMonth,
    totalInitialCosts,
    totalMonthlyCosts,
    total1stYearCost,
    genericConversionRate,
    currentEstimatedTraffic,
    futureConversionRate,
    currentSales,
    futureExpectedSales,
    currentReportingExpenses
  };
  const results = {
    monthlySalesImprovement,
    savingsFromReportOptimizationInYenPerMonth,
    roi
  };

  return [calculatedValues, results];
};
