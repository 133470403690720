

  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { kpiMapping, kpiList } from '../../util/mockData/kpiMapping';
import { filterAvailableKpis } from '@/util/kpis';
import PaginationMixin from '@/mixins/PaginationMixin';

  export default {
    mixins: [PaginationMixin],
    props: {
      startdate: { type: String, default: '' },
      enddate: { type: String, default: '' },
      storecodes: { type: String, default: '' }
  },
    data() {
      return {
        pagination: {
          rowsPerPage: 0
        },
        selectedKPIOptionList: [],
        loading: false,
        columns: [],
        data: [],
        formattedTableData: [],
        parameter: '',
        customSort: ['Date', 'Weather']
      };
    },
    computed: {
      ...mapGetters('filter', [
        'getStoreCodesList',
        'getDaysOfWeekList',
        'getStartingPeriod',
        'getEndingPeriod',
        'tabluarDataKpiOptions'
      ]),
      ...mapState('filter', ['tabluarDataKpiOptions']),
      ...mapState('distribution', ['selectedKPIOptions']),
      ...mapState('distribution', ['byDate']),
      ...mapState('user', ['kpiPreferences'])
    },
    watch: {
      selectedKPIOptionList: {
        handler: 'loadData',
        immediate: true
      },
      tabluarDataKpiOptions(newValue) {
        this.selectedKPIOptionList = filterAvailableKpis(newValue);
      }
    },
    created() {
      this.kpiPreferences.map(kpi => {
        this.customSort.push(kpi.metricKey);
      });
      this.getLocalStorageTabularKPI();

      this.selectedKPIOptionList = filterAvailableKpis(
        this.tabluarDataKpiOptions
      );
    },
    methods: {
      ...mapActions('distribution', ['fetchDistributionByDate']),
      ...mapMutations('filter', ['getLocalStorageTabularKPI']),
      kpisorter(x, y) {
        return (
          this.customSort.indexOf(x.field) - this.customSort.indexOf(y.field)
        );
      },
      handleSearch(e) {
        this.data = this.formattedTableData.filter(tableData => {
          if (e.target.name === '天気' || e.target.name === 'Weather') {
            return tableData[e.target.name].Temperature.toLowerCase().includes(
              e.target.value.toLowerCase()
            );
          } else {
            return tableData[e.target.name]
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
        });
      },
      async loadData() {
        try {
          this.loading = true;
          this.data = [];
          this.columns = [];

          // * updates this.byDate
          await this.fetchDistributionByDate({
            startDate: this.getStartingPeriod,
            endDate: this.getEndingPeriod,
            storeCodes: this.storecodes, // this.getStoreCodesList,
            daysOfWeek: this.getDaysOfWeekList,
            kpis: this.selectedKPIOptionList.join(',')
          });
          const byDate = { ...this.byDate };
          const availableDate = Object.keys(byDate);

          if (Object.keys(byDate[availableDate[0]]).length) {
            const tabularData = availableDate.map(singleDate => {
              const singleTableRow = { ...byDate[singleDate] };

              ['Weather', 'WeatherIcon', 'Temperature'].forEach(
                col => delete singleTableRow[col]
              );
              if (byDate[singleDate].Temperature) {
                return {
                  [this.$t('Date')]: singleDate,
                  [this.$t('weather')]: {
                    Temperature: byDate[singleDate].Temperature,
                    Weather: byDate[singleDate].Weather,
                    WeatherIcon: byDate[singleDate].WeatherIcon
                  },
                  ...singleTableRow
                };
              } else {
                return {
                  [this.$t('Date')]: singleDate,
                  ...singleTableRow
                };
              }
            });

            const sortedKpis = this.kpiPreferences.map(k => k.title);
            let singleTabularData = Object.keys(tabularData[0]);
            singleTabularData.sort((a, b) =>
              sortedKpis.indexOf(a) > sortedKpis.indexOf(b) ? 1 : -1
            );
            if (singleTabularData.includes(this.$t('weather'))) {
              singleTabularData = [
                this.$t('Date'),
                this.$t('weather'),
                ...singleTabularData
              ];
            }
            const tabularColumn = Array.from(new Set(singleTabularData))
              .map(column => {
                return {
                  name:
                    this.kpiPreferences.find(k => k.metricKey === column)
                      ?.title ?? this.$t(column),
                  align: 'center',
                  label:
                    this.kpiPreferences.find(k => k.metricKey === column)
                      ?.title ?? this.$t(column),
                  field: column,
                  sortable: true
                };
              })
              .sort(this.kpisorter);
            this.data = [...tabularData];
            this.columns = [...tabularColumn];
            this.formattedTableData = [...tabularData];
          }
        } catch (error) {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('errorMsg')
          });
        } finally {
          this.loading = false;
        }
      }
    }
  };
  