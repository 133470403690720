import router from '@/router';
import store from '../store';
import axios from 'axios';
export const fetchClass = async (url, data, head, noauth?: any) => {
  const fetch = axios.create();
  let requestUrl = null;
  let body = {};
  let params = {};

  if (data.method == 'GET') {
    requestUrl = url;
    params = {
      ...data.params,
      ...data.body
    };
  } else {
    requestUrl = url;
    body = {
      data: JSON.stringify({
        ...data.body
      })
    };
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': `${store.getters['user/getLocale']}`,
    Authorization: `Bearer ${store.getters['user/getToken']}`,
    'client-id': process.env.VUE_APP_CLIENT_ID,
    'client-secret': process.env.VUE_APP_CLIENT_SECRET,
    ...head
  };
  if (noauth === true) {
    delete headers.Authorization;
  }
  const response = await fetch(requestUrl, {
    method: data.method,
    ...body,
    params,
    headers
  });

  if (response.status === 200) {
    const res = await response.data;
    return res;
  } else {
    // store.dispatch('user/clearSession');
    // store.dispatch('tenant/reset');
    // router.push({ name: 'auth:login' }).catch(_ => {});
    // localStorage.clear();
    throw response;
  }
};
