import {
  APIFetchDetailOverview,
  APIFetchOverview,
  OverviewParams
} from '@/api/overview';
import { addKpiCustomization } from '@/util/kpis';
import { debug } from 'webpack';

export interface KpiOverView {
  comparedValue: string;
  growthIndicator: number;
  kpi: string;
  metricKey: string;
  metricKeyShort: string;
  value: string;
  variance: string;
  variation: string;
  variationPercentage: string;
}

export const fetchOverview = async (
  params: OverviewParams
): Promise<KpiOverView[]> => {
  const apiResponse = await APIFetchOverview(params);
  const kpis = apiResponse.data[0].kpis;
  const kpiOverView: KpiOverView[] = Object.keys(kpis).map((kpi: string) => {
    if (kpis[kpi].metricKey == "Sales Per Sqm") {
      kpis[kpi].metricKey = "Sales Per Tsubo"
    }
    if (kpis[kpi].metricKey == "Traffic Per Sqm") {
      kpis[kpi].metricKey = "Traffic Per Tsubo"
    }
    return {
      ...kpis[kpi],
      kpi
    };
  });
  return kpiOverView;
};

export const fetchDetailOverview = async (
  params: OverviewParams
): Promise<KpiOverView[]> => {
  const apiResponse = await APIFetchDetailOverview(params);
  const kpis = apiResponse.data[0].kpis;
  const kpiOverView: KpiOverView[] = Object.keys(kpis).map((kpi: string) => {
    return {
      ...kpis[kpi],
      kpi
    };
  });
  return kpiOverView;
};
