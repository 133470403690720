
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import store from '@/store';


export function formatValues(val, key) {
  if(key == "dw") {
    let t = '';
    const lang = store.getters['user/getLocale'];
  if(lang.toLowerCase() == 'en' || lang.toLowerCase() == 'en-us')
    //return `${minutes}min : ${remainingSeconds}s`;
    t = `${Math.floor((val||0)/60)}min : ${Math.round((val||0)%60)}s`
  else
    t = `${Math.floor((val||0)/60)}分 : ${Math.round((val||0)%60)}秒`
   // return `${minutes}分  : ${remainingSeconds}秒`;

    return t
  } else {
    return `${getSignsLeftSide(key)}${val}${getSignsRightSide(key)}`;
  } 
}

let properties = {};

export function addProperty(key, k, sK, mId) {
  properties[key] = {metricKey: k, metricKeyShort: sK, metricID: mId}
}

addProperty("sls", "Sales", "Sales", "sls");
addProperty("tra", "Target Achievement Rate", "Target Achievement Rate", "tra");
addProperty("trf", "Traffic", "Traffic", "trf");
addProperty("cn", "Conversion", "Conversion", "cn");
addProperty("trn", "Transaction", "Transaction", "trn");
addProperty("is", "Units Sold", "Units Sold", "is");
addProperty("pb", "Passers By", "Passers By", "pb");
addProperty("sh", "Staff Hours", "Staff Hours", "sh");
addProperty("dw", "Store Dwell Time", "Store Dwell Time", "dw");
addProperty("ap", "Average Purchase", "Avg Purchase", "ap");
addProperty("aip", "Average Item Price", "Avg Item Price", "aip");
addProperty("upt", "Units Per Transaction", "UPT", "upt");
addProperty("rov", "Return on Visit", "ROV", "rov");
addProperty("spt", "Sales Per Sqm", "Sales Per Sqm", "spt");
addProperty("tpt", "Traffic Per Sqm", "Traffic Per Sqm", "tpt");
addProperty("sl", "Staff Level", "Staff Level", "sl");
addProperty("se", "Staff Efficiency", "Staff Efficiency", "se");
addProperty("cr", "Capture Rate", "Capture Rate", "cr");
addProperty("cs", "Closing Strength", "Closing Strength", "cs");
addProperty("st", "Sales Target", "Sales Target", "st");
// addProperty("engagementrate", "Engagement Rate", "Engagement Rate", "engagementrate");
// addProperty("bouncerate", "Bounce Rate", "Bounce Rate", "bouncerate");
// addProperty("facadestops", "Facade Stops", "Facade Stops", "facadestops");
// addProperty("customerinteraction", "Customer Interaction", "Customer Interaction", "customerinteraction");
// addProperty("demographics", "Demographics", "Demographics", "demographics");//demographics
// addProperty("occupancy", "Occupancy", "Occupancy", "occupancy");//occupancy
// addProperty("salestarget", "Sales Target", "Sales Target", "salestarget");//salestarget
// addProperty("trafficin", "Traffic In", "Traffic In", "trafficin");//trafficin
// addProperty("trafficout", "Traffic Out", "Traffic Out", "trafficout");//trafficout
// addProperty("weather", "Weather", "Weather", "weather");//weather
// addProperty("targetrate", "Target Rate", "Target Rate", "targetrate");//targetrate
// addProperty("customerinteraction", "Customer Interaction", "Customer Interaction", "customerinteraction");//targetrate

export const compatibleList = {
  // "passerby": "pb", 
  // "totalitemsold": "unitsold",
  // "sc": "sh",
  // "cs": "cs"
}
export const compListToFida = {
  // "pb": "passerby", 
  // "unitsold": "totalitemsold",
}
export function getObjByKeyTree(key) {
  return properties[key] || properties[compatibleList[key]] || undefined;
}

//CONSTANTS

export const sortingKpiOrder = "sls,trf,cn,ap,trn,aip,upt,rov,spt,tpt,se,cs,pb,cr,dw,is,sh,sl,st".split(',');
export const avgList = ["cn", "cr", "aip", "ap", "spt", "tpt", "upt", "rov", "sl", "sh", "sc", "se", "cs", "dw", "tra"];
export const notFixed = []
//, 
export const zeroFixed = ["spt", "sls", "trf", "ap", "aip", "trn", "rov", "pb", "sl", "sh", "sc", "se", "cs","st"]
export const variationInNumbers = ["tpt", "spt", "rov", "se", "cs", "sh", "sl","is"]
export const variationInNumbersPadded = ["upt"]
export const variationLocale = ["ap", "aip"]
export const variationPercDiff = ["cn", "cr", "tra"]

export const directCalc = ["aip", "ap", "upt", "rov", "spt", "tpt", "cn", "tra", "cr"];
const relatedKpis = {
  "cn": {
    "trn": 1,
    "trf": 1
  },
  "ap": {
    "sls": 1,
    "trn": 1
  },
  "aip": {
    "sls": 1,
    "is": 1
  },
  "upt": {
    "is": 1,
    "trn": 1
  },
  "rov": {
    "sls": 1,
    "trf": 1
  },
  "spt": {
    "sls": 1
  },
  "tpt": {
    "trf": 1
  },
  "cr": {
    "trf": 1,
    "pb": 1
  },
  "sl": {
    "trf": 1,
    "sh": 1
  },
  "cs": {
    "trn": 1,
    "sh": 1
  },
  "se": {
    "sls": 1,
    "sh": 1
  },
  "tra": {
    "sls": 1,
    "st": 1
  }
}

function getRelatedKpis(key) {
  return relatedKpis[key] ? Object.keys(relatedKpis[key]).join(",") + "," : "";
}

export function getAllRelatedKpis(kpiArr) {
  let retVal = "";
  kpiArr.forEach((k) => {
    retVal += k + "," + getRelatedKpis(k)
  })
  retVal = retVal.split(',')
  retVal = retVal.filter((d, i) => {
    return d && retVal.indexOf(d) == i
  })
  return retVal;
}

function isDirectCalc(key) {
  return directCalc.indexOf(key) > -1;
}

function decimalDigit(val) {
  return Math.round(val*100)/100;
}
function divideTwo(a, b) {
  return !a || !b ? a || 0 : a/ b;
}

function divideTwo2(a, b) {
  return !a || !b ? 0 : a/ b;
}

function getTsubu(k, obj, area) {
  if(k == "spt") {
    return divideTwo(obj.sales, area)
  } else if(k == "tpt") {
    return divideTwo(obj.traffic, area)
  }
  return 0
}

function directCalcFn(key, val, area) {
  let a=0
  if(key == 'cn') {
    a = divideTwo2(val.trn,val.trf) * 100
  } else if(key == 'ap') {
    a = divideTwo(val.sls,val.trn)
  } else if(key == 'aip') {
    a = divideTwo2(val.sls,val.is)
  } else if(key == 'upt') {
    a = divideTwo(val.is,val.trn)
  } else if(key == 'rov') {
    a = divideTwo2(val.sls,val.trf)
  } else if(key == 'se') {
    a = divideTwo(val.sls,val.sh);
  } else if(key == 'cs') {
    a = divideTwo2(val.trn,val.sh);
  } else if(key == 'cr') {
    a = divideTwo2(val.trf* 100 ,val.pb) 
  } else if(key == 'sl') {
    a = divideTwo(val.trf,val.sh);
  } else if(key == 'spt') {
    a = divideTwo(val.sls, area)
  } else if(key == 'tpt') {
    a = divideTwo(val.trf, area)
  } else if(key == 'tra') {
    a = divideTwo2(val.sls, val.st) * 100;
  }
  return a
}

export function getDistrictStoreCodes(storeData, type, n) {
  let retVal = {}
  storeData.dataAccessStores.forEach((s) => {
    if(s.locationDetails.district == n) {
      retVal[n] = (retVal[n] || '') +s.storeNumber + ","
    }
  })

  return Object.values(retVal).join(',');
}

export function getStoreCodesByType(storeData, type, names) {
  if(type == "stores") {
    let retVal = {};
    let st = storeData.dataAccessStores.map((itm) => {
      return itm.storeNumber
    })
    st.forEach((s) => {
      retVal[s] = s
    })
    return retVal;
  } else if (type == "districts") {
    let retVal = {}
    names.split(',').forEach((n) => {
      storeData.dataAccessStores.forEach((s) => {
        if(s.locationDetails.district == n) {
          retVal[n] += s.storeNumber + ","
        }
      })
    })
  }
}

export const directCalcObj = {
  isDirectCalc,
  directCalcFn,
  getTsubu
}
