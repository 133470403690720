
import ApexCharts from 'apexcharts';
import { addSign } from '@/util/replaceFidaKpi';
import moment from 'moment';

import { mapState, mapActions, mapGetters } from 'vuex';
import { getStartDayOfWeek } from '@/util/calendar.js';
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  getDay
} from 'date-fns';
import { APIGetTriggeredMessages } from '@/api/fidaApi';
import { DUO_TONES, MONO_TONE } from '@/util/colors';
import { key } from 'localforage';

export default {
  props: {
    kpi: {
      type: String,
      required: true
    },
    barChartData: {
      type: Array, // Adjust the type according to your data structure
      required: true
    }
  },
  data() {
    return {
      comp: '',
      kpiData: {},
      loading: false,
      yesterdayDate: '',
      previousDate: '',
      previousYearDate: '',
      datesObj: {}
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('overview', { overViewKpis: 'kpis' })
  },

  mounted() {
    this.loading = true;
    this.fetchKpiData();
  },

  methods: {
    ...mapActions('overview', ['fetchOverview']),

    renderChart() {
      const options = {
        chart: {
          type: 'bar',
          height: 300
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        series: [
          {
            name: this.$store.state.user.translate.kpi_data
              ? this.$store.state.user.translate.kpi_data
              : 'KPI Data',
            data: [
              this.kpiData['previousYear'],
              this.kpiData['previousPeriod'],
              this.kpiData['yesterday']
            ]
          }
        ],
        xaxis: {
          categories: [
            this.$store.state.user.translate.kpitree_graph_label_1,
            this.$store.state.user.translate.kpitree_graph_label_2,
            this.$store.state.user.translate.kpitree_graph_label_3
          ],
          labels: {
            style: {
              fontSize: '12px',
              cssClass: 'apexcharts-xaxis-label'
            }
          }
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: value => {
              if (this.kpi === 'sls') {
                return `¥${Math.floor(value).toLocaleString()}`;
              } else if (
                this.kpi === 'cn' ||
                this.kpi === 'cr' ||
                this.kpi === 'tra'
              ) {
                return `${value.toFixed(2)}%`;
              } else {
                return value.toLocaleString();
              }
            }
          }
        },
         annotations: {
        yaxis: [
          {
            y: this.kpiData['average'],
            borderColor: '#FF0000',
            strokeDashArray: 0,
            borderWidth: 2,
            opacity: 0.9,
            label: {
              text: (() => {
                const value = this.kpiData['average'];
                if (this.kpi === 'sls') {
                  return this.$store.state.user.translate.average +' '+ `¥${Math.floor(value).toLocaleString()}`;
                } else if (
                  this.kpi === 'cn' ||
                  this.kpi === 'cr' ||
                  this.kpi === 'tra'
                ) {
                  return this.$store.state.user.translate.average +' '+ `${value.toFixed(2)}%`;
                } else {
                  return this.$store.state.user.translate.average + ' '+value.toLocaleString();
                }
              })(),
              style: {
                color: '#FF0000',
                fontSize: '12px',
                background: '#fff'
              }
            }
          }
        ]
      },
        /*  tooltip: {
            y: {
              formatter: (val) => {
                return this.addSigns(val.toLocaleString());
              }
            }
          },
          */
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const getFullDay = val => {
              if (val === 0) {
                return 'Sun';
              }
              if (val === 1) {
                return 'Mon';
              }
              if (val === 2) {
                return 'Tue';
              }
              if (val === 3) {
                return 'Wed';
              }
              if (val === 4) {
                return 'Thu';
              }
              if (val === 5) {
                return 'Fri';
              }
              if (val === 6) {
                return 'Sat';
              }
            };
            const value = series[seriesIndex][dataPointIndex];
            const formattedValue = this.addSigns(value.toLocaleString());
            let Dates;
            if (dataPointIndex == 0) Dates = this.datesObj.previousYearDate;
            else if (dataPointIndex == 1) Dates = this.datesObj.previousDate;
            else Dates = this.datesObj.yesterdayDate;

            return `
            <q-card class="flex column q-pa-md tooltip-container">
              <div class="flex justify-between">
                   <label>
                   ${Dates}
                   </label>
                      <div class="flex">
                      
                 </div>
                </div>
                <div class="flex tooltip-title items-center">
              <div class="tooltip-title-color" style="background:${w.config.colors[0]}"></div>

                  <label>${this.$store.state.user.translate.selected_period}:</label>
                  <div class="q-pl-md">${formattedValue}</div>
                </div>
          
            </q-card>`;
          }
        },
        colors: DUO_TONES,
        // Disable download and menu buttons
        toolbar: {
          show: false
        }
      };

      const chart = new ApexCharts(document.querySelector('#chart'), options);
      chart.render();
    },
    addSigns(val) {
      return addSign(this.kpi, val);
    },
    async fetchKpiData() {
      try {
        const res = await this.fetchOverviewData();
        this.kpiData = res.data;
        if (this.kpiData) {
          this.renderChart();
          this.loading = false;
        }
      } catch (error) {
        console.error('Error fetching KPI data:', error);
      }
    },
    getComparedDates(selectedPeriod, compOption) {
      const comparedOption = compOption; //this.dateOptionsComparedModel.value;
      let comparedPeriod = {};

      if (comparedOption === 'PREVIOUS_PERIOD') {
        comparedPeriod = {};

        const diffDays = differenceInDays(
          selectedPeriod.start,
          selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        comparedPeriod.start = addWeeks(selectedPeriod.start, -weeks);
        comparedPeriod.end = addWeeks(selectedPeriod.end, -weeks);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        comparedPeriod = {};
        comparedPeriod.start = addYears(new Date(), -1);
        comparedPeriod.end = addYears(new Date(), -1);
        const diffDays = differenceInDays(
          selectedPeriod.start,
          selectedPeriod.end
        );
        comparedPeriod.start = addYears(selectedPeriod.start, -1);
        comparedPeriod.end = addYears(selectedPeriod.end, -1);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
        comparedPeriod = {};
        comparedPeriod.start = addWeeks(new Date(selectedPeriod.start), -52);
        comparedPeriod.end = addWeeks(new Date(selectedPeriod.end), -52);
      }
      return comparedPeriod;
    },
    getValueByIdAndKey(data, id, key) {
      const item = data.find(obj => obj._id === id);
      // If the item is found, return the value of the specified key
      if (item) {
        return item[key];
      } else {
        return 0; // or you can return a default value or throw an error
      }
    },

    async fetchOverviewData() {
      // var response = await APIGetTriggeredMessages({});
      var resp = this.barChartData; //response.data.response.kpis.data;
      if (resp.length == 0) {
        console.log('no record found');
        return;
      }

      let today = {};
      today.start = new Date();
      today.end = new Date();
      this.yesterdayDate = format(startOfYesterday(), 'yyyyMMdd');
      let yesterdayDates1 = {};
      const yesterday1 = startOfYesterday();
      yesterdayDates1.start = yesterday1;
      yesterdayDates1.end = yesterday1;

      const previousPeriodDates = this.getComparedDates(
        yesterdayDates1,
        'PREVIOUS_PERIOD'
      );
      const previousYearDates = this.getComparedDates(
        yesterdayDates1,
        'PREVIOUS_YEAR_SAME_DAY_OF_WEEK'
      );
      //previousYearDate,previousDate,yesterdayDate
      let lang = this.$store.state.user.locale;
      this.datesObj.previousYearDate = moment(previousYearDates.start)
        .locale(lang)
        .format('ll');
      this.datesObj.previousDate = moment(previousPeriodDates.start)
        .locale(lang)
        .format('ll'); //format(previousPeriodDates.start, 'yyyy-MM-dd')
      this.datesObj.yesterdayDate = moment(startOfYesterday())
        .locale(lang)
        .format('ll'); //format(startOfYesterday(), 'yyyy-MM-dd')

      this.previousDate = format(previousPeriodDates.start, 'yyyyMMdd');
      this.previousYearDate = format(previousYearDates.start, 'yyyyMMdd');

      const result = resp.reduce((acc, item) => {
        const idPrefix = item._id.split('_')[0];

        // Check if we already have an entry for this prefix
        if (!acc[idPrefix]) {
          acc[idPrefix] = { _id: idPrefix }; // Initialize with the new _id
          // Initialize all other keys with 0
          for (const key in item) {
            if (key !== '_id') {
              acc[idPrefix][key] = item[key];
            }
          }
        } else {
          // Sum the keys
          for (const key in item) {
            if (key !== '_id') {
              acc[idPrefix][key] += item[key];
            }
          }
        }

        return acc;
      }, {});

      // Convert the result back to an array
      const resultArray = Object.values(result);

      let yes = this.getValueByIdAndKey(
        resultArray,
        this.yesterdayDate,
        this.kpi
      );
      let pre = this.getValueByIdAndKey(
        resultArray,
        this.previousDate,
        this.kpi
      );
      let preYear = this.getValueByIdAndKey(
        resultArray,
        this.previousYearDate,
        this.kpi
      );
      let avg = (yes + pre + preYear) / 3;

      let data = {
        yesterday: Number(yes ? yes.toFixed(2) : 0),
        previousPeriod: Number(pre ? pre.toFixed(2) : 0),
        previousYear: Number(preYear ? preYear.toFixed(2) : 0),
        average: Number(avg ? avg.toFixed(2) : 0)
      };

      return {
        data
      };
    }
  }
};
