
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { addWeeks, startOfWeek } from "date-fns";
import { TRI_TONES } from "../../../util/colors";
import { weatherObj } from "@/util/weatherIcons";
import moment from "moment";
import weather from "../../../../public/weather.js";

export default {
  data() {
    return {
      weather: [],
      weatherCompare: [],
      loading: false,
      series: [],
      formattedSelected: [],
      formattedCompared: [],
      formattedTarget: [],
      selectedIcon: [],
      selectedIcon2: [],
      weatherData: [],
      temperatures: [],
      weatherData2: [],
      temperatures2: [],
      categories: [],
      chartOptions: {
        chart: {
          height: 230,
          type: "line",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: '<i class="fas fa-download"></i>',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: TRI_TONES,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          show: true,
          position: "top",
          markers: {
            radius: 0,
            width: 16,
            height: 6,
          },
        },
        yaxis: {
          type: "number",
          forceNiceScale: true,
          tickAmount: 4,
        },
        xaxis: {
          categories: [],
          tooltip: { enabled: false },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `<q-card class='q-pa-md box-trend-tooltip daily-sales-trend tooltip-container'>
                         <div class='flex items-center justify-between'>
                            <div>
                      ${
                        this.getPulseStartingPeriod === this.getPulseEndingPeriod
                          ? this.getPulseStartingPeriod +
                            "  " +
                            (w.config.series[0].data[dataPointIndex]
                              ? w.config.series[0].data[dataPointIndex].x
                              : "")
                          : w.config.series[0].data[dataPointIndex]
                          ? Array.isArray(w.config.series[0].data[dataPointIndex].x)
                            ? w.config.series[0].data[dataPointIndex].z.toString()
                                .split("_")[0]
                                .substring(0, 18)
                            : ""
                          : ""
                      }</div>
                      <div class='flex'>
                       <div class='flex items-center weather-tooltip'>
                                  <div class='q-ml-xs'>
                                 ${
                        this.getPulseStartingPeriod === this.getPulseEndingPeriod
                          ? this.getPulseStartingPeriod +
                            "  " +
                            (w.config.series[0].data[dataPointIndex]
                              ? w.config.series[0].data[dataPointIndex].x
                              : "")
                          : w.config.series[0].data[dataPointIndex]
                          ? Array.isArray(w.config.series[0].data[dataPointIndex].x)
                            ? w.config.series[0].data[dataPointIndex].z.toString()
                                .split("_")[1]
                                .replaceAll(",", " ")
                            : ""
                          : ""
                      }
                                  </div></div>
  
                          </div>
                      </div>
  
                  <div class='tooltip-title flex items-center q-mx-xs'>
                    <div class='tooltip-title-color' style='background:${
                      w.config.colors[0]
                    }'></div>
                    <label>${this.$t("selected_period")} :</label>
                      <div class='q-ml-xs text-bold'>
                    ${this.formattedSelected[dataPointIndex] ?? "-"}
                    </div>
                  </div>
                   <div class='tooltip-title q-mx-xs q-mb-md flex items-center'>
                  <div class='tooltip-title-color' style='background:${
                    w.config.colors[2]
                  }'></div>
                  <br/>
                  <label> ${this.$t("sale_target")} :</label>
                    <div class='q-ml-xs text-bold'> ${
                      this.formattedTarget[dataPointIndex] ?? "-"
                    }</div>
                    </div>
                      <div class='flex items-center justify-between'>
                        <div>
                          ${
                            this.getPulseStartingPeriod === this.getPulseEndingPeriod
                              ? this.getPulseStartingPeriod +
                                "  " +
                                (w.config.series[1].data[dataPointIndex]
                                  ? w.config.series[1].data[dataPointIndex].y
                                  : "")
                              : w.config.series[1].data[dataPointIndex]
                              ? Array.isArray(w.config.series[1].data[dataPointIndex].x)
                                ? w.config.series[1].data[dataPointIndex].z.toString()
                                .split("_")[0]
                                .substring(0, 18)
                            : ""
                          : ""
                          }
                        </div>
                        <div class='flex'>
                          <div class='flex items-center weather-tooltip'>
                              <div class='q-ml-xs'>
                                ${
                            this.getPulseStartingPeriod === this.getPulseEndingPeriod
                              ? this.getPulseStartingPeriod +
                                "  " +
                                (w.config.series[1].data[dataPointIndex]
                                  ? w.config.series[1].data[dataPointIndex].y
                                  : "")
                              : w.config.series[1].data[dataPointIndex]
                              ? Array.isArray(w.config.series[1].data[dataPointIndex].x)
                                ? w.config.series[1].data[dataPointIndex].z.toString()
                                .split("_")[1]
                                .replaceAll(",", " ")
                            : ""
                          : ""
                          }</div></div>
                      </div>
                    </div>
                  <div class='tooltip-title q-mx-xs flex items-center'>
                  <div class='tooltip-title-color' style='background:${
                    w.config.colors[1]
                  }'></div>
                  <label> ${this.$t("compared_period")} :</label>
                    <div class='q-ml-xs text-bold'> ${
                      this.formattedCompared[dataPointIndex]
                        ? this.formattedCompared[dataPointIndex]
                        : "-"
                    }</div>
                    </div>
                  </q-card>`;
          },
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("filter", [
      "getStoreCodesList",
      "getDaysOfWeekList",
      "getPulseStartingPeriod",
      "getPulseEndingPeriod",
      "getPulseStartingCompared",
      "getPulseEndingCompared",
      "getCustomCalenderState",
    ]),
    ...mapState("periodicReport", [
      "selectedStoreCode",
      "salesTrendData",
      "hourlyDate",
      "selectedStore",
    ]),
  },
  watch: {
    async selectedStoreCode() {
      await this.loadData();
    },
   weather: {
      handler(newValue, oldValue) {
        this.addWeatherHander();
      },
    },
     weatherCompare: {
      handler(newValue, oldValue) {
        this.addWeatherHander();
      },
    },
  },
  async created() {
    if (this.selectedStoreCode !== "") {
      await this.loadData();
      
    }
  },
  methods: {
    ...mapActions("periodicReport", ["getSalesTrendWithBudget"]),
    ...mapMutations("periodicReport", ["setWeeklyLoading"]),
    async overAllWeather() {
      var startDate = moment(this.hourlyDate.selectedPeriod.start);
      var endDate = moment(this.hourlyDate.selectedPeriod.end);
      var startDateComp = moment(this.hourlyDate.comparedPeriod.start);
      var endDateComp = moment(this.hourlyDate.comparedPeriod.end);
      const res = await weather(startDate, endDate, [
        { cityId: this.selectedStore.cityId },
      ]);
      this.weather = res?.weather;
      const res2 = await weather(startDateComp, endDateComp, [
        { cityId: this.selectedStore.cityId },
      ]);
      this.weatherCompare = res2?.weather;
    },
    async loadData() {
      try {
        this.loading = true;
        this.setWeeklyLoading({ component: "dailySalesTrend", value: true });
        let params = {
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseEndingPeriod,
          compareStartDate: this.getPulseStartingCompared,
          compareEndDate: this.getPulseEndingCompared,
          storeCodes: this.selectedStoreCode,
        };
        await this.getSalesTrendWithBudget({
          ...params,
        });
        this.overAllWeather();
        this.addWeatherHander();
      } catch (error) {
        // this.$q.notify({
        //   type: 'negative',
        //   position: 'top',
        //   message: error
        // });
      }
      this.loading = false;
      this.chartOptions = {
          ...this.chartOptions,
      };
      this.chartOptions.chart.toolbar = {
             export: {
              svg: {
                filename: `${this.$t('DailyReport.DailySalesTrend')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              },
              png: {
                filename: `${this.$t('DailyReport.DailySalesTrend')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              },
              csv: {
                filename: `${this.$t('DailyReport.DailySalesTrend')} ${this.$t(
                  'KpiAnalysis.selected'
                )}꞉ ${this.getPulseStartingPeriod}˷${this.getPulseEndingPeriod}  ${this.$t(
                  'KpiAnalysis.compared'
                )}꞉ ${this.getPulseStartingCompared}˷${this.getPulseEndingCompared}`
              }
            }
           
          };
      this.setWeeklyLoading({ component: "dailySalesTrend", value: false });
    },
    addWeatherHander() {
       let lang = this.$store.state.user.locale;
      const data = [
        {
          name: this.$t("selected_period"),
          data: [],
        },
        {
          name: this.$t("compared_period"),
          data: [],
        },
        {
          name: this.$t("sale_target"),
          data: [],
        },
      ];

      // this.weatherData = this.salesTrendData.selectedPeriod.map(
      //   v => v.weather
      // );
      this.weatherData2 = this.salesTrendData.comparedPeriod.map((v) => v.weather);
      this.weatherData2 = [];
      this.selectedIcon2 = [];
      this.temperatures2 = [];
      this.formattedCompared = [];
      this.categories = [];
      this.salesTrendData.comparedPeriod.forEach((date, index) => {
        this.formattedCompared = [...this.formattedCompared, date.formattedValue];
        var findObj = this.weatherCompare?.find((v) => v.hasOwnProperty(date.timeLabel));
        this.weatherData2 = [
          ...this.weatherData2,
          findObj && findObj[date.timeLabel]?.icon,
        ];
        this.selectedIcon2 = [
          ...this.selectedIcon2,
          findObj && findObj[date.timeLabel]?.icon,
        ];
        this.temperatures2 = [
          ...this.temperatures2,
          findObj && findObj[date.timeLabel]?.temp,
        ];
        let label = this.getLabel(
          date.timeLabel,
          findObj && findObj[date.timeLabel]?.icon,
          findObj && findObj[date.timeLabel]?.tempMin?.toFixed(2),
          findObj && findObj[date.timeLabel]?.tempMax?.toFixed(2)
        );
        let label2 = this.getLabelFr(
          date.timeLabel,
          findObj && findObj[date.timeLabel]?.icon,
          findObj && findObj[date.timeLabel]?.tempMin?.toFixed(2),
          findObj && findObj[date.timeLabel]?.tempMax?.toFixed(2)
        );
        data[1].data.push({
          x: label,
          y: date.value.toFixed(2) ? date.value.toFixed(2) : 0,
          z: label2
        });
        this.categories = [...this.categories, label];
      });
      this.weatherData = [];
      this.selectedIcon = [];
      this.temperatures = [];
      this.formattedSelected = [];
      this.salesTrendData.selectedPeriod.forEach((date, index) => {
        this.formattedSelected = [...this.formattedSelected, date.formattedValue];
        var findObj = this.weather?.find((v) => v.hasOwnProperty(date.timeLabel));
        this.weatherData = [
          ...this.weatherData,
          findObj && findObj[date.timeLabel]?.icon,
        ];
        this.selectedIcon = [
          ...this.selectedIcon,
          findObj && findObj[date.timeLabel]?.icon,
        ];
        this.temperatures = [
          ...this.temperatures,
          findObj && findObj[date.timeLabel]?.temp,
        ];
        let label = this.getLabel(
          date.timeLabel,
          findObj && findObj[date.timeLabel]?.icon,
          findObj && findObj[date.timeLabel]?.tempMin?.toFixed(2),
          findObj && findObj[date.timeLabel]?.tempMax?.toFixed(2)
        );
         let label2 = this.getLabelFr(
           date.timeLabel,
          findObj && findObj[date.timeLabel]?.icon,
          findObj && findObj[date.timeLabel]?.tempMin?.toFixed(2),
          findObj && findObj[date.timeLabel]?.tempMax?.toFixed(2)
        );
        
        data[0].data.push({
          x: label.concat(this.categories[index]),
          y: date.value.toFixed(2),
          z: label2
        });
      });

      this.salesTrendData.target.forEach((date, index) => {
        this.formattedTarget = [...this.formattedTarget, date.formattedValue];
        let label =
          this.getPulseStartingPeriod === this.getPulseEndingPeriod
            ? date.timeLabel + ":00"
            : date.timeLabel + `(${this.getFullDay(date.timeLabel)})`;
        data[2].data.push({
          x: label,
          y: date.value.toFixed(2) ? date.value.toFixed(2) : 0,
          z: this.getPulseStartingPeriod === this.getPulseEndingPeriod
            ? date.timeLabel + ":00"
            : moment(date.timeLabel).locale(lang).format('ll') + ` (${this.getFullDay(date.timeLabel)})`
        });
      });

      this.series = data;
    },
    getLabel(val, weather, min, max) {
      const formattedDate = moment(val).format("MMM D");
      return [
        `${weather ? weatherObj[weather] : "-"} ${max ? max + "°C" : "-"}`,
        `${min ? min + "°C" : "-"}`,
        formattedDate,
        "",
      ];
    },
    getLabelFr(val, weather, min, max) {
      let lang = this.$store.state.user.locale;
      const formattedDate = moment(val).locale(lang).format('ll') + ` (${this.getFullDay(val)})`
      return [
        formattedDate,
        `_${weather ? weatherObj[weather] : "-"} ${max ? max + "°C" : "-"}`,
        `${min ? min + "°C" : "-"}`,
        "",
      ];
    },
    getUnits() {
      if (val === this.$t("sales")) {
        return "円";
      }
      if (val === this.$t("traffic")) {
        return "人";
      }
      if (val === this.$t("transactions")) {
        return "人";
      }
      if (val === this.$t("conversion")) {
        return "%";
      }
      if (val === this.$t("averagesale")) {
        return "円";
      }
      if (val === this.$t("averagepurchase")) {
        return "円";
      }
      if (val === this.$t("upt")) {
        return "点";
      }
      if (val === this.$t("rov")) {
        return "円";
      }
      if (val === this.$t("averageitemprice")) {
        return "円";
      }
      if (val === this.$t("salespersqm")) {
        return "円";
      }
      if (val === this.$t("trafficpersqm")) {
        return "人";
      }
      if (val === this.$t("stafflevel")) {
        return "人";
      }
      if (val === this.$t("staffhours")) {
        return "人";
      }
      if (val === this.$t("closingstrength")) {
        return "人";
      }
      if (val === this.$t("staffefficiency")) {
        return "円";
      }
      if (val === this.$t("passersby")) {
        return "人";
      }
      if (val === this.$t("capturerate")) {
        return "%";
      }
      // if (val === this.$t('facadestops')) {
      //   return '人';
      // }
      if (val === this.$t("storedwelltime")) {
        return "x 時間 y分 z秒";
      }
      if (val === this.$t("repeatrate")) {
        return "-";
      }
      if (val === this.$t("engagementrate")) {
        return "％";
      }
      if (val === this.$t("bouncerate")) {
        return "％";
      }
      if (val === this.$t("gendercomparison")) {
        return "％";
      }
      if (val === this.$t("age")) {
        return "％";
      }
      if (val === this.$t("fittingroomvisits")) {
        return "人";
      }
      if (val === this.$t("fittingroom %")) {
        return "％";
      }
      if (val === this.$t("fittingroomconversion %")) {
        return "％";
      }
      if (val === this.$t("fittingroomdwelltime")) {
        return "x 時間 y分 z秒";
      }
      if (val === this.$t("areapassersby")) {
        return "人";
      }
      if (val === this.$t("areastops")) {
        return "人";
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t("Sun");
      }
      if (dayVal === 1) {
        return this.$t("Mon");
      }
      if (dayVal === 2) {
        return this.$t("Tue");
      }
      if (dayVal === 3) {
        return this.$t("Wed");
      }
      if (dayVal === 4) {
        return this.$t("Thu");
      }
      if (dayVal === 5) {
        return this.$t("Fri");
      }
      if (dayVal === 6) {
        return this.$t("Sat");
      }
    },
  },
};
