
export default {
  props: {
    noCenter: { type: Boolean, default: false }
  },
  computed: {
    cardStyle() {
      const { $style, noCenter } = this;
      return {
        [$style.container]: true,
        [$style.center]: !noCenter
      };
    }
  }
};
