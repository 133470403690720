
import { addMonths, startOfWeek, startOfMonth } from 'date-fns';
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      dateValue: {
        title: this.$t('title'),
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        compareStartDate: this.getPulseStartingCompared,
        compareEndDate: this.getPulseEndingCompared,
        daysOfWeek: 'monday,tuesday,wednesday,thursday,friday,saturday,sunday'
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState',
      'getPulseEndingCompared'
    ])
  },
  methods: {
    ...mapMutations('periodicReport', ['setMonthlyLoading']),
    changeLoading(val) {
      this.setMonthlyLoading({ component: 'salesTrend', value: val });
    }
  }
};
