import PlanOrderController from '@/services/transactional/PlanOrderController';
import Store from '@/models/Store';
import Plan from '@/models/Plan';
import TenantController from '@/services/account/TenantController';
import { PaymentConfirmation } from '@/models/RequestOrder';

export default class PlanOrderRepository {
  private planOrderController: PlanOrderController;
  private tenantController: TenantController;

  constructor(
    planOrderController: PlanOrderController,
    tenantController: TenantController
  ) {
    this.planOrderController = planOrderController;
    this.tenantController = tenantController;
  }

  public async getPlanForStore(store: Store) {
    return await this.planOrderController.getPlanForStore(store.id);
  }

  public async getPlanOrderQuote(tenantName: string, store: Store, plan: Plan) {
    const tenant = await this.tenantController.getTenant(tenantName);
    return await this.planOrderController.getPlanOrderCalculation({
      tenantId: tenant.id,
      planId: plan.id,
      storeId: store.id
    });
  }

  public async createOrder(
    tenantName: string,
    plan: Plan,
    store: Store,
    confirmation: PaymentConfirmation
  ) {
    const tenant = await this.tenantController.getTenant(tenantName);
    return await this.planOrderController.createOrder({
      planId: plan.id,
      tenantId: tenant.id,
      storeId: store.id,
      paymentMethod: confirmation
    });
  }

  public async cancelPlan(tenantName: string, store: Store) {
    const tenant = await this.tenantController.getTenant(tenantName);
    return await this.planOrderController.cancelPlan({
      tenantId: tenant.id,
      storeId: store.id
    });
  }
}
