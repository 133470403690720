import HttpController from '../HttpController';
import { PaginatedResponse } from '@/models/Pagination';
import { StoreType } from '@/models/Store';

export type GetStoreTypesResponse = PaginatedResponse<StoreType>;

export default class StoreTypeController extends HttpController {
  public async getStoreTypes() {
    const { data } = await this.client.get('/store-type', {
      params: {
        offset: 0,
        pageNumber: 1,
        pageSize: 10,
        paged: false
      }
    });
    return data as GetStoreTypesResponse;
  }
}
