import { Currency, CurrencyDTO } from '../Currency';

export const convertFromCurrencyDTO = (dto: CurrencyDTO): Currency => dto;
export const convertToCurrencyDTO = (currency: Currency): CurrencyDTO =>
  currency;

/**
 * Currency Formatter
 *
 * Contains function to obtain currency symbol for popular
 * subset of currencies.
 *
 * Currency code and minor unit data on this list is based
 * on the ISO 4217 (Currency Codes) entry.
 * Locale data is based on IETF BCP 47 language tag.
 */

interface CurrencyData {
  symbol: string;
  minorUnit: number;
  locale?: string;
}

const currencyData: { [key: string]: CurrencyData } = {
  JPY: { symbol: '¥', minorUnit: 0, locale: 'ja-JP' },
  //  Since Euro is used across multiple countries in the Eurozone,
  //  'en' locale is used as fallback
  EUR: { symbol: '€', minorUnit: 2, locale: 'en' },
  USD: { symbol: '$', minorUnit: 2, locale: 'en-US' },
  AUD: { symbol: '$', minorUnit: 2, locale: 'en-AU' },
  NZD: { symbol: '$', minorUnit: 2, locale: 'en-NZ' },
  SGD: { symbol: '$', minorUnit: 2, locale: 'en-SG' },
  CAD: { symbol: '$', minorUnit: 2, locale: 'en-CA' },
  IDR: { symbol: 'Rp', minorUnit: 2, locale: 'id-ID' },
  GBP: { symbol: 'Rp', minorUnit: 2, locale: 'en-GB' },
  KRW: { symbol: '₩', minorUnit: 0, locale: 'ko-KR' }
};

export function getCurrencySymbol(currencyCode: string) {
  if (!Object.keys(currencyData).includes(currencyCode)) {
    return currencyCode;
  }
  return currencyData[currencyCode].symbol;
}

export function formatCurrency(
  value: number,
  currencyCode: string,
  locale?: string
) {
  let effectiveLocale = locale;
  if (
    effectiveLocale != null &&
    Object.keys(currencyData).includes(currencyCode)
  ) {
    effectiveLocale = currencyData[currencyCode].locale;
  }
  if (
    typeof Intl === 'object' &&
    Intl.NumberFormat &&
    typeof Intl.NumberFormat === 'function'
  ) {
    const formatted = Intl.NumberFormat(effectiveLocale || 'en').formatToParts(
      value
    );
    return formatted
      .filter(part => part.type !== 'currency')
      .map(part => part.value)
      .join('');
  } else {
    return value.toLocaleString();
  }
}

export function formatCurrencyWithSymbol(
  value: number,
  currencyCode: string,
  locale?: string
) {
  const symbol = getCurrencySymbol(currencyCode);
  const formatted = formatCurrency(value, currencyCode, locale);
  return `${symbol} ${formatted}`;
}
