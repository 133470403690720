import { PaginatedResponse } from '@/models/Pagination';
import { ProductDTO } from '@/models/Product';
import { createBackendError } from '@/util/error';
import HttpController from '../HttpController';

export type GetProductsResponse = PaginatedResponse<ProductDTO>;

const basePath = '/console/item';

export default class ProductController extends HttpController {
  public async getProducts() {
    try {
      const { data } = await this.client.get(basePath, {
        params: {
          offset: 0,
          pageNumber: 1,
          pageSize: 10,
          paged: false
        }
      });
      return data as GetProductsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getProduct(id: string) {
    try {
      const { data } = await this.client.get(`${basePath}/${id}`);
      return data as ProductDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createProduct(product: ProductDTO) {
    try {
      const { data } = await this.client.post(basePath, product);
      return data as ProductDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateProduct(id: string, product: ProductDTO) {
    try {
      const { data } = await this.client.put(`${basePath}/${id}`, product);
      return data as ProductDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getProductTypes() {
    try {
      const { data } = await this.client.get(basePath + '/types');
      return data as string[];
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async uploadProductImage(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await this.client.post(
        basePath + '/upload-image',
        formData
      );
      return data as string;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async downloadProductImage(path: string) {
    try {
      const { data } = await this.client.get(basePath + '/photo/base64', {
        params: { path }
      });
      return data as string;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
