import Chance from 'chance';
// import fs from 'fs';
// const { faker } = require('@faker-js/faker');

export function getMissingDates(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const missingDates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        missingDates.push(`${year}-${month}-${day}`);

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return missingDates;
}

let baseKeys =  ['out',  'trf',  'sls',  'tra',  'pb',   'dw',   'trn',  'is',   'sh',   'cn',   'cr',   'ap',   'aip',  'spt',  'tpt',  'upt',  'rov',  'sl',    'cs',  'se',    'tar', 'st'];

let settingsObj = {
    "trf": {
        "min": 0,
        "max": 50,
    },
    // "in": {
    //     "min": 0,
    //     "max": 10
    // },
    "out": {
        "min": 0,
        "max": 10
    },
    "sls": {
        "min": 0,
        "max": 45000
    },
    "tra": {
        "min": 0,
        "max": 10
    },
    "pb": {
        "min": 0,
        "max": 1000
    },
    "dw": {
        "min": 0,
        "max": 15
    },
    "trn": {
        "min": 0,
        "max": 6
    },
    "is": {
        "min": 0,
        "max": 5
    },
    "sh": {
        "min": 0,
        "max": 2
    },
    "cn": {
        "min": 0,
        "max": 10
    },
    "cr": {
        "min": 0,
        "max": 8
    },
    "ap": {
        "min": 0,
        "max": 800
    },
    "aip": {
        "min": 0,
        "max": 2000
    },
    "spt": {
        "min": 0,
        "max": 250
    },
    "tpt": {
        "min": 0,
        "max": 250
    },
    "upt": {
        "min": 0,
        "max": 20
    },
    "rov": {
        "min": 0,
        "max": 140
    },
    "sl": {
        "min": 0,
        "max": 10
    },
    "cs": {
        "min": 0,
        "max": 5
    },
    "se": {
        "min": 0,
        "max": 7
    },
    "tar": {
        "min": 0,
        "max": 200
    },
    "st": {
        "min": 0,
        "max": 50000
    }
};
let up = 1.45;
let dn = 0.15;

let variation = {
    "sls": up,
    "trn": up,
    "cn": up,
    "trf": dn,
}

let hourlyMul = [
    0.6,
    1,
    1.05,
    2,
    2.25,
    2.15,
    1.75,
    1,
    0.2,
    0.1
]

let pS = [
    "33393", "33082", "33292"
]

let lS = [
    "33149", "33316", "32261", "33373", "33211", "33246"
]

let boostedKyes = ["sls", "trn", "cn"];
let bodgedKeys = ["trf"]
let weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
function getDailyData(date, stH, hC, storeCodes, days) {
    days = days || weekDays;
    let isHoliday = new Date(date).getDay();
    let dayText = weekDays[isHoliday];
    let isZeroDay = days.indexOf(dayText) == -1;
    isHoliday = isHoliday == 6 || isHoliday == 0;
    stH = Number(stH)
    let dailyResp = []
    let hourlyResp = []
    storeCodes.split(',').forEach((sc) => {
        let trf_t = 0, in_t =0, out_t =0;
        let dailySD = { "_id": `${date.split('-').join('')}_${sc}`, "demographics": {} }
        baseKeys.forEach((k, i) => {
            dailySD[k] = 0;
        })

        let max_multiplier = 0;
        String(sc).split("").forEach((c) => {
            max_multiplier = c.charCodeAt()
        })
        max_multiplier = (max_multiplier%5);
        if(!max_multiplier) {
            max_multiplier = 0.5;
        }

        const seed = (date+sc).split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        let rK = (seed * sc)%baseKeys.length;
        const chance = new Chance(seed+rK);

        let trf_val = chance.integer({min: settingsObj["trf"].min, max: settingsObj["trf"].max * 7}) * max_multiplier * (isHoliday ? 1.45 : 1);
        let rK2 = 0;
        let i = 0;

        let rk3 = chance.integer({min: 1, max: 3})

        for (i = 0; i < hC; i++) {

            let dData = { "_id": `${(stH + i).toString()}_${sc}` }
            dData.date = date.split("-").join("");
            baseKeys.forEach((k) => {
                let jump = chance.integer({min: 0, max: 11});
                for(let k =0; k<jump; k++) { chance.integer(); }
                let m_shuffle = chance.integer({min: 1, max:5}) * max_multiplier * (isHoliday ? 1.45 : 1);

                // console.log(i, hourlyMul[i])
                let max = settingsObj[k].max * m_shuffle;
                let min = settingsObj[k].min;
                if((k == "sls" || k == "trn") && pS.indexOf(sc) > -1) {
                    max *= 3;
                    min *= 3;
                }
                if((k == "sls" || k == "trn") && lS.indexOf(sc) > -1) {
                    max /= 3;
                    min /= 3;
                }

                if(k == "out") {
                    max = trf_t - out_t;
                    min = (trf_t - out_t) * 0.75
                } 
                // console.log(Math.floor((trf_val/7)*hourlyMul[i]), hourlyMul[i])
                dData[k] = isZeroDay ? 0 : k == "trf" ? Math.floor((trf_val/7)*hourlyMul[i]) : chance.integer({ min, max });
                dailySD[k] += dData[k];
                if(k == "trf") {
                    trf_t += dData[k]
                    dData["in"] = dData[k]
                    dailySD["in"] = dailySD[k];
                } else if(k == "out") {
                    out_t += dData[k]
                }
            })

            dData["demographics"] = {};
            for (let i = 1; i < 9; i++) {
                dData["demographics"]["ageGroup_" + i] = isZeroDay ? 0 : chance.integer({ min: 0, max: 30 * (isHoliday ? 1.45 : 1) });
                dailySD["demographics"]["ageGroup_" + i] = (dailySD["demographics"]["ageGroup_" + i] || 0) + dData["demographics"]["ageGroup_" + i];
            }

            if(i+1 == hC) {
                dData["out"] += trf_t - out_t;
                dailySD["out"] += trf_t - out_t;
            }
            hourlyResp.push(dData)
        }
        let diff = trf_t - out_t;
        // console.log(diff, trf_t, out_t, stH+i, dailySD)

        dailyResp.push(dailySD)
    })


    return { hourlyResp, dailyResp }
}

export function genRawData({dateS, dateE, storeCodes, frequency, resp, days}) {
    // const storeCodes = '009,007';
    // const dateS = '2024-01-05';
    // const dateE = '2024-01-06';
    const hour = '10';
    const hourC = 10;
    let retVal = []
    if (dateS === dateE || frequency === 'hourly') {
        if(resp == "daily") {
            retVal = getDailyData(dateS, hour, hourC, storeCodes, days).dailyResp
        } else if(resp == "hourly") {
            retVal = getDailyData(dateS, hour, hourC, storeCodes, days).hourlyResp
        } else {
            retVal = getDailyData(dateS, hour, hourC, storeCodes, days).hourlyResp
        }
    } else {
        let dates = getMissingDates(dateS, dateE)
        dates.forEach((d) => {
            let cV = getDailyData(d, hour, hourC, storeCodes, days);
            if(resp == "daily") {
                retVal = retVal.concat(cV.dailyResp)
            } else if(resp == "hourly") {
                retVal = retVal.concat(cV.hourlyResp)
            } else {
                retVal = retVal.concat(cV.dailyResp)
            }
        })
    }
    return retVal
}

export function trfIn(fakeData) {
  if (!(fakeData && fakeData.length)) return
  let dataPlane = fakeData?.map(el => {
    let idSplit = el._id.split('_');
    const seed = (idSplit[1]).split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const chance = new Chance(seed);

    let ranEntrnce = ['001', '002', '003']
    let ranNum = chance.integer({min: 0, max: 2})
    let hour = idSplit[0];
    let { tenant } = JSON.parse(localStorage.getItem("vuex"))?.['user']?.['profile'];

    return {
      _id: `${el.date}_${hour}_${tenant._id}_${idSplit[1]}`,
      trf: {
        [ranEntrnce[ranNum]]: {
          trf: el.trf,
          in: el.in,
          out: el.out
        }
      }
    };
  });
  // console.log(dataPlane, "dataPlane")
  return dataPlane
}


// const storeCodes = '009,007';
// const dateS = '2024-01-05';
// const dateE = '2024-01-06';
// const hour = '10';
// const hourC = 11;
// console.time("genFake")
// const fakeData = genRawData()
// console.timeEnd("genFake")
// console.log(fakeData)

// fs.writeFile('./fakegen/output.json', JSON.stringify(fakeData), 'utf8', (err, res) => {
//   console.log(err, res)
// });