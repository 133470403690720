
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      metrics: [
        {
          metricKey: this.$t('Demographics.metric') + '1',
          value: '985215',
          growthIndicator: 1,
          variance: 2
        },
        {
          metricKey: this.$t('Demographics.metric') + '2',
          value: '3025',
          growthIndicator: -1,
          variance: 2
        },
        {
          metricKey: this.$t('Demographics.metric') + '3',
          value: '65555',
          growthIndicator: -1,
          variance: 56
        },
        {
          metricKey: this.$t('Demographics.metric') + '4',
          value: '3025',
          growthIndicator: -1,
          variance: 3
        },
        {
          metricKey: this.$t('Demographics.metric') + '5',
          value: '3025',
          growthIndicator: 1,
          variance: 2
        },
        {
          metricKey: this.$t('Demographics.metric') + '6',
          value: '3025',
          growthIndicator: -1,
          variance: 2
        },
        {
          metricKey: this.$t('Demographics.metric') + '7',
          value: '96325',
          growthIndicator: -1,
          variance: 6
        },
        {
          metricKey: this.$t('Demographics.metric') + '8',
          value: '56433',
          growthIndicator: -1,
          variance: 1
        }
      ]
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ])
  },
  async created() {
    await this.loadKpiMetrics();
  },
  methods: {
    ...mapActions('demographics', ['fetchDemographicsByStore']),
    async loadKpiMetrics() {
      await this.fetchDemographicsByStore({
        // storeCodes: this.getStoreCodesList,
        // startDate: this.getStartingPeriod,
        // endDate: this.getEndingPeriod,
        // kpis: 'sales,traffic',
        // daysOfWeek: this.getDaysOfWeekList,
        // groupByMetricKey: false
        startDate: '2018-12-01',
        endDate: '2018-12-31',
        kpis: 'sales,traffic',
        groupByMetricKey: false, //optional and true by default
        daysOfWeek: 'Sunday',
        storeCodes: '33246,33261'
      });
    }
  }
};
