
import { addMonths, startOfWeek, startOfMonth } from 'date-fns';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      dateValue: {
        title: this.$t('title'),
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
        compareStartDate: addMonths(startOfMonth(new Date()), -1),
        compareEndDate: addMonths(new Date(), -1),
        daysOfWeek: 'monday,tuesday,wednesday,thursday,friday,saturday,sunday'
      }
    };
  },
  methods: {
    ...mapMutations('periodicReport', ['setMonthlyLoading']),
    changeLoading(val) {
      this.setMonthlyLoading({ component: 'trafficTrend', value: val });
    }
  }
};
