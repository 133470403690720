
import CommonMixin from '@/mixins/CommonMixin';
import { createColumn } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  props: {
    data: { type: Object, default: () => ({}) },
    successMessage: { type: String, default: 'Data imported successfully' }
  },
  data() {
    return {
      // columns: [
      //   createColumn('row',this.$t('Extension.Row')),
      //   createColumn('description',this.$t('Extension.Description'))
      // ]
    };
  },
  methods: {
    replacePlaceholders(message) {

      // Split the message by comma to get parts
      if (!message) {
          return [];
      }
      const lang = this.$store.state.user.locale
      if (!message.includes(',')) {

        if(message == "emptyFileError") {
          if(lang == 'en') {
          return ["The file you uploaded is does not contain valid data. Please upload file with required data to proceed."]
          } else {
            return ["アップロードされたファイルには有効なデータが含まれていません。続行するには、必要なデータを含むファイルをアップロードしてください。"]
          }
        }
        return [this.$store.state.user.translate[message]];
      }
      let retVal = []
      const errorsAll = message.split('\n');
      // Make sure the parts include at least three values
      errorsAll.forEach(err => {

        const parts = err.split(',');
        if(parts[0]) {

          let currError = ""
          const translationKey = parts[0];
          const translationString = this.$store.state.user.translate[translationKey];
          if(parts.length == 1) currError += translationString;

          if (parts.length == 3) {

            // Replace %@ placeholders with values from parts[1] and parts[2]
            currError += translationString ? translationString//  coloumn
              .replace('column %@', 'column '+parts[2])
              .replace('列%@', '列'+parts[2])
              .replace('%@', Number(Number(parts[1])+1)): lang === 'ja' ? parts[1]: translationKey;

          }
          if(parts.length == 2) {
            // Replace %@ placeholders with values from parts[1] and parts[2]
            currError += translationString ? translationString
              ?.replace('%@', Number(Number(parts[1])+1)): lang === 'ja' ? parts[1]: translationKey
          }
          retVal.push(currError);
        }

      });


      // If there aren't enough parts, return a default message or an error message.
      if(!retVal.length) {
        retVal.push('Invalid message format')
      }
      return retVal;
    }
  }
};
