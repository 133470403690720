var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LoginTabs',{attrs:{"tab-labels":[_vm.$t('AuthPage.create_new_account')]}}),_c('q-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitPassword.apply(null, arguments)}}},[_c('q-input',{attrs:{"filled":"","label":_vm.$t('AuthPage.password'),"placeholder":_vm.$t('AuthPage.type_password'),"rules":[
        v => !!v || _vm.$t('AuthPage.field_is_required'),
        v => !/\s/g.test(v) || _vm.$t('AuthPage.no_white_space'),
        v => /^(?=.*\d).{1,}$/.test(v) || _vm.$t('AuthPage.password_one_number'),
        v =>
          /^(?=.*[A-Z]).{1,}$/.test(v) ||
          _vm.$t('AuthPage.password_one_uppercase'),
        v =>
          /^(?=.*[a-z]).{1,}$/.test(v) ||
          _vm.$t('AuthPage.password_one_lowercase'),

        v => _vm.checkSpecialSymbols(v) || _vm.$t('AuthPage.password_special_symbol'),
        v => _vm.checkFullwidth(v) || _vm.$t('AuthPage.no_full_width_characters'),
        v => v.length > 7 || _vm.$t('AuthPage.min_length')
      ],"type":_vm.isHide ? 'text' : 'password',"no-margin":"","mandatory":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{class:_vm.isHide ? 'fas fa-eye-slash' : 'fas fa-eye',on:{"click":_vm.togglePassword}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('q-input',{attrs:{"filled":"","label":_vm.$t('AuthPage.confirm_password'),"placeholder":_vm.$t('AuthPage.type_confirm_password'),"type":_vm.isHide ? 'text' : 'password',"rules":[
        v => !!v || _vm.$t('AuthPage.field_is_required'),
        v => v === _vm.password || _vm.$t('AuthPage.password_does_not_match')
      ],"no-margin":"","mandatory":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{class:_vm.isHide ? 'fas fa-eye-slash' : 'fas fa-eye',on:{"click":_vm.togglePassword}})]},proxy:true}]),model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('div',{staticClass:"full-width q-mt-xl"},[_c('q-btn',{staticClass:"full-width",attrs:{"color":"primary","size":"lg","type":"submit","label":_vm.$t('AuthPage.create_account')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }