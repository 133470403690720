
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      selected: {},
      recommended: [],
      integrations: []
    };
  },
  computed: {
    ...mapState('marketplace', [
      'partnersRecommended',
      'partners',
      'pricing',
      'selectedTag'
    ]),
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    pricing() {
      // this.hardwares= this.iot.map(v=>)
      if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() === 'free'
      ) {
        this.integrations = this.thirdParty.filter(v => {
          return v.rate.toLowerCase() === 'free';
        });
      } else if (
        this.pricing.length === 1 &&
        this.pricing[0].toLowerCase() !== 'free'
      ) {
        this.integrations = this.thirdParty.filter(
          v => v.rate.toLowerCase() !== 'free'
        );
      } else {
        this.integrations = this.thirdParty;
      }
    },
    selectedTag() {
      this.integrations = this.thirdParty.filter(v =>
        [v.label.toLowerCase(), ...v.tags].includes(this.selectedTag)
      );
    }
  },
  created() {
    this.fetchPartnersRecommended();
    this.fetchPartners();
    this.integrations = this.partners;
    this.recommended = this.partnersRecommended;
  },
  methods: {
    ...mapActions('marketplace', ['fetchPartners', 'fetchPartnersRecommended']),
    openDialog(int) {
      this.selected = int;
      this.show = true;
    },
    onCardClick(integration) {
      gtag('event', 'Open-Details', {
        event_action: 'Partner Box Click',
        event_category: 'Partner Box',
        event_label: integration.name,
        value: 1
      });
      this.$router.push('/partners/' + integration.id);
    },
    getImgUrl(pic) {
      return require('../../../assets/img/icons/' + pic + '.png');
    }
  }
};
