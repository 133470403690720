
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { kpiMapping } from '../../util/mockData/kpiMapping';
import { kpiList } from '../../components/PeriodicReport/SelectKPI/kpiList';
import { mixins } from 'vue-class-component';
import PaginationMixin from '@/mixins/PaginationMixin';
export default {
  mixins: [PaginationMixin],
  data() {
    return {
      selectedKPIOptionList: [],
      loading: false,
      columns: [],
      data: [],
      formattedTableData: [],
      parameter: '',
      customSort: [
        'Date',
        'Weather',
        'Sales',
        'Avg Item Price',
        'UPT',
        'Avg Purchase',
        'Conversion',
        'Transactions',
        'Traffic',
        'Passers By',
        'Sales Per Tsubo',
        'Traffic Per Tsubo',
        'Capture Rate',
        'Store Dwell Time',
        'Engagement Rate',
        'Bounce Rate',
        // 'Facade Stops',
        'Staff Level',
        'Staff Hours',
        'Closing Strength',
        'Staff Efficiency',
        'ROV',
        'Max Occupancy',
        'Min Occupancy',
        'Avg Occupancy',
        'Avg Occupancy %',
        'Number of Alerts',
        'Number of Requests',
        'Request Taken Per Alert',
        'Time Before Taking Request',
        'Traffic In Line',
        'Avg Users In Line',
        'Avg Waiting Time'
      ]
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'tabluarDataKpiOptions'
    ]),
    ...mapState('filter', ['tabluarDataKpiOptions']),
    ...mapState('distribution', ['selectedKPIOptions']),
    ...mapState('distribution', ['byDate'])
  },
  watch: {
    selectedKPIOptionList: {
      handler: 'loadData',
      immediate: true
    },
    tabluarDataKpiOptions(newValue) {
      this.selectedKPIOptionList = newValue;
    }
  },
  created() {
    this.getLocalStorageTabularKPI();

    this.selectedKPIOptionList = this.tabluarDataKpiOptions;
  },
  methods: {
    ...mapActions('distribution', ['fetchDistributionByDate']),
    ...mapMutations('filter', ['getLocalStorageTabularKPI']),
    kpisorter(x, y) {
      return (
        this.customSort.indexOf(x.field) - this.customSort.indexOf(y.field)
      );
    },
    handleSearch(e) {
      // let filteredData = Object.keys(this.byDate).map(date => {
      //   return {
      //     [this.$t('Date')]: date,
      //     ...this.byDate[date]
      //   };
      // });

      this.data = this.formattedTableData.filter(tableData => {
        if (e.target.name === '天気' || e.target.name === 'Weather') {
          return tableData[e.target.name].Temperature.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        } else {
          return tableData[e.target.name]
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
      });
    },
    async loadData() {
      try {
        this.loading = true;
        this.data = [];
        this.columns = [];

        // * updates this.byDate
        await this.fetchDistributionByDate({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          storeCodes: this.getStoreCodesList,
          daysOfWeek: this.getDaysOfWeekList,
          kpis: this.selectedKPIOptionList.join(',')
        });
        const byDate = { ...this.byDate };
        const availableDate = Object.keys(byDate);

        if (Object.keys(byDate[availableDate[0]]).length) {
          const tabularData = availableDate.map(singleDate => {
            const singleTableRow = { ...byDate[singleDate] };

            ['Weather', 'WeatherIcon', 'Temperature'].forEach(
              col => delete singleTableRow[col]
            );
            if (byDate[singleDate].Temperature) {
              return {
                [this.$t('Date')]: singleDate,
                [this.$t('weather')]: {
                  Temperature: byDate[singleDate].Temperature,
                  Weather: byDate[singleDate].Weather,
                  WeatherIcon: byDate[singleDate].WeatherIcon
                },
                ...singleTableRow
              };
            } else {
              return {
                [this.$t('Date')]: singleDate,
                ...singleTableRow
              };
            }
          });

          const sortedKpis = Object.values(kpiMapping);
          const singleTabularData = Object.keys(tabularData[0]);
          singleTabularData.sort((a, b) =>
            sortedKpis.indexOf(a) > sortedKpis.indexOf(b) ? 1 : -1
          );
          if (singleTabularData.includes(this.$t('weather'))) {
            singleTabularData[0] = this.$t('Date');
            singleTabularData[1] = this.$t('weather');
          }
          const tabularColumn = singleTabularData
            .map(column => {
              return {
                name: this.$t(column),
                align: 'center',
                label: this.$t(column),
                field: column,
                sortable: true
              };
            })
            .sort(this.kpisorter);
          this.data = [...tabularData];
          this.columns = [...tabularColumn];
          this.formattedTableData = [...tabularData];
        }
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: `${error}`
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
