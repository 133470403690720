
// import axios from 'axios';
import { PostRequest, GetRequest } from '@/util/http';
import { fetchClass } from '@/plugins/fetch';
export default {
  props: {
    // ...your custom props
    category: {
      type: String,
      default: 'warning'
    }
  },
  data() {
    return {
      categories: [],
      newCategory: {
        id: 0,
        name: '',
        description: ''
      }
    }
  },
  mounted() {
    if(this.category.id > 0)
    {
      this.newCategory.id = this.category.id;
      this.newCategory.name = this.category.name;
      this.newCategory.description = this.category.description;
    }
    else
    {
      // load categories from JSON file
      GetRequest('http://localhost:3000/categories',{},{ headers: null })
        .then(response => response)
        .then(data => {
          this.categories = data
        })
    }
  },
  created() {
    // this.loadCategories();
  },
  methods: {
    async onOKClick() { // generate new id
      if(this.newCategory.id > 0)
      {
        const newCategory = {
        id: this.newCategory.id,
        name: this.newCategory.name,
        description: this.newCategory.description
      }
      // this.categories.push(newCategory);
      await fetchClass(
        'http://localhost:3000/categories/'+this.newCategory.id,
          {
            method: 'PUT',
            body: newCategory
          },{ headers: null }
        );
      }
      else
      {
        const id = this.categories.length > 0 ? this.categories[this.categories.length - 1].id + 1 : 1

        // create new category object
        const newCategory = {
          id,
          name: this.newCategory.name,
          description: this.newCategory.description
        }

        // add new category to the list
        this.categories.push(newCategory);
        //const finalData = JSON.stringify({ categories: this.categories });
        // debugger;

        // save updated categories list to JSON file

        await PostRequest('http://localhost:3000/categories', newCategory, { headers: null })

  //  await fetchClass(
  //     'http://localhost:3000/categories/1',
  //   {
  //     method: 'PUT',
  //     body: this.categories
  //   },{ headers: null }
  // );
  }

      // reset newCategory object
      this.newCategory = {
        id: 0,
        name: '',
        description: ''
      }
      this.$emit('ok')
      // or with payload: this.$emit('ok', { ... })

      // then hiding dialog
      this.hide()
    },
    // updateCategory(category) {
    //   // save updated categories list to JSON file
    //   fetch('/categories.json', {
    //     method: 'PUT',
    //     body: JSON.stringify({ categories: this.categories })
    //   })
    // },
    // deleteCategory(category) {
    //   // remove category from the list
    //   this.categories = this.categories.filter(c => c !== category)

    //   // save updated categories list to JSON file
    //   fetch('/categories.json', {
    //     method: 'PUT',
    //     body: JSON.stringify({ categories: this.categories })
    //   })
    // },
    cancel() {
      this.dialog = false;
    },

    // following method is REQUIRED
    // (don't change its name --> "show")
    show () {
      this.$refs.dialog.show()
    },

    // following method is REQUIRED
    // (don't change its name --> "hide")
    hide () {
      this.$refs.dialog.hide()
    },

    onDialogHide () {
      // required to be emitted
      // when QDialog emits "hide" event
      this.$emit('hide')
    },
  }
}
