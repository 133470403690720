import {
  APIFeaturedIntegration,
  APIFeaturedListOfPackages,
  APIFetchFlowService,
  APIFetchIntegration,
  APIFetchIot,
  APIFetchIotRecommended,
  APIFetchListOfPackages,
  APIFetchPartners,
  APIFetchPartnersRecommended,
  APIFetchThirdParty,
  APIFetchThirdPartyRecommended,
  APISendEmail
} from '@/api/marketplace';

const today = new Date();
const yesterday = new Date(today);

yesterday.setDate(yesterday.getDate() - 1);

const formatDate = date => {
  if (!date) return null;
  let newDate = date.toISOString().substr(0, 10);
  const [year, month, day] = newDate.split('-');
  return `${year}-${month}-${day}`;
};
const initialState = {
  thirdPartyRecommended: [],
  thirdParty: [],
  partnersRecommended: [],
  partners: [],
  iot: [],
  iotRecommended: [],
  flowService: [],
  selectedTag: '',
  pricing: [],
  packages: [],
  featuredPackages: [],
  integration: [],
  integrationRecommended: [],
  filterLibrary: {
    startingPeriod: formatDate(new Date()),
    endingPeriod: formatDate(new Date()),
    startingCompared: formatDate(yesterday),
    endingCompared: formatDate(yesterday)
  }
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setThirdPartyRecommended(state, payload) {
      state.thirdPartyRecommended = payload;
    },
    setThirdParty(state, payload) {
      state.thirdParty = payload;
    },
    setIntegrationRecommended(state, payload) {
      state.thirdPartyRecommended = payload;
    },
    setIntegration(state, payload) {
      state.integration = payload;
    },
    setPartnersRecommended(state, payload) {
      state.integrationRecommended = payload;
    },
    setPartners(state, payload) {
      state.partners = payload;
    },
    setIot(state, payload) {
      state.iot = payload;
    },
    setIotRecommended(state, payload) {
      state.iotRecommended = payload;
    },
    setFlowService(state, payload) {
      state.flowService = payload;
    },
    setSelectedTag(state, payload) {
      state.selectedTag = state.selectedTag === payload ? '' : payload;
    },
    setPricing(state, payload) {
      state.pricing = payload;
    },
    setListOfPackages(state, payload) {
      state.packages = payload;
    },
    setFeaturedListOfPackages(state, payload) {
      state.featuredPackages = payload;
    },
    setLibraryStartingPeriod(state, { startingPeriod }) {
      localStorage.removeItem('startingLibraryPeriod');
      localStorage.setItem('startingLibraryPeriod', startingPeriod);
      state.filterLibrary.startingPeriod = startingPeriod;
    }, // setStartingPeriod
    setLibraryEndingPeriod(state, { endingPeriod }) {
      localStorage.removeItem('endingLibraryPeriod');
      localStorage.setItem('endingLibraryPeriod', endingPeriod);
      state.filterLibrary.endingPeriod = endingPeriod;
    }, // setEndingPeriod

    setLibraryStartingCompared(state, { startingCompared }) {
      localStorage.removeItem('startingLibraryCompared');
      localStorage.setItem('startingLibraryCompared', startingCompared);
      state.filterLibrary.startingCompared = startingCompared;
    }, // setStartingCompared
    setLibraryEndingCompared(state, { endingCompared }) {
      localStorage.removeItem('endingLibraryCompared');
      localStorage.setItem('endingLibraryCompared', endingCompared);
      state.filterLibrary.endingCompared = endingCompared;
    }
  }, // mutations
  actions: {
    async sendEmailToUser({ commit }, details) {
      await APISendEmail(details);
    },
    fetchListOfPackages({ commit }) {
      let res = APIFetchListOfPackages();
      commit('setListOfPackages', res);
    },
    fetchFeaturedListOfPackages({ commit }) {
      let res = APIFeaturedListOfPackages();
      commit('setFeaturedListOfPackages', res);
    },
    fetchThirdPartyRecommended({ commit }) {
      let res = APIFetchThirdPartyRecommended();
      commit('setThirdPartyRecommended', res);
    },
    fetchThirdParty({ commit }) {
      let res = APIFetchThirdParty();
      commit('setThirdParty', res);
    },
    fetchIntegrationRecommended({ commit }) {
      let res = APIFeaturedIntegration();
      commit('setIntegrationRecommended', res);
    },
    fetchIntegration({ commit }) {
      let res = APIFetchIntegration();
      commit('setIntegration', res);
    },
    fetchPartnersRecommended({ commit }) {
      let res = APIFetchPartnersRecommended();
      commit('setPartnersRecommended', res);
    },
    fetchPartners({ commit }) {
      let res = APIFetchPartners();
      commit('setPartners', res);
    },
    fetchIot({ commit }) {
      let res = APIFetchIot();
      commit('setIot', res);
    },
    fetchIotRecommended({ commit }) {
      let res = APIFetchIotRecommended();
      commit('setIotRecommended', res);
    },
    fetchFlowService({ commit }) {
      let res = APIFetchFlowService();
      commit('setFlowService', res);
    }
  } // actions
}; // export default
