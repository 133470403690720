
import Vue from 'vue';
import loader from '../../../plugins/google-maps';
import SpaceUserTooltip from './SpaceUserTooltip.vue';

export default {
  name: 'SpaceMapArea',
  props: {
    staffDetails: {
      required: true,
      type: Object,
      default: () => {}
    },
    storeSelected: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      zoom: 20.35,
      map: null,
      center: { lat: 139.63477693, lng: 35.44013373 },
      mapBounds: null,
      floorMapUrl: '',
      staffDetailsList: [],
      markers: []
    };
  },
  computed: {
    onlineStaff() {
      return this.staffDetails?.online ?? [];
    }
  },
  watch: {
    storeSelected(newValue) {
      this.floorMapUrl = `https://demo-eddy.flow-insight.com/${newValue.floorPlanImagePath}`;
      loader.load().then(() => {
        const center = new google.maps.LatLng(
          newValue?.latitude ?? 35.44013373,
          newValue?.longitude ?? 139.63477693
        );
        this.map = new google.maps.Map(document.getElementById('map'), {
          zoom: this.zoom,
          center
        });
        this.map.panTo(center);
        const _this = this;
        google.maps.event.addListenerOnce(
          this.map,
          'bounds_changed',
          function() {
            _this.mapBounds = this.getBounds();
          }
        );
      });
    },
    onlineStaff(newValue) {
      // * place marker according to socket data staff array

      this.markers.forEach(v => v.setMap(null));
      newValue.forEach(staff => this.loadMarker(staff));
    },
    mapBounds(newValue) {
      this.loadOverlay(this.floorMapUrl);
    },
    floorMapUrl(newValue) {
      this.loadOverlay(newValue);
    }
  },
  created() {
    this.floorMapUrl = `https://demo-eddy.flow-insight.com/${newValue.floorPlanImagePath}`;
    loader.load().then(() => {
      const center = new google.maps.LatLng(35.44013373, 139.63477693);
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: this.zoom,
        center
      });
      this.map.panTo(center);
      const _this = this;
      google.maps.event.addListenerOnce(this.map, 'bounds_changed', function() {
        _this.mapBounds = this.getBounds();
      });
    });
  },
  methods: {
    loadOverlay(floorMapUrl) {
      // * getting four corners of view port to place image.
      const bounds = this.map?.getBounds();
      const ne = this.mapBounds?.getNorthEast();
      const sw = this.mapBounds?.getSouthWest();
      const imageBounds = {
        north: ne?.lat(),
        south: sw?.lat(),
        east: ne?.lng(),
        west: sw?.lng()
      };

      // * load floor map image, replace url with real floormap url.
      let groundOverlay = new google.maps.GroundOverlay(
        floorMapUrl,
        imageBounds,
        {
          opacity: 0.7
        }
      );

      // * adds overlay to map
      groundOverlay.setMap(this.map);
    },

    loadMarker(staff) {
      const myLatlng = new google.maps.LatLng(
        staff.locationLat ?? 35.44013373,
        staff.locationLong ?? 139.63477693
      );
      const marker = new google.maps.Marker({
        position: myLatlng
        // title: 'employee coordinate'
      });

      const infowindow = new google.maps.InfoWindow({
        content: this.generateTooltipContent(staff)
      });
      const hoverText = new google.maps.InfoWindow({
        content: staff.name
      });
      marker.addListener('mouseover', () => {
        hoverText.open(this.map, marker);
      });
      marker.addListener('mouseout', () => {
        hoverText.close(this.map, marker);
      });
      marker.addListener('click', () => {
        infowindow.open(this.map, marker);
      });
      this.markers.push(marker);
      marker.setMap(this.map);
    },
    generateTooltipContent(staff) {
      const ComponentClass = Vue.extend(SpaceUserTooltip);
      const instance = new ComponentClass();
      const propsObj = {
        staffInfo: { ...staff, floorLevel: '1' }
      };
      const props = Vue.observable({
        ...instance._props,
        ...propsObj
      });

      instance._props = props;
      const myicon = instance.$mount();
      const myiconhtml = myicon.$el.innerHTML;
      return myiconhtml;
    }
  }
};
