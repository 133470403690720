
import CommonMixin from '@/mixins/CommonMixin';
import map from 'lodash/map';

export default {
  name: 'SensorFilters',
  mixins: [CommonMixin],
  props: {
    stores: { type: Array, required: false, default: () => [] }
  },
  computed: {
    storeOptions() {
      return map(this.stores, store => ({
        label: store.name,
        value: store
      }));
    }
  }
};
