import webstomp from 'webstomp-client';
import { IEddyConfig, eddyConfigDefault } from './interface';
import { Notify } from 'quasar';
import { StoreParams, Occupancy } from '../../util/interface/Occupancy';
import OccupancyHandler  from './OccupancyHandler';
import storedata from '@/store';
import config from '@/config';
import {
  APIFetchDaily,
  APIFetchHourly,
  APIFetchOverview,
  APIFetchTokenForStore,
  APIFetchEddyConfig,
  APISetEddyConfig,
  APIFetchPowerHour,
  APIFetchStoreSensors
} from '@/api/occupancy';
// import store from '..';
// import { Store } from 'vuex';

interface IDateState {
  startDate: String;
  endDate: String;
  compareStartDate: String;
  compareEndDate: String;
}

interface IInitialState {
  selectedStore: String;
  overview: any[];
  currentOverview: any;
  availableStores: any;
  storeListLoading: boolean;
  hourlyLoadingState: boolean;
  dailyLoadingState: boolean;
  overviewLoadingState: boolean;
  hourlyData: any;
  dailyData: any;
  hourlyCategories: any;
  dailyCategories: any;
  currentWidgetToken: string;
  widgetTokenLoadingState: boolean;
  powerHourData: any;
  storeSensorData: any;
  eddyConfig: IEddyConfig | {};
}

const initialState: IInitialState = {
  selectedStore: '33064',
  overview: [],
  availableStores: [],
  storeListLoading: false,
  hourlyLoadingState: false,
  dailyLoadingState: false,
  overviewLoadingState: false,
  currentOverview: {},
  hourlyData: {},
  dailyData: {},
  hourlyCategories: [],
  dailyCategories: [],
  currentWidgetToken: '',
  powerHourData: [],
  storeSensorData: [],
  widgetTokenLoadingState: false,
  eddyConfig: eddyConfigDefault
};

type Employee = {
  id: number;
  name: string;
  salary: number;
};

export default {
  state: initialState, // state
  mutations: {
    setSelectedStore(state: IInitialState, { store }) {
      state.selectedStore = store;
    },
    setOverview(state: IInitialState, { overview }) {
      state.overview = overview;
    },
    setOverviewLoading(state: IInitialState, { isLoading }) {
      state.overviewLoadingState = isLoading;
    },
    setCurrentOverview(state: IInitialState, { overview }) {
      state.currentOverview = overview;
    },
    setAvailableStores(state: IInitialState, { availableStores }) {
      state.availableStores = availableStores;
    },
    setStoreListLoading(state: IInitialState, { isLoading }) {
      state.storeListLoading = isLoading;
    },
    setHourlySeries(
      state: IInitialState,
      { hourlyOccupancy }: { hourlyOccupancy: Occupancy[] }
    ) {
      const hourlySeriesValues = {
        avgOccupancyPercentage: { data: [], formattedValue: [] },
        maxOccupancy: { data: [], formattedValue: [] },
        minOccupancy: { data: [], formattedValue: [] },
        avgOccupancy: { data: [], formattedValue: [] }
      };
      hourlyOccupancy.forEach((hourOccupancy: Occupancy) => {
        hourlySeriesValues.avgOccupancy = {
          data: [
            ...hourlySeriesValues.avgOccupancy.data,
            hourOccupancy.avgOccupancy
          ],
          formattedValue: [
            ...hourlySeriesValues.avgOccupancy.formattedValue,
            hourOccupancy.formattedAvgOccupancy
          ]
        };

        hourlySeriesValues.maxOccupancy = {
          data: [
            ...hourlySeriesValues.maxOccupancy.data,
            hourOccupancy.maxOccupancy
          ],
          formattedValue: [
            ...hourlySeriesValues.maxOccupancy.formattedValue,
            hourOccupancy.formattedMaxOccupancy
          ]
        };

        hourlySeriesValues.minOccupancy = {
          data: [
            ...hourlySeriesValues.minOccupancy.data,
            hourOccupancy.minOccupancy
          ],
          formattedValue: [
            ...hourlySeriesValues.minOccupancy.formattedValue,
            hourOccupancy.formattedMinOccupancy
          ]
        };

        hourlySeriesValues.avgOccupancyPercentage = {
          data: [
            ...hourlySeriesValues.avgOccupancyPercentage.data,
            hourOccupancy.avgOccupancyPercent
          ],
          formattedValue: [
            ...hourlySeriesValues.avgOccupancyPercentage.formattedValue,
            hourOccupancy.formattedAvgOccupancyPercent
          ]
        };
      });
      const hourlySeries: any = [
        {
          name: 'Average Occupancy Percentage',
          type: 'column',
          data: hourlySeriesValues.avgOccupancyPercentage.data,
          formattedValue:
            hourlySeriesValues.avgOccupancyPercentage.formattedValue
        },
        {
          name: 'Max Occupancy',
          type: 'line',
          data: hourlySeriesValues.maxOccupancy.data,
          formattedValue: hourlySeriesValues.maxOccupancy.formattedValue
        },
        {
          name: 'Min Occupancy',
          type: 'line',
          data: hourlySeriesValues.minOccupancy.data,
          formattedValue: hourlySeriesValues.minOccupancy.formattedValue
        }
        ,
        {
          name: 'Average Occupancy',
          type: 'line',
          data: hourlySeriesValues.avgOccupancy.data,
          formattedValue: hourlySeriesValues.avgOccupancy.formattedValue
        }
      ];
      state.hourlyData = hourlySeries;
    },
    setHourlySeriesFromSocket(state: IInitialState, { hourlyData }) {
      const arrayCat = hourlyData;
      let hourlySeries: any = [
        {
          name: 'Average Occupancy Percentage',
          type: 'column',
          data: Object.values(arrayCat).map(val =>
            parseInt(val['avgOccupancyPercentage'])
          )
        },
        {
          name: 'Max Occupancy',
          type: 'line',
          data: Object.values(arrayCat).map(val =>
            parseInt(val['maxOccupancy'])
          )
        },
        {
          name: 'Min Occupancy',
          type: 'line',
          data: Object.values(arrayCat).map(val =>
            parseInt(val['maxOccupancy'])
          )
        }
        ,
        {
          name: 'Average Occupancy',
          type: 'line',
          data: Object.values(arrayCat).map(val =>
            parseInt(val['avgOccupancy'])
          )
        }
      ];
      state.hourlyData = hourlySeries;
    },
    setDailySeries(
      state: IInitialState,
      { dailyOccupancy }: { dailyOccupancy: Occupancy[] }
    ) {
      const dailySeriesValues = {
        avgOccupancyPercentage: { data: [], formattedValue: [] },
        maxOccupancy: { data: [], formattedValue: [] },
        minOccupancy: { data: [], formattedValue: [] },
        avgOccupancy: { data: [], formattedValue: [] }
      };
      dailyOccupancy.forEach((dailyOccupancy: Occupancy) => {
        dailySeriesValues.avgOccupancy = {
          data: [
            ...dailySeriesValues.avgOccupancy.data,
            dailyOccupancy.avgOccupancy
          ],
          formattedValue: [
            ...dailySeriesValues.avgOccupancy.formattedValue,
            dailyOccupancy.formattedAvgOccupancy
          ]
        };

        dailySeriesValues.maxOccupancy = {
          data: [
            ...dailySeriesValues.maxOccupancy.data,
            dailyOccupancy.maxOccupancy
          ],
          formattedValue: [
            ...dailySeriesValues.maxOccupancy.formattedValue,
            dailyOccupancy.formattedMaxOccupancy
          ]
        };

        dailySeriesValues.minOccupancy = {
          data: [
            ...dailySeriesValues.minOccupancy.data,
            dailyOccupancy.minOccupancy
          ],
          formattedValue: [
            ...dailySeriesValues.minOccupancy.formattedValue,
            dailyOccupancy.formattedMinOccupancy
          ]
        };
        dailySeriesValues.avgOccupancyPercentage = {
          data: [
            ...dailySeriesValues.avgOccupancyPercentage.data,
            dailyOccupancy.avgOccupancyPercent
          ],
          formattedValue: [
            ...dailySeriesValues.avgOccupancyPercentage.formattedValue,
            dailyOccupancy.formattedAvgOccupancyPercent
          ]
        };
      });
      const dailySeries: any = [
        {
          name: 'Average Occupancy Percentage',
          type: 'column',
          data: dailySeriesValues.avgOccupancyPercentage.data,
          formattedValue:
            dailySeriesValues.avgOccupancyPercentage.formattedValue
        },
        {
          name: 'Max Occupancy',
          type: 'line',
          data: dailySeriesValues.maxOccupancy.data,
          formattedValue: dailySeriesValues.maxOccupancy.formattedValue
        },
        {
          name: 'Min Occupancy',
          type: 'line',
          data: dailySeriesValues.minOccupancy.data,
          formattedValue: dailySeriesValues.minOccupancy.formattedValue
        }
        ,
        {
          name: 'Average Occupancy',
          type: 'line',
          data: dailySeriesValues.avgOccupancy.data,
          formattedValue: dailySeriesValues.avgOccupancy.formattedValue
        }
      ];
      state.dailyData = dailySeries;
      // state.dailyData = series;
    },
    setHourlyLoading(state: IInitialState, { isLoading }) {
      state.hourlyLoadingState = isLoading;
    },
    setDailyLoading(state: IInitialState, { isLoading }) {
      state.dailyLoadingState = isLoading;
    },
    setHourlyCategories(
      state: IInitialState,
      { hourLabel }: { hourLabel: string[] }
    ) {
      state.hourlyCategories = [...hourLabel];
    },
    setHourlyCategoryFromSocket(state: IInitialState, { hourlyData }) {
      let arrayCat = hourlyData;
      const categories = Object.keys(arrayCat).map(v => v + ':00'); // here..
      state.hourlyCategories = categories; // here..
    },
    setDailyCategories(
      state: IInitialState,
      { dayLabel }: { dayLabel: string[] }
    ) {
      state.dailyCategories = [...dayLabel];
    },
    setCurrentWidgetToken(state: IInitialState, { token }) {
      state.currentWidgetToken = token;
    },
    setWidgetTokenLoadingState(state: IInitialState, { isLoading }) {
      state.widgetTokenLoadingState = isLoading;
    },
    setEddyConfig(state: IInitialState, { config }: { config: IEddyConfig }) {
      state.eddyConfig = config;
    },
    setPowerHourData(state: IInitialState, { powerHourData }) {
      state.powerHourData = powerHourData;
    },
    setStoreSensorData(state: IInitialState, { storeSensorData }) {
      state.storeSensorData = storeSensorData;
    },
  }, // mutations
  actions: {
    setStoreValue: async function({ commit }, data) {
      commit('setSelectedStore', { store: data });
    },
    fetchOccupancyOverview: async function({ commit }) {
      try {
    var sso = config.flowgo2Url;
    const storeState: any = storedata.state;
    const token = 'Bearer '+storeState.user.sessionClient.access_token;

    let obj1 = new OccupancyHandler(sso, token);
    //set time out

    obj1.callback = function () {
        // if(obj1.stores[0].data != null && obj1.stores[0].data != undefined   && obj1.stores[0].data != 'undefined')
        var stores = obj1.stores;// obj1.stores;//await APIFetchOverview();
        commit('setStoreListLoading', { isLoading: true });
        commit('setOverviewLoading', { isLoading: true });
        var storesResponce = stores.map(x => {return {
          id:x._id,
          storeName:x.title,//str.substring(0, 10)
          storeCode:x.storeNumber,
          occupancy: x.data?.occupancy?x.data.occupancy:0,
          capacity: x.occupancyConfig.maxOccupancy,
          occupancyPercentage: x.data?.occupantPercentage?x.data.occupantPercentage:0,
          occupancyConfig: x.occupancyConfig,
        }})
        let current: any = storesResponce;
        var StoreOccupancyId = localStorage.getItem("StoreOccupancyId");
        var index = current.findIndex(object => {
          return object.id === StoreOccupancyId?StoreOccupancyId:0;
        });
        if(index == -1)
        {
          index =0;
        }

        let store: any = current[index];
        commit('setCurrentOverview', { overview: current[index] });
        commit('setSelectedStore', { store: store });

        commit('setAvailableStores', {
          availableStores: current
        });
        commit('setOverview', { overview: current });
        commit('setStoreListLoading', { isLoading: false });
        commit('setOverviewLoading', { isLoading: false });

    };
    obj1.startUpdating();
          } catch (error) {
        commit('setStoreListLoading', { isLoading: false });
        commit('setOverviewLoading', { isLoading: false });
      }
    },
    socketHourlySeries: function({ commit }, hourlyData) {
      commit('setHourlySeriesFromSocket', { hourlyData }); //here..
    },
    socketHourlyCategory: function({ commit }, hourlyData) {
      commit('setHourlyCategoryFromSocket', { hourlyData }); // here..
    },
    socketOccupancyOverview:async function({ commit }, configHeader) {
    },
    setCurrentStoreData: async function({ commit }, storeId,id) {
      let store = this.state.occupancy.availableStores;
      let currentStore = store.find(val => val.storeCode === storeId);
      commit('setCurrentOverview', { overview: currentStore });
      commit('setSelectedStore', { store: currentStore });
    },
    fetchHourlyData: async function({ commit }, storeParams: StoreParams) {
      commit('setHourlyLoading', { isLoading: true });
      let apiResponse = await APIFetchHourly({
        ...storeParams,
        // tenant: 'FlowSolutions'
      });
      const responseData =
      apiResponse ? apiResponse: {};
        //  &&
        // apiResponse.data &&
        // apiResponse.data[0] &&
        // apiResponse.data[0][storeParams.storeCodes]
        //   ? apiResponse.data[0][storeParams.storeCodes]
        //   : {}; // filter data provided by backend
      try {
        const responseObjectKeys = Object.keys(responseData);
        let myArray = [];
        for(var i=0;i<responseObjectKeys.length;i++)
        {
          let data = {} as Occupancy;
          data.inTraffic = responseData[i]?.enters?responseData[i].enters:0;
          data.outTraffic = responseData[i]?.exits?responseData[i].exits:0;
          data.minOccupancy = responseData[i]?.minOccupancy?responseData[i].minOccupancy:0;
          data.maxOccupancy = responseData[i]?.maxOccupancy?responseData[i].maxOccupancy:0;
          data.time  = responseData[i]?._id?responseData[i]._id:0;
          var enteries = data.inTraffic-data.outTraffic;
          if(enteries<0)
          {
            enteries=0;
          }
          var maxConfOccupancy = Number(localStorage.getItem("MaxConfigOccupancy"));//avgOccupancy

          data.avgOccupancy = responseData[i]?.avgOccupancy?responseData[i].avgOccupancy:0;//(data.maxOccupancy + data.minOccupancy) /2;
          data.avgOccupancyPercent =  Math.round(((data.avgOccupancy)/maxConfOccupancy)*100);
          data.formattedAvgOccupancy = "" + data.avgOccupancy;
          data.formattedAvgOccupancyPercent = "" + data.avgOccupancyPercent;
          data.formattedMaxOccupancy = "" + data.maxOccupancy;
          data.formattedMinOccupancy = "" + data.minOccupancy;
          data.formattedOutTraffic = "" + data.outTraffic;
          data.formattedinTraffic = "" + data.inTraffic;

          myArray.push(data);
        }
        const hourlyOccupancy = myArray;
        // commit('setHourlyCategories', {
        //   hourLabel: responseObjectKeys.length
        //     ? apiResponse.map(label => label._id)
        //     : []
        // });

           commit('setHourlyCategories', {
          hourLabel: responseObjectKeys.length
            ? apiResponse.map(x => {
              if(Number( x._id)<=9)
              {
                x._id = '0'+ x._id +':'+'00';
              }
              else
              {
                x._id =  x._id+':'+'00';
              }
              return  x._id;


            }
              )
            : []
        });
        // const dailyOccupancy = myArray1;
        // commit('setDailyCategories', {
        //   dayLabel: apiResponse1.length
        //     ? apiResponse1.map(label => label._id)
        //     : []
        // });


        commit('setHourlySeries', { hourlyOccupancy });


        // const hourlyOccupancy: Occupancy[] = responseObjectKeys.length
        //   ? Object.keys(responseData).map(hour => {
        //       return {
        //         time: hour,
        //         ...responseData.occupancy[hour]
        //       };
        //     })
        //   : [];

        // commit('setHourlyCategories', {
        //   hourLabel: responseObjectKeys.length
        //     ? Object.keys(responseData.occupancy).map(label => label.toString())
        //     : []
        // });


        // commit('setHourlySeries', { hourlyOccupancy });
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      } finally {
        commit('setHourlyLoading', { isLoading: false });
      }
    },
    fetchDailyData: async function({ commit }, storeParams: StoreParams) {
      try {
        commit('setDailyLoading', { isLoading: true });
        let apiResponse1 = await APIFetchDaily(storeParams);
        // const responseData1 =
        //   apiResponse1 ? apiResponse1: {};
        // const responseObjectKeys1 = Object.keys(responseData1);
        let myArray1 = [];
       // for(var i=0;i<responseObjectKeys1.length;i++)
       for(var i=0;i<apiResponse1.length;i++)
        {
          let data1 = {} as Occupancy;
          data1.inTraffic = apiResponse1[i]?.enters?apiResponse1[i].enters:0;
          data1.outTraffic = apiResponse1[i]?.exits?apiResponse1[i].exits:0;
          data1.minOccupancy = apiResponse1[i]?.minOccupancy?apiResponse1[i].minOccupancy:0;
          data1.maxOccupancy = apiResponse1[i]?.maxOccupancy?apiResponse1[i].maxOccupancy:0;
          data1.time  = apiResponse1[i]?._id?apiResponse1[i]._id:0;
          var diff = data1.inTraffic-data1.outTraffic;
          if(diff<0)
          diff = 0;
          var maxConfOccupancy = Number(localStorage.getItem("MaxConfigOccupancy"));//avgOccupancy

          data1.avgOccupancy = apiResponse1[i]?.avgOccupancy?apiResponse1[i].avgOccupancy:0;
          data1.avgOccupancyPercent = Math.round(((data1.avgOccupancy)/maxConfOccupancy)*100);
          data1.formattedAvgOccupancy = "" + (data1.avgOccupancy>=0?data1.avgOccupancy:0);
          data1.formattedAvgOccupancyPercent = "" + (data1.avgOccupancyPercent>=0?data1.avgOccupancyPercent:0);
          data1.formattedMaxOccupancy = "" + (data1.maxOccupancy>=0?data1.maxOccupancy:0);
          data1.formattedMinOccupancy = "" + (data1.minOccupancy>=0?data1.minOccupancy:0);
          data1.formattedOutTraffic = "" + data1.outTraffic;
          data1.formattedinTraffic = "" + data1.inTraffic;

          myArray1.push(data1);
        }
        const dailyOccupancy = myArray1;
        commit('setDailyCategories', {
          dayLabel: apiResponse1.length
            ? apiResponse1.map(label => label._id)
            : []
        });
        commit('setDailySeries', { dailyOccupancy });
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      } finally {
        commit('setDailyLoading', { isLoading: false });
      }
    },
    fetchToken: async function({ commit }, storeBody) {
      let tenant = 'FlowSolutions';
      commit('setWidgetTokenLoadingState', { isLoading: true });
      const res = await APIFetchTokenForStore(storeBody);
      if (res.data) {
        const token = res.data.token;
        commit('setCurrentWidgetToken', { token });
      }
      commit('setWidgetTokenLoadingState', { isLoading: false });
    },
    fetchEddyConfig: async function({ commit }, storeDataF) {
      //overview //Nabeel
      const res = await APIFetchEddyConfig(storeDataF.id);
      // const obj:IEddyConfig =  {
      //   tenant: "",
      //   storeName: storeDataF.storeName,
      //   showHourlyIndicators: storeDataF.occupancyConfig.showHourlyIndicator,
      //   capacity: storeDataF.capacity,

      //   greenIndicatorMsg: storeDataF.occupancyConfig.defaultWidget.safe.description,
      //   greenIndicatorLabel: storeDataF.occupancyConfig.defaultWidget.safe.title,
      //   greenIndicatorThreshold: 0,

      //   redIndicatorMsg: storeDataF.occupancyConfig.defaultWidget.unsafe.description,
      //   redIndicatorLabel: storeDataF.occupancyConfig.defaultWidget.unsafe.title,
      //   redIndicatorThreshold: storeDataF.occupancyConfig.defaultWidget.unsafe.thresholdActual,

      //   yellowIndicatorLabel: storeDataF.occupancyConfig.defaultWidget.warning.title,
      //   yellowIndicatorMsg: storeDataF.occupancyConfig.defaultWidget.warning.description,
      //   yellowIndicatorThreshold: storeDataF.occupancyConfig.defaultWidget.warning.thresholdActual,

      //   widgetGreenIndicatorLabel: storeDataF.occupancyConfig.safeUnSafeWidget.safe.title,
      //   widgetGreenIndicatorMsg:  storeDataF.occupancyConfig.safeUnSafeWidget.safe.description,
      //   widgetGreenIndicatorThreshold:  storeDataF.occupancyConfig.safeUnSafeWidget.safe.thresholdActual,

      //   widgetRedIndicatorLabel: storeDataF.occupancyConfig.safeUnSafeWidget.unsafe.title,
      //   widgetRedIndicatorMsg: storeDataF.occupancyConfig.safeUnSafeWidget.unsafe.description,
      //   widgetRedIndicatorThreshold:  storeDataF.occupancyConfig.safeUnSafeWidget.unsafe.thresholdActual
      // };

    //   const formatedData = storeData.occupancyConfig.map(x => {return{
    //     tenant: x.maxOccupancy,
    //     storeName: x.maxOccupancy,
    //     showHourlyIndicators: 1,
    //     capacity: 1,
    //     greenIndicatorMsg: x.maxOccupancy,
    //     greenIndicatorLabel: x.maxOccupancy,
    //     greenIndicatorThreshold: 1,

    //     redIndicatorMsg: x.maxOccupancy,
    //     redIndicatorLabel: x.maxOccupancy,
    //     redIndicatorThreshold: 1,

    //     yellowIndicatorLabel: x.maxOccupancy,
    //     yellowIndicatorMsg: x.maxOccupancy,
    //     yellowIndicatorThreshold: 1,

    //     widgetGreenIndicatorLabel: x.maxOccupancy,
    //     widgetGreenIndicatorMsg: x.maxOccupancy,
    //     widgetGreenIndicatorThreshold: 1,
    //     widgetRedIndicatorLabel: x.maxOccupancy,
    //     widgetRedIndicatorMsg: x.maxOccupancy,
    //     widgetRedIndicatorThreshold: 1
    // }});
      const eddyConfig = res as IEddyConfig;
   //storeData
      commit('setEddyConfig', { config: eddyConfig });
    },
    setEddyConfig: async function({ commit }, eddyConfig) {
      return await APISetEddyConfig({
        ...eddyConfig,
        // tenant: 'FlowSolutions'
      });
    },
    async fetchPowerHourData(
      { commit },
      { startDate, endDate, storeCodes, kpiMetricData, daysOfWeek, storeId }
    ) {
      if(storeId == null || storeId == undefined)
      {
       var storeVarId = localStorage.getItem("StoreOccupancyId");
       storeId = storeVarId;
      }
      const obj = await APIFetchPowerHour(
        {
          startDate,
          endDate,
          storeCodes,
          daysOfWeek,
          storeId
        },
        kpiMetricData
      );

    const obj2 = {
      Monday: {},
      Tuesday:{},
      Wednesday: {},
      Thursday:{},
      Friday: {},
      Saturday:{},
      Sunday: {}
    };
    const obj3 = {
      Monday: 0,
      Tuesday:0,
      Wednesday:0,
      Thursday:0,
      Friday: 0,
      Saturday:0,
      Sunday: 0
    };
    for(var i=0;i<obj.length;i++)
    {
      if(obj[i]?._id?.dayName == "monday")
      {
        obj3.Monday = 1;
        obj2["Monday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "tuesday")
      {
        obj3.Tuesday = 1;
        obj2["Tuesday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "wednesday")
      {
        obj3.Wednesday = 1;
        obj2["Wednesday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "thursday")
      {
        obj3.Thursday = 1;
        obj2["Thursday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "friday")
      {
        obj3.Friday = 1;
        obj2["Friday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "saturday")
      {
        obj3.Saturday = 1;
        obj2["Saturday"][""+obj[i]._id.hour] = obj[i];
      }
      else if(obj[i]?._id?.dayName == "sunday")
      {
        obj3.Sunday = 1;
        obj2["Sunday"][""+obj[i]._id.hour] = obj[i];
      }
    }

    if(obj3.Monday == 0)
    {
      delete obj2["Monday"];
    }

    if(obj3.Tuesday == 0)
    {
      delete obj2["Tuesday"];
    }

    if(obj3.Wednesday == 0)
    {
      delete obj2["Wednesday"];
    }

    if(obj3.Thursday == 0)
    {
      delete obj2["Thursday"];
    }

    if(obj3.Friday == 0)
    {
      delete obj2["Friday"];
    }

    if(obj3.Saturday == 0)
    {
      delete obj2["Saturday"];
    }

    if(obj3.Sunday == 0)
    {
      delete obj2["Sunday"];
    }

      commit('setPowerHourData', { powerHourData: obj2 });
      // commit('setPowerHourData', { powerHourData: res.data });
    },

    // async fetchStoreSensors(
    //   { commit },
    // ) {
    //   const res = await APIFetchStoreSensors();
    //   commit('setStoreSensorData', { storeSensorData: res });
    //   // commit('setPowerHourData', { powerHourData: res.data });
    // },

  },
  methods: {
    async fetchStores() {
            var url = new URL(this.baseUrl + "getKpiStores");
            url.searchParams.append("kpiType", "occupancy");
            url.searchParams.append("client_id", "aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688");
            url.searchParams.append("client_secret", "fp329-polk80s-ye04p1yy-45hx874z06");

            const rawResponse = await fetch(url.href, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": this.token
              },
            });
            return rawResponse.json();
          },
  }
}; // export default
