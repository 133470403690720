import axios from 'axios';
import store from '@/store'
//check
export function getLocaleMehod() {

    axios.get('https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/localization/client_app.json',{headers:{
        Authorization:''
    }})
  .then(function(response) {
    let data = response;
    let langObj = {};
    const storeState: any = store.state;
    var lang = storeState.user.locale || 'en';
    for (const key in data) {
        langObj[key] = data[key][lang];
    }
    store.commit('user/setTranslations', langObj)
    store.commit('user/setTranslationsWhole', data)
  })
  .catch(function(error) {
    //console.log(error);
  })
 }
 export function getLocaleMethodKpi() {

  axios.get('https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/localization/client_kpis.json',{headers:{
      Authorization:''
  }})
.then(function(response) {
  
  let data = response;
  let langObj = {};
  let tenantId = localStorage.getItem("tenentID") || ''; 
  
  for (const key in data) {
    let _key = `${key}_${tenantId}`
    if (data[_key]) {
       langObj[key.split('_')[0]] = data[_key]; 
       console.log("Plaza kpis key:", _key, 'kpi Data :', data[_key])
    }else { 
      if(!langObj[key] && key.split("_").length == 1){
        langObj[key] = data[key]; 
      }
    }
  }
  store.commit('user/setTranslationsKpi', langObj)
})
.catch(function(error) {
  //console.log(error);
})
}
