
import { mapState, mapActions, mapGetters } from 'vuex';
import KpiCardTree from './KpiCardTree.vue';
import { APIGetReportingSumByLocationDataFidaV2} from '@/api/fidaApi.ts'
import { getStartDayOfWeek } from '@/util/calendar.js'
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  getDay
} from 'date-fns';
import {APIGetTriggeredMessages} from '@/api/fidaApi';
import moment from "moment";
import store from '@/store';
import { getWeekdayToday } from '@/helper/helperFunction';
import { addSign } from '@/util/replaceFidaKpi';
import { getKpiTitle } from '@/util/utilFidaFunctions';

export default {
  components: {
    KpiCardTree
  },
  props: {
    type: {
      type: String,
      default: 'daily'
    }
  },
  data() {
    return {
      loading: false,
      compPeriod: '',
      storeCount: 0,
      kpiTreeOpen: false,
      showDate: '',
      showCompDate: '',
      comp: "",
                
      kpiData: {
        sls: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        ap: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        trn: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        aip: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        upt: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        trf: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        cn: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        pb: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false },
        cr: { title:'',value: '0',compTargetFormated : '0', variance: '', growthIndicator: false, line: false, sparkles: false }
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod'
    ]),
    ...mapState('overview', { overViewKpis: 'kpis' }),
    ...mapState('target', ['targets'])
  },
  watch: {
    kpiTreeOpen(val) {
      this.kpiTreeOpen = val;
      if (val) {
        this.fetchKpiData();
      }
    }
  },
  created(){
    this.storeCount = this.getStoreCodesList.split(',').length;
  },
  methods: {
      ...mapActions('overview', ['fetchOverview']),
     close(){
      this.kpiTreeOpen = false
     },
      async fetchKpiData() {
        try {
          this.loading = true;
          const res = await this.fetchOverviewData(this.type);
          this.kpiData = res.data;
        } catch (error) {
          console.error('Error fetching KPI data:', error);
        }
      },
      getComparedDates(selectedPeriod, types) {//types daily,weekly, monthly
        let comparedPeriod = {};
        if(types == 'daily') { //nedd to pass week day when calling api
          const daysDiff = 35;
          comparedPeriod.start = addDays(selectedPeriod.start, -daysDiff);
          comparedPeriod.end = addDays(selectedPeriod.start, -1);
        } else if(types == 'weekly') {
          const daysDiff = 35;
          comparedPeriod.start = addDays(selectedPeriod.start, -daysDiff);
          comparedPeriod.end = addDays(selectedPeriod.start, -1);
        } else { //monthly
          comparedPeriod.start = addMonths(new Date(selectedPeriod.start),-5);
          comparedPeriod.end = addDays(selectedPeriod.start, -1);
        }
       
       // this.compPeriod = this.$store.state.user.translate[comparedOption.toLowerCase()];
        return comparedPeriod;
      },
      getDateFormatted() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      },
      calculateSums(data) {
          return data.reduce((acc, kpi) => {
          for (let key in kpi) {
              if (key !== '_id' && kpi[key] != null && typeof kpi[key] === 'number') {
                  acc[key] = (acc[key] || 0) + kpi[key];
              }
          }
          return acc;
        }, {});
      },
      formatData(kpisSum, kpisCompareSum) {
        const data = {};
        for (let key in kpisSum) {
              const value = kpisSum[key];
              const compareValue = kpisCompareSum[key];

              if (compareValue == null || compareValue === 0) {
                  data[key] = {
                      value: `${value}`,
                      variance: '0',
                      arrow: false,
                      line: true,
                      sparkles: key === 'trn' || key === 'cn' || key === 'cr'
                  };
              } else {
        var variation;
        if(compareValue == 0 || compareValue == '0' || compareValue == undefined || compareValue == null ||compareValue == '')
          variation = 0;
        else
        variation = ((value - compareValue) / compareValue) * 100;

                  const variance = Math.round(variation);//((value / compareValue) * 100).toFixed(2);
                  const arrow = variance >= 0;
                  const line = true;
                  const sparkles = key === 'trn' || key === 'cn' || key === 'cr';

                  data[key] = {
                      value: `${value}`,
                      variance: `${variance}%`,
                      arrow: arrow,
                      line: line,
                      sparkles: sparkles
                  };
              }
          }
          return data;
      },
      getUniqueKPIsByDate(data, date) {
        let uniqueKPIs = new Set();

        data.forEach(item => {
            if (item.cDate.date === date) {
                uniqueKPIs.add(item.kpi);
            }
        });

        return Array.from(uniqueKPIs);
      },
      getTargetPercent(achieved, total) {
        if(isNaN(achieved) || isNaN(total) || total === 0) {
          return 0
        }
        const percentage = Math.round((achieved / total) * 100);
        return percentage / 100;
      },
      async fetchOverviewData(params) {
        let startDate,endDate,compareStartDate,compareEndDate;
        if(params == 'daily') {// today
          startDate = new Date();
          endDate = new Date();
         
        } else if(params == 'weekly') {//this week
            startDate = startOfWeek(new Date(), { weekStartsOn: getStartDayOfWeek() });
            endDate = new Date();
        }
        else { // month
            startDate = startOfMonth(new Date());
            endDate = new Date();
        }
        
        let selectedPeriod = {
          start: startDate,
          end: endDate
        }
        const compDates = this.getComparedDates(selectedPeriod,params);
        let kpiList = "sls,ap,trn,aip,upt,trf,cn,pb,cr,st,is";
        localStorage.setItem("kpiList",kpiList);
       
        const filterParams = {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate:  format(endDate, 'yyyy-MM-dd'),
          compareStartDate: format(compDates.start, 'yyyy-MM-dd'),
          compareEndDate: format(compDates.end, 'yyyy-MM-dd'),
          "storeCodes": this.getStoreCodesList,
          daysOfWeek: params == 'daily'? getWeekdayToday(): this.getDaysOfWeekList,
          "kpiTypes": kpiList.split(','),
          "frequency": "all",
          "status": "kpitree"
        };

         let lang = this.$store.state.user.locale;
         if(params == 'daily')
         {
            this.showDate =  moment(startDate).locale(lang).format("ll"); ///format(startDate, 'yyyy-MM-dd');
         }
         else
         {
            this.showDate = moment(startDate).locale(lang).format("ll")  +' - ' +moment(endDate).locale(lang).format("ll") ;
         }
       
        let isActionable = await this.fetchOverview(filterParams);
   
        var response = await APIGetTriggeredMessages({});
        var resp = response.data.response;
        let availbleMessageKpis = this.getUniqueKPIsByDate(resp.messages,format(startDate, 'yyyy-MM-dd'))
        if(resp.messages.length ==0)
        {
          console.log('no record found');
        }
        const data = isActionable.data[0].kpis;
        if(data) {  
          let totalSalesTarget = 0;
          let multiplier = 1;
          let zeroMultiplier = 1;
          let targetPercent = 1;
            if(params == 'daily')
            {
                totalSalesTarget = this.targets.dailyTarget.expectedTar;
                
                multiplier = this.getTargetPercent(
                  this.targets?.dailyTarget?.expectedTar || 0,this.targets?.dailyTarget?.target || 0
                )
                
            } else if(params == 'weekly') {
               totalSalesTarget = this.targets.weeklyTarget.expectedTar;
                multiplier = this.getTargetPercent(
                  this.targets?.weeklyTarget?.expectedTar || 0,this.targets?.weeklyTarget?.target || 0
                )
              
            } else {
               totalSalesTarget = this.targets.monthlyTarget.expectedTar;
                multiplier = this.getTargetPercent(
                  this.targets?.monthlyTarget?.expectedTar || 0,this.targets?.monthlyTarget?.target || 0
                )
            }
            if(multiplier == 0)
            {
              zeroMultiplier = 0;
            }
          //get average values
          for (let key in data) {
            const comp = data[key]?.comparedValuePlain?data[key].comparedValuePlain:0;
            //it have average of compare period
            if(key != 'cr' && key != 'upt'  && key != 'ap' && key != 'aip')
              data[key].compTarget = Number(comp) / 5;
            else
               data[key].compTarget = Number(comp);
          }
            //sales target
            data['sls'].compTarget = Math.round(totalSalesTarget);
           
           
            //= Sales Target / Transactions
           // data['ap'].compTarget = Math.round(totalSalesTarget /data['trn'].compTarget);

            //= Sales Target / Average Purchase
            data['trn'].compTarget = Math.round(totalSalesTarget /data['ap'].compTarget);
            
            //Sales Target / Units per Transaction
          //  data['aip'].compTarget = Math.round(totalSalesTarget /data['is'].compTarget);
           
            // = Average Item Price / Average Purchase
             data['upt'].compTarget = Number((data['ap'].compTarget/data['aip'].compTarget).toFixed(2));
         
            data['trf'].compTarget = Math.round(data['trf'].compTarget*multiplier);
            console.log('Multiplier:',Math.round(data['trf'].compTarget));
            const conv = data['trn'].compTarget/data['trf'].compTarget
            data['cn'].compTarget = (conv*100).toFixed(2);
      
            
            data['pb'].compTarget = Math.round(data['pb'].compTarget*multiplier);
          
            data['cr'].compTarget = Number(data['cr'].compTarget).toFixed(2);
          
          for (let key in data) {
              if (data[key].compTarget === null || isNaN(data[key].compTarget) || zeroMultiplier ==0) {
                  data[key].compTarget = 0;
              }
          }

          for (let key in data) {
            data[key].title = getKpiTitle(key);
            console.log('kpi title'+data[key].title);
            data[key].compTargetFormated = ' ('+this.$store.state.user.translate["target_tree"]+':'+addSign(key,Number(data[key].compTarget).toLocaleString()) +')';
            let varianceRes = 0;
            if(data[key].compTarget == 0)
              varianceRes = 0;
            else
              varianceRes = (data[key].valuePlain/data[key].compTarget)*100;
            
            // if(varianceRes>100)
            //   varianceRes = 100;
            // else 
            if(varianceRes<0)
              varianceRes = 0;

            data[key].variance = varianceRes.toFixed(2) + '%';
            if(availbleMessageKpis.includes(key))
            {
              data[key].sparkles = true;
            }
            if (varianceRes < 100) {
                data[key].growthIndicator = false;
                if(key != "sls")
                  data[key].line = true;
            }
            else
            {
              data[key].growthIndicator = true;
              if(key != "sls")
                  data[key].line = true;
            }
          }
           this.loading = false;
          return {
            data
        }
      }
    }
  }
};
