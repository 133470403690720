import { render, staticRenderFns } from "./peerGroups.vue?vue&type=template&id=0c11de41&"
import script from "./peerGroups.vue?vue&type=script&lang=js&"
export * from "./peerGroups.vue?vue&type=script&lang=js&"
import style0 from "./peerGroups.vue?vue&type=style&index=0&id=0c11de41&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QCardActions,QBtn,QTable,QTh,QIcon,QTooltip,QTd,QPagination,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QCardActions,QBtn,QTable,QTh,QIcon,QTooltip,QTd,QPagination})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
