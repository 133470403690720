
import { props } from './ButtonLinkDefs';

export default {
  props,
  computed: {
    buttonClass() {
      const { $style, outline } = this;
      return {
        'base-button': true,
        [$style.button]: true,
        [$style.outline]: outline
      };
    }
  }
};
