import {formatValues, compatibleList, compListToFida, getObjByKey, sortingKpiOrder, avgList, notFixed, zeroFixed,variationLocale, variationInNumbers, variationInNumbersPadded, directCalcObj, variationPercDiff} from '@/api/apiDataManagers/calc_consts';

function toDemo(res, stores) {
  const conv = {prod: {}, demo: {}}
  let retVal = {}
  res.demoStores.forEach(s => {
    if(stores.indexOf(s.storeNumber) > -1) {
      conv.prod[s.storeNumber] = s.stripeCode || s.prodCode;
      if(!conv.demo[s.stripeCode]) {
        conv.demo[s.stripeCode] = []
      }
      conv.demo[s.stripeCode].push({code: s.storeNumber, factor: s.demoFactor})
    }
  });


  retVal.stores = res.demoStores;
  ["kpis", "kpisCompare"].forEach((kpiVal) => {
    if(!retVal[kpiVal]) {
      retVal[kpiVal] = [];
    }


    res[kpiVal]?.forEach((kpi) => {
      let c = kpi._id.split("_")[1];
      let d = kpi._id.split("_")[1];

      let dS = conv.demo[c];
      if(dS.length) {
        dS.forEach((dC) => {
          let currKpi = JSON.parse(JSON.stringify(kpi));
          currKpi._id = d + "_" + dC.code;
          Object.keys(currKpi).forEach((k) => {
            if(k != "_id" && currKpi[k]) {
              currKpi[k] *= 1 + (dC.factor - 1)*14;
              if(zeroFixed.indexOf(k) > -1) {
                currKpi[k] = currKpi[k].toFixed(0)
              }
            }
          })
          retVal[kpiVal].push(currKpi)
        })
      }
    })
  })

  if(res.adjustRelatedKpiTypes) {
    retVal.adjustRelatedKpiTypes = res.adjustRelatedKpiTypes;
  }
  return retVal;
}
export default {
  toDemo
}