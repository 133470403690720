
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  props: {
    submitReport: {
      type: Function,
      default: () => {},
      required: true,
    },
    toggleModal: {
      type: Function,
      default: () => {},
      required: true,
    },
    isReportSaving: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...mapGetters("filter", [
      "getStoreCodesList",
      "getDaysOfWeekList",
      "getPulseStartingPeriod",
      "getPulseEndingPeriod",
      "getPulseStartingCompared",
      "getPulseEndingCompared",
      "getCustomCalenderState",
    ]),
    ...mapState("periodicReport", [
      "selectedStoreCode",
      "dailyReportList",
      "selectedStore",
    ]),
    ...mapState("user", ["profile"]),
    getButtonLabel() {
      if (this.$route.name === 'periodic_report_list' || this.$route.name === 'periodic_report_list_demo') {
        return this.$store.state.user.translate.download;
      } else {
        return this.$t('Library.OK');
      }
    }
  },
  watch: {
    getPulseStartingPeriod() {
      this.getAllReport();
    },
    getPulseEndingPeriod() {
      this.getAllReport();
    },
  },
  async created() {
    this.getAllReport();
  },
  methods: {
    ...mapActions("periodicReport", ["getDailyPeriodicReport"]),
    async getAllReport() {
      await this.getDailyPeriodicReport({
        reportType: "daily",
        startDate: this.getPulseStartingPeriod,
        endDate: this.getPulseEndingPeriod,
        storeCodes: this.selectedStoreCode,
      });
    },
  },
};
