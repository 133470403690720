
export default {
  props: {
    label: { type: String, default: '' },
    noBg: { type: Boolean, default: false },
    noMargin: { type: Boolean, default: false },
    padding: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    haserror: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelClass() {
      const { $style } = this;
      return {
        [$style.mandatory]: this.mandatory,
        [$style.label]: true,
        'text-bold': true
      };
    },
    fieldClass() {
      const { $style, noMargin } = this;
      return {
        [$style.field]: true,
        [$style.margin]: !noMargin
      };
    },
    inputClass() {
      const { $style, noBg, padding, haserror } = this;
      return {
        [$style.input]: true,
        [$style.bg]: !noBg,
        [$style.padding]: padding,
        'has-error': haserror
      };
    }
  }
};
