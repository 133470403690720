import {
  Pagination,
  QuasarPagination,
  PaginationResponse,
  FieldFilters
} from '@/util/pagination';

export default {
  data() {
    return {
      pagination: new Pagination(),
      quasarPagination: { page: 1, rowsPerPage: 0 }
    };
  },
  computed: {
    filters(): FieldFilters {
      return this.pagination.query;
    }
  },
  methods: {
    async onFilterChange(filter: FieldFilters) {
      this.pagination.setFilter(filter);
      await this.paginationChangeHandler();
    },
    updatePagination(response: PaginationResponse) {
      this.pagination.updateByResponse(response);
      this.quasarPagination = this.pagination.toQuasarPagination();
    },
    async onPaginationChange(pagination: QuasarPagination) {
      this.pagination.updateByPaginationChange(pagination);
      await this.paginationChangeHandler();
    },
    async paginationChangeHandler() {
      throw new Error(
        'Unimplemented paginationChangeHandler function is called'
      );
    }
  }
};
