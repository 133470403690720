import Config from './config';

var reportingURL, coreURL, flowgo, flowgo2, sso, eddyUrl, status, chatIP, chatIsProd;


const configData = require('../config/enviornment.json');
//const finalUrls = configData.http.staging;//production;//staging;//change variable

var actualStatus;
if(process.env.VUE_APP_STATUS === 'production' )
{
  actualStatus =  configData.http.production
  status = null
}
else
{
  if(status == 'production')
  {
    actualStatus =  configData.http.production;
    status = null;
  }
  else
  {
    actualStatus =  configData.http.staging;
    status = null;
  }
}
const finalUrls = actualStatus;//.http.staging;//configData.http.staging;//production;//staging;//change variables

function setConfig() {

  reportingURL = localStorage.getItem('reporting');//VUE_APP_Reporting_URL
  coreURL = localStorage.getItem('core');//VUE_APP_CORE
  flowgo = localStorage.getItem('flowgo');//VUE_APP_SSO_URL_Kpi
  flowgo2 = localStorage.getItem('flowgo2');

  sso = localStorage.getItem('sso');//VUE_APP_SSO_URL
  eddyUrl = localStorage.getItem('eddyUrl');
  status = localStorage.getItem('status');
  chatIP = localStorage.getItem('chatIP');
  chatIsProd = localStorage.getItem('chatIsProd');
  if ((reportingURL == null && coreURL == null && flowgo == null && flowgo2==null && sso == null && eddyUrl == null) || (status == null || status == "null")) {
    localStorage.setItem('sso', finalUrls.sso);
    localStorage.setItem('core', finalUrls.core);
    localStorage.setItem('flowgo', finalUrls.flowgo);
    localStorage.setItem('flowgo2', finalUrls.flowgo2);

    localStorage.setItem('reporting', finalUrls.reporting);
    localStorage.setItem('eddyUrl', finalUrls.eddyUrl);

    localStorage.setItem('status', finalUrls.status);
    localStorage.setItem('chatIP', finalUrls.chatIP);
    localStorage.setItem('chatIsProd', finalUrls.chatIsProd);

    reportingURL = finalUrls.reporting;
    coreURL = finalUrls.core;
    flowgo = finalUrls.flowgo;
    flowgo2= finalUrls.flowgo2;
    eddyUrl = finalUrls.eddyUrl;
    sso = finalUrls.sso;
    status = finalUrls.status;
    chatIP = finalUrls.chatIP;
    chatIsProd = finalUrls.chatIsProd;
  }
}
setConfig();

export default {
  reportingURL: reportingURL,
  coreURL: coreURL,
  ssoUrl: flowgo,
  flowgo2Url: flowgo2,
  sso: sso,
  eddyUrl: eddyUrl,
  chatIP: chatIP,
  chatIsProd: chatIsProd,
  setConfig: setConfig
} as Config;
