
import {
  getCurrencySymbol,
  formatCurrency
} from '@/models/formatters/CurrencyFormatter';
import Plan, { BillingModel } from '@/models/Plan';

export default {
  props: {
    plan: { type: Object, required: true },
    billingModel: { type: Number, required: true },
    active: { type: Boolean, default: false }
  },
  computed: {
    displayedPrice() {
      return formatCurrency(this.plan.price, this.plan.currency.code);
    },
    currency() {
      return getCurrencySymbol(this.plan.currency.code);
    },
    billingModelNote() {
      const period =
        this.billingModel === BillingModel.MONTHLY ? 'month' : 'year';
      const billingPeriod =
        this.billingModel === BillingModel.MONTHLY ? 'monthly' : 'annually';
      return `per ${period}, billed ${billingPeriod}`;
    }
  },
  methods: {
    select(plan: Plan) {
      this.$emit('selected', plan);
      if (this.active) {
        this.$emit('cancel', plan);
      } else {
        this.$emit('purchase', plan);
      }
    }
  }
};
