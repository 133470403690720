
import { mapState, mapActions, mapMutations } from 'vuex';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  mixins: [PaginationMixin],
  data() {
    return {
      columns: [
        {
          name: 'alertID',
          align: 'right',
          label: this.$t('alertID'),
          field: 'alertID',
          sortable: true,
          required: true,
          format: val => `${val}`
        },
        {
          name: 'alertName',
          align: 'center',
          label: this.$t('alertName'),
          field: 'alertName',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'stores',
          align: 'center',
          label: this.$t('stores'),
          field: 'stores',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'floor',
          align: 'center',
          label: this.$t('floor'),
          field: 'floor',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'POS',
          align: 'center',
          label: this.$t('pos'),
          field: 'POS',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'message',
          align: 'center',
          label: this.$t('message'),
          field: 'message',
          headerClasses: 'entrance-col',
          sortable: true
        },
        {
          name: 'status',
          align: 'center',
          label: this.$t('status'),
          field: 'status',
          headerClasses: 'entrance-col'
        },
        {
          name: 'actions',
          align: 'center',
          label: this.$t('actions'),
          field: 'actions',
          headerClasses: 'entrance-col'
        }
      ],
      row: [],
      loading: true,
      // searchKeyword: {
      //   alertID: '',
      //   alertName: '',
      //   stores: '',
      //   floor: '',
      //   POS: '',
      //   message: '',
      //   status: ''
      // },
      searchKeyword: ['', '', '', '', '', '', ''],
      parameter: ''
    };
  },
  computed: {
    ...mapState('alert', [
      'alertList',
      'tableData',
      'alertSelected',
      'alertRetrieved'
    ])
  },
  watch: {
    tableData() {
      this.row = this.tableData
        .map(val => {
          return {
            alertID: val.alertID,
            alertName: val.alertName,
            stores: val.storeName,
            floor: val.floorName,
            POS: val.posName,
            message: val.message,
            status: val.status
          };
        })
        .sort((a, b) => a.alertID - b.alertID);
    },
    searchKeyword() {
      let obj = {
        alertID: this.searchKeyword[0],
        alertName: this.searchKeyword[1],
        stores: this.searchKeyword[2],
        floor: this.searchKeyword[3],
        POS: this.searchKeyword[4],
        message: this.searchKeyword[5],
        status: this.searchKeyword[6]
      };
      let rowData = this.tableData.map(val => {
        return {
          alertID: val.alertID,
          alertName: val.alertName,
          stores: val.storeName,
          floor: val.floorName,
          POS: val.posName,
          message: val.message,
          status: val.status
        };
      });
      this.row = rowData.filter(v => {
        return String(v[this.parameter])
          .toLowerCase()
          .includes(obj[this.parameter].toLowerCase());
      });
    }
  },
  async created() {
    this.loading = true;
    await this.fetchAllAlertsOverview();
    this.loading = false;
  },
  methods: {
    ...mapActions('alert', [
      'fetchAllAlertsOverview',
      'changeAlertStatus',
      'fetchAlertByID',
      'deleteAlert',
      'addAlertData'
    ]),
    ...mapMutations('alert', [
      'setDialog',
      'setAlert',
      'setMode',
      'setAlertRetrieved'
    ]),
    async fetchTableData() {
      await this.fetchAllAlertsOverview();
    },
    deleteSelectedAlert(val) {
      this.$q
        .dialog({
          title: this.$t('confirmDelete'),
          message: this.$t('promptDelete'),
          cancel: true,
          persistent: false
        })
        .onOk(async () => {
          await this.deleteAlert(val);
          await this.fetchAllAlertsOverview();
          this.$q.notify({
            type: 'positive',
            position: 'top',
            message: this.$t('successMessage')
          });
        })
        .onCancel(() => {})
        .onDismiss(() => {});
    },
    async duplicateAlert(val) {
      await this.fetchAlertByID(val);
      this.$q
        .dialog({
          title: this.$t('confirmDelete'),
          message: this.$t('promptClone'),
          cancel: true,
          persistent: true
        })
        .onOk(async () => {
          await this.addAlertData({
            alertName: this.alertRetrieved.alertName,
            userGroups: this.alertRetrieved.groups
              .map(v => v.groupID)
              .join(','),
            users: this.alertRetrieved.users.map(v => v.userID).join(','),
            storeCode: this.alertRetrieved.store.storeCode,
            floorID: this.alertRetrieved.floor.floorID,
            posID: this.alertRetrieved.pos.posID,
            threshold: this.alertRetrieved.threshold,
            timeThreshold: this.alertRetrieved.timeThreshold,
            message: this.alertRetrieved.message,
            sticker: this.alertRetrieved.sticker
          });
          this.fetchTableData();
          this.$q.notify({
            type: 'positive',
            position: 'top',
            message: this.$t('successMessage')
          });
        })
        .onCancel(() => {})
        .onDismiss(() => {});
    },
    async editMode(val) {
      await this.fetchAlertByID(val);
      await this.setMode('edit');
      this.setDialog(true);
    },
    openAddDialog() {
      this.setMode('add');
      this.setDialog(true);
      this.setAlertRetrieved(null);
    },
    async changeStatusOfRow(val, id) {
      this.loading = true;
      await this.changeAlertStatus({ status: val, val: id });
      await this.fetchAllAlertsOverview();
      this.loading = false;
    }
  }
};
