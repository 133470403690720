
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';

export default {
  name: 'CustomerViewForm',
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      customer: null
    };
  }
};
