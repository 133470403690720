import { render, staticRenderFns } from "./SpaceUserTooltip.vue?vue&type=template&id=063128b0&"
import script from "./SpaceUserTooltip.vue?vue&type=script&lang=js&"
export * from "./SpaceUserTooltip.vue?vue&type=script&lang=js&"
import style0 from "./SpaceUserTooltip.vue?vue&type=style&index=0&id=063128b0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QSeparator,QCardSection,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QSeparator,QCardSection,QIcon})
