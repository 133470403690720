var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-table',{attrs:{"pagination":_vm.quasarPagination,"flat":"","loading":_vm.loading,"columns":_vm.columns,"data":_vm.data},scopedSlots:_vm._u([{key:"body-cell-price",fn:function(props){return [_c('q-td',{attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.formatPrice(props.row))+" ")])]}},{key:"body-cell-actions",fn:function(props){return [_c('q-td',{attrs:{"props":props}},[_c('router-link',{attrs:{"to":{
          name: 'management:product:view',
          params: { id: props.row.id }
        }}},[_c('c-btn',{attrs:{"flat":"","label":_vm.$t('SettingsManagement.view')}})],1),_c('router-link',{attrs:{"to":{
          name: 'management:product:edit',
          params: { id: props.row.id }
        }}},[_c('c-btn',{attrs:{"flat":"","color":"secondary","label":_vm.$t('SettingsManagement.edit')}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }