import { Currency, CurrencyDTO } from './Currency';
import Entity from '@/models/Entity';

class Plan implements Entity {
  id: string;
  name: string;
  code?: string;
  price: number;
  currency: Currency;
  benefits: string;
  subscriptionType: SubscriptionType;

  constructor(initializer: Partial<Plan>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: PlanDTO) {
    return new Plan({
      id: dto.id,
      name: dto.name,
      price: dto.price,
      currency: dto.currency,
      benefits: dto.benefits,
      subscriptionType: dto.subscriptionType
    });
  }
}

export enum BillingModel {
  MONTHLY,
  ANNUALLY
}

export enum PlanType {
  PLAN = 'PLAN',
  ADDONS = 'ADDONS'
}

export enum SubscriptionType {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY'
}

export interface PlanDTO {
  id: string;
  name: string;
  price: number;
  currency: CurrencyDTO;
  benefits: string;
  subscriptionType: SubscriptionType;
  status: number;
}

export default Plan;
