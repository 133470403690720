
export default {
  props: {
    url: { type: String, required: true, default: '' },
    filename: { type: String, required: true, default: '' },
    fetcher: { type: Function, default: null },
    formatter: { type: Function, default: null }
  },
  data() {
    return {
      data: null,
      error: false
    };
  },
  async created() {
    try {
      this.data = await this.fetch(this.url);
    } catch {
      this.error = true;
    }
  },
  methods: {
    fetch(url: string) {
      return (this.fetcher || this.defaultFetcher)(url);
    },
    format(data: any, url: string) {
      return (this.formatter || this.defaultFormatter)(data, url);
    },
    isImage() {
      return !this.filename.includes('.pdf');
    },
    defaultFetcher(url: string) {
      return Promise.resolve(url);
    },
    defaultFormatter(_: any, url: string) {
      return `url(${url})`;
    }
  }
};
