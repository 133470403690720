
import map from 'lodash/map';
import CommonMixin from '@/mixins/CommonMixin';

interface BusinessHour {
  day: string;
  close: boolean;
  startTime: string;
  endTime: string;
}

export default {
  mixins: [CommonMixin],
  props: {
    value: { default: () => [], type: Array },
    readonly: { default: false, type: Boolean }
  },
  data() {
    return {
      days: [
        this.$t('Date.monday'),
        this.$t('Date.tuesday'),
        this.$t('Date.wednesday'),
        this.$t('Date.thursday'),
        this.$t('Date.friday'),
        this.$t('Date.saturday'),
        this.$t('Date.sunday')
      ],
      payloadDays: [
        {
          name: 'MONDAY',
          openHour: 'mondayOpenHour',
          closeHour: 'mondayCloseHour'
        },
        {
          name: 'TUESDAY',
          openHour: 'tuesdayOpenHour',
          closeHour: 'tuesdayCloseHour'
        },
        {
          name: 'WEDNESDAY',
          openHour: 'wednesdayOpenHour',
          closeHour: 'wednesdayCloseHour'
        },
        {
          name: 'THURSDAY',
          openHour: 'thursdayOpenHour',
          closeHour: 'thursdayCloseHour'
        },
        {
          name: 'FRIDAY',
          openHour: 'fridayOpenHour',
          closeHour: 'fridayCloseHour'
        },
        {
          name: 'SATURDAY',
          openHour: 'saturdayOpenHour',
          closeHour: 'saturdayCloseHour'
        },
        {
          name: 'SUNDAY',
          openHour: 'sundayOpenHour',
          closeHour: 'sundayCloseHour'
        }
      ]
    };
  },
  async created() {
    if (
      this.value == null ||
      (Array.isArray(this.value) && this.value.length === 0)
    ) {
      const defaultValue = map(
        this.payloadDays,
        (day, index: string) =>
          ({
            day: day.name,
            close: false,
            startTime: '08:00',
            endTime: '20:00'
          } as BusinessHour)
      );
      this.$emit('input', { storeBusinessHours: defaultValue });
    }
  },
  methods: {
    updateBusinessHour(day, update) {
      const copy = this.value.map(el => ({ ...el }));
      if (update.close) {
        copy[day] = {
          ...copy[day],
          startTime: '09:00:00',
          endTime: '22:00:00',
          ...update
        };
      } else {
        copy[day] = {
          ...copy[day],
          ...update
        };
      }
      let dayHours = {};
      dayHours[this.payloadDays[day].openHour] = copy[day].startTime;
      dayHours[this.payloadDays[day].closeHour] = copy[day].endTime;
      this.$emit('input', { ...dayHours, storeBusinessHours: copy });
    }
  }
};
