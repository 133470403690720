
import { mapActions, mapState, mapGetters } from 'vuex';
import { addKpiCustomization } from '@/util/kpis';

export default {
  components: {},
  data() {
    return {
      currentTrend: '',
      loading: true
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    ...mapState('queue', ['storeID', 'floorID', 'posID'])
  }, // computed
  watch: {
    async posID() {
      this.loading = true;
      await this.loadData();
      this.loading = false;
    }
  },
  async created() {
    if (this.posID) {
      await this.loadData();
      this.loading = false;
    }
  }, // created
  methods: {
    ...mapActions('queue', ['fetchKpiData']),
    async loadData() {
      this.loading = true;
      if (this.posID) {
        await this.fetchKpiData({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          daysOfWeek: this.getDaysOfWeekList,
          compareEndDate: this.getEndingCompared,
          kpis: 'all',
          posID: this.posID,
          floorID: this.floorID,
          storeCodes: this.storeID
        });
      }
    }
  } // methods
}; // export default
