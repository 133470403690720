
import BaseModal from './BaseModal';

export default {
  mixins: [BaseModal],
  props: {
    size: { type: String, default: 'medium' }
  },
  data() {
    return {
      $style: null
    };
  },
  computed: {
    rootClass() {
      return this.toggleClass(this.$style.root);
    },
    overlayClass() {
      return this.toggleClass(this.$style.overlay);
    },
    containerClass() {
      return this.toggleClass(this.$style.container);
    },
    contentClass() {
      const { $style, size } = this;
      return this.toggleClass(this.$style.content, {
        [$style.medium]: size === 'medium',
        [$style.small]: size === 'small'
      });
    }
  },
  methods: {
    toggleClass(baseClass: string, additional?: any) {
      const { $style, value } = this;
      return Object.assign(
        {
          [baseClass]: true,
          [$style.shown]: value,
          [$style.hidden]: !value
        },
        additional
      );
    }
  }
};
