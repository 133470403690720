import axios from "axios"
import store from '@/store';
import { SSODeleteRequest, SSOPutRequest, SSOGetRequest, SSOPostReq } from '@/util/kpis';
export default {
  // getRoles() {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   return axios.get(store.state?.baseSSOUrl+'/api/getRoles?client_id='+client_id+'&client_secret='+client_secret,{
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getRoles() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,

    }
    const apiResponse = await SSOGetRequest(
      '/api/getRoles',
      {
        params: paramsData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // getPermissions() {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   return axios.get(store.state?.baseSSOUrl+'/api/getTenantPermissions?client_id='+client_id+'&client_secret='+client_secret,{
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Accept-Language': 'en',
  //       Authorization:
  //         "Bearer"+store.state?.token
  //     },
  //   })
  // },
  async getPermissions() {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    const paramsData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,

    }
    const apiResponse = await SSOGetRequest(
      '/api/getTenantPermissions',
      {
        params: paramsData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': 'en',
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // updateUserRole(userDetails) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let userData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "title": userDetails.title,
  //     "description": userDetails.description,
  //     "tenantIds": userDetails.tenantIds,
  //     "permissionsArray": userDetails.permissionsArray,
  //     "_id": userDetails.roleId,
  //     "selectedPeriod": userDetails.selectedPeriod,
  //     "comparePeriod": userDetails.comparePeriod,
  //     "allowLocationFilter": userDetails.locationFilterVisibility
  //   };
  //   return axios.put(store.state?.baseSSOUrl+'/api/updateRole', userData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async updateUserRole(userDetails) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let userData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "title": userDetails.title,
      "description": userDetails.description,
      "tenantIds": userDetails.tenantIds,
      "permissionsArray": userDetails.permissionsArray,
      "_id": userDetails.roleId,
      "selectedPeriod": userDetails.selectedPeriod,
      "comparePeriod": userDetails.comparePeriod,
      "allowLocationFilter": userDetails.locationFilterVisibility
    };
    const apiResponse = await SSOPutRequest(
      '/api/updateRole',userData,
      {
        // params: userData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // createRole(roleDetails) {
  //   let client_id =  store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let roleData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "title": roleDetails.title,
  //     "description": roleDetails.description,
  //     "permissionsArray": roleDetails.permissionsArray,
  //     "selectedPeriod": roleDetails.selectedPeriod,
  //     "comparePeriod": roleDetails.comparePeriod,
  //     "allowLocationFilter": roleDetails.locationFilterVisibility
  //   };
  //   return axios.post(store.state?.baseSSOUrl+'/api/createRole', roleData, {
  //     headers: {
  //       Authorization:
  //         "Bearer"+store.state?.token,
  //     },
  //   })
  // },
  async createRole(roleDetails) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let roleData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "title": roleDetails.title,
      "description": roleDetails.description,
      "permissionsArray": roleDetails.permissionsArray,
      "selectedPeriod": roleDetails.selectedPeriod,
      "comparePeriod": roleDetails.comparePeriod,
      "allowLocationFilter": roleDetails.locationFilterVisibility
    };
    const apiResponse = await SSOPostReq(
      '/api/createRole',roleData,
      {
        // params: roleData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  async deleteRole(roleId) {
    const storeState = store.state;
    const token = storeState.user.sessionClient.access_token
    let roleData = {
      client_id: storeState.user?.clientId,
      client_secret: storeState.user?.clientSecret,
      "_id": roleId
    };
    const apiResponse = await SSODeleteRequest(
      '/api/deleteRole', 
      {
        data:roleData,
        // params: roleData,
        headers: {
          Authorization: 'Bearer' + token
        }
      }
    );
    return apiResponse
  },
  // deleteRole(roleId) {
  //   let client_id = store.state?.clientId
  //   let client_secret = store.state?.clientSecret
  //   let roleData = {
  //     "client_id": client_id,
  //     "client_secret": client_secret,
  //     "_id": roleId
  //   };
  //   return axios.delete(store.state?.baseSSOUrl + '/api/deleteRole', {
  //     data: roleData,
  //     headers: {
  //       Authorization:
  //         "Bearer" + store.state?.token,
  //     },
  //   })
  // },
}