
const props = {
  scrollable: {
    type: Boolean,
    default: false
  }
};

const render = (h, context) => {
  const { $style, data, props, children } = context;
  const baseClass = {
    [$style.body]: true,
    [$style.scrollable]: props.scrollable
  };
  const options = {
    class: [baseClass, data.class]
  };
  return h('div', options, children);
};

export default {
  name: 'ModalBody',
  functional: true,
  props,
  render
};
