import HttpController from '../HttpController';
import { createBackendError } from '@/util/error';

export default class ProgressController extends HttpController {
  public async getProgress() {
    try {
      const res = await this.client.get('/onboarding-progresses');
      return res.data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async markAsCompleted(onboardingSubtaskId: string) {
    try {
      const { data } = await this.client.post('/onboarding-progresses', {
        onboardingSubtaskId
      });
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
