
import { mapGetters, mapActions, mapState } from 'vuex';
import { DUO_TONES, MONO_TONE } from '../../util/colors';
import { formatYAxis } from '../../util/legend';
import { kpiListQuese } from '@/util/mockData/kpiMapping';

export default {
  data() {
    return {
      kpi: {
        label: this.$t('BoxQueueTrendAnalysis.NumberOfAlerts'),
        value: 'Number Of Alerts'
      },
      getUnit: '',
      loading: false,
      selectedIcon: [],
      temperatures: [],
      weatherData: [],
      xaxisLabels: [],
      xaxisLabels2: [],
      kpiListOrder: [],
      kpiOptions: [],
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        stroke: {
          width: 1,
          curve: 'straight',
          dashArray: [0, 6]
        },
        title: {
          text: ''
        },
        colors: DUO_TONES,
        dataLabels: {
          enabled: true
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `    <div
      class="q-card q-pa-md tooltip-container flex column q-pa-md items-center"
    >
      <div class="flex justify-between">
        <label>${this.xaxisLabels[dataPointIndex]}</label>
        <div>
          <div class="flex items-center weather-tooltip">
            <i
              class="wi ${this.selectedIcon[dataPointIndex]}"
            ></i>
            <div class="q-pl-sm">${this.temperatures[dataPointIndex]} °C</div>
          </div>
        </div>
      </div>
      <div class="tooltip-title flex items-center">
        <div class="tooltip-title-color" style="background:${
          w.config.colors[0]
        }"></div> ${w.globals.seriesNames[0]}: ${
              series[0][dataPointIndex] ? series[0][dataPointIndex] : '0'
            }${this.getUnit}
      </div>
      <div class="q-mt-md flex justify-between">
        <label>${this.xaxisLabels2[dataPointIndex]}</label>
        <div>
          <div class="flex items-center weather-tooltip">
            <i
              class="wi ${this.selectedIcon2[dataPointIndex]}"
            ></i>
            <div class="q-pl-sm">${this.temperatures2[dataPointIndex]} °C</div>
          </div>
        </div>
      </div>
      <div class="flex items-center tooltip-title">
            <div class="tooltip-title-color" style="background:${
              w.config.colors[1]
            }"></div>
            ${w.globals.seriesNames[1]}: ${
              series[1][dataPointIndex] ? series[1][dataPointIndex] : '0'
            }${this.getUnit}
      </div>
    </div>`;
          }
        },
        labels: [],
        legend: {
          position: 'top',
          offsetY: 20,
          markers: {
            radius: 0,
            width: 16,
            height: 4,
            customHTML: function() {
              return `<style>
.apexcharts-legend-series:nth-child(1) .custom-marker{
    display:none;
}
</style>
                          <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:0;top:-2px;background:white"></div>
                        <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white"></div>`;
            }
          }
        },
        xaxis: {
          labels: {
            formatter: value => {
              return value + `(${this.getFullDay(value)})`;
            }
          }
        },
        yaxis: [
          {
            labels: {
              formatter: value => {
                return formatYAxis(value) + this.getUnit;
              }
            },
            title: {
              text: ''
            }
          },
          {
            opposite: true,
            show: false,
            title: {
              text: ''
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]),
    allKpiOption() {
      return this.kpiListOrder.map(kpi => ({
        label: this.$t(`BoxQueueTrendAnalysis.${kpi}`),
        value: kpi
      }));
    },
    ...mapState('queue', ['trendData', 'posID', 'floorID', 'storeID'])
  },
  watch: {
    async kpi() {
      this.loading = true;
      await this.loadData();
      if (this.kpi.value === 'Number Of Alerts') {
        this.getUnit = this.$t('BoxQueueTrendAnalysis.NumberOfAlertsUnit');
      } else if (this.kpi.value === 'Number Of Requests Taken') {
        this.getUnit = this.$t(
          'BoxQueueTrendAnalysis.NumberOfRequestsTakenUnit'
        );
      } else if (this.kpi.value === 'Time Before Taking Request') {
        this.getUnit = this.$t(
          'BoxQueueTrendAnalysis.TimeBeforeTakingRequestUnit'
        );
      } else if (this.kpi.value === 'Average Users In Line') {
        this.getUnit = this.$t('BoxQueueTrendAnalysis.AverageUsersInLineUnit');
      } else if (this.kpi.value === 'Average Waiting Time') {
        this.getUnit = this.$t('BoxQueueTrendAnalysis.AverageWaitingTimeUnit');
      } else if (this.kpi.value === 'Traffic In Line') {
        this.getUnit = this.$t('BoxQueueTrendAnalysis.TrafficInLineUnit');
      } else if (this.kpi.value === 'Request Taken Per Alert') {
        this.getUnit = this.$t(
          'BoxQueueTrendAnalysis.RequestTakenPerAlertUnit'
        );
      } else {
        this.getUnit = '';
      }
      this.loading = false;
    },
    async posID() {
      this.loading = true;
      await this.loadData();
      this.loading = false;
    },
    trendData() {
      this.weatherData = this.trendData
        ? this.trendData.selectedPeriod.map(v => v.weather)
        : [];
      this.selectedIcon = this.trendData
        ? this.trendData.selectedPeriod.map(v => v.weatherIcon)
        : [];
      this.temperatures = this.trendData
        ? this.trendData.selectedPeriod.map(v =>
            v.temperature ? v.temperature.toFixed(2) : '-'
          )
        : [];
      this.selectedIcon2 = this.trendData
        ? this.trendData.comparedPeriod.map(v => v.weatherIcon)
        : [];
      this.temperatures2 = this.trendData
        ? this.trendData.comparedPeriod.map(v =>
            v.temperature ? v.temperature.toFixed(2) : '-'
          )
        : [];
      this.series = [
        {
          name: `${this.kpi.label} (${this.$t(
            'BoxQueueTrendAnalysis.selectedPeriod'
          )})`,
          type: 'column',
          data:
            this.trendData && this.trendData.selectedPeriod
              ? this.trendData.selectedPeriod.map(val => val.value)
              : []
        },
        {
          name: `${this.kpi.label} (${this.$t(
            'BoxQueueTrendAnalysis.comparePeriod'
          )})`,
          type: 'line',
          data:
            this.trendData && this.trendData.comparedPeriod
              ? this.trendData.comparedPeriod.map(val => val.value)
              : []
        }
      ];
      this.loadChart();
    },
    kpiOptions(newValue) {
      this.kpi = newValue[0]?? {};
    }
  },
  created: async function() {
    this.kpiListOrder = [...kpiListQuese];
    this.kpiOptions = this.allKpiOption;
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('BoxQueueTrendAnalysis.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('BoxQueueTrendAnalysis.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('BoxQueueTrendAnalysis.title')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
    this.kpi = this.kpiOptions[0] ?? {};
    await this.loadData();
  },
  methods: {
    ...mapActions('queue', [
      'fetchQueueNotifierTrend',
      'fetchQueueNotifierTrendHourly'
    ]),
    loadChart() {
      this.xaxisLabels = [];
      this.xaxisLabels2 = [];
      if (this.getStartingPeriod !== this.getEndingPeriod) {
        this.xaxisLabels =
          this.trendData && this.trendData.selectedPeriod
            ? this.trendData.selectedPeriod.map(v =>
                this.getDateWithMonth(v.timeLabel)
              )
            : [];
        this.xaxisLabels2 =
          this.trendData && this.trendData.comparedPeriod
            ? this.trendData.comparedPeriod.map(v =>
                this.getDateWithMonth(v.timeLabel)
              )
            : [];
        this.chartOptions = {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: true,
              tools: {
                download: '<i class="fas fa-download"></i>'
              }
            }
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: ''
          },
          dataLabels: {
            enabled: false
          },
          labels: this.xaxisLabels,
          xaxis: {
            type: 'text'
          },
          yaxis: [
            {
              labels: {
                formatter: value => {
                  return value
                    ? value.toFixed(2) + this.getKPIUnit(this.kpi.value)
                    : '-';
                }
              },
              title: {
                text: ''
              }
            },
            {
              labels: {
                formatter: value => {
                  return value
                    ? value.toFixed(2) + this.getKPIUnit(this.kpi.value)
                    : '-';
                }
              },
              show: false,
              opposite: true,
              title: {
                text: ''
              }
            }
          ]
        };
      } else {
        this.xaxisLabels =
          this.trendData && this.trendData.selectedPeriod
            ? this.trendData.selectedPeriod.map(v => v.timeLabel + ':00')
            : [];
        this.xaxisLabels2 =
          this.trendData && this.trendData.comparedPeriod
            ? this.trendData.comparedPeriod.map(v => v.timeLabel + ':00')
            : [];
        this.chartOptions = {
          chart: {
            height: 350,
            type: 'line'
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: ''
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [1]
          },
          labels: this.xaxisLabels,
          xaxis: {
            type: 'text'
          },
          yaxis: [
            {
              title: {
                text: ''
              }
            },
            {
              opposite: true,
              title: {
                text: ''
              }
            }
          ]
        };
      }
    },
    async loadData() {
      if (this.getStartingPeriod !== this.getEndingPeriod) {
        await this.fetchQueueNotifierTrend({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          compareEndDate: this.getEndingCompared,
          kpis: 'all',
          posID: this.posID,
          daysOfWeek: this.getDaysOfWeekList,
          floorID: this.floorID,
          storeCodes: this.storeID,
          type: this.kpi.value
            .split(' ')
            .join('')
            .toLowerCase()
        });
      } else {
        await this.fetchQueueNotifierTrendHourly({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          compareEndDate: this.getEndingCompared,
          kpis: 'all',
          daysOfWeek: this.getDaysOfWeekList,
          posID: this.posID,
          floorID: this.floorID,
          storeCodes: this.storeID,
          type: this.kpi.value
            .split(' ')
            .join('')
            .toLowerCase()
        });
      }
    },
    getKPIUnit() {
      if (this.kpi.value === 'Number Of Alerts') {
        return this.$t('BoxQueueTrendAnalysis.NumberOfAlertsUnit');
      } else if (this.kpi.value === 'Number Of Requests Taken') {
        return this.$t('BoxQueueTrendAnalysis.NumberOfRequestsTakenUnit');
      } else if (this.kpi.value === 'Time Before Taking Request') {
        return this.$t('BoxQueueTrendAnalysis.TimeBeforeTakingRequestUnit');
      } else if (this.kpi.value === 'Average Users In Line') {
        return this.$t('BoxQueueTrendAnalysis.AverageUsersInLineUnit');
      } else if (this.kpi.value === 'Average Waiting Time') {
        return this.$t('BoxQueueTrendAnalysis.AverageWaitingTimeUnit');
      } else if (this.kpi.value === 'Traffic In Line') {
        return this.$t('BoxQueueTrendAnalysis.TrafficInLineUnit');
      } else if (this.kpi.value === 'Request Taken Per Alert') {
        return this.$t('BoxQueueTrendAnalysis.RequestTakenPerAlertUnit');
      } else {
        return '';
      }
    },
    getDateWithMonth(val) {
      return val + `(${this.getFullDay(val)})`;
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('BoxQueueTrendAnalysis.Sun');
      }
      if (dayVal === 1) {
        return this.$t('BoxQueueTrendAnalysis.Mon');
      }
      if (dayVal === 2) {
        return this.$t('BoxQueueTrendAnalysis.Tue');
      }
      if (dayVal === 3) {
        return this.$t('BoxQueueTrendAnalysis.Wed');
      }
      if (dayVal === 4) {
        return this.$t('BoxQueueTrendAnalysis.Thu');
      }
      if (dayVal === 5) {
        return this.$t('BoxQueueTrendAnalysis.Fri');
      }
      if (dayVal === 6) {
        return this.$t('BoxQueueTrendAnalysis.Sat');
      }
    }
  }
};
