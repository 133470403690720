import { PaginatedResponse } from '@/models/Pagination';
import { BaseStoreDTO, StoreDTO, StorePlanDTO } from '@/models/Store';
import { createBackendError, createBulkDeleteBackendError } from '@/util/error';
import { PaginationRequest } from '@/util/pagination';
import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';

export type GetStoresResponse = PaginatedResponse<StorePlanDTO>;

export interface CreateStoreRequest extends BaseStoreDTO {
  brand: {
    id: string;
  };
  city: {
    id: string;
  };
  storeType: {
    id: string;
  };
}

export type UpdateStoreRequest = CreateStoreRequest;

export type CreateStoreResponse = StoreDTO;

export interface BulkStoreImageUpdate {
  browserId: string;
  requests: {
    storeId: string;
    filePaths: string[];
  }[];
}

export default class StoreController {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  public async getStores(pagination: PaginationRequest) {
    try {
      const { data } = await this.client.get('/store', {
        params: {
          ...pagination
        }
      });
      return data as GetStoresResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getStore(id: string) {
    try {
      const { data } = await this.client.get(`/store/${id}`);
      return data as StoreDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async createStore(req: CreateStoreRequest) {
    try {
      const { data } = await this.client.post('/store', req);
      return data as CreateStoreResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateUserStore(userId: string, stores: string[]) {
    try {
      const data = await this.client.post(`/userStore/invite`, {
        userId,
        stores
      });
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getUserStore(userId: string) {
    try {
      const { data } = await this.client.get(`/userStore/${userId}`);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async updateStore(id: string, req: UpdateStoreRequest) {
    try {
      const { data } = await this.client.put(`/store/${id}`, req);
      return data as StoreDTO;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async deleteStore(ids: string[]) {
    try {
      const data = await this.client.delete('/store', {
        data: ids
      });

      createBulkDeleteBackendError(data);
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async uploadStoreImage(
    file: File,
    cancelToken?: CancelToken,
    onUploadProgress?: Function
  ) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const option = {
        cancelToken,
        onUploadProgress
      } as AxiosRequestConfig;

      const { data } = await this.client.post(
        '/store/upload-store',
        formData,
        option
      );
      return data as string;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async uploadStoreImageForBulkUpdate(
    storeId: string,
    file: File,
    sessionId: string,
    cancelToken?: CancelToken,
    onUploadProgress?: Function
  ) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('browserId', sessionId);

      const option = {
        cancelToken,
        onUploadProgress
      } as AxiosRequestConfig;

      const { data } = await this.client.post(
        `/store/upload-store/${storeId}`,
        formData,
        option
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async bulkUpdateStoreImage(req: BulkStoreImageUpdate) {
    try {
      const { data } = await this.client.post('/store/bulk-upload', req);
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getStoreImageAsBase64(identifier: string) {
    try {
      const { data } = await this.client.get(
        `/store/photo/base64?path=${identifier}`
      );
      return data;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async downloadFile(id: string): Promise<BlobPart> {
    try {
      const { data } = await this.client.get(`/store/download/${id}`);
      return data as BlobPart;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async getImportTemplate() {
    try {
      const { data } = await this.client.get('/store/import-store/template');
      return data as BlobPart;
    } catch (err) {
      throw createBackendError(err);
    }
  }

  public async importStores(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.client.post('/store/import-store', formData);
    return data;
  }
}
