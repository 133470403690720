
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      storesList: [],
      floorsList: [],
      posList: [],
      storeID: null,
      floorID: null,
      posID: null
    };
  }, // data
  computed: {
    ...mapState('queue', ['stores', 'floorData', 'posListByStore'])
  }, // computed
  watch: {
    stores() {
      if (this.stores && this.stores.length > 0) {
        this.storesList = this.stores.map(val => {
          return {
            label: val.storeName,
            value: val.storeID,
            description: val.storeCode
          };
        });
        this.storeID = this.storesList[0];
      }
    },
    floorData() {
      if (this.floorData) {
        this.floorsList = Object.values(this.floorData).map(val => {
          return {
            label: val.floorName,
            value: val.floorID,
            description: val.floorLevel
          };
        });
        this.floorsList.sort((x, y) => {
          return x.description - y.description;
        });
        this.floorID = this.floorsList[0];
        this.$store.commit('queue/setFloorID', this.floorID.value);
      }
    },
    posListByStore() {
      if (this.posListByStore) {
        this.posList = Object.values(this.posListByStore).map(val => {
          return {
            label: val.posName,
            value: val.posID
          };
        });
        this.posID = this.posList[0];
      }
    },
    async storeID() {
      this.$store.commit('queue/setStoreID', this.storeID.description);
      this.$store.commit('queue/setStoreData', this.storeID.value);
      await this.fetchFloorListByStore(this.storeID.value);
    },
    async floorID() {
      this.$store.commit('queue/setFloorID', this.floorID.value);
      await this.fetchPosListByStore(this.floorID.value);
    },
    posID() {
      this.$store.commit('queue/setPosID', this.posID.value);
    }
  },
  async created() {
    await this.fetchStoresList();
    if (this.storeID) {
      await this.fetchFloorListByStore(this.storeID.value);
    }
    if (this.floorID) {
      await this.fetchPosListByStore(this.floorID.value);
    }
  }, // created
  methods: {
    ...mapActions('queue', [
      'fetchStoresList',
      'fetchFloorListByStore',
      'fetchPosListByStore'
    ])
  } // methods
}; // export default
