
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue'
import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag);

export default {
  name: 'LangSwitch',
  props: {
    isDense: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    localLangLabel() {
      return this.getLocale === 'ja' ?  <span><country-flag country='jp' size='small'/> JPN</span>:  <span><country-flag country='us' size='small' title='ENG'/> ENG</span>;
    },
    getLocalLang() {
      return this.getLocale === 'ja' ? 'en' : 'ja';
    }
  },
  async created() {
    // if(localStorage.get('language')=='en')
    // {
    //   this.setupLocale('en');
    // } else{
    //   localStorage.set('language','ja');
    //   this.setupLocale('ja');
    // }

  }, // created
  methods: {
    ...mapMutations('user', ['setLocale']),
    setupLocale(locale) {
      this.setLocale({ locale, denyRefresh: true });
      this.$root.$i18n.locale = locale;
    } // setupLocale
  }
};
