
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin],
  props: {
    view: { type: Number, required: true },
    isAdmin: { type: Boolean, required: true },
    order: { type: Object, required: true }
  },
  methods: {
    formatDate(date?: Date) {
      return date.toLocaleString();
    }
  }
};
