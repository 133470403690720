
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  addMonths,
  addWeeks,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInDays
} from 'date-fns';
import moment from 'moment';

export default {
  data() {
    return {
      trendOrder: 4,
      currentTrend: '',
      loading: false,
      kpiOverview: [],
      customTitles: [
        'Sales',
        'Average Item Price',
        'Units Per Transaction',
        'Average Purchase',
        'Conversion',
        'Transactions',
        'Capture Rate',
        'Traffic',
        'Sales Per Tsubo',
        'Traffic Per Tsubo',
        'Passersby',
        'Store Dwell Time',
        'Engagement Rate',
        'Bounce Rate',
        // 'Facade Stops',
        'Staff Level',
        'Staff per hour',
        'Staff Efficiency',
        'Return on Visit',
        'Closing Strength'
      ],
      customSort: [
        'sales',
        'traffic',
        'conversion',
        'avgpurchase',
        'transactions',
        'avgitemprice',
        'upt',
        'rov',
        'salespersqm',
        'trafficpersqm',
        'bouncerate',
        'engagementrate',
        'staffhours',
        'staffefficiency',
        'closingstrength',
        'passersby',
        'capturerate',
        'storedwelltime'
        // 'facadestops'
      ]
    };
  }, // data
  computed: {
    ...mapState('overview', { kpis: 'kpis' }),
    ...mapState('periodicReport', ['selectedStoreCode', 'weeklyTarget']),
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseEndingPeriod',
      'getPulseStartingCompared',
      'getPulseEndingCompared',
      'getCustomCalenderState',
      'getPulseEndingCompared'
    ])
  },
  watch: {
    async selectedStoreCode() {
      await this.loadData();
      await this.loadTargetData();
    }
  },
  async created() {
    const kpiOverview = await fetchOverview({
      startDate: this.getPulseStartingPeriod,
      endDate: this.getPulseEndingPeriod,
      compareStartDate: this.getPulseStartingCompared,
      compareEndDate: this.getPulseEndingCompared,
      storeCodes: this.selectedStoreCode,
      kpis: this.selectedKPIOptions.join(','),
      daysOfWeek: this.getDaysOfWeekList,
      groupByMetricKey: true
    });
    this.kpiOverview = kpiOverview;
    // if (this.store) {
    //   await this.loadData();
    //   await this.loadTargetData();
    // }
  },
  methods: {
    ...mapActions('overview', ['fetchOverview']),
    ...mapActions('periodicReport', ['getTargetTableValueWeekly']),
    kpiSorter(x, y) {
      return (
        this.customTitles.indexOf(x.metricKey) -
        this.customTitles.indexOf(y.metricKey)
      );
    },
    async loadTargetData() {
      try {
        let prevWeek = moment(addWeeks(startOfWeek(new Date()), -1)).format(
          'YYYY-MM-DD'
        );
        let date = new Date();
        await this.getTargetTableValueWeekly({
          // * updates weeklyTarget
          startDate: moment(startOfWeek(new Date())).format('YYYY-MM-DD'),
          endDate: moment(date).format('YYYY-MM-DD'),
          compareStartDate: prevWeek,
          compareEndDate: moment(endOfWeek(new Date(prevWeek))).format(
            'YYYY-MM-DD'
          ),
          storeCodes: this.selectedStoreCode,
          type: 'weekly'
        });
      } catch (e) {
        // this.$q.notify({
        //   type: 'negative',
        //   position: 'top',
        //   message: `${e}`
        // });
      }
    },
    async loadData() {
      try {
        this.loading = true;
        const diffDays = differenceInDays(startOfMonth(new Date()), new Date());
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        await this.fetchOverview({
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
          compareStartDate: addWeeks(startOfMonth(new Date()), -weeks),
          compareEndDate: addWeeks(new Date(), -1),
          storeCodes: this.selectedStoreCode,
          daysOfWeek: this.getDaysOfWeekList,
          useCustomCalender: this.getCustomCalenderState,
          groupByMetricKey: true
        });
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
