
import { mapState, mapActions, mapGetters } from 'vuex';
import { addWeeks, startOfWeek } from 'date-fns';

export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    storecode: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function() {
    return {
      loading: false,
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          type: 'area',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: '<i class="fas fa-download"></i>',
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        colors: ['#1a3667', '#ccc'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'straight'
        },
        legend: {
          show: true,
          position: 'top',
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        },
        yaxis: {
          type: 'number',
          forceNiceScale: true,
          tickAmount: 4
        },
        xaxis: {
          categories: [],
          tooltip: { enabled: false }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const dataPointSelected =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[0] &&
              w.config.series[0].data[dataPointIndex]
                ? w.config.series[0].data[dataPointIndex]
                : '';
            const dataPointCompared =
              w.config && // w.config.series[0].data[dataPointIndex].x
              w.config.series &&
              w.config.series.length &&
              w.config.series[1] &&
              w.config.series[1].data[dataPointIndex]
                ? w.config.series[1].data[dataPointIndex]
                : '';

            return `
              <q-card class="flex column q-pa-md tooltip-container">
                <div class="flex justify-between">
                     <label>${
                       dataPointSelected.x ? dataPointSelected.x : '-'
                     }</label>
                        <div class="flex">
                        ${
                          this.getStoreCodesList.split(',').length === 1
                            ? `<div class="flex items-center justify-between weather-tooltip">
                                <i class="wi ${
                                  dataPointSelected.z?.weatherIcon
                                }"></i>
                                </div>
                                <div class="text-bold q-ml-sm">
                                  ${dataPointSelected.z?.temperature ?? '-'} °C
                                </div>`
                            : ''
                        }
                   </div>
                  </div>
                  <div class="flex tooltip-title items-center">
                  <div class="tooltip-title-color" style="background:${
                    w.config.colors[0]
                  }"></div>
                    <label>${this.$t('selected_period')}:</label>
                    <div class="q-pl-md">${
                      dataPointSelected.formattedValue
                        ? dataPointSelected.formattedValue
                        : '-'
                    }</div>
                  </div>
                <div class="flex justify-between"><label for="">
                ${dataPointCompared.x ? dataPointCompared.x : '-'}
                </label>
                <div class="flex">
                        ${
                          this.getStoreCodesList.split(',').length === 1
                            ? `<div class="flex items-center justify-between weather-tooltip">
                                <i class="wi ${
                                  dataPointCompared.z?.weatherIcon
                                }"></i>
                                </div>
                                <div class="q-ml-sm">
                                  ${dataPointCompared.z?.temperature ?? '-'} °C
                                </div>`
                            : ''
                        }
                   </div>
                </div>
                  <div class="flex tooltip-title items-center">
                  <div class="tooltip-title-color" style="background:${
                    w.config.colors[1]
                  }"></div>
                    <label>${this.$t('compared_period')}:</label>
                    <div class="q-pl-md">${
                      dataPointCompared.formattedValue
                        ? dataPointCompared.formattedValue
                        : '-'
                    }</div>
                  </div>
              </q-card>
            `;
          }
          // x: {
          //   format: 'dd/MM/yy HH:mm'
          // }
        }
      }
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getPulseStartingPeriod',
      'getPulseStartingCompared',
      'getCustomCalenderState'
    ]),
    ...mapState('trend', ['selectedPeriod', 'comparedPeriod'])
  },
  async created() {
    if (this.options.metricKey) {
      try {
        this.loading = true;
        let params = {
          startDate: this.getPulseStartingPeriod,
          endDate: this.getPulseStartingPeriod,
          compareStartDate: this.getPulseStartingCompared,
          compareEndDate: this.getPulseStartingCompared,
          storeCodes: this.storecode ? this.storecode : this.getStoreCodesList,
          daysOfWeek: this.getDaysOfWeekList,
          metricKey: this.options.metricKey,
          useCustomCalender: this.getCustomCalenderState
        };
        await this.fetchTrendHour(params);

        const selectedData = this.selectedPeriod.map(singlePoint => {
          return {
            x: singlePoint.timeLabel + ':00',

            y: singlePoint.value.toFixed(2),
            z: {
              weatherIcon: singlePoint.weatherIcon,
              temperature: singlePoint.temperature.toFixed(2)
            },
            formattedValue: singlePoint.formattedValue
          };
        });

        const comparedData = this.comparedPeriod.map(singlePoint => {
          return {
            x: singlePoint.timeLabel + ':00',
            y: singlePoint.value.toFixed(2),
            z: {
              weatherIcon: singlePoint.weatherIcon,
              temperature: singlePoint.temperature.toFixed(2)
            },
            formattedValue: singlePoint.formattedValue
          };
        });
        let data = [
          {
            name: this.$t('selected_period'),
            data: selectedData
          },
          {
            name: this.$t('compared_period'),
            data: comparedData
          }
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: comparedData.map(v => v.x),
            tooltip: { enabled: false }
          }
        };

        this.series = [...data];
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      } finally {
        this.loading = false;
      } // finally
    } // if (this.options.metricKey)
  }, // created
  methods: {
    ...mapActions('trend', ['fetchTrend', 'fetchTrendHour']),
    getUnits() {
      if (val === this.$t('sales')) {
        return '円';
      }
      if (val === this.$t('traffic')) {
        return '人';
      }
      if (val === this.$t('transactions')) {
        return '人';
      }
      if (val === this.$t('conversion')) {
        return '%';
      }
      if (val === this.$t('averagesale')) {
        return '円';
      }
      if (val === this.$t('averagepurchase')) {
        return '円';
      }
      if (val === this.$t('upt')) {
        return '点';
      }
      if (val === this.$t('rov')) {
        return '円';
      }
      if (val === this.$t('averageitemprice')) {
        return '円';
      }
      if (val === this.$t('salespersqm')) {
        return '円';
      }
      if (val === this.$t('trafficpersqm')) {
        return '人';
      }
      if (val === this.$t('stafflevel')) {
        return '人';
      }
      if (val === this.$t('staffhours')) {
        return '人';
      }
      if (val === this.$t('closingstrength')) {
        return '人';
      }
      if (val === this.$t('staffefficiency')) {
        return '円';
      }
      if (val === this.$t('passersby')) {
        return '人';
      }
      if (val === this.$t('capturerate')) {
        return '%';
      }
      // if (val === this.$t('facadestops')) {
      //   return '人';
      // }
      if (val === this.$t('storedwelltime')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('repeatrate')) {
        return '-';
      }
      if (val === this.$t('engagementrate')) {
        return '％';
      }
      if (val === this.$t('bouncerate')) {
        return '％';
      }
      if (val === this.$t('gendercomparison')) {
        return '％';
      }
      if (val === this.$t('age')) {
        return '％';
      }
      if (val === this.$t('fittingroomvisits')) {
        return '人';
      }
      if (val === this.$t('fittingroom %')) {
        return '％';
      }
      if (val === this.$t('fittingroomconversion %')) {
        return '％';
      }
      if (val === this.$t('fittingroomdwelltime')) {
        return 'x 時間 y分 z秒';
      }
      if (val === this.$t('areapassersby')) {
        return '人';
      }
      if (val === this.$t('areastops')) {
        return '人';
      }
    },
    getFullDay(val) {
      let dayVal = new Date(val).getDay();
      if (dayVal === 0) {
        return this.$t('Sun');
      }
      if (dayVal === 1) {
        return this.$t('Mon');
      }
      if (dayVal === 2) {
        return this.$t('Tue');
      }
      if (dayVal === 3) {
        return this.$t('Wed');
      }
      if (dayVal === 4) {
        return this.$t('Thu');
      }
      if (dayVal === 5) {
        return this.$t('Fri');
      }
      if (dayVal === 6) {
        return this.$t('Sat');
      }
    }
  } // methods
}; // export default
