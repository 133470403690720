import Entity from '@/models/Entity';

export const initialDefaultSettings = (): DefaultSettings =>
  new DefaultSettings({
    bounceRateThreshold: 'SECONDS',
    engagementRateThreshold: 'SECONDS',
    fiscalCalendarStart: 'JANUARY',
    headquartersComparePeriods: 'PREVIOUS_PERIOD',
    headquartersSelectPeriods: 'LAST_WEEK',
    id: '0000000-0000-0000-0000-000000000001',
    locationFilter: false,
    allowAdvertiseItems: false,
    monthlyEmailReportSendDay: 'MONDAY',
    monthlyEmailReportSendTime: '23:00:00',
    storeComparePeriods: 'PREVIOUS_PERIOD',
    storeFloorAreaUnit: 'TSUBO',
    storeSelectPeriods: 'THIS_MONTH',
    weekStartDay: 'MONDAY',
    weeklyEmailReportSendDay: 'MONDAY',
    weeklyEmailReportSendTime: '23:00:00'
  });

class DefaultSettings {
  id?: string;
  storeFloorAreaUnit: string;

  headquartersSelectPeriods: string;
  headquartersComparePeriods: string;
  storeSelectPeriods: string;
  storeComparePeriods: string;
  locationFilter: boolean;
  allowAdvertiseItems: boolean;

  engagementRateThreshold: string;
  bounceRateThreshold: string;

  weekStartDay: string;
  fiscalCalendarStart: string;

  weeklyEmailReportSendDay: string;
  weeklyEmailReportSendTime: string;
  monthlyEmailReportSendDay: string;
  monthlyEmailReportSendTime: string;

  constructor(initializer: Partial<DefaultSettings>) {
    Object.assign(this, initializer);
  }

  static fromDTO(dto: DefaultSettingsDTO): DefaultSettings {
    return new DefaultSettings({
      id: dto.id,
      storeFloorAreaUnit: dto.storeFloorAreaUnit,
      headquartersSelectPeriods: dto.headquartersSelectPeriods,
      headquartersComparePeriods: dto.headquartersComparePeriods,
      storeSelectPeriods: dto.storeSelectPeriods,
      storeComparePeriods: dto.storeComparePeriods,
      locationFilter: dto.locationFilter,
      allowAdvertiseItems: dto.allowAdvertiseItems,
      engagementRateThreshold: dto.engagementRateThreshold,
      bounceRateThreshold: dto.bounceRateThreshold,
      weekStartDay: dto.weekStartDay,
      fiscalCalendarStart: dto.fiscalCalendarStart,
      weeklyEmailReportSendDay: dto.weeklyEmailReportSendDay,
      weeklyEmailReportSendTime: dto.weeklyEmailReportSendTime,
      monthlyEmailReportSendDay: dto.monthlyEmailReportSendDay,
      monthlyEmailReportSendTime: dto.monthlyEmailReportSendTime
    });
  }

  toDTO(): DefaultSettingsDTO {
    return {
      id: this.id,
      name: null,
      storeFloorAreaUnit: this.storeFloorAreaUnit,
      headquartersSelectPeriods: this.headquartersSelectPeriods,
      headquartersComparePeriods: this.headquartersComparePeriods,
      storeSelectPeriods: this.storeSelectPeriods,
      storeComparePeriods: this.storeComparePeriods,
      locationFilter: this.locationFilter,
      allowAdvertiseItems: this.allowAdvertiseItems,
      engagementRateThreshold: this.engagementRateThreshold,
      bounceRateThreshold: this.bounceRateThreshold,
      weekStartDay: this.weekStartDay,
      fiscalCalendarStart: this.fiscalCalendarStart,
      weeklyEmailReportSendDay: this.weeklyEmailReportSendDay,
      weeklyEmailReportSendTime: this.weeklyEmailReportSendTime,
      monthlyEmailReportSendDay: this.monthlyEmailReportSendDay,
      monthlyEmailReportSendTime: this.monthlyEmailReportSendTime
    };
  }
}

export interface DefaultSettingsDTO extends Entity {
  storeFloorAreaUnit: string;

  headquartersSelectPeriods: string;
  headquartersComparePeriods: string;
  storeSelectPeriods: string;
  storeComparePeriods: string;
  locationFilter: boolean;
  allowAdvertiseItems: boolean;

  engagementRateThreshold: string;
  bounceRateThreshold: string;

  weekStartDay: string;
  fiscalCalendarStart: string;

  weeklyEmailReportSendDay: string;
  weeklyEmailReportSendTime: string;
  monthlyEmailReportSendDay: string;
  monthlyEmailReportSendTime: string;
}

export default DefaultSettings;
