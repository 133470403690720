import HttpController from '../HttpController';
import Section from '@/models/onboarding/Section';
import { createBackendError } from '@/util/error';

export interface GetSectionsResponse {
  content: Section[];
}

export default class SectionController extends HttpController {
  public async getSections() {
    try {
      const { data } = await this.client.get(
        '/console/onboarding-sections/module=core'
      );
      return data as GetSectionsResponse;
    } catch (err) {
      throw createBackendError(err);
    }
  }
}
