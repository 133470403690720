
import { PostRequest, GetRequest } from '@/util/http';
import { fetchClass } from '@/plugins/fetch';
import { mapState, mapActions, mapMutations } from 'vuex';
import { APIFetchStores } from '@/api/store';
import FilterStoreCampaign from './FilterStoreCampaign';

const columnsFile = [
  {
    name: 'Name',
    required: true,
    label: 'File',
    align: 'left',
    field: name,
    sortable: true
  },
  { name: 'date', align: 'left', label: 'Date Uploaded', field: 'date', sortable: true },
  { name: 'download', label: 'Download', align: 'center' },
]

const rowsFile = [
  {
    file: 'File 1',
    date: '2021/05/01',
  },
  {
    file: 'File 2',
    date: '2022/05/01',
  }
]

// const searchResult = [
//   {
//     label: 'Store 1',
//           value: '1'
//   },
//   {
//     label: 'Store 2',
//           value: '2'
//   },
//   {
//     label: 'Store 3',
//           value: '3'
//   }
// ]


export default {
  components: { FilterStoreCampaign },
  props: {
    editCampaign: {
      type: String,
      default: 'warning'
    }
  },

  data () {
    return {
      campaignList:[],
      campaign: {
        id: 0,
        campaignName: '',
        campaignDescription: '',
        campaignPeriod: '',
        Cat: '',
        week: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
        filterStore:  [],
        Badget: '',
        file:{
          name:'',
          filename: '',
          dateUpload:'',
          path:''
        },
        markedOptions: '',
      },
      categoryOption: [],
      rowsStore: [],
      columnsStore: [
        {
          name: 'code',
          required: true,
          label: 'Code',
          align: 'left',
          field: row => row.code,
          sortable: true
        },
        { name: 'name', align: 'left', label: 'Store name', field: 'name', sortable: true },
        { name: 'delete', label: 'Delete', align: 'center' },
      ],
      columnsFile,
      rowsFile,

      searchResult: [
        { label: 'Store 1', value: '1' },
        { label: 'Store 2', value: '2' },
        { label: 'Store 3', value: '3' }
      ],

      dialogVisible: false,
      dialogStoreList: [],
      selectedStores: [],

    };
  },
  watch: {
    selectedStores(newVal) {
      this.rowsStore = newVal;
    }
  },

  async created() {

    this.dialogStoreList = this.searchResult;
  GetRequest('http://localhost:3000/categories',{},{ headers: null })
        .then(response => response)
        .then(data => {
          this.categoryOption = data
        });
  if(this.editCampaign.id > 0) {

      this.campaign.id = this.editCampaign.id;
      this.campaign.campaignName = this.editCampaign.campaignName;
      this.campaign.campaignDescription = this.editCampaign.campaignDescription;
      this.campaign.campaignPeriod = this.editCampaign.campaignPeriod;
      // this.campaign.campaignPeriod.start = this.editCampaign.campaignPeriod.start;
      // this.campaign.campaignPeriod.end = this.editCampaign.campaignPeriod.end;
      this.campaign.Cat = this.editCampaign.Cat;
      this.campaign.week = this.editCampaign.week;
      this.campaign.filterStore = this.editCampaign.filterStore;
      this.campaign.Badget = this.editCampaign.Badget;
      this.campaign.file = this.editCampaign.file;

    }
  else {
    GetRequest('http://localhost:3000/campaigns',{},{ headers: null })
      .then(response => response)
      .then(data => {
        this.campaignList = data
      });

    }

    //  const storesList = await APIFetchStores();
    //  this.searchResult = storesList.data.stores.map(store => {
    //           return {
    //             label: store.storeCode + ' -  ' + store.name,
    //             value: store.storeCode
    //           };
    //         });

  },
compute:{

  // searchResult() {
  //     if (this.search) {
  //       return this.options?.filter(
  //         option =>
  //           option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
  //       );
  //     } else {
  //       return this.options;
  //     }
  //   },
},
 mounted() {

this.rowsFile = this.campaign.file;

  },
  methods: {
    refreshPage() {
      this.$emit('refreshPage');
    },
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },

    onDialogHide () {
      this.$emit('hide')
    },
    onApplyClick() {
      this.selectedStores = this.campaign.markedOptions.map(option => {
        const store = this.searchResult.find(store => store.value === option);
        return { code: store.value, name: store.label };
      });
      this.dialogVisible = false;
    },
   async onOKClick () {
    if(this.campaign.id > 0) {
      const newcampaignList = {
          id: this.campaign.id,
          campaignName: this.campaign.campaignName,
          campaignDescription: this.campaign.campaignDescription,
          campaignPeriod: this.campaign.campaignPeriod,
          Cat: this.campaign.Cat,
          week: this.campaign.week,
          filterStore: this.campaign.filterStore,
          Badget: this.campaign.Badget,
          file: this.campaign.file,
        }
      await fetchClass(
        'http://localhost:3000/campaigns/'+this.campaign.id,
          {
            method: 'PUT',
            body: newcampaignList
          },{ headers: null }
        );
    }
      else
      {
        const id = this.campaignList.length > 0 ? this.campaignList[this.campaignList.length - 1].id + 1 : 1
        const newcampaignList = {
          id,
          campaignName: this.campaign.campaignName,
          campaignDescription: this.campaign.campaignDescription,
          campaignPeriod: this.campaign.campaignPeriod,
          Cat: this.campaign.Cat,
          week: this.campaign.week,
          filterStore: this.campaign.filterStore,
          Badget: this.campaign.Badget,
          file: this.campaign.file,
        }
        this.campaignList.push(newcampaignList);
        await PostRequest('http://localhost:3000/campaigns', newcampaignList, { headers: null })
      }
      this.campaign = {
        id: 0,
        campaignName: '',
        campaignDescription: '',
        campaignPeriod: '',
        Cat: '',
        week: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
        filterStore:  [],
        Badget: '',
        file:{
          filename:'',
          dateUpload:'',
          path:''
        }
      };

      this.$emit('ok')
      this.hide()

    },

    onCancelClick () {
      this.hide()
    },
    uploadFile () {
      this.$refs.file.pickFiles();
    }
  }
}
