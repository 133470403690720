import { PostRequest } from '@/util/http';

export function APIFetchUsersByLine(params: any) {
  return PostRequest('users/line', params);
}
export function APIFetchUsers(params: any) {
  return PostRequest('users', params);
}
export function APIFetchUserGroup(params: any) {
  return PostRequest('userGroups', params);
}

export function APIFetchUserGroupHierarchy(params: any) {
  return PostRequest('userGroups/hierarchy', params);
}
export function APIAddAlert(params: any) {
  return PostRequest('alertSetting/add', params);
}
export function APIFetchAllAlertsOverview(params: any) {
  return PostRequest('activeAlertSettings', params);
}

export function APIFetchAlertByID(params: any, alertID) {
  return PostRequest(`alertSetting/${alertID}`, params);
}
export function APIUpdateAlertByID(params: any, alertID) {
  return PostRequest(
    `alertSetting/update/${alertID}
`,
    params
  );
}

export function APIChangeAlertStatus(params: any, alertID) {
  return PostRequest(
    `alertSetting/changeStatus/${alertID}
`,
    params
  );
}

export function APIDeleteAlert(params: any, alertID) {
  return PostRequest(
    `alertSetting/delete/${alertID}

`,
    params
  );
}
