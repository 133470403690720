import { map } from 'lodash';
import AreaManager, { AreaManagerDTO } from '../AreaManager';
import { convertStoreDTOAreaManager } from './StoreFormatter';

export const convertFromAreaManagerDTO = (dto: AreaManagerDTO): AreaManager => {
  return {
    id: dto.id,
    title: dto.title,
    description: '',
    storeAreaManagerIdToStoreId: dto.storeAreaManagerIdToStoreId,
    stores: dto.storeAreaManagers.map(v =>
      convertStoreDTOAreaManager(v.vdStore, null)
    )
  };
};
