
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import clone from 'lodash/clone';

import AuthMixin from '../../../../mixins/AuthMixin';
import CommonMixin from '../../../../mixins/CommonMixin';
import { handleErrorUI } from '../../../../util/error';
import countryCodes from 'country-codes-list';

export default {
  mixins: [AuthMixin, CommonMixin],
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }, // props
  data() {
    return {
      isHide: false
    };
  }, // data
  computed: {
    countryCodesList() {
      return Object.keys(
        countryCodes.customList('countryCallingCode', '[{countryCallingCode}]')
      ).map(v => '+' + v);
    }
  },
  methods: {
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    togglePassword() {
      this.isHide = !this.isHide;
    },
    validate() {
      return this.$v.value.$invalid;
    },
    sync() {
      this.loading = true;
      this.disable = true;

      this.$v.value.$touch();

      try {
        if (!this.$v.value.$invalid) {
          this.$emit('input', clone(this.value));
        }
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
        this.disable = false;
        this.$v.value.$reset();
      } // finally
    }
  },
  validations: {
    value: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      username: {
        required
      },
      phone: {
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password')
      }
    }
  }
};
