// Vue
import Vue from 'vue';
import App from '@/App.vue';

import '@/plugins/axios.ts';
import '@/plugins/quasar.ts';
import '@/plugins/apexchart.ts';
import '@/plugins/vue-google-maps';
import '@/plugins/vcalendar.ts';
import '@/plugins/vee-validate.js';
import '@/plugins/intercom.ts';
import '@/plugins/fontawesome.ts';
import '@/plugins/VueCompositionApi.js';
import '@/plugins/mixpanel';

import '@/registerServiceWorker';
import '@/components/_autoloader';

// Repositories
import repositories from '@/repositories';

// Router
import router from '@/router';

// Services
import services from '@/services';

// Analytics
import '@/plugins/vue-analytics';
import i18n from '@/plugins/i18n';
import store from './store/';

// HTTP client manager
import clientManager from './util/client';
import { checkAuthorities } from './util/navigation';

// Vuelidate
import Vuelidate from 'vuelidate';
import { Notify } from 'quasar';
import Gleap from 'gleap';


Vue.config.productionTip = false;

Vue.client = clientManager;
Vue.repo = repositories;
Vue.svc = services;

Vue.prototype.$client = clientManager;
Vue.prototype.$repo = repositories;
Vue.prototype.$svc = services;

Vue.use(Vuelidate);
Gleap.initialize("qmRKPo0kbg04hFHWqRZ09sEG4IDefawp");
// const userLanguage = 'ja'; // Example: 'en', 'fr', 'de', etc.
// Gleap.setLanguage(userLanguage);
async function sessionFetcher() {
  const session = await repositories.session.fetch();
  if (session) {
    clientManager.setSession(session);
  }
}
//
// router.beforeEach(async (to, from, next) => {
//   const session = await repositories.session.fetch();
//   if (!store.getters['user/isAuthenticated']) {
//     if (to.name.startsWith('auth')) {
//       next();
//       return;
//     } else {
//       next({ name: 'auth:login' });
//       return;
//     }
//   } else if (session) {
//     if (to.name.startsWith('auth')) {
//       next({ name: 'home' });
//       return;
//     }
//   }
//
//   if (
//     !store.getters['user/isAuthenticated'] &&
//     to.meta.requiresAuth !== false
//   ) {
//     if (store.getters['user/getRefreshToken']) {
//       await store.dispatch('user/reAuthenticate');
//       await store.dispatch('comparison/fetchCompareDates');
//       await store.dispatch('comparison/fetchMarketingCampaign');
//     }
//
//     if (!store.getters['user/isAuthenticated']) {
//       next({ name: 'auth:login' });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach(async (to, from, next) => {
  const session = await repositories.session.fetch();
  next();
  if (!session || !store.getters['user/isAuthenticated']) {
    if (to.name.startsWith('auth') || to.name.startsWith('lead-calculator')) {
      next();
      return;
    } else {
      next({ name: 'auth:login' });
      return;
    }
  } else if (session) {
    if (to.name.startsWith('auth')) {
      next({ name: 'home' });
      return;
    }
  }

  if (
    !store.getters['user/isAuthenticated'] &&
    to.meta.requiresAuth !== false
  ) {
    if (store.getters['user/getRefreshToken']) {
      await store.dispatch('user/reAuthenticate');
      await store.dispatch('comparison/fetchCompareDates');
      // await store.dispatch('comparison/fetchMarketingCampaign');
    }

    if (!store.getters['user/isAuthenticated']) {
      store.dispatch('user/clearSession');
      store.dispatch('tenant/reset');
      localStorage.clear();
      next('/auth/login');
      store.dispatch('tenant/reset');
      Vue.prototype.$intercom.shutdown();
    } else {
      next();
    }

    next();
  } else {
    next();
  }

  if (!to.meta || !to.meta.authorityCodes) {
    next();
    return;
  }

  const authorityCodes: string[] = to.meta.authorityCodes;
  if (await checkAuthorities(authorityCodes)) {
    next();
  } else {
    next({ name: 'error' });
  }
});

sessionFetcher().then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
