
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import {
  reportMenu,
  VMDMenu,
  toolsMenu,
  academyMenu,
  settingMenu
} from '../../util/mockData/menuList';
import { addWeeks, startOfWeek } from 'date-fns';

export default {
  name: 'ContainerHeader',
  data() {
    return {

      releaseShow: false,
      accessibleModules: {},
      showNews: false,
      showVMD: false,
      showTools: false,
      showSetting: true,
      accessibleReportMenu: [],
      accessibleVMDMenu: [],
      accessibleToolsMenu: [],
      accessibleAcademyMenu: [],
      accessibleSettingMenu: []
    };
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['getLocale', 'isAuthenticated'])
  },
  watch: {
    profile: function(newValue) {
      this.accessibleModules = this.profile.accessibleModules;
    }
  },
  created() {
    this.generateMenu(reportMenu, 'accessibleReportMenu');
    this.generateMenu(VMDMenu, 'accessibleVMDMenu');
    this.generateMenu(toolsMenu, 'accessibleToolsMenu');
    this.generateMenu(academyMenu, 'accessibleAcademyMenu');
    this.generateMenu(settingMenu, 'accessibleSettingMenu');
  },
  mounted() {

    if (this.isAuthenticated) {
      if (localStorage.getItem('storeCodes')) {
        this.setStoreCodes({
          storeCodes: localStorage.getItem('storeCodes').split(',')
        });
      }
      this.setRetailCalendar({
        dates: JSON.parse(localStorage.getItem('retailCalendar'))
      });
      this.setStartingPeriod({
        startingPeriod: localStorage.getItem('startingPeriod')
      });
      this.setEndingPeriod({
        endingPeriod: localStorage.getItem('endingPeriod')
      });
      this.setStartingCompared({
        startingCompared: localStorage.getItem('startingCompared')
      });
      this.setEndingCompared({
        endingCompared: localStorage.getItem('endingCompared')
      });
      this.setLibraryStartingPeriod({
        startingPeriod:
          localStorage.getItem('startingLibraryPeriod') ??
          startOfWeek(new Date())
      });
      this.setLibraryEndingPeriod({
        endingPeriod: localStorage.getItem('endingLibraryPeriod') ?? new Date()
      });
      this.setLibraryStartingCompared({
        startingCompared:
          localStorage.getItem('startingLibraryCompared') ??
          addWeeks(startOfWeek(new Date()), -1)
      });
      this.setLibraryEndingCompared({
        endingCompared:
          localStorage.getItem('endingLibraryCompared') ??
          addWeeks(new Date(), -1)
      });
      this.setCustomCalenderFlag({
        endingCompared: localStorage.getItem('useCustomCalender')
      });

      let selectedKPI = localStorage.getItem('selectedTrafficKPI');
      this.getLocalStoreKPI(selectedKPI !== undefined ? selectedKPI : []);
      this.$intercom.boot({
        user_id: this.profile?.userID,
        name: `${this.profile?.firstName} ${this.profile?.lastName}`,
        email: this.profile?.emailID,
        user_hash: this.profile?.userHash,
        language_override: this.profile?.languageOverride,
        company: {
          company_id: this.profile?.company?.companyId,
          name: this.profile?.company.companyName
        },
        accessibleModules: this.profile?.accessibleModules
      });
      this.accessibleModules = this.profile?.accessibleModules;
    }
  },
  methods: {
    ...mapMutations('user', ['setLocale']),
    ...mapMutations('distribution', ['getLocalStoreKPI']),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapMutations('marketplace', [
      'setLibraryStartingPeriod',
      'setLibraryEndingPeriod',
      'setLibraryStartingCompared',
      'setLibraryEndingCompared'
    ]),
    ...mapActions('user', ['clearSession']),
    signout() {
      this.clearSession();
      this.$intercom.shutdown();
      this.$router.push({ name: 'login' });
    }, // signout
    backToV2() {
      window.open('https://staging-ta.flow-insight.com', '_blank');
    },

    redirectTo(routeName) {
      if (routeName) {
        this.$router.push({ name: routeName });
      } else {
        return;
      }
    },
    generateMenu(menuList, accessibleMenuOptions) {
      //console.log(this.profile, menuList, accessibleMenuOptions);
      const accessibleMenu =
        Object.keys(menuList).map(reportKey => {
          if (this.profile?.accessibleModules[reportKey]?.enabled) {
            return menuList[reportKey];
          }
        }) || [];
      this[accessibleMenuOptions] = accessibleMenu.filter(Boolean);
    },
    setupLocale(locale) {
      this.setLocale({ locale });
    }
  }
};
