
import { mapMutations, mapState } from 'vuex';
import {APICreatePreferences} from '@/api/kpi';
export default {
  props: {
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    radarChartSelected: {
      type: Boolean,
      default: false
    },
    comparison: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      markedOptions: [],
      kpiListOrder: [],
      pageUrl:''
    };
  },
  computed: {
    ...mapState('filter', ['radarChartKpiOptions']),
    ...mapState('user', ['kpiLabelValue']),

    searchResult() {
      if (this.search) {
        return this.options.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return [...this.options].map(kpi => {
          for(var j=0;j<this.markedOptions.length;j++)
          {
             if(kpi.label.includes('-')){
              kpi.label = kpi.label.split('- ')[1];
             }
          }

          for(var i=0;i<this.markedOptions.length;i++)
            if(kpi.value == this.markedOptions[i])
            {
              if(!kpi.label.includes('-')){
              var counter= i+1;
              kpi.label = counter+'- ' + kpi.label;
              }
            }
        return { label: kpi.label, value: kpi.value };
        });
      }
    },
    options() {
      return this.kpiListOrder.map(kpi => ({
        label: kpi.label,
        value: kpi.value
      }));
    }
  },
  watch: {
    markedOptions(newValue) {

      this.setRadarChartKpiOptions({ kpiOptions: newValue });
    },
    selectedOptions(newValue) {
      this.markedOptions = newValue;
    }
  },
  async created() {
    this.pageUrl = window.location.pathname;
    this.kpiListOrder = [...this.kpiLabelValue];
    // this.kpiListOrder = [...this.kpiLabelValue, {label:this.$store.state.user.translate.target_achievement_rate, value:'targetAchive'}];
    this.markedOptions = this.selectedOptions;
  },
  methods: {
    ...mapMutations('distribution', [
      'setPersistentKPI',
      'setPersistentComparisonKPI'
    ]),
   async submit() {
      if (this.markedOptions.length < 4 && this.kpiLabelValue.length>=4) {
        return false;
      }
      if (this.comparison) {
        this.selectedOptions = this.markedOptions;
        this.setPersistentComparisonKPI(this.markedOptions);
      }
      if (this.radarChartSelected) {
        this.selectedOptions = this.markedOptions;
        this.setPersistentKPI(this.markedOptions);
      } else {
        this.selectedOptions = this.markedOptions;
      }
      this.$emit('update:selectedOptions', this.markedOptions);
      var urlPage = window.location.pathname;
      await APICreatePreferences(urlPage+'/kpi',this.markedOptions);
      this.hide();
    },
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterKPI.hide();
    },
    ...mapMutations('filter', ['setRadarChartKpiOptions'])
  }
};
