
import { createColumn } from '@/util/table';
import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'CustomerTable',
  mixins: [PaginationMixin],
  props: {
    data: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      columns: [
        createColumn(
          'firstname',
          this.$t('SettingsManagement.firstname') as string
        ),
        createColumn(
          'lastname',
          this.$t('SettingsManagement.lastname') as string
        ),
        createColumn(
          'phone',
          this.$t('SettingsManagement.phonenumber') as string
        ),
        createColumn(
          'companyName',
          this.$t('SettingsManagement.companyName') as string
        ),
        createColumn('email', this.$t('SettingsManagement.email') as string)
      ]
    };
  }
};
