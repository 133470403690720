// replaceText.ts
import store from '../store';
export function replaceText(inputKpi: string): string {
  let replacedKpi = inputKpi;

  if (inputKpi === 'trafficOut') {
    replacedKpi = 'traffic';
  }
  if (inputKpi === 'totalItemSold') {
    replacedKpi = 'unitssold';
  }
  if (inputKpi === 'passerby') {
    replacedKpi = 'passersby';
  }
  return replacedKpi;
}

export function addSign(inputKpi: string, value: string): string {
  const result = getSignsLeftSide(inputKpi) + value +  getSignsRightSide(inputKpi);
  return result;
}

export function getSignsLeftSide(inputKpi: string): string {
  let sign = '';
  const lang = store.getters['user/getLocale'];
  if(lang.toLowerCase() == 'en' ||lang.toLowerCase() == 'en-us')
  {
    if (inputKpi == 'sls' || inputKpi == 'ap' || inputKpi == 'spt'
    || inputKpi == 'rov' || inputKpi == 'aip' || inputKpi == 'se'|| inputKpi == 'st' || inputKpi == '') {
      sign = '¥';
    }
  }
  // else
  // {
  //   if (inputKpi == 'sls' || inputKpi == 'ap' || inputKpi == 'spt'
  //   || inputKpi == 'rov' || inputKpi == 'aip' || inputKpi == 'se' || inputKpi == '') {
  //     sign = '円';
  //   }
  // }
  return sign;
}

export function getSignsRightSide(inputKpi: string): string {
  let sign = '';
  const lang = store.getters['user/getLocale'];
  
  if(lang.toLowerCase() == 'en' ||lang.toLowerCase() == 'en-us')
  {
    if (inputKpi == 'cn' || inputKpi == 'cr' ) {
      sign = '%';
    } else if(inputKpi == 'tra') { //Just to match old Prod otherwise % should not have any space
      sign = '%';
    }
  }
  else
  {
    if (inputKpi == 'cn' || inputKpi == 'cr' || inputKpi == 'tra') {
      sign = '%';
    } else if (inputKpi == 'trf' || inputKpi == 'tpt' ||inputKpi == 'pb' || inputKpi == 'sl' || inputKpi == 'sh') {
      sign = '人';
    } else if(inputKpi == 'upt' || inputKpi == 'is') {
      sign = '点';
    } else if(inputKpi == 'trn' || inputKpi == 'cs') {
      sign = '件';
    } else if (inputKpi == 'sls' || inputKpi == 'ap' || inputKpi == 'spt'
    || inputKpi == 'rov' || inputKpi == 'aip' || inputKpi == 'se' || inputKpi == 'st' || inputKpi == '' ) {
      sign = '円';
    }
  }
  return sign;
}
export function getAllSigns(inputKpi: string): string {
  let sign = ' ';
  const lang = store.getters['user/getLocale'];
  
  if(lang.toLowerCase() == 'en' ||lang.toLowerCase() == 'en-us')
  {
    if (inputKpi == 'cn' ||inputKpi == 'cr' ) {
      sign = ' (%)';
    }
    if (inputKpi == 'sls' || inputKpi == 'ap' || inputKpi == 'spt'
    || inputKpi == 'rov' || inputKpi == 'aip' || inputKpi == 'se'|| inputKpi == '') {
      sign = '(¥)';
    }
  }
  else
  {
    if (inputKpi == 'cn' || inputKpi == 'cr' || inputKpi == 'tra') {
      sign = ' (%)';
    } else if (inputKpi == 'trf' || inputKpi == 'tpt' ||inputKpi == 'pb' || inputKpi == 'sl' || inputKpi == 'sh') {
      sign = ' (人)';
    } else if(inputKpi == 'upt' || inputKpi == 'is') {
      sign = ' (点)';
    } else if(inputKpi == 'trn' || inputKpi == 'cs') {
      sign = ' (件)';
    } else if (inputKpi == 'sls' || inputKpi == 'ap' || inputKpi == 'spt'
    || inputKpi == 'rov' || inputKpi == 'aip' || inputKpi == 'se' || inputKpi == '') {
      sign = ' (円)';
    }
  }
  return sign;
}
