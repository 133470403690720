import { render, staticRenderFns } from "./hiddenModel.vue?vue&type=template&id=c55f3d90&"
import script from "./hiddenModel.vue?vue&type=script&lang=js&"
export * from "./hiddenModel.vue?vue&type=script&lang=js&"
import style0 from "./hiddenModel.vue?vue&type=style&index=0&id=c55f3d90&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QForm,QRadio,QItemLabel,QCheckbox,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QCardActions,QInnerLoading,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QForm,QRadio,QItemLabel,QCheckbox,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect,QCardActions,QInnerLoading,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
