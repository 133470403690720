
export default {
  name: 'LoginTabs',
  props: {
    tabLabels: {
      type: Array,
      default: () => []
    },
    selectedIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    labelClass(index) {
      const { $style, isSelected } = this;
      return {
        [$style.label]: true,
        [$style.selected]: isSelected(index)
      };
    },
    indicatorClass(index) {
      const { $style, isSelected } = this;
      return {
        [$style.indicator]: true,
        [$style.selected]: isSelected(index)
      };
    },
    isSelected(index) {
      return index === this.selectedIndex;
    },
    handleClick(index) {
      this.$emit('tab-selected', index);
    }
  }
};
