import { APIFetchRSSInsightNews } from '@/api/insightNews';

const initialState = {
  rss: null
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setRss(state, { targets }) {
      state.targets = targets;
    } // setTargets
  }, // mutations
  actions: {
    async fetchRSSNewsFeed({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await APIFetchRSSInsightNews({});
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    } // fetchTargets
  } // actions
}; // export default
