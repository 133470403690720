
export default {
  // props: {
  //   openModel: Boolean,
  // },
  data() {
    return {
      loading: false,
      selectedKPI: 'sls',
      options: [
        {
          label: "Sales",
          value: 'sales'
        },
        {
          label: "Sales Target",
          value: 'sales-target'
        },
        {
          label: "Traffic",
          value: 'traffic'
        },
        {
          label: "Staff Hours",
          value: 'staff-hours'
        }
      ],
      selectedSalesMappingTemplate: null,
      salesMappingTemplates: [],
      dataAccessibleColumns: [
        {
          name: "no",
          align: "center",
          label: "#",
          field: "no",
          sortable: true,
        },
        {
          name: "fileName",
          align: "left",
          label: "File Name",
          field: "fileName",
          sortable: true,
        },
        { name: "status", label: "Status", field: "status" },
        { name: "action", label: "Action", field: "action" },
      ],

      dataAccessibleRows: [
        {
          no: 1,
          fileName: "20210101012022",
          status: "Ok",
          action: "Delete",
        },
        {
          no: 1,
          fileName: "20210101012022",
          status: "Ok",
          action: "Delete",
        },
        {
          no: 1,
          fileName: "20210101012022",
          status: "Ok",
          action: "Delete",
        },
      ],
    }
  },
  watch: {
    async selectedKPI() {
      await this.fetchSalesMappingTemplate();
    }
  },
  async mounted() {
    await this.fetchSalesMappingTemplate();
  },
  methods: {
    async fetchSalesMappingTemplate() {
      const { data } = await this.$repo.extension.getSalesTemplates(
        this.selectedKPI
      );
      this.salesMappingTemplates = data.map(salesMapping => ({
        label: salesMapping.mappingName,
        value: salesMapping.id
      }));
      this.selectedSalesMappingTemplate = this.salesMappingTemplates[0];
    },
    removeStore() {
      this.loading = true;
      setTimeout(() => {
        const index = Math.floor(
          Math.random() * this.dataAccessibleRows.value.length
        );
        this.dataAccessibleRows.value = [
          ...this.dataAccessibleRows.value.slice(0, index),
          ...this.dataAccessibleRows.value.slice(index + 1),
        ];
        this.loading = false;
      }, 500);
    },
  }
};
