
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  addDays,
  addWeeks,
  addMonths,
  differenceInDays,
  format,
  getDay
} from 'date-fns';
import moment from 'moment';
import {getDayDiffFromMonday, getStartDayOfWeek} from '@/util/calendar.js'

export default {
  props: {
    setdatefilter: {
      required: false,
      type: Function,
      default: () => {}
    },
    selectedRange: {
      type: Object,
      required: false,
      default: () => {}
    } // selectedRange
  },
  data() {
    return {
      attrs: [
        {
          key: 'Holidays',
          highlight: true,
          popover: {
            label: ''
          },
          dates: []
        }
      ],
      calenderMode: 'range',
      dragValue: null,
      selectedPeriodState: false,
      selectedDateRange: [],
      dateOptionsSelectedModel: {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      },
      dateOptionsComparedModel: {
        label: this.$t('previous_period'),
        value: 'PREVIOUS_PERIOD'
      },
      marketingCampaignMode: false,
      marketingCampaignOption: null,
      marketingCampaignOptionList: [],
      dateOptionsSelected: [
        {
          label: this.$t('today'),
          value: 'TODAY'
        },
        {
          label: this.$t('yesterday'),
          value: 'YESTERDAY'
        },
        {
          label: this.$t('this_week'),
          value: 'THIS_WEEK'
        },
        {
          label: this.$t('last_week'),
          value: 'LAST_WEEK'
        },
		  {
          label: this.$t('this_month'),
          value: 'THIS_MONTH'
        },
        {
          label: this.$t('last_month'),
          value: 'LAST_MONTH'
        },
        {
          label: this.$t('this_year'),
          value: 'THIS_YEAR'
        },
        {
          label: this.$t('last_year'),
          value: 'LAST_YEAR'
        },

        {
          label: this.$t('custom_range'),
          value: 'CUSTOM',
          disable: true
        } // {
        //   label: this.$t('marketing_campaigns'),
        //   value: 'marketing_campaigns'
        // }
      ],
	  
      dateOptionsCompared: [
        {
          label: this.$t('previous_period'),
          value: 'PREVIOUS_PERIOD'
        },
        {
          label: this.$t('previous_year_same_period'),
          value: 'PREVIOUS_YEAR_SAME_PERIOD'
        }
        // {
        //   label: this.$t('special_calendar'),
        //   value: 'tutuanna_calender'
        // }
      ],
      selectedPeriod: {
        start: new Date(), // Jan 16th, 2018
        end: new Date() // Jan 19th, 2018
      },
      comparedPeriod: {
        start: addDays(new Date(), -1), // Jan 16th, 2018
        end: addDays(new Date(), -1) // Jan 19th, 2018
      },
      lastTimeStamp: Date.now(),
      startDayFromMonday: 0,
      startDayOfWeek: 1,
    };
  }, // data
  computed: {
    ...mapState('user', ['profile', 'startOfWeek', 'defaultSettings']),
    ...mapGetters('user', ['getLocale']),
    ...mapState('filter', ['filter']),
    ...mapState('store', ['stores']),
    ...mapState('comparison', ['marketingCampaign', 'compareDates']),
    // ...mapState('comparison', []),
    tutuannaMode() {
      return this.dateOptionsComparedModel.value === 'tutuanna_calender';
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: this.tutuannaMode ? 'hover' : 'none',
          isInteractive: false // Defaults to true when using slot
        }
      };
    },

    dragComparedValue: function() {
      let value = '';
      if (this.dragValue && this.compareDates) {
        value = this.getComparedDate(this.formatDate(this.dragValue));
        return this.formatDate(value);
      } else {
        return value;
      }
    }
  }, // computed
  watch: {
    marketingCampaign() {
      if (this.marketingCampaign && this.marketingCampaign.length > 0) {
        this.dateOptionsSelected = [
          ...this.dateOptionsSelected,
          {
            label: this.$t('marketing_campaigns'),
            value: 'marketing_campaigns'
          }
        ];
      }
    },
    holidays() {
      this.attrs = [
        ...this.holidays.map(v => ({
          key: 'Holidays',
          // highlight: true,
          popover: {
            label: v.value,
            labelClass: 'white-text',
            labelStyle: { color: 'white' },
            class: 'white-text'
          },
          dates: v.date,
          bar: {
            color: 'red',
            class: 'my-dot-class'
          }
        }))
      ];
    },
    'filter.daysOfWeek': {
      handler: 'refreshPage'
    },
    selectedPeriod: function() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    },
    dateOptionsSelectedModel() {
      this.getSelectedDateRange();
      if (this.tutuannaMode) {
        this.comparedPeriod = this.selectedDateRange.map(
          dat => new Date(this.getComparedDate(dat))
        );
      } else {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    },
    marketingCampaignOption: function() {
      if (!this.tutuannaMode && this.marketingCampaignMode) {
        if (this.dateOptionsComparedModel.value === 'PREVIOUS_PERIOD') {
          const diffDays = differenceInDays(
            this.selectedPeriod.start,
            this.selectedPeriod.end
          );
          const diffDaysPositive =
            diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
          const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
          this.comparedPeriod.start = addWeeks(
            this.selectedPeriod.start,
            -weeks
          );
          this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
        } else if (
          this.dateOptionsComparedModel.value === 'PREVIOUS_YEAR_SAME_PERIOD'
        ) {
          this.comparedPeriod.start = addYears(
            new Date(this.selectedPeriod.start),
            -1
          );
          this.comparedPeriod.end = addYears(
            new Date(this.selectedPeriod.end),
            -1
          );
        }
      }
    }
  },
  async created() {
    
    this.firstTimeCheck()
    this.startDayFromMonday = getDayDiffFromMonday();
    this.startDayOfWeek = getStartDayOfWeek();
    
    this.$set(this.selectedPeriod, "start", new Date(this.filter.startingPeriod))
    this.$set(this.selectedPeriod, "end", new Date(this.filter.endingPeriod))
    this.$set(this.comparedPeriod, "start", new Date(this.filter.startingCompared))
    this.$set(this.comparedPeriod, "end", new Date(this.filter.endingCompared))
    
    this.dateOptionsSelected?.forEach((data) => {
      if (data.value == this.filter.selectedOption) {
        this.$set(this.dateOptionsSelectedModel, "value", data.value)
        this.$set(this.dateOptionsSelectedModel, "label", data.label)
        this.$set(this.dateOptionsSelectedModel, "disable", data.disable || false)
        this.dateOptionsSelectedModelChanged(data.value);
      }
    })
    if(this.filter.selectedOption == "CUSTOM_RANGE"|| this.filter.selectedOption == "CUSTOM") {
      this.onDayClick()
    }
    this.dateOptionsCompared?.forEach((data) => {
      if (data.value == this.filter.comparedOption) {
        this.$set(this.dateOptionsComparedModel, "value", data.value)
        this.$set(this.dateOptionsComparedModel, "label", data.label)
        this.$set(this.dateOptionsComparedModel, "disable", data.disable || false)
        this.dateOptionsComparedModelChanged(data.value);
      }
    })
    if(this.filter.comparedOption == "CUSTOM") {
      this.onDayClickCompare()
    }
    await this.fetchCompareDates();
    await this.fetchMarketingCampaign();
    this.getSelectedDateRange();
    this.marketingCampaignOptionList = this.marketingCampaign
      ? this.marketingCampaign.map(mc => {
          return {
            label: mc.campaignName,
            value: {
              startDate: mc.startDate,
              endDate: mc.endDate
            }
          };
        })
      : [];

    this.$emit('updated', {
      start: this.formatDate(this.selectedPeriod.start),
      end: this.formatDate(this.selectedPeriod.end)
    });
    this.setSelectedPeriod({
      selectedPeriod: this.dateOptionsSelectedModel
    });
    
    
    this.setCalendarDatesToStore({selectedOption: this.dateOptionsSelectedModel.value, selectedPeriod: this.selectedPeriod})
  }, // created
  methods: {
    getSelectedDateRange() {
      let dates = [];
      let startDate = this.selectedPeriod.start;
      while (startDate <= this.selectedPeriod.end) {
        dates.push(startDate);
        startDate = addDays(startDate, 1);
      }
      this.selectedDateRange = dates.map(dat => this.formatDate(dat));
      this.dateOptionsSelected.map(data => {
        if (data.value == this.defaultSettings.storeSelectPeriods) {
          if (this.dateOptionsSelectedModel == null) {
            this.dateOptionsSelectedModel = data.label;
          }
        }
      });
    },
    dateOptionsSelectedModelChanged() {
      const selectedOption = this.dateOptionsSelectedModel?.value;
      this.marketingCampaignMode = false;
      if (selectedOption === 'TODAY') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = new Date();
        this.selectedPeriod.end = new Date();
      } else if (selectedOption === 'YESTERDAY') {
        this.selectedPeriod = {};
        const yesterday = startOfYesterday();
        this.selectedPeriod.start = yesterday;
        this.selectedPeriod.end = yesterday;
      } else if (selectedOption === 'THIS_WEEK') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfWeek(new Date(), { weekStartsOn: this.startDayOfWeek === -1 ? 0: this.startDayOfWeek });
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfWeek(new Date());
      } else if (selectedOption === "LAST_WEEK") {
        this.selectedPeriod = {};
        const d = addWeeks(new Date(), -1);
        this.selectedPeriod.start = startOfWeek(d, { weekStartsOn: this.startDayOfWeek === -1 ? 0: this.startDayOfWeek });
        this.selectedPeriod.end = addDays(this.selectedPeriod.start, 6)
      } else if (selectedOption === 'THIS_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfMonth(new Date());
      } else if (selectedOption === 'LAST_MONTH') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfMonth(addMonths(new Date(), -1));
        this.selectedPeriod.end = endOfMonth(addMonths(new Date(), -1));
      } else if (selectedOption === 'THIS_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = startOfYear(new Date());
        this.selectedPeriod.end = new Date();
        // this.selectedPeriod.end = endOfYear(new Date());
      } else if (selectedOption === 'LAST_YEAR') {
        this.selectedPeriod = {};
        this.selectedPeriod.start = addYears(startOfYear(new Date()), -1);
        this.selectedPeriod.end = addYears(endOfYear(new Date()), -1);
      } else if (selectedOption === 'marketing_campaigns') {
        this.marketingCampaignMode = true;
      }
      this.dateOptionsComparedModelChanged();
    },
    dateOptionsComparedModelChanged(res) {
      const comparedOption = this.dateOptionsComparedModel?.value
      this.specialCalenderMode = false;
      this.calenderMode = 'range';
      if (comparedOption === 'PREVIOUS_PERIOD') {
        this.comparedPeriod = {};

        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        const diffDaysPositive =
          diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
        const weeks = Math.ceil((diffDaysPositive / 7).toFixed(2));
        this.comparedPeriod.start = addWeeks(this.selectedPeriod.start, -weeks);
        this.comparedPeriod.end = addWeeks(this.selectedPeriod.end, -weeks);
      } else if (comparedOption === 'PREVIOUS_YEAR_SAME_PERIOD') {
        this.comparedPeriod = {};
        const diffDays = differenceInDays(
          this.selectedPeriod.start,
          this.selectedPeriod.end
        );
        this.comparedPeriod.start = addDays(
          new Date(this.selectedPeriod.start),
          -365
        );
        this.comparedPeriod.end = addDays(
          new Date(this.selectedPeriod.end),
          -365
        );
      } else if (comparedOption === 'tutuanna_calender') {
        this.specialCalenderMode = true;
        this.calenderMode = 'multiple';
        this.getComparedDatesArray();
      }
    },
    getComparedDatesArray() {
      this.comparedPeriod = this.selectedDateRange.map(
        dat => new Date(this.getComparedDate(dat))
      );
    },
    marketingCampaignOptionChange() {
      let val = this.marketingCampaignOption?.value;
      this.selectedPeriod = {};
      this.selectedPeriod = {
        start: new Date(val.startDate),
        end: new Date(val.endDate)
      };
    },
    ...mapMutations('filter', [
      'setCalendarDatesToStore',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setSelectedPeriod',
      'setCustomCalenderFlag',
      'firstTimeCheck'
    ]), // mapMutations
    ...mapActions('comparison', [
      'fetchCompareDates',
      'fetchMarketingCampaign'
    ]),
    onDayClick() {
      this.dateOptionsSelectedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
    },
    onDayClickCompare() {
      this.dateOptionsComparedModel = {
        label: this.$t('custom_range'),
        value: 'CUSTOM',
        disable: true
      };
    },
    startingPeriodChanged(val) {
      this.setStartingPeriod({ startingPeriod: val });
    }, // startingPeriodChanged
    endingPeriodChanged(val) {
      this.setEndingPeriod({ endingPeriod: val });
    }, // endingPeriodChanged
    startingComparedChanged(val) {
      this.setStartingCompared({ startingCompared: val });
    }, // startingComparedChanged
    endingComparedChanged(val) {
      this.setEndingCompared({ endingCompared: val });
    }, // endingComparedChanged
    refreshPage() {
      this.$emit('refreshPage');
    }, // refreshPage
    submitDate() {

      this.$emit('updated', {
        start: this.formatDate(this.selectedPeriod.start),
        end: this.formatDate(this.selectedPeriod.end)
      });

      this.setCalendarDatesToStore({selectedOption: this.dateOptionsSelectedModel?.value, selectedPeriod: this.selectedPeriod})
      
      this.setSelectedPeriod({
        selectedPeriod: this.dateOptionsSelectedModel
      });
      // if (!this.tutuannaMode) {
      //   this.setStartingCompared({
      //     startingCompared: format(this.comparedPeriod.start, 'yyyy-MM-dd')
      //   });
      //   this.setEndingCompared({
      //     endingCompared: format(this.comparedPeriod.end, 'yyyy-MM-dd')
      //   });
      // }
      this.setCustomCalenderFlag({ value: this.tutuannaMode });

      this.hide();
      this.refreshPage();
    }, // submitDate
    hide() {
      this.$refs.periodFilter.hide();
    },
    formatDate(date) {
      if (!date) return null;
      let newDate = format(date, 'yyyy-MM-dd');
      const [year, month, day] = newDate.split('-');
      return `${year}-${month}-${day}`;
    },
    getFormattedDate(date) {
      return moment(new Date(date)).format("YYYY/MM/DD");
    },
    getComparedDate(date) {
      if (date && this.compareDates) {
        let dateObj = this.compareDates.filter(dat => dat.date === date);
        if (dateObj.length > 0) {
          return new Date(dateObj[0].comparedDate);
        } else {
          return '';
        }
      }
    },
    dropDownToggled() {
      // setTimeout(() => {
      //   document.querySelector(".calender-card .vc-weeks .vc-highlight.vc-bg-blue-600")?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      // }, 1000);
    },
    getDayDiffFromMonday() {
      const weekday = ["friday","saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"];
      let i = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
      this.startDayFromMonday = i - 3;
      const indexes_of_weekday = [0,1,2,3,4,5,6];
    },
    getStartDayOfWeek() {
      const weekday = ["sunday","monday", "tuesday", "wednesday", "thursday","friday","saturday"];
      this.startDayOfWeek = weekday.indexOf(this?.defaultSettings?.weekStartDay?.toLowerCase())
    },
  } // methods
}; // export default
