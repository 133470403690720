
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    selectedOptions: {
      type: String,
      required: false,
      default: 'store'
    }
  },
  data() {
    return {
      search: '',
      markedOptions: 'store'
    };
  },
  computed: {
    ...mapState('filter', ['radarChartKpiOptions']),
    searchResult() {
      if (this.search) {
        return this.options.filter(
          option =>
            option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.options;
      }
    },
    options() {
      return [
        { label: 'Region', value: 'region' },
        { label: 'District', value: 'district' },
        { label: 'Store', value: 'store' },
        { label: 'Peer Group', value: 'peerGroup' },
        { label: 'SV', value: 'sv' }
      ];
    }
  },
  // watch: {
  //   markedOptions(newValue) {
  //     this.setRadarChartKpiOptions({ kpiOptions: newValue });
  //   }
  // },
  // created() {
  //   this.markedOptions = this.selectedOptions;
  // },
  methods: {
    // ...mapMutations('distribution', ['setPersistentKPI']),
    submit() {
      // if (this.markedOptions.length < 4) {
      //   return false;
      // }
      // if (this.radarChartSelected) {
      //   this.selectedOptions = this.markedOptions;
      //   this.setPersistentKPI(this.markedOptions);
      // } else {
      //   this.selectedOptions = this.markedOptions;
      // }
      // this.$emit('update:selectedOptions', this.markedOptions);

      this.$emit('updated', markedOptions);

      this.hide();
    },
    hide() {
      this.markedOptions = this.selectedOptions;
      this.$refs.filterKPI.hide();
    }
    // ...mapMutations('filter', ['setRadarChartKpiOptions'])
  }
};
