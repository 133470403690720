
import { QDate } from 'quasar';
import Field from './Field';

export default {
  components: {
    Field,
    QDate
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    mask: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    options: {
      type: [Array, Function],
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    haserror: {
      type: Boolean,
      default: false
    },
    dateReadonly: {
      type: Boolean,
      default: false
    },
    startingDate: {
      type: [String, Number],
      default: ''
    },
  },
  data: () => ({
    focus: false
  }),
  computed: {
    inputClass() {
      const { $style, focus, haserror } = this;
      return {
        [$style.input]: true,
        [$style.focus]: focus && !haserror,
        'has-error': haserror
      };
    },
    inputFieldMask() {
      const shouldBeNumbers = ['Y', 'M', 'D'];
      const { mask: datePickerMask } = this;
      let mask = datePickerMask;
      shouldBeNumbers.forEach(char => {
        const regex = new RegExp(char, 'gi');
        mask = mask.replace(regex, '#');
      });
      return mask;
    }
  },
  created() {
    // console.log("this.startingDate", this.startingDate)
  },
  methods: {
    handleFocus() {
      this.focus = true;
    },
    handleBlur() {
      this.focus = false;
    }
  },
};
