export const props = {
  to: {
    type: [String, Object],
    required: true
  },
  outline: {
    type: Boolean,
    default: false
  }
};
