
import { mapState, mapGetters } from 'vuex';
import { addDays } from 'date-fns';
import moment from 'moment';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('periodicReport', ['filterFormState', 'dailyReportList']),
    ...mapGetters('filter', ['getPulseStartingPeriod'])
  },
  methods: {}
};
