
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      mode: 1,
      showContactModal: false,
      planSelected: '',
      integration: {
        label: 'Integration',
        items: [
          {
            label: 'POS',
            type: [
              { label: 'Basic', essential: '✔', pro: '✔', enterprise: '✔' },
              {
                label: 'Granular',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Sales Targets',
            type: [
              {
                label: 'Basic_Sales',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Granular_Sales',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Staff Shifts',
            type: [
              {
                label: 'Basic_Staff',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Granular_Staff',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Reviews',
            type: [
              {
                label: 'Google Maps',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              }
            ]
          },
          {
            label: 'Weather',
            type: [
              {
                label: 'Weather API',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Location Attributes',
            type: [
              {
                label: 'Location Name',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Location Brand',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Location Address',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Floor Area (sqm or tsubo)',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Area Manager',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Peer Groups',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Country',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'District',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Region',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Developer',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Portfolio',
                essential: 'X',
                pro: 'X',
                enterprise: 'X'
              },

              {
                label: 'Building',
                essential: 'X',
                pro: 'X',
                enterprise: 'X'
              },

              {
                label: 'Floor',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },

              {
                label: 'Zone',
                essential: 'X',
                pro: 'X',
                enterprise: 'X'
              },

              {
                label: 'Open Hour Exception Mgt',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },

              {
                label: 'Custom Store Number Length',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              }
            ]
          },
          // {
          //   label: 'Flow GO',
          //   type: [
          //     {
          //       label: 'Manual Counting',
          //       essential: '✔',
          //       pro: '✔',
          //       enterprise: '✔'
          //     },
          //     {
          //       label: 'Knowledge Base',
          //       essential: 'X',
          //       pro: 'X',
          //       enterprise: '✔'
          //     }
          //   ]
          // },
          // {
          //   label: 'Flow Learning',
          //   type: [
          //     {
          //       label: 'Flow Academy e-learning',
          //       essential: '✔',
          //       pro: '✔',
          //       enterprise: '✔'
          //     },
          //     {
          //       label: 'Flow Library',
          //       essential: 'X',
          //       pro: 'X',
          //       enterprise: '✔'
          //     }
          //   ]
          // },
          // {
          //   label: 'Available IoT Integrations',
          //   essential: '✔',
          //   pro: '✔',
          //   enterprise: '✔'
          // },
          {
            label: 'High Spec People Counters',
            type: [
              {
                label: 'Traffic',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Occupancy',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Passerby',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Queue Monitoring',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Fitting Rooms',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Tracking',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'IP Cameras',
            type: [
              {
                label: 'Traffic',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Occupancy',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Passerby',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Queue Monitoring',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Demographics',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Fitting Rooms',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Repeats',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Masks',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Cross Shopping',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Path Tracking',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              }
            ]
          },
          {
            label: 'WiFi',
            type: [
              {
                label: 'Passerby',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Dwell Time',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Bounce Rate',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Engagement Rate',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Staff Location (Floor Level)',
                essential: 'X',
                pro: 'X',
                enterprise: 'Option'
              },
              {
                label: 'Staff Identity (Floor Level)',
                essential: 'X',
                pro: 'X',
                enterprise: 'Option'
              }
            ]
          }
        ]
      },
      usage: {
        label: 'Usage',
        items: [
          {
            label: 'Flow GO',
            type: [
              {
                label: 'Manual Counting',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Knowledge Base',
                essential: 'Coming Soon - 1,500 yen',
                pro: 'Coming Soon - 1,500 yen',
                enterprise: 'Coming Soon'
              },
              {
                label: 'FlowGo Chat',
                essential: '10,000 active messages',
                pro: 'Unlimited',
                enterprise: 'Unlimited'
              },
              {
                label: 'FlowGo KPI Trends',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              // {
              //   label: 'Flow GO Manual Counts',
              //   essential: '✔',
              //   pro: '✔',
              //   enterprise: '✔'
              // },
              {
                label: 'Flow GO Occupancy App',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Flow Learning',
            type: [
              {
                label: 'Flow Academy e-learning',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Flow Library',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Platform Support',
            type: [
              {
                label: 'Intercom',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Email',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Phone',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              },
              {
                label: 'Response Time',
                essential: '48 Business hours (M-F)',
                pro: '24 Business hours (M-F)',
                enterprise: '24 Business hours (M-F)'
              }
            ]
          },
          {
            label: 'IoT Support',
            type: [
              {
                label: 'IoT Hardware Monitoring',
                essential: '2,500 yen',
                pro: '2,500 yen',
                enterprise: '2,500 yen'
              },
              {
                label: 'Data Monitoring',
                essential: '2,500 yen',
                pro: '2,500 yen',
                enterprise: '2,500 yen'
              },
              {
                label: 'Data Imputation',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Service Level Agreement',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              }
            ]
          }
        ]
      },
      viewableReports: {
        label: 'Viewable Reports',
        items: [
          {
            label: 'Standard Reports',
            type: [
              {
                label: 'Retail Dashboard',
                feature: [
                  {
                    label: 'Traffic  Prediction',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Target Achievement',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Sales Prediction',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'KPI Trends',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Location Comparison',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Detailed Report',
                feature: [
                  {
                    label: 'Radar Analysis',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Trend Table',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'KPI Analysis',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Traffic Report',
                feature: [
                  {
                    label: 'Traffic Overview',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Traffic Trends',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Power Hours',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Entrance Analysis',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Occupancy Report',
                feature: [
                  {
                    label: 'Location Overview',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Real-time Widget Manager',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Current Occupancy',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Hourly Trend',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Peak Times',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Daily Trends',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Shop Pulse',
                feature: [
                  {
                    label: 'Daily ',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Weekly',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Monthly',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              }
            ]
          },
          {
            label: 'Advanced Retail Reports',
            type: [
              {
                label: 'Queue Report',
                feature: [
                  {
                    label: 'KPI Analysis',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Peak Times by Floor',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Peak Times by Line',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Trend Analysis',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Detailed Table',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Staff Performance',
                feature: [
                  {
                    label: 'Staff Overview',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'KPI Comparison(Staff hours vs KPIs)',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Scheduler',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'COL/Sales Trends',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Staff Performance',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  }
                ]
              },
              {
                label: 'Location Report',
                feature: [
                  {
                    label: 'Location Comparison',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Radar Analysis',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'COL/Sales Trends',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Net Reputation Score',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  },
                  {
                    label: 'Sentiment Analysis',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  },
                  {
                    label: 'Aggregated Review Scores',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  }
                ]
              },
              {
                label: 'Demographics Report',
                feature: [
                  {
                    label: 'Demographics Overview',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Task Recommendations',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  },
                  {
                    label: 'Demographic groups profiles',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  },
                  {
                    label: 'Product Category',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  },
                  {
                    label: 'Demographic Trends',
                    essential: 'X',
                    pro: 'Coming Soon',
                    enterprise: 'Coming Soon'
                  }
                ]
              },
              {
                label: 'Product Category Report',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Layout Performance Report',
                feature: [
                  {
                    label: 'Zone Traffic',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Zone Stops',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Zone Dwell Time',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Fixture Traffic ',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Fixture Stops',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Fixture Dwell Time',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  },
                  {
                    label: 'Heatmap',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  }
                ]
              }
            ]
          },
          {
            label: 'Spaces Advanced Reports',
            type: [
              {
                label: 'Spaces Dashboard',
                feature: [
                  {
                    label: 'Safety Score',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Seat/Desk Usage',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Density',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Real-time Occupancy Level',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Utilization Trend',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  }
                ]
              },
              {
                label: 'Location Analysis',
                feature: [
                  {
                    label: 'Utilization Trend',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Utilization Table',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  }
                ]
              },
              {
                label: 'Meeting Room Analysis',
                feature: [
                  {
                    label: 'Attendance Report',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Density Analysis',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Usage Trends',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  },
                  {
                    label: 'Meeting room usage',
                    essential: 'X',
                    pro: 'X',
                    enterprise: 'X'
                  }
                ]
              }
            ]
          }
        ]
      },
      botsAndAlerts: {
        label: 'Bots and Alerts',
        items: [
          {
            label: 'Bot',
            type: [
              {
                label: 'Queue Alerts',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Occupancy Alerts',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'If/Then Alerts',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'ML TaskBot',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              }
            ]
          }
        ]
      },
      importExport: {
        label: 'Import/Export',
        items: [
          {
            label: 'Data Import',
            type: [
              {
                label: 'API Import',
                essential: '',
                pro: '',
                enterprise: ''
              },
              {
                label: 'FTP Import',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Manual Import via GUI',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Import Logs',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              },
              {
                label: 'FlowGo Upload',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              }
            ]
          },
          {
            label: 'Data Import',
            type: [
              {
                label: 'Filter',
                feature: [
                  {
                    label: 'Custom KPI Filter',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Custom Date Filter',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Custom Sorting',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Export file format',
                feature: [
                  {
                    label: 'Summary',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Hourly Total',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Daily',
                    essential: '✔',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Detailed',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'All Locations Daily',
                    essential: 'X',
                    pro: '✔',
                    enterprise: '✔'
                  },
                  {
                    label: 'Demographics',
                    essential: 'X',
                    pro: 'X',
                    enterprise: '✔'
                  }
                ]
              },
              {
                label: 'Real-time API Access',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Custom Data Exports',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              }
            ]
          },
          {
            label: 'Email Reports',
            type: [
              {
                label: 'Email reports',
                essential: '✔',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Custom Email Report',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              }
            ]
          }
        ]
      },
      management: {
        label: 'Management',
        items: [
          {
            label: 'Basic Security Options',
            type: [
              {
                label: 'Login History',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Login Analysis',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'User Management',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Alert Settings',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'User Group Management',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Occupancy Widget Manager',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Private Server',
                essential: 'X',
                pro: 'X',
                enterprise: 'with 50+ Locations'
              },
              {
                label: 'User Logs',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              },
              {
                label: 'Create Report Creation Tool',
                essential: 'X',
                pro: 'X',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Webhooks',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: '2 Factor Authentication',
                essential: 'X',
                pro: '✔',
                enterprise: '✔'
              },
              {
                label: 'Google Single Sign-On',
                essential: 'X',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'IP Address Restriction',
                essential: 'X',
                pro: 'X',
                enterprise: '✔'
              }
            ]
          },
          {
            label: 'Advanced Security Options',
            type: [
              {
                label: 'Maximum Login Attempt Restriction',
                essential: '50,000 yen per client',
                pro: '50,000 yen per client',
                enterprise: '50,000 yen per client'
              },
              {
                label: 'Login Time Anomaly Alert',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Login Location Anomaly Alert',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              },
              {
                label: 'Data Download Anomaly Alert',
                essential: 'Coming Soon',
                pro: 'Coming Soon',
                enterprise: 'Coming Soon'
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    showContactModalBox(val) {
      this.planSelected = val;
      this.showContactModal = true;
    },
    closeDialog() {
      this.showContactModal = false;
    }
  }
};
