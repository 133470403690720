var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-input',{attrs:{"filled":"","label":_vm.$t('AuthPage.password'),"placeholder":_vm.$t('AuthPage.type_password'),"haserror":_vm.$v.value.$dirty && _vm.$v.value.password.$error,"rules":[
      v => !!v || _vm.$t('AuthPage.field_is_required'),
      v => !/\s/g.test(v) || _vm.$t('AuthPage.no_white_space'),
      v => /^(?=.*\d).{1,}$/.test(v) || _vm.$t('AuthPage.password_one_number'),
      v =>
        /^(?=.*[A-Z]).{1,}$/.test(v) || _vm.$t('AuthPage.password_one_uppercase'),
      v =>
        /^(?=.*[a-z]).{1,}$/.test(v) || _vm.$t('AuthPage.password_one_lowercase'),

      v => _vm.checkSpecialSymbols(v) || _vm.$t('AuthPage.password_special_symbol'),
      v => _vm.checkFullwidth(v) || _vm.$t('AuthPage.no_full_width_characters'),
      v => v.length > 5 || _vm.$t('AuthPage.min_length')
    ],"type":_vm.isHide ? 'text' : 'password',"no-margin":"","mandatory":""},model:{value:(_vm.value.password),callback:function ($$v) {_vm.$set(_vm.value, "password", $$v)},expression:"value.password"}}),_c('q-input',{attrs:{"filled":"","label":_vm.$t('AuthPage.confirm_password'),"placeholder":_vm.$t('AuthPage.type_confirm_password'),"haserror":_vm.$v.value.$dirty && _vm.$v.value.confirmPassword.$error,"type":_vm.isHide ? 'text' : 'password',"rules":[
      v => !!v || _vm.$t('AuthPage.field_is_required'),
      v => v === _vm.value.password || _vm.$t('AuthPage.password_does_not_match')
    ],"no-margin":"","mandatory":""},model:{value:(_vm.value.confirmPassword),callback:function ($$v) {_vm.$set(_vm.value, "confirmPassword", $$v)},expression:"value.confirmPassword"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }