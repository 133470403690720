
import AddNewStoreType from "@/components/modals/AddNewStoreType.vue";
import stores from "@/services/stores";

export default {
  components: {
    AddNewStoreType
  },
  props: {},
  data() {
    return {
      openModel: false,
      columns: [
        {
          name: "storeName",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "storeName",
          sortable: true
        },
        {
          name: "description",
          align: "left",
          label: this.$store.state.user.translate.description,
          field: "description",
          sortable: true
        },
        {
          name: "action",
          label: this.$store.state.user.translate.actions,
          field: "action"
        }
      ],
      storeData: [],
      selectedStore: [],
       pagination:{
      page: 1,
      rowsPerPage: 10,
     },
      selected: [],
      filter: "",
      confirm: false,
      visible: false,
      userDetails: "",
      loading: false
    };
  },
  computed: {
    pagesNumber () {
      return Math.ceil(this.storeData.length / this.pagination.rowsPerPage)
    }
  },
  mounted() {
    this.visible = true;
    this.getStores();
  },
  methods: {
    closeModel() {
      this.openModel = false;
    },
    addNewStore() {
      this.selectedStore = null;
      this.openModel = true;
    },
    editStoreDialog(store) {
      this.selectedStore = store;
      this.openModel = true;
    },
    getStores() {
      this.visible = true;
      this.loading = true;
      stores
        .getStoreTypes()
        .then(response => {
          this.storeData = response.data.response.types;
          this.visible = false;
          this.loading = false;
        })
        .catch(error => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red"
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate
                .something_went_wrong,
              color: "red"
            });
          }
        });
    },
    deleteStore(store) {
      this.selectedStore = store;
      this.confirm = true;
    },
    confirmDeleteStore() {
      stores
        .deleteStoreType(this.selectedStore._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.deleted_successfully,
            color: "green"
          });
          this.updateStores();
        })
        .catch(error => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red"
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate
                .something_went_wrong,
              color: "red"
            });
          }
        });
      this.confirm = false;
    },
    async updateStores() {
      this.getStores();
      this.openModel = false;
    }
  }
};
