
import CommonMixin from '@/mixins/CommonMixin';
import map from 'lodash/map';
import { handleErrorUI } from '@/util/error';

export default {
  mixins: [CommonMixin],
  props: {
    plan: { type: Object, required: true },
    payments: { type: Array, required: true }
  },
  data() {
    return {
      selectedPaymentId: '',
      submittingOrder: false
    };
  },
  computed: {
    paymentOptions() {
      return map(this.payments, payment => ({
        label: payment.cardNumber,
        value: payment.id
      }));
    },
    planPeriod() {
      const date = new Date();
      const startDate = date.toLocaleDateString();
      date.setFullYear(date.getFullYear() + 1);
      const endDate = date.toLocaleDateString();
      return `${startDate} ~ ${endDate}`;
    },
    selectedPayment() {
      return !!this.selectedPaymentId;
    }
  },
  methods: {
    async submitOrder() {
      try {
        this.submittingOrder = true;
        const { tenant } = await this.$repo.session.fetch();
        await this.$repo.plan.changePlan(
          tenant,
          this.selectedPaymentId,
          this.plan.name.toUpperCase()
        );
        this.$emit('submit');
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
