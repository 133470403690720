
import { createColumn, formatAsDate } from '@/util/table';

export default {
  props: {
    input: { type: Array, default: () => [] },
    appends: { type: Array, default: () => [] },
    pagination: { type: Object, required: true },
    loading: { type: Boolean, default: false },
    noAction: { type: Boolean, default: false }
  },
  computed: {
    columns() {
      const columnList = [
        createColumn('invoiceNumber', 'Invoice Number'),
        createColumn('storeName', 'Store Name'),
        createColumn('type', 'Billing Type'),
        createColumn('billingDate', 'Billing Date', formatAsDate),
        createColumn('dueDate', 'Billing Due Date', formatAsDate),
        createColumn('paymentMethod', 'Payment Method'),
        createColumn('billingAmount', 'Billing Amount')
      ];

      return [
        ...columnList,
        ...this.appends,
        createColumn('action', 'Action', {
          sortable: false
        })
      ].filter(Boolean);
    }
  }
};
